import { ExclamationCircleFilled } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Collapse,
  Empty,
  Modal,
  Progress,
  Row,
  Spin,
  Tabs,
  Tag,
} from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../../components/Footer";
import NavBar from "../../../components/NavBar";
import SideBar from "../../../components/SideBar";
import { rightsService } from "../../../services/rightsService";
import { LOGGED_USER } from "../../../variables/constants";
import { frenchDate } from "../../../variables/functions";
import axiosInstance from "../../../variables/api/axiosInstance";
import moment from "moment";

const { confirm } = Modal;
const { Panel } = Collapse;

const DetailsLotAttestations = () => {
  const [lots, setLots] = useState([]);
  const [categories, setCategories] = useState([]);
  const [compagnie, setCompagnie] = useState("");
  const [affectationModalOpen, setAffectationModalOpen] = useState(false);
  const [orderModalOpen, setOrderModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [firstAttestation, setFirstAttestation] = useState(0);
  const [lastAttestation, setLastAttestation] = useState(0);
  const [nombre, setNombre] = useState(0);
  const [reload, setReload] = useState(false);
  const [selectedCategorie, setSelectedCategorie] = useState("M");
  const [selectedTypeAttestation, setSelectedTypeAttestation] =
    useState("SIMPLE");
  const [commentaire, setCommentaire] = useState("");
  const [getLotLoading, setGetLotLoading] = useState(false);

  const param = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const code = param.codeCompagnie
      ? param.codeCompagnie
      : LOGGED_USER.profil.organisme.code;
    setGetLotLoading(true);
    axiosInstance
      .get(`/api/lot-attestation/compagnie/`)
      .then((res) => {
        console.log(res.data);
        setLots(res.data);
        setReload(false);
        setGetLotLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setGetLotLoading(false);
      });

    axiosInstance
      .get(`/api/organisme/code/${code}`)
      .then((res) => {
        console.log(res.data);
        setCompagnie(res.data);
        /*
                                setGetLotLoading(false)
                */
      })
      .catch((err) => {
        console.log(err);
        setGetLotLoading(false);
      });

    axiosInstance
      .get(`/api/categorie`)
      .then((res) => {
        console.log(res.data);
        setCategories(res.data);
        /*
                                setGetLotLoading(false)
                */
      })
      .catch((err) => {
        console.log(err);
        setGetLotLoading(false);
      });
  }, [reload, param.codeCompagnie]);
  /*

        const onSubmit = () => {
            if (nombre === lastAttestation - firstAttestation + 1) {
                const catg = categories.find(element => element.codeCategorie === selectedCategorie);
                let lot = {
                    compagnie: compagnie,
                    lot: [firstAttestation, lastAttestation],
                    updatedLot: [firstAttestation, lastAttestation],
                    categorie: catg,
                    typeAttestation: selectedTypeAttestation
                }
                console.log("Loooot: ", lot);
                axiosInstance
                    .post(`/api/lot-attestation`, JSON.stringify(lot))
                    .then((res) => {
                        console.log("Data Ressst", res);
                        if (res.status === 226) {
                            toast("Cet numéro d'attestation existe déjà!");
                        } else {
                            setAffectationModalOpen(false);
                            setReload(true);
                            toast("Lot d'attestation ajoutée avec succès");
                        }
                        setLoading(false);
                    })
                    .catch((err) => {
                        console.log(err);
                        if (err.response.status === 226) {
                            toast("Cet numéro d'attestation existe déjà!");
                        } else {
                            toast("Erreur lors de l'ajout du lot d'attestation");
                        }
                        setLoading(false);
                    });
            } else {
                toast("Veuillez vérifier les valeurs que vous avez saisi!");
            }
        }
    */

  const onSubmitCommandeAttestation = () => {
    if (nombre) {
      const catg = categories.find(
        (element) => element.codeCategorie === selectedCategorie,
      );
      let bonCommande = {
        commentaire: commentaire,
        nombre: nombre,
        categorie: catg,
        typeAttestation: selectedTypeAttestation,
        statutCommande: "INITIE",
      };

      axiosInstance
        .post(`/api/bon-commande`, JSON.stringify(bonCommande))
        .then((res) => {
          console.log("Data Ressst", res);
          if (res.status === 226) {
            toast("Cet numéro d'attestation existe déjà!");
          } else {
            setAffectationModalOpen(false);
            setReload(true);
            toast("Lot d'attestation ajoutée avec succès");
          }
          setLoading(false);
          setOrderModalOpen(false);
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 226) {
            toast("Cet numéro d'attestation existe déjà!");
          } else {
            toast("Erreur lors de l'ajout du lot d'attestation");
          }
          setLoading(false);
        });
    } else {
      toast(
        "Veuillez renseigner le nombre d'attestation que vous voulez commander!",
      );
    }
  };

  const showReceptionAttestationConfirm = (lotA) => {
    confirm({
      title: "Etes-vous sûr de vouloir valider la réception du lot?",
      icon: <ExclamationCircleFilled />,
      content: "Toutes vos actions sont historisées",
      okText: "Oui",
      okType: "danger",
      cancelText: "Annuler",
      onOk() {
        console.log("OK");
        validationReception(lotA);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  console.log(lots);

  const validationReception = (lotA) => {
    axiosInstance
      .get(`/api/lot-attestation/reception/${lotA}`)
      .then((res) => {
        // console.log(res.data);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPercentage = (l) => {
    return (l.lot.length - l.updatedLot.length) / l.lot.length;
  };

  const [allDispatchedLots, setAllDispatchedLots] = useState([]);
  const [siegeLots, setSiegeLots] = useState([]);
  const [agenceLots, setAgenceLots] = useState([]);
  useEffect(() => {
    const uniqueDispatched = [];
    const seenCodes = new Set();

    const allDispatched = lots?.flatMap((l) =>
      (l?.dispatchedLots || []).map((dispatched) => {
        const entry = {
          ...dispatched,
          categorie: l.categorie,
          typeAttestation: l.typeAttestation,
          lotId: l.codeId,
        };

        if (!seenCodes.has(entry.lotId)) {
          seenCodes.add(entry.lotId);
          uniqueDispatched.push(entry);
        }
        setSiegeLots(
          uniqueDispatched?.filter(
            (adl) =>
              adl.agence.typeAgence === "SIEGE" && adl.activeData === true,
          ),
        );
        return entry;
      }),
    );
    setAgenceLots(
      uniqueDispatched?.filter(
        (adl) => adl.agence.typeAgence !== "SIEGE" && adl.activeData === true,
      ),
    );
    console.log(uniqueDispatched);
    setAllDispatchedLots(uniqueDispatched);
  }, [lots]);

  const newLots = lots?.filter((l) => getPercentage(l) !== 1);

  console.log("AgenceLot: ", lots);

  console.log(
    lots.filter((l) => l.codeId === "SA1105656" || l.codeId === "SA1105655"),
  );
  console.log("newwwww", newLots);
  console.log(agenceLots);
  console.log("Siegeeeee", siegeLots);
  /*
       console.log(siegeLots?.length)
       console.log(lots[0]?.dispatchedLots)
       console.log(agenceLots?.length)*/

  const groupLotsByDate = (lots) => {
    const today = moment().startOf("day");
    const threeDaysAgo = moment().subtract(3, "days").startOf("day");
    const sevenDaysAgo = moment().subtract(7, "days").startOf("day");

    return {
      aujourdhui: lots.filter((l) => moment(l.createdAt).isSame(today, "day")),
      troisDerniersJours: lots.filter(
        (l) =>
          moment(l.createdAt).isAfter(threeDaysAgo) &&
          moment(l.createdAt).isBefore(today),
      ),
      cetteSemaine: lots.filter(
        (l) =>
          moment(l.createdAt).isAfter(sevenDaysAgo) &&
          moment(l.createdAt).isBefore(threeDaysAgo),
      ),
      plusAncien: lots.filter((l) =>
        moment(l.createdAt).isBefore(sevenDaysAgo),
      ),
    };
  };
  const groupedLots = groupLotsByDate(newLots);

  return (
    <div class="layout-wrapper layout-content-navbar  ">
      <div class="layout-container">
        <SideBar />

        {/* <!-- Layout container --> */}
        <div class="layout-page">
          <NavBar />

          {/* <!-- Content wrapper --> */}
          <div class="content-wrapper">
            {/* <!-- Content --> */}
            <div class="container-xxl flex-grow-1 container-p-y">
              <div class="row g-6 mb-6">
                <div class="col-lg-12 col-xxl-12">
                  <Spin spinning={getLotLoading}>
                    <div class="card h-100">
                      <div class="card-header d-flex align-items-center justify-content-between">
                        <div class="card-title mb-0">
                          <h5 class="m-0 me-2">
                            Les lots d'attestations de {compagnie.raisonSociale}
                          </h5>
                        </div>
                        {rightsService("HLA061") ? (
                          <div class="dropdown">
                            <button
                              type="button"
                              class="btn btn-primary"
                              onClick={() => setAffectationModalOpen(true)}
                            >
                              Affecter un lot
                            </button>
                          </div>
                        ) : null}
                        {rightsService("HLA065") &&
                        LOGGED_USER.profil.organisme.typeOrganisme !==
                          "POOL" ? (
                          <button
                            type="button"
                            class="btn btn-primary"
                            onClick={() => setOrderModalOpen(true)}
                          >
                            Effectuer une commande
                          </button>
                        ) : null}
                      </div>

                      <div class="card-body">
                        <Tabs
                          defaultActiveKey="1"
                          style={{ marginTop: 20 }}
                          items={[
                            {
                              key: 1,
                              label: "Liste des lots recus du POOL",
                              children: (
                                <div>
                                  <Collapse
                                    accordion
                                    bordered={false}
                                    style={{
                                      backgroundColor: "#f7f8fa",
                                      borderRadius: 8,
                                      boxShadow:
                                        "0 4px 10px rgba(0, 0, 0, 0.1)",
                                    }}
                                  >
                                    {Object.entries(groupedLots).map(
                                      ([section, lots]) => (
                                        <Panel
                                          key={section}
                                          header={
                                            section === "aujourdhui"
                                              ? "Aujourd'hui"
                                              : section === "troisDerniersJours"
                                                ? "Les 3 derniers jours"
                                                : section === "cetteSemaine"
                                                  ? "Cette semaine"
                                                  : "Plus ancien"
                                          }
                                          style={{
                                            marginBottom: 8,
                                            borderRadius: 8,
                                            overflow: "hidden",
                                            backgroundColor: "#ffffff",
                                          }}
                                        >
                                          <Row className="p-0 m-0" gutter={16}>
                                            {lots.length ? (
                                              lots.map((l, id) => (
                                                <Col
                                                  span={8}
                                                  key={id}
                                                  style={{ marginBottom: 16 }}
                                                >
                                                  <Card
                                                    hoverable
                                                    style={{
                                                      borderRadius: 8,
                                                      overflow: "hidden",
                                                      boxShadow:
                                                        "0 4px 10px rgba(0, 0, 0, 0.1)",
                                                      textAlign: "center",
                                                    }}
                                                    actions={[
                                                      l.dateReception
                                                        ? rightsService(
                                                            "HLA063",
                                                          ) && (
                                                            <Button
                                                              type="primary"
                                                              onClick={() =>
                                                                navigate(
                                                                  `/dispatching-lot-attestation/${l.codeId}`,
                                                                )
                                                              }
                                                              style={{
                                                                backgroundColor:
                                                                  "#4caf50",
                                                                borderColor:
                                                                  "#0ff345",
                                                                color: "#fff",
                                                                fontWeight:
                                                                  "bold",
                                                                width: "90%",
                                                                borderRadius: 6,
                                                                transition:
                                                                  "background 0.3s",
                                                              }}
                                                              onMouseEnter={(
                                                                e,
                                                              ) =>
                                                                (e.target.style.backgroundColor =
                                                                  "#81c784")
                                                              }
                                                              onMouseLeave={(
                                                                e,
                                                              ) =>
                                                                (e.target.style.backgroundColor =
                                                                  "#4caf50")
                                                              }
                                                            >
                                                              Dispatcher{" "}
                                                              <i className="bx bx-chevron-right"></i>
                                                              <div
                                                                className="badge bg-label-secondary"
                                                                style={{
                                                                  marginLeft: 8,
                                                                  color: "#fff",
                                                                  background:
                                                                    "#4caf50",
                                                                  borderRadius: 8,
                                                                  padding:
                                                                    "0 6px",
                                                                }}
                                                              >
                                                                {l.lot.length -
                                                                  l.updatedLot
                                                                    .length}{" "}
                                                                / {l.lot.length}
                                                              </div>
                                                            </Button>
                                                          )
                                                        : rightsService(
                                                            "HLA064",
                                                          ) && (
                                                            <Button
                                                              type="default"
                                                              onClick={() =>
                                                                showReceptionAttestationConfirm(
                                                                  l.codeId,
                                                                )
                                                              }
                                                              style={{
                                                                backgroundColor:
                                                                  "#f5f5f5",
                                                                borderColor:
                                                                  "#d6d6d6",
                                                                color: "#333",
                                                                fontWeight:
                                                                  "bold",
                                                                width: "90%",
                                                                borderRadius: 6,
                                                                transition:
                                                                  "all 0.3s",
                                                              }}
                                                              onMouseEnter={(
                                                                e,
                                                              ) =>
                                                                (e.target.style.backgroundColor =
                                                                  "#e0e0e0")
                                                              }
                                                              onMouseLeave={(
                                                                e,
                                                              ) =>
                                                                (e.target.style.backgroundColor =
                                                                  "#f5f5f5")
                                                              }
                                                            >
                                                              Valider réception
                                                            </Button>
                                                          ),
                                                    ]}
                                                  >
                                                    <Row
                                                      gutter={[8, 8]}
                                                      justify="center"
                                                      align="middle"
                                                    >
                                                      <Col span={24}>
                                                        <Progress
                                                          type="dashboard"
                                                          size={90}
                                                          percent={(
                                                            (100 *
                                                              (l.lot.length -
                                                                l.updatedLot
                                                                  .length)) /
                                                            l.lot.length
                                                          ).toFixed(0)}
                                                          strokeColor="blueviolet"
                                                        />
                                                      </Col>
                                                      <Col span={24}>
                                                        <Tag
                                                          color="geekblue"
                                                          style={{
                                                            fontSize: 14,
                                                            fontWeight: "bold",
                                                          }}
                                                        >
                                                          {l.typeAttestation}
                                                        </Tag>
                                                        <Tag
                                                          color="cyan"
                                                          style={{
                                                            fontSize: 14,
                                                            fontWeight: "bold",
                                                          }}
                                                        >
                                                          {l?.categorie?.nom}
                                                        </Tag>
                                                      </Col>
                                                    </Row>
                                                    <div
                                                      style={{ marginTop: 16 }}
                                                    >
                                                      <p
                                                        style={{
                                                          margin: 0,
                                                          color: l.dateReception
                                                            ? "#52c41a"
                                                            : "#faad14",
                                                        }}
                                                      >
                                                        {l.dateReception
                                                          ? `Livré le ${frenchDate(
                                                              l.createdAt,
                                                            )}`
                                                          : "Pas encore livré"}
                                                      </p>
                                                    </div>
                                                  </Card>
                                                </Col>
                                              ))
                                            ) : (
                                              <Empty
                                                description={`Aucun lot dans cette catégorie`}
                                                image={
                                                  Empty.PRESENTED_IMAGE_SIMPLE
                                                }
                                                style={{ marginTop: 32 }}
                                              />
                                            )}
                                          </Row>
                                        </Panel>
                                      ),
                                    )}
                                  </Collapse>
                                </div>
                              ),
                            },
                            {
                              key: 2,
                              label: "Lots dispatchés",
                              children: (
                                <Row class="p-0 m-0" gutter={16}>
                                  {agenceLots?.length ? (
                                    agenceLots?.map((l, id) => (
                                      <Col
                                        span={8}
                                        key={id}
                                        style={{ marginBottom: 16 }}
                                      >
                                        <Card
                                          hoverable
                                          style={{
                                            borderRadius: 8,
                                            overflow: "hidden",
                                            boxShadow:
                                              "0 4px 10px rgba(0, 0, 0, 0.1)",
                                            textAlign: "center",
                                          }}
                                        >
                                          <Row
                                            gutter={[8, 8]}
                                            justify="center"
                                            align="middle"
                                          >
                                            <Col span={24}>
                                              <Progress
                                                type="dashboard"
                                                size={90}
                                                percent={(
                                                  (100 *
                                                    (l.lot.length -
                                                      l.updatedLot.length)) /
                                                  l.lot.length
                                                ).toFixed(0)}
                                                strokeColor="blueviolet"
                                              />
                                            </Col>
                                            <Col span={24}>
                                              <Tag
                                                color="geekblue"
                                                style={{
                                                  fontSize: 14,
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                {l.typeAttestation}
                                              </Tag>
                                              <Tag
                                                color="cyan"
                                                style={{
                                                  fontSize: 14,
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                {l?.categorie?.nom}
                                              </Tag>
                                            </Col>
                                          </Row>
                                          <div style={{ marginTop: 16 }}>
                                            <p
                                              style={{
                                                margin: 0,
                                                color: l.dateReception
                                                  ? "#52c41a"
                                                  : "#faad14",
                                              }}
                                            >
                                              {l.dateReception
                                                ? `Livré le ${frenchDate(l.createdAt)}`
                                                : "Pas encore livré"}
                                            </p>
                                          </div>
                                        </Card>
                                      </Col>
                                    ))
                                  ) : (
                                    <Empty
                                      description="Aucun lot assigné à la compagnie pour l'instant"
                                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                                      style={{ marginTop: 32 }}
                                    />
                                  )}
                                </Row>
                              ),
                            },
                            {
                              key: 3,
                              label: "Nos lots d'attestations",
                              children: (
                                <Row class="p-0 m-0" gutter={16}>
                                  {siegeLots.length ? (
                                    siegeLots.map((l, id) => (
                                      <Col
                                        span={8}
                                        key={id}
                                        style={{ marginBottom: 16 }}
                                      >
                                        <Card
                                          hoverable
                                          style={{
                                            borderRadius: 8,
                                            overflow: "hidden",
                                            boxShadow:
                                              "0 4px 10px rgba(0, 0, 0, 0.1)",
                                            textAlign: "center",
                                          }}
                                          actions={[
                                            l.dateReception
                                              ? rightsService("HLA063") && (
                                                  <Button
                                                    type="primary"
                                                    onClick={() =>
                                                      navigate(
                                                        `/dispatching-lot-attestation/${l.codeId}`,
                                                      )
                                                    }
                                                    style={{
                                                      backgroundColor:
                                                        "blueviolet",
                                                      borderColor: "blueviolet",
                                                      width: "100%",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Dispatcher{" "}
                                                    <i className="bx bx-chevron-right"></i>
                                                    <div
                                                      className="badge bg-label-secondary"
                                                      style={{ marginLeft: 8 }}
                                                    >
                                                      {l.lot.length -
                                                        l.updatedLot.length}
                                                      /{l.lot.length}
                                                    </div>
                                                  </Button>
                                                )
                                              : rightsService("HLA064") && (
                                                  <Button
                                                    type="default"
                                                    onClick={() =>
                                                      showReceptionAttestationConfirm(
                                                        l.codeId,
                                                      )
                                                    }
                                                    style={{
                                                      fontWeight: "bold",
                                                      width: "100%",
                                                    }}
                                                  >
                                                    Valider réception
                                                  </Button>
                                                ),
                                          ]}
                                        >
                                          <Row
                                            gutter={[8, 8]}
                                            justify="center"
                                            align="middle"
                                          >
                                            <Col span={24}>
                                              <Progress
                                                type="dashboard"
                                                size={90}
                                                percent={(
                                                  (100 *
                                                    (l.lot.length -
                                                      l.updatedLot.length)) /
                                                  l.lot.length
                                                ).toFixed(0)}
                                                strokeColor="blueviolet"
                                              />
                                            </Col>
                                            <Col span={24}>
                                              <Tag
                                                color="geekblue"
                                                style={{
                                                  fontSize: 14,
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                {l.typeAttestation}
                                              </Tag>
                                              <Tag
                                                color="cyan"
                                                style={{
                                                  fontSize: 14,
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                {l?.categorie?.nom}
                                              </Tag>
                                            </Col>
                                          </Row>
                                          <div style={{ marginTop: 16 }}>
                                            <p
                                              style={{
                                                margin: 0,
                                                color: l.dateReception
                                                  ? "#52c41a"
                                                  : "#faad14",
                                              }}
                                            >
                                              {l.dateReception
                                                ? `Livré le ${frenchDate(l.createdAt)}`
                                                : "Pas encore livré"}
                                            </p>
                                          </div>
                                        </Card>
                                      </Col>
                                    ))
                                  ) : (
                                    <Empty
                                      description="Aucun lot assigné à la compagnie pour l'instant"
                                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                                      style={{ marginTop: 32 }}
                                    />
                                  )}
                                </Row>
                              ),
                            },
                          ]}
                        />
                      </div>
                    </div>
                  </Spin>
                </div>
              </div>
            </div>
            {/* <!-- / Content --> */}

            {/* <!-- Footer --> */}
            <Footer />
            {/* <!-- / Footer --> */}

            <div class="content-backdrop fade"></div>
          </div>
          {/* <!-- Content wrapper --> */}
        </div>
        {/* <!-- / Layout page --> */}
      </div>
      {/* <Modal open={affectationModalOpen} loading={loading} onOk={onSubmit}
               onCancel={() => setAffectationModalOpen(false)} zIndex={1900} centered>
            <div className="text-center mb-6">
                <h4 className="address-title mb-2">Nouveau lot d'attestation </h4>
                <p className="address-subtitle">
                    Affecter un nouveau lot d'attestation pour {compagnie?.raisonSociale}
                </p>
            </div>
            <form id="addNewAddressForm" className="row g-5" onSubmit={onSubmit}>
                <div className="col-12 col-md-12">
                    <label class="form-label" for="firstAttestation">
                        Veuillez entrer le numéro de la première attestation
                    </label>
                    <input
                        className="form-control" id="firstAttestation" value={firstAttestation}
                        name="firstAttestation"
                        onChange={(e) => {
                            setFirstAttestation(parseInt(e.target.value));
                            setLastAttestation(parseInt(e.target.value));
                        }}
                        placeholder="Entrez le numéro de la première attestation" type="number"/>

                </div>
                <div className="col-12 col-md-12">
                    <label class="form-label" for="lastAttestation">
                        Le nombre d'attestation
                    </label>
                    <input className="form-control" id="lastAttestation" value={nombre} name="lastAttestation"
                           onChange={(e) => setNombre(parseInt(e.target.value))}
                           placeholder="Entrez le nombre d'attestation" type="number"/>
                </div>
                <div className="col-12 col-md-12">
                    <label class="form-label" for="lastAttestation">
                        Veuillez entrer le numéro de la première attestation si incorrect
                    </label>
                    <input className="form-control" id="lastAttestation" value={lastAttestation}
                           name="lastAttestation" onChange={(e) => setLastAttestation(parseInt(e.target.value))}
                           placeholder="Entrez le numéro de la dernière attestation" type="number"/>
                </div>
                <div className="col-12 col-md-12">
                    <label class="form-label" for="agence">
                        Veuillez sélectionner le type d'attestation
                    </label>
                    <select className="form-control" id="typeAttestation" value={selectedTypeAttestation}
                            name="typeAttestation" onChange={(e) => setSelectedTypeAttestation(e.target.value)}
                            placeholder="Sélectionner un type d'attestation">
                        <option value="SIMPLE"> SIMPLE</option>
                        <option value="CEDEAO"> CEDEAO</option>
                    </select>
                </div>
                <div className="col-12 col-md-12">
                    <label class="form-label" for="agence">
                        Veuillez sélectionner la catégorie
                    </label>
                    <select className="form-control" id="agence" value={selectedCategorie} name="agence"
                            onChange={(e) => setSelectedCategorie(e.target.value)}
                            placeholder="Sélectionner une agence">
                        {categories?.map((cat, id) => (
                            <option key={id} value={cat.codeCategorie}> {cat.nom} </option>))}
                    </select>
                </div>
            </form>
        </Modal>*/}

      <Modal
        open={orderModalOpen}
        loading={loading}
        onOk={onSubmitCommandeAttestation}
        onCancel={() => setOrderModalOpen(false)}
        zIndex={1900}
        centered
      >
        <div className="text-center mb-6">
          <h4 className="address-title mb-2">
            Commande de lot d'attestations{" "}
          </h4>
          <p className="address-subtitle">
            Effectuer une commande de lot(s) d'attestations
          </p>
        </div>
        <form
          id="addNewAddressForm"
          className="row g-5"
          onSubmit={onSubmitCommandeAttestation}
        >
          <div className="col-12 col-md-12">
            <label class="form-label" for="firstAttestation">
              Veuillez entrer le nombre d'attestation
            </label>
            <input
              className="form-control"
              id="nombre"
              value={nombre}
              name="nombre"
              onChange={(e) => {
                setNombre(parseInt(e.target.value));
              }}
              placeholder="Entrez le nombre d'attestations que vous voulez commander"
              type="number"
            />
          </div>
          <div className="col-12 col-md-12">
            <label class="form-label" for="agence">
              Veuillez sélectionner le type d'attestation
            </label>
            <select
              className="form-control"
              id="typeAttestation"
              value={selectedTypeAttestation}
              name="typeAttestation"
              onChange={(e) => setSelectedTypeAttestation(e.target.value)}
              placeholder="Sélectionner un type d'attestation"
            >
              <option value="SIMPLE"> SIMPLE</option>
              <option value="CEDEAO"> CEDEAO</option>
            </select>
          </div>
          <div className="col-12 col-md-12">
            <label class="form-label" for="agence">
              Veuillez sélectionner la catégorie
            </label>
            <select
              className="form-control"
              id="agence"
              value={selectedCategorie}
              name="agence"
              onChange={(e) => setSelectedCategorie(e.target.value)}
              placeholder="Sélectionner une agence"
            >
              {categories?.map((cat, id) => (
                <option key={id} value={cat.codeCategorie}>
                  {" "}
                  {cat.nom}{" "}
                </option>
              ))}
            </select>
          </div>
          <div className="col-12 col-md-12">
            <label class="form-label" for="commentaire">
              Un commentaire?
            </label>
            <textarea
              className="form-control"
              id="commentaire"
              value={commentaire}
              name="commentaire"
              onChange={(e) => {
                setCommentaire(e.target.value);
              }}
              placeholder="Un commentaire pour des précisions sur la commande"
              rows={3}
            ></textarea>
          </div>
        </form>
      </Modal>
      {/* <!-- Overlay --> */}
      <div class="layout-overlay layout-menu-toggle"></div>

      {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
      <div class="drag-target"></div>
    </div>
  );
};

export default DetailsLotAttestations;
