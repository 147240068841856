import {ExclamationCircleFilled} from '@ant-design/icons';
import {Card, Descriptions, Empty, Input, Modal, Progress, Slider, Spin} from 'antd';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import Footer from '../../../components/Footer';
import NavBar from '../../../components/NavBar';
import SideBar from '../../../components/SideBar';
import {rightsService} from '../../../services/rightsService';
import {API_URL, LOGGED_USER, TOKEN} from '../../../variables/constants';
import {frenchDate} from '../../../variables/functions';
import axiosInstance from "../../../variables/api/axiosInstance";

const {confirm} = Modal;

const DetailsLotAttestationsDispatched = () => {
    const [lots, setLots] = useState([]);
    const [agences, setAgences] = useState([]);
    const [lotAttestation, setLotAttestation] = useState({
        lot: [],
        updatedLot: []
    });
    const [compagnie, setCompagnie] = useState("");
    const [affectationModalOpen, setAffectationModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [firstAttestation, setFirstAttestation] = useState(0);
    const [lastAttestation, setLastAttestation] = useState(0);
    const [nombre, setNombre] = useState(0);
    const [reload, setReload] = useState(false);
    const [selectedAgence, setSelectedAgence] = useState("");
    const [getLotLoading, setGetLotLoading] = useState(false)
    const param = useParams();
    console.log(lotAttestation)
    useEffect(() => {
        const code = param.codeCompagnie ? param.codeCompagnie : LOGGED_USER.profil.organisme.code;
        setGetLotLoading(true)
        axiosInstance
            .get(`api/lot-attestation/${param.lotAttestationId}/`)
            .then((res) => {
                setLots(res.data.dispatchedLots);
                setLotAttestation(res.data);
                setCompagnie(res.data.compagnie);
                setReload(false);

                axiosInstance
                    .get(`api/agence/organisme/code/${res.data.compagnie.code}`)
                    .then((resp) => {
                        setGetLotLoading(false)
                        setAgences(resp.data);
                        setSelectedAgence(resp.data[0]?.codeAgence || "");
                    })
                    .catch((err) => {
                        console.error(err);
                        setGetLotLoading(false)

                    });
            })
            .catch((err) => {
                console.error(err);
                setGetLotLoading(false)

            });
    }, [TOKEN, reload]);

    const onSubmit = () => {
        setLoading(true)
        if (nombre === lastAttestation - firstAttestation + 1) {
            const allWantedAttestations = Array.from({length: nombre}, (_, i) => firstAttestation + i);
            const absentElement = allWantedAttestations.some(element => !lotAttestation?.updatedLot.includes(element));

            if (!absentElement) {
                const agency = agences.find(element => element.codeAgence === selectedAgence);

                axiosInstance
                    .get(`/api/dispatched-lot-attestation/lot/${param.lotAttestationId}/${firstAttestation}/${lastAttestation}/${selectedAgence}`)
                    .then((res) => {
                        if (res.status === 226) {
                            toast("Cet numéro d'attestation existe déjà!");
                        } else {
                            setAffectationModalOpen(false);
                            setReload(true);
                            toast("Lot d'attestation ajoutée avec succès");
                        }
                        setLoading(false);
                    })
                    .catch((err) => {
                        toast("Erreur lors de l'ajout du lot d'attestation");
                        setLoading(false);
                    });
            } else {
                toast("Il existe des attestations déjà dispatchées ou utilisées dans votre sélection");
                setLoading(false);
            }
        } else {
            toast("Veuillez vérifier les valeurs que vous avez saisi!");
            setLoading(false);
        }
    };

    const showReceptionAttestationConfirm = (lotA) => {
        confirm({
            title: "Etes-vous sûr de vouloir valider la réception du lot dispatché?",
            icon: <ExclamationCircleFilled/>,
            content: "Toutes vos actions sont historisées",
            okText: "Oui",
            okType: "danger",
            cancelText: "Annuler",
            onOk() {
                validationReception(lotA);
            },
        });
    };

    const groupByContinuity = (arr) => {
        if (!Array.isArray(arr) || arr.length === 0) return [];

        const groups = [];
        let currentGroup = [arr[0]];

        for (let i = 1; i < arr.length; i++) {
            if (arr[i] === arr[i - 1] + 1) {
                currentGroup.push(arr[i]);
            } else {
                groups.push(currentGroup);
                currentGroup = [arr[i]];
            }
        }

        if (currentGroup.length > 0) {
            groups.push(currentGroup);
        }

        return groups;
    }

    const renderSliders = (groups, firstAttestation, lastAttestation, onChange) => {
        return groups.map((group, index) => (
            <div key={index} className="mb-4">
                <label className="form-label">Plage {index + 1} des attestations</label>
                <Slider
                    range
                    min={group[0]}
                    max={group[group.length - 1]}
                    defaultValue={[group[0], group[group.length - 1]]}
                    value={[
                        Math.max(firstAttestation, group[0]),
                        Math.min(lastAttestation, group[group.length - 1]),
                    ]}
                    onChange={(value) => onChange(index, value)}
                    styles={{
                        track: {
                            background: 'linear-gradient(to right, #32cd32, #32cd32)',
                            height: 8,
                            opacity: 0.8,
                        },
                        rail: {
                            background: 'linear-gradient(to right, #1e90ff, #1e90ff)',
                            height: 8,
                            opacity: 0.3,
                        },
                        handle: {
                            backgroundColor: '#32cd32',
                            borderColor: '#32cd32',
                            color: 'blueviolet',
                            height: 8,
                        }
                    }}
                />
                <div className="d-flex justify-content-between">
                    <span>Première: {group[0]}</span>
                    <span>Dernière: {group[group.length - 1]}</span>
                </div>
            </div>
        ));
    }

    const validationReception = (lotA) => {
        axiosInstance
            .get(`${API_URL}/api/dispatched-lot-attestation/reception/${lotA}`)
            .then(() => {
                window.location.reload();
            })
            .catch((err) => {
                console.error(err);
            });
    };

    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <SideBar/>
                <div className="layout-page">
                    <NavBar/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <div className="row g-6 mb-6">
                                <div className="col-lg-12 col-xxl-12">
                                    <div className="card h-100">
                                        <div className="card-header d-flex align-items-center justify-content-between">
                                            <h5 className="m-0 me-2">
                                                Dispatching du lot
                                                d'attestation {lotAttestation?.typeAttestation || ""} de
                                                <span style={{color: "yellowgreen"}}>
                                                    {lotAttestation?.lot?.[0]} - {lotAttestation?.lot?.[lotAttestation?.lot?.length - 1]}
                                                </span> de {compagnie.raisonSociale}
                                            </h5>
                                            {rightsService("HLA062") && lotAttestation.updatedLot.length !== 0 && (
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={() => setAffectationModalOpen(true)}
                                                >
                                                    Effectuer un dispatch
                                                </button>
                                            )}
                                        </div>
                                        <Spin spinning={getLotLoading}>

                                            <div className="card-body">
                                                <ul className="p-0 m-0">
                                                    {lots.length ? lots.map((l, id) => (
                                                        <Card
                                                            key={id}
                                                            className="mb-4"
                                                            hoverable
                                                            bordered
                                                            bodyStyle={{padding: '16px'}}
                                                            style={{
                                                                borderRadius: '8px',
                                                                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)'
                                                            }}
                                                        >
                                                            <div className="d-flex align-items-center">
                                                                <Progress
                                                                    type="dashboard"
                                                                    percent={((100 * ((l.lot.length - l.updatedLot.length) / l.lot.length)).toFixed(1))}
                                                                    width={60}
                                                                    style={{marginRight: '16px'}}
                                                                />
                                                                <Descriptions column={1} style={{flex: 1}}>
                                                                    <Descriptions.Item label="Plage d'Attestations">
                                                                        <strong
                                                                            className="text-success">{l.lot[0]} - {l.lot[l.lot.length - 1]}</strong>
                                                                    </Descriptions.Item>
                                                                    <Descriptions.Item label="Statut">
                                                                        {l.dateReception ? (
                                                                            <>Livré
                                                                                le {frenchDate(l.createdAt)} à <u>{l.agence.nom}</u></>
                                                                        ) : (
                                                                            <span
                                                                                className="text-warning">Pas encore livré</span>
                                                                        )}
                                                                    </Descriptions.Item>
                                                                </Descriptions>
                                                                <div className="text-end">
                                                                    {l.dateReception ? (
                                                                        <span className="badge bg-label-secondary">
                        {(l.lot.length - l.updatedLot.length)}/{l.lot.length}
                    </span>
                                                                    ) : (
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-secondary"
                                                                            onClick={() => showReceptionAttestationConfirm(l.codeId)}
                                                                        >
                                                                            Valider réception
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </Card>
                                                    )) : (
                                                        <Empty
                                                            description="Aucun dispatching effectué pour l'instant"
                                                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                                                        />
                                                    )}
                                                </ul>
                                            </div>
                                        </Spin>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer/>
                    </div>
                </div>
            </div>

            <Modal
                open={affectationModalOpen}
                loading={loading}
                onOk={onSubmit}
                confirmLoading={loading}
                onCancel={() => setAffectationModalOpen(false)}
                zIndex={1900}
                centered
            >
                <div className="text-center mb-6">
                    <h4 className="address-title mb-2">Nouveau Dispatch d'attestation</h4>
                    <p className="address-subtitle">
                        Affecter un nouveau lot d'attestation à une agence
                    </p>
                </div>
                <form id="addNewAddressForm" className="row g-5">
                    <div className="col-12">
                        <label className="form-label">Veuillez sélectionner l'agence</label>
                        <select
                            className="form-control"
                            value={selectedAgence}
                            onChange={(e) => setSelectedAgence(e.target.value)}
                        >
                            {agences?.map((ag, id) => (
                                <option key={id} value={ag.codeAgence}>{ag.nom}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-12">
                        {renderSliders(
                            groupByContinuity(lotAttestation.updatedLot),
                            firstAttestation,
                            lastAttestation,
                            (index, value) => {
                                setFirstAttestation(value[0]);
                                setLastAttestation(value[1]);
                            }
                        )}
                    </div>
                    <div className="col-12">
                        <label className="form-label">Premier numéro à assigner</label>
                        <Input
                            type="number"
                            value={firstAttestation}
                            min={lotAttestation.updatedLot[0]}
                            max={lotAttestation.updatedLot[lotAttestation.updatedLot.length - 1]}
                            onChange={(e) => {
                                const value = parseInt(e.target.value);
                                setFirstAttestation(value);
                                setLastAttestation(value);
                            }}
                        />
                    </div>

                    <div className="col-12">
                        <label className="form-label">Nombre</label>
                        <Input
                            type="number"
                            value={nombre}
                            min={1}
                            onChange={(e) => {
                                if (e.target.value < 0) {
                                    setNombre(0)
                                } else {
                                    const value = parseInt(e.target.value);
                                    setNombre(value);
                                    setLastAttestation(firstAttestation + value - 1);
                                }

                            }}
                        />
                    </div>
                    <div className="col-12">
                        <label className="form-label">Dernier numéro à assigner</label>
                        <Input
                            type="number"
                            value={lastAttestation}
                            min={firstAttestation + 1}
                            max={lotAttestation.updatedLot[lotAttestation.updatedLot.length - 1]}
                            onChange={(e) => {
                                const value = parseInt(e.target.value);
                                setLastAttestation(value);
                                setNombre(value - firstAttestation + 1);
                            }}
                        />
                    </div>
                </form>
            </Modal>
        </div>
    );
};

export default DetailsLotAttestationsDispatched;
