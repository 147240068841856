import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Modal, Space, Table, message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Footer from "../../../components/Footer";
import NavBar from "../../../components/NavBar";
import SideBar from "../../../components/SideBar";
import { API_URL, TOKEN } from "../../../variables/constants";
import AddMarque from "./AddMarque";

const Marques = () => {
  const [marques, setMarques] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedMarque, setSelectedMarque] = useState(null);

  useEffect(() => {
    fetchMarques();
  }, [showModal]);

  const fetchMarques = () => {
    axios
      .get(`${API_URL}/api/parametre/type/MARQUE`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      })
      .then((res) => {
        console.log("Marques récupérées :", res.data);
        setMarques(res.data);
      })
      .catch((err) => {
        console.log(err);
        message.error("Erreur lors de la récupération des marques.");
      });
  };

  const handleShow = (edit = false, marque = null) => {
    setEditMode(edit);
    setSelectedMarque(marque);
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);

  const showDeleteConfirm = (parametreId) => {
    if (!parametreId) {
      console.error("L'ID de la marque à supprimer est manquant.");
      toast.error("Erreur : ID de la marque manquant.");
      return;
    }

    Modal.confirm({
      title: "Êtes-vous sûr de vouloir supprimer cette marque ?",
      icon: <ExclamationCircleOutlined />,
      content: "Cette action est irréversible",
      okText: "Oui",
      okType: "danger",
      cancelText: "Annuler",
      onOk() {
        deleteMarque(parametreId);
      },
    });
  };

  const deleteMarque = (parametreId) => {
    if (!parametreId) {
      console.error("Impossible de supprimer une marque sans ID.");
      toast.error("Erreur : ID de la marque manquant.");
      return;
    }

    axios
      .delete(`${API_URL}/api/parametre/${parametreId}`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      })
      .then((res) => {
        setMarques((prevMarques) => prevMarques.filter((marque) => marque.parametreId !== parametreId));
        toast.success("Marque supprimée avec succès.");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Erreur lors de la suppression de la marque.");
      });
  };

  const columns = [
    {
      title: "Nom",
      dataIndex: "libelleParametre",
      key: "libelleParametre",
    },
    {
      title: "Catégorie",
      dataIndex: ["categorie", "nom"],
      key: "categorie",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleShow(true, record)}
          >
            Modifier
          </Button>
          <Button
            type="link"
            icon={<DeleteOutlined />}
            danger
            onClick={() => showDeleteConfirm(record.parametreId)} // Utilisation correcte de `parametreId`
          >
            Supprimer
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <SideBar currentMenu="TARIFICATION" />
        <div className="layout-page">
          <NavBar />
          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              <Button
                type="primary"
                icon={<PlusOutlined />}
                style={{ marginBottom: 16 }}
                onClick={() => handleShow()}
              >
                Ajouter une nouvelle marque
              </Button>
              <div className="card">
                <h5 className="card-header">Paramétrage de marques</h5>
                <Table
                  columns={columns}
                  dataSource={marques}
                  rowKey={(record) => record.parametreId} // Assurez-vous que chaque marque a un parametreId unique
                />
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>

      <AddMarque
        show={showModal}
        handleClose={handleClose}
        editMode={editMode}
        selectedMarque={selectedMarque}
      />
    </div>
  );
};

export default Marques;
