import React, {forwardRef, useImperativeHandle} from "react";
import PropTypes from "prop-types";
import {Card, Collapse, Divider, Space, Table, Typography} from "antd";
import {frenchDate} from "../../../../variables/functions";
import {
    BankOutlined,
    CarOutlined,
    DashboardOutlined,
    EnvironmentOutlined,
    EyeOutlined,
    FlagOutlined
} from "@ant-design/icons";
import {toast} from "react-toastify";
import {saveSinistreDeclaration} from "../../../../hooks/useHooks";
import {useNavigate} from "react-router-dom";

const {Title, Text} = Typography;
const {Panel} = Collapse;


const ResumeInformations = forwardRef((props, ref) => {

    const navigate = useNavigate();
    const infoAssure = JSON.parse(sessionStorage.getItem("infoAssur")) || {};
    const infoAdversaire = JSON.parse(sessionStorage.getItem("infoAdvers")) || {items: []};
    const infoAccident = JSON.parse(sessionStorage.getItem("infoAcc")) || {};
    const infoVictimes = JSON.parse(sessionStorage.getItem("infoVict")) || {items: []};

    useImperativeHandle(ref, () => ({
        isValidated: async () => {
            const sinistre = [
                {key: "infoAssure", value: infoAssure},
                {key: "infoAdversaire", value: infoAdversaire},
                {key: "infoVictimes", value: infoVictimes},
                {key: "infoAccident", value: infoAccident}
            ];

            console.log("Sinistre Data:", sinistre);

            const isValid = sinistre.every(
                (item) => item.value && Object.keys(item.value).length > 0
            );

            return isValid;
        },
        sendState: async () => {

            if (infoAssure === {} || infoAccident === {}) {
                toast.error('Les informations du sinistre sont incompletes', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }

            const sinisterDTO = {
                lieu: infoAccident.lieu,
                provenanceAssure: infoAccident.provenanceAssure,
                destinationAssure: infoAccident.destinationAssure,
                vitesseAssure: infoAccident.vitesseAssure,
                temoin: infoAccident.temoin,
                circonstance: infoAccident.circonstance,
                croquis: infoAccident.croquis,
                observationAgent: infoAccident.observationAgent,
                entiteConstat: infoAccident.entiteConstat.entite,
                contrat: infoAssure.contrat,
                engin: infoAssure.engin,
                typeSinistre: infoAssure.typeSinistre,
                dateSinistre: infoAssure.dateSinistre,
                conducteur: infoAssure.conduisait === false ? infoAssure.conducteur : null,
                adversaires: infoAdversaire.items,
                victimes: infoVictimes.items,
            }
            try {
                await saveSinistreDeclaration(sinisterDTO)
                toast.success('Enregistrement de la declaration reussie')
                sessionStorage.removeItem('infoAssur')
                sessionStorage.removeItem('infoAdvers')
                sessionStorage.removeItem('infoAcc')
                sessionStorage.removeItem('infoVict')
                navigate('liste-des-sinistres')
            } catch (e) {
                toast.error(e.message)
            }
            console.log("Sending Sinistre Data to Backend:", sinisterDTO);
        }
    }));

    const adversaireVehicleColumns = [
        {title: "Marque", dataIndex: ["engin", "marque"], key: "marque"},
        {title: "Puissance", dataIndex: ["engin", "puissance"], key: "puissance"},
        {title: "Immatriculation", dataIndex: ["engin", "immatriculation"], key: "immatriculation"},
        {title: "Usage", dataIndex: ["engin", "usage"], key: "usage"},
    ];
    console.log(infoAdversaire)
    return (
        <div className="grid-container grid container" style={{justifyContent: "center", gap: "20px"}}>
            {/* Assure Information */}
            <Card bordered title="Informations de l'Assuré" style={{width: "100%"}}>
                <div>
                    <Text strong>Numéro Police:</Text>
                    <Text>{infoAssure?.contrat?.numeroPoliceP || "--"}</Text>
                </div>
                <div>
                    <Text strong>Nom et Prénom(s):</Text>{" "}
                    <Text>
                        {infoAssure?.contrat?.assure?.nom || "--"} {infoAssure?.contrat?.assure?.prenom || ""}
                    </Text>
                </div>
                <div>
                    <Text strong>Date de survenance du sinistre:</Text>{" "}
                    <Text>{frenchDate(infoAssure?.dateSinistre) || "--"}</Text>
                </div>
            </Card>

            {/* Adversaire Information */}
            <Card bordered title="Informations des Adversaires" style={{width: "100%"}}>
                <Collapse>
                    {infoAdversaire.items.map((adversaire, index) => (
                        <Panel header={`Adversaire ${index + 1}`} key={index}>

                            <div>
                                {
                                    adversaire.conduisait === 'oui' ? (
                                        <>
                                            <div>
                                                <Text strong>Nom:</Text> <Text>{adversaire.nom || "--"}</Text>
                                            </div>
                                            <div>
                                                <Text strong>Prénom(s):</Text> <Text>{adversaire.prenom || "--"}</Text>
                                            </div>
                                            <Text> L'assuré conduisait le véhicule </Text>
                                        </>
                                    ) : (
                                        <>
                                            <div>
                                                <Text strong>Nom:</Text> <Text>{adversaire.nom || "--"}</Text>
                                            </div>
                                            <div>
                                                <Text strong>Prénom(s):</Text> <Text>{adversaire.prenom || "--"}</Text>
                                            </div>
                                            <Text> {adversaire?.conducteur.nom} {adversaire?.conducteur.prenom} conduisait
                                                a la place de l'assuré </Text>
                                        </>
                                    )
                                }
                            </div>
                            <div style={{marginTop: "10px"}}>
                                <Divider>Informations sur l'engin</Divider>
                                <Table
                                    columns={adversaireVehicleColumns}
                                    dataSource={[adversaire]}
                                    pagination={false}
                                    locale={{emptyText: "-"}}
                                />
                            </div>
                        </Panel>
                    ))}
                </Collapse>
            </Card>

            {/* Accident Information */}
            <Card
                bordered
                title="Détails de l'Accident"
                style={{
                    width: "100%",
                    borderRadius: "8px",
                    backgroundColor: "#fafafa",
                }}
                headStyle={{
                    fontWeight: "bold",
                    fontSize: "16px",
                }}
            >
                <Space direction="vertical" size="middle" style={{width: "100%"}}>
                    <Space size="small">
                        <EnvironmentOutlined style={{fontSize: "18px", color: "#1890ff"}}/>
                        <Text strong>Lieu:</Text>
                        <Text>{infoAccident.lieu || "--"}</Text>
                    </Space>
                    <Space size="small">
                        <CarOutlined style={{fontSize: "18px", color: "#52c41a"}}/>
                        <Text strong>Provenance de l'Assuré:</Text>
                        <Text>{infoAccident.provenanceAssure || "--"}</Text>
                    </Space>
                    <Space size="small">
                        <FlagOutlined style={{fontSize: "18px", color: "#faad14"}}/>
                        <Text strong>Destination de l'Assuré:</Text>
                        <Text>{infoAccident.destinationAssure || "--"}</Text>
                    </Space>
                    <Space size="small">
                        <DashboardOutlined style={{fontSize: "18px", color: "#13c2c2"}}/>
                        <Text strong>Vitesse de l'Assuré:</Text>
                        <Text>{infoAccident.vitesseAssure || "--"} km/h</Text>
                    </Space>
                    <Space size="small">
                        <EyeOutlined style={{fontSize: "18px", color: "#eb2f96"}}/>
                        <Text strong>Observation de l'agent:</Text>
                        <Text>{infoAccident.observationAgent || "--"}</Text>
                    </Space>
                    <Space size="small">
                        <BankOutlined style={{fontSize: "18px", color: "#fa541c"}}/>
                        <Text strong>Entité constat:</Text>
                        <Text>{infoAccident.entiteConstat?.label || "--"}</Text>
                    </Space>
                </Space>
            </Card>
            {/* Victim Information */}
            <Card bordered title="Informations des Victimes" style={{width: "100%"}}>
                <Collapse>
                    {infoVictimes.items.map((victim, index) => (
                        <Panel header={`Victime ${index + 1}`} key={index}>
                            <div>
                                <Text strong>Nom:</Text> <Text>{victim.nom || "--"}</Text>
                            </div>
                            <div>
                                <Text strong>Prénom(s):</Text> <Text>{victim.prenom || "--"}</Text>
                            </div>
                            <div>
                                <Text strong>Profession:</Text> <Text>{victim.profession || "--"}</Text>
                            </div>
                            <div>
                                <Text strong>Adresse:</Text> <Text>{victim.adresse || "--"}</Text>
                            </div>
                            <div>
                                <Text strong>Description des dégâts:</Text>
                                <Text>{victim.descriptionDegatsVictime || "--"}</Text>
                            </div>
                        </Panel>
                    ))}
                </Collapse>
            </Card>
        </div>
    );
});

ResumeInformations.displayName = "ResumeInformations";

ResumeInformations.propTypes = {
    allStates: PropTypes.object,
};

export default ResumeInformations;
