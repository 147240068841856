import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { API_URL, TOKEN } from "../../../variables/constants";
import { toast } from "react-toastify";

const AddMarque = ({ show, handleClose, editMode, selectedMarque }) => {
  const [loading, setLoading] = useState(false);
  const [nom, setNom] = useState("");
  const [selectedCategorie, setSelectedCategorie] = useState("");
  const [categories, setCategories] = useState([]);

  // Charger les catégories et initialiser le formulaire en mode édition
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const res = await axios.get(`${API_URL}/api/categorie`, {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
          },
        });
        setCategories(res.data);
      } catch (err) {
        console.log(err);
        toast.error("Erreur lors de la récupération des catégories.");
      }
    };

    fetchCategories();

    if (editMode && selectedMarque) {
      setNom(selectedMarque.libelleParametre || "");
      setSelectedCategorie(selectedMarque.categorie?.categorieId || "");
    } else {
      setNom("");
      setSelectedCategorie("");
    }
  }, [show, editMode, selectedMarque]);

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const categorie = categories.find(
      (cat) => cat.categorieId === parseInt(selectedCategorie),
    );
    const marqueData = {
      ...selectedMarque,
      libelleParametre: nom,
      typeParametre: "MARQUE",
      categorie,
    };

    try {
      const response = editMode
        ? await axios.put(`${API_URL}/api/parametre`, marqueData, {
            headers: {
              Authorization: `Bearer ${TOKEN}`,
              "Content-Type": "application/json",
            },
          })
        : await axios.post(`${API_URL}/api/parametre`, marqueData, {
            headers: {
              Authorization: `Bearer ${TOKEN}`,
              "Content-Type": "application/json",
            },
          });

      toast.success(
        editMode ? "Marque modifiée avec succès" : "Marque ajoutée avec succès",
      );
      handleClose();
    } catch (err) {
      console.error(err);
      toast.error("Erreur lors de la sauvegarde de la marque.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Body>
        <button
          type="button"
          className="btn-close btn-primary"
          onClick={handleClose}
          aria-label="Close"
        ></button>
        <div className="text-center mb-4">
          <h4 className="address-title mb-2">
            {editMode ? "Modifier la" : "Ajouter une"} marque
          </h4>
          <p className="address-subtitle">
            {editMode ? "Modifier la" : "Ajouter une nouvelle"} marque
          </p>
        </div>
        <form className="row g-3" onSubmit={onSubmit}>
          <div className="col-12">
            <label className="form-label" htmlFor="marqueName">
              Nom
            </label>
            <input
              type="text"
              id="marqueName"
              onChange={(e) => setNom(e.target.value)}
              className="form-control"
              placeholder="Nom de la marque"
              name="nom"
              value={nom}
              required
            />
          </div>
          <div className="col-12">
            <label className="form-label" htmlFor="marqueCategorie">
              Catégorie
            </label>
            <select
              id="marqueCategorie"
              className="form-select"
              onChange={(e) => setSelectedCategorie(e.target.value)}
              value={selectedCategorie}
              required
            >
              <option value="">Sélectionner la catégorie</option>
              {categories.map((cat) => (
                <option key={cat.categorieId} value={cat.categorieId}>
                  {cat.nom}
                </option>
              ))}
            </select>
          </div>
          <div className="col-12 text-center">
            <button
              type="submit"
              className="btn btn-primary me-3"
              disabled={loading}
            >
              {loading ? (
                <>
                  <span
                    className="spinner-grow spinner-grow-sm me-1"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Enregistrement...
                </>
              ) : (
                "Enregistrer"
              )}
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleClose}
            >
              Annuler
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddMarque;
