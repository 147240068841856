import { Modal, Select, Tag } from "antd";
import React, { useEffect, useState } from "react";

import axios from "axios";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { API_URL, LOGGED_USER, TOKEN } from "../../../../variables/constants";
import axiosInstance from "../../../../variables/api/axiosInstance";
import dayjs from "dayjs";

const Prorogation = (props) => {
  const [modalProrogation, setModalProrogation] = useState(props.open);
  const [loading, setLoading] = useState(false);
  const [contrat, setContrat] = useState();
  const [dateEffet, setDateEffet] = useState();
  const [dateEcheance, setDateEcheance] = useState();
  const [prorogationTime, setProrogationTime] = useState();
  const [fractionnements, setFractionnements] = useState([]);

  useEffect(() => {
    setModalProrogation(props.open);
    setDateEffet(dayjs(props?.contrat?.dateEcheance).add(1, "day"));
    setContrat(props.contrat);
  }, [props.open, props.contrat]);

  const param = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (param.contratId) {
      axios
        .get(
          `${API_URL}/api/taux-fractionnement/fractionnements/codeId/${param.contratId}/1`,
          {
            headers: {
              Authorization: `Bearer ${TOKEN}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
              "Access-Control-Allow-Headers":
                "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
            },
          },
        )
        .then((response) => {
          // console.log(response.data);
          setFractionnements(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [TOKEN, param.id]);

  const handleProrogationSubmit = () => {
    if (contrat?.fractionnement?.nombre === 1) {
      setModalProrogation(false);
    } else {
      setLoading(true);
      contrat.dateEffet = dateEffet.toISOString();
      contrat.dateEcheance = dateEcheance;
      contrat?.fractionnement?.nombre > 1 &&
        axiosInstance
          .put(
            contrat?.engins[0]?.categorie?.codeCategorie?.startsWith("VT")
              ? `/api/contrat/prorogation/vt/${prorogationTime}`
              : `/api/contrat/prorogation/${LOGGED_USER.codeId}`,
            JSON.stringify(contrat),
          )
          .then((res) => {
            toast("Prorogation effectuée!");
            navigate(`/details-contrat/${res.data.codeId}`, { replace: true });
            setLoading(false);
            setModalProrogation(false);
            props.update(false);
            window.location.reload();
          })
          .catch((err) => {
            console.log(err);
            toast("Erreur lors de la prorogation");
          });
    }
  };

  const showDateWithTime = (date) => {
    var d = date?.split("T")[0];
    var t = date?.split("T")[1];
    return `${d} - ${t}`;
  };
  const handleCancel = () => {
    setModalProrogation(false);
    props.update(false);
  };

  return (
    <Modal
      title="PROROGATION"
      centered
      confirmLoading={loading}
      open={modalProrogation}
      onOk={() => handleProrogationSubmit()}
      onCancel={() => handleCancel()}
      width={900}
      zIndex={1500}
    >
      <div className="row" justify="center">
        <div className="col-12">
          Date Effet:{" "}
          <Tag color="blue">
            <b>{showDateWithTime(contrat?.dateEffet)}</b>
          </Tag>
        </div>
        <br />
        <br />
        <div className="col-12">
          Date Echéance actuelle:{" "}
          <Tag color="blue">
            <b>{showDateWithTime(contrat?.dateEcheance)}</b>
          </Tag>
        </div>
        <br />
        <br />
        {contrat?.fractionnement?.nombre === 1 ? (
          <div> Pas de prorogation possible! </div>
        ) : (
          <>
            <div className="col-12">
              <form>
                <label htmlFor="prorogationTime" className="form-label">
                  Temps de prorogation
                </label>
                <Select
                  value={prorogationTime}
                  onChange={(value) => {
                    setProrogationTime(value);
                    var date = new Date(contrat?.dateEcheance);
                    date.setMonth(
                      date.getMonth() + contrat.fractionnement.moisMin,
                    );
                    date.setHours(23, 59, 59, 0);
                    setDateEcheance(date.toISOString().split(".")[0]);
                  }}
                  options={
                    fractionnements
                      ? fractionnements?.map((fract, index) => ({
                          label: `${fract.moisMin} ${fract.periode === "JOUR" ? "JOURS" : fract.periode}`,
                          value:
                            contrat?.engins[0]?.categorie?.codeCategorie?.startsWith(
                              "VT",
                            )
                              ? fract?.moisMin
                              : fract.taux,
                          key: index,
                        }))
                      : []
                  }
                  style={{ width: "100%" }}
                />
              </form>
            </div>
            <br />
            <br />
            <br />
            <div className="col-12">
              <div>
                Nouvelle Date Effet:{" "}
                <Tag color="green">
                  <b>{dateEffet?.format("YYYY-MM-DD")}</b>
                </Tag>
              </div>
            </div>
            <div className="col-12">
              <div>
                Nouvelle Date Echéance:{" "}
                <Tag color="green">
                  {/* <b>{showDateWithTime(dateEcheance)}</b> */}
                  <b>{dateEcheance && showDateWithTime(dateEcheance)}</b>
                </Tag>
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

Prorogation.propTypes = {
  open: PropTypes.bool,
  update: PropTypes.func,
  contrat: PropTypes.object,
};

export default Prorogation;
