import React, { useEffect, useState } from "react";
import { API_URL, TOKEN } from "../../../variables/constants";
import axios from "axios";
import { toast } from "react-toastify";
import { Select } from "antd";

const AddTaxe = ({ show, handleClose, editMode, codeId, selectedTaxe }) => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  const [pourcentage, setPourcentage] = useState("");
  const [nom, setNom] = useState("");
  const [description, setDescription] = useState("");
  const [garanties, setGaranties] = useState([]);
  const [selectedGaranties, setSelectGaranties] = useState([]);
  const [selectedCategorie, setSelectedCategorie] = useState(0);

  // Chargement des valeurs en mode édition
  useEffect(() => {
    if (editMode && selectedTaxe) {
      
      setNom(selectedTaxe.libelleTaxe);
      setPourcentage(selectedTaxe.valeur);
      setDescription(selectedTaxe.description);
      setSelectGaranties(selectedTaxe.garanties.map((gar) => gar.codeId));
    }
  }, [editMode, selectedTaxe]);

  useEffect(() => {
    axios
      .get(`${API_URL}/api/categorie`, {
        headers: { Authorization: `Bearer ${TOKEN}` },
      })
      .then((res) => setCategories(res.data))
      .catch((err) => console.log(err));

    axios
      .get(`${API_URL}/api/garantie`, {
        headers: { Authorization: `Bearer ${TOKEN}` },
      })
      .then((res) => setGaranties(res.data))
      .catch((err) => console.log(err));
  }, []);

  const handleChange = (value) => {
    setSelectGaranties(value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const selectedGarantiesObjects = selectedGaranties.map((id) =>
      garanties.find((gar) => gar.codeId === id)
    );

    const tax = {
      ...selectedTaxe,
      libelleTaxe: nom,
      description,
      valeur: pourcentage,
      garanties: selectedGarantiesObjects,
    };

    console.log("test", selectedTaxe);

    console.log("fet",tax);
    


    const request = editMode
      ? axios.put(`${API_URL}/api/taxe`, tax, {
        headers: { Authorization: `Bearer ${TOKEN}`, "Content-Type": "application/json" },
      })
      : axios.post(`${API_URL}/api/taxe`, tax, {
        headers: { Authorization: `Bearer ${TOKEN}`, "Content-Type": "application/json" },
      });

    request
      .then(() => {
        toast(editMode ? "Taxe modifiée avec succès" : "Taxe ajoutée avec succès");
        toast("Taxe ajoutée avec succès");
        toast("Taxe modifiée avec succès")
        handleClose();
        window.location.reload();
        
        setLoading(false);
        window.location.reload();
      })
      .catch(() => {
        toast(editMode ? "Erreur lors de la modification" : "Erreur lors de l'ajout");
        setLoading(false);
      });
  };

  return (
    <div size="md" centered>
      <div className="text-center mb-6">
        <h4 className="address-title mb-2">
          {editMode ? "Modifier la" : "Ajouter une"} taxe
        </h4>
        <p className="address-subtitle">
          {editMode ? "Modifier la" : "Ajouter une nouvelle"} taxe
        </p>
      </div>
      <form id="addNewAddressForm" className="row g-6" onSubmit={onSubmit}>
        <div className="col-12 col-md-12">
          <label className="form-label" htmlFor="modalAddressFirstName">
            Nom
          </label>
          <input
            type="text"
            id="modalAddressFirstName"
            onChange={(e) => setNom(e.target.value)}
            className="form-control"
            placeholder="Nom de la taxe"
            name="nom"
            value={nom}
          />
        </div>
        <div className="col-12 col-md-12">
          <label className="form-label" htmlFor="modalAddressFirstName">
            Pourcentage
          </label>
          <input
            type="number"
            id="modalAddressFirstName"
            onChange={(e) => setPourcentage(e.target.value)}
            className="form-control"
            placeholder="Valeur en pourcentage de la taxe"
            name="pourcentage"
            value={pourcentage}
          />
        </div>

        <div className="col-12">
          <label className="form-label" htmlFor="modalAddressFirstName">
            Garanties appliquées
          </label>
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            placeholder="Sélectionnez les garanties"
            onChange={handleChange}
            value={selectedGaranties}
            options={garanties.map((gar) => ({
              label: `${gar.nom} - ${gar?.categorie?.nom}`,
              value: gar.codeId,
            }))}
          />
        </div>
        <div className="col-12 col-md-12">
          <label className="form-label" htmlFor="modalAddressFirstName">
            Description
          </label>
          <textarea
            type="text"
            id="modalAddressFirstName"
            onChange={(e) => setDescription(e.target.value)}
            className="form-control"
            placeholder="Description de la garantie"
            name="description"
            value={description}
          ></textarea>
        </div>
        <div className="col-12 text-center">
          <button
            type="submit"
            className="btn btn-primary me-3"
            disabled={loading}
          >
            {loading && (
              <span
                className="spinner-grow me-1"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            {loading ? `Enregistrement...` : `Enregistrer`}
          </button>
          <button
            type="reset"
            className="btn btn-label-secondary"
            onClick={handleClose}
          >
            Annuler
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddTaxe;