import React, { useEffect, useState } from 'react';

import { UploadOutlined } from '@ant-design/icons';
import { Button, Select, Upload } from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import image from "../../assets/img/image.png";
import poolins from "../../assets/img/poolins.jpg";
import "../../assets/styles/auth.css";
import { API_URL } from '../../variables/constants';

const Register = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [villes, setVilles] = useState([]);
  const [assurances, setAssurances] = useState([]);
  const [formData, setFormData] = useState({
    raisonSociale: "",
    siegeSocial: "",
    ville: "",
    email: "",
    nif: "",
    nomResponsable: "",
    prenomResponsable: "",
    emailResponsable: "",
    genreResponsable: "MASCULIN",
    adresseResponsable: "",
    telephoneResponsable: "",
    typePieceResponsable: "CNI",
    numIdentiteResponsable: "",
    dateDelivranceResponsable: "",
    lieuDelivranceResponsable: "",
    telephone1: "",
    telephone2: "",
    active: "",
    agrements: [],
  });
  const [errors, setErrors] = useState({});
  const [selectedCompagnies, setSelectedCompagnies] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const [agreements, setAgreements] = useState([]);

  const navigate = useNavigate();

  const onChangeFileSelect = (e, code) => {
    console.log("FileSelected: ", e);
    // let agr = [...agreements];
    // let fa = agr.findIndex(ag => ag.company.code === code);
    // const file = e.fileList;
    // file[0].status = "done";
    // console.log("faaaaa:", fa);
    // console.log("Agr: ", agr);

    // agr[fa].document = file.objOriginFile;
    // // setFileList(newFileList);
    // setAgreements(agr);
  };

  const onChangeBeforeUploadFileSelect = (e, code) => {
    console.log("FileSelecteddddd: ", e);
    let agr = [...agreements];
    let fa = agr.findIndex(ag => ag.company.code === code);
    e.status = "done";
    console.log("faaaaa:", fa);
    console.log("Agr: ", agr);

    agr[fa].document = e;
    // setFileList(newFileList);
    setAgreements(agr);
  };


  useEffect(() => {
    axios
      .get(`${API_URL}/api/parametre/villes`, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
          "Access-Control-Allow-Headers":
            "Content-Type, Access-Control-Allow-Headers, X-Requested-With",
        },
      })
      .then((res) => {
        setVilles(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`${API_URL}/api/organisme/assurance`, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
          "Access-Control-Allow-Headers":
            "Content-Type, Access-Control-Allow-Headers, X-Requested-With",
        },
      })
      .then((res) => {
        setAssurances(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleSelectCompagniesChange = (value) => {
    console.log("Value: ", value);
    let l = value.length;
    let agr = [...agreements];
    let agrs = [];
    value.forEach(element => {
      agrs.push({
        company: JSON.parse(element),
        document: []
      });
    });

    setAgreements(agrs);
    setSelectedCompagnies(value);
  }

  const validateForm = () => {
    const newErrors = {};
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^(22|90|91|92|93|96|98|99|70|71|79)\d{6}$/;

    if (!formData.raisonSociale) newErrors.raisonSociale = "La raison sociale est requise";
    if (formData.raisonSociale.length < 3) newErrors.raisonSociale = "La raison sociale est trop courte";
    if (!formData.siegeSocial) newErrors.siegeSocial = "Le siège sociale est requis";
    if (formData.siegeSocial.length < 3) newErrors.siegeSocial = "Le siège sociale est trop court";
    if (!formData.email) newErrors.email = "L'adresse mail du cabinet est requise";
    if (!emailPattern.test(formData.email)) newErrors.email = "L'adresse mail n'est pas valide!";
    if (!phonePattern.test(formData.telephone1)) newErrors.telephone1 = "Le numéro de téléphone n'est pas valide!";
    if (formData.nif && formData.nif.length < 5) newErrors.nif = "Le NIF n'est pas valide!";
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  }

  const validateUserForm = () => {
    const newErrors = {};
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^(22|90|91|92|93|96|98|99|70|71|79)\d{6}$/;
    const cniPattern = /^\d{0,4}-?\d{0,3}-?\d{0,3}$/;
    const passportPattern = /^[A-Za-z]{0,2}\d*$/;

    if (!formData.nomResponsable) newErrors.nomResponsable = "Le nom est requise";
    if (formData.nomResponsable.length < 3) newErrors.nomResponsable = "Le nom est trop court";
    if (!formData.prenomResponsable) newErrors.prenomResponsable = "Le prénom est requis";
    if (formData.prenomResponsable.length < 3) newErrors.prenomResponsable = "Le prénom est trop court";
    if (!formData.emailResponsable) newErrors.emailResponsable = "Votre adresse mail est requise";
    if (!emailPattern.test(formData.emailResponsable)) newErrors.emailResponsable = "L'adresse mail n'est pas valide!";
    if (!phonePattern.test(formData.telephoneResponsable)) newErrors.telephoneResponsable = "Le numéro de téléphone n'est pas valide!";
    // if (!formData.numIdentiteResponsable) newErrors.numIdentiteResponsable = "Votre numéro de pièce d'identité est requis";
    if (formData.typePieceResponsable === "CNI" && !cniPattern.test(formData.numIdentiteResponsable)) newErrors.numIdentiteResponsable = "Le format de la CNI est invalide";
    if (formData.typePieceResponsable === "PASSEPORT" && !passportPattern.test(formData.numIdentiteResponsable)) newErrors.numIdentiteResponsable = "Le format du passeport est invalide";

    console.log("fddd", formData);

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  }

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    console.log(formData);
    console.log(agreements);
    if (selectedCompagnies.length) {
      let verif = true;
      agreements.forEach((ag) => {
        if (!ag.document) {
          verif = false;
        }
      });
      if (verif) {
        setLoading(true);
        console.log("Nice");
        // let agrees = [];
        // agreements.forEach((ag, id) => {
        //   const data = new FormData();
        //   let extension = ag.document.name.substring(ag.document.name.lastIndexOf(".") + 1, ag.document.name.length);
        //   let filename = `Agreement_${ag.company.code}_${formData.raisonSociale.split(" ").join("_")}.${extension}`;
        //   // console.log("Typeof: ");
        //   console.log(typeof ag.document);
        //   data.append(`fichier`, ag.document, filename);

        //   agrees.push({
        //     compagnie: ag.company.code,
        //     document: data
        //   });
        // });

        const data = new FormData();

        agreements.forEach((ag, id) => {
          let extension = ag.document.name.substring(ag.document.name.lastIndexOf(".") + 1, ag.document.name.length);
          let filename = `Agreement_${ag.company.code}_${formData.raisonSociale.split(" ").join("_")}.${extension}`;
          // ag.document.name = filename;
          data.append('fichier', ag.document, filename);
          data.append('compagnies', ag.company.code);
        });

        axios
          .post(`${API_URL}/api/agreement/all`, data, {
            headers: {
              "Content-Type": "multipart/form-data",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods":
                "GET, PUT, POST, DELETE, OPTIONS",
              "Access-Control-Allow-Headers":
                "Content-Type, Access-Control-Allow-Headers, X-Requested-With",
            },
          }).then(res => {
            console.log(res.data);
            const courtier = {
              raisonSociale: formData.raisonSociale,
              siegeSocial: formData.siegeSocial,
              ville: formData.ville,
              email: formData.email,
              nif: formData.nif,
              nomResponsable: formData.nomResponsable,
              prenomResponsable: formData.prenomResponsable,
              emailResponsable: formData.emailResponsable,
              genreResponsable: formData.genreResponsable,
              adresseResponsable: formData.adresseResponsable,
              telephoneResponsable: [formData.telephoneResponsable],
              typePieceResponsable: formData.typePieceResponsable,
              numIdentiteResponsable: formData.numIdentiteResponsable,
              dateDelivranceResponsable: formData.dateDelivranceResponsable,
              lieuDelivranceResponsable: formData.lieuDelivranceResponsable,
              contact: formData.telephone2 ? [formData.telephone1, formData.telephone2] : [formData.telephone1],
              agreements: res.data,
            }

            console.log("Courtier: ", courtier);
            axios
              .post(`${API_URL}/api/agence/courtier`, JSON.stringify(courtier), {
                headers: {
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods":
                    "GET, PUT, POST, DELETE, OPTIONS",
                  "Access-Control-Allow-Headers":
                    "Content-Type, Access-Control-Allow-Headers, X-Requested-With",
                },
              })
              .then((resp) => {
                console.log(resp.data);
                toast("Enregistrement effectué avec succès!");
                setLoading(false);
                navigate("/waiting");
                // window.location.reload();
              })
              .catch((err) => {
                console.log(err);
                toast("Erreur lors de l'enregistrement'");
                setLoading(false);
              });

          }).catch(err => {
            console.log(err);
            setLoading(false);
          });

      } else {
        setLoading(false);
        toast("Veuillez joindre tous vos agréements");
        setLoading(false);
      }
    } else {
      toast("Veuillez sélectionner les compagnies avec lesquelles vous avez des agréements");
      setLoading(false);
    }

    // setLoading(true);
  }

  return (
    <div class="authentication-wrapper authentication-cover">
      {/* <!-- Logo --> */}
      <a href="/" class="app-brand auth-cover-brand gap-2">

        <span class="app-brand-text demo text-heading fw-bold">
          <img src={poolins} alt="multi-steps" width="180" />
        </span>
      </a>
      {/* <!-- /Logo --> */}
      <div class="authentication-inner row m-0">
        {/* <!-- Left Text --> */}
        <div class="d-none d-lg-flex col-lg-4 align-items-center justify-content-end p-5 pe-0">
          <div class="w-px-400">
            <img src={image} class="img-fluid" alt="multi-steps" width="600" />
          </div>
        </div>
        {/* <!-- /Left Text --> */}

        {/* <!--  Multi Steps Registration --> */}
        <div class="d-flex col-lg-8 align-items-center justify-content-center authentication-bg p-5">
          <div class="w-px-700">
            <div id="multiStepsValidation" class="bs-stepper border-none shadow-none mt-5">
              <div class="bs-stepper-header border-none pt-12 px-0">
                <div class={activeTab === 1 ? "step active" : "step"} data-target="#accountDetailsValidation">
                  <button type="button" class="step-trigger"
                    onClick={() => setActiveTab(1)}>
                    <span class="bs-stepper-circle"><i class="bx bx-home"></i></span>
                    <span class="bs-stepper-label">
                      <span class="bs-stepper-title">Informations</span>
                      <span class="bs-stepper-subtitle">Détails sur le cabinet de courtage</span>
                    </span>
                  </button>
                </div>
                <div class="line">
                  <i class="bx bx-chevron-right"></i>
                </div>
                <div class={activeTab === 2 ? "step active" : "step"} data-target="#personalInfoValidation">
                  <button type="button" class="step-trigger"
                    onClick={() => validateForm() && setActiveTab(2)}>
                    <span class="bs-stepper-circle"><i class="bx bx-user"></i></span>
                    <span class="bs-stepper-label">
                      <span class="bs-stepper-title">Personne</span>
                      <span class="bs-stepper-subtitle">Information sur l'utilisateur</span>
                    </span>
                  </button>
                </div>
                <div class="line">
                  <i class="bx bx-chevron-right"></i>
                </div>
                <div class={activeTab === 3 ? "step active" : "step"} data-target="#billingLinksValidation">
                  <button type="button" class="step-trigger"
                    onClick={() => validateUserForm() && setActiveTab(3)}>
                    <span class="bs-stepper-circle"><i class="bx bx-detail"></i></span>
                    <span class="bs-stepper-label">
                      <span class="bs-stepper-title">Agréements</span>
                      <span class="bs-stepper-subtitle">Précisez les agréements</span>
                    </span>
                  </button>
                </div>
              </div>
              <div class="bs-stepper-content px-0">
                <form>
                  {/* <!-- Account Details --> */}
                  {activeTab === 1 &&
                    <div id="accountDetailsValidation"
                      className={
                        activeTab === 1 ? "content active" : "content"
                      }
                    >
                      <div class="content-header mb-6">
                        <h4 class="mb-0">Informations sur le cabinet</h4>
                        <p class="mb-0">Entrez les informations détails du cabinet de courtage</p>
                      </div>
                      <div class="row g-6">
                        <div class="col-sm-6">
                          <label class="form-label" for="raisonSociale">Raison sociale</label>
                          <input type="text" name="raisonSociale" onChange={handleInputChange} id="raisonSociale" value={formData.raisonSociale} class={`form-control ${errors.raisonSociale ? "is-invalid" : ""}`} placeholder="Nom du cabinet" />
                          {errors.raisonSociale && (
                            <div className="invalid-feedback">{errors.raisonSociale}</div>
                          )}
                        </div>
                        <div class="col-sm-6">
                          <label class="form-label" for="siegeSocial">Siège social</label>
                          <input type="text" name="siegeSocial" id="siegeSocial" onChange={handleInputChange} value={formData.siegeSocial} class={`form-control ${errors.siegeSocial ? "is-invalid" : ""}`} placeholder="Siège social du cabinet" />
                          {errors.siegeSocial && (
                            <div className="invalid-feedback">{errors.siegeSocial}</div>
                          )}
                        </div>
                        <div class="col-12 col-md-6">
                          <label class="form-label" for="ville">
                            Ville{" "}
                          </label>
                          <select
                            id="ville"
                            name="ville"
                            class="form-control form-select"
                            value={formData.ville}
                            placeholder="Sélectionnez la ville du cabinet"
                            onChange={handleInputChange}
                          >
                            {villes?.map((vil, id) => (
                              <option key={id} value={vil.libelleParametre}>
                                {vil.libelleParametre}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div class="col-sm-6">
                          <label class="form-label" for="email">Email du cabinet</label>
                          <input type="email" name="email" id="email" onChange={handleInputChange} value={formData.email} class={`form-control ${errors.email ? "is-invalid" : ""}`} placeholder="Entrez l'adresse mail du cabinet" aria-label="email" />
                          {errors.email && (
                            <div className="invalid-feedback">{errors.email}</div>
                          )}
                        </div>

                        <div class="col-12 col-md-6">
                          <label class="form-label" for="telephone1Agence">
                            Numéro de téléphone
                          </label>
                          <div class="input-group">
                            <span class="input-group-text">TG (+228)</span>
                            <input
                              type="tel"
                              id="telephone1"
                              name="telephone1"
                              onChange={handleInputChange}
                              class={`form-control phone-number-mask ${errors.telephone1 ? "is-invalid" : ""}`}
                              placeholder="00 00 00 00"
                              value={formData.telephone1}
                            />
                            {errors.telephone1 && (
                              <div className="invalid-feedback">{errors.telephone1}</div>
                            )}
                          </div>
                        </div>
                        <div class="col-12 col-md-6">
                          <label class="form-label" for="nif">
                            NIF
                          </label>
                          <input
                            type="number"
                            id="nif"
                            name="nif"
                            class={`form-control ${errors.nif ? "is-invalid" : ""}`}
                            placeholder="Entrez, si vous en disposez, votre NIF"
                            value={formData.nif}
                            onChange={handleInputChange}
                          />
                          {errors.nif && (
                            <div className="invalid-feedback">{errors.nif}</div>
                          )}
                        </div>

                        <div class="col-12 d-flex justify-content-between">
                          <div></div>
                          <button class="btn btn-primary" type="button"
                            onClick={() => {
                              validateForm() && setActiveTab(2);
                            }}
                          > <span class="align-middle d-sm-inline-block d-none me-sm-2 me-0">Suivant</span> <i class="bx bx-right-arrow-alt bx-sm me-sm-n2"></i>
                          </button>
                        </div>
                      </div>
                    </div>}
                  {/* <!-- Personal Info --> */}
                  {activeTab === 2 && <div id="personalInfoValidation"
                    className={
                      activeTab === 2 ? "content active" : "content"
                    }
                  >
                    <div class="content-header mb-6">
                      <h4 class="mb-0">Informations Personnelles</h4>
                      <p class="mb-0">Entrez vos informations personnelles</p>
                    </div>
                    <div class="row g-6">
                      <div class="col-sm-6">
                        <label class="form-label" for="nomResponsable">Nom</label>
                        <input type="text" id="nomResponsable" name="nomResponsable" onChange={handleInputChange} class={`form-control ${errors.nomResponsable ? "is-invalid" : ""}`} placeholder="Entrez votre nom" value={formData.nomResponsable} />
                        {errors.nomResponsable && (
                          <div className="invalid-feedback">{errors.nomResponsable}</div>
                        )}
                      </div>
                      <div class="col-sm-6">
                        <label class="form-label" for="prenomResponsable">Prénom(s)</label>
                        <input type="text" id="prenomResponsable" name="prenomResponsable" onChange={handleInputChange} class={`form-control ${errors.prenomResponsable ? "is-invalid" : ""}`} placeholder="Entrez votre prénom" value={formData.prenomResponsable} />
                        {errors.prenomResponsable && (
                          <div className="invalid-feedback">{errors.prenomResponsable}</div>
                        )}
                      </div>
                      <div class="col-sm-6">
                        <label class="form-label" for="telephoneResponsable">Numéro de téléphone</label>
                        <div class="input-group">
                          <span class="input-group-text">TG (+228)</span>
                          <input type="text" id="telephoneResponsable" name="telephoneResponsable" onChange={handleInputChange} class={`form-control multi-steps-mobile ${errors.telephoneResponsable ? "is-invalid" : ""}`} placeholder="00 00 00 00" value={formData.telephoneResponsable} />
                          {errors.telephoneResponsable && (
                            <div className="invalid-feedback">{errors.telephoneResponsable}</div>
                          )}
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <label class="form-label" for="emailResponsable">Adresse mail</label>
                        <input type="email" id="emailResponsable" name="emailResponsable" onChange={handleInputChange} class={`form-control multi-steps-email ${errors.emailResponsable ? "is-invalid" : ""}`} placeholder="Entrez votre adresse mail" value={formData.emailResponsable} />
                        {errors.emailResponsable && (
                          <div className="invalid-feedback">{errors.emailResponsable}</div>
                        )}
                      </div>
                      <div class="col-md-12">
                        <label class="form-label" for="adresseResponsable">Adresse de résidence</label>
                        <input type="text" id="adresseResponsable" name="adresseResponsable" onChange={handleInputChange} class="form-control" placeholder="Entrez votre adresse de résidence" value={formData.adresseResponsable} />
                      </div>
                      <div class="col-sm-6">
                        <label class="form-label" for="genreResponsable">Genre</label>
                        <select id="genreResponsable" name="genreResponsable" class="form-control" onChange={handleInputChange} value={formData.genreResponsable} >
                          <option value="MASCULIN">MASCULIN</option>
                          <option value="FEMININ">FEMININ</option>
                        </select>
                      </div>
                      <div class="col-sm-6">
                        <label class="form-label" for="typePieceResponsable">Pièce d'identité</label>
                        <select id="typePieceResponsable" name="typePieceResponsable" class="form-control" onChange={handleInputChange} value={formData.typePieceResponsable} >
                          <option value="CNI">CNI</option>
                          <option value="PASSEPORT">PASSEPORT</option>
                        </select>
                      </div>
                      <div class="col-sm-6">
                        <label class="form-label" for="numIdentiteResponsable">Numéro identité </label>
                        <input type="text" name="numIdentiteResponsable" id="numIdentiteResponsable" onChange={handleInputChange} class={`form-control ${errors.numIdentiteResponsable ? "is-invalid" : ""}`} placeholder="Numéro de votre pièce" value={formData.numIdentiteResponsable} />
                        {errors.numIdentiteResponsable && (
                          <div className="invalid-feedback">{errors.numIdentiteResponsable}</div>
                        )}
                      </div>
                      <div class="col-sm-6">
                        <label class="form-label" for="dateDelivranceResponsable">Date de délivrance </label>
                        <input type="date" name="dateDelivranceResponsable" id="dateDelivranceResponsable" onChange={handleInputChange} class={`form-control ${errors.dateDelivranceResponsable ? "is-invalid" : ""}`} placeholder="Date délivrance de la pièce" value={formData.dateDelivranceResponsable} />
                        {errors.dateDelivranceResponsable && (
                          <div className="invalid-feedback">{errors.dateDelivranceResponsable}</div>
                        )}
                      </div>
                      <div class="col-sm-6">
                        <label class="form-label" for="lieuDelivranceResponsable">Lieu de délivrance </label>
                        <input type="text" name="lieuDelivranceResponsable" id="lieuDelivranceResponsable" onChange={handleInputChange} class={`form-control ${errors.lieuDelivranceResponsable ? "is-invalid" : ""}`} placeholder="Lieu de délivrance" value={formData.lieuDelivranceResponsable} />
                      </div>
                      <div class="col-12 d-flex justify-content-between">
                        <button class="btn btn-primary"
                          onClick={() => {
                            setActiveTab(1);
                          }} >
                          <i class="bx bx-left-arrow-alt bx-sm ms-sm-n2 me-sm-2"></i>
                          <span class="align-middle d-sm-inline-block d-none">Précédent</span>
                        </button>
                        <button class="btn btn-primary" type="button"
                          onClick={() => {
                            validateUserForm() && setActiveTab(3);
                          }}
                        >
                          <span class="align-middle d-sm-inline-block d-none me-sm-2 me-0">Suivant</span>
                          <i class="bx bx-right-arrow-alt bx-sm me-sm-n2"></i>
                        </button>
                      </div>
                    </div>
                  </div>}
                  {/* <!-- Billing Links --> */}
                  {activeTab === 3 && <div id="billingLinksValidation"
                    className={
                      activeTab === 3 ? "content active" : "content"
                    }
                  >
                    <div class="content-header mb-6">
                      <h4 class="mb-0">Agréements</h4>
                      <p class="mb-0">Pour nous permettre de vous donner les habilitations pour produire pour telle ou telle compagnie, soumettez vos agréemments que vous avez obtenu auprès d'eux</p>
                    </div>
                    {/* <!-- Credit Card Details --> */}
                    <div class="row g-6">
                      <div class="col-md-12">
                        <label class="form-label w-100" for="multiStepsCard">Compagnies</label>
                        <div class="input-group input-group-merge">
                          <Select
                            mode="multiple"
                            style={{
                              width: "100%",
                            }}
                            placeholder="Veuillez sélectionner les compagnies avec lesquelles vous avez des agréements"
                            onChange={handleSelectCompagniesChange}
                            options={assurances?.map((c, id) => ({
                              label: c.raisonSociale,
                              value: JSON.stringify(c),
                              key: id,
                            }))}
                            value={selectedCompagnies}
                          />
                        </div>
                      </div>
                      {selectedCompagnies?.map((sc, id) => (
                        <div class="col-md-12" key={id}>
                          <Upload name="agreement" beforeUpload={(file) => {
                            onChangeBeforeUploadFileSelect(file, JSON.parse(sc).code)
                          }} onChange={(e) => onChangeFileSelect(e, JSON.parse(sc).code)} listType="picture" maxCount={1}>
                            <Button style={{ width: "100%" }} icon={<UploadOutlined />} block>Cliquez pour téléverser l'agréement de {JSON.parse(sc).raisonSociale} </Button>
                          </Upload>
                        </div>
                      ))}

                      <div class="col-12 d-flex justify-content-between">
                        <button class="btn btn-primary"
                          onClick={() => {
                            setActiveTab(2);
                          }}
                        >
                          <i class="bx bx-left-arrow-alt bx-sm ms-sm-n2 me-sm-2"></i>
                          <span class="align-middle d-sm-inline-block d-none">Précédent</span>
                        </button>
                        <button type="submit" class="btn btn-success" onClick={(e) => handleSubmit(e)}
                          disabled={loading}
                        >{loading && (
                          <span
                            class="spinner-grow me-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                          {loading ? `Enregistrement...` : `S'enregistrer`}
                        </button>
                      </div>
                    </div>
                    {/* <!--/ Credit Card Details --> */}
                  </div>}
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- / Multi Steps Registration --> */}
      </div>
    </div >

  )
}

export default Register