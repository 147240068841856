import React, {useEffect, useState} from "react";
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";
import SideBar from "../../components/SideBar";
import axios from "axios";
import {API_URL, LOGGED_USER, TOKEN} from "../../variables/constants";
import {frenchDate} from "../../variables/functions";
import {NavLink, useNavigate} from "react-router-dom";
import {Button, ConfigProvider, Dropdown, Input, Menu, Spin, Table, Tag} from "antd";
import {DownOutlined, EditOutlined, MenuOutlined, PlusOutlined, SearchOutlined} from "@ant-design/icons";
import frFR from "antd/locale/fr_FR";
import {rightsService} from "../../services/rightsService";
import {validateDossierSinistre} from "../../hooks/useHooks";
import {toast} from "react-toastify";

const ListSinistres = () => {
    const navigate = useNavigate()
    const [sinistres, setSinistres] = useState([]);
    const [data, setData] = useState([]);
    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});
    const [loadingSinistres, setLoadingSinistres] = useState(true);
    console.log('data ', data)
    console.log('sinistres ', sinistres)
    const handleChange = (pagination, filters, sorter) => {
        console.log("Various parameters", pagination, filters, sorter);
        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };
    useEffect(() => {
        const ctrs = [];
        // setSinistres(sinistres);
        let url = "";
        if (LOGGED_USER.profil.organisme.typeOrganisme === "POOL") {
            url = `${API_URL}/api/sinistre`;
        } else {
            url = `${API_URL}/api/sinistre`;
        }
        axios
            .get(url, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                setSinistres(res.data);
                res.data.map((sinistre, key) => {
                    ctrs.push({
                        key: sinistre.codeId,
                        id: key,
                        numeroPolice: sinistre.contrat?.numeroPoliceP,
                        assure: sinistre?.contrat?.souscripteur?.prenom
                            ? `${sinistre?.contrat?.souscripteur?.prenom} ${sinistre?.contrat?.souscripteur?.nom}`
                            : `${sinistre?.contrat?.souscripteur?.nom}`,
                        numeroAssure: sinistre?.contrat?.souscripteur?.numeroAssureP,
                        organisme: sinistre?.contrat?.organisme.raisonSociale,
                        dateEffet: sinistre?.contrat?.dateEffet.split("T")[1].split(".")
                            ? `${sinistre?.contrat?.dateEffet.split("T")[0]} ${
                                sinistre?.contrat?.dateEffet.split("T")[1].split(".")[0]
                            }`
                            : `${sinistre?.contrat?.dateEffet.split("T")[0]} ${
                                sinistre?.contrat?.dateEffet.split("T")[1]
                            }`,
                        dateEcheance: sinistre?.contrat?.dateEcheance
                            ? sinistre?.contrat?.dateEcheance?.split("T") &&
                            sinistre?.contrat?.dateEcheance?.split("T")[1]?.split(".")
                                ? `${sinistre?.contrat?.dateEcheance.split("T")[0]} ${
                                    sinistre?.contrat?.dateEcheance.split("T")[1].split(".")[0]
                                }`
                                : `${sinistre?.contrat.dateEcheance.split("T")[0]} ${
                                    sinistre?.contrat.dateEcheance.split("T")[1]
                                }`
                            : "",
                        data: sinistre,
                        dateSinistre: sinistre?.dateSinistre
                            ? frenchDate(sinistre?.dateSinistre)
                            : "-",
                        numeroSinistre: sinistre?.numSinistre,
                        sort: sinistre?.sortDossier,
                        actions: (
                            <div className="actions-right">
                                <Button
                                    justIcon
                                    round
                                    simple
                                    onClick={() => {
                                        navigate(`/admin/modifier-declaration-sinistre/${sinistre.codeId}`, {
                                            replace: true,
                                        })
                                    }}
                                    color="info"
                                    className="like"
                                >
                                    <EditOutlined/>
                                </Button>
                                <Button
                                    justIcon
                                    round
                                    simple
                                    onClick={() => {
                                        navigate(`/admin/details-sinistre/${sinistre.codeId}`, {replace: true})
                                    }}
                                    color="warning"
                                    className="edit"
                                >
                                    <MenuOutlined/>
                                </Button>
                            </div>

                        ),
                    });

                });
                setData(ctrs);
                setLoadingSinistres(false);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [TOKEN]);

    const showActivateConfirm = (codeId, active) => {

    }

    const showDeletionConfirm = () => {

    }
    const [validateLoading, setValidateLoading] = useState(false)

    const handleValiderDossier = async (codeId) => {
        try {
            setValidateLoading(true)
            const response = await validateDossierSinistre(codeId)
            toast.success('Dossier validé avec succès')
            setValidateLoading(false)
        } catch (e) {
            toast.error('Erreur lors de la validation du dossier')
            setValidateLoading(false)
            console.log(e)
        }
    }
    const columns = [
        {
            title: "N° Sinistre",
            dataIndex: "numeroSinistre",
            key: "1",
        },
        {
            title: "N° Police",
            dataIndex: "numeroPolice",
            key: "2",
        },
        {
            title: "Assuré",
            dataIndex: "assure",
            key: "3",
        },
        {
            title: "N° Assuré",
            dataIndex: "numeroAssure",
            key: "4",
        },
        {
            title: "Date Sinistre",
            dataIndex: "dateSinistre",
            key: "5",
        },

        {
            title: "Sort dossier",
            dataIndex: "sort",
            key: "sort",
            render: (_, record) => (
                <>
                    {record.sort === "I" ? (
                        <Tag color="green" key={_}>
                            EN INSTANCE
                        </Tag>
                    ) : record.sort === "A" ? (
                        <Tag color="orange" key={_}>
                            EN ATTENTE
                        </Tag>
                    ) : record.sort === "T" ? (
                        <Tag color="red" key={_}>
                            TERMINÉ
                        </Tag>
                    ) : record.sort === "S" ? (
                        <Tag color="blue" key={_}>
                            SANS SUITE
                        </Tag>
                    ) : (
                        <Tag color="green" key={_}>
                            EN INSTANCE
                        </Tag>
                    )}
                </>
            ),
            filters: [
                {
                    text: "EN ATTENTE",
                    value: "A",
                }, {
                    text: "EN INSTANCE",
                    value: "I",
                },
                {
                    text: "TERMINÉ",
                    value: "T",
                },
                {
                    text: "SANS SUITE",
                    value: "S",
                },
            ],
            filteredValue: filteredInfo.sort || null,
            onFilter: (value, record) => record.sort === value,
            sorter: (a, b) => a.sort?.length - b.sort?.length,
            sortOrder: sortedInfo.columnKey === "sort" ? sortedInfo.order : null,
            ellipsis: true,
        },
        {
            title: "Actions",
            dataIndex: "actions",
            key: "7",
            render: (_, record) => (
                <>
                    <Dropdown
                        overlay={
                            <Menu>
                                <Menu.Item key="1">
                                    <Button onClick={() => {
                                        navigate(`/details-sinistre`, {
                                            state: {codeId: record.key}
                                        })
                                    }}>
                                        Détails
                                    </Button>
                                </Menu.Item>
                                {rightsService("HPE041") ? <Menu.Item key="3">
                                    <a href={`/modifier-declaration-sinistre/${record.key}`}>Modifier</a>
                                </Menu.Item> : null}

                            </Menu>
                        }
                    >
                        <Button icon={<DownOutlined/>}/>
                    </Dropdown>
                    {record.sort === "A" && (
                        <>
                            <Button style={{
                                borderLeft: 'none',
                                backgroundColor: 'lightgreen',
                                fontWeight: 'bold',
                                marginLeft: '10px'
                            }} onClick={() => handleValiderDossier(record.key)}> Valider </Button>
                        </>
                    )}
                </>

            ),
        },
    ];

    // Set up a function to filter sinistre by id
    // const filterSinistre = (id) => {
    //   return sinistres.filter((sinistre) => sinistre.codeId === id);
    // };

    const numeroSinistreFiltering = (e) => {
        // console.log(e.target.value, "okkkkkkkkk");
        const value = e.target.value;
        if (value) {
            const filteredData = data.filter((entry) =>
                entry?.numeroSinistre?.toLowerCase()?.includes(value?.toLowerCase())
            );
            setData(filteredData);
        } else {
            setData(sinistres);
        }
    };

    return (
        <div className="layout-wrapper layout-content-navbar  ">
            <div className="layout-container">
                <SideBar currentMenu={"SINISTRES"}/>

                {/* <!-- Layout container --> */}
                <div className="layout-page">
                    <NavBar/>

                    {/* <!-- Content wrapper --> */}
                    <div className="content-wrapper">
                        {/* <!-- Content --> */}
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <div className="grid-container">
                                <div className="grid-item full-width">
                                    <div className="card">
                                        <div className="card-header primary-header">
                                            <div className="card-icon primary-icon">
                                                <i className="icon-assignment"></i>
                                            </div>
                                            <h4 className="card-icon-title">Liste des Sinistres</h4>
                                        </div>
                                        <div className="card-body">
                                            <div className="grid-container">
                                                <div className="grid-item half-width">
                                                    <NavLink to="/declaration-sinistre">
                                                        <Button className="primary-button" type="primary"
                                                                icon={<PlusOutlined/>}
                                                                style={{marginBottom: 16}}>
                                                            <i className="icon-add-circle"></i> Déclarer un sinistre
                                                        </Button>
                                                    </NavLink>
                                                    <hr/>
                                                </div>
                                                <div className="grid-item">
                                                    <Input
                                                        onChange={(e) => numeroSinistreFiltering(e)}
                                                        type="text"
                                                        style={{
                                                            background: "white",
                                                            color: "blue",
                                                        }}
                                                        prefix={<SearchOutlined/>}
                                                        placeholder="Filtre par numéro sinistre"
                                                    />
                                                </div>
                                            </div>

                                            <ConfigProvider locale={frFR}>
                                                <Spin spinning={loadingSinistres}>
                                                    <Table columns={columns} dataSource={data}
                                                           onChange={handleChange}/>
                                                </Spin>
                                            </ConfigProvider>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        {/* <!-- / Content --> */}

                        {/* <!-- Footer --> */}
                        <Footer/>
                        {/* <!-- / Footer --> */}

                        <div className="content-backdrop fade"></div>
                    </div>
                    {/* <!-- Content wrapper --> */}
                </div>
                {/* <!-- / Layout page --> */}
            </div>

            {/* <!-- Overlay --> */}
            <div className="layout-overlay layout-menu-toggle"></div>

            {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
            <div className="drag-target"></div>
        </div>
    );
};

export default ListSinistres;
