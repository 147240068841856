import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Footer from '../../../components/Footer';
import NavBar from '../../../components/NavBar';
import SideBar from '../../../components/SideBar';
import {API_URL, TOKEN} from '../../../variables/constants';

const GestionDesAttestations = () => {
    const [datas, setDatas] = useState([]);
    const [poolSubdivisions, setPoolSubdivisions] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        axios
            .get(`${API_URL}/api/lot-attestation/all`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                console.log(res.data);
                setDatas(res.data);
            })
            .catch((err) => {
                console.log(err);
            });

        axios
            .get(`${API_URL}/api/dispatched-lot-attestation/stats/all-pool-subdivisions`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                console.log(res.data);
                setPoolSubdivisions(res.data);
            })
            .catch((err) => {
                console.log(err);
            });

    }, [TOKEN]);

    return (
        <div class="layout-wrapper layout-content-navbar  ">
            <div class="layout-container">
                <SideBar/>

                {/* <!-- Layout container --> */}
                <div class="layout-page">
                    <NavBar/>

                    {/* <!-- Content wrapper --> */}
                    <div class="content-wrapper">
                        {/* <!-- Content --> */}
                        <div class="container-xxl flex-grow-1 container-p-y">
                            <div class="row g-6 mb-6">
                                <div class="col-xxl-12">
                                    <div class="card text-center h-100">
                                        <div class="card-header nav-align-top">
                                            <ul class="nav nav-pills" role="tablist">
                                                <li class="nav-item">
                                                    <button type="button" class="nav-link active" role="tab"
                                                            data-bs-toggle="tab" data-bs-target="#navs-pills-browser"
                                                            aria-controls="navs-pills-browser"
                                                            aria-selected="true">Gestion des attestations
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="tab-content pt-0">
                                            <div className="table-responsive text-start text-nowrap">
                                                <table className="table table-borderless">
                                                    <thead>
                                                    <tr>
                                                        <th>Compagnie</th>
                                                        <th>Utilisation</th>
                                                        <th className="w-50">Données en pourcentage</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {datas?.map((data, id) => (
                                                        <tr key={id}>
                                                            <td>
                                                                <div className="d-flex align-items-center">
                                                                    {/* <img src="../../assets/img/icons/brands/chrome.png" alt="Chrome" height="24" class="me-3" /> */}
                                                                    <span
                                                                        className="text-heading">{data.compagnie}</span>
                                                                </div>
                                                            </td>
                                                            <td className="text-heading">{data.used} / {data.total}</td>
                                                            <td>
                                                                <div
                                                                    className="d-flex justify-content-between align-items-center gap-4">
                                                                    <div className="progress w-100"
                                                                         style={{height: "10px"}}>
                                                                        <div className="progress-bar bg-success"
                                                                             role="progressbar"
                                                                             style={{width: `${100 * data.used / data.total}%`}}
                                                                             aria-valuenow={100 * data.used / data.total}
                                                                             aria-valuemin="0"
                                                                             aria-valuemax="100"></div>
                                                                    </div>
                                                                    <small
                                                                        className="fw-medium">{data.total ? (100 * data.used / data.total).toFixed(2) : 0}%</small>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <button type="button"
                                                                        className="btn btn-sm btn-icon btn-label-secondary"
                                                                        onClick={() => navigate(`/lots-attestations/${data.code}`)}>
                                                                    <i className="bx bx-chevron-right scaleX-n1-rtl"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className="table-responsive text-start text-nowrap">
                                                <table className="table table-borderless">
                                                    <thead>
                                                    <tr>
                                                        <th>Autres subdivisions</th>
                                                        <th>Utilisation</th>
                                                        <th className="w-50">Données en pourcentage</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {poolSubdivisions?.map((data, id) => (
                                                        <tr key={id}>
                                                            <td>
                                                                <div className="d-flex align-items-center">
                                                                    <span
                                                                        className="text-heading">{data.compagnie}</span>
                                                                </div>
                                                            </td>
                                                            <td className="text-heading">{data.used} / {data.total}</td>
                                                            <td>
                                                                <div
                                                                    className="d-flex justify-content-between align-items-center gap-4">
                                                                    <div className="progress w-100"
                                                                         style={{height: "10px"}}>
                                                                        <div className="progress-bar bg-success"
                                                                             role="progressbar"
                                                                             style={{width: `${100 * data.used / data.total}%`}}
                                                                             aria-valuenow={100 * data.used / data.total}
                                                                             aria-valuemin="0"
                                                                             aria-valuemax="100"></div>
                                                                    </div>
                                                                    <small
                                                                        className="fw-medium">{data.total ? (100 * data.used / data.total).toFixed(2) : 0}%</small>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <button type="button"
                                                                        className="btn btn-sm btn-icon btn-label-secondary"
                                                                        onClick={() => navigate(`/lots-attestations/${data.code}`, {state: "agence"})}>
                                                                    <i className="bx bx-chevron-right scaleX-n1-rtl"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- / Content --> */}

                        {/* <!-- Footer --> */}
                        <Footer/>
                        {/* <!-- / Footer --> */}

                        <div class="content-backdrop fade"></div>
                    </div>
                    {/* <!-- Content wrapper --> */}
                </div>
                {/* <!-- / Layout page --> */}
            </div>

            {/* <!-- Overlay --> */}
            <div class="layout-overlay layout-menu-toggle"></div>

            {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
            <div class="drag-target"></div>
        </div>
    );
}

export default GestionDesAttestations