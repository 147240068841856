import React from "react";
import {Card, Descriptions, Divider, List, Modal, Typography} from "antd";

const {Title} = Typography;

const EvaluationDetailsModal = ({isVisible, onClose, evaluationData}) => {
    const {evaluation} = evaluationData;

    return (
        <Modal
            title="Évaluation Détails"
            visible={isVisible}
            onCancel={onClose}
            footer={null}
            width={800}
        >
            <Title level={4}>Informations sur l'évaluation</Title>
            <Descriptions bordered size="small" column={2}>
                <Descriptions.Item label="Code ID">{evaluation?.codeId}</Descriptions.Item>
                <Descriptions.Item label="Ancienne Provision">{evaluation?.ancienneProvision}</Descriptions.Item>
                <Descriptions.Item label="Nouvelle Provision">{evaluation?.nouvelleProvision}</Descriptions.Item>
                <Descriptions.Item label="État">{evaluation?.etatEvaluation}</Descriptions.Item>
            </Descriptions>

            <Divider/>
            <Title level={4}>Informations sur le Sinistre</Title>
            <Descriptions bordered size="small" column={2}>
                <Descriptions.Item label="Code Sinistre">{evaluation?.sinistreCodeId}</Descriptions.Item>
                <Descriptions.Item label="Numéro Sinistre">{evaluation?.sinistreNumSinistre}</Descriptions.Item>
                <Descriptions.Item label="Souscripteur">{evaluation?.sinistreSouscripteur}</Descriptions.Item>
                <Descriptions.Item label="Numéro Police">{evaluation?.sinistreNumPolice}</Descriptions.Item>
            </Descriptions>

            <Divider/>
            <Title level={4}>Reviseur</Title>
            <Descriptions bordered size="small" column={2}>
                <Descriptions.Item label="Nom">{evaluation?.reviseur.personne.nom}</Descriptions.Item>
                <Descriptions.Item label="Prénom">{evaluation?.reviseur.personne.prenom}</Descriptions.Item>
                <Descriptions.Item label="Email">{evaluation?.reviseur.personne.email}</Descriptions.Item>
                <Descriptions.Item label="Fonction">{evaluation?.reviseur.personne.fonction}</Descriptions.Item>
                <Descriptions.Item
                    label="Contact">{evaluation?.reviseur.personne.contact.join(", ")}</Descriptions.Item>
            </Descriptions>

            <Divider/>
            <Title level={4}>Sous Évaluations</Title>
            <List
                dataSource={evaluation?.sousEvaluations}
                renderItem={(item) => (
                    <Card title={`Sous Évaluation: ${item.codeId}`} style={{marginBottom: "16px"}}>
                        <Descriptions bordered size="small" column={1}>
                            <Descriptions.Item label="Nom Victime">{item.victime.personne.nom}</Descriptions.Item>
                            <Descriptions.Item label="Prénom Victime">{item.victime.personne.prenom}</Descriptions.Item>
                            <Descriptions.Item label="Profession">{item.victime.personne.profession}</Descriptions.Item>
                            <Descriptions.Item label="Adresse">{item.victime.personne.adresse}</Descriptions.Item>
                        </Descriptions>

                        <Divider/>
                        <Title level={5}>Préjudices</Title>
                        <List
                            dataSource={item.prejudices}
                            renderItem={(prejudice) => (
                                <Descriptions bordered size="small" column={1}>
                                    <Descriptions.Item label="Type">{prejudice.libellePrejudice}</Descriptions.Item>
                                    <Descriptions.Item label="Montant">{prejudice.montant}</Descriptions.Item>
                                </Descriptions>
                            )}
                        />

                        <Divider/>
                        <Title level={5}>Garantie</Title>
                        <Descriptions bordered size="small" column={1}>
                            <Descriptions.Item label="Nom Garantie">{item.garantieAffecte.nom}</Descriptions.Item>
                            <Descriptions.Item
                                label="Commentaire">{item.garantieAffecte.commentaire}</Descriptions.Item>
                            <Descriptions.Item
                                label="Catégorie">{item.garantieAffecte.categorie.nom}</Descriptions.Item>
                        </Descriptions>
                    </Card>
                )}
            />
        </Modal>
    );
};

export default EvaluationDetailsModal
