import { GiftOutlined } from "@ant-design/icons";
import { Card, Spin, Table, Tag } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Footer from "../../../components/Footer";
import NavBar from "../../../components/NavBar";
import SideBar from "../../../components/SideBar";
import { API_URL, TOKEN } from "../../../variables/constants";

const CommandeDelivre = () => {
  const [commandes, setCommandes] = useState([]);
  const [loading, setLoading] = useState(true);

  const columns = [
    {
      title: "Compagnie",
      dataIndex: "compagnie",
      key: "compagnie",
      render: (_, record) => (
        <>{record?.emetteur?.profil?.organisme?.raisonSociale}</>
      ),
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
    },
    {
      title: "Type d'attestation",
      dataIndex: "typeAttestation",
      key: "typeAttestation",
      render: (_, record) => (
        <>
          {record.typeAttestation} - {record?.categorie?.nom}
        </>
      ),
    },
    {
      title: "Date de Commande",
      dataIndex: "dateEmission",
      key: "dateEmission",
      render: (_, record) => (
        <>{new Date(record.dateEmission).toLocaleDateString()}</>
      ),
    },
    {
      title: "Statut commande",
      dataIndex: "statutCommande",
      key: "statutCommande",
      render: (_, record) => <Tag color="blue">{record.statutCommande}</Tag>,
    },
    {
      title: "Action",
      dataIndex: "actions",
      key: "actions",
      render: (_, record) => (
        <button
          type="button"
          className="btn btn-secondary btn-icon rounded-pill"
          onClick={() => handleValidation(record.codeId)}
        >
          <i className="bx bx-check-circle"></i>
        </button>
      ),
    },
  ];

  useEffect(() => {
    axios
      .get(`${API_URL}/api/bon-commande/statut/DELIVRE`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      })
      .then((response) => {
        setCommandes(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des données :", error);
        setLoading(false);
      });
  }, []);

  const handleValidation = (commandeId) => {
    axios
      .get(`${API_URL}/api/bon-commande/validate/${commandeId}`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      })
      .then(() => {
        setCommandes((prev) =>
          prev.filter((commande) => commande.codeId !== commandeId)
        );
      })
      .catch((error) => {
        console.error("Erreur lors de la validation de la commande :", error);
      });
  };

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <SideBar />
        <div className="layout-page">
          <NavBar />
          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              <Card
                bordered={false}
                style={{
                  marginBottom: 20,
                  //   backgroundColor: "#cce5ff",
                  //   color: "#004085",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  borderRadius: "8px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h2
                    style={{ margin: 0, fontSize: "26px", fontWeight: "bold" }}
                  >
                    Commandes
                  </h2>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <GiftOutlined
                      style={{ fontSize: "20px", color: "green" }}
                    />
                    <span
                      style={{
                        marginLeft: "8px",
                        fontSize: "22px",
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      commande Delivrées
                    </span>
                  </div>
                </div>
              </Card>
              <div className="card">
                <Spin spinning={loading}>
                  <Table
                    columns={columns}
                    dataSource={commandes}
                    rowKey="codeId"
                    pagination={{ pageSize: 10 }}
                  />
                </Spin>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommandeDelivre;
