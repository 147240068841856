import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { API_URL, TOKEN } from "../../../variables/constants";

const AddNatureActe = ({ show, handleClose, editMode, selectedNatureActe }) => {
  const [loading, setLoading] = useState(false);
  const [libelle, setLibelle] = useState("");

  // Remplir les champs si en mode édition
  useEffect(() => {
    if (editMode && selectedNatureActe) {
      setLibelle(selectedNatureActe.libelleParametre || "");
    } else {
      setLibelle("");
    }
  }, [editMode, selectedNatureActe]);

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const natureActeData = {
      ...selectedNatureActe,
      libelleParametre: libelle,
      typeParametre: "NATURE_ACTE",
    };

    const request = editMode
      ? axios.put(`${API_URL}/api/parametre`, JSON.stringify(natureActeData), {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
          },
        })
      : axios.post(`${API_URL}/api/parametre`, JSON.stringify(natureActeData), {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
          },
        });

    request
      .then((res) => {
        toast.success(
          editMode
            ? "Nature de l'acte modifiée avec succès"
            : "Nature de l'acte ajoutée avec succès",
        );
        setLibelle("");
        handleClose();
      })
      .catch((err) => {
        console.error("Error:", err);
        toast.error(
          editMode
            ? "Erreur lors de la modification"
            : "Erreur lors de l'ajout",
        );
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Body>
        <button
          type="button"
          className="btn-close btn-primary"
          onClick={handleClose}
          aria-label="Close"
        ></button>
        <div className="text-center mb-6">
          <h4 className="address-title mb-2">
            {editMode ? "Modifier la" : "Ajouter une"} nature de l'acte
          </h4>
          <p className="address-subtitle">
            {editMode ? "Modifier la" : "Ajouter une nouvelle"} nature de l'acte
          </p>
        </div>
        <form
          id="addNewNatureActeForm"
          className="row g-12"
          onSubmit={onSubmit}
        >
          <div className="col-12 col-md-12">
            <label className="form-label" htmlFor="modalNatureActeLibelle">
              Libellé
            </label>
            <input
              type="text"
              id="modalNatureActeLibelle"
              onChange={(e) => setLibelle(e.target.value)}
              className="form-control"
              placeholder="Libellé de la nature de l'acte"
              name="libelle"
              value={libelle}
            />
          </div>
          <div className="col-12 text-center">
            <button
              type="submit"
              className="btn btn-primary me-3"
              disabled={loading}
            >
              {loading && (
                <span
                  className="spinner-grow me-1"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              {loading ? `Enregistrement...` : `Enregistrer`}
            </button>
            <button
              type="reset"
              className="btn btn-label-secondary"
              onClick={handleClose}
            >
              Annuler
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddNatureActe;
