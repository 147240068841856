import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    DeliveredProcedureOutlined,
    DollarCircleOutlined,
    ExclamationCircleFilled,
    FileDoneOutlined,
    FileProtectOutlined,
    HourglassOutlined,
    PlusOutlined,
    ShoppingCartOutlined,
    SyncOutlined,
} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import {Button, Form, Input, InputNumber, message, Modal, Popconfirm, Select, Spin, Table, Tabs, Tag,} from "antd";
import axios from "axios";
import {API_URL, TOKEN} from "../../../variables/constants";
import {toast} from "react-toastify";
import SideBar from "../../../components/SideBar";
import NavBar from "../../../components/NavBar";
import Footer from "../../../components/Footer";
import axiosInstance from "../../../variables/api/axiosInstance";
import {updateBonCommande, validateWithComment,} from "../../../hooks/useHooks";
import OrderTimeline from "../components/modals/OrderTimeline";
import {frenchDate} from "../../../variables/functions";
import PaymentConfirmationModal from "../components/modals/PaymentConfirmationModal";
import {rightsService} from "../../../services/rightsService";

const {Option} = Select;

const NosCommandes = () => {
    const [orderModalOpen, setOrderModalOpen] = useState(false);
    const [commandes, setCommandes] = useState([]);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [nombre, setNombre] = useState(0);
    const [selectedCategorie, setSelectedCategorie] = useState("M");
    const [selectedTypeAttestation, setSelectedTypeAttestation] =
        useState("SIMPLE");
    const [loadingOrders, setLoadingOrders] = useState(false);
    const [commentaire, setCommentaire] = useState("");
    const [updateCommentaire, setUpdateCommentaire] = useState("");
    const [openTimeline, setOpenTimeline] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState({});
    const [modalVisible, setModalVisible] = useState(false);
    const [statutCommande, setStatutCommande] = useState();
    const [lotA, setLotA] = useState();
    const [editingKey, setEditingKey] = useState("");
    const [dataBeingEdited, setDataBeingEdited] = useState(false);

    const showModal = () => setModalVisible(true);
    const handleCancel = () => setModalVisible(false);
    const handleOk = (lotA, commentaire) => {
        console.log(
            "Payment confirmed for lotA:",
            lotA,
            "with comment:",
            commentaire,
        );
        setModalVisible(false);
    };

    const typePaiement = {
        CHEQUE: "CHEQUE",
        ESPECE: "ESPECE",
        MOBILE_MONEY: "MOBILE MONEY",
    };

    const [selectedPaymentType, setSelectedPaymentType] = useState(
        typePaiement.ESPECE,
    );

    let paymentType = "CHEQUE";

    const getAntdIcon = (status) => {
        const statusIconMap = {
            INITIE: <ShoppingCartOutlined/>, // Order placed
            VALIDE: <CheckCircleOutlined/>, // Order validated
            AUTORISE: <SyncOutlined/>, // Order authorized
            EN_TRAITEMENT: <HourglassOutlined/>, // Order in processing
            APPROUVE: <FileProtectOutlined/>, // Order approved
            REGLE: <DollarCircleOutlined/>, // Payment completed
            DELIVRE: <DeliveredProcedureOutlined/>, // Order delivered
            RECEPTIONNE: <FileDoneOutlined/>, // Order received
            REJETE: <CloseCircleOutlined/>, // Order rejected
        };

        return statusIconMap[status] || null;
    };

    const getActionVerb = (statutCommande) => {
        switch (statutCommande) {
            case "INITIE":
                return "valider";
            case "VALIDE":
                return "autoriser";
            case "AUTORISE":
                return "passer en traitement";
            case "EN_TRAITEMENT":
                return "approuver";
            case "APPROUVE":
                return "régler";
            case "REGLE":
                return "livrer";
            case "DELIVRE":
                return "réceptionner";
            default:
                return null;
        }
    };

    const onSubmitCommandeAttestation = () => {
        if (nombre) {
            const catg = categories.find(
                (element) => element.codeCategorie === selectedCategorie,
            );
            let bonCommande = {
                commentaire,
                nombre,
                categorie: catg,
                typeAttestation: selectedTypeAttestation,
                statutCommande: "INITIE",
            };
            console.log("data envoyée", bonCommande);

            axios
                .post(`${API_URL}/api/bon-commande`, JSON.stringify(bonCommande), {
                    headers: {
                        Authorization: `Bearer ${TOKEN}`,
                        "Content-Type": "application/json",
                    },
                })
                .then(() => {
                    setReload(true);
                    toast("Lot d'attestations ajouté avec succès");
                    setLoading(false);
                    setOrderModalOpen(false);
                    resetForm();
                    console.log("data envoyée", bonCommande);
                })
                .catch(() => toast("Erreur lors de l'ajout du lot d'attestations"));
        } else {
            toast("Veuillez renseigner le nombre d'attestations!");
        }
    };
    console.log(commentaire);

    const getCodeHabilitation = (statutCommande) => {
        if (statutCommande === "INITIE") {
            return "HCO071";
        } else if (statutCommande === "VALIDE") {
            return "HCO072";
        } else if (statutCommande === "AUTORISE") {
            return "HCO073";
        } else if (statutCommande === "EN_TRAITEMENT") {
            return "HCO074";
        } else if (statutCommande === "APPROUVE") {
            return "HCO075";
        } else if (statutCommande === "REGLE") {
            return "HCO076";
        }
    };

    const handleFileUpload = (info) => {
        const {status} = info.file;
        if (status === "done") {
            message.success(`${info.file.name} fichier téléchargé avec succès.`);
        } else if (status === "error") {
            message.error(`${info.file.name} échec du téléchargement.`);
        }
    };
    const reject = (record, updateCommentaire) => {
        const updatedBonCommande = {...record};
        updatedBonCommande.statutCommande = "REJETE";
        updatedBonCommande.motifRejet = updateCommentaire;
        updateBonCommande(updatedBonCommande).then((r) => {
            setReload((prevState) => !prevState);
            toast.success("Commande rejetée avec succes");
        });
    };
    let com = "";
    let paiement = typePaiement.CHEQUE;
    console.log(paiement);
    console.log(selectedPaymentType);

    const showValidationAttestationConfirm = (lotA, statutCommande) => {
        Modal.confirm({
            title: `Etes-vous sûr de vouloir ${getActionVerb(statutCommande)} la commande?`,
            icon: <ExclamationCircleFilled color={"blue"}/>,
            content: (
                <>
                    <p style={{color: "red", marginBottom: "1rem"}}>
                        Toutes vos actions sont historisées
                    </p>
                    <div style={{marginBottom: "1rem"}}>
                        <label htmlFor="commentaire">Faites un commentaire :</label>
                        <textarea
                            id="commentaire"
                            style={{
                                width: "100%",
                                marginTop: "0.5rem",
                                padding: "0.5rem",
                                borderRadius: "4px",
                                border: "1px solid #d9d9d9",
                            }}
                            placeholder="Votre commentaire ici..."
                            onChange={(e) => {
                                com = e.target.value;
                                setUpdateCommentaire(e.target.value);
                            }}
                        />
                    </div>
                </>
            ),
            okText: "Oui",
            okType: "primary",
            cancelText: "Annuler",
            onOk: () => validation(lotA, com),
        });
    };

    const showRejectBonCommandeConfirm = (record) => {
        Modal.confirm({
            title: `Etes-vous sûr de vouloir rejeter la commande?`,
            icon: <ExclamationCircleFilled color={"blue"}/>,
            content: (
                <>
                    <p style={{color: "red", marginBottom: "1rem"}}>
                        Toutes vos actions sont historisées
                    </p>
                    <div style={{marginBottom: "1rem"}}>
                        <label htmlFor="commentaire">Motif de rejet :</label>
                        <textarea
                            id="commentaire"
                            style={{
                                width: "100%",
                                marginTop: "0.5rem",
                                padding: "0.5rem",
                                borderRadius: "4px",
                                border: "1px solid #d9d9d9",
                            }}
                            placeholder="Votre commentaire ici..."
                            onChange={(e) => {
                                com = e.target.value;
                                setUpdateCommentaire(e.target.value);
                            }}
                        />
                    </div>
                </>
            ),
            okText: "Oui",
            okType: "primary",
            cancelText: "Annuler",
            onOk: () => reject(record, com),
        });
    };

    const [reload, setReload] = useState(false);

    const resetForm = () => {
        setNombre(0);
        setSelectedCategorie("M");
        setSelectedTypeAttestation("SIMPLE");
        setCommentaire("");
    };
    console.log(commandes);
    const getTagColor = (statutCommande) => {
        switch (statutCommande) {
            case "INITIE":
                return "blue";
            case "VALIDE":
                return "green";
            case "AUTORISE":
                return "gold";
            case "CONFIRME":
                return "purple";
            case "DELIVRE":
                return "cyan";
            case "RECEPTIONNE":
                return "volcano";
            default:
                return "default";
        }
    };
    const showHandleReglement = (codeId, statutCommande) => {
        setLotA(codeId);
        setModalVisible(true);
        setStatutCommande(statutCommande);
    };
    useEffect(() => {
        axiosInstance
            .get(`${API_URL}/api/bon-commande`)
            .then((res) => {
                setCommandes(res.data.reverse());
                setLoadingOrders(false);
            })
            .catch(() => setLoadingOrders(false));

        axios
            .get(`${API_URL}/api/categorie`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                },
            })
            .then((res) => setCategories(res.data))
            .catch(console.error);
    }, [TOKEN, reload]);

    const columns = [
        {
            title: "Type d'attestation",
            dataIndex: "typeAttestation",
            key: "typeAttestation",
            editable: false,
            render: (_, record) => (
                <>
                    {record.typeAttestation} - {record?.categorie?.nom}
                </>
            ),
        },
        {
            title: "Nombre",
            dataIndex: "nombre",
            key: "nombre",
            editable: true,
        },
        {
            title: "Statut commande",
            dataIndex: "statutCommande",
            key: "statutCommande",
            editable: false,
            render: (_, record) => (
                <Tag color={getTagColor(record.statutCommande)}>
                    {record.statutCommande}
                </Tag>
            ),
        },
        {
            title: "Date de Commande",
            dataIndex: "dateEmission",
            key: "dateEmission",
            editable: false,
            render: (_, record) => <>{frenchDate(record.dateEmission)}</>,
        },
        /* {
                 title: "Action",
                 key: "actions",
                 render: (_, record) => (
                     <Tooltip title="Afficher plus d'actions">
                         <button
                             type="button"
                             className="btn btn-secondary btn-icon rounded-pill"
                             onClick={() => toggleExpandedRow(record)}
                         >
                             <i className="bx bx-plus"></i>
                         </button>
                     </Tooltip>
                 ),
             },*/
    ];

    const isEditing = (record) => record.codeId === editingKey;

    const [editNombre, setEditNombre] = useState();
    const edit = (record) => {
        setEditNombre(record.nombre);
        form.setFieldsValue({
            nombre: record.nombre,
        });
        console.log("keyy " + nombre);
        setEditingKey(record.codeId);
        setDataBeingEdited(true);
    };

    const cancel = () => {
        setNombre(null);
        setEditingKey("");
        setDataBeingEdited(false);
    };

    const save = (record) => {
        const updatedNombre = form.getFieldValue("nombre");
        console.log(form.getFieldValue("nombre"));
        const bonCommande = {
            ...record,
            nombre: updatedNombre,
            oldNombre: editNombre,
        };

        updateBonCommande(bonCommande).then((r) => {
            setReload((prevState) => !prevState);
            toast.success("Commande éditée avec succes");
        });

        console.log(bonCommande);
    };

    const [form] = Form.useForm();

    console.log(nombre);
    const EditableCell = ({
                              editing,
                              dataIndex,
                              title,
                              inputType,
                              record,
                              index,
                              children,
                              ...restProps
                          }) => {
        const handleChange = (value) => {
            console.log("Changed value:", value);
        };

        const inputNode =
            inputType === "number" ? (
                <InputNumber min={1} defaultValue={editNombre}/>
            ) : (
                <Input onChange={(e) => handleChange(e.target.value)}/>
            );

        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item
                        name={dataIndex}
                        style={{margin: 0}}
                        rules={[
                            {
                                required: true,
                                message: `Veuillez saisir le ${title}!`,
                            },
                        ]}
                    >
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };

    const expandedRowRender = (record) => (
        <div
            style={{
                padding: "16px",
                background: "#f9f9f9",
                display: "flex",
                justifyContent: "center",
                gap: "16px",
            }}
        >
            {rightsService(getCodeHabilitation(record.statutCommande)) ? (
                <>
                    <Button
                        type="primary"
                        style={{backgroundColor: "#28a745", borderColor: "#28a745"}}
                        icon={getAntdIcon(record.statutCommande)}
                        disabled={editingKey !== ""}
                        onClick={() =>
                            record.statutCommande === "APPROUVE"
                                ? showHandleReglement(record.codeId, record.statutCommande)
                                : showValidationAttestationConfirm(
                                    record.codeId,
                                    record.statutCommande,
                                )
                        }
                    >
                        {`${getActionVerb(record.statutCommande)?.charAt(0).toUpperCase() + getActionVerb(record.statutCommande)?.slice(1).toLowerCase()}  la commande`}
                    </Button>
                    {isEditing(record) ? (
                        <>
                            <Popconfirm
                                title={"Voulez vous annuler ?"}
                                onConfirm={() => cancel()}
                            >
                                <Button
                                    type="default"
                                    style={{
                                        backgroundColor: "#002d00",
                                        borderColor: "#005edd",
                                        color: "white",
                                    }}
                                    icon={<i className="bx bx-edit-alt"></i>}
                                >
                                    Annuler
                                </Button>
                            </Popconfirm>
                            <Popconfirm
                                title={"Voulez vous enregistrez ?"}
                                onConfirm={() => save(record)}
                            >
                                <Button
                                    type="default"
                                    style={{
                                        backgroundColor: "#006f00",
                                        borderColor: "#007bff",
                                        color: "white",
                                    }}
                                    icon={<i className="bx bx-edit-alt"></i>}
                                >
                                    Enregistrer
                                </Button>
                            </Popconfirm>
                        </>
                    ) : (
                        <Button
                            type="default"
                            style={{
                                backgroundColor: "#007bff",
                                borderColor: "#007bff",
                                color: "white",
                            }}
                            icon={<i className="bx bx-edit-alt"></i>}
                            onClick={() => edit(record)}
                        >
                            Éditer la commande
                        </Button>
                    )}
                </>
            ) : null}

            {rightsService("HCO075") && (
                <Button
                    disabled={editingKey !== ""}
                    style={{
                        backgroundColor: "#dc3545",
                        borderColor: "#dc3545",
                        color: "#fff",
                    }}
                    icon={<i className="bx bx-message-alt-x"></i>}
                    onClick={() => showRejectBonCommandeConfirm(record)}
                >
                    Rejeter la commande
                </Button>
            )}
            <Button
                type="default"
                style={{
                    backgroundColor: "#6c757d",
                    borderColor: "#6c757d",
                    color: "white",
                }}
                icon={<i className="bx bx-clipboard"></i>}
                onClick={() => {
                    setSelectedOrder(record);
                    setOpenTimeline(true);
                }}
            >
                Timeline
            </Button>
        </div>
    );
    const oldColumns = [
        {
            title: "Type d'attestation",
            dataIndex: "typeAttestation",
            key: "3",
            render: (_, record) => (
                <>
                    {record.typeAttestation} - {record?.categorie?.nom}
                </>
            ),
        },

        {
            title: "Nombre", dataIndex: "nombre", key: "2",
        }, {
            title: "Type d'attestation", dataIndex: "typeAttestation", key: "3", render: (_, record) => (<>
                {record.typeAttestation} - {record?.categorie?.nom}
            </>),
        }, {
            title: "Statut commande",
            dataIndex: "statutCommande",
            key: "4",
            render: (_, record) => (<Tag color={getTagColor(record.statutCommande)}>
                {record.statutCommande}
            </Tag>),
        }, {
            title: "Date de Commande",
            dataIndex: "dateEmission",
            key: "dateEmission",
            render: (_, record) => (<>{new Date(record.dateEmission).toLocaleDateString()}</>),
        }, {
            title: "Date de reception",
            dataIndex: "updatedAt",
            key: "updatedAt",
            render: (_, record) => (<>{new Date(record.updatedAt).toLocaleDateString()}</>),
        },];
    console.log(updateCommentaire)
    const validation = (lotA, updateCommentaire, reglementBonCommandeDTO) => {
        console.log("Code de la commande à valider :", lotA);
        const com = updateCommentaire
        console.log(updateCommentaire)
        const validationBonCommandeDTO = {
            bonCommandeCodeId: lotA, commentaire: com, reglementBonCommandeDTO: reglementBonCommandeDTO
        }
        console.log(validationBonCommandeDTO)
        validateWithComment(validationBonCommandeDTO).then(r => {
            toast("Commande validée avec succès !");
            setReload((prevState) => !prevState)
        }).catch((error) => {
            console.error("Erreur lors de la validation :", error);
            toast("Erreur lors de la validation !");
        });
    };


    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === "nombre" && "number",
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    const commandesReceptionnees = commandes.filter(
        (c) => c.statutCommande === "RECEPTIONNE" || c.statutCommande === "DELIVRE",
    );
    const autresCommandes = commandes.filter(
        (c) =>
            c.statutCommande !== "RECEPTIONNE" &&
            c.statutCommande !== "DELIVRE" &&
            c.statutCommande !== "REJETE",
    );

    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <SideBar/>

                <div className="layout-page">
                    <NavBar/>

                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <>
                                <Button
                                    type="primary"
                                    icon={<PlusOutlined/>}
                                    onClick={() => setOrderModalOpen(true)}
                                    disabled={editingKey !== ""}
                                >
                                    Initier une nouvelle commande
                                </Button>
                                <Tabs
                                    defaultActiveKey="2"
                                    style={{marginTop: 20}}
                                    items={[
                                        {
                                            label: "Commandes en  cours",
                                            key: "2",
                                            children: (
                                                <Spin spinning={loadingOrders}>
                                                    <Form form={form} component={false}>
                                                        <Table
                                                            components={{
                                                                body: {
                                                                    cell: EditableCell,
                                                                },
                                                            }}
                                                            columns={mergedColumns}
                                                            dataSource={autresCommandes}
                                                            rowKey="codeId"
                                                            expandable={{
                                                                expandedRowRender,
                                                            }}
                                                        />
                                                    </Form>
                                                </Spin>
                                            ),
                                        },
                                        {
                                            label: "Commandes Receptionnées",
                                            key: "1",
                                            children: (
                                                <Spin spinning={loadingOrders}>
                                                    <Table
                                                        columns={oldColumns}
                                                        dataSource={commandesReceptionnees}
                                                        rowKey="codeId"
                                                    />
                                                </Spin>
                                            ),
                                        },
                                    ]}
                                />
                                <PaymentConfirmationModal
                                    visible={modalVisible}
                                    onCancel={handleCancel}
                                    onOk={validation}
                                    lotA={lotA}
                                    statutCommande={statutCommande}
                                    typePaiement={typePaiement}
                                />
                                <Modal
                                    open={orderModalOpen}
                                    loading={loading}
                                    onOk={onSubmitCommandeAttestation}
                                    onCancel={() => setOrderModalOpen(false)}
                                    zIndex={1900}
                                    centered
                                >
                                    <h4>Lancer une commande</h4>
                                    <p>Effectuer une commande de lot(s) d'attestations</p>
                                    <form>
                                        <div className="form-group">
                                            <label>Nombre d'attestations</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                value={nombre}
                                                onChange={(e) => setNombre(parseInt(e.target.value))}
                                                placeholder="Entrez le nombre"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Type d'attestation</label>
                                            <select
                                                className="form-control"
                                                value={selectedTypeAttestation}
                                                onChange={(e) =>
                                                    setSelectedTypeAttestation(e.target.value)
                                                }
                                            >
                                                <option value="SIMPLE">SIMPLE</option>
                                                <option value="CEDEAO">CEDEAO</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>Catégorie</label>
                                            <select
                                                className="form-control"
                                                value={selectedCategorie}
                                                onChange={(e) => setSelectedCategorie(e.target.value)}
                                            >
                                                {categories.map((cat, index) => (
                                                    <option key={index} value={cat.codeCategorie}>
                                                        {cat.nom}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label>Commentaire</label>
                                            <textarea
                                                className="form-control"
                                                rows="3"
                                                value={commentaire}
                                                onChange={(e) => setCommentaire(e.target.value)}
                                                placeholder="Ajoutez un commentaire"
                                            ></textarea>
                                        </div>
                                    </form>
                                </Modal>
                            </>
                            <OrderTimeline
                                open={openTimeline}
                                cancel={() => setOpenTimeline(false)}
                                order={selectedOrder}
                            />
                        </div>

                        <Footer/>

                        <div className="content-backdrop fade"></div>
                    </div>
                </div>
            </div>

            <div className="layout-overlay layout-menu-toggle"></div>

            <div className="drag-target"></div>
        </div>
    );
};

export default NosCommandes;
