// export const API_URL = "http://localhost:8088";
// export const API_URL = "http://130.61.148.139:8088";
export const API_URL = "https://poolinsapptest.ddns.net";
export const TOKEN =
  sessionStorage.getItem("n") +
  "." +
  sessionStorage.getItem("t") +
  "." +
  sessionStorage.getItem("k");
export const LOGGED_USER = JSON.parse(sessionStorage.getItem("user"));

export const PARAMETERS = {
  accessoires: "ACCESSOIRES",
  marque: "MARQUE",
  usage: "USAGE",
  carosserie: "CAROSSERIE",
  taxe: "TAXE",
  dureeMaxSuspension: "DUREE_MAX_SUSPENSION",
  dureeNonSuspension: "DUREE_NON_SUSPENSION",
  typeProfil: "TYPE_PROFIL",
  commission: "COMMISSION",
  banque: "BANQUE",
  natureActe: "NATURE_ACTE",
  prejudice: "PREJUDICE",
  causeSinistre: "CAUSE_SINISTRE",
  profession: "PROFESSION",
  ville: "VILLE",
  tauxCommission: "TAUX_COMMISSION",
  typeAttestation: "TYPE_ATTESTATION",
  motifRevision: "MOTIF_REVISION",
};
