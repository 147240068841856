import SideBar from "../../components/SideBar";
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";
import {useEffect, useState} from "react";
import {getAllDecaissementWithSinistre, rejectDecaissement, validateDecaissement} from "../../hooks/useHooks";
import InformationCard from "../../components/statistics/InformationCard";
import {Card, DatePicker, Dropdown, Menu, Modal, Spin, Table, Tag} from "antd";
import {EllipsisOutlined} from "@ant-design/icons";
import {frenchDate} from "../../variables/functions";
import {toast} from "react-toastify";

const {confirm} = Modal
const {RangePicker} = DatePicker;

const DataCards = ({r_reglements}) => {
    const reglementStats = {
        EN_ATTENTE: r_reglements?.filter(d => d?.etatDecaissement === 'EN_ATTENTE').length || 0,
        VALIDE: r_reglements?.filter(d => d?.etatDecaissement === 'VALIDE').length || 0,
        REJETE: r_reglements?.filter(d => d?.etatDecaissement === 'REJETE').length || 0,
        ENCAISSE: r_reglements?.filter(d => d?.etatDecaissement === 'ENCAISSE').length || 0,
    };

    const renderTags = (stats) => (
        <div style={{display: 'flex', justifyContent: 'space-between', gap: '16px', width: '100%', fontSize: '14px'}}>
            <Tag color="blue" style={{flex: 1, textAlign: 'center'}}>En attente: {stats.EN_ATTENTE}</Tag>
            <Tag color="green" style={{flex: 1, textAlign: 'center'}}>Validé: {stats.VALIDE}</Tag>
            <Tag color="red" style={{flex: 1, textAlign: 'center'}}>Rejeté: {stats.REJETE}</Tag>
            <Tag color="gold" style={{flex: 1, textAlign: 'center'}}>Encaissé: {stats.ENCAISSE}</Tag>
        </div>
    );

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '16px',
            justifyContent: 'space-between',
            width: '100%',
            marginTop: '5px'
        }}>
            <Card
                style={{
                    flex: 1,
                    border: '1px solid #d9d9d9',
                    borderRadius: '8px',
                }}
            >
                {renderTags(reglementStats)}
            </Card>
        </div>
    );
};


const ValidationDecaissements = () => {
    const [decaissements, setDecaissements] = useState([]);
    const [decaissementsLoading, setDecaissementsLoading] = useState(false);
    const [filteredDecaissements, setFilteredDecaissements] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [refetch, setRefetch] = useState(false)

    useEffect(() => {
        const fetchAllDecaissements = async () => {
            setDecaissementsLoading(true);
            const response = await getAllDecaissementWithSinistre();
            setDecaissements(response.data);
            setFilteredDecaissements(response.data);
            setDecaissementsLoading(false);
            console.log(refetch)
        };
        fetchAllDecaissements();
    }, []);


    console.log(refetch)
    const onlyDecaissements = decaissements?.map((ev) => ev?.decaissement);

    const r_encaissements = onlyDecaissements?.filter(decaissement => decaissement?.encaissement === true);
    const r_decaissements = onlyDecaissements?.filter(decaissement => decaissement?.encaissement === false || decaissement?.encaissement === null);

    console.log(onlyDecaissements)


    const rDecaissements = {
        container: 'col-sm-6 col-xl-6',
        label: 'Decaissements',
        value: r_decaissements?.length || 0,
        description: 'Nombre total des decaissements',
        avatar: 'bx bx-right-top-arrow-circle bx-rotate-60',
        state: 'bg-label-info'
    };

    const rEncaissements = {
        container: 'col-sm-6 col-xl-6',
        label: 'Encaissements',
        value: r_encaissements?.length || 0,
        description: 'Nombre total des encaissements',
        avatar: 'bx bx-right-top-arrow-circle bx-rotate-180',
        state: 'bg-label-info'
    };


    const source = decaissements?.map((ev) => {
        const {sinistreNumSinistre, sinistreNumPolice, sinistreSouscripteur, decaissement,} = ev;
        const {
            codeId, createdAt, etatDecaissement, montantGlobal, beneficiaire
        } = decaissement || {};

        return {
            key: codeId,
            numSinistre: sinistreNumSinistre,
            numPolice: sinistreNumPolice,
            souscripteur: sinistreSouscripteur,
            beneficiaire: beneficiaire?.nom + ' ' + beneficiaire?.prenom,
            montantGlobal: montantGlobal,
            etatDecaissement,
            dateDecaissement: frenchDate(createdAt)
        };
    }).filter(item => item !== null);

    const columns = [{
        title: 'Numero de sinistre', dataIndex: 'numSinistre', key: 'numSinistre',
    }, {
        title: 'Numero de police', dataIndex: 'numPolice', key: 'numPolice',
    }, {
        title: 'Souscripteur', dataIndex: 'souscripteur', key: 'souscripteur',
    }, {
        title: 'Beneficiaire', dataIndex: 'beneficiaire', key: 'beneficiaire',
    }, {
        title: 'Montant global', dataIndex: 'montantGlobal', key: 'montantGlobal',
    }, {
        title: 'Etat', dataIndex: 'etatDecaissement', key: 'etatDecaissement', render: (etatDecaissement) => {
            let color;
            switch (etatDecaissement) {
                case 'VALIDE':
                    color = 'green';
                    break;
                case 'EN_ATTENTE':
                    color = 'orange';
                    break;
                case 'REJETE':
                    color = 'red';
                    break;
                default:
                    color = 'gray';
            }
            return <Tag color={color}>{etatDecaissement}</Tag>;
        }
    }, {
        title: 'Date du decaissement', dataIndex: 'dateDecaissement', key: 'dateDecaissement',
    }, {
        title: 'Action', key: 'action', render: (_, record) => {
            const menu = (<Menu>
                <Menu.Item key="valider" icon={<i className="bx bx-check"></i>}
                           onClick={() => handleValiderDecaissement(record)}>
                    Valider
                </Menu.Item>
                <Menu.Item key="rejeter" icon={<i className="bx bx-x"></i>}
                           onClick={() => handleRejeterDecaissement(record)}>
                    Rejeter
                </Menu.Item>
                <Menu.Item key="details" icon={<i className="bx bx-show"></i>}>
                    Voir les details
                </Menu.Item>
            </Menu>);

            return (<Dropdown overlay={menu} trigger={["click"]}>
                <EllipsisOutlined style={{cursor: "pointer"}}/>
            </Dropdown>);
        },
    },];

    const handleDateFilter = (dates) => {
        if (!dates) {
            setFilteredDecaissements(decaissements);
            return;
        }
        const [start, end] = dates.map(date => date.startOf('day').toDate());

        setFilteredDecaissements(decaissements.filter(ev => {
            const createdAt = ev?.decaissement?.createdAt;
            if (!createdAt) return false;
            const decaissementDate = new Date(createdAt);
            return decaissementDate >= start && decaissementDate <= end;
        }));
    };
    const confirmColumns = [{
        title: 'Numéro de sinistre', dataIndex: 'numSinistre', key: 'numSinistre',
    }, {
        title: 'Numéro de police', dataIndex: 'numPolice', key: 'numPolice',
    }, {
        title: 'Souscripteur', dataIndex: 'souscripteur', key: 'souscripteur',
    }, {
        title: 'Nouvelle provision', dataIndex: 'nouvelleProvision', key: 'nouvelleProvision',
    },]
    const [updateEvaluationLoading, setUpdateEvaluationLoading] = useState(false)
    const findEvaluationByCodeId = (codeId) => {
        return onlyDecaissements?.filter((ev) => ev?.codeId === codeId)[0]
    }

    console.log(decaissements)

    const handleValiderDecaissement = (record) => {
        confirm({
            title: 'Voulez-vous vraiment valider cette évaluation ?',
            icon: <i className="bx bx-check-circle" style={{color: 'green', fontSize: '24px'}}></i>,

            width: 800,
            content: (<div>
                <Table
                    dataSource={[record]}
                    columns={confirmColumns}
                    pagination={false}
                    bordered
                />
            </div>),
            onOk() {
                try {
                    console.log('Évaluation validée:', record);
                    console.log(findEvaluationByCodeId(record.key))
                    const decaissement = findEvaluationByCodeId(record.key)
                    decaissement.etatDecaissement = 'VALIDE'
                    validateDecaissement(decaissement?.codeId)
                    setRefetch((prev) => !prev);
                    toast.success('Évaluation validée avec succès')
                } catch (e) {
                    console.log(e)
                    toast.error('Erreur lors de la validation de l\'évaluation')
                }

            },
            onCancel() {
                console.log('Validation annulée');
            },
        });
    };
    const handleRejeterDecaissement = (record) => {
        confirm({
            title: 'Voulez-vous vraiment rejeter cette évaluation ?',
            icon: <i className="bx bx-x-circle" style={{color: 'darkred', fontSize: '24px'}}></i>,
            width: 800,
            content: (<div>
                <Table
                    dataSource={[record]}
                    columns={confirmColumns}
                    pagination={false}
                    bordered
                />
            </div>),
            onOk() {
                try {
                    console.log('Évaluation rejetée:', record);
                    console.log(findEvaluationByCodeId(record.key))
                    const decaissement = findEvaluationByCodeId(record.key)
                    decaissement.etatDecaissement = 'REJETE'
                    rejectDecaissement(decaissement.codeId)
                    setRefetch((prev) => !prev);
                    toast.success('Évaluation rejetée avec succès')
                } catch (e) {
                    console.log(e)
                    toast.error('Erreur lors du rejet de l\'évaluation')
                } finally {
                    setRefetch(false)
                }
            },
            onCancel() {
                console.log('Validation annulée');
            },
        });
    };

    return (<div className="layout-wrapper layout-content-navbar  ">
        <div className="layout-container">
            <SideBar currentMenu={"SINISTRES"}/>
            {/* <!-- Layout container --> */}
            <div className="layout-page">
                <NavBar/>
                {/* <!-- Content wrapper --> */}
                <div className="content-wrapper">
                    {/* <!-- Content --> */}
                    <div className="container-xxl flex-grow-1 container-p-y">
                        <div className={'container'}>
                            <div className={'container'}>
                                <Spin spinning={decaissementsLoading}>
                                    <div className="row g-6 mb-6">
                                        <InformationCard cardInfo={rEncaissements}>
                                            <DataCards r_reglements={r_encaissements}/>
                                        </InformationCard>
                                        <InformationCard cardInfo={rDecaissements}>
                                            <DataCards r_reglements={r_decaissements}/>
                                        </InformationCard>
                                    </div>


                                </Spin>
                                <Spin spinning={decaissementsLoading}>
                                    <RangePicker onChange={handleDateFilter} style={{marginBottom: '16px'}}/>
                                    <Table
                                        dataSource={source}
                                        columns={columns}
                                        pagination={{
                                            showSizeChanger: true,
                                            onShowSizeChange: (_, size) => setPageSize(size),
                                            pageSize: pageSize
                                        }}
                                    />
                                </Spin>

                            </div>
                        </div>

                    </div>
                    {/* <!-- / Content --> */}

                    {/* <!-- Footer --> */}
                    <Footer/>
                    {/* <!-- / Footer --> */}

                    <div className="content-backdrop fade"></div>
                </div>
                {/* <!-- Content wrapper --> */}
            </div>
            {/* <!-- / Layout page --> */}
        </div>

        {/* <!-- Overlay --> */}
        <div className="layout-overlay layout-menu-toggle"></div>

        {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
        <div className="drag-target"></div>
    </div>);
};

export default ValidationDecaissements;
