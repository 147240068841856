import { Modal, Select } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { API_URL, TOKEN } from "../../../variables/constants";

const AddReduction = ({
  show,
  handleClose,
  editMode,
  selectedReduction = {},
}) => {
  const [categories, setCategories] = useState([]);
  const [garanties, setGaranties] = useState([]);
  const [selectedCategorie, setSelectedCategorie] = useState(0);
  const [selectedGaranties, setSelectedGaranties] = useState([]);
  const [nom, setNom] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);

  useEffect(() => {
    if (show && editMode && selectedReduction.nom) {
      // Mode édition : Pré-remplir les champs si une réduction est sélectionnée
      setNom(selectedReduction.nom || "");
      setDescription(selectedReduction.commentaire || "");
      setSelectedCategorie(selectedReduction.categorie?.categorieId || 0);
      setSelectedGaranties(selectedReduction.garanties || []);
    } else if (show && !editMode) {
      // Mode ajout : Réinitialiser les champs
      setNom("");
      setDescription("");
      setSelectedCategorie(0);
      setSelectedGaranties([]);
    }

    // Charger les catégories et garanties
    const fetchCategoriesAndGaranties = async () => {
      setIsDataLoading(true);
      try {
        const [categoriesRes, garantiesRes] = await Promise.all([
          axios.get(`${API_URL}/api/categorie`, {
            headers: { Authorization: `Bearer ${TOKEN}` },
          }),
          axios.get(`${API_URL}/api/garantie`, {
            headers: { Authorization: `Bearer ${TOKEN}` },
          }),
        ]);
        setCategories(categoriesRes.data || []);
        setGaranties(garantiesRes.data || []);
      } catch (error) {
        toast.error(
          "Erreur lors du chargement des catégories et des garanties.",
        );
      } finally {
        setIsDataLoading(false);
      }
    };

    fetchCategoriesAndGaranties();
  }, [show, editMode, selectedReduction]);

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!nom || selectedCategorie === 0 || selectedGaranties.length === 0) {
      toast.error(
        "Veuillez remplir tous les champs obligatoires (Nom, Catégorie, Garanties).",
      );
      setLoading(false);
      return;
    }

    const categorieObj = categories.find(
      (cat) => cat.codeCategorie === selectedCategorie,
    );
    const reductionData = {
      ...(editMode && selectedReduction ? selectedReduction : {}),
      nom,
      commentaire: description,
      categorie: categorieObj,
      garanties: selectedGaranties,
    };

    try {
      if (editMode) {
        await axios.put(`${API_URL}/api/reduction`, reductionData, {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
          },
        });
        toast.success("Réduction modifiée avec succès !");
      } else {
        await axios.post(`${API_URL}/api/reduction`, reductionData, {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
          },
        });
        toast.success("Réduction ajoutée avec succès !");
      }
      handleClose();
      window.location.reload();
    } catch (error) {
      console.error("Erreur lors de l'opération :", error);
      toast.error("Erreur lors de l'opération.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={show}
      onCancel={handleClose}
      footer={null}
      centered
      title={
        <div style={{ textAlign: "center", fontWeight: "bold" }}>
          {editMode ? "Modifier une réduction" : "Ajouter une réduction"}
        </div>
      }
    >
      <form onSubmit={onSubmit}>
        <div className="form-group">
          <label htmlFor="nom">Nom</label>
          <input
            type="text"
            id="nom"
            className="form-control"
            placeholder="Nom de la réduction"
            value={nom}
            onChange={(e) => setNom(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="categorie">Catégorie</label>
          <select
            id="categorie"
            className="form-select"
            value={selectedCategorie}
            onChange={(e) => setSelectedCategorie(e.target.value)}
            required
          >
            <option value="">Sélectionner une catégorie</option>
            {categories.map((cat) => (
              <option key={cat.categorieId} value={cat.codeCategorie}>
                {cat.nom}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="garanties">Garanties</label>
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            placeholder="Sélectionner les garanties"
            value={selectedGaranties.map((gar) => gar.codeId.toString())}
            onChange={(values) => {
              const selectedObjects = garanties.filter((g) =>
                values.includes(g.codeId.toString()),
              );
              setSelectedGaranties(selectedObjects);
            }}
            options={
              selectedCategorie
                ? garanties
                    .filter(
                      (gar) =>
                        gar.categorie.codeCategorie === selectedCategorie,
                    )
                    .map((gar) => ({
                      label: gar.nom,
                      value: gar.codeId.toString(),
                    }))
                : garanties.map((gar) => ({
                    label: gar.nom,
                    value: gar.codeId.toString(),
                  }))
            }
          />
        </div>
        <div className="form-group">
          <label htmlFor="description">Description</label>
          <textarea
            id="description"
            className="form-control"
            placeholder="Description de la réduction"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </div>
        <div className="text-center mt-4">
          <button type="submit" className="btn btn-primary" disabled={loading}>
            {loading ? "Enregistrement..." : "Enregistrer"}
          </button>
          <button
            type="button"
            className="btn btn-secondary ms-2"
            onClick={handleClose}
          >
            Annuler
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default AddReduction;
