// AddTypeProfil.js
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { API_URL, TOKEN } from "../../../variables/constants";
import { toast } from "react-toastify";

const AddTypeProfil = ({ show, handleClose, editMode, selectedTypeProfil }) => {
  const [loading, setLoading] = useState(false);
  const [nom, setNom] = useState("");

  useEffect(() => {
    if (editMode && selectedTypeProfil) {
      setNom(selectedTypeProfil.libelleParametre || "");
    } else {
      setNom("");
    }
  }, [editMode, selectedTypeProfil]);

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const typeProfilData = {
      ...selectedTypeProfil,
      libelleParametre: nom,
      typeParametre: "TYPE_PROFIL",
    };

    const request = editMode
      ? axios.put(`${API_URL}/api/parametre`, typeProfilData, {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
          },
        })
      : axios.post(`${API_URL}/api/parametre`, typeProfilData, {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
          },
        });

    request
      .then(() => {
        toast.success(
          editMode
            ? "Type de profil modifié avec succès"
            : "Type de profil ajouté avec succès",
        );
        handleClose();
      })
      .catch((err) => {
        console.error("Error saving type profil:", err);
        toast.error("Erreur lors de l'enregistrement du type de profil");
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Body>
        <button
          type="button"
          className="btn-close btn-primary"
          onClick={handleClose}
          aria-label="Close"
        ></button>
        <div className="text-center mb-6">
          <h4>{editMode ? "Modifier le" : "Ajouter un"} type de profil</h4>
          <p>
            {editMode ? "Modifier le" : "Ajouter un nouveau"} type de profil
          </p>
        </div>
        <form onSubmit={onSubmit}>
          <div className="mb-3">
            <label className="form-label">Nom</label>
            <input
              type="text"
              className="form-control"
              placeholder="Nom du type de profil"
              value={nom}
              onChange={(e) => setNom(e.target.value)}
            />
          </div>
          <div className="text-center">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}
            >
              {loading ? "Enregistrement..." : "Enregistrer"}
            </button>
            <button
              type="button"
              className="btn btn-secondary ms-3"
              onClick={handleClose}
            >
              Annuler
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddTypeProfil;
