import axios from 'axios';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { API_URL, LOGGED_USER, TOKEN } from '../../variables/constants';

const AddIntermediaire = ({ show, handleClose, editMode, agence }) => {
    const [formData, setFormData] = useState({
        nom: "",
        prenom: "",
        genre: "",
        fonction: "",
        adresseResidence: "",
        telephone: "",
        email: "",
        type: "",
        codeIntermediaire: ""
    });
    const [loading, setLoading] = useState(false);
    const [mailExists, setMailExists] = useState(false);

    const [errors, setErrors] = useState({});
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === "email") {
            checkIfEmailExists(value);
        }
        setFormData({ ...formData, [name]: value });
    };

    const validateForm = (email) => {
        const newErrors = {};
        const phonePattern = /^(22|90|91|92|93|96|98|99|70|71|79)\d{6}$/;
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!formData.nom) newErrors.nom = "Nom requis";
        if (formData.nom.length < 3)
            newErrors.nom = "Le nom doit avoir au moins 2 caractères";
        if (!formData.prenom) newErrors.prenom = "Prénom requis";
        if (formData.prenom.length < 3)
            newErrors.prenom = "Le prénom doit avoir au moins 2 caractères";
        if (!formData.genre) newErrors.genre = "Genre requis";
        if (!formData.codeIntermediaire) newErrors.codeIntermediaire = "Code intermédiaire requis";
        if (!formData.type) newErrors.type = "Veuillez sélectionner le type d'intermédiaire";
        if (!formData.telephone) newErrors.telephone = "Numéro de téléphone requis";
        if (!phonePattern.test(formData.telephone))
            newErrors.telephone = "Numéro de téléphone invalide";
        if (!email) newErrors.email = "Adresse mail requis";
        if (!emailPattern.test(email))
            newErrors.email = "L'adresse email n'est pas valide.";
        // Add more validation rules if needed
        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const checkIfEmailExists = (email) => {
        axios
            .get(`${API_URL}/api/utilisateur/check/${email}`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                if (res.data)
                    toast("Il existe déjà un utilisateur avec cette adresse mail dans le système!");
                setMailExists(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        let v = formData.email;
        if (agence?.organisme?.domaine) {
            v += "@" + agence?.organisme?.domaine;
        }
        checkIfEmailExists(v);

        if (validateForm(v) && !mailExists) {
            const user = {
                nom: formData.nom,
                prenom: formData.prenom,
                email: v,
                contact: [formData.telephone],
                password: formData.password,
                genre: formData.genre,
                adresse: formData.adresseResidence,
                fonction: "INTERMEDIAIRE",
                type: formData.type,
                typePersonne: "PHYSIQUE",
                agence: agence ? agence : LOGGED_USER.agence,
                username: formData.codeIntermediaire
            };
            console.log("userrrrrr", user);
            console.log("userrrrrrStringified", JSON.stringify(user));
            let agenceCode = agence ? agence?.codeAgence : LOGGED_USER.agence.codeAgence;
            axios
                .post(`${API_URL}/api/intermediaire/new/${agenceCode}`, JSON.stringify(user), {
                    headers: {
                        Authorization: `Bearer ${TOKEN}`,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                        "Access-Control-Allow-Headers":
                            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                    },
                })
                .then((res) => {
                    console.log(res.data);
                    setLoading(false);
                    // navigate("/utilisateurs", { replace: true });
                    toast("Intermediaire ajouté avec succès");
                    handleClose();
                })
                .catch((err) => {
                    console.log(err);
                    toast("Erreur lors de l'ajout de l'intermédiaire");
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    };


    return (
        <Modal show={show} onHide={handleClose} size="lg" centered>
            <Modal.Body>
                <button
                    type="button"
                    className="btn-close btn-primary"
                    onClick={handleClose}
                    aria-label="Close"
                ></button>
                <div className="text-center mb-6">
                    <h4 className="address-title mb-2">
                        {editMode ? "Modifier l'" : "Ajouter un"} intermédiaire
                    </h4>
                    <p className="address-subtitle">
                        {editMode ? "Modifier l'" : "Ajouter un nouvel"} intermédiaire de l'agence
                    </p>
                </div>
                <form id="addNewAddressForm" className="row g-12">
                    <div className="col-sm-6">
                        <label className="form-label" htmlFor="nom">
                            Nom
                        </label>
                        <input
                            type="text"
                            id="nom"
                            name="nom"
                            className={`form-control ${errors.nom ? "is-invalid" : ""
                                }`}
                            placeholder="Entrez le nom"
                            value={formData.nom}
                            onChange={handleInputChange}
                        />
                        {errors.nom && (
                            <div className="invalid-feedback">
                                {errors.nom}
                            </div>
                        )}
                    </div>
                    <div className="col-sm-6">
                        <label className="form-label" htmlFor="prenom">
                            Prénom(s)
                        </label>
                        <input
                            type="text"
                            id="prenom"
                            name="prenom"
                            className={`form-control ${errors.prenom ? "is-invalid" : ""
                                }`}
                            placeholder="Entrez le prénom"
                            value={formData.prenom}
                            onChange={handleInputChange}
                        />
                        {errors.prenom && (
                            <div className="invalid-feedback">
                                {errors.prenom}
                            </div>
                        )}
                    </div>
                    <div className="col-sm-6">
                        <label className="form-label" htmlFor="codeIntermediaire">
                            Code de l'intermédiaire
                        </label>
                        <input
                            type="text"
                            id="codeIntermediaire"
                            name="codeIntermediaire"
                            className={`form-control ${errors.codeIntermediaire ? "is-invalid" : ""
                                }`}
                            placeholder="Entrez le code intermédiaire"
                            value={formData.codeIntermediaire}
                            onChange={handleInputChange}
                        />
                        {errors.codeIntermediaire && (
                            <div className="invalid-feedback">
                                {errors.codeIntermediaire}
                            </div>
                        )}
                    </div>
                    <div className="col-sm-6">
                        <label class="form-label" for="genre">
                            Genre
                        </label>
                        <select
                            id="genre"
                            name="genre"
                            class={`form-control w-100 ${errors.genre ? "is-invalid" : ""
                                }`}
                            data-style="btn-default"
                            value={formData.genre}
                            onChange={handleInputChange}
                        >
                            <option value="">
                                Selectionnez le genre
                            </option>
                            <option value="MASCULIN">Masculin</option>
                            <option value="FEMININ">Féminin</option>
                        </select>
                        {errors.genre && (
                            <div className="invalid-feedback">
                                {errors.genre}
                            </div>
                        )}
                    </div>
                    <div className="col-sm-6">
                        <label className="form-label" htmlFor="nom">
                            Type d'intermédiaire
                        </label>
                        <select
                            id="type"
                            name="type"
                            class={`form-control w-100 ${errors.type ? "is-invalid" : ""
                                }`}
                            data-style="btn-default"
                            value={formData.type}
                            onChange={handleInputChange}
                        >
                            <option value="">
                                Selectionnez le type d'intermédiaire
                            </option>
                            <option value="AGENT_GENERAL">Agent général</option>
                            <option value="APPORTEUR_REGULIER">Apporteur régulier</option>
                            <option value="APPORTEUR_OCCASIONNEL">Apporteur occasionnel</option>
                            <option value="COURTIER">Courtier</option>
                        </select>
                        {errors.type && (
                            <div className="invalid-feedback">
                                {errors.type}
                            </div>
                        )}
                    </div>
                    <div className="col-sm-6">
                        <label className="form-label" htmlFor="email">
                            Adresse mail
                        </label>
                        <div class="input-group input-group-merge">
                            <input
                                type="text"
                                id="email"
                                name="email"
                                className={`form-control ${errors.email ? "is-invalid" : ""
                                    }`}
                                placeholder="Veuillez renseigner l'adresse mail"
                                value={formData.email}
                                onChange={handleInputChange}
                            />
                            {agence?.organisme?.domaine && (
                                <span
                                    class="input-group-text"
                                    id="basic-addon33"
                                >
                                    @{agence?.organisme?.domaine}
                                </span>
                            )}
                            {errors.email && (
                                <div className="invalid-feedback">
                                    {errors.email}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <label class="form-label" for="telephone">
                            Numéro de téléphone
                        </label>
                        <div class="input-group">
                            <span class="input-group-text">
                                TG (+228)
                            </span>
                            <input
                                type="text"
                                id="telephone"
                                name="telephone"
                                placeholder="00 00 00 00"
                                className={`form-control phone-number-mask ${errors.telephone ? "is-invalid" : ""
                                    }`}
                                value={formData.telephone}
                                onChange={handleInputChange}
                            />
                        </div>
                        {errors.telephone && (
                            <div className="invalid-feedback">
                                {errors.telephone}
                            </div>
                        )}
                    </div>
                    <div className="col-sm-6">
                        <label
                            class="form-label"
                            for="adresseResidence"
                        >
                            Adresse de résidence
                        </label>
                        <textarea
                            id="adresseResidence"
                            name="adresseResidence"
                            class="form-control bootstrap-maxlength-example"
                            maxlength="255"
                            placeholder="L'adresse de résidence de l'intermédiaire"
                            value={formData.adresseResidence}
                            onChange={handleInputChange}
                        ></textarea>
                    </div>
                    <div className="col-12 text-center">
                        <button
                            onClick={(e) => handleSubmit(e)}
                            className="btn btn-primary me-3"
                            disabled={loading}
                        >
                            {loading && (
                                <span
                                    class="spinner-grow me-1"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            )}
                            {loading ? `Enregistrement...` : `Enregistrer`}
                        </button>
                        <button
                            type="reset"
                            className="btn btn-label-secondary"
                            onClick={handleClose}
                        >
                            Annuler
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default AddIntermediaire