import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeliveredProcedureOutlined,
  DollarCircleOutlined,
  ExclamationCircleFilled,
  FileDoneOutlined,
  FileProtectOutlined,
  HourglassOutlined,
  ShoppingCartOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Spin,
  Table,
  Tabs,
  Tag,
  Tooltip,
} from "antd";
import { API_URL, LOGGED_USER, TOKEN } from "../../../variables/constants";
import { toast } from "react-toastify";
import SideBar from "../../../components/SideBar";
import NavBar from "../../../components/NavBar";
import Footer from "../../../components/Footer";
import axiosInstance from "../../../variables/api/axiosInstance";
import { frenchDate } from "../../../variables/functions";
import {
  updateBonCommande,
  validateWithComment,
} from "../../../hooks/useHooks";
import OrderTimeline from "../components/modals/OrderTimeline";
import AffecterLotAttestation from "../components/modals/AffecterLotAttestation";

const CompaniesCommandes = () => {
  const [commandes, setCommandes] = useState([]);
  const [loadingOrders, setLoadingOrders] = useState(false);
  const [commentaire, setCommentaire] = useState("");
  const [openTimeline, setOpenTimeline] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [categories, setCategories] = useState([]);
  const [compagnie, setCompagnie] = useState("");
  const [livrerLoading, setLivrerLoading] = useState(false);
  const [selectedCompagnie, setSelectedCompagnie] = useState({});
  const [selectedBonCommandeId, setSelectedBonCommandeId] = useState(null);
  const [affecterLotModal, setAffecterLotModal] = useState(false);
  const [editNombre, setEditNombre] = useState();
  const [getCommandesLoading, setGetCommandesLoading] = useState(false);

  const reject = (record, updateCommentaire) => {
    const updatedBonCommande = { ...record };
    updatedBonCommande.statutCommande = "REJETE";
    updatedBonCommande.motifRejet = updateCommentaire;
    updateBonCommande(updatedBonCommande).then((r) => {
      setReload((prevState) => !prevState);
      toast.success("Commande rejetée avec succes");
    });
  };
  let com = "";

  console.log(selectedCompagnie);

  console.log(commandes);

  const showValidationAttestationConfirm = (lotA, statutCommande) => {
    Modal.confirm({
      title: `Etes-vous sûr de vouloir ${getActionVerb(statutCommande)} la commande?`,
      icon: <ExclamationCircleFilled color={"blue"} />,
      content: (
        <>
          <p style={{ color: "red", marginBottom: "1rem" }}>
            Toutes vos actions sont historisées
          </p>
          <div style={{ marginBottom: "1rem" }}>
            <label htmlFor="commentaire">Faites un commentaire :</label>
            <textarea
              id="commentaire"
              style={{
                width: "100%",
                marginTop: "0.5rem",
                padding: "0.5rem",
                borderRadius: "4px",
                border: "1px solid #d9d9d9",
              }}
              placeholder="Votre commentaire ici..."
              onChange={(e) => {
                com = e.target.value;
              }}
            />
          </div>
        </>
      ),
      okText: "Oui",
      okType: "primary",
      cancelText: "Annuler",
      onOk: () => validation(lotA, com),
    });
  };

  const showRejectBonCommandeConfirm = (record) => {
    Modal.confirm({
      title: `Etes-vous sûr de vouloir rejeter la commande?`,
      icon: <ExclamationCircleFilled color={"blue"} />,
      content: (
        <>
          <p style={{ color: "red", marginBottom: "1rem" }}>
            Toutes vos actions sont historisées
          </p>
          <div style={{ marginBottom: "1rem" }}>
            <label htmlFor="commentaire">Motif de rejet :</label>
            <textarea
              id="commentaire"
              style={{
                width: "100%",
                marginTop: "0.5rem",
                padding: "0.5rem",
                borderRadius: "4px",
                border: "1px solid #d9d9d9",
              }}
              placeholder="Votre commentaire ici..."
              onChange={(e) => {
                com = e.target.value;
              }}
            />
          </div>
        </>
      ),
      okText: "Oui",
      okType: "primary",
      cancelText: "Annuler",
      onOk: () => reject(record, com),
    });
  };

  const [reload, setReload] = useState(false);

  const getAntdIcon = (status) => {
    const statusIconMap = {
      INITIE: <ShoppingCartOutlined />, // Order placed
      VALIDE: <CheckCircleOutlined />, // Order validated
      AUTORISE: <SyncOutlined />, // Order authorized
      EN_TRAITEMENT: <HourglassOutlined />, // Order in processing
      APPROUVE: <FileProtectOutlined />, // Order approved
      REGLE: <DollarCircleOutlined />, // Payment completed
      DELIVRE: <DeliveredProcedureOutlined />, // Order delivered
      RECEPTIONNE: <FileDoneOutlined />, // Order received
      REJETE: <CloseCircleOutlined />, // Order rejected
    };

    return statusIconMap[status] || null;
  };

  const getActionVerb = (statutCommande) => {
    switch (statutCommande) {
      case "INITIE":
        return "valider";
      case "VALIDE":
        return "autoriser";
      case "AUTORISE":
        return "passer en traitement";
      case "EN_TRAITEMENT":
        return "approuver";
      case "APPROUVE":
        return "régler";
      case "REGLE":
        return "livrer";
      case "DELIVRE":
        return "réceptionner";
      default:
        return null;
    }
  };

  console.log(commandes);
  const getTagColor = (statutCommande) => {
    switch (statutCommande) {
      case "INITIE":
        return "blue";
      case "VALIDE":
        return "green";
      case "AUTORISE":
        return "gold";
      case "CONFIRME":
        return "purple";
      case "DELIVRE":
        return "cyan";
      case "RECEPTIONNE":
        return "volcano";
      default:
        return "default";
    }
  };

  useEffect(() => {
    const code = LOGGED_USER.profil.organisme.code;
    setLoadingOrders(true);
    setGetCommandesLoading(true);
    axiosInstance
      .get(`/api/bon-commande`)
      .then((res) => {
        setCommandes(res.data.reverse());
        setLoadingOrders(false);
        setGetCommandesLoading(false);
      })
      .catch(() => setLoadingOrders(false));

    axiosInstance
      .get(`/api/categorie`)
      .then((res) => {
        console.log(res.data);
        setCategories(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axiosInstance
      .get(`/api/organisme/code/${code}`)
      .then((res) => {
        console.log(res.data);
        setCompagnie(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [TOKEN, reload]);

  const columns = [
    {
      title: "Compagnie",
      dataIndex: "compagnie",
      key: "1",
      render: (_, record) => (
        <>{record?.emetteur?.profil?.organisme?.raisonSociale} </>
      ),
    },
    {
      title: "Type d'attestation",
      dataIndex: "typeAttestation",
      key: "3",
      render: (_, record) => (
        <>
          {record.typeAttestation} - {record?.categorie?.nom}
        </>
      ),
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "2",
    },

    {
      title: "Statut commande",
      dataIndex: "statutCommande",
      key: "4",
      render: (_, record) => (
        <Tag color={getTagColor(record.statutCommande)}>
          {record.statutCommande}
        </Tag>
      ),
    },
    {
      title: "Date de Commande",
      dataIndex: "dateEmission",
      key: "dateEmission",
      render: (_, record) => <>{frenchDate(record.dateEmission)}</>,
    },
    /*      {
                  title: "Action",
                  dataIndex: "actions",
                  key: "actions",
                  render: (_, record) => (
                      record.statutCommande !== 'APPROUVE' && record.statutCommande !== 'DELIVRE' ? (
                          <>
                              <Tooltip
                                  title={`${getActionVerb(record.statutCommande)?.charAt(0).toUpperCase() + getActionVerb(record.statutCommande)?.slice(1).toLowerCase()}  la commande`}>
                                  <Button
                                      type="primary"
                                      style={{backgroundColor: "#28a745", borderColor: "#28a745"}}
                                      icon={getAntdIcon(record.statutCommande)}
                                      onClick={() => {
                                          if (record.statutCommande === 'REGLE') {
                                              setSelectedBonCommandeId(record?.codeId)
                                              setSelectedCompagnie(record?.emetteur?.profil?.organisme)
                                              setAffecterLotModal(true)
                                          } else
                                              showValidationAttestationConfirm(record.codeId, record.statutCommande)

                                      }
                                      }
                                  >
                                      {`${getActionVerb(record.statutCommande)?.charAt(0).toUpperCase() + getActionVerb(record.statutCommande)?.slice(1).toLowerCase()}  la commande`}
                                  </Button>
                              </Tooltip>
                              <Tooltip title={'Editer la commande'}>
                                  <button
                                      type="button"
                                      className="btn btn-secondary btn-icon rounded-pill"
                                  >
                                      <i className="bx bx-edit-alt"></i>
                                  </button>
                              </Tooltip>
                              <Tooltip title={'Rejeter la commande'}>
                                  <button
                                      type="button"
                                      className="btn btn-danger btn-icon rounded-pill"
                                      onClick={() => showRejectBonCommandeConfirm(record)}
                                  >
                                      <i className="bx bx-message-alt-x"></i>
                                  </button>
                              </Tooltip>
                              <Tooltip title={'Timeline'}>
                                  <button
                                      type="button"
                                      className="btn btn-secondary btn-icon rounded-pill"
                                      onClick={() => {
                                          setSelectedOrder(record)
                                          setOpenTimeline(true)
                                      }}
                                  >
                                      <i className="bx bx-clipboard"></i>
                                  </button>
                              </Tooltip>
                          </>
                      ) : (
                          <Tooltip title={'Timeline'}>
                              <button
                                  type="button"
                                  className="btn btn-secondary btn-icon rounded-pill"
                                  onClick={() => {
                                      setSelectedOrder(record)
                                      setOpenTimeline(true)
                                  }}
                              >
                                  <i className="bx bx-clipboard"></i>
                              </button>
                          </Tooltip>
                      )

                  )
              },*/
  ];
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const [dataBeingEdited, setDataBeingEdited] = useState(false);
  const [nombre, setNombre] = useState();
  const isEditing = (record) => record.codeId === editingKey;

  const edit = (record) => {
    setEditNombre(record.nombre);
    form.setFieldsValue({
      nombre: record.nombre,
    });
    console.log("keyy " + nombre);
    setEditingKey(record.codeId);
    setDataBeingEdited(true);
  };
  const save = (record) => {
    const updatedNombre = form.getFieldValue("nombre");
    console.log(form.getFieldValue("nombre"));
    const bonCommande = {
      ...record,
      nombre: updatedNombre,
      oldNombre: editNombre,
    };

    updateBonCommande(bonCommande).then((r) => {
      setReload((prevState) => !prevState);
      toast.success("Commande éditée avec succes");
    });

    console.log(bonCommande);
  };
  const cancel = () => {
    setNombre(null);
    setEditingKey("");
    setDataBeingEdited(false);
  };

  const expandedRowRender = (record) => (
    <div
      style={{
        padding: "16px",
        background: "#f9f9f9",
        display: "flex",
        justifyContent: "center",
        gap: "16px",
      }}
    >
      {record.statutCommande !== "APPROUVE" &&
      record.statutCommande !== "DELIVRE" ? (
        <>
          <Tooltip
            title={`${getActionVerb(record.statutCommande)?.charAt(0).toUpperCase() + getActionVerb(record.statutCommande)?.slice(1).toLowerCase()}  la commande`}
          >
            <Button
              type="primary"
              style={{ backgroundColor: "#28a745", borderColor: "#28a745" }}
              icon={getAntdIcon(record.statutCommande)}
              onClick={() => {
                if (record.statutCommande === "REGLE") {
                  setSelectedBonCommandeId(record?.codeId);
                  setSelectedCompagnie(record?.emetteur?.profil?.organisme);
                  setAffecterLotModal(true);
                } else
                  showValidationAttestationConfirm(
                    record.codeId,
                    record.statutCommande,
                  );
              }}
            >
              {`${getActionVerb(record.statutCommande)?.charAt(0).toUpperCase() + getActionVerb(record.statutCommande)?.slice(1).toLowerCase()}  la commande`}
            </Button>
          </Tooltip>
          {isEditing(record) ? (
            <>
              <Popconfirm
                title={"Voulez vous annuler ?"}
                onConfirm={() => cancel()}
              >
                <Button
                  type="default"
                  style={{
                    backgroundColor: "#002d00",
                    borderColor: "#005edd",
                    color: "white",
                  }}
                  icon={<i className="bx bx-edit-alt"></i>}
                >
                  Annuler
                </Button>
              </Popconfirm>
              <Popconfirm
                title={"Voulez vous enregistrez ?"}
                onConfirm={() => save(record)}
              >
                <Button
                  type="default"
                  style={{
                    backgroundColor: "#006f00",
                    borderColor: "#007bff",
                    color: "white",
                  }}
                  icon={<i className="bx bx-edit-alt"></i>}
                >
                  Enregistrer
                </Button>
              </Popconfirm>
            </>
          ) : (
            <Button
              type="default"
              style={{
                backgroundColor: "#007bff",
                borderColor: "#007bff",
                color: "white",
              }}
              icon={<i className="bx bx-edit-alt"></i>}
              onClick={() => edit(record)}
            >
              Éditer la commande
            </Button>
          )}
          <Tooltip title={"Rejeter la commande"}>
            <Button
              type="button"
              style={{
                backgroundColor: "#dc3545",
                borderColor: "#dc3545",
                color: "#fff",
              }}
              onClick={() => showRejectBonCommandeConfirm(record)}
              icon={<i className="bx bx-message-alt-x"></i>}
            >
              Rejeter la commande
            </Button>
          </Tooltip>
          <Tooltip title={"Timeline"}>
            <Button
              icon={<i className="bx bx-clipboard"></i>}
              style={{
                backgroundColor: "#6c757d",
                borderColor: "#6c757d",
                color: "white",
              }}
              onClick={() => {
                setSelectedOrder(record);
                setOpenTimeline(true);
              }}
            >
              Historique
            </Button>
          </Tooltip>
        </>
      ) : (
        <Tooltip title={"Timeline"}>
          <Button
            icon={<i className="bx bx-clipboard"></i>}
            style={{
              backgroundColor: "#6c757d",
              borderColor: "#6c757d",
              color: "white",
            }}
            onClick={() => {
              setSelectedOrder(record);
              setOpenTimeline(true);
            }}
          >
            Historique
          </Button>
        </Tooltip>
      )}
      `
    </div>
  );
  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const handleChange = (value) => {
      console.log("Changed value:", value);
    };

    const inputNode =
      inputType === "number" ? (
        <InputNumber min={1} defaultValue={editNombre} />
      ) : (
        <Input onChange={(e) => handleChange(e.target.value)} />
      );

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: `Veuillez saisir le ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "nombre" && "number",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  const oldColumns = [
    {
      title: "Compagnie",
      dataIndex: "compagnie",
      key: "1",
      render: (_, record) => (
        <>{record?.emetteur?.profil?.organisme?.raisonSociale} </>
      ),
    },
    {
      title: "Type d'attestation",
      dataIndex: "typeAttestation",
      key: "3",
      render: (_, record) => (
        <>
          {record.typeAttestation} - {record?.categorie?.nom}
        </>
      ),
    },

    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "2",
    },
    {
      title: "Type d'attestation",
      dataIndex: "typeAttestation",
      key: "3",
      render: (_, record) => (
        <>
          {record.typeAttestation} - {record?.categorie?.nom}
        </>
      ),
    },
    {
      title: "Statut commande",
      dataIndex: "statutCommande",
      key: "4",
      render: (_, record) => (
        <Tag color={getTagColor(record.statutCommande)}>
          {record.statutCommande}
        </Tag>
      ),
    },
    {
      title: "Date de Commande",
      dataIndex: "dateEmission",
      key: "dateEmission",
      render: (_, record) => (
        <>{new Date(record.dateEmission).toLocaleDateString()}</>
      ),
    },
    {
      title: "Date de reception",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (_, record) => (
        <>{new Date(record.updatedAt).toLocaleDateString()}</>
      ),
    },
  ];

  const validation = (lotA, updateCommentaire) => {
    console.log("Code de la commande à valider :", lotA);
    const com = updateCommentaire;
    console.log(updateCommentaire);
    const validationBonCommandeDTO = {
      bonCommandeCodeId: lotA,
      commentaire: com,
    };
    console.log(validationBonCommandeDTO);
    validateWithComment(validationBonCommandeDTO)
      .then((r) => {
        toast("Commande validée avec succès !");
        setReload((prevState) => !prevState);
      })
      .catch((error) => {
        console.error("Erreur lors de la validation :", error);
        toast("Erreur lors de la validation !");
      });
    setReload((prevState) => !prevState);
  };

  const commandesLivrees = commandes.filter(
    (c) => c.statutCommande === "RECEPTIONNE",
  );
  const commandesEnCours = commandes.filter(
    (c) =>
      c.statutCommande !== "RECEPTIONNE" &&
      c.statutCommande !== "INITIE" &&
      c.statutCommande !== "VALIDE",
  );

  const onSubmit = (
    firstAttestation,
    lastAttestation,
    selectedTypeAttestation,
    selectedCategorie,
    nombre,
  ) => {
    setLivrerLoading(true);
    if (firstAttestation > lastAttestation) {
      toast.error(
        "le premier numéro d'attestation doit être inférieur au dernier numéro d'attestation",
      );
    }
    if (nombre === lastAttestation - firstAttestation + 1) {
      validation(selectedBonCommandeId, com);
      const catg = categories.find(
        (element) => element.codeCategorie === selectedCategorie,
      );
      let lot = {
        compagnie: selectedCompagnie,
        lot: [firstAttestation, lastAttestation],
        updatedLot: [firstAttestation, lastAttestation],
        categorie: catg,
        typeAttestation: selectedTypeAttestation,
      };
      console.log("Loooot: ", lot);
      axiosInstance
        .post(`/api/lot-attestation`, JSON.stringify(lot))
        .then((res) => {
          console.log("Data Ressst", res);
          if (res.status === 226) {
            toast("Cet numéro d'attestation existe déjà!");
          } else {
            setAffecterLotModal(false);
            setReload(true);
            toast("Lot d'attestation ajoutée avec succès");
          }
          setLivrerLoading(false);
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 226) {
            toast("Cet numéro d'attestation existe déjà!");
          } else {
            toast("Erreur lors de l'ajout du lot d'attestation");
          }
          setLivrerLoading(false);
        });
    } else {
      setLivrerLoading(false);

      toast("Veuillez vérifier les valeurs que vous avez saisi!");
      toast(
        `Le nombre precis devrait etre ${lastAttestation - firstAttestation + 1}`,
      );
    }
  };

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <SideBar />

        <div className="layout-page">
          <NavBar />

          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              <>
                <Tabs
                  defaultActiveKey="2"
                  style={{ marginTop: 20 }}
                  items={[
                    {
                      label: "Demandes recues",
                      key: "2",
                      children: (
                        <Spin spinning={loadingOrders}>
                          <Table
                            components={{
                              body: {
                                cell: EditableCell,
                              },
                            }}
                            columns={mergedColumns}
                            dataSource={commandesEnCours}
                            rowKey="codeId"
                            expandable={{
                              expandedRowRender,
                            }}
                          />
                        </Spin>
                      ),
                    },
                    {
                      label: "Demandes traitées",
                      key: "1",
                      children: (
                        <Spin spinning={loadingOrders}>
                          <Table
                            columns={oldColumns}
                            dataSource={commandesLivrees}
                            rowKey="codeId"
                          />
                        </Spin>
                      ),
                    },
                  ]}
                />
              </>
              <OrderTimeline
                open={openTimeline}
                cancel={() => setOpenTimeline(false)}
                order={selectedOrder}
              />
              <AffecterLotAttestation
                affectationModalOpen={affecterLotModal}
                categories={categories}
                compagnie={compagnie}
                loading={livrerLoading}
                onSubmit={onSubmit}
                handleAffectationModalClose={() => setAffecterLotModal(false)}
              >
                <div style={{ marginBottom: "1rem", marginTop: "5px" }}>
                  <label htmlFor="commentaire">Faites un commentaire :</label>
                  <textarea
                    id="commentaire"
                    style={{
                      width: "100%",
                      marginTop: "0.5rem",
                      padding: "0.5rem",
                      borderRadius: "4px",
                      border: "1px solid #d9d9d9",
                    }}
                    placeholder="Votre commentaire ici..."
                    onChange={(e) => {
                      com = e.target.value;
                    }}
                  />
                </div>
              </AffecterLotAttestation>
            </div>

            <Footer />

            <div className="content-backdrop fade"></div>
          </div>
        </div>
      </div>

      <div className="layout-overlay layout-menu-toggle"></div>

      <div className="drag-target"></div>
    </div>
  );
};

export default CompaniesCommandes;
