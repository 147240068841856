import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { API_URL, LOGGED_USER, TOKEN } from '../../../variables/constants';

const EditTauxCommission = ({ show, handleClose, selectedTauxCommission }) => {
    const [formData, setFormData] = useState({
        libelleParametre: selectedTauxCommission?.libelleParametre,
        typeParametre: selectedTauxCommission?.typeParametre,
        categorie: selectedTauxCommission?.categorie?.categorieId,
        valeur: selectedTauxCommission?.valeur
    });
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    useEffect(() => {
        axios
            .get(`${API_URL}/api/categorie`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                },
            })
            .then((res) => {
                setCategories(res.data);
            })
            .catch((err) => {
                console.error(err);
                toast.error("Erreur lors de la récupération des catégories");
            });
    }, [TOKEN]);

    useEffect(() => {
        console.log("intermediaire", selectedTauxCommission);
        setFormData({
            libelleParametre: selectedTauxCommission?.libelleParametre,
            typeParametre: selectedTauxCommission?.typeParametre,
            categorie: selectedTauxCommission?.categorie?.categorieId,
            valeur: selectedTauxCommission?.valeur
        });
    }, [selectedTauxCommission]);

    const onSubmit = (e) => {
        e.preventDefault();
        if (!formData.libelleParametre || !formData.categorie) {
            toast.error("Veuillez remplir tous les champs requis.");
            return;
        }
        setLoading(true);

        const cat = categories.find(
            (e) => e.categorieId === parseInt(formData.categorie)
        );

        const gar = selectedTauxCommission;
        gar.libelleParametre = formData.libelleParametre;
        gar.categorie = cat;
        gar.valeur = formData.valeur;
        gar.organisme = LOGGED_USER.profil.organisme;


        // Log the data being sent before the request
        console.log("Data to be sent:", gar);
        const apiUrl = `${API_URL}/api/parametre`;

        axios.put(apiUrl, JSON.stringify(gar), {
            headers: {
                Authorization: `Bearer ${TOKEN}`,
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                console.log("Response from API:", res.data); // Log the response from the API
                setLoading(false);
                toast("Taux de commission modifié avec succès");
                handleClose();
            })
            .catch((err) => {
                console.error("Error during submission:", err);
                toast.error("Erreur lors de la modification du taux de commission");
                setLoading(false);
            });
    };

    return (
        <Modal show={show} onHide={handleClose} size="lg" centered>
            <Modal.Body>
                <button
                    type="button"
                    className="btn-close btn-primary"
                    onClick={handleClose}
                    aria-label="Close"
                ></button>
                <div className="text-center mb-6">
                    <h4 className="address-title mb-2">
                        Modifier le taux de commission
                    </h4>
                    <p className="add-subtitle">
                        Modifier le taux de commission suivant le type d'intermédiaire
                    </p>
                </div>
                <form id="addNewAddressForm" className="row g-12" onSubmit={onSubmit}>
                    <div className="col-12 col-md-12">
                        <label className="form-label" htmlFor="libelleParametre">
                            Type d'intermédiaire
                        </label>
                        <input
                            type="text"
                            id="libelleParametre"
                            onChange={(e) => handleInputChange(e)}
                            className="form-control"
                            placeholder="Type d'intermédiaire"
                            name="libelleParametre"
                            value={formData.libelleParametre}
                        />
                    </div>
                    <div className="col-12 col-md-12">
                        <label className="form-label" htmlFor="valeur">
                            Taux
                        </label>
                        <input
                            type="number"
                            id="valeur"
                            onChange={(e) => handleInputChange(e)}
                            className="form-control"
                            placeholder="Valeur du taux"
                            name="valeur"
                            value={formData.valeur}
                        />
                    </div>
                    <div className="col-12">
                        <label className="form-label" htmlFor="categorie">
                            Catégorie
                        </label>
                        <select
                            id="categorie"
                            name="categorie"
                            className="select2 form-select"
                            onChange={(e) => handleInputChange(e)}
                            value={formData.categorie}
                        >
                            <option value="">Sélectionner la catégorie</option>
                            {categories.map((cat) => (
                                <option key={cat.categorieId} value={cat.categorieId}>
                                    {cat.nom}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-12 text-center">
                        <button
                            type="submit"
                            className="btn btn-primary me-3"
                            disabled={loading}
                        >
                            {loading && (
                                <span
                                    className="spinner-grow me-1"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            )}
                            {loading ? `Enregistrement...` : `Enregistrer`}
                        </button>
                        <button
                            type="reset"
                            className="btn btn-label-secondary"
                            onClick={handleClose}
                            disabled={loading}
                        >
                            Annuler
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default EditTauxCommission
