import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { API_URL, TOKEN } from "../../../variables/constants";

const AddUsages = ({ show, handleClose, editMode, selectedUsages }) => {
  const [loading, setLoading] = useState(false);
  const [selectedCategorie, setSelectedCategorie] = useState("");
  const [categories, setCategories] = useState([]);
  const [nom, setNom] = useState("");

  useEffect(() => {
    if (editMode && selectedUsages) {
      setNom(selectedUsages.libelleParametre || "");
      setSelectedCategorie(selectedUsages.categorie?.categorieId || "");
    } else {
      setNom("");
      setSelectedCategorie("");
    }

    axios
      .get(`${API_URL}/api/categorie`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      })
      .then((res) => {
        setCategories(res.data);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Erreur lors de la récupération des catégories");
      });
  }, [editMode, selectedUsages, show]);

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const selectedCat = categories.find(
      (cat) => cat.categorieId === parseInt(selectedCategorie, 10),
    );
    const usagesData = {
      ...selectedUsages,
      libelleParametre: nom,
      typeParametre: "USAGE",
      categorie: selectedCat || null,
    };

    console.log("Données envoyées :", usagesData);
    const request = editMode
      ? axios.put(`${API_URL}/api/parametre`, usagesData, {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
          },
        })
      : axios.post(`${API_URL}/api/parametre`, usagesData, {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
          },
        });

    request
      .then((res) => {
        console.log("Réponse de l'API :", res.data); // Vérification de la réponse de l'API
        setNom("");
        setSelectedCategorie("");
        toast.success(
          editMode ? "Usage modifié avec succès" : "Usage ajouté avec succès",
        );
        handleClose();
      })
      .catch((err) => {
        console.error("Erreur lors de la requête :", err);
        toast.error("Erreur lors de l'enregistrement des usages");
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Body>
        <button
          type="button"
          className="btn-close btn-primary"
          onClick={handleClose}
          aria-label="Close"
        ></button>
        <div className="text-center mb-4">
          <h4 className="address-title mb-2">
            {editMode ? "Modifier l'usage" : "Ajouter un usage"}
          </h4>
          <p className="address-subtitle">
            {editMode ? "Modifier l'usage existant" : "Ajouter un nouvel usage"}
          </p>
        </div>
        <form className="row g-3" onSubmit={onSubmit}>
          <div className="col-12">
            <label className="form-label" htmlFor="usagesName">
              Nom
            </label>
            <input
              type="text"
              id="usagesName"
              onChange={(e) => setNom(e.target.value)}
              className="form-control"
              placeholder="Nom des usages"
              name="nom"
              value={nom}
              required
            />
          </div>
          <div className="col-12">
            <label className="form-label" htmlFor="usagesCategory">
              Catégorie
            </label>
            <select
              id="usagesCategory"
              name="usagesCategory"
              className="select2 form-select"
              onChange={(e) => setSelectedCategorie(e.target.value)}
              value={selectedCategorie}
              required
            >
              <option value="">Sélectionner la catégorie</option>
              {categories.map((cat) => (
                <option key={cat.categorieId} value={cat.categorieId}>
                  {cat.nom}
                </option>
              ))}
            </select>
          </div>
          <div className="col-12 text-center">
            <button
              type="submit"
              className="btn btn-primary me-3"
              disabled={loading}
            >
              {loading && (
                <span
                  className="spinner-grow spinner-grow-sm me-1"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              {loading ? "Enregistrement..." : "Enregistrer"}
            </button>
            <button
              type="button"
              className="btn btn-label-secondary"
              onClick={handleClose}
            >
              Annuler
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddUsages;
