import {ExclamationCircleFilled, FilePdfOutlined, SearchOutlined,} from "@ant-design/icons";
import {Button, ConfigProvider, DatePicker, Form, Input, Modal, Select, Space, Spin, Switch, Table, Tag,} from "antd";
import frFR from "antd/locale/fr_FR";
import React, {createRef, useEffect, useRef, useState} from "react";
import Highlighter from "react-highlight-words";
import {useNavigate} from "react-router-dom";
import {useReactToPrint} from "react-to-print";
import {toast} from "react-toastify";
import Footer from "../../components/Footer";
import NavBar from "../../components/NavBar";
import SideBar from "../../components/SideBar";
import {rightsService} from "../../services/rightsService";
import axiosInstance from "../../variables/api/axiosInstance";
import { API_URL, LOGGED_USER, TOKEN } from "../../variables/constants";
import {
  frenchDate,
  isBetweenOrEqualDate,
  isEqualDate,
  isToday,
  isTomonth,
} from "../../variables/functions";
import RecuCurrentFormat from "../production/components/docs/RecuCurrentFormat";
const { confirm } = Modal;
const { Option } = Select;
const { RangePicker } = DatePicker;

const Encaissements = () => {
    const [datas, setDatas] = useState([]);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [allDatas, setAllDatas] = useState([]);
    const [dataSum, setDataSum] = useState(0);
    const [todayDatas, setTodayDatas] = useState([]);
    const [todayDataSum, setTodayDataSum] = useState(0);
    const [monthDatas, setMonthDatas] = useState([]);
    const [monthDataSum, setMonthDataSum] = useState(0);
    const [nonCashedDatas, setNonCashedDatas] = useState([]);
    const [nonCashedDataSum, setNonCashedDataSum] = useState(0);
    const [loadingData, setLoadingData] = useState(true);
    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});
    const [modalModePaiement, setModalModePaiement] = useState(false);
    const [contractToPay, setContractToPay] = useState("");
    const [numeroPaiement, setNumeroPaiement] = useState("P");
    const [modePaiement, setModePaiement] = useState("");
    const [modePaiementForm] = Form.useForm();
    const [banques, setBanques] = useState([]);
    const [banque, setBanque] = useState("BANQUE");
    const [dateF, setDateF] = useState([]);
    const [reglement, setReglement] = useState("");
    const [modalOpen, setModalOpen] = useState(false);
    const [useCompensationRistourne, setUseCompensationRistourne] =
        useState(false);
    const [havingRistourne, setHavingRistourne] = useState(false);
    const [count, setCount] = useState("");

    const handleChange = (pagination, filters, sorter) => {
        setCurrentPage(pagination.current);
        setPageSize(pagination.pageSize);
        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };
    const searchInput = useRef(null);
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");

    const navigate = useNavigate();

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
                             setSelectedKeys,
                             selectedKeys,
                             confirm,
                             clearFilters,
                             close,
                         }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Rechercher ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined/>}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Rechercher...
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reinitialiser
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filtrer
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        Fermer
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1677ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });

    useEffect(() => {
        // axiosInstance
        //   .get(`/api/contrat`, {
        //     params: {
        //       page: currentPage - 1,
        //       size: pageSize,
        //       search: "",
        //       startDate: null,
        //       endDate: null,
        //       compagnie: "",
        //     },
        //   })
        //   .then((res) => {
        //     console.log("Res: ", res.data);
        //     let regs = [];
        //     let todayRegs = [];
        //     let monthRegs = [];
        //     let nonRegs = [];
        //     res.data.content.forEach((contrat, id) => {
        //       if (contrat.reglements) {
        //         contrat.reglements.forEach((reg, jd) => {
        //           regs.push({
        //             key: `${reg.codeId}${jd}${id}`,
        //             nom: `${contrat?.souscripteur?.nom} ${contrat?.souscripteur?.prenom ? contrat?.souscripteur?.prenom : ""}`,
        //             modeReglement: reg.modePaiement ? reg.modePaiement : "-",
        //             dateReglement: reg.modePaiement ? reg.dateReglement : "-",
        //             montant: reg.montant,
        //             numeroPaiement: reg.numeroPaiement,
        //             reference: reg.reference,
        //             createdAt: reg.createdAt,
        //             reglement: reg,
        //             avenant: reg?.avenant?.code ? reg?.avenant?.code : "AN",
        //             contrat: contrat,
        //             numeroPolice: contrat.numeroPoliceP,
        //           });

        //           isToday(reg.createdAt) &&
        //             todayRegs.push({
        //               key: `${reg.codeId}${jd}${id}`,
        //               nom: `${contrat?.souscripteur?.nom} ${contrat?.souscripteur?.prenom ? contrat?.souscripteur?.prenom : ""}`,
        //               modeReglement: reg.modePaiement ? reg.modePaiement : "-",
        //               dateReglement: reg.modePaiement ? reg.dateReglement : "-",
        //               montant: reg.montant,
        //               numeroPaiement: reg.numeroPaiement,
        //               reference: reg.reference,
        //               createdAt: reg.createdAt,
        //               reglement: reg,
        //               avenant: reg?.avenant?.code ? reg?.avenant?.code : "AN",
        //               contrat: contrat,
        //               numeroPolice: contrat.numeroPoliceP,
        //             });

        //           isTomonth(reg.createdAt) &&
        //             monthRegs.push({
        //               key: `${reg.codeId}${jd}${id}`,
        //               nom: `${contrat?.souscripteur?.nom} ${contrat?.souscripteur?.prenom ? contrat?.souscripteur?.prenom : ""}`,
        //               modeReglement: reg.modePaiement ? reg.modePaiement : "-",
        //               dateReglement: reg.modePaiement ? reg.dateReglement : "-",
        //               montant: reg.montant,
        //               numeroPaiement: reg.numeroPaiement,
        //               reference: reg.reference,
        //               createdAt: reg.createdAt,
        //               reglement: reg,
        //               avenant: reg?.avenant?.code ? reg?.avenant?.code : "AN",
        //               contrat: contrat,
        //               numeroPolice: contrat.numeroPoliceP,
        //             });

        //           !reg.modePaiement &&
        //             nonRegs.push({
        //               key: `${reg.codeId}${jd}${id}`,
        //               nom: `${contrat?.souscripteur?.nom} ${contrat?.souscripteur?.prenom ? contrat?.souscripteur?.prenom : ""}`,
        //               modeReglement: reg.modePaiement ? reg.modePaiement : "-",
        //               dateReglement: reg.modePaiement ? reg.dateReglement : "-",
        //               montant: reg.montant,
        //               numeroPaiement: reg.numeroPaiement,
        //               reference: reg.reference,
        //               createdAt: reg.createdAt,
        //               reglement: reg,
        //               avenant: reg?.avenant?.code ? reg?.avenant?.code : "AN",
        //               contrat: contrat,
        //               numeroPolice: contrat.numeroPoliceP,
        //             });
        //         });
        //       }
        //     });
        //     console.log("Regsss: ", regs);
        //     setDataSum(
        //       regs.reduce((pn, encaissement) => pn + encaissement.montant, 0),
        //     );
        //     setDatas(regs.reverse());
        //     setAllDatas(regs);

        //     setTodayDataSum(
        //       todayRegs.reduce((pn, encaissement) => pn + encaissement.montant, 0),
        //     );
        //     setTodayDatas(todayRegs);

        //     setMonthDataSum(
        //       monthRegs.reduce((pn, encaissement) => pn + encaissement.montant, 0),
        //     );
        //     setMonthDatas(monthRegs);

        //     setNonCashedDataSum(
        //       nonRegs.reduce((pn, encaissement) => pn + encaissement.montant, 0),
        //     );
        //     setNonCashedDatas(nonRegs);

        //     setLoadingData(false);
        //     setTotal(res.data.totalElements);
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //     setLoadingData(false);
        //   });

        axiosInstance
            .get(`/api/reglement`, {
                params: {
                    page: currentPage - 1,
                    size: pageSize,
                    search: "",
                    startDate: null,
                    endDate: null,
                    compagnie: "",
                },
            })
            .then((res) => {
                // console.log("jaiiiille: ", res);
                setDatas(res.data.content);
                setTotal(res.data.totalElements);
                setLoadingData(false);
            })
            .catch((err) => {
                console.log("Err: ", err);
                setLoadingData(false);
            });

        axiosInstance
            .get(`/api/reglement/encaissements-count`)
            .then((res) => {
                setCount(res.data);
            })
            .catch((err) => {
                console.log(err);
            });

        axiosInstance
            .get(`/api/parametre/type/BANQUE`)
            .then((res) => {
                setBanques(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [currentPage, pageSize]);

    const encaisserContrat = (id) => {
        axiosInstance
            .get(
                `/api/reglement/check-ristourne/${id.contrat.souscripteur.numeroAssureP}`,
            )
            .then((resp) => {
                // console.log("Having ristourne: ", resp.data);
                setHavingRistourne(resp.data);
            })
            .catch((err) => {
                console.log(err);
            });
        setModalModePaiement(true);
        setContractToPay(id);
    };

    const columns = [
        {
            title: "Assuré",
            dataIndex: "nom",
            key: "nom",
            render: (_, record) => (
                <>
                    {`${record.contrat?.souscripteur?.nom} ${record.contrat?.souscripteur?.prenom ? record.contrat?.souscripteur?.prenom : ""}`}{" "}
                </>
            ),
        },
        {
            title: "Numéro Police",
            dataIndex: "numeroPolice",
            key: "numeroPolice",
            render: (_, record) => <>{`${record.contrat?.numeroPoliceP}`} </>,
        },
        {
            title: "Avenant",
            dataIndex: "avenant",
            key: "avenant",
            render: (_, record) => (
                <>{record?.avenant?.code ? record?.avenant?.code : "AN"} </>
            ),
        },
        {
            title: "Montant",
            dataIndex: "montant",
            key: "montant",
            render: (_, record) => (
                <>
                    {new Intl.NumberFormat("en-DE").format(Math.trunc(record.montant))}{" "}
                </>
            ),
        },
        {
            title: "Mode de paiement",
            dataIndex: "modePaiement",
            key: "modePaiement",
            render: (_, record) => (
                <>
                    {record.modePaiement === "ESPECE" ? (
                        <Tag color="blue">ESPECES</Tag>
                    ) : record.modePaiement === "RISTOURNE" ? (
                        <Tag color="green">RISTOURNE</Tag>
                    ) : record.modePaiement && record.modePaiement !== "-" ? (
                        <Tag color="yellow">{record.modePaiement}</Tag>
                    ) : null}{" "}
                </>
            ),
            filters: [
                {
                    text: "ESPECES",
                    value: "ESPECE",
                },
                {
                    text: "RISTOURNE",
                    value: "RISTOURNE",
                },
                {
                    text: "BANQUE",
                    value: "BANQUE",
                },
                {
                    text: "MOBILE MONEY",
                    value: "MOBILE_MONEY",
                },
                {
                    text: "COMPENSATION",
                    value: "COMPENSATION",
                },
                {
                    text: "NON PAYE",
                    value: "-",
                },
            ],
            filteredValue: filteredInfo.modePaiement || null,
            onFilter: (value, record) => record.modePaiement === value,
            sorter: (a, b) => a.modePaiement.length - b.modePaiement.length,
            sortOrder:
                sortedInfo.columnKey === "modePaiement" ? sortedInfo.order : null,
            ellipsis: true,
        },
        {
            title: "Date opération",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (_, record) => <>{frenchDate(record.createdAt)} </>,
        },
        {
            title: "Date règlement",
            dataIndex: "dateReglement",
            key: "dateReglement",
            render: (_, record) => (
                <>{record.modePaiement ? frenchDate(record.dateReglement) : "-"} </>
            ),
        },
        {
            title: "Actions",
            dataIndex: "actions",
            key: "7",
            render: (_, record) => (
                <>
                    {rightsService("HLA037") &&
                        record.contrat.numeroPoliceP &&
                        !record.modePaiement && (
                            <button
                                type="button"
                                class="btn btn-success btn-icon rounded-pill"
                                onClick={() => encaisserContrat(record)}
                            >
                                <i class="bx bxs-credit-card"></i>
                            </button>
                        )}
                    <button
                        type="button"
                        class="btn btn-primary btn-icon rounded-pill"
                        onClick={() =>
                            navigate(`/details-contrat/${record.contrat.codeId}`)
                        }
                    >
                        <i class="bx bx-dots-vertical-rounded"></i>
                    </button>
                    {rightsService("HCO006") &&
                        record.contrat.numeroPoliceP &&
                        record.modePaiement && (
                            <button
                                type="button"
                                class="btn btn-danger btn-icon rounded-pill"
                                onClick={() => {
                                    alertCancelReglement(record);
                                }}
                            >
                                <i class="bx bx-x"></i>
                            </button>
                        )}
                    {rightsService("HCO006") &&
                        record.contrat.numeroPoliceP &&
                        record.modePaiement && (
                            <button
                                type="button"
                                class="btn btn-success btn-icon rounded-pill"
                                onClick={() => {
                                    setReglement(record);
                                    setModalOpen(true);
                                }}
                            >
                                <i class="bx bxs-file-pdf"></i>
                            </button>
                        )}
                </>
            ),
        },
    ];

    const alertReglement = (contractToPay) => {
        // console.log(numeroPaiement);
        confirm({
            title: "Etes-vous sûr de vouloir régler ce contrat?",
            icon: <ExclamationCircleFilled/>,
            content: "Toutes vos actions sont historisées!",
            okText: "Oui régler",
            okType: "danger",
            cancelText: "Annuler",
            onOk() {
                console.log("OK");
                reglementContrat(contractToPay);
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };

    const alertCancelReglement = (reglementToCancel) => {
        // console.log(numeroPaiement);
        confirm({
            title: "Etes-vous sûr de vouloir annuler cet encaissement?",
            icon: <ExclamationCircleFilled/>,
            content: "Toutes vos actions sont historisées!",
            okText: "Oui annuler",
            okType: "danger",
            cancelText: "Annuler",
            onOk() {
                console.log("OK");
                cancelReglement(reglementToCancel);
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };

    const cancelReglement = (data) => {
        axiosInstance
            .get(
                `/api/reglement/cancel/${data.contrat.codeId}/${data.reglement.codeId}/`,
            )
            .then((res) => {
                window.location.reload();
                toast("Règlement effectué avec succès");
                // console.log(res.data);
                res.data = null;
            })
            .catch((err) => {
                console.log(err);
                toast("Erreur lors du règlement");
            });
    };

    const reglementContrat = (data) => {
        if (useCompensationRistourne) {
            if (modePaiement === "ESPECES") {
                axiosInstance
                    .get(
                        `/api/reglement/compensation/${data.contrat.codeId}/${data.reglement.codeId}`,
                    )
                    .then((res) => {
                        window.location.reload();
                        toast("Règlement effectué avec succès");
                        // console.log(res.data);
                        res.data = null;
                    })
                    .catch((err) => {
                        console.log(err);
                        toast("Erreur lors du règlement");
                    });
            } else {
                axiosInstance
                    .get(
                        `/api/reglement/compensation/${data.contrat.codeId}/${data.reglement.codeId}/${modePaiement}/${numeroPaiement}/${banque}`,
                    )
                    .then((res) => {
                        window.location.reload();
                        toast("Règlement effectué avec succès");
                        // console.log(res.data);
                        res.data = null;
                    })
                    .catch((err) => {
                        console.log(err);
                        toast("Erreur lors du règlement");
                    });
            }
        } else {
            axiosInstance
                .get(
                    `/api/reglement/${data.contrat.codeId}/${data.reglement.codeId}/${LOGGED_USER.codeId}/${modePaiement}/${numeroPaiement}/${banque}/${data.montant}/${data.reglement.avenant.code}`,
                )
                .then((res) => {
                    window.location.reload();
                    toast("Règlement effectué avec succès");
                    // console.log(res.data);
                    res.data = null;
                })
                .catch((err) => {
                    console.log(err);
                    toast("Erreur lors du règlement");
                });
        }

        // if (modePaiement === "ESPECES") {
        //     axios
        //         .get(
        //             `${API_URL}/api/reglement/especes/contrat/codeId/${id}/${LOGGED_USER.codeId}`,
        //             {
        //                 headers: {
        //                     Authorization: `Bearer ${TOKEN}`,
        //                     "Content-Type": "application/json",
        //                     "Access-Control-Allow-Origin": "*",
        //                     "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
        //                     "Access-Control-Allow-Headers":
        //                         "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        //                 },
        //             }
        //         )
        //         .then((res) => {
        //             window.location.reload();
        //             toast("Reglement effectué avec succès!");
        //             console.log(res.data);
        //         })
        //         .catch((err) => {
        //             console.log(err);
        //             toast("Erreur lors du règlement");
        //         });
        // } else if (modePaiement === "BANQUE" || modePaiement === "MOBILE_MONEY") {
        //     axios
        //         .get(
        //             `${API_URL}/api/reglement/contrat/codeId/${id}/${LOGGED_USER.codeId}/${modePaiement}/${numeroPaiement}/${banque}`,
        //             {
        //                 headers: {
        //                     Authorization: `Bearer ${TOKEN}`,
        //                     "Content-Type": "application/json",
        //                     "Access-Control-Allow-Origin": "*",
        //                     "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
        //                     "Access-Control-Allow-Headers":
        //                         "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        //                 },
        //             }
        //         )
        //         .then((res) => {
        //             window.location.reload();
        //             toast("Règlement effectué avec succès!");
        //             // console.log(res.data);
        //             res.data = null;
        //         })
        //         .catch((err) => {
        //             console.log(err);
        //             toast("Erreur lors du règlement");
        //         });
        // }
    };

    const dateFiltering = (date, dateString) => {
        setDateF(date);
        // console.log("DateFiltering: ", date);
        // console.log("DateStringFiltering: ", dateString);
        let filRegs = [];
        if (dateString[0] === dateString[1]) {
            allDatas.forEach((dt) => {
                isEqualDate(dateString[0], dt.createdAt) && filRegs.push(dt);
            });
            setDatas(filRegs);
        } else {
            allDatas.forEach((dt) => {
                isBetweenOrEqualDate(dt.createdAt, dateString[0], dateString[1]) &&
                filRegs.push(dt);
            });
            setDatas(filRegs);
        }

        if (!dateString[0].length && !dateString[1].length) {
            setDatas(allDatas);
        }
        // console.log("Filll: ", filRegs);
    };

    const printRecu = createRef();

    const exportRecuToPdf = () => {
        handleRecu();
    };

    const handleRecu = useReactToPrint({
        content: () => printRecu.current,
    });

    return (
        <div class="layout-wrapper layout-content-navbar  ">
            <div class="layout-container">
                <SideBar currentMenu={"CAISSE"}/>

                {/* <!-- Layout container --> */}
                <div class="layout-page">
                    <NavBar/>

                    {/* <!-- Content wrapper --> */}
                    <div class="content-wrapper">
                        {/* <!-- Content --> */}
                        <div class="container-xxl flex-grow-1 container-p-y">
                            <div class="row g-6 mb-6">
                                <div class="col-sm-6 col-xl-3">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="d-flex align-items-start justify-content-between">
                                                <div class="content-left">
                                                    <span class="text-heading">Encaissements</span>
                                                    <div class="d-flex align-items-center my-1">
                                                        <h5 class="mb-0 me-2">
                                                            {count?.numberOfReglementsToday ?? 0} (
                                                            {new Intl.NumberFormat("en-DE").format(
                                                                Math.trunc(count?.totalAmountToday ?? 0),
                                                            )}{" "}
                                                            FCFA)
                                                        </h5>
                                                        {/* <p class="text-success mb-0">(+29%)</p> */}
                                                    </div>
                                                    <small class="mb-0">du jour</small>
                                                </div>
                                                <div class="avatar">
                          <span class="avatar-initial rounded bg-label-primary">
                            <i class="bx bx-group bx-lg"></i>
                          </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-xl-3">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="d-flex align-items-start justify-content-between">
                                                <div class="content-left">
                                                    <span class="text-heading">Encaissements</span>
                                                    <div class="d-flex align-items-center my-1">
                                                        <h5 class="mb-0 me-2">
                                                            {count?.numberOfReglementsThisMonth ?? 0} (
                                                            {new Intl.NumberFormat("en-DE").format(
                                                                Math.trunc(count?.totalAmountThisMonth ?? 0),
                                                            )}{" "}
                                                            FCFA)
                                                        </h5>
                                                        {/* <p class="text-success mb-0">(+18%)</p> */}
                                                    </div>
                                                    <small class="mb-0">du mois</small>
                                                </div>
                                                <div class="avatar">
                          <span class="avatar-initial rounded bg-label-danger">
                            <i class="bx bx-user-plus bx-lg"></i>
                          </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-xl-3">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="d-flex align-items-start justify-content-between">
                                                <div class="content-left">
                                                    <span class="text-heading">Encaissements</span>
                                                    <div class="d-flex align-items-center my-1">
                                                        <h5 class="mb-0 me-2">
                                                            {total ?? 0} (
                                                            {new Intl.NumberFormat("en-DE").format(
                                                                Math.trunc(count?.totalAmount ?? 0),
                                                            )}{" "}
                                                            FCFA)
                                                        </h5>
                                                        {/* <p class="text-danger mb-0">(-14%)</p> */}
                                                    </div>
                                                    <small class="mb-0">total</small>
                                                </div>
                                                <div class="avatar">
                          <span class="avatar-initial rounded bg-label-success">
                            <i class="bx bx-user-check bx-lg"></i>
                          </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-xl-3">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="d-flex align-items-start justify-content-between">
                                                <div class="content-left">
                                                    <span class="text-heading">Non encaissés</span>
                                                    <div class="d-flex align-items-center my-1">
                                                        <h5 class="mb-0 me-2">
                                                            {count?.numberOfNonCashedReglements ?? 0} (
                                                            {new Intl.NumberFormat("en-DE").format(
                                                                Math.trunc(count?.totalAmountNonCashed ?? 0),
                                                            )}{" "}
                                                            FCFA)
                                                        </h5>
                                                        {/* <p class="text-success mb-0">(+42%)</p> */}
                                                    </div>
                                                    <small class="mb-0">Total</small>
                                                </div>
                                                <div class="avatar">
                          <span class="avatar-initial rounded bg-label-warning">
                            <i class="bx bx-user-voice bx-lg"></i>
                          </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="container">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-6">
                                                    <RangePicker
                                                        onChange={dateFiltering}
                                                        type="primary"
                                                        placeholder="Filtre par date"
                                                        value={dateF}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ConfigProvider locale={frFR}>
                                    <Spin spinning={loadingData}>
                                        <Table
                                            columns={columns}
                                            dataSource={datas}
                                            onChange={handleChange}
                                            pagination={{
                                                current: currentPage,
                                                pageSize: pageSize,
                                                total: total,
                                                showSizeChanger: true,
                                            }}
                                        />
                                    </Spin>
                                </ConfigProvider>
                            </div>
                            {reglement ? (
                                <div className="container">
                                    <Modal
                                        title={reglement.reglement?.avenant?.nom}
                                        centered
                                        open={modalOpen}
                                        onOk={() => {
                                            setModalOpen(false);
                                        }}
                                        onCancel={() => {
                                            setModalOpen(false);
                                        }}
                                        width={1000}
                                        zIndex={2000}
                                    >
                                        <div className="excel-table-wrapper">
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "space-between",
                                                    height: "135vh",
                                                    margin: 0,
                                                    padding: 0,
                                                }}
                                            >
                                                <RecuCurrentFormat
                                                    ref={printRecu}
                                                    contrat={reglement.contrat}
                                                    reglement={reglement.reglement}
                                                />
                                            </div>
                                        </div>
                                        <Button
                                            onClick={() => {
                                                exportRecuToPdf();
                                            }}
                                            type="primary"
                                            style={{
                                                background: "green",
                                            }}
                                            disabled={!rightsService("HCO008")}
                                            icon={<FilePdfOutlined/>}
                                        >
                                            {" "}
                                            Générer PDF
                                            <br/>
                                            <br/>
                                            <br/>
                                        </Button>
                                    </Modal>
                                </div>
                            ) : null}
                        </div>
                        {/* <!-- / Content --> */}

                        {/* <!-- Footer --> */}
                        <Footer/>
                        {/* <!-- / Footer --> */}

                        <div class="content-backdrop fade"></div>
                    </div>
                    {/* <!-- Content wrapper --> */}
                </div>
                {/* <!-- / Layout page --> */}
            </div>
            <Modal
                title="Mode de paiement"
                centered
                open={modalModePaiement}
                onOk={() => {
                    alertReglement(contractToPay);
                }}
                onCancel={() => {
                    setModalModePaiement(false);
                }}
            >
                <>
                    <div className="container" justify="center">
                        <div className="grid-item" lg={12}>
                            <Form form={modePaiementForm} layout="vertical" name="userForm">
                                {havingRistourne ? (
                                    <Form.Item
                                        name="useCompensation"
                                        label="Utiliser la compensation?"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Switch
                                            checkedChildren="OUI"
                                            unCheckedChildren="NON"
                                            onChange={(checked) =>
                                                setUseCompensationRistourne(checked)
                                            }
                                            checked={useCompensationRistourne}
                                        />
                                    </Form.Item>
                                ) : null}
                                <Form.Item
                                    name="modePaiement"
                                    label="Mode de paiement"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Select
                                        onChange={(value) => {
                                            console.log(value);
                                            setModePaiement(value);
                                        }}
                                        placeholder="Selectionnez votre mode de paiement"
                                    >
                                        <Option value="ESPECES"> ESPECES </Option>
                                        <Option value="BANQUE">BANQUE (Chèque)</Option>
                                        <Option value="MOBILE_MONEY">TMONEY / FLOOZ</Option>
                                        {contractToPay.montant < 0 && (
                                            <Option value="RISTOURNE">RISTOURNE</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                                {modePaiement === "BANQUE" ||
                                modePaiement === "MOBILE_MONEY" ? (
                                    <Form.Item
                                        name="numeroPaiement"
                                        label={
                                            modePaiement === "BANQUE"
                                                ? "Numero de chèque"
                                                : modePaiement === "MOBILE_MONEY"
                                                    ? "Numero FLOOZ / TMONEY"
                                                    : ""
                                        }
                                        rules={[
                                            {
                                                required: true,
                                                message: "Veuillez renseigner le champ!",
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder={
                                                modePaiement === "BANQUE"
                                                    ? "Le numero de chèque"
                                                    : modePaiement === "MOBILE_MONEY"
                                                        ? "Le numero de téléphone MOOV (FLOOZ) / TOGOCOM (TMONEY)"
                                                        : ""
                                            }
                                            onChange={(value) => {
                                                console.log(value.target.value);
                                                setNumeroPaiement(value.target.value);
                                            }}
                                        />
                                    </Form.Item>
                                ) : null}
                                {modePaiement === "BANQUE" ? (
                                    <Form.Item
                                        name="banque"
                                        label="Banque"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Select
                                            onChange={(value) => {
                                                console.log(value);
                                                setBanque(value);
                                            }}
                                            placeholder="Selectionnez la banque"
                                        >
                                            {banques.map((b) => (
                                                <Option key={b.parametreId} value={b.libelleParametre}>
                                                    {b.libelleParametre}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                ) : null}
                            </Form>
                        </div>
                    </div>
                </>
            </Modal>
            {/* <!-- Overlay --> */}
            <div class="layout-overlay layout-menu-toggle"></div>

            {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
            <div class="drag-target"></div>
        </div>
    );
};

export default Encaissements;
