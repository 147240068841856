import { Image } from 'antd';
import PropTypes from "prop-types";
import React from 'react';
import entete from '../../../../assets/img/entete.jpg';
import footer from '../../../../assets/img/footer.png';
import { frenchDateOnly, frenchMonth } from '../../../../variables/functions';

const tableStyles = {
    width: "100%",
    fontSize: "11px",
    borderCollapse: "collapse",
};

const thTdStyles = {
    fontSize: "10px",
    padding: "0px 0px 0px 0px",
    lineHeight: 3,
    textAlign: "center",
    verticalAlign: "middle",
};

const nestedTableStyles = {
    width: "100%",
    borderCollapse: "collapse",
    margin: 0,
    padding: 0,
};

const nestedThTdStyles = {
    padding: "0px",
    // border: "1px solid black",
    textAlign: "center",
    verticalAlign: "middle",
    lineHeight: 2,
};

const EtatGlobalProductionMensuelDTRFNational = React.forwardRef((props, ref) => {
    const totalMotoPuissance0 = props?.jours?.reduce((sum, jour) => sum + jour.nombreMotoPuissance0, 0);
    const totalMontantPuissance0 = props?.jours?.reduce((sum, jour) => sum + jour.montantPuissance0, 0);
    const totalMotoPuissance1 = props?.jours?.reduce((sum, jour) => sum + jour.nombreMotoPuissance1, 0);
    const totalMontantPuissance1 = props?.jours?.reduce((sum, jour) => sum + jour.montantPuissance1, 0);
    const totalMotoPuissance2 = props?.jours?.reduce((sum, jour) => sum + jour.nombreMotoPuissance2, 0);
    const totalMontantPuissance2 = props?.jours?.reduce((sum, jour) => sum + jour.montantPuissance2, 0);
    const totalMotoPuissance3 = props?.jours?.reduce((sum, jour) => sum + jour.nombreMotoPuissance3, 0);
    const totalMontantPuissance3 = props?.jours?.reduce((sum, jour) => sum + jour.montantPuissance3, 0);
    const totalMotoTotal = props?.jours?.reduce((sum, jour) => sum + jour.nombreMotoTotal, 0);
    const totalPrimeNette = props?.jours?.reduce((sum, jour) => sum + jour.primeNette, 0);
    const totalTaxes = props?.jours?.reduce((sum, jour) => sum + jour.taxes, 0);
    const totalPrimeTotale = props?.jours?.reduce((sum, jour) => sum + jour.primeTotale, 0);
    const totalTimbre = props?.jours?.reduce((sum, jour) => sum + jour.timbre, 0);
    const totalMontantVerse = props?.jours?.reduce((sum, jour) => sum + jour.montantVerse, 0);
    const totalResteAReverser = props?.jours?.reduce((sum, jour) => sum + jour.montantRestant, 0);

    return (
        <div ref={ref} style={{ height: "210mm", width: "297mm" }}>
            <Image
                src={entete}
                style={{
                    width: "100%",
                    height: "auto"
                }}
            />
            <div
                className=""
                style={{
                    padding: "10mm",
                    boxSizing: "border-box"
                }}
            >
                <div className='text-center text-title'>
                    ETAT GLOBAL DE PRODUCTION GUM
                </div>
                <div className='text-center'>
                    {props?.bureau ? props?.bureau : "LOME-KPALIME-ATAKPAME-SOKODE-KARA-DAPAONG"}
                </div>
                <div className='text-center'>
                    MOIS: {frenchMonth(props?.mois?.split('-')[1]).toUpperCase()} {props?.mois?.split('-')[0]}
                </div>
                {/* <div className='text-center'>
                    Edité le {frenchDate(new Date().toISOString())} par {LOGGED_USER?.personne?.prenom} {LOGGED_USER?.personne?.nom}
                </div> */}
                <br />
                <table
                    className="table table-bordered"
                    style={tableStyles}
                >
                    <thead>
                        <tr>
                            <th style={thTdStyles}>DATE</th>
                            <th style={thTdStyles}>BUREAUX</th>
                            <th colSpan={2} style={thTdStyles}>
                                <table className='table table-bordered' border={1} style={nestedTableStyles}>
                                    <tr>
                                        <td colSpan={2} style={nestedThTdStyles}>PUISSANCE 0</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} style={nestedThTdStyles}>18 205</td>
                                    </tr>
                                    <tr>
                                        <td style={nestedThTdStyles}>NOMBRE</td>
                                        <td style={nestedThTdStyles}>MONTANT</td>
                                    </tr>
                                </table>
                            </th>
                            <th colSpan={2} style={thTdStyles}><table className='table table-bordered' border={1} style={nestedTableStyles}>
                                <tr>
                                    <td colSpan={2} style={nestedThTdStyles}>PUISSANCE 1</td>
                                </tr>
                                <tr>
                                    <td colSpan={2} style={nestedThTdStyles}>25 800</td>
                                </tr>
                                <tr>
                                    <td style={nestedThTdStyles}>NOMBRE</td>
                                    <td style={nestedThTdStyles}>MONTANT</td>
                                </tr>
                            </table></th>
                            <th colSpan={2} style={thTdStyles}><table className='table table-bordered' border={1} style={nestedTableStyles}>
                                <tr>
                                    <td colSpan={2} style={nestedThTdStyles}>PUISSANCE 2</td>
                                </tr>
                                <tr>
                                    <td colSpan={2} style={nestedThTdStyles}>32 012</td>
                                </tr>
                                <tr>
                                    <td style={nestedThTdStyles}>NOMBRE</td>
                                    <td style={nestedThTdStyles}>MONTANT</td>
                                </tr>
                            </table></th>
                            <th colSpan={2} style={thTdStyles}><table className='table table-bordered' border={1} style={nestedTableStyles}>
                                <tr>
                                    <td colSpan={2} style={nestedThTdStyles}>PUISSANCE 3</td>
                                </tr>
                                <tr>
                                    <td colSpan={2} style={nestedThTdStyles}>36 252</td>
                                </tr>
                                <tr>
                                    <td style={nestedThTdStyles}>NOMBRE</td>
                                    <td style={nestedThTdStyles}>MONTANT</td>
                                </tr>
                            </table></th>
                            <th style={thTdStyles}>NBRE DE<br />MOTOS</th>
                            <th style={thTdStyles}>PRIME NETTE</th>
                            <th style={thTdStyles}>TAXES</th>
                            <th style={thTdStyles}>PRIME TTC</th>
                            <th style={thTdStyles}>CUMUL</th>
                            <th style={thTdStyles}>TIMBRE</th>
                            <th style={thTdStyles}>MONTANT<br />VERSE</th>
                            <th style={thTdStyles}>RESTE A<br />REVERSER</th>
                            <th style={thTdStyles}>OBSERVATIONS</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.jours.map((jour, index) => (
                            <tr key={index}>
                                <td style={thTdStyles}>{frenchDateOnly(jour.date)}</td>
                                <td style={thTdStyles}>{jour.bureau}</td>
                                <td style={thTdStyles}>{new Intl.NumberFormat("en-DE").format(jour.nombreMotoPuissance0)}</td>
                                <td style={thTdStyles}>{new Intl.NumberFormat("en-DE").format(jour.montantPuissance0)}</td>
                                <td style={thTdStyles}>{new Intl.NumberFormat("en-DE").format(jour.nombreMotoPuissance1)}</td>
                                <td style={thTdStyles}>{new Intl.NumberFormat("en-DE").format(jour.montantPuissance1)}</td>
                                <td style={thTdStyles}>{new Intl.NumberFormat("en-DE").format(jour.nombreMotoPuissance2)}</td>
                                <td style={thTdStyles}>{new Intl.NumberFormat("en-DE").format(jour.montantPuissance2)}</td>
                                <td style={thTdStyles}>{new Intl.NumberFormat("en-DE").format(jour.nombreMotoPuissance3)}</td>
                                <td style={thTdStyles}>{new Intl.NumberFormat("en-DE").format(jour.montantPuissance3)}</td>
                                <td style={thTdStyles}>{new Intl.NumberFormat("en-DE").format(jour.nombreMotoTotal)}</td>
                                <td style={thTdStyles}>{new Intl.NumberFormat("en-DE").format(jour.primeNette)}</td>
                                <td style={thTdStyles}>{new Intl.NumberFormat("en-DE").format(jour.taxes)}</td>
                                <td style={thTdStyles}>{new Intl.NumberFormat("en-DE").format(jour.primeTotale)}</td>
                                <td style={thTdStyles}>{new Intl.NumberFormat("en-DE").format(jour.cumul)}</td>
                                <td style={thTdStyles}>{new Intl.NumberFormat("en-DE").format(jour.timbre)}</td>
                                <td style={thTdStyles}>{new Intl.NumberFormat("en-DE").format(jour.montantVerse)}</td>
                                <td style={thTdStyles}>{new Intl.NumberFormat("en-DE").format(jour.montantRestant)}</td>
                                <td style={thTdStyles}>{jour.observations}</td>
                            </tr>
                        ))}
                        <tr>
                            <td colSpan={2} style={thTdStyles}>TOTAL</td>
                            <td style={thTdStyles}>{new Intl.NumberFormat("en-DE").format(totalMotoPuissance0)}</td>
                            <td style={thTdStyles}>{new Intl.NumberFormat("en-DE").format(totalMontantPuissance0)}</td>
                            <td style={thTdStyles}>{new Intl.NumberFormat("en-DE").format(totalMotoPuissance1)}</td>
                            <td style={thTdStyles}>{new Intl.NumberFormat("en-DE").format(totalMontantPuissance1)}</td>
                            <td style={thTdStyles}>{new Intl.NumberFormat("en-DE").format(totalMotoPuissance2)}</td>
                            <td style={thTdStyles}>{new Intl.NumberFormat("en-DE").format(totalMontantPuissance2)}</td>
                            <td style={thTdStyles}>{new Intl.NumberFormat("en-DE").format(totalMotoPuissance3)}</td>
                            <td style={thTdStyles}>{new Intl.NumberFormat("en-DE").format(totalMontantPuissance3)}</td>
                            <td style={thTdStyles}>{new Intl.NumberFormat("en-DE").format(totalMotoTotal)}</td>
                            <td style={thTdStyles}>{new Intl.NumberFormat("en-DE").format(totalPrimeNette)}</td>
                            <td style={thTdStyles}>{new Intl.NumberFormat("en-DE").format(totalTaxes)}</td>
                            <td style={thTdStyles}>{new Intl.NumberFormat("en-DE").format(totalPrimeTotale)}</td>
                            <td style={thTdStyles}></td>
                            <td style={thTdStyles}>{new Intl.NumberFormat("en-DE").format(totalTimbre)}</td>
                            <td style={thTdStyles}>{new Intl.NumberFormat("en-DE").format(totalMontantVerse)}</td>
                            <td style={thTdStyles}>{new Intl.NumberFormat("en-DE").format(totalResteAReverser)}</td>
                            <td style={thTdStyles}></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <Image
                src={footer}
                style={{
                    width: "100%",
                    height: "auto"
                }}
            />
        </div>
    )
})
EtatGlobalProductionMensuelDTRFNational.propTypes = {
    jours: PropTypes.array.isRequired,
    mois: PropTypes.string,
    bureau: PropTypes.string
}
export default EtatGlobalProductionMensuelDTRFNational