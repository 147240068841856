import { ConfigProvider, DatePicker, Select, Space, Switch } from "antd";
import frFR from "antd/locale/fr_FR";
import axios from "axios";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../../components/Footer";
import NavBar from "../../../components/NavBar";
import SideBar from "../../../components/SideBar";
import { rightsService } from "../../../services/rightsService";
import axiosInstance from "../../../variables/api/axiosInstance";
import { API_URL, TOKEN } from "../../../variables/constants";

const AddVTContrat = () => {
  const [categories, setCategories] = useState([]);
  const [typePersonne, setTypePersonne] = useState("PHYSIQUE");
  const [categorie, setCategorie] = useState("Véhicule de Transit - Camion");
  const [selectedCategorie, setSelectedCategorie] = useState("");
  const [formData, setFormData] = useState([
    {
      categorie: "Véhicule de Transit - Camion",
      marque: "",
      numeroSerie: "",
      matricule: "",
      typeSerie: "TG",
      immatriculation: "",
      puissance: 1,
      volume: "",
      nombrePlace: 0,
      energie: "ESSENCE",
      numeroChassis: "",
      valeurDeclaree: "",
      valeurVenale: "",
      valeurANeuf: "",
      dateMiseEnCirculation: "",
      puissanceType: [],
      sousGaranties: [],
      garanties: [],
      usage: "TAXI",
      carosserie: null,
      moteur: "",
      type: "",
      genre: "VELOMOTEUR",
      concessionnaire: null,
      rc: 0,
      carteGrise: "",
      personne: {
        personneId: 0,
        nom: "",
        prenom: "",
        contact: [],
        profession: "",
        genre: "MASCULIN",
        adresse: "",
        email: "",
        pieces: [],
        numIdentite: "",
        lieuDelivrance: "",
        dateDelivrance: "",
        typePiece: "CNI",
      },
      codeId: "",
    },
  ]);

  const [personneData, setPersonneData] = useState({
    nom: "",
    prenom: "",
    genre: "MASCULIN",
    profession: "",
    email: "",
    contact: [],
    typePersonne: "",
    adresse: "",
    pieces: [],
    numIdentite: "",
    lieuDelivrance: "",
    dateDelivrance: "",
    dateNaissance: "",
    typePiece: "CNI",
    nif: "",
    numeroRegistreCommerce: "",
  });

  const [insuredData, setInsuredData] = useState({
    nom: "",
    prenom: "",
    genre: "MASCULIN",
    profession: "",
    email: "",
    contact: [],
    typePersonne: "",
    adresse: "",
    pieces: [],
    numIdentite: "",
    lieuDelivrance: "",
    dateDelivrance: "",
    dateNaissance: "",
    typePiece: "CNI",
    nif: "",
    numeroRegistreCommerce: "",
  });

  const [marques, setMarques] = useState([]);
  const [allMarques, setAllMarques] = useState([]);
  const [oldInsured, setOldInsured] = useState(false);
  const [renouvellement, setRenouvellement] = useState(true);
  const [numeroPolice, setNumeroPolice] = useState("");
  const [oldClient, setOldClient] = useState("");
  const [clients, setClients] = useState([]);
  const [errors, setErrors] = useState([{}]);
  const [exonereDeTaxe, setExonereDeTaxe] = useState(false);

  const [accessoires, setAccessoires] = useState([]);
  const [dateEffet, setDateEffet] = useState(dayjs());
  const [dateEcheance, setDateEcheance] = useState();
  const [loading, setLoading] = useState(false);
  const [existChassis, setExistChassis] = useState([false]);
  const [existImmatriculation, setExistImmatriculation] = useState([false]);
  const [taxe, setTaxe] = useState(0);
  const [totalGarantiesP, setTotalGarantiesP] = useState(0);
  const [totalGarantiesO, setTotalGarantiesO] = useState(0);
  const [fraisAccessoires, setFraisAccessoires] = useState(1000);
  const [typeRenouvellement, setTypeRenouvellement] = useState("1R");
  const [plaqued, setPlaqued] = useState(false);

  const [sfErrors, setSfErrors] = useState([]);
  const [subGaranties, setSubGaranties] = useState([]);
  const navigate = useNavigate();

  const verifyChassisNumber = (numChassis, index) => {
    // console.log("Verifying...");
    axiosInstance
      .get(`/api/engin/chassis/${numChassis}`)
      .then((res) => {
        let ec = [...existChassis];
        ec[index] = res.data;
        // console.log("chasss: ", numChassis, res.data);
        if (res.data) {
          toast("Un moteur est déjà enregistré avec ce numéro de châssis");
        }
        setExistChassis(ec);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (e, name, index) => {
    let newFormValues = [...formData];

    if (name === "carteGrise") {
      newFormValues[index].carteGrise = e;
    } else if (name === "marque") {
      newFormValues[index].marque = e;
    } else {
      const { id, value } = e.target;
      newFormValues[index][id] = value;
      if (e.target.name === "valeurVenale") {
        newFormValues[index].valeurANeuf = value;
        newFormValues[index].valeurDeclaree = value;
      }

      newFormValues[index].immatriculation =
        selectedCategorie?.codeCategorie?.startsWith("VT") ||
        newFormValues[index].typeSerie !== "TG"
          ? newFormValues[index].matricule
          : newFormValues[index].numeroSerie +
            " " +
            newFormValues[index].matricule +
            " " +
            newFormValues[index].typeSerie;

      if (id === "numeroSerie" || id === "matricule" || id === "typeSerie") {
        verifyImmatriculation(newFormValues[index].immatriculation, index);
      }
      // console.log("Here out", id);
      if (id === "numeroChassis") {
        // console.log("Here");
        verifyChassisNumber(value, index);
      }
    }

    setFormData(newFormValues);
  };

  useEffect(() => {
    axiosInstance
      .get(`/api/categorie`)
      .then((res) => {
        console.log("eff: ", res.data);
        let aCategories = res.data.filter((e) =>
          e.codeCategorie.startsWith("VT"),
        );
        setCategorie(aCategories[0].nom);
        setSelectedCategorie(aCategories[0]);
        setCategories(aCategories);
        axiosInstance
          .get(`/api/parametre/type/MARQUE`)
          .then((resp) => {
            let mqs = [];
            mqs = resp.data.filter(
              (el) =>
                el.categorie.codeCategorie.trim() ===
                aCategories[0].codeCategorie.trim(),
            );
            setMarques(mqs);
            setAllMarques(resp.data);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });

    axiosInstance
      .get(`/api/personne/client`)
      .then((res) => {
        // console.log(res);
        var dt = [];
        res.data.forEach((clt) => {
          let nom = clt.prenom ? `${clt.nom} ${clt.prenom}` : `${clt.nom}`;
          dt.push({
            label: nom,
            value: JSON.stringify(clt),
            key: clt.codeId,
          });
        });
        setClients(dt);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [TOKEN]);

  const someEffects = (categorieValue, codeId) => {
    var dt = [[]];
    dt[codeId] = [];
    let newFormValues = [...formData];

    const cat = categories.find((e) => e.nom === categorieValue);

    // console.log("Categorie: ", cat);
    axiosInstance
      .get(`/api/garantie/categorie/compulsory/${cat.categorieId}`)
      .then((res) => {
        console.log("Guaranties", res.data);
        res.data?.forEach((guaranty, index) => {
          axiosInstance
            .get(
              `${API_URL}/api/sous-garantie/capital/codeId/${guaranty.codeId}`,
            )
            .then((response) => {
              dt[codeId].push({
                key: index + 1,
                nom: `${guaranty.nom} - ${guaranty.categorie.nom}`,
                capital: guaranty.nom.toLowerCase().startsWith("rc")
                  ? "-"
                  : response.data[0],
                primeNette: guaranty.nom.toLowerCase().startsWith("rc")
                  ? newFormValues[codeId].rc
                  : response.data[1],
              });
            })
            .catch((error) => {
              console.log(error);
            });
        });

        setTimeout(() => {
          // console.log("SubGuaranties", dt);
          var dts = [];
          dt.forEach((sg) => {
            sg.forEach((g) => {
              dts.push(g);
            });
          });
          if (formData.length === 1) {
            setSubGaranties(dts);
          }
          // console.log("DTS: ", dts);
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
      });
    //
  };

  const handlePuissance = (value, codeId) => {
    var puissanceRC = value;

    const cat = categories.find((e) => e.nom === formData[codeId].categorie);
    // console.log("catégorieHP: ", cat, formData[codeId], codeId);

    axiosInstance
      .get(`${API_URL}/api/sous-garantie/rc/${puissanceRC}/${cat.categorieId}`)
      .then((resp) => {
        console.log("Powerr: ", resp);
        let newFormValues = [...formData];
        newFormValues[codeId]["rc"] = resp.data.primeNette;
        setFormData(newFormValues);
        someEffects(formData[codeId].categorie, codeId);
      })
      .catch((errp) => {
        console.log(errp);
      });
  };

  const validateSouscripteurForm = () => {
    const newSfErrors = {};

    if (!personneData.nom)
      newSfErrors.nom = "Le nom du souscripteur est requis!";
    if (personneData.nom.trim().length < 2)
      newSfErrors.nom = "Le nom du souscripteur est trop court!";
    if (typePersonne === "PHYSIQUE" && !personneData.prenom)
      newSfErrors.prenom = "Le prénom du souscripteur est requis!";
    if (typePersonne === "PHYSIQUE" && personneData.prenom.trim().length < 2)
      newSfErrors.prenom = "Le prénom du souscripteur est trop court!";

    setSfErrors(newSfErrors);
    return Object.keys(newSfErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm() && validateSouscripteurForm()) {
      setLoading(true);

      // console.log("Données: ", formData);
      personneData.typePersonne = typePersonne;
      personneData.exonereDeTaxe = exonereDeTaxe;
      if (exonereDeTaxe) {
        updateTaxes();
      }
      if (insuredData?.nom) {
        insuredData.typePersonne = "PHYSIQUE";
      }
      // console.log("personne: ", personneData);
      let engins = [];
      const cat = categories.find((e) => e.nom === formData[0].categorie);
      formData.forEach((fd, id) => {
        fd.categorie = cat;
        fd.concessionnaire = JSON.parse(fd.concessionnaire);
        // fd.personne = personneData;
        fd.marque = fd.marque[0];
        engins.push(fd);
      });

      let contratData = {
        engins: engins,
        souscripteur: personneData,
        assure: insuredData?.nom ? insuredData : null,
        fractionnement: null,
        taxes: taxe,
        accessoires: fraisAccessoires,
        primeNette: totalGarantiesO * formData.length + totalGarantiesP,
        dateEffet: dateEffet?.toISOString(),
        dateEcheance: cat?.codeCategorie?.startsWith("VT")
          ? dateEffet.add(15, "day").endOf("day").toISOString()
          : null,
        intermediaire: null,
        courtier: null,
        tauxCommission: 0,
      };
      if (renouvellement) {
        contratData.numeroPoliceM = numeroPolice;
        contratData.typeRenouvellement = typeRenouvellement;
        contratData.plaqued = plaqued;
      }

      // console.log("contrat: ", contratData);
      if (oldInsured) {
        contratData.oldSouscripteur = oldClient;
        axiosInstance
          .post(`/api/contrat/new/old-client`, JSON.stringify(contratData))
          .then((res) => {
            console.log("Data Ressst", res.data);

            setLoading(false);
            navigate(`/details-contrat/${res.data.codeId}`, { replace: true });
            toast("Contrat ajoutée avec succès");
          })
          .catch((err) => {
            console.log(err);
            toast("Erreur lors de l'ajout du contrat");
            setLoading(false);
          });
      } else {
        axiosInstance
          .post(`${API_URL}/api/contrat/new`, JSON.stringify(contratData))
          .then((res) => {
            console.log("Data Ressst", res.data);
            setLoading(false);
            navigate(`/details-contrat/${res.data.codeId}`, { replace: true });
            toast("Contrat ajoutée avec succès");
          })
          .catch((err) => {
            console.log(err);
            toast("Erreur lors de l'ajout du contrat");
            setLoading(false);
          });
      }
    }
  };

  const updateTaxes = () => {
    setTaxe(
      (totalGarantiesO * formData.length + totalGarantiesP + fraisAccessoires) *
        0.06,
    );
  };

  const getDateEcheanceVT = (date, dateString) => {
    console.log(date, dateString);
    setDateEffet(date);
    setDateEcheance(date?.add(15, "day"));
    // sessionStorage.setItem("effet", date.toISOString());
  };

  const getFraisAccessoires = (category) => {
    let fa = accessoires.find((el) => el.categorie.nom === category);
    // console.log("FA: ", fa);
    fa?.libelleParametre && setFraisAccessoires(parseInt(fa?.libelleParametre));
  };

  const validateForm = () => {
    const newErrors = [];

    formData.forEach((fd, index) => {
      newErrors.push({});
      // console.log("eiiim", existImmatriculation[index], index);
      if (!fd.matricule)
        newErrors[index].matricule = "Le numéro d'immatriculation est requis";
      if (fd.matricule?.length < 6)
        newErrors[index].matricule =
          "Le numéro d'immatriculation doit avoir 6 caractères au minimum";
      if (existImmatriculation[index])
        newErrors[index].matricule =
          "Un engin est déjà enregistré avec cette immatriculation";
      if (!fd.marque) newErrors[index].marque = "La marque est requise";
      if (!fd.numeroChassis)
        newErrors[index].numeroChassis = "Le numéro de châssis est requis";
      if (fd.numeroChassis?.length > 17)
        newErrors[index].numeroChassis =
          "Le numéro de châssis doit comporter 17 caractères au maximum";
      if (existChassis[index])
        newErrors[index].numeroChassis =
          "Un moteur est déjà enregistré avec ce numéro de châssis!";
      if (renouvellement && !numeroPolice)
        newErrors[index].numeroPolice =
          "Veuillez renseigner le numéro de police!";
      if (renouvellement && !numeroPolice.length)
        newErrors[index].numeroPolice =
          "Veuillez renseigner le numéro de police!";
    });

    // Add more validation rules if needed
    setErrors(newErrors);

    console.log("Trusting you: ", newErrors);

    let valid = true;
    newErrors.forEach((ne) => {
      if (Object.keys(ne).length !== 0) {
        valid = false;
      }
    });

    return valid;
  };

  const verifyImmatriculation = (immatriculation, index) => {
    axiosInstance
      .get(`/api/engin/immatriculation/${immatriculation}`)
      .then((res) => {
        // console.log("Immatriculation: ", immatriculation);
        // console.log(res.data, index);
        let ei = [...existImmatriculation];
        ei[index] = res.data;
        if (res.data) {
          toast("Immatriculation déjà existante!");
        }
        setExistImmatriculation(ei);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePersonneChange = (e) => {
    const { id, value } = e.target;
    if (e.target.name === "telephone") {
      let contacts = [value];
      setPersonneData({ ...personneData, ["contact"]: contacts });
    } else {
      setPersonneData({ ...personneData, [id]: value });
    }
  };

  return (
    <div class="layout-wrapper layout-content-navbar  ">
      <div class="layout-container">
        <SideBar />

        {/* <!-- Layout container --> */}
        <div class="layout-page">
          <NavBar />

          {/* <!-- Content wrapper --> */}
          <div class="content-wrapper">
            {/* <!-- Content --> */}
            <div class="container-xxl flex-grow-1 container-p-y">
              {/* Your main content here */}
              <div class="card mb-6">
                <h5 class="card-header">
                  Etablir un{" "}
                  {renouvellement ? "renouvellement" : "nouveau contrat"}
                </h5>
                <Space direction="vertical">
                  <Switch
                    checkedChildren="Renouvellement"
                    unCheckedChildren="Affaire Nouvelle"
                    value={renouvellement}
                    onChange={(e) => {
                      setRenouvellement(true);
                    }}
                  />
                </Space>
                <form class="card-body" onSubmit={handleSubmit}>
                  <h6>1. Informations sur l'engin</h6>
                  <div class="row g-6">
                    <div className="col-sm-12">
                      <label class="form-label" for="categorieId">
                        Catégorie *
                      </label>
                      <select
                        id="categorie"
                        name="categorie"
                        class="form-control w-100"
                        data-style="btn-default"
                        value={formData[0].categorie}
                        onChange={(e) => {
                          handleChange(e, "categorie", 0);
                          setCategorie(e.target.value);
                          const cat = categories.find(
                            (el) => el.nom === e.target.value,
                          );
                          setSelectedCategorie(cat);
                          let mqs = [];
                          mqs = allMarques.filter(
                            (el) => el.categorie.nom.trim() === e.target.value,
                          );
                          setMarques(mqs);
                          someEffects(e.target.value, 0);
                          getFraisAccessoires(e.target.value);
                        }}
                      >
                        {categories?.map((cat, id) => {
                          if (
                            cat.codeCategorie.startsWith("VT") &&
                            rightsService("HCO021")
                          ) {
                            return (
                              <option key={id} value={cat.nom}>
                                {cat.nom}
                              </option>
                            );
                          } else if (
                            cat.codeCategorie.startsWith("M") &&
                            rightsService("HCO019")
                          ) {
                            return (
                              <option key={id} value={cat.nom}>
                                {cat.nom}
                              </option>
                            );
                          } else if (
                            cat.codeCategorie.startsWith("TPV") &&
                            rightsService("HCO020")
                          ) {
                            return (
                              <option key={id} value={cat.nom}>
                                {cat.nom}
                              </option>
                            );
                          }
                        })}
                      </select>
                    </div>
                    <div className="col-sm-6">
                      <label
                        className="form-label"
                        htmlFor="last-name-modern-vertical"
                      >
                        Immatriculation *
                      </label>
                      <input
                        type="text"
                        aria-label="Immatriculation"
                        placeholder="Immatriculation"
                        class={`form-control ${
                          errors[0]?.matricule ? "is-invalid" : ""
                        }`}
                        id="matricule"
                        name="matricule"
                        value={formData[0].matricule}
                        onChange={(e) => handleChange(e, "matricule", 0)}
                      />
                      {errors[0]?.matricule && (
                        <div className="invalid-feedback">
                          {errors[0]?.matricule}
                        </div>
                      )}
                    </div>
                    <div className="col-sm-6">
                      <label className="form-label" htmlFor="marque">
                        Marque *
                      </label>
                      {rightsService("HPA060") ? (
                        <Select
                          mode="tags"
                          style={{
                            width: "100%",
                          }}
                          maxCount={1}
                          placeholder="Sélectionner la marque"
                          onChange={(e) => {
                            selectedCategorie?.codeCategorie &&
                              selectedCategorie?.codeCategorie?.startsWith(
                                "VT",
                              ) &&
                              handlePuissance(1, 0);
                            handleChange(e, "marque", 0);
                          }}
                          options={marques?.map((mar, id) => ({
                            label: mar.libelleParametre,
                            value: mar.libelleParametre,
                            key: id,
                          }))}
                        />
                      ) : (
                        <select
                          id="marque"
                          name="marque"
                          value={formData[0].marque}
                          onChange={(e) => {
                            handleChange(e.target.value, "marque", 0);
                            selectedCategorie?.codeCategorie &&
                              selectedCategorie?.codeCategorie?.startsWith(
                                "VT",
                              ) &&
                              handlePuissance(1, 0);
                          }}
                          class={`form-control w-100 ${
                            errors[0]?.marque ? "is-invalid" : ""
                          }`}
                          data-style="btn-default"
                        >
                          <option value="">Sélectionner la marque</option>
                          {marques?.map((mar, id) => (
                            <option key={id} value={mar.libelleParametre}>
                              {mar.libelleParametre}
                            </option>
                          ))}
                        </select>
                      )}
                      {errors[0]?.marque && (
                        <div className="invalid-feedback">
                          {errors[0]?.marque}
                        </div>
                      )}
                    </div>
                    <div className="col-sm-6">
                      <label className="form-label" htmlFor="numeroChassis">
                        Numéro de châssis *
                      </label>
                      <input
                        type="text"
                        id="numeroChassis"
                        name="numeroChassis"
                        value={formData[0].numeroChassis}
                        onChange={(e) => handleChange(e, "numeroChassis", 0)}
                        className={`form-control ${
                          errors[0]?.numeroChassis ? "is-invalid" : ""
                        }`}
                        placeholder="Numéro de châssis"
                      />
                      {errors[0]?.numeroChassis && (
                        <div className="invalid-feedback">
                          {errors[0]?.numeroChassis}
                        </div>
                      )}
                    </div>
                    {renouvellement ? (
                      <div className="col-sm-6">
                        <label className="form-label" htmlFor="valeurANeuf">
                          Numéro de police *
                        </label>
                        <input
                          type="text"
                          id="numeroPolice"
                          name="numeroPolice"
                          value={numeroPolice}
                          onChange={(e) => setNumeroPolice(e.target.value)}
                          className={`form-control ${
                            errors[0]?.numeroPolice ? "is-invalid" : ""
                          }`}
                          placeholder="Numéro de Police"
                        />
                        {errors[0]?.numeroPolice && (
                          <div className="invalid-feedback">
                            {errors[0]?.numeroPolice}
                          </div>
                        )}
                      </div>
                    ) : null}
                    {renouvellement ? (
                      <div class="col-md-6 select2-primary">
                        <label class="form-label" for="typeRenouvellement">
                          Type de renouvellemennt
                        </label>
                        <select
                          id="typeRenouvellement"
                          name="typeRenouvellement"
                          class="form-select"
                          onChange={(e) => {
                            // console.log("Type renouvellement: ", e.target.value);
                            setTypeRenouvellement(e.target.value);
                          }}
                        >
                          <option value="1R">1er renouvellement</option>
                          <option value="2R">2ème renouvellement</option>
                          <option value="3M">Après 3 mois</option>
                        </select>
                      </div>
                    ) : null}
                    {renouvellement ? (
                      <div class="col-md-6 select2-primary">
                        <label class="form-label" for="typeRenouvellement">
                          Date d'effet
                        </label>
                        <br />
                        <ConfigProvider locale={frFR}>
                          <DatePicker
                            onChange={getDateEcheanceVT}
                            placeholder="Entrer la date effet"
                            showTime={true}
                            showNow={true}
                            showToday={true}
                            defaultValue={dayjs()}
                            style={{ width: "100%", height: "60%" }}
                          />
                        </ConfigProvider>
                      </div>
                    ) : null}
                    {renouvellement && typeRenouvellement === "1R" ? (
                      <Space direction="vertical">
                        <Switch
                          checkedChildren="Plaqué"
                          unCheckedChildren="Non plaqué"
                          value={plaqued}
                          onChange={(e) => {
                            setPlaqued(e);
                          }}
                        />
                      </Space>
                    ) : null}
                  </div>
                  <hr class="my-6 mx-n6" />
                  <h6>2. Information sur le souscripteur / l'assuré</h6>
                  <div class="row g-6">
                    <div className="col-sm-12">
                      <div class="col-xl-12">
                        <div class="card">
                          <h5 class="card-header">Type de personne</h5>
                          <div class="card-body">
                            <div class="row">
                              <div class="col-md mb-md-0 mb-5">
                                <div class="form-check custom-option custom-option-icon">
                                  <label
                                    class="form-check-label custom-option-content"
                                    for="customRadioIcon2"
                                  >
                                    <span class="custom-option-body">
                                      <i class="bx bx-user"></i>
                                      <span class="custom-option-title">
                                        {" "}
                                        Physique{" "}
                                      </span>
                                    </span>
                                    <input
                                      name="customOptionRadioIcon"
                                      class="form-check-input"
                                      type="radio"
                                      value=""
                                      id="customRadioIcon2"
                                      onChange={() =>
                                        setTypePersonne("PHYSIQUE")
                                      }
                                      checked={typePersonne === "PHYSIQUE"}
                                    />
                                  </label>
                                </div>
                              </div>
                              <div class="col-md">
                                <div class="form-check custom-option custom-option-icon">
                                  <label
                                    class="form-check-label custom-option-content"
                                    for="customRadioIcon3"
                                  >
                                    <span class="custom-option-body">
                                      <i class="bx bx-briefcase"></i>
                                      <span class="custom-option-title">
                                        {" "}
                                        Morale{" "}
                                      </span>
                                    </span>
                                    <input
                                      name="customOptionRadioIcon"
                                      class="form-check-input"
                                      type="radio"
                                      value=""
                                      id="customRadioIcon3"
                                      onChange={() => setTypePersonne("MORALE")}
                                    />
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <label className="form-label" htmlFor="nom">
                        {typePersonne === "PHYSIQUE"
                          ? "Nom *"
                          : "Raison sociale *"}
                      </label>
                      <input
                        type="text"
                        id="nom"
                        className={`form-control ${
                          sfErrors?.nom ? "is-invalid" : ""
                        }`}
                        placeholder="Entrez le nom"
                        name="nom"
                        value={personneData.nom}
                        onChange={handlePersonneChange}
                      />
                      {sfErrors?.nom && (
                        <div className="invalid-feedback">{sfErrors?.nom}</div>
                      )}
                    </div>
                    {typePersonne === "PHYSIQUE" ? (
                      <div className="col-sm-6">
                        <label className="form-label" htmlFor="prenom">
                          Prénom(s) *
                        </label>
                        <input
                          type="text"
                          id="prenom"
                          className={`form-control ${
                            sfErrors?.prenom ? "is-invalid" : ""
                          }`}
                          placeholder="Entrez le prénom"
                          name="prenom"
                          value={personneData.prenom}
                          onChange={handlePersonneChange}
                        />
                        {sfErrors?.prenom && (
                          <div className="invalid-feedback">
                            {sfErrors?.prenom}
                          </div>
                        )}
                      </div>
                    ) : null}
                    <div className="col-sm-6">
                      <label className="form-label" htmlFor="adresse">
                        {typePersonne === "PHYSIQUE"
                          ? "Adresse de résidence *"
                          : "Siège social *"}
                      </label>
                      <input
                        type="text"
                        id="adresse"
                        className={`form-control ${
                          sfErrors?.adresse ? "is-invalid" : ""
                        }`}
                        placeholder={
                          typePersonne === "PHYSIQUE"
                            ? "Entrez l'adresse de résidence"
                            : "Entrez le siège social"
                        }
                        name="adresse"
                        value={personneData.adresse}
                        onChange={handlePersonneChange}
                      />
                      {sfErrors?.adresse && (
                        <div className="invalid-feedback">
                          {sfErrors?.adresse}
                        </div>
                      )}
                    </div>
                  </div>
                  <div class="pt-6">
                    <button
                      type="submit"
                      class="btn btn-primary me-3"
                      disabled={loading}
                    >
                      {loading && (
                        <span
                          class="spinner-grow me-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      )}
                      <span className="align-middle d-sm-inline-block d-none me-sm-2">
                        {loading ? `Enregistrement...` : `Enregistrer`}
                      </span>
                    </button>
                    <button type="reset" class="btn btn-label-secondary">
                      Annuler
                    </button>
                  </div>
                </form>
              </div>
            </div>
            {/* <!-- / Content --> */}

            {/* <!-- Footer --> */}
            <Footer />
            {/* <!-- / Footer --> */}

            <div class="content-backdrop fade"></div>
          </div>
          {/* <!-- Content wrapper --> */}
        </div>
        {/* <!-- / Layout page --> */}
      </div>

      {/* <!-- Overlay --> */}
      <div class="layout-overlay layout-menu-toggle"></div>

      {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
      <div class="drag-target"></div>
    </div>
  );
};

export default AddVTContrat;
