import { DatePicker, Modal, Select, Space, Switch, Tag } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axiosInstance from "../../../../variables/api/axiosInstance";
import { API_URL, TOKEN } from "../../../../variables/constants";

const Renouvellement = (props) => {
  const [modalRenouvellement, setModalRenouvellement] = useState(props.open);
  const [loading, setLoading] = useState(false);
  const [contrat, setContrat] = useState();
  const [dateEcheance, setDateEcheance] = useState("");
  const [dateEffet, setDateEffet] = useState("");
  const [fractionnements, setFractionnements] = useState([]);
  const [fractionnement, setFractionnement] = useState("");
  const [plaquedVehicule, setPlaquedVehicule] = useState(true);

  useEffect(() => {
    setModalRenouvellement(props.open);
    setContrat(props.contrat);
    setDateEcheance(dayjs().add(15, "d").subtract(1, "s").toISOString());
  }, [props.open, props?.contrat]);

  const param = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (param.contratId) {
      axios
        .get(
          `${API_URL}/api/fractionnement/contrat/codeId/${param.contratId}`,
          {
            headers: {
              Authorization: `Bearer ${TOKEN}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
              "Access-Control-Allow-Headers":
                "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
            },
          },
        )
        .then((res) => {
          // console.log(res.data);
          setFractionnements(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [TOKEN, param.contratId]);

  const handleRenouvellementSubmit = () => {
    setLoading(true);
    contrat.dateEffet = dateEffet;
    axiosInstance
      .put(
        contrat?.engins[0]?.categorie?.codeCategorie?.startsWith("VT")
          ? plaquedVehicule
            ? `/api/contrat/renouvellement/vt/plaqued`
            : `/api/contrat/renouvellement/vt/non-plaqued`
          : `/api/contrat/renouvellement`,
        JSON.stringify(contrat),
      )
      .then((res) => {
        // console.log(res.data);
        toast("Renouvellement effectué!");
        setLoading(false);
        setModalRenouvellement(false);
        props.update(false);
        navigate(`/details-contrat/${res.data.codeId}`);
      })
      .catch((err) => {
        console.log(err);
        toast("Erreur lors du renouvellement!");
      });
  };

  const showDateWithTime = (date) => {
    var d = date?.split("T")[0];
    var t = date?.split("T")[1];
    return `${d} - ${t}`;
  };

  const handleCancel = () => {
    setModalRenouvellement(false);
    console.log(dateEcheance);
    props.update(false);
  };

  return (
    <Modal
      title="RENOUVELLEMENT"
      centered
      confirmLoading={loading}
      open={modalRenouvellement}
      onOk={() => handleRenouvellementSubmit()}
      onCancel={() => handleCancel()}
    >
      <div className="row" justify="center">
        <div className="col-12" xs={12} sm={12} md={12} lg={10}>
          Date Echéance du present contrat:{" "}
          <Tag color="blue">
            <b>{showDateWithTime(contrat?.dateEcheance)}</b>
          </Tag>
        </div>

        <br />
        <div className="col-12" xs={12} sm={12} md={12} lg={10}>
          <DatePicker
            value={dateEffet}
            onChange={(event) => {
              // console.log("Date effet: ", event.toISOString());
              setDateEffet(event);
              //   var dt = event.add(12, "M");
              // dt = dt.transform("YYYY-MM-+00 23:59:59.000");
              // console.log("MM: ", JSON.parse(fractionnement).moisMin);
              !contrat?.engins[0]?.categorie?.codeCategorie?.startsWith("VT")
                ? fractionnement &&
                  setDateEcheance(
                    event.add(JSON.parse(fractionnement).moisMin, "M"),
                  )
                : setDateEcheance(
                    event.add(15, "d").subtract(1, "s").toISOString(),
                  );
            }}
            timeFormat={true}
            placeholder="Définir une nouvelle date effet"
            style={{ width: "100%" }}
          />
        </div>
        <br />
        {contrat?.engins[0]?.categorie?.codeCategorie?.startsWith(
          "VT",
        ) ? null : (
          <div className="col-12" col-12>
            <form>
              <label htmlFor="fractionnement" className="form-label">
                Fractionnement
              </label>
              <Select
                value={fractionnement}
                onChange={(event) => {
                  setFractionnement(event.target.value);
                  var date = new Date(dateEffet);
                  date.setMonth(
                    date.getMonth() + JSON.parse(event.target.value).moisMin,
                  );
                  console.log("New Da: ", date);
                  console.log("New D: ", date.toISOString());
                  setDateEcheance(date);
                }}
                options={fractionnements?.map((fract, index) => ({
                  label: `${fract.nombre} - ${fract.libelleFractionnement}`,
                  value: JSON.stringify(fract),
                  key: index,
                }))}
                style={{ width: "100%" }}
              />
            </form>
          </div>
        )}
        <br />
        <br />
        <br />
        <div className="col-12">
          Nouvelle Date Echéance:{" "}
          <Tag color="green">
            {/* <b>{showDateWithTime(dateEcheance)}</b> */}
            <b>{dateEcheance && showDateWithTime(dateEcheance)}</b>
          </Tag>
        </div>
        {contrat?.engins[0]?.categorie?.codeCategorie?.startsWith("VT") ? (
          <Space direction="vertical">
            <Switch
              checkedChildren="Plaqué"
              unCheckedChildren="Non plaqué"
              value={plaquedVehicule}
              onChange={(e) => {
                setPlaquedVehicule(e);
              }}
            />
          </Space>
        ) : null}
      </div>
    </Modal>
  );
};

Renouvellement.propTypes = {
  open: PropTypes.bool,
  update: PropTypes.func,
};

export default Renouvellement;
