import React, {useState} from 'react';
import {Form, Input, Modal, Select} from 'antd';
import {toast} from "react-toastify";

const {Option} = Select;

const ProvisionsModal = ({
                             provisionsGarantiesModal,
                             setProvisionsGarantiesModal,
                             prejudices, sousEvaluation, listPrejudices, setListPrejudices,

                         }) => {
    const [selectedPrejudice, setSelectedPrejudice] = useState(null);
    const [cost, setCost] = useState('');

    const selectedPrejudiceObject = prejudices.find((p) => p.value === selectedPrejudice)


    const handleSave = () => {
        const existingPrejudiceIndex = listPrejudices.findIndex((prejudice) => prejudice.codeId === selectedPrejudiceObject.codeId)
        if (existingPrejudiceIndex !== -1) {
            Modal.confirm({
                title: 'Confirmer la Mise à Jour',
                content: `Une provision pour "${listPrejudices[existingPrejudiceIndex].libellePrejudice}" existe déjà. Voulez-vous mettre à jour la valeur avec ${cost} FCFA ?`,
                okText: 'Oui',
                cancelText: 'Non',
                onOk: () => {
                    const updatedPrejudices = [...listPrejudices];
                    updatedPrejudices[existingPrejudiceIndex].montantPrejudice = cost;
                    sousEvaluation.prejudices = updatedPrejudices;
                    setListPrejudices(updatedPrejudices)
                    toast('Provision mise à jour avec succès');
                    resetForm();
                },
            });
        } else {
            const newPrejudice = {
                libellePrejudice: selectedPrejudiceObject.value,
                montantPrejudice: cost,
                codeId: selectedPrejudiceObject.codeId,
            };
            const updatedListPrejudices = [...listPrejudices, newPrejudice];
            sousEvaluation.prejudices = updatedListPrejudices;

            setListPrejudices(updatedListPrejudices);

            toast('Provision ajoutée avec succès');
            resetForm();
        }


    }

    const resetForm = () => {
        setProvisionsGarantiesModal(false);
        setSelectedPrejudice(null);
        setCost('');
    };

    return (
        <Modal
            open={provisionsGarantiesModal}
            title="Provision"
            centered
            onOk={handleSave}
            onCancel={resetForm}
            width={500}
            zIndex={1000}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    width: '100%'
                }}
            >
                <Form layout="vertical" style={{width: '100%'}}>
                    {/* Prejudice Selector */}
                    <Form.Item label="Type de Préjudice">
                        <Select
                            placeholder="Choisissez un préjudice"
                            onChange={(value, obj) => {
                                setSelectedPrejudice(value)
                            }}
                            style={{width: '100%'}}
                            value={selectedPrejudice}
                        >
                            {prejudices.map((prejudice) => (
                                <Option key={prejudice.value} value={prejudice.value}>
                                    {prejudice.label}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    {/* Cost Input */}
                    {selectedPrejudice && (
                        <Form.Item
                            label={`Evaluation du ${prejudices.find((p) => p.value === selectedPrejudice)?.label}`}
                        >
                            <Input
                                type="number"
                                prefix="FCFA"
                                placeholder="Saisissez le montant"
                                value={cost}
                                onChange={(e) => {
                                    const value = Math.max(0, Number(e.target.value));
                                    setCost(value);
                                }}
                            />

                        </Form.Item>
                    )}
                </Form>
            </div>
        </Modal>
    );

};

export default ProvisionsModal;
