import axiosInstance from "../variables/api/axiosInstance";
import axiosMultiPartDataInstance from "../variables/api/axiosMultiPartDatainstance";

export const recupererLesClients = () => {
    return axiosInstance.get(`/api/personne/client`);
};

export const recupererLesContrats = () => {
    return axiosInstance.get(`/api/contrat`);
};

export const recupererLesEntitesDeconstat = () => {
    return axiosInstance.get(`/api/entite-constat`);
};

export const recupererLeContratDunClient = (numeroAssure) => {
    return axiosInstance.get(`/api/contrat/souscripteur/${numeroAssure}`);
};

export const ajouterUnTemoin = (temoin) => {
    return axiosInstance.post(`/api/personne`, temoin);
};

export const addPersonne = (personne) => {
    return axiosInstance.post(`/api/personne`, personne);
};

export const updatePersonne = (personne) => {
    return axiosInstance.put(`/api/personne`, personne);
};

export const ajouterUneVictime = (victime) => {
    return axiosInstance.post(`/api/victime`, victime);
};

export const editerUneVictime = (victime) => {
    return axiosInstance.put(`/api/victime`, victime);
};

export const ajouterUnEngin = (engin) => {
    return axiosInstance.post(`/api/engin`, engin);
};

export const ajouterSinistre = (sinistre) => {
    return axiosInstance.post(`/api/sinistre/sinister`, sinistre);
};

export const getSinistreById = (codeId) => {
    return axiosInstance.get(`/api/sinistre/codeId/${codeId}`);
};

export const getParametre = (typeParametre) => {
    return axiosInstance.get(`/api/parametre/type/${typeParametre}`);
};


export const getAllEvaluations = () => {
    return axiosInstance.get(`/api/evaluation/`);
};

export const getAllEvaluationsWithSinistre = () => {
    return axiosInstance.get(`/api/evaluation/sinistre`);
};

export const getAllDecaissementWithSinistre = () => {
    return axiosInstance.get(`/api/decaissement/sinistre`);
};


export const saveSinistreDeclaration = (sinistreDTO) => {
    return axiosInstance.post(`/api/sinistre/web`, sinistreDTO);
};

export const saveReevaluation = (evaluation) => {
    return axiosInstance.post(`/api/evaluation/reevaluation`, evaluation);
};

export const updateVictime = (victime) => {
    return axiosInstance.put(`/api/victime`, victime);
};

export const saveVictime = (victimeDTO) => {
    return axiosInstance.post(`/api/victime`, victimeDTO);
};

export const saveExpert = (expert, id) => {
    return axiosInstance.post(`/api/sinistre/expert/codeId/${id}`, expert);
};

export const saveDecaissement = (decaissement) => {
    return axiosInstance.post(`/api/decaissement`, decaissement);
};

export const updateSinistre = (sinistre) => {
    return axiosInstance.put(`/api/sinistre`, sinistre);
};

export const updateEvaluation = (evaluation) => {
    return axiosInstance.put(`/api/evaluation`, evaluation);
};

export const validateEvaluation = (codeId) => {
    return axiosInstance.get(`/api/evaluation/validate/${codeId}`);
};

export const validateDossierSinistre = (codeId) => {
    return axiosInstance.get(`/api/sinistre/validation/${codeId}`);
};

export const checkContratAvailability = async (engins) => {
    return await axiosInstance.post(`/api/attestation-digitale/check/availability`, engins);
};

export const assignNumAttestation = async (engins) => {
    return await axiosInstance.post(`/api/attestation-digitale/assign`, engins);
};

export const validateWithComment = async (validationBonCommandeDTO) => {
    return await axiosInstance.post(`/api/bon-commande/validate-comment`, validationBonCommandeDTO);
};

export const updateBonCommande = async (bonCommande) => {
    return await axiosInstance.put(`/api/bon-commande/`, bonCommande);
};


export const rejectEvaluation = (codeId) => {
    return axiosInstance.get(`/api/evaluation/reject/${codeId}`);
};


export const validateDecaissement = (codeId) => {
    return axiosInstance.get(`/api/decaissement/validate/${codeId}`);
};


export const rejectDecaissement = (codeId) => {
    return axiosInstance.get(`/api/decaissement/reject/${codeId}`);
};


export const saveSinistreFile = async (file) => {
    if (file) {
        const formData = new FormData();
        formData.append('fichier', file);

        const response = await axiosMultiPartDataInstance.post('/api/sinistre/file', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        console.log(response.data)
        sessionStorage.removeItem('file')
        sessionStorage.setItem('file', response.data);

        return response.data;
    }
}


export const saveSinistreFiles = async (file) => {
    if (file) {
        const formData = new FormData();
        formData.append('fichier', file);

        const response = await axiosMultiPartDataInstance.post('/api/sinistre/files', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        console.log(response.data)
        sessionStorage.removeItem('file')
        sessionStorage.setItem('file', response.data);

        return response.data;
    }
}

export const saveReglementCheque = async (file) => {
    if (file) {
        const formData = new FormData();
        formData.append('fichier', file);

        const response = await axiosMultiPartDataInstance.post('/api/reglement/file', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        console.log(response.data)
        sessionStorage.removeItem('file')
        sessionStorage.setItem('file', response.data);

        return response.data;
    }
}


