import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Modal, Table } from "antd";
import confirm from "antd/es/modal/confirm";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Footer from "../../../components/Footer";
import NavBar from "../../../components/NavBar";
import SideBar from "../../../components/SideBar";
import { API_URL, TOKEN } from "../../../variables/constants";
import AddPrejudice from "./AddPrejudice";

const Prejudices = () => {
  const [prejudices, setPrejudices] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedPrejudice, setSelectedPrejudice] = useState(null);

  const handleShow = (edit, prejudice = null) => {
    setEditMode(edit);
    setSelectedPrejudice(prejudice);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleEdit = (prejudice) => {
    setEditMode(true);
    setSelectedPrejudice(prejudice);
    setShowModal(true);
  };

  const showDeleteConfirm = (codeId) => {
    confirm({
      title: "Êtes-vous sûr de vouloir supprimer ce préjudice?",
      icon: <ExclamationCircleFilled />,
      content: "Cette action est irréversible.",
      okText: "Oui",
      okType: "danger",
      cancelText: "Annuler",
      onOk() {
        deletePrejudice(codeId);
      },
    });
  };

  const deletePrejudice = (codeId) => {
    axios
      .delete(`${API_URL}/api/prejudice/${codeId}`, {
        headers: { Authorization: `Bearer ${TOKEN}` },
      })
      .then(() => {
        setPrejudices((prevPrejudices) =>
          prevPrejudices.filter((p) => p.codeId !== codeId)
        );
        toast.success("Préjudice supprimé avec succès");
      })
      .catch((err) => {
        console.error(
          "Erreur lors de la suppression du préjudice :",
          err.response?.data || err.message
        );
        toast.error("Erreur lors de la suppression du préjudice");
      });
  };

  const loadPrejudices = () => {
    axios
      .get(`${API_URL}/api/prejudice`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log("Données récupérées :", res.data);
        setPrejudices(res.data); // Mettez à jour les données récupérées
      })
      .catch((err) => {
        console.error(
          "Erreur lors du chargement des préjudices :",
          err.response?.data || err.message
        );
        toast.error("Erreur lors du chargement des préjudices");
      });
  };

  useEffect(() => {
    loadPrejudices();
  }, []);

  const columns = [
    {
      title: "Libellé",
      dataIndex: "libellePrejudice",
      key: "libellePrejudice",
    },
    {
      title: "Montant",
      dataIndex: "montant",
      key: "montant",
      render: (montant) => `${montant} €`,
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <>
          <Button
            type="primary"
            icon={<EditOutlined />}
            style={{ marginRight: 8 }}
            onClick={() => handleEdit(record)}
          >
            Modifier
          </Button>
          <Button
            type="danger"
            icon={<DeleteOutlined />}
            onClick={() => showDeleteConfirm(record.codeId)}
          >
            Supprimer
          </Button>
        </>
      ),
    },
  ];

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <SideBar currentMenu={"PREJUDICES"} />

        <div className="layout-page">
          <NavBar />

          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              <Button
                type="primary"
                icon={<PlusOutlined />}
                style={{ marginBottom: 16 }}
                onClick={() => handleShow(false)}
              >
                Ajouter un nouveau préjudice
              </Button>
              <div className="card">
                <h5 className="card-header">Liste des préjudices</h5>
                <Table
                  columns={columns}
                  dataSource={prejudices}
                  rowKey="codeId" // Correction ici
                  pagination={{ pageSize: 5 }}
                />
              </div>

              <Modal open={showModal} footer={null} onCancel={handleClose}>
                <Modal open={showModal} footer={null} onCancel={handleClose}>
                  <AddPrejudice
                    show={showModal}
                    handleClose={handleClose}
                    editMode={editMode}
                    selectedPrejudice={selectedPrejudice}
                    onSaveSuccess={() => {
                      loadPrejudices();
                      setShowModal(false);
                    }}
                  />
                </Modal>
              </Modal>
            </div>

            <Footer />
            <div className="content-backdrop fade"></div>
          </div>
        </div>
      </div>
      <div className="layout-overlay layout-menu-toggle"></div>
      <div className="drag-target"></div>
    </div>
  );
};

export default Prejudices;
