import {Typography} from "antd";
import React, {useRef} from "react";
import {toast} from "react-toastify";
import {FormDatePicker, FormInput, FormRadio, FormSelect} from "../../../../../components/forms/FormInputs";
import dayjs from "dayjs";

const {Title} = Typography;

const SingleItemForm = ({
                            formik,
                            index,
                            isPieton,
                            setIsPieton,
                            wasAdversaireConducteur,
                            setWasAdversaireConducteur
                        }) => {
    const getFieldPath = (field) => `items[${index}].${field}`;
    const getEnginNestedFieldPath = (nestedField) => `items[${index}].engin.${nestedField}`;
    const getConducteurNestedFieldPath = (nestedField) => `items[${index}].conducteur.${nestedField}`;

    const handleFieldErrorToast = (fieldName) => {
        const fieldMeta = formik.getFieldMeta(fieldName);
        fieldMeta.touched = true
        if (fieldMeta.touched && fieldMeta.error) {
            toast.error(fieldMeta.error, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };
    console.log(isPieton)


    const listeUsage = [
        {value: "PRIVE", label: "PRIVÉ"},
        {value: "TAXI", label: "TAXI"},
    ];
    const listeDesCategoriesDePermis = [
        {value: "A1", label: "A1"},
        {value: "B", label: "B"},
        {value: "C", label: "C"},
        {value: "D", label: "D"},
    ];

    const listeDesQualitesDuConducteur = [
        {value: "propriétaire", label: "Propriétaire"},
        {value: "locataire", label: "Locataire"},
        {value: "emprunteur", label: "Emprunteur"},
        {value: "salarié", label: "Salarié"},
    ];
    const listeDesTypesDeDommagesPossibles = [
        {value: "MATERIEL", label: "Matériel"},
        {value: "CORPOREL", label: "Corporel"},
        {value: "MIXTE", label: "Mixte"},
    ];
    console.log(formik.values)
    const currentItem = formik.values.items[index];
    console.log(currentItem, index, 'hai')


    const pietonRef = useRef(null)
    const getFrenchDate = () => {
        const now = dayjs();
        return now.format("DD-MM-YYYY");
    };

    return (
        <>
            <div className="grid-container grid container" style={{justifyContent: "center"}}>
                <Title style={{
                    fontSize: '26px',
                    color: '#1a1a1a'
                }}> Informations sur l'adversaire </Title>
                <FormRadio
                    label={`L'adversaire est il piéton ?`}
                    name={getFieldPath("pieton")}
                    value={currentItem.pieton || ""}
                    ref={pietonRef}
                    onBlur={() => handleFieldErrorToast(getFieldPath("pieton"))}
                    onChange={(event) => {
                        formik.setFieldValue(`${getFieldPath("pieton")}`, event);
                        setIsPieton(event === "oui");
                        if (event === 'oui') {
                            setWasAdversaireConducteur(false)
                            formik.setFieldValue('conduisait', 'non')
                        } else {
                            formik.setFieldValue('conduisait', 'oui')
                            setWasAdversaireConducteur(true)
                        }
                    }
                    }
                    options={[
                        {label: 'Oui', value: 'oui'},
                        {label: 'Non', value: 'non'},
                    ]}
                />
                {
                    !currentItem.pieton ? (
                        <div style={{
                            backgroundColor: '#f0f9ff', // Light blue background
                            border: '1px solid #b6d4fe', // Blue border
                            borderRadius: '8px', // Rounded corners
                            padding: '12px 16px', // Padding
                            color: '#084298', // Dark blue text
                            fontSize: '12px', // Font size
                            fontWeight: '500', // Slightly bold
                            marginBottom: '16px', // Space below
                            marginTop: '16px', // Space below
                            textAlign: 'center', // Center align
                        }}>
                            Veuillez indiquer si l'adversaire est un piéton ou conduit un véhicule.
                        </div>
                    ) : (
                        <>
                            <>
                                <FormInput
                                    label={`Nom de l'adversaire`}
                                    name={getFieldPath("nom")}
                                    value={currentItem.nom || ""}
                                    onBlur={() => {
                                        handleFieldErrorToast(getFieldPath("nom"))
                                    }}
                                    onChange={(event) => formik.handleChange(event)}
                                />
                                <FormInput
                                    label={`Prénoms de l'adversaire`}
                                    name={getFieldPath("prenom")}
                                    value={currentItem.prenom || ""}
                                    onBlur={() => handleFieldErrorToast(getFieldPath("prenom"))}
                                    onChange={(event) => formik.handleChange(event)}
                                />
                                <FormInput
                                    label={`Numero de téléphone de l'adversaire`}
                                    name={getFieldPath("telephone")}
                                    value={currentItem.telephone || ""}
                                    onBlur={() => handleFieldErrorToast(getFieldPath("telephone"))}
                                    onChange={(event) => formik.handleChange(event)}
                                />
                                <FormInput
                                    label={`Profession de l'adversaire`}
                                    name={getFieldPath("profession")}
                                    value={currentItem.profession || ""}
                                    onBlur={() => handleFieldErrorToast(getFieldPath("profession"))}
                                    onChange={(event) => formik.handleChange(event)}
                                />
                                <FormInput
                                    label={`Adresse de l'adversaire`}
                                    name={getFieldPath("adresse")}
                                    value={currentItem.adresse || ""}
                                    onBlur={() => handleFieldErrorToast(getFieldPath("adresse"))}
                                    onChange={(event) => formik.handleChange(event)}
                                />
                            </>
                            {formik.values.items[index].pieton === 'oui' ? null : (
                                <>
                                    <FormInput
                                        label={`Marque du vehicule de l'adversaire`}
                                        name={getEnginNestedFieldPath("marque")}
                                        value={currentItem.engin.marque || ""}
                                        onBlur={() => handleFieldErrorToast(getEnginNestedFieldPath("marque"))}
                                        onChange={(event) => formik.handleChange(event)}
                                    />
                                    <FormInput
                                        label={`Puissance du vehicule de l'adversaire`}
                                        name={getEnginNestedFieldPath("puissance")}
                                        value={currentItem.engin.puissance || ""}
                                        onBlur={() => handleFieldErrorToast(getEnginNestedFieldPath("puissance"))}
                                        onChange={(event) => formik.handleChange(event)}
                                    />

                                    <FormInput
                                        label={`Numéro de la plaque du véhicule de l'adversaire`}
                                        name={getEnginNestedFieldPath("immatriculation")}
                                        value={currentItem.engin.immatriculation || ""}
                                        onBlur={() => handleFieldErrorToast(getEnginNestedFieldPath("immatriculation"))}
                                        onChange={(event) => formik.handleChange(event)}
                                    />
                                    <FormSelect
                                        label={`Usage du véhicule de l'adversaire`}
                                        name={getEnginNestedFieldPath("usage")}
                                        value={currentItem.engin.usage}
                                        onBlur={() => handleFieldErrorToast(getEnginNestedFieldPath("usage"))}
                                        onChange={(event) => {
                                            console.log(event.target.value); // Logs selected value
                                            formik.setFieldValue(event.target.name, event.target.value);
                                        }}
                                        options={listeUsage}
                                    />

                                    <FormInput
                                        label={`Nombre de personnes transportées`}
                                        name={getEnginNestedFieldPath("nombrePersonneTransportees")}
                                        value={currentItem.engin.nombrePersonneTransportees || ""}
                                        onBlur={() => handleFieldErrorToast(getEnginNestedFieldPath("nombrePersonneTransportees"))}
                                        onChange={(event) => formik.handleChange(event)}
                                    />
                                    <FormRadio
                                        label={`L'adversaire conduisait-il ?`}
                                        name={getFieldPath("conduisait")}
                                        value={currentItem.conduisait || ""}
                                        onBlur={() => handleFieldErrorToast(getFieldPath("conduisait"))}
                                        onChange={(event) => {
                                            formik.setFieldValue(getFieldPath('conduisait'), event)
                                            setWasAdversaireConducteur(event === "oui" ? true : false);
                                            formik.handleChange(event)
                                        }}
                                        options={[
                                            {label: 'Oui', value: 'oui'},
                                            {label: 'Non', value: 'non'},
                                        ]}
                                    />
                                    {formik.values.items[index].conduisait !== 'oui' ? (
                                        <>
                                            <FormInput
                                                label={`Nom du conducteur`}
                                                name={getConducteurNestedFieldPath("nom")}
                                                value={currentItem.conducteur.nom || ""}
                                                onBlur={() => handleFieldErrorToast(getConducteurNestedFieldPath("nom"))}
                                                onChange={(event) => formik.handleChange(event)}
                                            />
                                            <FormInput
                                                label={`Prénoms du conducteur`}
                                                name={getConducteurNestedFieldPath("prenom")}
                                                value={currentItem.conducteur.prenom || ""}
                                                onBlur={() => handleFieldErrorToast(getConducteurNestedFieldPath("prenom"))}
                                                onChange={(event) => formik.handleChange(event)}
                                            />
                                            <FormInput
                                                label={`Numero de téléphone  du conducteur`}
                                                name={getConducteurNestedFieldPath("telephone")}
                                                value={currentItem.conducteur.telephone || ""}
                                                onBlur={() => handleFieldErrorToast(getConducteurNestedFieldPath("telephone"))}
                                                onChange={(event) => formik.handleChange(event)}
                                            />
                                        </>
                                    ) : null}
                                    <>
                                        <FormInput
                                            label={`Numéro du permis du conducteur`}
                                            name={getConducteurNestedFieldPath("numeroPermis")}
                                            value={currentItem.conducteur.numeroPermis || ""}
                                            onBlur={() => handleFieldErrorToast(getConducteurNestedFieldPath("numeroPermis"))}
                                            onChange={(event) => formik.handleChange(event)}
                                        />
                                        <FormSelect
                                            label={`Catégorie du permis du conducteur`}
                                            name={getConducteurNestedFieldPath("categoriePermis")}
                                            value={currentItem.conducteur.categoriePermis || ""}
                                            onBlur={() => handleFieldErrorToast(getEnginNestedFieldPath("categoriePermis"))}
                                            onChange={(event) => {
                                                console.log(event.target.value); // Logs selected value
                                                formik.setFieldValue(event.target.name, event.target.value);
                                            }} options={listeDesCategoriesDePermis}
                                        />
                                        <FormInput
                                            label={`Lieu de délivrance du permis du conducteur`}
                                            name={getConducteurNestedFieldPath("lieuDelivrance")}
                                            value={currentItem.conducteur.lieuDelivrance || ""}
                                            onBlur={() => handleFieldErrorToast(getConducteurNestedFieldPath("lieuDelivrance"))}
                                            onChange={(event) => formik.handleChange(event)}
                                        />
                                        <FormDatePicker
                                            label="Date de délivrance du permis du conducteur"
                                            name={getConducteurNestedFieldPath("dateDelivrance")}
                                            value={currentItem.conducteur.dateDelivrance || null}
                                            maxDate={dayjs(getFrenchDate(), 'DD-MM-YYYY')}
                                            errorState={formik.errors}
                                            onBlur={formik.handleBlur}
                                            onChange={(date, dateString) => {
                                                if (date) {
                                                    formik.setFieldValue(getConducteurNestedFieldPath("dateDelivrance"), date.toISOString());
                                                } else {
                                                    formik.setFieldValue(getConducteurNestedFieldPath("dateDelivrance"), null);
                                                }
                                            }}
                                            picker="date"
                                        />
                                        <FormDatePicker
                                            label="Date d'expiration du permis du conducteur"
                                            name={getConducteurNestedFieldPath("dateExpiration")}
                                            value={currentItem.conducteur.dateExpiration || null}
                                            errorState={formik.errors}
                                            onBlur={formik.handleBlur}
                                            onChange={(date, dateString) => {
                                                if (date) {
                                                    formik.setFieldValue(getConducteurNestedFieldPath("dateExpiration"), date.toISOString());
                                                } else {
                                                    formik.setFieldValue(getConducteurNestedFieldPath("dateExpiration"), null);
                                                }
                                            }}
                                            picker="date"
                                        />

                                        <FormSelect
                                            label={` Qualité du conducteur`}
                                            name={getConducteurNestedFieldPath("qualite")}
                                            value={currentItem.conducteur.qualite || ""}
                                            onBlur={() => handleFieldErrorToast(getConducteurNestedFieldPath("qualite"))}
                                            onChange={(event) => {
                                                console.log(event.target.value); // Logs selected value
                                                formik.setFieldValue(event.target.name, event.target.value);
                                            }} options={listeDesQualitesDuConducteur}
                                        />
                                        <FormInput
                                            label={`Société d'assurance de l'adversaire`}
                                            name={getFieldPath("societeAssurance")}
                                            value={currentItem.societeAssurance || ""}
                                            onBlur={() => handleFieldErrorToast(getFieldPath("societeAssurance"))}
                                            onChange={(event) => formik.handleChange(event)}
                                        />
                                        <FormInput
                                            label={`La voiture l'adversaire venant de`}
                                            name={getFieldPath("provenance")}
                                            value={currentItem.provenance || ""}
                                            onBlur={() => handleFieldErrorToast(getFieldPath("provenance"))}
                                            onChange={(event) => formik.handleChange(event)}
                                        />
                                        <FormInput
                                            label={`La voiture l'adversaire se dirigeant vers`}
                                            name={getFieldPath("destination")}
                                            value={currentItem.destination || ""}
                                            onBlur={() => handleFieldErrorToast(getFieldPath("destination"))}
                                            onChange={(event) => formik.handleChange(event)}
                                        />
                                        <FormInput
                                            label={` À quelle vitesse allait la voiture de l'adversaire?`}
                                            name={getFieldPath("vitesse")}
                                            value={currentItem.vitesse || ""}
                                            onBlur={() => handleFieldErrorToast(getFieldPath("vitesse"))}
                                            onChange={(event) => formik.handleChange(event)}
                                        />
                                    </>

                                </>
                            )
                            }
                            <FormSelect
                                label={`Type de dommages chez l'adversaire`}
                                name={getFieldPath("typeSinistre")}
                                value={currentItem.typeSinistre || ""}
                                onBlur={() => handleFieldErrorToast(getEnginNestedFieldPath("typeSinistre"))}
                                onChange={(event) => {
                                    console.log(event.target.value); // Logs selected value
                                    formik.setFieldValue(event.target.name, event.target.value);
                                }} options={listeDesTypesDeDommagesPossibles}
                            />
                        </>
                    )
                }

            </div>
        </>
    )
        ;
};

export default SingleItemForm;


