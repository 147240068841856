import { ExclamationCircleFilled } from '@ant-design/icons'
import { DatePicker, Modal, Select, Skeleton } from 'antd'
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { useReactToPrint } from 'react-to-print'
import { toast } from 'react-toastify'
import pdfImage from "../../../assets/img/pdf.png"
import Footer from '../../../components/Footer'
import NavBar from '../../../components/NavBar'
import SideBar from '../../../components/SideBar'
import axiosInstance from '../../../variables/api/axiosInstance'
import { API_URL, LOGGED_USER, TOKEN } from '../../../variables/constants'
import { frenchMonth } from '../../../variables/functions'
import CompteDeCessionParCategorieParCompagnie from './documents/CompteDeCessionParCategorieParCompagnie'
const { confirm } = Modal;

const Cession = () => {
    const [categories, setCategories] = useState([]);
    const [selectedCategorie, setSelectedCategorie] = useState("");
    const [reglements, setReglements] = useState([]);
    const [reglementsByMonth, setReglementsByMonth] = useState([]);
    const [decaissements, setDecaissements] = useState([]);
    const [compagnies, setCompagnies] = useState([]);
    const [selectedCompagnie, setSelectedCompagnie] = useState("");
    const [files, setFiles] = useState([]);
    const [selectedFile, setSelectedFile] = useState("");
    const [loading, setLoading] = useState(true);
    const [date, setDate] = useState();
    const [complete, SetComplete] = useState(false);
    const [cessionExistante, setCessionExistante] = useState("");
    const [addCessionLoading, setAddCessionLoading] = useState(false);

    const printCessionByCompanyAndMonthAndCategoryReference = useRef();

    useEffect(() => {
        if (LOGGED_USER.profil.organisme.typeOrganisme !== "POOL") {
            setSelectedCompagnie(LOGGED_USER.profil.organisme);
            setupDecaissements(LOGGED_USER.profil.organisme.code);
            setupReglements(LOGGED_USER.profil.organisme.code);
            setupReglementsOnMonth(LOGGED_USER.profil.organisme.code, new Date().toISOString().split("T")[0]);
        }

        axios
            .get(`${API_URL}/api/categorie`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                setCategories(res.data.filter(e => !e.codeCategorie.startsWith("VT")));
            })
            .catch((err) => {
                console.log(err);
            });


        axios
            .get(`${API_URL}/api/organisme/assurance`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                setCompagnies(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const setupDecaissements = (codeCompagnie) => {
        axios
            .get(`${API_URL}/api/decaissement/compagnie/${codeCompagnie}`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                setDecaissements(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const setupReglements = (codeCompagnie) => {
        axios
            .get(`${API_URL}/api/contrat/organisme/code/${codeCompagnie}`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                let coms = [];
                res.data.forEach((contrat) => {
                    if (contrat.reglements && contrat.reglements.length) {
                        contrat.reglements.forEach((reg) => {
                            coms.push({
                                key: reg.codeId,
                                codeId: reg.codeId,
                                id: reg.codeId,
                                categorie: contrat?.engins[0]?.categorie?.codeCategorie,
                                numeroPolice: contrat.numeroPoliceP,
                                souscripteur: contrat?.souscripteur?.prenom
                                    ? `${contrat?.souscripteur?.prenom} ${contrat?.souscripteur?.nom}`
                                    : `${contrat?.souscripteur?.nom}`,
                                organisme: contrat.organisme.raisonSociale,
                                regle: reg.dateReglement
                                    ? "REGLE"
                                    : "NON REGLE",
                                data: reg,
                                contrat: contrat
                            });
                        });
                    }
                });
                setReglements(coms);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const setupReglementsOnMonth = (codeCompagnie, periode) => {
        axios
            .get(`${API_URL}/api/contrat/organisme/code/${codeCompagnie}/${periode}`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                let coms = [];
                res.data.forEach((contrat) => {
                    if (contrat.reglements && contrat.reglements.length) {
                        contrat.reglements.forEach((reg) => {
                            coms.push({
                                key: reg.codeId,
                                codeId: reg.codeId,
                                id: reg.codeId,
                                categorie: contrat?.engins[0]?.categorie?.codeCategorie,
                                numeroPolice: contrat.numeroPoliceP,
                                souscripteur: contrat?.souscripteur?.prenom
                                    ? `${contrat?.souscripteur?.prenom} ${contrat?.souscripteur?.nom}`
                                    : `${contrat?.souscripteur?.nom}`,
                                organisme: contrat.organisme.raisonSociale,
                                regle: reg.dateReglement
                                    ? "REGLE"
                                    : "NON REGLE",
                                data: reg,
                                contrat: contrat
                            });
                        });
                    }
                });
                setReglementsByMonth(coms);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const dateFiltering = (_, dateString) => {
        setDate(dateString);
        setupReglementsOnMonth(selectedCategorie.code, `${dateString}-01`);
    }

    const categorieFiltering = (_, categ) => {
        setSelectedCategorie(categ.data);
        setupReglementsOnMonth(selectedCompagnie.code, `${date}-01`);
    }

    const compagnieFiltering = (code, comp) => {
        setupDecaissements(code);
        setupReglementsOnMonth(code, `${date}-01`);
        setSelectedCompagnie(comp.data);
    }

    const confirmValidation = () => {
        confirm({
            title: "Etes-vous sûr de vouloir valider la cession?",
            icon: <ExclamationCircleFilled />,
            content: "Toutes vos actions sont historisées",
            okText: "Oui",
            okType: "danger",
            cancelText: "Annuler",
            onOk() {
                validerCession();
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    }

    const validerCession = () => {
        setAddCessionLoading(true);
        const cession = {
            utilisateur: LOGGED_USER,
            moisRapport: `${date}-01`,
            categorie: selectedCategorie,
        }

        axiosInstance.post("/api/cession", cession).then(res => {
            console.log(res.data);
            toast("Cession validée avec succès!");
            setAddCessionLoading(false);
        }).catch(err => {
            console.log(err);
            toast("Erreur lors de la cession");
            setAddCessionLoading(false);
        });
    }

    const checkCession = (mois, compagnie, categorie) => {
        axiosInstance.get(`/api/cession/checking-cession/${mois}/${compagnie}/${categorie}`)
            .then(res => {
                console.log("Cession existant:", res.data);
                setCessionExistante(res.data);
            }).catch(err => {
                console.log(err);
            });
    }

    const handlePrintCessionByCompanyAndMonthAndCategory = useReactToPrint({
        content: () => printCessionByCompanyAndMonthAndCategoryReference.current,
    });

    return (
        <div className="layout-wrapper layout-content-navbar  ">
            <div className="layout-container">
                <SideBar />

                {/* <!-- Layout container --> */}
                <div className="layout-page">
                    <NavBar />

                    {/* <!-- Content wrapper --> */}
                    <div className="content-wrapper">
                        {/* <!-- Content --> */}
                        <div className="container-xxl flex-grow-1 container-p-y">
                            {/* Your main content here */}
                            <div class="row g-6 mb-6">
                                <div class="col-sm-6 col-xl-3">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="d-flex align-items-start justify-content-between">
                                                <div class="content-left">
                                                    <span class="text-heading">Filtrer suivant la Période</span>
                                                    <div class="d-flex align-items-center my-1">
                                                        <DatePicker placeholder="Sélectionner le mois" onChange={dateFiltering} picker="month" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-xl-3">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="align-items-start justify-content-between">
                                                <div class="content">
                                                    <span class="text-heading">Filtrer suivant la Catégorie</span>
                                                    <div class="align-items-center my-1">
                                                        <Select
                                                            placeholder="Sélectionner la catégorie......"
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            onChange={categorieFiltering}
                                                            options={categories.map((cat) => ({
                                                                value: cat.codeCategorie,
                                                                label: cat.nom,
                                                                data: cat
                                                            }))}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {LOGGED_USER?.profil?.organisme?.typeOrganisme === "POOL" ? <div class="col-sm-6 col-xl-3">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="align-items-start justify-content-between">
                                                <div class="content">
                                                    <span class="text-heading">Filtrer suivant la Compagnie</span>
                                                    <div class="align-items-center my-1">
                                                        <Select
                                                            placeholder="Sélectionner la compagnie"
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            onChange={compagnieFiltering}
                                                            options={compagnies.map((com) => ({
                                                                value: com.code,
                                                                label: com.raisonSociale,
                                                                data: com
                                                            }))}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> : null}
                            </div>

                            <div class="row g-6 mb-6">
                                <div class="card mb-6">
                                    <div class="card-header">
                                        <h5 class="mb-0">Les différents états à générer</h5>
                                        <span class="card-subtitle mt-0">Veuillez sélectionner les différents états à générer.</span>
                                    </div>
                                    <div class="card-body pt-0">
                                        <h6 class="mb-1"></h6>
                                        <div class="mb-4">
                                            <div class="d-flex w-100 action-icons">
                                                <Select
                                                    showSearch
                                                    mode="multiple"
                                                    value={files}
                                                    placeholder="Sélectionner les états"
                                                    style={{ width: "100%" }}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                                    }
                                                    onSearch={(e) => {
                                                        console.log("Recherche ...", e);
                                                    }}
                                                    onChange={(e) => {
                                                        if (e.find(el => el === "Compte de cession par catégorie")) {
                                                            if (!selectedCategorie) {
                                                                toast("Veuillez sélectionner une catégorie!");
                                                                SetComplete(false);
                                                            }
                                                            if (!selectedCompagnie) {
                                                                toast("Veuillez sélectionner une compagnie!");
                                                                SetComplete(false);
                                                            }
                                                            if (!date) {
                                                                toast("Veuillez préciser une période!");
                                                                SetComplete(false);
                                                            }
                                                            if (date && selectedCategorie && selectedCompagnie) {
                                                                SetComplete(true);
                                                                setFiles(e);
                                                            }
                                                        }
                                                    }}
                                                    options={[{
                                                        value: "Compte de cession par catégorie",
                                                        label: "Compte de cession par catégorie",
                                                    }, {
                                                        value: "Etat des primes reportées en cours",
                                                        label: "Etat des primes reportées en cours",
                                                    }]}
                                                />
                                            </div>
                                        </div>
                                        {/* <p class="mb-0">Two-factor authentication adds an additional layer of security to your account by requiring more than just a password to log in.
                                            <a href="javascript:void(0);" class="text-primary">Learn more.</a>
                                        </p> */}
                                    </div>
                                </div>

                            </div>

                            <div class="row g-6 mb-6">
                                <div class="card mb-6">
                                    <div class="card-header">
                                        {/* <h5 class="mb-0">-</h5>
                                        <span class="card-subtitle mt-0">-</span> */}
                                    </div>
                                    <div class="card-body pt-0">
                                        <h6 class="mb-1"></h6>
                                        <div class="mb-4">
                                            <div class="d-flex w-100 action-icons">
                                                {files ? files.map((file, id) => (
                                                    <div class="d-flex align-items-center mb-2" key={id} onClick={() => {
                                                        setupReglementsOnMonth(selectedCompagnie.code, `${date}-01`);
                                                        checkCession(`${date}-01`, selectedCompagnie.code, selectedCategorie.codeCategorie);
                                                        setSelectedFile(file)
                                                        setLoading(false);
                                                    }}>
                                                        <div class="badge bg-lighter rounded d-flex align-items-center">
                                                            <img src={pdfImage} alt="img" width="15" class="me-2" />
                                                            <span class="h6 mb-0 text-body">{file}</span>
                                                        </div>
                                                    </div>
                                                )) : "Aucun état sélectionné à afficher!"}
                                            </div>
                                        </div>
                                        {/* <p class="mb-0">Two-factor authentication adds an additional layer of security to your account by requiring more than just a password to log in.
                                            <a href="javascript:void(0);" class="text-primary">Learn more.</a>
                                        </p> */}
                                    </div>
                                </div>

                            </div>
                            <div class="row g-6 mb-6">
                                <div class="card mb-6">
                                    <div class="card-header">
                                        <h5 class="mb-0">{selectedFile ? selectedFile : "Etat ..."} </h5>
                                        <span class="card-subtitle mt-0">...</span>
                                    </div>
                                    <div class="card-body pt-0">
                                        <h6 class="mb-1"></h6>
                                        <div class="mb-4">
                                            <div class="d-flex w-100 action-icons">
                                                <Skeleton loading={loading}>
                                                    <div>
                                                        {selectedFile === "Compte de cession par catégorie" ?
                                                            <div>
                                                                <CompteDeCessionParCategorieParCompagnie
                                                                    souscriptions={reglementsByMonth}
                                                                    sinistres={decaissements}
                                                                    compagnie={selectedCompagnie}
                                                                    categorie={selectedCategorie}
                                                                    periode={date}
                                                                    ref={printCessionByCompanyAndMonthAndCategoryReference}
                                                                />
                                                                <br />
                                                                <button
                                                                    type="button"
                                                                    onClick={() => {
                                                                        !cessionExistante && confirmValidation();
                                                                    }}
                                                                    disabled={addCessionLoading}
                                                                    class={cessionExistante ? "btn btn-label-success" : "btn btn-label-linkedin"}
                                                                >
                                                                    {addCessionLoading && (
                                                                        <span
                                                                            class="spinner-grow me-1"
                                                                            role="status"
                                                                            aria-hidden="true"
                                                                        ></span>
                                                                    )}
                                                                    <i class="tf-icons bx bxs-badge-check bx-18px me-2"></i> <span className="align-middle d-sm-inline-block d-none me-sm-2">
                                                                        {addCessionLoading
                                                                            ? `Validation...`
                                                                            : cessionExistante ? `Cession ${selectedCategorie?.codeCategorie === "M" ? "MOTO" : selectedCategorie?.codeCategorie} de ${frenchMonth(date.split("-")[1])} ${date.split("-")[0]} validée` : `Valider la cession  ${selectedCategorie?.codeCategorie === "M" ? "MOTO" : selectedCategorie?.codeCategorie} de ${frenchMonth(date.split("-")[1])} ${date.split("-")[0]}`}
                                                                    </span> </button>
                                                            </div> : null
                                                        }
                                                        <br />
                                                        <button
                                                            type="button"
                                                            class="btn btn-label-pinterest"
                                                            onClick={handlePrintCessionByCompanyAndMonthAndCategory}
                                                        ><i class="tf-icons bx bxs-file-pdf bx-18px me-2"></i>Télécharger</button>
                                                    </div>
                                                </Skeleton>
                                            </div>
                                        </div>
                                        {/* <p class="mb-0">Two-factor authentication adds an additional layer of security to your account by requiring more than just a password to log in.
                                            <a href="javascript:void(0);" class="text-primary">Learn more.</a>
                                        </p> */}
                                    </div>
                                </div>

                            </div>

                        </div>
                        {/* <!-- / Content --> */}

                        {/* <!-- Footer --> */}
                        <Footer />
                        {/* <!-- / Footer --> */}

                        <div className="content-backdrop fade"></div>
                    </div>
                    {/* <!-- Content wrapper --> */}
                </div>
                {/* <!-- / Layout page --> */}
            </div>

            {/* <!-- Overlay --> */}
            <div className="layout-overlay layout-menu-toggle"></div>

            {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
            <div className="drag-target"></div>
        </div>
    )
}

export default Cession