import { PlusOutlined, EditOutlined, DeleteOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Modal, Table } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Footer from "../../../components/Footer";
import NavBar from "../../../components/NavBar";
import SideBar from "../../../components/SideBar";
import { API_URL, TOKEN } from "../../../variables/constants";
import AddTaxe from "./AddTaxe";
import { toast } from "react-toastify";
import confirm from "antd/es/modal/confirm";

const Taxes = () => {
  const [taxes, setTaxes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedTaxe, setSelectedTaxe] = useState(null);

  const handleShow = (edit, taxe = null) => {
    setEditMode(edit);
    setSelectedTaxe(taxe);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleEdit = (tax) => {
    setEditMode(true);
    setSelectedTaxe(tax);
    setShowModal(true);
  };

  const showDeleteConfirm = (codeId) => {
    confirm({
      title: "Êtes-vous sûr de vouloir supprimer cette taxe?",
      icon: <ExclamationCircleFilled />,
      content: "Cette action est irréversible.",
      okText: "Oui",
      okType: "danger",
      cancelText: "Annuler",
      onOk() {
        deleteTaxe(codeId);
      },
    });
  };

 const deleteTaxe = (codeId) => {
  axios
    .delete(`${API_URL}/api/taxe/${codeId}`, {
      headers: { Authorization: `Bearer ${TOKEN}` },
    })
    .then(() => {
      setTaxes((prevTaxes) => {
        const updatedTaxes = prevTaxes.filter((t) => t.codeId !== codeId);
        console.log("Updated taxes list:", updatedTaxes);
        return updatedTaxes;
      });
      toast.success("Taxe supprimée avec succès");
    })
    .catch((err) => {
      console.error("Erreur lors de la suppression de la taxe :", err);
      toast.error("Erreur lors de la suppression de la taxe");
    });
};


  const loadTaxes = () => {
  axios
    .get(`${API_URL}/api/taxe`, {
      headers: { Authorization: `Bearer ${TOKEN}`, "Content-Type": "application/json" },
    })
    .then((res) => {
      console.log(res.data);
      setTaxes(res.data.filter(t => t.activeData)); // Filtre supplémentaire si nécessaire
    })
    .catch((err) => {
      console.error("Erreur lors du chargement des taxes :", err);
      toast.error("Erreur lors du chargement des taxes");
    });
};

  useEffect(() => {
    loadTaxes(); 
  }, []);

  const columns = [
    {
      title: "Nom",
      dataIndex: "libelleTaxe",
      key: "libelleTaxe",
    },
    {
      title: "Valeur",
      dataIndex: "valeur",
      key: "valeur",
      render: (valeur) => `${valeur} %`,
    },
    {
      title: "Garanties",
      dataIndex: "garanties",
      key: "garanties",
      render: (garanties) => (
        <ul>
          {garanties.map((gar) => (
            <li key={gar.id}>{gar.nom}</li>
          ))}
        </ul>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <>
          <Button
            type="primary"
            icon={<EditOutlined />}
            style={{ marginRight: 8 }}
            onClick={() => handleEdit(record)}
          >
            Modifier
          </Button>
          <Button
            type="danger"
            icon={<DeleteOutlined />}
            onClick={() => showDeleteConfirm(record.codeId)}
          >
            Supprimer
          </Button>
        </>
      ),
    },
  ];

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <SideBar currentMenu={"TARIFICATION"} />

        <div className="layout-page">
          <NavBar />

          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              <Button
                type="primary"
                icon={<PlusOutlined />}
                style={{ marginBottom: 16 }}
                onClick={handleShow}
              >
                Ajouter une nouvelle taxe
              </Button>
              <div className="card">
                <h5 className="card-header">Paramétrage de taxes</h5>
                <Table
                  columns={columns}
                  dataSource={taxes}
                  rowKey="codeId"
                  pagination={{ pageSize: 5 }}
                />
              </div>

              <Modal
                open={showModal}
                footer={null}
                onCancel={handleClose}
              >
                <AddTaxe
                  show={showModal}
                  handleClose={handleClose}
                  editMode={editMode}
                  codeId={selectedTaxe?.codeId}
                  selectedTaxe={selectedTaxe}
                  onSaveSuccess={() => {
                    loadTaxes();
                    toast.success(editMode ? "Taxe modifiée avec succès" : "Taxe ajoutée avec succès");
                    setShowModal(false);
                  }}
                  onSaveError={() => {
                    toast.error("Erreur lors de la sauvegarde de la taxe");
                  }}
                />
              </Modal>
            </div>

            <Footer />
            <div className="content-backdrop fade"></div>
          </div>
        </div>
      </div>
      <div className="layout-overlay layout-menu-toggle"></div>
      <div className="drag-target"></div>
    </div>
  );
};

export default Taxes;
