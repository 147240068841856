import {
    Alert,
    Avatar,
    Button as AntdButton,
    Button,
    Card,
    Descriptions,
    Divider,
    Input,
    List,
    Modal,
    Select,
    Space,
    Spin,
    Tabs,
    Tag
} from "antd";
import {useState} from "react";
import {CardBody} from "react-bootstrap";
import {CreditCardOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import {frenchDate} from "../../../../variables/functions";
import Encaissement from "../../components/Encaissements";
import {useNavigate} from "react-router-dom";
import ReglementRecoursModal from "../../components/modals/ReglementRecoursModal";


const Recours = ({
                     modalRecoursReevaluation,
                     handleChange,
                     loadingSinistre,
                     items,
                     name,
                     inputRef,
                     setValueSelected,
                     setSelectedGaranties,
                     modalEncaissement,
                     sinistre,
                     showFicheDemandeReglement,
                     setProvisionGarantie,
                     addProvisionFormFields,
                     loadingSuspension,
                     setModalEncaissement,
                     setOpenEncaissement,
                     provisionGaranties,
                     valueSelected,
                     handleSuspensionDossier,
                     selectedGaranties,
                     setMotifRevisionState,
                     garanties,
                     setGarantieState,
                     modalSuspension,
                     encaissements,
                     addItem,
                     onNameChange,
                     setModalCloture,
                     initEvaluation,
                     loadingCloture,
                     showQuittance,
                     evaluation,
                     setModalSuspension,
                     removeProvisionFormFields,
                     handleClotureDossier,
                     showOrdreDepense,
                     montant,
                     recoursEvaluations,
                     setEvaluation,
                     editMode,
                     setDecaissement,
                     modalCloture,
                     setModalRecoursReevaluation,
                     setIsRecours,
                     editEvaluation,
                     handleSubmit,
                     setModalAssignCheque,
                 }) => {
    const [tabId, setTabId] = useState("1");

    const onTabsChange = (key) => {
        setTabId(key);
    };
    const navigate = useNavigate()
    const calculateEncaissementAmountTotal = () => {
        const taux = 100 - parseFloat(sinistre?.tauxResponsabilite)
        const amount = sinistre?.evaluation * taux / 100
        return amount
    }

    const calculatePayedAmounts = () => {
        const recoursDejaEncaisse = sinistre?.decaissements?.filter((encaissement) => encaissement.encaissement === true)
        const recoursDejaEncaisseMontant = recoursDejaEncaisse?.reduce((acc, curr) => acc + curr.montantGlobal, 0)

        return recoursDejaEncaisseMontant
    }
    calculatePayedAmounts()
    console.log('gdoi', sinistre?.decaissements)
    const [reglementModalOpen, setReglementModalOpen] = useState(false)
    const reglementControl = {
        open: reglementModalOpen,
        setOpen: setReglementModalOpen,
    }

    return (
        <>
            <Card>
                <Spin spinning={loadingSinistre}>
                    <Tabs
                        onChange={onTabsChange}
                        type="card"
                        items={new Array(3).fill(null).map((_, i) => {
                            const id = String(i + 1);
                            const lb =
                                i === 0
                                    ? `Détails`
                                    : i === 1
                                        ? `Évaluations`
                                        : `Encaissements`;
                            const cd =
                                i === 0 ? (
                                    <>
                                        <CardBody>
                                            <div className={'container'} style={{justifyContent: 'center'}}>
                                                <div className={'grid-item'}>
                                                    <Descriptions
                                                        title={`Sinistre N° - ${
                                                            sinistre?.numSinistre
                                                                ? sinistre?.numSinistre
                                                                : "-"
                                                        }`}
                                                    >
                                                        <Descriptions.Item label="Numéro de Police">
                                                            {sinistre?.contrat?.souscripteur
                                                                ?.numeroPoliceM
                                                                ? `${sinistre?.contrat?.numeroPoliceM}`
                                                                : sinistre?.contrat?.numeroPoliceP
                                                                    ? `${sinistre?.contrat?.numeroPoliceP}`
                                                                    : "-"}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label="Assuré">
                                                            {
                                                                sinistre?.contrat?.souscripteur?.prenom || sinistre?.contrat?.souscripteur?.nom
                                                                    ? `${sinistre?.contrat?.souscripteur?.prenom || ''} ${sinistre?.contrat?.souscripteur?.nom || ''}`.trim()
                                                                    : '-'
                                                            }

                                                        </Descriptions.Item>
                                                        <Descriptions.Item
                                                            label="Immatriculation engin">
                                                            {sinistre?.engin?.immatriculation}
                                                        </Descriptions.Item>
                                                        <Descriptions.Item
                                                            label="Provision actuelle">
                                                            {sinistre?.evaluation
                                                                ? new Intl.NumberFormat(
                                                                    "en-DE"
                                                                ).format(sinistre?.evaluation)
                                                                : "0"}{" "}
                                                            FCFA
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label="Sinistre à payer">
                                                            {sinistre?.sap
                                                                ? new Intl.NumberFormat(
                                                                    "en-DE"
                                                                ).format(
                                                                    calculateEncaissementAmountTotal()
                                                                )
                                                                : "0"}{" "}
                                                            FCFA
                                                        </Descriptions.Item>
                                                        <Descriptions.Item label="Montant réglé">
                                                            {sinistre?.sap
                                                                ? new Intl.NumberFormat(
                                                                    "en-DE"
                                                                ).format(calculatePayedAmounts())
                                                                : "0"}{" "}
                                                            FCFA
                                                        </Descriptions.Item>
                                                    </Descriptions>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </>
                                ) : i === 1 ? (
                                    <CardBody>
                                        <div className={'container'} style={{justifyContent: 'center'}}>
                                            <div className={'grid-item'}>
                                                {recoursEvaluations.length ? (
                                                    <List
                                                        itemLayout="horizontal"
                                                        dataSource={recoursEvaluations}
                                                        renderItem={(item) => (
                                                            <List.Item
                                                                actions={[
                                                                    <AntdButton
                                                                        type="primary"
                                                                        icon={<EditOutlined/>}
                                                                        onClick={() =>
                                                                            editEvaluation(item)
                                                                        }
                                                                        key={item.evaluationId}
                                                                    ></AntdButton>,
                                                                ]}
                                                            >
                                                                <List.Item.Meta
                                                                    avatar={
                                                                        <Avatar
                                                                            src={`https://ui-avatars.com/api/?name=${
                                                                                item?.motifRevision
                                                                            }`}
                                                                        />
                                                                    }
                                                                    title={
                                                                        <a href="/#">
                                                                            {item?.motifRevision
                                                                                ? item?.motifRevision
                                                                                : ""}
                                                                        </a>
                                                                    }
                                                                    description={
                                                                        <>
                                                                            <p>
                                                                                Ancienne
                                                                                provision:{" "}
                                                                                <b>
                                                                                    {`${new Intl.NumberFormat(
                                                                                        "en-DE"
                                                                                    ).format(
                                                                                        Math.trunc(
                                                                                            item?.ancienneProvision
                                                                                        )
                                                                                    )}`}{" "}
                                                                                    FCFA
                                                                                </b>
                                                                            </p>
                                                                            <p>
                                                                                Nouvelle
                                                                                provision:{" "}
                                                                                <b>
                                                                                    {`${new Intl.NumberFormat(
                                                                                        "en-DE"
                                                                                    ).format(
                                                                                        Math.trunc(
                                                                                            item?.nouvelleProvision
                                                                                        )
                                                                                    )}`}{" "}
                                                                                    FCFA
                                                                                </b>
                                                                            </p>
                                                                            <p>
                                                                                Reévaluée le:{" "}
                                                                                <b>
                                                                                    {frenchDate(
                                                                                        item?.createdAt
                                                                                    )}
                                                                                </b>
                                                                            </p>
                                                                        </>
                                                                    }
                                                                />
                                                            </List.Item>
                                                        )}
                                                    />
                                                ) : (
                                                    "Aucune reévaluation effectuée sur ce dossier sinistre..."
                                                )}
                                            </div>
                                            <br/>
                                            <br/>
                                            <Button
                                                onClick={() => {
                                                    navigate('/evaluation-sinistre', {
                                                        state: {
                                                            recours: true,
                                                            iz: sinistre?.codeId
                                                        }
                                                    })
                                                    setModalRecoursReevaluation(true)
                                                }
                                                }
                                            >
                                                RÉEVALUATION
                                            </Button>
                                        </div>
                                    </CardBody>
                                ) : i === 2 ? (
                                    <CardBody>
                                        <div className={'container'} style={{justifyContent: 'center'}}>
                                            <div className={'grid-item'}>
                                                {encaissements.length ? (
                                                    <List
                                                        itemLayout="horizontal"
                                                        dataSource={encaissements}
                                                        renderItem={(item) => (
                                                            <List.Item
                                                                actions={[
                                                                    item.numeroCheque ? (
                                                                        <AntdButton
                                                                            type="primary"
                                                                            onClick={() =>
                                                                                showQuittance(item)
                                                                            }
                                                                            key={item.evaluationId}
                                                                        >
                                                                            Q
                                                                        </AntdButton>
                                                                    ) : (
                                                                        <Button
                                                                            justIcon
                                                                            round
                                                                            simple
                                                                            key={item.decaissementId}
                                                                            onClick={() => {
                                                                                setDecaissement(item);
                                                                                setModalAssignCheque(true);
                                                                                console.log("Item", item);
                                                                            }}
                                                                            color="success"
                                                                            className="like"
                                                                        >
                                                                            <CreditCardOutlined/>
                                                                        </Button>
                                                                    ),
                                                                    <AntdButton
                                                                        type="primary"
                                                                        onClick={() =>
                                                                            showOrdreDepense(item)
                                                                        }
                                                                        key={item.evaluationId}
                                                                    >
                                                                        OD
                                                                    </AntdButton>,
                                                                    <AntdButton
                                                                        type="primary"
                                                                        onClick={() =>
                                                                            showFicheDemandeReglement(item)
                                                                        }
                                                                        key={item.evaluationId}
                                                                    >
                                                                        FDR
                                                                    </AntdButton>,
                                                                ]}
                                                            >
                                                                <List.Item.Meta
                                                                    avatar={
                                                                        <Avatar
                                                                            src={`https://ui-avatars.com/api/?name=EN`}
                                                                        />
                                                                    }
                                                                    title={
                                                                        <a href="/#">Encaissement</a>
                                                                    }
                                                                    description={
                                                                        <>
                                                                            <p>
                                                                                Montant TTC :{" "}
                                                                                {`${new Intl.NumberFormat(
                                                                                    "en-DE"
                                                                                ).format(
                                                                                    Math.trunc(
                                                                                        item?.montantGlobal
                                                                                    )
                                                                                )}`}{" "}
                                                                                FCFA
                                                                            </p>
                                                                            <p>
                                                                                Montant retenu:{" "}
                                                                                {item?.montantPaye
                                                                                    ? `${new Intl.NumberFormat(
                                                                                        "en-DE"
                                                                                    ).format(
                                                                                        Math.trunc(
                                                                                            item?.montantGlobal -
                                                                                            item?.montantPaye
                                                                                        )
                                                                                    )}`
                                                                                    : "0"}{" "}
                                                                                FCFA
                                                                            </p>
                                                                            <p>
                                                                                Date
                                                                                d&apos;encaissement:{" "}
                                                                                {/* {frenchDateOnly(
                                                                                    item?.dateReglement
                                                                                )}*/}
                                                                            </p>
                                                                        </>
                                                                    }
                                                                />
                                                            </List.Item>
                                                        )}
                                                    />
                                                ) : (
                                                    <div>
                                                        <Alert
                                                            message="Aucun encaissement effectué sur ce dossier sinistre..."
                                                            type="info" showIcon/>


                                                    </div>
                                                )}
                                            </div>
                                            <br/>
                                            <br/>
                                            <Button
                                                onClick={() => {
                                                    setReglementModalOpen(true);
                                                }}
                                            >
                                                ENCAISSEMENT
                                            </Button>
                                        </div>
                                        <ReglementRecoursModal sinistre={sinistre} modalControl={reglementControl}/>
                                    </CardBody>
                                ) : null;
                            return {
                                label: lb,
                                key: id,
                                children: cd,
                            };
                        })}
                        activeKey={tabId}
                    />
                </Spin>
                <Modal
                    title={
                        editMode
                            ? "MISE À JOUR RÉEVALUATION - RECOURS"
                            : "REÉVALUATION - RECOURS"
                    }
                    centered
                    open={modalRecoursReevaluation}
                    onOk={(e) => {
                        setIsRecours(true);
                        handleSubmit(e, true);
                    }}
                    onCancel={() => {
                        setEvaluation(initEvaluation);
                        setModalRecoursReevaluation(false);
                    }}
                >
                    <Card>
                        <CardBody>
                            <div className={'container'} style={{justifyContent: 'center'}}>
                                <div className={'grid-item'}>
                                    <div>
                                        <b>Ancienne provision :</b>{" "}
                                        <Tag color="blue">
                                            <b>
                                                {sinistre?.evaluation
                                                    ? new Intl.NumberFormat("en-DE").format(
                                                        sinistre?.evaluation
                                                    )
                                                    : "0"}{" "}
                                                FCFA
                                            </b>
                                        </Tag>
                                    </div>
                                    <br/>

                                    <div className={'container'} style={{justifyContent: 'center'}}>
                                        <div className={'grid-item'}>
                                            <div className={'form-control'}
                                            >
                                                <Select
                                                    mode="multiple"
                                                    className={'select2'}
                                                    /*MenuProps={{
                                                        className: selectClasses.selectMenu,
                                                    }}*/
                                                    /* classes={{
                                                         select: selectClasses.select,
                                                     }}*/
                                                    value={valueSelected}
                                                    onChange={(value) => {
                                                        let vs = valueSelected;
                                                        if (value.length > vs.length) {
                                                            addProvisionFormFields();
                                                        } else {
                                                            let outGarantie = value.filter(
                                                                (v) => !garanties.includes(v)
                                                            );
                                                            // console.log(outGarantie);

                                                            outGarantie &&
                                                            removeProvisionFormFields(
                                                                outGarantie[0]
                                                            );
                                                        }
                                                        const gs = [];
                                                        value.forEach((v) => {
                                                            let g = garanties.find(
                                                                (gar) => gar.value === v
                                                            );
                                                            g && gs.push(g.garantie);
                                                        });
                                                        setValueSelected(value);
                                                        setSelectedGaranties(gs);
                                                        // console.log("Target...", value);
                                                        value.length
                                                            ? setGarantieState("success")
                                                            : setGarantieState("error");
                                                    }}
                                                    options={garanties}
                                                    placeholder="Sélectionnez des garanties"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {selectedGaranties.length ? (
                                        <div className={'container'} style={{justifyContent: 'center'}}>
                                            {selectedGaranties.map((sg, index) => (
                                                <div className={'grid-item'}
                                                     key={index}
                                                >
                                                    <label
                                                        htmlFor="capital"
                                                        style={{
                                                            margin: "30px 0 10px 0",
                                                        }}
                                                    >
                                                        Provision Garantie {sg.nom} :
                                                    </label>
                                                    <Input
                                                        id="capital"
                                                        name="capital"
                                                        placeholder="Veuillez entrer la valeur de la provision"
                                                        value={
                                                            provisionGaranties[index].provision
                                                        }
                                                        onChange={(event) => {
                                                            let newProvisionFormFields = [
                                                                ...provisionGaranties,
                                                            ];
                                                            newProvisionFormFields[
                                                                index
                                                                ].garantie = sg;
                                                            newProvisionFormFields[
                                                                index
                                                                ].provision = event.target.value;
                                                            setProvisionGarantie(
                                                                newProvisionFormFields
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    ) : null}
                                    <br/>
                                    <div>
                                        <b>Nouvelle provision :</b>{" "}
                                        <Tag color="green">
                                            <b>
                                                {evaluation?.nouvelleProvision
                                                    ? new Intl.NumberFormat("en-DE").format(
                                                        evaluation?.nouvelleProvision
                                                    )
                                                    : "0"}{" "}
                                                FCFA
                                            </b>
                                        </Tag>
                                    </div>

                                    <br/>
                                    <div className={'form-control'}
                                    >
                                        <Select
                                            id="motifRevision"
                                            name="motifRevision"
                                            placeholder="Motif de la révision"
                                            dropdownRender={(menu) => (
                                                <>
                                                    {menu}
                                                    <Divider
                                                        style={{
                                                            margin: "8px 0",
                                                        }}
                                                    />
                                                    <Space
                                                        style={{
                                                            padding: "0 8px 4px",
                                                        }}
                                                    >
                                                        <Input
                                                            placeholder="Ajouter un motif"
                                                            ref={inputRef}
                                                            value={name}
                                                            onChange={onNameChange}
                                                        />
                                                        <Button
                                                            type="text"
                                                            icon={<PlusOutlined/>}
                                                            onClick={addItem}
                                                        >
                                                            Ajouter un motif
                                                        </Button>
                                                    </Space>
                                                </>
                                            )}
                                            options={items.map((item) => ({
                                                label: item,
                                                value: item,
                                            }))}
                                            onChange={(value) => {
                                                handleChange(value, "motifRevision");
                                                console.log(value);
                                                if (value.trim().length > 3) {
                                                    setMotifRevisionState("success");
                                                } else {
                                                    setMotifRevisionState("error");
                                                }
                                            }}
                                            value={evaluation.motifRevision}
                                        />
                                    </div>
                                    <br/>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Modal>
                <Modal
                    title="Clôture du dossier sinistre"
                    open={modalCloture}
                    onOk={() => handleClotureDossier()}
                    confirmLoading={loadingCloture}
                    onCancel={() => setModalCloture(false)}
                >
                    <p>Voulez-vous vraiment clôturer ce dossier sinistre?</p>
                </Modal>
                <Modal
                    title="Suspension du dossier sinistre"
                    open={modalSuspension}
                    onOk={() => handleSuspensionDossier()}
                    confirmLoading={loadingSuspension}
                    onCancel={() => setModalSuspension(false)}
                >
                    <p>
                        Voulez-vous vraiment classer le dossier {"'"}Sans suite
                        {"'"}?
                    </p>
                </Modal>
                <Encaissement
                    open={modalEncaissement}
                    setOpenEncaissement={setOpenEncaissement}
                />
            </Card>

        </>
    )
}

export default Recours;