import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RequireAuth from "./components/services/RequireAuth";
import RequireRights from "./components/services/RequireRights";
import AddHabilitation from "./pages/accessandrights/AddHabilitation";
import AddProfil from "./pages/accessandrights/AddProfil";
import Habilitations from "./pages/accessandrights/Habilitations";
import Modules from "./pages/accessandrights/Modules";
import Profils from "./pages/accessandrights/Profils";
import Profile from "./pages/account/Profile";
import ProfileNotifications from "./pages/account/ProfileNotifications";
import ProfileSecurity from "./pages/account/ProfileSecurity";
import Assures from "./pages/assures/Assures";
import AfterRegistrationPage from "./pages/auth/AfterRegistrationPage";
import ConfirmOTP from "./pages/auth/ConfirmOTP";
import FirstConnexion from "./pages/auth/FirstConnexion";
import ForgotPassword from "./pages/auth/ForgotPassword";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import ResetPassword from "./pages/auth/ResetPassword";
import VerifyEmail from "./pages/auth/VerifyEmail";
import Encaissements from "./pages/caisse/Encaissements";
import VersementsEnCompagnie from "./pages/caisse/VersementsEnCompagnie";
import Home from "./pages/Home";
import AllMembres from "./pages/membres/AllMembres";
import AddCourtier from "./pages/membres/courtiers/AddCourtier";
import Courtiers from "./pages/membres/courtiers/Courtiers";
import DetailsCourtier from "./pages/membres/courtiers/DetailsCourtier";
import DetailsAgence from "./pages/membres/DetailsAgence";
import DetailsIntermediaire from "./pages/membres/DetailsIntermediaire";
import DetailsMembre from "./pages/membres/DetailsMembre";
import Intermediaires from "./pages/membres/intermediaires/Intermediaires";
import Cession from "./pages/operations/cessions/Cession";
import Retrocession from "./pages/operations/retrocesions/Retrocession";
import Accessoires from "./pages/parametres/accessoires/Accessoires";
import TypesAttestations from "./pages/parametres/attestations/TypesAttestations";
import AutresParametres from "./pages/parametres/AutresParametres";
import Avenants from "./pages/parametres/avenants/Avenants";
import Carosseries from "./pages/parametres/carosseries/Carosseries";
import Categories from "./pages/parametres/categories/Categories";
import TauxCommissions from "./pages/parametres/commissions/TauxCommissions";
import Energies from "./pages/parametres/conversions/Energies";
import Fractionnements from "./pages/parametres/fractionnements/Fractionnements";
import Garanties from "./pages/parametres/Garanties";
import Marques from "./pages/parametres/marques/Marques";
import Banques from "./pages/parametres/other-parameters/Banques";
import CausesSinistres from "./pages/parametres/other-parameters/CausesSinistres";
import Etablissements from "./pages/parametres/other-parameters/Etablissements";
import Exercices from "./pages/parametres/other-parameters/Exercices";
import NatureActe from "./pages/parametres/other-parameters/NatureActe";
import Prejudices from "./pages/parametres/other-parameters/Prejudices";
import Professions from "./pages/parametres/other-parameters/Professions";
import SommeMinList from "./pages/parametres/other-parameters/SommeMini";
import TypeProfils from "./pages/parametres/other-parameters/TypeProfils";
import Villes from "./pages/parametres/other-parameters/Villes";
import ParametresTarification from "./pages/parametres/ParametresTarification";
import DetailsReductions from "./pages/parametres/reductions/DetailsReductions";
import Reductions from "./pages/parametres/reductions/Reductions";
import SousGaranties from "./pages/parametres/SousGaranties";
import Taxes from "./pages/parametres/taxes/Taxes";
import Usages from "./pages/parametres/usages/Usages";
import CommandeAutorise from "./pages/production/attestations/CommandeAutorise";
import CommandeConfirme from "./pages/production/attestations/CommandeConfirme";
import CommandeDelivre from "./pages/production/attestations/CommandeDelivre";
import CommandeReception from "./pages/production/attestations/CommandeReception";
import CommandeRejete from "./pages/production/attestations/CommandeRejete";
import Commandes from "./pages/production/attestations/Commandes";
import CommandeValides from "./pages/production/attestations/CommandeValides";
import DetailsLotAttestations from "./pages/production/attestations/DetailsLotAttestations";
import DetailsLotAttestationsDispatched from "./pages/production/attestations/DetailsLotAttestationsDispatched";
import GestionDesAttestations from "./pages/production/attestations/GestionDesAttestations";
import Bordereaux from "./pages/production/Bordereaux";
import AddContrat from "./pages/production/contrats/AddContrat";
import Contrats from "./pages/production/contrats/Contrats";
import DetailsContrat from "./pages/production/contrats/DetailsContrat";
import PrintedContracts from "./pages/production/contrats/PrintedContracts";
import Production from "./pages/production/Production";
import STACFileConsumption from "./pages/production/STACFileConsumption";
import DeclarationSinistre from "./pages/sinistres/DeclarationSinistre";
import DetailsSinistre from "./pages/sinistres/details/DetailsSinistre";
import EvaluationSinistre from "./pages/sinistres/details/EvaluationSinistre";
import ListSinistres from "./pages/sinistres/ListSinistres";
import ValidationDecaissements from "./pages/sinistres/ValidationDecaissements";
import ValidationEvaluations from "./pages/sinistres/ValidationEvaluations";
import EtatsProductionGlobal from "./pages/stats/production/EtatsProductionGlobal";
import AddUtilisateur from "./pages/utilisateurs/AddUtilisateur";
import DetailsUtilisateur from "./pages/utilisateurs/DetailsUtilisateur";
import Utilisateurs from "./pages/utilisateurs/Utilisateurs";
import NosCommandes from "./pages/production/attestations/NosCommandes";
import CompaniesCommandes from "./pages/production/attestations/CompaniesCommandes";
import { rightsService } from "./services/rightsService";
import AddVTContrat from "./pages/production/contrats/AddVTContrat";

function App() {
  // const ProtectedRoute = () => {
  //   const currentUser = sessionStorage.getItem("user")
  //     ? JSON.parse(sessionStorage.getItem("user"))
  //     : null;
  //   const isAuthenticated = isAuthenticated();
  //   if (!currentUser || !isAuthenticated) {
  //     return <Navigate to="/connexion" />;
  //   } else {
  //     return <Outlet />;
  //   }
  // };
  return (
    <div>
      <BrowserRouter>
        <ToastContainer position="top-center" />
        <Routes>
          <Route element={<RequireAuth />}>
            <Route element={<RequireRights codeHabilitation={"HPE033"} />}>
              <Route path="/ajouter-utilisateur" element={<AddUtilisateur />} />
              <Route
                path="/details-utilisateur/:utilisateurId"
                element={<DetailsUtilisateur />}
              />
              <Route path="/utilisateurs" element={<Utilisateurs />} />
            </Route>
            {/* Etats et statistiques */}
            <Route
              path="/etats/production-globale"
              element={<EtatsProductionGlobal />}
            />
            {/* Fin Etats et statistiques */}
            <Route
              path="/autre-parametres/professions"
              element={<Professions />}
            />
            <Route path="/autre-parametres/villes" element={<Villes />} />
            <Route path="/autre-parametres/banques" element={<Banques />} />

            <Route path="/autre-parametres/exercices" element={<Exercices />} />
            <Route
              path="/autre-parametres/type-profils"
              element={<TypeProfils />}
            />
            <Route
              path="/sous-garanties/:garantieId"
              element={<SousGaranties />}
            />

            <Route
              path="/autre-parametres/cause-sinistres"
              element={<CausesSinistres />}
            />
            <Route
              path="/autre-parametres/prejudices"
              element={<Prejudices />}
            />
            <Route
              path="/autre-parametres/etablisements"
              element={<Etablissements />}
            />
            <Route
              path="/autre-parametres/autre-parametre"
              element={<AutresParametres />}
            />

            <Route
              path="/autre-parametres/nature-acte"
              element={<NatureActe />}
            />
            <Route
              path="/autre-parametres/somme-minimale-a-payer"
              element={<SommeMinList />}
            />
            <Route path="/garanties" element={<Garanties />} />
            <Route
              path="/parametres/types-attestations"
              element={<TypesAttestations />}
            />
            <Route
              path="/parametres/taux-commissions"
              element={<TauxCommissions />}
            />
            <Route path="/parametres/avenants" element={<Avenants />} />
            {/* <Route path="/ajouter-avenant" element={<AddAvenant />} /> */}
            <Route path="/parametres/carosseries" element={<Carosseries />} />
            <Route path="/parametres/taxes" element={<Taxes />} />
            <Route path="/parametres/marques" element={<Marques />} />
            <Route path="/parametres/accessoires" element={<Accessoires />} />
            <Route path="/parametres/conversion" element={<Energies />} />
            {/* <Route path="/ajouter-energie" element={<AddEnergie />} /> */}
            <Route path="/parametres/Usages" element={<Usages />} Route />
            {/* <Route path="/ajouter-energie" element={<AddEnergie />} /> */}

            <Route
              path="/details-reduction/:reductionId"
              element={<DetailsReductions />}
            />
            <Route path="/reductions" element={<Reductions />} />
            <Route path="/categories" element={<Categories />} />
            {/* <Route path="ajouter-categorie" element={<AddCategorie />} /> */}
            <Route
              path="/parametres/fractionnements"
              element={<Fractionnements />}
            />
            {/* <Route path="ajouter-fractionement" element={<AddFractionnement />} /> */}
            <Route path="/tarification" element={<ParametresTarification />} />
            <Route path="/autres-parametres" element={<AutresParametres />} />
            <Route
              path="/details-contrat/:contratId"
              element={<DetailsContrat />}
            />
            <Route
              path="/ajouter-contrat"
              element={
                !rightsService("HCO021") ? <AddVTContrat /> : <AddContrat />
              }
            />
            <Route element={<RequireRights codeHabilitation={"HCO021"} />}>
              <Route
                path="/production/port"
                element={<STACFileConsumption />}
              />
            </Route>
            <Route
              path="/dispatching-lot-attestation/:lotAttestationId"
              element={<DetailsLotAttestationsDispatched />}
            />
            <Route
              path="/mes-attestations"
              element={<DetailsLotAttestations />}
            />
            <Route
              path="/lots-attestations/:codeCompagnie"
              element={<DetailsLotAttestations />}
            />
            <Route
              path="/gestion-des-attestations"
              element={<GestionDesAttestations />}
            />
            <Route path="/commandes" element={<Commandes />} />
            <Route path="/nos-commandes" element={<NosCommandes />} />
            <Route
              path="/compagnies-commandes"
              element={<CompaniesCommandes />}
            />
            <Route
              path="/commandes-Autorisées"
              element={<CommandeAutorise />}
            />
            <Route path="/commandes-Delivrées" element={<CommandeDelivre />} />
            <Route
              path="/commandes-confirmées"
              element={<CommandeConfirme />}
            />
            <Route path="/commande-Rejetées" element={<CommandeRejete />} />
            <Route
              path="/commandes-receptionnées"
              element={<CommandeReception />}
            />
            <Route path="/commandes-validées" element={<CommandeValides />} />

            {/* Opérations */}
            <Route path="/cession" element={<Cession />} />
            <Route path="/retrocession" element={<Retrocession />} />

            <Route path="/production" element={<Production />} />
            <Route path="/assures" element={<Assures />} />
            <Route path="/encaissements" element={<Encaissements />} />
            <Route path={"/liste-des-sinistres"} element={<ListSinistres />} />
            <Route path={"/details-sinistre"} element={<DetailsSinistre />} />
            <Route
              path={"/validation-decaissement"}
              element={<ValidationDecaissements />}
            />
            <Route
              path={"/validation-evaluation"}
              element={<ValidationEvaluations />}
            />
            <Route
              path={"/declaration-sinistre"}
              element={<DeclarationSinistre />}
            />
            <Route
              path={"/evaluation-sinistre"}
              element={<EvaluationSinistre />}
            />
            <Route path="/ajouter-courtier" element={<AddCourtier />} />
            <Route path="/courtiers" element={<Courtiers />} />
            <Route path="/contrats-imprimes" element={<PrintedContracts />} />
            <Route path="/contrats" element={<Contrats />} />
            <Route
              path="/intermediaire/:codeIntermediaire"
              element={<DetailsIntermediaire />}
            />
            <Route path="/courtier/:courtierId" element={<DetailsCourtier />} />
            <Route path="/intermediaires" element={<Intermediaires />} />
            <Route path="/mon-agence" element={<DetailsAgence />} />
            <Route path="/agence/:codeAgence" element={<DetailsAgence />} />
            <Route path="/ma-compagnie" element={<DetailsMembre />} />
            <Route path="/membre/:organismeCode" element={<DetailsMembre />} />
            <Route path="/membres" element={<AllMembres />} />
            {/* <Route path="/ajouter-membres" element={<AddMembres />} /> */}
            {/* <Route path="ajouter-reduction" element={<AddReduction />} /> */}
            <Route path="/profils" element={<Profils />} />
            <Route path="/modifier-profil/:profilId" element={<AddProfil />} />
            <Route path="/ajouter-profil" element={<AddProfil />} />
            {/* <Route path="/add-user" element={<AddUser />} /> */}
            <Route path="/production" element={<Production />} />
            <Route path="/assures" element={<Assures />} />
            <Route path="/encaissements" element={<Encaissements />} />
            <Route
              path="/versements-en-compagnie"
              element={<VersementsEnCompagnie />}
            />
            <Route path="/production" element={<Production />} />
            <Route path="/assures" element={<Assures />} />
            <Route path="/encaissements" element={<Encaissements />} />
            <Route path={"/liste-des-sinistres"} element={<ListSinistres />} />
            <Route path={"/details-sinistre"} element={<DetailsSinistre />} />
            {/* <Route path={'/evaluation-des-sinistres'} element={<EvaluationSinistres />} /> */}
            <Route
              path={"/declaration-sinistre"}
              element={<DeclarationSinistre />}
            />
            <Route
              path={"/evaluation-sinistre"}
              element={<EvaluationSinistre />}
            />
            <Route path="/ajouter-courtier" element={<AddCourtier />} />
            <Route path="/courtiers" element={<Courtiers />} />
            <Route path="/contrats-imprimes" element={<PrintedContracts />} />
            <Route path="/contrats" element={<Contrats />} />
            <Route
              path="/intermediaire/:codeIntermediaire"
              element={<DetailsIntermediaire />}
            />
            <Route path="/courtier/:courtierId" element={<DetailsCourtier />} />
            <Route path="/intermediaires" element={<Intermediaires />} />
            <Route path="/mon-agence" element={<DetailsAgence />} />
            <Route path="/agence/:codeAgence" element={<DetailsAgence />} />
            <Route path="/ma-compagnie" element={<DetailsMembre />} />
            <Route path="/membre/:organismeCode" element={<DetailsMembre />} />
            <Route path="/membres" element={<AllMembres />} />
            {/* <Route path="/ajouter-membres" element={<AddMembres />} /> */}
            {/* <Route path="ajouter-reduction" element={<AddReduction />} /> */}
            <Route path="/profils" element={<Profils />} />
            <Route path="/modifier-profil/:profilId" element={<AddProfil />} />
            <Route path="/ajouter-profil" element={<AddProfil />} />
            {/* <Route path="/add-user" element={<AddUser />} /> */}
            <Route path="/production" element={<Production />} />
            <Route path="/assures" element={<Assures />} />
            <Route path="/encaissements" element={<Encaissements />} />
            <Route
              path="/versements-en-compagnie"
              element={<VersementsEnCompagnie />}
            />

            <Route path="/ajouter-courtier" element={<AddCourtier />} />
            <Route path="/courtiers" element={<Courtiers />} />
            <Route path="/bordereaux" element={<Bordereaux />} />
            <Route path="/ajouter-courtier" element={<AddCourtier />} />
            <Route path="/courtiers" element={<Courtiers />} />
            <Route path="/bordereaux" element={<Bordereaux />} />

            <Route path="/contrats-imprimes" element={<PrintedContracts />} />
            <Route path="/contrats" element={<Contrats />} />
            <Route
              path="/intermediaire/:codeIntermediaire"
              element={<DetailsIntermediaire />}
            />
            <Route path="/courtier/:courtierId" element={<DetailsCourtier />} />
            <Route path="/intermediaires" element={<Intermediaires />} />
            <Route path="/mon-agence" element={<DetailsAgence />} />
            <Route path="/agence/:codeAgence" element={<DetailsAgence />} />
            <Route path="/ma-compagnie" element={<DetailsMembre />} />
            <Route path="/membre/:organismeCode" element={<DetailsMembre />} />
            <Route path="/membres" element={<AllMembres />} />

            <Route path="/profils" element={<Profils />} />
            <Route path="/modifier-profil/:profilId" element={<AddProfil />} />
            <Route path="/ajouter-profil" element={<AddProfil />} />

            <Route
              path="/ajouter-utilisateur-agence/:codeAgence"
              element={<AddUtilisateur />}
            />

            <Route path="/ajouter-habilitation" element={<AddHabilitation />} />
            <Route path="/habilitations" element={<Habilitations />} />
            <Route path="/modules" element={<Modules />} />
            <Route path="/mon-profil" element={<Profile />} />
            <Route path="/mon-profil-securite" element={<ProfileSecurity />} />
            <Route
              path="/mon-profil-notifications"
              element={<ProfileNotifications />}
            />
            <Route path="/confirm-otp" element={<ConfirmOTP />} />
            <Route path="/" index element={<Home />} />
          </Route>
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/verifier-email" element={<VerifyEmail />} />
          <Route path="/nouveau-mot-de-passe" element={<ResetPassword />} />
          <Route path="/mot-de-passe-oublie" element={<ForgotPassword />} />
          <Route path="/first-connexion" element={<FirstConnexion />} />
          <Route path="/connexion" element={<Login />} />
          <Route path="/waiting" element={<AfterRegistrationPage />} />
          <Route path="/inscription" element={<Register />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
