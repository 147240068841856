import {Button, Input, Modal, Select, Typography} from "antd";
import React, {useState} from "react";
import {InboxOutlined, UserAddOutlined, UserOutlined} from "@ant-design/icons";
import {toast} from "react-toastify";
import {saveDecaissement} from "../../../../hooks/useHooks";

const {Option} = Select;
const {Title} = Typography;

const ReglementRecoursModal = ({sinistre, modalControl}) => {
    let victimes = sinistre.victimes || [];
    const experts = sinistre.experts || [];
    const evaluations = sinistre.evaluations || [];
    const [beneficiairesVictimes, setBeneficiairesVictimes] = useState([]);
    const [beneficiairesExperts, setBeneficiairesExperts] = useState([])
    const [sousEvaluationsSelected, setSousEvaluationsSelected] = useState([])
    const [montantReglement, setMontantReglement] = useState(0)
    const [beneficiaire, setBeneficiaire] = useState(null)
    const [isVictimeBeneficiaire, setIsVictimeBeneficiaire] = useState(null)
    const [completeSousEvSelected, setCompleteSousEvSelected] = useState([])
    const [honoraire, setHonoraire] = useState(0)
    const [decaissementLoading, setDecaissementLoading] = useState(false)

    const handleRetrieveBeneficiaire = (value) => {
        if (isVictimeBeneficiaire && value.length > 0) {
            setBeneficiaire(victimes?.filter((v) => v.codeId === value)[0])
        }
        if (isVictimeBeneficiaire === false && value.length > 0) {
            console.log('hey')
            setBeneficiaire(experts?.filter((v) => v.codeId === value)[0])

        }
    }

    const handleBeneficiaireChange = (value) => {
        setBeneficiairesVictimes(value);
        handleRetrieveBeneficiaire(value);
    };
    const handleExpertChange = (value) => {
        setBeneficiairesExperts(value);
        handleRetrieveBeneficiaire(value);
    };

    const handleSousEvChange = (value) => {
        setSousEvaluationsSelected(value);
        const tempSous = sousEvaluations?.filter((v) => sousEvaluationsSelected?.includes(v.codeId))
        setCompleteSousEvSelected(tempSous)
    };

    const hendleBeneficiaireIsVictimeChoice = (value) => {
        setIsVictimeBeneficiaire(value)
    }
    victimes = victimes.filter((victime) => victime.personne)
    const evaluation = evaluations.filter((evaluation) => evaluation.courant)[0]
    const sousEvaluations = evaluation?.sousEvaluations
    const filteredSousEvaluations = sousEvaluations?.filter(sousEval => beneficiairesVictimes?.includes(sousEval?.victime?.codeId));


    console.log(beneficiairesVictimes)
    console.log(sousEvaluationsSelected)
    console.log(completeSousEvSelected)
    const totalMontant = sousEvaluations?.prejudices?.reduce(
        (acc, curr) => acc + (curr.montant || 0), 0
    );

    const [isPartielOrTotal, setIsPartielOrTotal] = useState(false)

    return (<>
        <Modal
            title={'Règlement de sinistre'}
            open={modalControl.open}
            confirmLoading={decaissementLoading}
            style={{
                width: '500px'
            }}
            onCancel={() => {
                modalControl.setOpen(false)
                setIsVictimeBeneficiaire(null)
                setBeneficiaire(null)
                setIsPartielOrTotal(null)
            }}
            okText="Valider"
            cancelText="Annuler"
            onOk={async () => {
                let result;
                setDecaissementLoading(true);

                if (isPartielOrTotal) {
                    // Logic for partial or total decaissement
                    result = {
                        montantGlobal: parseFloat(montantReglement), // Input for montantGlobal
                        sinistre: sinistre,
                        encaissement: true,
                    };

                    console.log("Partial or Total Decaissement:", result);
                } else if (isVictimeBeneficiaire) {
                    const selectedVictimes = victimes.find(victime => victime.codeId === beneficiairesVictimes);
                    const tempSous = sousEvaluations?.filter(v => sousEvaluationsSelected?.includes(v.codeId));

                    result = {
                        beneficiaire: selectedVictimes.personne,
                        sousEvaluations: tempSous,
                        montantGlobal: parseFloat(montantReglement),
                        sinistre: sinistre,
                        taux: parseFloat(honoraire),
                        expertise: false,
                        encaissement: true,
                    };

                    console.log(result);
                } else {
                    const selectedExpert = experts.find(expert => expert.codeId === beneficiairesExperts);

                    result = {
                        beneficiaire: selectedExpert,
                        montantGlobal: parseFloat(montantReglement),
                        sinistre: sinistre,
                        taux: parseFloat(honoraire),
                        expertise: true,
                        encaissement: true,
                    };

                    console.log(result);
                }

                try {
                    await saveDecaissement(result);
                    console.log("Decaissement Result:", result);
                    toast.success('Decaissement effectué !');
                    setDecaissementLoading(false);
                    modalControl.setOpen(false);
                } catch (e) {
                    setDecaissementLoading(false);
                    toast.error(`Une erreur s'est produite !`);
                    console.error("Error:", e, "Result:", result);
                }
            }

            }
        >
            {
                isVictimeBeneficiaire ? (
                        <>
                            <div className="grid-item">
                                <label
                                    style={{
                                        margin: "30px 0 10px 0",
                                    }}
                                >
                                    Joindre la victime bénéficiaire
                                </label>
                                <Select
                                    name="beneficiaire"
                                    value={beneficiairesVictimes}
                                    placeholder="Sélectionner les victimes"
                                    style={{width: "100%"}}
                                    onChange={handleBeneficiaireChange}>
                                    {victimes.map((victime) => (<Option key={victime.codeId} value={victime.codeId}>
                                        {victime.personne?.nom} {victime.personne?.prenom}
                                    </Option>))}
                                </Select>
                            </div>
                            <div className="grid-item">
                                <label
                                    style={{
                                        margin: "30px 0 10px 0",
                                    }}
                                >
                                    Joindre la(les) sous évaluation(s)
                                </label>
                                <Select
                                    name="sousEvaluations"
                                    value={sousEvaluationsSelected}
                                    placeholder="Sélectionner la(les) sous évaluation(s)"
                                    style={{width: "100%"}}
                                    mode="tags"
                                    onChange={handleSousEvChange}
                                >
                                    {filteredSousEvaluations?.map((sousEv) => (
                                        <Option key={sousEv.codeId} value={sousEv.codeId}>
                                   <span style={{
                                       color: 'blueviolet',
                                   }}>
                                 {sousEv?.garantieAffecte?.nom.toUpperCase()} -
                            </span>
                                            {sousEv?.victime?.personne.nom.toUpperCase()}-{sousEv?.victime?.personne.prenom.toUpperCase().charAt(0)}-{sousEv?.prejudices?.reduce(
                                            (acc, curr) => acc + (curr.montant || 0), 0
                                        )} FCFA
                                        </Option>))}
                                </Select>

                            </div>

                            <div className="grid-item">
                                <label
                                    style={{
                                        margin: "30px 0 10px 0",
                                    }}
                                >
                                    Taux | Honoraire
                                </label>
                                <Input
                                    name="honoraire"
                                    value={honoraire}
                                    placeholder="Saisir le taux"
                                    onChange={(event) => setHonoraire(event.target.value)}
                                />
                            </div>
                            <div className="grid-item">
                                <label
                                    style={{
                                        margin: "30px 0 10px 0",
                                    }}
                                >
                                    Montant du reglement
                                </label>
                                <Input
                                    name="montantReglement"
                                    value={montantReglement}
                                    placeholder="Saisir le montant du reglement"
                                    onChange={(event) => setMontantReglement(event.target.value)}
                                />
                            </div>
                        </>
                    ) :
                    isVictimeBeneficiaire === false ? (
                        <>
                            <div className="grid-item">
                                <label
                                    style={{
                                        margin: "30px 0 10px 0",
                                    }}
                                >
                                    Joindre la(les) expert(s) bénéficiaire(s)
                                </label>
                                <Select
                                    name="beneficiaire"
                                    value={beneficiairesExperts}
                                    placeholder="Sélectionner les victimes"
                                    style={{width: "100%"}}
                                    onChange={handleExpertChange}
                                >
                                    {experts.map((expert) => (<Option key={expert.codeId} value={expert.codeId}>
                                        {expert?.nom} {expert?.prenom}
                                    </Option>))}
                                </Select>
                            </div>
                            <div className="grid-item">
                                <label
                                    style={{
                                        margin: "30px 0 10px 0",
                                    }}
                                >
                                    Taux | Honoraire
                                </label>
                                <Input
                                    name="honoraire"
                                    value={honoraire}
                                    placeholder="Saisir le taux"
                                    onChange={(event) => setHonoraire(event.target.value)}
                                />
                            </div>

                            <div className="grid-item">
                                <label
                                    style={{
                                        margin: "30px 0 10px 0",
                                    }}
                                >
                                    Montant du reglement
                                </label>
                                <Input
                                    name="montantReglement"
                                    value={montantReglement}
                                    placeholder="Saisir le montant du reglement"
                                    onChange={(event) => setMontantReglement(event.target.value)}
                                />
                            </div>
                        </>
                    ) : isPartielOrTotal ? (
                            <div className="grid-item">
                                <label
                                    style={{
                                        margin: "30px 0 10px 0",
                                    }}
                                >
                                    Montant Global
                                </label>
                                <Input
                                    name="montantReglement"
                                    value={montantReglement}
                                    placeholder="Saisir le montant du reglement"
                                    onChange={(event) => setMontantReglement(event.target.value)}
                                />
                            </div>
                        ) :
                        (
                            <>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        height: "100%",
                                        padding: "16px",
                                    }}
                                >
                                    <Typography variant="h6" style={{textAlign: "center"}}>
                                        L'encaissement est-il ?
                                    </Typography>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            gap: "20px", // Adds spacing between buttons
                                            marginTop: "20px",
                                        }}
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            icon={<InboxOutlined/>}
                                            size="large"
                                            onClick={() => setIsPartielOrTotal(true)}
                                        >
                                            Total | Partiel
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            icon={<UserOutlined/>}
                                            size="large"
                                            onClick={() => hendleBeneficiaireIsVictimeChoice(true)}
                                        >
                                            Pour une victime
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            icon={<UserAddOutlined/>}
                                            size="large"
                                            onClick={() => hendleBeneficiaireIsVictimeChoice(false)}
                                        >
                                            Pour un expert
                                        </Button>
                                    </div>
                                </div>

                            </>
                        )
            }


        </Modal>
    </>);
};

export default ReglementRecoursModal;
