import React, {useState} from 'react';
import {DatePicker, Form, Input, message, Modal, Select} from 'antd';
import {ExclamationCircleFilled, InboxOutlined} from '@ant-design/icons';
import Dragger from "antd/es/upload/Dragger";
import {saveReglementCheque} from "../../../../hooks/useHooks";


const {Option} = Select;

const PaymentConfirmationModal = ({visible, onCancel, onOk, lotA, statutCommande, typePaiement}) => {
    const [selectedPaymentType, setSelectedPaymentType] = useState('');
    const [commentaire, setUpdateCommentaire] = useState('');
    const [feather, setFeather] = useState('');
    const [fileUrl, setFileUrl] = useState(null)
    const [numeroCheque, setNumeroCheque] = useState(null)
    const [dateEmission, setDateEmission] = useState(null)
    const [banque, setBanque] = useState(null)
    const [numeroPaiement, setNumeroPaiement] = useState(null)
    const [identifiantPaiement, setIdentifiantPaiement] = useState(null)

    const draggerProps = {
        name: 'file', accept: 'image/*, .pdf', multiple: false, customRequest: async (options) => {
            const {file, onSuccess, onError, onProgress} = options;
            try {

                const response = await saveReglementCheque(file);
                console.log(response)
                setFileUrl(response)
                message.success(`${file.name} téléversé avec succès.`);
            } catch (error) {
                // Handle errors
                onError(error);
                message.error(`Échec de l'upload du fichier: ${file.name}`);
            }
        }, onChange(info) {
            const {status} = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {

                setFileUrl(sessionStorage.getItem('file'))
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        }, onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };
    console.log(fileUrl + ' fileUrl ')

    const handleFileUpload = (info) => {
        console.log('File uploaded:', info);
    };

    const handlePaymentTypeChange = (value) => {
        setSelectedPaymentType(value);
        setFeather(value);
    };
    console.log(selectedPaymentType)

    const getActionVerb = (statutCommande) => {
        switch (statutCommande) {
            case "INITIE":
                return "valider";
            case "VALIDE":
                return "autoriser";
            case "AUTORISE":
                return "passer en traitement";
            case "EN_TRAITEMENT":
                return "approuver";
            case "APPROUVE":
                return "régler";
            case "REGLE":
                return "livrer";
            case "DELIVRE":
                return "réceptionner";
            default:
                return null;
        }
    };


    const reglementBonCommandeDTO = {
        numeroCheque: numeroCheque,
        dateEmissionCheque: dateEmission,
        fichierCheque: fileUrl,
        banque: banque,
        numeroPaiement: numeroPaiement,
        identifiantPaiement: identifiantPaiement
    }


    return (
        <Modal
            visible={visible}
            title={`Etes-vous sûr de vouloir ${getActionVerb(statutCommande)} la commande?`}
            icon={<ExclamationCircleFilled color={'blue'}/>}
            onCancel={onCancel}
            onOk={() => onOk(lotA, commentaire, reglementBonCommandeDTO)}
            okText="Oui"
            cancelText="Annuler"
            width={600}
            maskClosable={false}
        >
            <p style={{color: 'red', marginBottom: '1rem'}}>
                Toutes vos actions sont historisées
            </p>
            <Form layout="vertical">
                <Form.Item label="Type de Paiement" name="typePaiement">
                    <Select
                        onChange={(value) => handlePaymentTypeChange(value)}
                        /* style={{
                             width: "100%", padding: "8px", borderRadius: "4px", border: "1px solid #d9d9d9"
                         }}*/
                        value={selectedPaymentType || ""}
                    >

                        {Object.entries(typePaiement).map(([key, label]) => (
                            <Option key={key} value={key}>
                                {label}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <div>
                    {selectedPaymentType === 'CHEQUE' && (
                        <>
                            <Form.Item label="Numéro de Chèque" name="numeroCheque">
                                <Input placeholder="Entrez le numéro du chèque"
                                       onChange={(e) => setNumeroCheque(e.target.value)}/>
                            </Form.Item>
                            <Form.Item label="Date d'Émission" name="dateEmission">
                                <DatePicker style={{width: "100%"}} onChange={(date) => setDateEmission(date)}/>
                            </Form.Item>
                            <Form.Item label="Banque" name="banque">
                                <Select placeholder="Sélectionnez une banque" onChange={(value) => setBanque(value)}>
                                    <Option value="banque1">Banque 1</Option>
                                    <Option value="banque2">Banque 2</Option>
                                    <Option value="banque3">Banque 3</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item label="Fichier du Chèque" name="fichierCheque">
                                <Dragger {...draggerProps}
                                >
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined/>
                                    </p>
                                    <p className="ant-upload-text">
                                        Cliquez ou glissez un fichier ici pour le télécharger.
                                    </p>
                                </Dragger>
                            </Form.Item>

                        </>
                    )}
                    {selectedPaymentType === 'MOBILE_MONEY' && (
                        <>
                            <Form.Item label="Numéro de Paiement" name="numeroPaiement">
                                <Input addonBefore="+228" placeholder="Entrez le numéro de paiement"
                                       onChange={(e) => setNumeroPaiement(e.target.value)}/>
                            </Form.Item>
                            <Form.Item label="Identifiant de Paiement" name="identifiantPaiement">
                                <Input placeholder="Entrez l'identifiant de paiement"
                                       onChange={(e) => setIdentifiantPaiement(e.target.value)}/>
                            </Form.Item>
                        </>
                    )}
                </div>
            </Form>

            <div style={{marginBottom: '1rem'}}>
                <label htmlFor="commentaire">Faites un commentaire :</label>
                <textarea
                    id="commentaire"
                    style={{
                        width: '100%',
                        marginTop: '0.5rem',
                        padding: '0.5rem',
                        borderRadius: '4px',
                        border: '1px solid #d9d9d9',
                    }}
                    placeholder="Votre commentaire ici..."
                    onChange={(e) => setUpdateCommentaire(e.target.value)}
                />
            </div>
        </Modal>
    );
};

export default PaymentConfirmationModal;