import React, { useEffect, useState } from "react";
import { PlusOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Modal, Dropdown, Menu } from "antd";
import axios from "axios";
import Footer from "../../../components/Footer";
import NavBar from "../../../components/NavBar";
import SideBar from "../../../components/SideBar";
import { API_URL, TOKEN } from "../../../variables/constants";
import { toast } from "react-toastify";
import AddCauseSinistre from "./AddCauseSinistre"; // Correction du nom importé

const CausesSinistres = () => {
    const [causesSinistre, setCausesSinistre] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [selectedCauseSinistre, setSelectedCauseSinistre] = useState(null); 

    const handleShow = (edit = false, causeSinistre = null) => {
        setShowModal(true);
        setEditMode(edit);
        setSelectedCauseSinistre(causeSinistre); 
    };

    const handleClose = () => {
        setShowModal(false);
        setEditMode(false);
        setSelectedCauseSinistre(null); 
    };

    // Fetch list of causes sinistre
    useEffect(() => {
        axios
            .get(`${API_URL}/api/parametre/type/CAUSE_SINISTRE`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                },
            })
            .then((res) => setCausesSinistre(res.data))
            .catch((err) => console.error("Error fetching causes sinistre:", err));
    }, [showModal]);

    // Handle delete action with confirmation
    const handleDeleteCauseSinistre = (causeSinistreId) => {
        Modal.confirm({
            title: "Êtes-vous sûr de vouloir supprimer cette cause de sinistre ?",
            content: "Cette action est irréversible",
            okText: "Oui",
            okType: "danger",
            cancelText: "Annuler",
            onOk: () => {
                axios
                    .delete(`${API_URL}/api/parametre/${causeSinistreId}`, {
                        headers: { Authorization: `Bearer ${TOKEN}` },
                    })
                    .then(() => {
                        setCausesSinistre((prevCausesSinistre) =>
                            prevCausesSinistre.filter(
                                (causeSinistre) => causeSinistre.parametreId !== causeSinistreId
                            )
                        );
                        toast.success("Cause de sinistre supprimée avec succès.");
                    })
                    .catch((err) => {
                        console.error("Error deleting cause sinistre:", err);
                        toast.error("Erreur lors de la suppression de la cause de sinistre.");
                    });
            },
        });
    };

    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <SideBar currentMenu="AUTRES_PARAMETRES" />
                <div className="layout-page">
                    <NavBar />
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <Button
                                type="primary"
                                icon={<PlusOutlined />}
                                style={{ marginBottom: 16 }}
                                onClick={() => handleShow()}
                            >
                                Ajouter une nouvelle cause de sinistre
                            </Button>
                            <div className="card">
                                <h5 className="card-header">Paramétrage des causes de sinistres</h5>
                                <div className="table-responsive text-nowrap">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Libellé</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody className="table-border-bottom-0">
                                            {causesSinistre?.map((causeSinistre, id) => (
                                                <tr key={id}>
                                                    <td>{causeSinistre.libelleParametre}</td>
                                                    <td>
                                                        <Dropdown
                                                            overlay={
                                                                <Menu>
                                                                    <Menu.Item
                                                                        icon={<EditOutlined />}
                                                                        onClick={() => handleShow(true, causeSinistre)}
                                                                    >
                                                                        Modifier
                                                                    </Menu.Item>
                                                                    <Menu.Item
                                                                        icon={<DeleteOutlined />}
                                                                        danger
                                                                        onClick={() => handleDeleteCauseSinistre(causeSinistre.parametreId)}
                                                                    >
                                                                        Supprimer
                                                                    </Menu.Item>
                                                                </Menu>
                                                            }
                                                            trigger={["click"]}
                                                        >
                                                            <Button type="text" icon={<PlusOutlined />} />
                                                        </Dropdown>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>

            <AddCauseSinistre
                show={showModal}
                handleClose={handleClose}
                editMode={editMode}
                selectedCauseSinistre={selectedCauseSinistre} 
            />
        </div>
    );
};

export default CausesSinistres;
