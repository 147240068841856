import {Typography} from "antd";
import React from "react";
import {toast} from "react-toastify";
import {FormInput, FormTextArea} from "../../../../../components/forms/FormInputs";

const {Title} = Typography;

const SingleItemForm = ({formik, index}) => {
    const getFieldPath = (field) => `items[${index}].${field}`;

    const handleFieldErrorToast = (fieldName) => {
        const fieldMeta = formik.getFieldMeta(fieldName);
        if (fieldMeta.touched && fieldMeta.error) {
            toast.error(fieldMeta.error, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };

    console.log(formik.values)
    return (
        <div className="grid-container grid container" style={{justifyContent: "center"}}>
            <Typography.Title style={{fontSize: '26px', color: '#1a1a1a'}}>
                Informations sur la victime
            </Typography.Title>

            <FormInput
                label="Nom de la victime"
                name={getFieldPath("nom")}
                value={formik.values.items[index]?.nom || ""}
                onBlur={() => handleFieldErrorToast(getFieldPath("nom"))}
                onChange={formik.handleChange}
            />
            <FormInput
                label="Prénoms de la victime"
                name={getFieldPath("prenom")}
                value={formik.values.items[index]?.prenom || ""}
                onBlur={() => handleFieldErrorToast(getFieldPath("prenom"))}
                onChange={formik.handleChange}
            />
            <FormInput
                label="Numéro de téléphone de la victime"
                name={getFieldPath("telephone")}
                value={formik.values.items[index]?.telephone || ""}
                onBlur={() => handleFieldErrorToast(getFieldPath("telephone"))}
                onChange={formik.handleChange}
            />
            <FormInput
                label="Profession de la victime"
                name={getFieldPath("profession")}
                value={formik.values.items[index]?.profession || ""}
                onBlur={() => handleFieldErrorToast(getFieldPath("profession"))}
                onChange={formik.handleChange}
            />
            <FormInput
                label="Adresse de la victime"
                name={getFieldPath("adresse")}
                value={formik.values.items[index]?.adresse || ""}
                onBlur={() => handleFieldErrorToast(getFieldPath("adresse"))}
                onChange={formik.handleChange}
            />
            <FormTextArea
                label="Description des dégâts sur la victime"
                name={getFieldPath("descriptionDegatsVictime")}
                value={formik.values.items[index]?.descriptionDegatsVictime || ""}
                onBlur={() => handleFieldErrorToast(getFieldPath("descriptionDegatsVictime"))}
                onChange={formik.handleChange}
            />
        </div>
    );
};


export default SingleItemForm;


