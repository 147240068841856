import {ExclamationCircleFilled, PlusOutlined} from "@ant-design/icons";
import {Button, Card, Modal, Spin, Table, Tag} from "antd";
import axios from "axios";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import Footer from "../../../components/Footer";
import NavBar from "../../../components/NavBar";
import SideBar from "../../../components/SideBar";
import {API_URL, TOKEN} from "../../../variables/constants";

const Commandes = () => {
    const [commandes, setCommandes] = useState([]);
    const [loadingOrders, setLoadingOrders] = useState(true);
    const [orderModalOpen, setOrderModalOpen] = useState(false);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [nombre, setNombre] = useState(0);
    const [selectedCategorie, setSelectedCategorie] = useState("M");
    const [selectedTypeAttestation, setSelectedTypeAttestation] =
        useState("SIMPLE");
    const [commentaire, setCommentaire] = useState("");
    const [reload, setReload] = useState(false);

    const resetForm = () => {
        setNombre(0);
        setSelectedCategorie("M");
        setSelectedTypeAttestation("SIMPLE");
        setCommentaire("");
    };

    const getTagColor = (statutCommande) => {
        switch (statutCommande) {
            case "INITIE":
                return "blue";
            case "VALIDE":
                return "green";
            case "AUTORISE":
                return "gold";
            case "CONFIRME":
                return "purple";
            case "DELIVRE":
                return "cyan";
            case "RECEPTIONNE":
                return "volcano";
            default:
                return "default";
        }
    };

    const columns = [
        {
            title: "Compagnie",
            dataIndex: "compagnie",
            key: "1",
            render: (_, record) => (
                <>{record?.emetteur?.profil?.organisme?.raisonSociale} </>
            ),
        },
        {
            title: "Nombre",
            dataIndex: "nombre",
            key: "2",
        },
        {
            title: "Type d'attestation",
            dataIndex: "typeAttestation",
            key: "3",
            render: (_, record) => (
                <>
                    {record.typeAttestation} - {record?.categorie?.nom}
                </>
            ),
        },
        {
            title: "Statut commande",
            dataIndex: "statutCommande",
            key: "4",
            render: (_, record) => (
                <Tag color={getTagColor(record.statutCommande)}>
                    {record.statutCommande}
                </Tag>
            ),
        },
        {
            title: "Date de Commande",
            dataIndex: "dateEmission",
            key: "dateEmission",
            render: (_, record) => (
                <>{new Date(record.dateEmission).toLocaleDateString()}</>
            ),
        },
        {
            title: "Action",
            dataIndex: "actions",
            key: "actions",
            render: (_, record) => (
                <>
                    <button
                        type="button"
                        className="btn btn-success btn-icon rounded-pill"
                    >
                        <i className="bx bx-edit-alt"></i>
                    </button>
                    <button
                        type="button"
                        className="btn btn-secondary btn-icon rounded-pill"
                        onClick={() => showValidationAttestationConfirm(record.codeId)}
                    >
                        <i className="bx bx-check-circle"></i>
                    </button>
                </>
            ),
        },
    ];

    console.log('tchiee ', commandes)

    useEffect(() => {
        axios
            .get(`${API_URL}/api/bon-commande`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                },
            })
            .then((res) => {
                setCommandes(res.data.reverse());
                setLoadingOrders(false);
            })
            .catch(() => setLoadingOrders(false));

        axios
            .get(`${API_URL}/api/categorie`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                },
            })
            .then((res) => setCategories(res.data))
            .catch(console.error);
    }, [TOKEN, reload]);

    const onSubmitCommandeAttestation = () => {
        if (nombre) {
            const catg = categories.find(
                (element) => element.codeCategorie === selectedCategorie
            );
            let bonCommande = {

                commentaire,
                nombre,
                categorie: catg,
                typeAttestation: selectedTypeAttestation,
                statutCommande: "INITIE",
            };
            console.log("data envoyée", bonCommande);


            axios
                .post(`${API_URL}/api/bon-commande`, JSON.stringify(bonCommande), {
                    headers: {
                        Authorization: `Bearer ${TOKEN}`,
                        "Content-Type": "application/json",
                    },
                })
                .then(() => {
                    setReload(true);
                    toast("Lot d'attestations ajouté avec succès");
                    setLoading(false);
                    setOrderModalOpen(false);
                    resetForm();
                    console.log("data envoyée", bonCommande);

                })
                .catch(() => toast("Erreur lors de l'ajout du lot d'attestations"));
        } else {
            toast("Veuillez renseigner le nombre d'attestations!");
        }
    };

    const showValidationAttestationConfirm = (lotA) => {
        Modal.confirm({
            title: "Etes-vous sûr de vouloir valider la commande?",
            icon: <ExclamationCircleFilled/>,
            content: "Toutes vos actions sont historisées",
            okText: "Oui",
            okType: "danger",
            cancelText: "Annuler",
            onOk: () => validation(lotA),
        });
    };

    const validation = (lotA) => {
        console.log("Code de la commande à valider :", lotA);
        axios
            .get(`${API_URL}/api/bon-commande/validate/${lotA}`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                },
            })
            .then((res) => {
                toast("Commande validée avec succès !");
                setCommandes((prevCommandes) =>
                    prevCommandes.filter((commande) => commande.codeId !== lotA)
                );


            })
            .catch((error) => {
                console.error("Erreur lors de la validation :", error);
                toast("Erreur lors de la validation !");
            });
    };


    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <SideBar/>
                <div className="layout-page">
                    <NavBar/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <Card
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginBottom: "20px",
                                    padding: "10px 20px",
                                    backgroundColor: "#e3f2fd",
                                    color: "#0d47a1",
                                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                    borderRadius: "8px",
                                }}
                            >
                                <Button
                                    type="primary"
                                    icon={<PlusOutlined/>}
                                    onClick={() => setOrderModalOpen(true)}
                                >
                                    Ajouter une commande
                                </Button>
                            </Card>

                            <Spin spinning={loadingOrders}>
                                <Table
                                    columns={columns}
                                    dataSource={commandes}
                                    rowKey="codeId"
                                />
                            </Spin>
                        </div>
                        <Footer/>
                    </div>
                </div>
            </div>
            <Modal
                open={orderModalOpen}
                loading={loading}
                onOk={onSubmitCommandeAttestation}
                onCancel={() => setOrderModalOpen(false)}
                zIndex={1900}
                centered
            >
                <h4>Lancer une commande</h4>
                <p>Effectuer une commande de lot(s) d'attestations</p>
                <form>
                    <div className="form-group">
                        <label>Nombre d'attestations</label>
                        <input
                            type="number"
                            className="form-control"
                            value={nombre}
                            onChange={(e) => setNombre(parseInt(e.target.value))}
                            placeholder="Entrez le nombre"
                        />
                    </div>
                    <div className="form-group">
                        <label>Type d'attestation</label>
                        <select
                            className="form-control"
                            value={selectedTypeAttestation}
                            onChange={(e) => setSelectedTypeAttestation(e.target.value)}
                        >
                            <option value="SIMPLE">SIMPLE</option>
                            <option value="CEDEAO">CEDEAO</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label>Catégorie</label>
                        <select
                            className="form-control"
                            value={selectedCategorie}
                            onChange={(e) => setSelectedCategorie(e.target.value)}
                        >
                            {categories.map((cat, index) => (
                                <option key={index} value={cat.codeCategorie}>
                                    {cat.nom}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group">
                        <label>Commentaire</label>
                        <textarea
                            className="form-control"
                            rows="3"
                            value={commentaire}
                            onChange={(e) => setCommentaire(e.target.value)}
                            placeholder="Ajoutez un commentaire"
                        ></textarea>
                    </div>
                </form>
            </Modal>
        </div>
    );
};

export default Commandes;
