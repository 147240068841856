import { EllipsisOutlined, EyeOutlined, EditOutlined, DeleteOutlined, ExclamationCircleFilled, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, Modal, Table } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import NavBar from "../../components/NavBar";
import SideBar from "../../components/SideBar";
import { API_URL, TOKEN } from "../../variables/constants";
import AddMembres from "./AddMembres"; // Modal for adding/editing members

const { confirm } = Modal;

const AllMembres = () => {
  const [assurances, setAssurances] = useState([]);
  const [partenaires, setPartenaires] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedMembre, setSelectedMembre] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);

    axios
      .get(`${API_URL}/api/organisme/assurance`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      })
      .then((res) => setAssurances(res.data))
      .catch(() => toast.error("Erreur lors du chargement des assurances"));



    axios
      .get(`${API_URL}/api/organisme/partenaire`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      })
      .then((res) => setPartenaires(res.data))
      .catch(() => toast.error("Erreur lors du chargement des partenaires"))
      .finally(() => setLoading(false));
  }, []);

  const showDeleteConfirm = (organismeId) => {
    confirm({
      title: "Etes-vous sûr de vouloir supprimer ce membre?",
      icon: <ExclamationCircleFilled />,
      content: "Cela impliquera que toutes les données soient corrompues",
      okText: "Oui",
      okType: "danger",
      cancelText: "Annuler",
      onOk() {
        deleteMembre(organismeId);
      },
    });
  };

  const deleteMembre = (organismeId) => {
    axios
      .delete(`${API_URL}/api/organisme/${organismeId}`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      })
      .then(() => {
        setAssurances((prev) => prev.filter((item) => item.organismeId !== organismeId));
        setPartenaires((prev) => prev.filter((item) => item.organismeId !== organismeId));
        toast.success("Suppression effectuée avec succès");
      })
      .catch(() => toast.error("Erreur lors de la suppression"));
  };

  const handleShowModal = (membre = null) => {
    setSelectedMembre(membre);
    setEditMode(!!membre);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedMembre(null);
  };

  const columns = [
    {
      title: "Raison sociale",
      dataIndex: "raisonSociale",
      key: "raisonSociale",
      render: (text, record) => (
        <a href={`/membre/${record.code}`} className="text-heading text-truncate">
          {text}
        </a>
      ),
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Date de création",
      dataIndex: "dateCreation",
      key: "dateCreation",
    },
    {
      title: "Responsable",
      dataIndex: "responsable",
      key: "responsable",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => {
        const menu = (
          <Menu>
            <Menu.Item
              key="1"
              icon={<EyeOutlined />}
              onClick={() => navigate(`/membre/${record.code}`)}
            >
              Détails
            </Menu.Item>
            <Menu.Item
              key="2"
              icon={<EditOutlined />}
              onClick={() => handleShowModal(record)}
            >
              Modifier
            </Menu.Item>
            <Menu.Item
              key="3"
              danger
              icon={<DeleteOutlined />}
              onClick={() => showDeleteConfirm(record.organismeId)}
            >
              Supprimer
            </Menu.Item>
          </Menu>
        );

        return (
          <Dropdown overlay={menu} trigger={['click']}>
            <Button type="text" icon={<EllipsisOutlined />} />
          </Dropdown>
        );
      },
    },
  ];

  const dataSource = [...assurances, ...partenaires].map((item, index) => ({
    key: index,
    ...item,
  }));

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <SideBar currentMenu={"MEMBRES"} />
        <div className="layout-page">
          <NavBar />

          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              <div className="row">
                <div className="col-sm-4">
                  <Button
                    type="primary"
                    icon={<PlusCircleOutlined />}
                    style={{ marginBottom: 16 }}
                    onClick={() => handleShowModal()}
                  >
                    Nouveau membre et partenaire du Pool
                  </Button>
                </div>
              </div>
              <div className="card">
                <h5 className="card-header">Les membres et partenaires du Pool</h5>
                <div className="table-responsive">
                  <Table
                    columns={columns}
                    dataSource={dataSource}
                    loading={loading}
                    pagination={{ pageSize: 10 }}
                  />
                </div>
              </div>
            </div>

            <Footer />
          </div>
        </div>
      </div>

      {showModal && (
        <AddMembres
          show={showModal}
          handleClose={handleCloseModal}
          editMode={editMode}
          selectedMembre={selectedMembre}
        />
      )}
    </div>
  );
};

export default AllMembres;
