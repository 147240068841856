import { Card, Image } from "antd";
import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { CardBody } from "react-bootstrap";
import entete from "../../../assets/img/entete.jpg";
import entfoot from "../../../assets/img/footer.png";
import { frenchDate } from "../../../variables/functions";


const styles = {
    infoText: {
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center",
    },

    epais: {
        border: "1px solid #063970",
        margin: "0px 0px 0px 0px",
    },

    textLeft: {
        textAlign: "left",
    },

    tabTextStyleLeft: {
        border: "1px solid #063970",
        textAlign: "left",
    },

    tabTextStyleRight: {
        border: "1px solid #063970",
        textAlign: "right",
    },

    textRight: {
        textAlign: "right",
    },

    textCenter: {
        textAlign: "center",
    },

    inputAdornmentIcon: {
        color: "#555",
    },
    inputAdornment: {
        position: "relative",
    },
    container: {
        maxWidth: "800px",
        margin: "0 auto",
        backgroundColor: "#fff",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        padding: "20px",
    },
    table: {
        width: "100%",
        borderCollapse: "collapse",
    },
    cell: {
        padding: "12px 16px",
        border: "1px solid #ddd",
        textAlign: "left",
    },
    leftCell: {
        fontWeight: "bold",
        color: "#555",
    },
    rightCell: {
        color: "#333",
    },
    rowEven: {
        backgroundColor: "#f9f9f9",
    },
    rowOdd: {
        backgroundColor: "#ffffff",
    },
};


const FicheDemandeReglementSinistre = (props) => {
    const [cumulPaye, setCumulPaye] = useState(0);
    useEffect(() => {
        if (props?.sinistre?.decaissements) {
            let cumul = 0.0;
            props?.sinistre?.decaissements.forEach((decaissement) => {
                cumul += parseFloat(decaissement.montantGlobal);
            });
            setCumulPaye(cumul);
        }
    }, [props?.decaissement?.sinistre?.decaissements, props?.sinistre?.decaissements]);
    const sinistre = props.sinistre
    const decaissement = props.decaissement
    return (
        <div className={'container'} style={{ justifyContent: 'center' }}>
            <div className={'container'}>
                <div className={'grid-item'}>
                    <Image src={entete} />
                    <Card testimonial>
                        <CardBody>
                            <div className={'grid-item'}>
                                <hr className={styles.epais}></hr>
                                <div className={'container'} style={{ justifyContent: 'center' }}>
                                    <div className={'grid-item'}>
                                        <h5>
                                            <b>FICHE DE DEMANDE DE REGLEMENT SINISTRE</b>
                                        </h5>
                                    </div>
                                </div>
                                <hr className={styles.epais}></hr>
                                <br />
                                <br />
                                <div style={styles.container}>
                                    <table style={styles.table}>
                                        <tbody>
                                            {[
                                                {
                                                    label: "Sinistre N°",
                                                    value: sinistre?.numSinistre,
                                                    label2: "Police N°",
                                                    value2:
                                                        sinistre?.contrat?.numeroPoliceM ||
                                                        sinistre?.contrat?.numeroPoliceP,
                                                },
                                                {
                                                    label: "Date de survenance",
                                                    value: frenchDate(sinistre?.dateSinistre),
                                                    label2: "Effet",
                                                    value2: frenchDate(sinistre?.contrat?.dateEffet),
                                                },
                                                {
                                                    label: "Date de Déclaration",
                                                    value: frenchDate(sinistre?.createdAt),
                                                    label2: "Echéance",
                                                    value2: frenchDate(sinistre?.contrat?.dateEcheance),
                                                },
                                                {
                                                    label: "Catégorie",
                                                    value: sinistre?.engin?.categorie?.nom,
                                                    label2: "Assuré",
                                                    value2: sinistre?.contrat?.souscripteur?.prenom
                                                        ? `${sinistre?.contrat?.souscripteur?.nom} ${sinistre?.contrat?.souscripteur?.prenom}`
                                                        : sinistre?.contrat?.souscripteur?.nom,
                                                },
                                            ].map((row, index) => (
                                                <tr
                                                    key={index}
                                                    style={index % 2 === 0 ? styles.rowEven : styles.rowOdd}
                                                >
                                                    <td style={{ ...styles.cell, ...styles.leftCell }}>
                                                        {row.label}
                                                    </td>
                                                    <td style={{ ...styles.cell, ...styles.rightCell }}>
                                                        {row.value}
                                                    </td>
                                                    <td style={{ ...styles.cell, ...styles.leftCell }}>
                                                        {row.label2}
                                                    </td>
                                                    <td style={{ ...styles.cell, ...styles.rightCell }}>
                                                        {row.value2}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <br />
                                <div className={'container'} style={{ justifyContent: 'center' }}>
                                    <div className={'grid-item'}
                                    >
                                        <h5>
                                            <b>
                                                <u>SITUATION FINANCIERE DU DOSSIER</u>
                                            </b>
                                        </h5>
                                    </div>
                                </div>
                                <br />
                                <div style={styles.container}>
                                    <table style={styles.table}>
                                        <tbody>
                                            <tr>
                                                <td style={{ ...styles.cell, ...styles.textLeft }}>
                                                    <b>Coût total prévisible</b>
                                                </td>
                                                <td style={styles.cell}>
                                                    <b>
                                                        {new Intl.NumberFormat("en-DE").format(
                                                            Math.trunc(sinistre?.evaluation || 0)
                                                        )}
                                                    </b>
                                                </td>
                                                <td style={{ ...styles.cell, ...styles.textLeft }}>
                                                    <b>Prévision de recours</b>
                                                </td>
                                                <td style={styles.cell}>
                                                    <b>0</b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ ...styles.cell, ...styles.textLeft }}>
                                                    <b>Cumul des paiements</b>
                                                </td>
                                                <td style={styles.cell}>
                                                    <b>
                                                        {new Intl.NumberFormat("en-DE").format(Math.trunc(cumulPaye || 0))}
                                                    </b>
                                                </td>
                                                <td style={styles.cell}></td>
                                                <td style={styles.cell}></td>
                                            </tr>
                                            <tr>
                                                <td style={{ ...styles.cell, ...styles.textLeft }}>
                                                    <b>SAP</b>
                                                </td>
                                                <td style={styles.cell}>
                                                    <b>
                                                        {new Intl.NumberFormat("en-DE").format(
                                                            Math.trunc((sinistre?.evaluation || 0) - (cumulPaye || 0))
                                                        )}
                                                    </b>
                                                </td>
                                                <td style={{ ...styles.cell, ...styles.textLeft }}>
                                                    <b>Sort du dossier</b>
                                                </td>
                                                <td style={styles.cell}>
                                                    <b>
                                                        {decaissement?.sinistre?.sortDossier === "I"
                                                            ? "EN INSTANCE"
                                                            : decaissement?.sinistre?.sortDossier === "T"
                                                                ? "TERMINE"
                                                                : decaissement?.sinistre?.sortDossier === "S"
                                                                    ? "SANS SUITE"
                                                                    : "EN INSTANCE"}
                                                    </b>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <br />
                                <div className={'container'} style={{ justifyContent: 'center' }}>
                                    <div className={'grid-item'}>
                                        <h5>
                                            <b>
                                                <u>
                                                    {" "}
                                                    DEMANDE DE REGLEMENT DU{" "}
                                                    {frenchDate(props?.decaissement?.dateReglement)}
                                                </u>
                                            </b>
                                        </h5>
                                    </div>
                                </div>
                                {/* <br /> */}
                                <div style={styles.container}>
                                    <div style={styles.gridItem}>
                                        <table style={styles.table}>
                                            <tbody>
                                                <tr>
                                                    <td style={{ ...styles.cell, ...styles.textLeft }}>Bénéficiaire :</td>
                                                    <td style={styles.cell}>
                                                        {decaissement?.beneficiaire?.prenom
                                                            ? `${decaissement?.beneficiaire?.nom} ${decaissement?.beneficiaire?.prenom}`
                                                            : `${decaissement?.beneficiaire?.prenom}`}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ ...styles.cell, ...styles.textLeft }}>Nature :</td>
                                                    <td style={styles.cell}>{decaissement?.sinistre?.typeSinistre || "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ ...styles.cell, ...styles.textLeft }}>Motif :</td>
                                                    <td style={styles.cell}>Le remboursement des frais médicaux justifiés
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ ...styles.cell, ...styles.textLeft }}>Montant :</td>
                                                    <td style={styles.cell}>
                                                        {new Intl.NumberFormat("en-DE").format(
                                                            Math.trunc(decaissement?.montantGlobal || 0)
                                                        )}{" "}
                                                        FCFA
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ ...styles.cell, ...styles.textLeft }}>Après une retenue de
                                                        :
                                                    </td>
                                                    <td style={styles.cell}>
                                                        {decaissement?.montantPaye
                                                            ? new Intl.NumberFormat("en-DE").format(
                                                                Math.trunc(
                                                                    (decaissement?.montantGlobal || 0) -
                                                                    (decaissement?.montantPaye || 0)
                                                                )
                                                            )
                                                            : "0"}{" "}
                                                        FCFA
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div style={styles.gridItem}></div>
                                </div>
                                <br />
                                <div className={'container'} style={{ justifyContent: 'center' }}>
                                    <div className={'grid-item'}
                                    // md={12}
                                    >
                                        <h5>
                                            <b>
                                                <u> Visa et Autorisation</u>
                                            </b>
                                        </h5>
                                    </div>
                                </div>
                                <br />

                                <div className={'container'} style={{ justifyContent: 'center' }}>
                                    <div className={'grid-item'} md={4}>
                                        <h7>
                                            <u>Rédacteur</u>
                                        </h7>
                                    </div>
                                    <div className={'grid-item'} md={4}>
                                        <h7>
                                            <u>D.O</u>
                                        </h7>
                                    </div>
                                    <div className={'grid-item'} md={4}>
                                        <h7>
                                            <u>Directeur du Pool</u>
                                        </h7>

                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                    <Image src={entfoot} />
                </div>
            </div>
        </div>
    );
};

FicheDemandeReglementSinistre.propTypes = {
    decaissement: PropTypes.object,
    sinistre: PropTypes.object,
};

export default FicheDemandeReglementSinistre;
