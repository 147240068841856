import PropTypes from "prop-types";
import React from "react";
import { frenchDateOnly } from "../../../../variables/functions";

const AttestationCEDEAOToPrint = React.forwardRef((props, ref) => {
    return (
        <div ref={ref} >
            <div
                style={{ height: 900, width: 1080, backgroundSize: "contain", backgroundRepeat: "no-repeat", position: "absolute" }}
            >
                <div className="row">
                    <div className="col" style={{ position: 'relative' }}>
                        <h6
                            className="text-left"
                            style={{ position: 'absolute', marginLeft: "30px", marginTop: "45px", fontSize: "12px" }}
                        >
                            <b>
                                {`${props.contrat?.souscripteur?.nom} ${props.contrat?.souscripteur?.prenom ? props.contrat?.souscripteur?.prenom : ""}`.substring(0, 20)}{" "}

                            </b>
                        </h6>
                        <h6
                            className="text-left"
                            style={{ position: 'absolute', marginLeft: "30px", marginTop: "60px", fontSize: "12px" }}
                        >
                            <b>{`${props.contrat?.souscripteur?.nom} ${props.contrat?.souscripteur?.prenom ? props.contrat?.souscripteur?.prenom : ""}`.substring(20)} {props.contrat?.souscripteur?.contact?.[0] || " "}</b>
                        </h6>
                        <h6
                            className="text-left"
                            style={{ position: 'absolute', marginLeft: "90px", marginTop: "70px", fontSize: "12px" }}
                        >
                            <b>
                                {props.engin?.immatriculation || ""}
                            </b>
                        </h6>
                        <h6
                            className="text-left"
                            style={{ position: 'absolute', marginLeft: "85px", marginTop: "90px", fontSize: "12px" }}
                        >
                            <b>{props.engin?.marque || ""}</b>
                        </h6>
                        <h6
                            className="text-left"
                            style={{ position: 'absolute', marginLeft: "62px", marginTop: "105px", fontSize: "12px" }}
                        >
                            <b>{props.contrat?.organisme?.raisonSociale}</b>
                        </h6>
                        <h6
                            className="text-left"
                            style={{ position: 'absolute', marginLeft: "112px", marginTop: "135px", fontSize: "12px" }}
                        >
                            <b>{props.contrat?.producteur?.agence?.nom ? `${props.contrat?.producteur?.agence?.nom} ${props.contrat?.producteur?.agence?.ville}` : "---"}</b>
                        </h6>
                        <h6
                            className="text-left"
                            style={{ position: 'absolute', marginLeft: "46px", marginTop: "155px", fontSize: "12px" }}
                        >
                            <b>{props.engin?.numeroChassis}</b>
                        </h6>
                        <h6
                            className="text-left"
                            style={{ position: 'absolute', marginLeft: "62px", marginTop: "173px", fontSize: "12px" }}
                        >
                            <b>{props.contrat?.numeroPoliceM || ""}</b>
                        </h6>
                        <h6
                            className="text-left"
                            style={{ position: 'absolute', marginLeft: "47px", marginTop: "197px", fontSize: "12px" }}
                        >
                            <b>{frenchDateOnly(props.contrat?.dateEffet)}</b>
                        </h6>
                        <h6
                            className="text-left"
                            style={{ position: 'absolute', marginLeft: "135px", marginTop: "197px", fontSize: "12px" }}
                        >
                            <b>{frenchDateOnly(props.contrat?.dateEcheance)}</b>
                        </h6>
                        <h6
                            className="text-left"
                            style={{ position: 'absolute', marginLeft: "35px", marginTop: "237px", fontSize: "12px" }}
                        >
                            <b>{props.contrat?.engins[0]?.categorie?.nom}</b>
                        </h6>
                    </div>
                    <div className="col">
                        <h6
                            className=""
                            style={{ position: 'absolute', marginLeft: "-100px", marginTop: "46px", fontSize: "12px" }}
                        >
                            <b>
                                {`${props.contrat?.souscripteur?.nom} ${props.contrat?.souscripteur?.prenom ? props.contrat?.souscripteur?.prenom : ""}`.substring(0, 20)}{" "}
                            </b>
                        </h6>
                        <h6
                            className="text-left"
                            style={{ position: 'absolute', marginLeft: "-100px", marginTop: "60px", fontSize: "12px" }}
                        >
                            <b>{`${props.contrat?.souscripteur?.nom} ${props.contrat?.souscripteur?.prenom ? props.contrat?.souscripteur?.prenom : ""}`.substring(20)} {props.contrat?.souscripteur?.contact?.[0] || " "}</b>
                        </h6>
                        <h6
                            className="text-left"
                            style={{ position: 'absolute', marginLeft: "-50px", marginTop: "74px", fontSize: "12px" }}
                        >
                            <b>{props.engin?.immatriculation || ""}</b>
                        </h6>
                        <h6
                            className="text-left"
                            style={{ position: 'absolute', marginLeft: "-50px", marginTop: "90px", fontSize: "12px" }}
                        >
                            <b>{props.engin?.marque || "_"}</b>
                        </h6>
                        <h6
                            className="text-left"
                            style={{ position: 'absolute', marginLeft: "-70px", marginTop: "109px", fontSize: "12px" }}
                        >
                            <b>{props.contrat?.organisme?.raisonSociale}</b>
                        </h6>
                        <h6
                            className="text-left"
                            style={{ position: 'absolute', marginLeft: "-45px", marginTop: "140px", fontSize: "12px" }}
                        >
                            <b>{props.contrat?.producteur?.agence?.nom ? `${props.contrat?.producteur?.agence?.nom} ${props.contrat?.producteur?.agence?.ville}` : "---"}</b>
                        </h6>
                        <h6
                            className="text-left"
                            style={{ position: 'absolute', marginLeft: "-95px", marginTop: "155px", fontSize: "12px" }}
                        >
                            <b>{props.engin?.numeroChassis || "---"}</b>
                        </h6>
                        <h6
                            className="text-left"
                            style={{ position: 'absolute', marginLeft: "-70px", marginTop: "174px", fontSize: "12px" }}
                        >
                            <b>{props.contrat?.numeroPoliceM || ""}</b>
                        </h6>
                        <h6
                            className="text-left"
                            style={{ position: 'absolute', marginLeft: "-90px", marginTop: "198px", fontSize: "12px" }}
                        >
                            <b>{frenchDateOnly(props.contrat?.dateEffet)}</b>
                        </h6>
                        <h6
                            className="text-left"
                            style={{ position: 'absolute', marginLeft: "5px", marginTop: "197px", fontSize: "12px" }}
                        >
                            <b>{frenchDateOnly(props.contrat?.dateEcheance)}</b>
                        </h6>
                        <h6
                            className="text-left"
                            style={{ position: 'absolute', marginLeft: "-95px", marginTop: "237px", fontSize: "12px" }}
                        >
                            <b>{props.contrat?.engins[0]?.categorie?.nom}</b>
                        </h6>
                    </div>
                    <div className="col">
                        <h6
                            className="text-left"
                            style={{ position: 'absolute', marginLeft: "-30px", marginTop: "30px", fontSize: "12px" }}
                        >
                            <b>{frenchDateOnly(props.contrat?.dateEffet)}</b>
                        </h6>
                        <h6
                            className="text-left"
                            style={{ position: 'absolute', marginLeft: "94px", marginTop: "30px", fontSize: "12px" }}
                        >
                            <b>{frenchDateOnly(props.contrat?.dateEcheance)}</b>
                        </h6>
                        <h6
                            className="text-left"
                            style={{ position: 'absolute', marginLeft: "-257px", marginTop: "65px", fontSize: "12px" }}
                        >
                            <b>
                                {props.contrat?.souscripteur?.nom || "_"}{" "}
                                {props.contrat?.souscripteur?.prenom || ""}{" "}
                            </b>
                        </h6>
                        <h6 className="text-left" style={{ position: 'absolute', marginLeft: "-257px", marginTop: "80px", fontSize: "12px" }}>
                            <b>
                                {props.contrat?.souscripteur?.adresse || "-"}{" "}
                                {props.contrat?.souscripteur?.contact?.[0] || ""}
                            </b>
                        </h6>
                        <h6
                            className="text-left"
                            style={{ position: 'absolute', marginLeft: "-180px", marginTop: "110px", fontSize: "12px" }}
                        >
                            <b>{props.engin?.immatriculation || "_"}</b>
                        </h6>
                        <h6
                            className="text-left"
                            style={{ position: 'absolute', marginLeft: "-253px", marginTop: "145px", fontSize: "12px" }}
                        >
                            <b>
                                {`${props.contrat?.organisme?.raisonSociale} ${props.contrat?.organisme?.siegeSocial}`.substring(0, 36)}
                            </b>
                        </h6>
                        <h6
                            className="text-left"
                            style={{ position: 'absolute', marginLeft: "-253px", marginTop: "158px", fontSize: "12px" }}
                        >
                            <b>
                                {`${props.contrat?.organisme?.raisonSociale} ${props.contrat?.organisme?.siegeSocial}`.substring(36)}
                            </b>
                        </h6>
                        <h6
                            className="text-left"
                            style={{ position: 'absolute', marginLeft: "-210px", marginTop: "232px", fontSize: "12px" }}
                        >
                            <b>{props.engin?.categorie?.nom}</b>
                        </h6>
                        <h6
                            className="text-left"
                            style={{ position: 'absolute', marginLeft: "55px", marginTop: "52px", fontSize: "12px" }}
                        >
                            <b>{props.contrat?.numeroPoliceM || ""}</b>
                        </h6>
                        <h6
                            className="text-left"
                            style={{ position: 'absolute', marginLeft: "30px", marginTop: "70px", fontSize: "12px" }}
                        >
                            <b>{frenchDateOnly(props.contrat?.dateEffet)}</b>
                        </h6>
                        <h6
                            className="text-left"
                            style={{ position: 'absolute', marginLeft: "130px", marginTop: "70px", fontSize: "12px" }}
                        >
                            <b>{frenchDateOnly(props.contrat?.dateEcheance)}</b>
                        </h6>
                        <h6
                            className="text-left"
                            style={{ position: 'absolute', marginLeft: "-5px", marginTop: "105px", fontSize: "12px" }}
                        >
                            <b>{props.engin?.marque}</b>
                        </h6>

                        <h6
                            className="text-left"
                            style={{ position: 'absolute', marginLeft: "-5px", marginTop: "135px", fontSize: "12px" }}
                        >
                            <b>{props.engin?.numeroChassis}</b>
                        </h6>
                        <h6
                            className="text-left"
                            style={{ position: 'absolute', marginLeft: "-5px", marginTop: "160px", fontSize: "12px" }}
                        >
                            <b>{props.contrat?.producteur?.agence?.nom ? `${props.contrat?.producteur?.agence?.nom} ${props.contrat?.producteur?.agence?.ville}` : "POOL"}</b>
                        </h6>
                    </div>
                </div>
            </div>
        </div >
    );
});
AttestationCEDEAOToPrint.propTypes = {
    contrat: PropTypes.object,
    engin: PropTypes.object,
    printing: PropTypes.bool,
};
AttestationCEDEAOToPrint.displayName = "Attestation Moto";

export default AttestationCEDEAOToPrint;