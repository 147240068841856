import { ExclamationCircleFilled, PlusOutlined } from "@ant-design/icons";
import { Button, Modal, Table } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Footer from "../../../components/Footer";
import NavBar from "../../../components/NavBar";
import SideBar from "../../../components/SideBar";
import { API_URL, TOKEN } from "../../../variables/constants";
import AddEtablissement from "./AddEtablissement";

const { confirm } = Modal;

const Etablissements = () => {
  const [etablissements, setEtablissements] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedEtablissement, setSelectedEtablissement] = useState(null);

  useEffect(() => {
    axios
      .get(`${API_URL}/api/etablissement`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setEtablissements(res.data);
      })
      .catch((err) => {
        console.error(err);
        toast.error("Erreur lors du chargement des établissements");
      });
  }, []);

  const handleShow = (edit, etablissement = null) => {
    setEditMode(edit);
    setSelectedEtablissement(etablissement);
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);

  const showDeleteConfirm = (codeId) => {
    confirm({
      title: "Êtes-vous sûr de vouloir supprimer cet établissement?",
      icon: <ExclamationCircleFilled />,
      content: "Cette action est irréversible.",
      okText: "Oui",
      okType: "danger",
      cancelText: "Annuler",
      onOk() {
        deleteEtablissement(codeId);
      },
    });
  };

  const deleteEtablissement = (codeId) => {
    axios
      .delete(`${API_URL}/api/etablissement/${codeId}`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        setEtablissements(etablissements.filter((et) => et.codeId !== codeId));
        toast.success("Établissement supprimé avec succès");
      })
      .catch((err) => {
        console.error(err);
        toast.error("Erreur lors de la suppression de l'établissement");
      });
  };

  const columns = [
    {
      title: "Nom",
      dataIndex: "nom",
      key: "nom",
    },
    {
      title: "Type d'établissement",
      dataIndex: "typeEtablissement",
      key: "typeEtablissement",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <>
          <button
            type="button"
            className="btn btn-secondary btn-icon rounded-pill"
            onClick={() => handleShow(true, record)}
          >
            <i className="bx bx-edit-alt"></i>
          </button>
          <button
            type="button"
            className="btn btn-danger btn-icon rounded-pill"
            onClick={() => showDeleteConfirm(record.codeId)}
          >
            <i className="bx bx-trash"></i>
          </button>
        </>
      ),
    },
  ];

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <SideBar currentMenu={"ETABLISSEMENTS"} />

        <div className="layout-page">
          <NavBar />

          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              <Button
                type="primary"
                icon={<PlusOutlined />}
                style={{ marginBottom: 16 }}
                onClick={() => handleShow(false)}
              >
                Ajouter un établissement
              </Button>

              <div className="card">
                <Table
                  columns={columns}
                  dataSource={etablissements}
                  rowKey="codeId"
                  pagination={{ pageSize: 10 }}
                />
              </div>
            </div>

            <Footer />
          </div>
        </div>
      </div>

      <AddEtablissement
        show={showModal}
        handleClose={handleClose}
        editMode={editMode}
        selectedEtablissement={selectedEtablissement}
      />
    </div>
  );
};

export default Etablissements;
