import {Button, Modal, Select, Tag} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import GarantyBasedProvisionsDetails from "../GarantyBasedProvisionsDetails";
import {useState} from "react";
import ProvisionsModal from "../ProvisionsModal";

const EvaluationModal = ({
                             victime,
                             evalOpen,
                             evalLoading,
                             garanties,
                             setIsEvaluating
                         }) => {
    const [provisionsGarantiesModal, setProvisionsGarantiesModal] = useState(false);
    const [valueSelected, setValueSelected] = useState([]);
    const [selectedGaranties, setSelectedGaranties] = useState([]);
    const [evaluationObject, setEvaluationObject] = useState({
        garantieAffecte: {},
        victime: {},
        listPrejudices: []
    });
    const [listPrejudices, setListPrejudices] = useState([]);

    const prejudices = [
        {label: "Préjudice matériel (dommages aux biens)", value: "Préjudice matériel", codeId: 'PMT'},
        {label: "Préjudice corporel (dommages physiques)", value: "Préjudice corporel", codeId: 'PCL'},
        {label: "Préjudice moral (souffrance psychologique)", value: "Préjudice moral", codeId: 'PML'},
        {label: "Préjudice économique (pertes financières)", value: "Préjudice économique", codeId: 'PEQ'},
    ];

    const calculateTotalProvision = (evaluationObject) => {
        if (evaluationObject && evaluationObject.prejudices) {
            return evaluationObject.prejudices.reduce((total, current) => total + current.montantPrejudice, 0);
        }
        return 0;
    };
    const handleAddProvision = (guarantee) => {
        setProvisionsGarantiesModal(true);
        // Optionally, update the evaluation object or pass guarantee to modal
    };

    console.log(evaluationObject);

    return (
        <>
            <Modal
                open={evalOpen}
                confirmLoading={evalLoading}
                onOk={() => {
                    sessionStorage.setItem(`${evaluationObject.victime.personne.nom}-${evaluationObject.victime.personne.prenom}-${evaluationObject.garantieAffecte.codeId}`, JSON.stringify(evaluationObject));
                    setEvaluationObject({
                        garantieAffecte: {},
                        victime: {},
                        listPrejudices: []
                    });
                    setListPrejudices([]);
                    setIsEvaluating(false);
                }}
                onCancel={() => {
                    setEvaluationObject({
                        garantieAffecte: {},
                        victime: victime,
                        listPrejudices: []
                    })
                    setListPrejudices([]);
                    setIsEvaluating(false)
                }}
                title={`${victime?.personne?.nom || ""} ${victime?.personne?.prenom || ""}`}
                width={900}
            >
                {
                    Object.keys(evaluationObject.garantieAffecte).length === 0 && (
                        <div style={{marginBottom: 20}}>
                            <Select
                                value={valueSelected}
                                placeholder="Sélectionner des garanties"
                                options={garanties}
                                style={{width: "100%"}}
                                onChange={(_, obj) => {
                                    setEvaluationObject({
                                        garantieAffecte: obj.garantie,
                                        victime: victime,
                                        prejudices: []
                                    });
                                }}
                            />
                        </div>
                    )
                }
                {
                    Object.keys(evaluationObject.garantieAffecte).length !== 0 && (
                        <>
                            <div style={{marginBottom: 20}}>
                                <div style={{marginBottom: 10}}>
                                    <span>Provision Garantie <b>{evaluationObject?.garantieAffecte?.nom}</b> :</span>
                                    <Tag color="geekblue">
                                        <b>{calculateTotalProvision(evaluationObject) || 0} FCFA</b>
                                    </Tag>
                                </div>
                                <Button
                                    type="primary"
                                    icon={<PlusOutlined/>}
                                    style={{marginBottom: 10}}
                                    onClick={() => handleAddProvision(evaluationObject.garantieAffecte)}
                                >
                                    Nouvelle provision
                                </Button>
                                <GarantyBasedProvisionsDetails
                                    uniteEvaluation={evaluationObject}
                                />
                            </div>
                        </>
                    )
                }

                {selectedGaranties.map((sg, index) => (
                    <div key={index} style={{marginBottom: 20}}>
                        <div style={{marginBottom: 10}}>
                            <span>Provision Garantie <b>{sg.nom}</b> :</span>
                            <Tag color="geekblue">
                                <b>{calculateTotalProvision(sg) || 0} FCFA</b>
                            </Tag>
                        </div>
                        <Button
                            type="primary"
                            icon={<PlusOutlined/>}
                            style={{marginBottom: 10}}
                            onClick={() => handleAddProvision(sg)}
                        >
                            Nouvelle provision
                        </Button>
                        <GarantyBasedProvisionsDetails
                            uniteEvaluation={evaluationObject}
                        />
                    </div>
                ))}
            </Modal>
            <ProvisionsModal
                prejudices={prejudices}
                setProvisionsGarantiesModal={setProvisionsGarantiesModal}
                provisionsGarantiesModal={provisionsGarantiesModal}
                sousEvaluation={evaluationObject}
                listPrejudices={listPrejudices}
                setListPrejudices={setListPrejudices}
            />
        </>
    );
};

export default EvaluationModal;
