import {Card, List, Tag, Typography} from "antd";

const {Title, Text} = Typography;

const GarantyBasedProvisionsDetails = ({uniteEvaluation,}) => {
    console.log('uniteEvaluation ', uniteEvaluation.garantieAffecte)
    return (
        <Card
            title={`Détails des Provisions pour la garantie ${uniteEvaluation?.garantieAffecte?.nom} `}
            bordered={false}
            style={{
                margin: "20px",
                borderRadius: "10px",
                boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
                width: '90%'
            }}
        >
            <List
                dataSource={uniteEvaluation?.prejudices}
                renderItem={(item) => (
                    <List.Item>

                        <div style={{
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            padding: 10
                        }}>
                            <Text type="primary" style={{fontSize: "16px"}}>
                                {item.libellePrejudice}
                            </Text>
                            <Tag color="blue" style={{fontSize: "12px", padding: "5px 10px"}}>
                                {new Intl.NumberFormat("en-DE").format(item.montantPrejudice)} FCFA
                            </Tag>
                        </div>

                    </List.Item>
                )}
            />
        </Card>
    );
};

export default GarantyBasedProvisionsDetails
