import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { API_URL, TOKEN } from "../../../variables/constants";
import { toast } from "react-toastify";

const AddBanque = ({ show, handleClose, editMode, selectedBanque }) => {
    const [loading, setLoading] = useState(false);
    const [nom, setNom] = useState("");

    useEffect(() => {
        if (editMode && selectedBanque) {
            setNom(selectedBanque.libelleParametre || "");
        } else {
            setNom("");
        }
    }, [editMode, selectedBanque]);

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        const banqueData = {
            ...selectedBanque,
            libelleParametre: nom,
            typeParametre: "BANQUE",
        };

        const request = editMode
            ? axios.put(`${API_URL}/api/parametre`, banqueData, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                },
            })
            : axios.post(`${API_URL}/api/parametre`, banqueData, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                },
            });

        request
            .then(() => {
                toast.success(editMode ? "Banque modifiée avec succès" : "Banque ajoutée avec succès");
                handleClose();
            })
            .catch((err) => {
                console.error("Error saving banque:", err);
                toast.error("Erreur lors de l'enregistrement de la banque");
            })
            .finally(() => setLoading(false));
    };

    return (
        <Modal show={show} onHide={handleClose} size="lg" centered>
            <Modal.Body>
                <button
                    type="button"
                    className="btn-close btn-primary"
                    onClick={handleClose}
                    aria-label="Close"
                ></button>
                <div className="text-center mb-6">
                    <h4>{editMode ? "Modifier la" : "Ajouter une"} banque</h4>
                    <p>{editMode ? "Modifier la" : "Ajouter une nouvelle"} banque</p>
                </div>
                <form onSubmit={onSubmit}>
                    <div className="mb-3">
                        <label className="form-label">Nom de la banque</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Nom de la banque"
                            value={nom}
                            onChange={(e) => setNom(e.target.value)}
                        />
                    </div>
                    <div className="text-center">
                        <button type="submit" className="btn btn-primary" disabled={loading}>
                            {loading ? "Enregistrement..." : "Enregistrer"}
                        </button>
                        <button type="button" className="btn btn-secondary ms-3" onClick={handleClose}>
                            Annuler
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default AddBanque;
