import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { API_URL, TOKEN } from "../../../variables/constants";
import { toast } from "react-toastify";

const AddExercice = ({ show, handleClose, editMode, selectedExercice }) => {
  const [libelle, setLibelle] = useState("");
  const [annee, setAnnee] = useState("");
  const [commentaire, setCommentaire] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log("selectedExercice:", selectedExercice); // Log pour vérifier selectedExercice
    if (editMode && selectedExercice) {
      setLibelle(selectedExercice.libelleExercice || "");
      setAnnee(new Date(selectedExercice.annee).getFullYear().toString() || "");
      setCommentaire(selectedExercice.commentaire || "");
    } else {
      setLibelle("");
      setAnnee("");
      setCommentaire("");
    }
  }, [editMode, selectedExercice]);

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const exerciceData = {
      ...selectedExercice,
      libelleExercice: libelle,
      annee: `${annee}-01-01`,
      commentaire: commentaire,
    };
    console.log("Données envoyées:", exerciceData);

    const request = editMode
      ? axios.put(`${API_URL}/api/exercice`, exerciceData, {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
          },
        })
      : axios.post(`${API_URL}/api/exercice`, exerciceData, {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
          },
        });

    request
      .then(() => {
        toast.success(
          editMode
            ? "Exercice modifié avec succès"
            : "Exercice ajouté avec succès",
        );
        handleClose();
      })
      .catch((err) => {
        console.error("Erreur lors de l'enregistrement de l'exercice:", err);
        toast.error("Erreur lors de l'enregistrement de l'exercice");
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Body>
        <button
          type="button"
          className="btn-close btn-primary"
          onClick={handleClose}
          aria-label="Close"
        ></button>
        <div className="text-center mb-6">
          <h4>{editMode ? "Modifier l'" : "Ajouter un"} exercice</h4>
        </div>
        <form onSubmit={onSubmit}>
          <div className="mb-3">
            <label className="form-label">Libellé</label>
            <input
              type="text"
              className="form-control"
              value={libelle}
              onChange={(e) => setLibelle(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Année</label>
            <input
              type="number"
              className="form-control"
              value={annee}
              onChange={(e) => setAnnee(e.target.value)}
              min="2009"
              max="2030"
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Commentaire</label>
            <textarea
              className="form-control"
              value={commentaire}
              onChange={(e) => setCommentaire(e.target.value)}
            />
          </div>
          <div className="text-center">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}
            >
              {loading ? "Enregistrement..." : "Enregistrer"}
            </button>
            <button
              type="button"
              className="btn btn-secondary ms-3"
              onClick={handleClose}
            >
              Annuler
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddExercice;
