import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_URL, TOKEN } from "../../../variables/constants";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";

function AddSommeMin({ show, handleClose, editMode, selectedSommeMin }) {
  const [libelle, setLibelle] = useState("");
  const [valeur, setValeur] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (editMode && selectedSommeMin) {
      setLibelle(selectedSommeMin.libelleParametre || "");
      setValeur(selectedSommeMin.valeur || "");
    } else {
      setLibelle("");
      setValeur("");
    }
  }, [editMode, selectedSommeMin]);

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const sommeMinData = {
      ...selectedSommeMin, 
      libelleParametre: libelle,
      valeur: valeur,
      typeParametre: "SOMME_MIN", 
    };

    // Requête PUT (édition) ou POST (ajout)
    const request = editMode
      ? axios.put(
          `${API_URL}/api/parametre`,
          JSON.stringify(sommeMinData),
          {
            headers: {
              Authorization: `Bearer ${TOKEN}`,
              "Content-Type": "application/json",
            },
          }
        )
      : axios.post(
          `${API_URL}/api/parametre`,
          JSON.stringify(sommeMinData),
          {
            headers: {
              Authorization: `Bearer ${TOKEN}`,
              "Content-Type": "application/json",
            },
          }
        );

    request
      .then(() => {
        toast.success(
          editMode
            ? "Somme minimale du Sinitre à payer   modifiée avec succès"
            : "Somme minimale du sinistre à ajoutée avec succès"
        );
        // Réinitialisation des champs
        setLibelle("");
        setValeur("");
        // Fermer la modale
        handleClose();
      })
      .catch((err) => {
        console.error("Error:", err);
        toast.error(
          editMode ? "Erreur lors de la modification" : "Erreur lors de l'ajout"
        );
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Body>
        <button
          type="button"
          className="btn-close btn-primary"
          onClick={handleClose}
          aria-label="Close"
        ></button>
        <div className="text-center mb-6">
          <h4 className="address-title mb-2">
            {editMode ? "Modifier la" : "Ajouter une"} Somme minimale de sinistre à payer
          </h4>
          <p className="address-subtitle">
            {editMode ? "Modifier la" : "Ajouter une nouvelle"} somme   minimale de sinistre à payer
          </p>
        </div>

        <form
          id="addSommeMinForm"
          className="row g-12"
          onSubmit={onSubmit}
        >
          <div className="col-12 col-md-12">
            <label className="form-label" htmlFor="modalNatureActeLibelle">
              Libellé
            </label>
            <input
              type="text"
              id="modalNatureActeLibelle"
              onChange={(e) => setLibelle(e.target.value)}
              className="form-control"
              placeholder="Libellé"
              name="libelle"
              value={libelle}
            />
          </div>

          <div className="col-12 col-md-12">
            <label className="form-label" htmlFor="modalSommeMinValeur">
              Montant
            </label>
            <input
              type="number" 
              id="modalSommeMinValeur"
              onChange={(e) => setValeur(e.target.value)}
              className="form-control"
              placeholder="Montant"
              name="valeur"
              value={valeur}
            />
          </div>

          <div className="col-12 text-center">
            <button
              type="submit"
              className="btn btn-primary me-3"
              disabled={loading}
            >
              {loading && (
                <span
                  className="spinner-grow me-1"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              {loading ? "Enregistrement..." : "Enregistrer"}
            </button>
            <button
              type="reset"
              className="btn btn-label-secondary"
              onClick={handleClose}
            >
              Annuler
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default AddSommeMin;
