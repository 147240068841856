import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { API_URL, TOKEN } from "../../../variables/constants";

const AddCategorie = ({
  show,
  handleClose,
  editMode = false,
  selectedCategorie,
}) => {
  const [loading, setLoading] = useState(false);
  const [nom, setNom] = useState("");
  const [codeCategorie, setCodeCategorie] = useState("");
  const [commentaire, setCommentaire] = useState("");
  const [valeurVenaleMinimale, setValeurVenaleMinimale] = useState("");

  // Pré-remplir les champs en mode édition
  useEffect(() => {
    if (editMode && selectedCategorie) {
      setNom(selectedCategorie.nom || "");
      setCodeCategorie(selectedCategorie.codeCategorie || "");
      setCommentaire(selectedCategorie.commentaire || "");
      setValeurVenaleMinimale(selectedCategorie.valeurVenaleMinimale || "");
    } else {
      // Réinitialiser les champs si on est en mode ajout
      setNom("");
      setCodeCategorie("");
      setCommentaire("");
      setValeurVenaleMinimale("");
    }
  }, [editMode, selectedCategorie]);

  // Soumission du formulaire
  const onSubmit = (e) => {
    e.preventDefault();

    if (!nom || !codeCategorie || !valeurVenaleMinimale) {
      toast.error("Veuillez remplir tous les champs requis.");
      return;
    }
    setLoading(true);

    const categorieData = {
      ...selectedCategorie,
      nom,
      codeCategorie,
      commentaire,
      valeurVenaleMinimale: parseFloat(valeurVenaleMinimale),
    };

    const request = editMode
      ? axios.put(`${API_URL}/api/categorie`, JSON.stringify(categorieData), {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
          },
        })
      : axios.post(`${API_URL}/api/categorie`, JSON.stringify(categorieData), {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
          },
        });

    request
      .then((res) => {
        console.log(res.data);
        setLoading(false);
        window.location.reload();
        toast(
          editMode
            ? "Catégorie modifiée avec succès"
            : "Catégorie ajoutée avec succès",
        );
        handleClose();
      })
      .catch((err) => {
        console.log(err);
        toast(
          editMode
            ? "Erreur lors de la modification de la catégorie"
            : "Erreur lors de l'ajout de la catégorie",
        );
        setLoading(false);
      });
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Body>
        <button
          type="button"
          className="btn-close btn-primary"
          onClick={handleClose}
          aria-label="Close"
        ></button>
        <div className="text-center mb-4">
          <h4 className="address-title mb-2">
            {editMode ? "Modifier la catégorie" : "Ajouter une catégorie"}
          </h4>
          <p className="add-subtitle">
            {editMode
              ? "Modifier les détails de la catégorie"
              : "Ajouter une nouvelle catégorie"}
          </p>
        </div>
        <form onSubmit={onSubmit} className="row g-3">
          <div className="col-12">
            <label className="form-label">Nom</label>
            <input
              type="text"
              className="form-control"
              placeholder="Nom de la catégorie"
              value={nom}
              onChange={(e) => setNom(e.target.value)}
              required
            />
          </div>
          <div className="col-12">
            <label className="form-label">Code de la catégorie</label>
            <input
              type="text"
              className="form-control"
              placeholder="Code de la catégorie"
              value={codeCategorie}
              onChange={(e) => setCodeCategorie(e.target.value)}
              required
            />
          </div>
          <div className="col-12">
            <label className="form-label">Description</label>
            <textarea
              className="form-control"
              placeholder="Description de la catégorie"
              value={commentaire}
              onChange={(e) => setCommentaire(e.target.value)}
            />
          </div>
          <div className="col-12">
            <label className="form-label">Valeur vénale minimale</label>
            <input
              type="number"
              className="form-control"
              placeholder="Valeur vénale minimale"
              value={valeurVenaleMinimale}
              onChange={(e) => setValeurVenaleMinimale(e.target.value)}
              required
            />
          </div>
          <div className="col-12 text-center">
            <button
              type="submit"
              className="btn btn-primary me-2"
              disabled={loading}
            >
              {loading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : editMode ? (
                "Modifier"
              ) : (
                "Ajouter"
              )}
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleClose}
              disabled={loading}
            >
              Annuler
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddCategorie;
