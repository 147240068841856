import { ExclamationCircleFilled, PlusOutlined } from "@ant-design/icons";
import { Button, Modal, Table } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../../components/Footer";
import NavBar from "../../../components/NavBar";
import SideBar from "../../../components/SideBar";
import { API_URL, TOKEN } from "../../../variables/constants";
import AddDetailsReduction from "./AddDetailsReduction";
const { confirm } = Modal;

const DetailsReductions = () => {
  const [detailsReductions, setDetailsReductions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedReduction, setSelectedReduction] = useState("");
  const [editMode, setEditMode] = useState(false);

  const param = useParams();

  const handleShow = (codeId) => {
    const reduction = detailsReductions.find((red) => red.codeId === codeId);
    if (reduction) {
      console.log("Reduction trouvée :", reduction);
      setSelectedReduction(reduction);
    }
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setSelectedReduction("");
  };

  const handleAddOrUpdateSuccess = () => {
    handleClose();
    loadDetailsReductions();
  };

  const loadDetailsReductions = () => {
    axios
      .get(`${API_URL}/api/sous-garantie/reduction/all/${param.reductionId}`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log("Détails des réductions chargés :", res.data);
        setDetailsReductions(res.data);
      })
      .catch((err) => {
        console.error("Erreur lors du chargement des réductions :", err);
      });
  };

  useEffect(() => {
    loadDetailsReductions();
  }, [TOKEN]);

  useEffect(() => {
    loadDetailsReductions();
  }, [TOKEN]);

  const columns = [
    {
      title: "Nom",
      dataIndex: "nom",
      key: "nom",
      render: (_, record) => (
        <p>
          {record.tranche[0]
            ? `${record.nom} (${record.tranche[0]} - ${record.tranche[1]})`
            : `${record.nom} `}
        </p>
      ),
    },
    {
      title: "Description",
      dataIndex: "commentaire",
      key: "commentaire",
    },
    {
      title: "Taux",
      dataIndex: "taux",
      key: "taux",
      render: (_, record) => (
        <p>
          {record.tauxPCent
            ? `${record.tauxPCent}%`
            : record.tauxPMille
            ? `${record.tauxPMille}‰`
            : "-"}
        </p>
      ),
    },
    {
      title: "Réduction",
      dataIndex: "reduction",
      key: "reduction",
      render: (_, record) => <p>{record?.reduction?.nom}</p>,
    },
    {
      title: "Actions",
      key: "action",
      render: (_, record) => (
        <>
          <button
            type="button"
            className="btn btn-secondary btn-icon rounded-pill"
            onClick={() => handleShow(record.codeId)}
          >
            <i className="bx bx-edit-alt"></i>
          </button>
          <button
            type="button"
            className="btn btn-danger btn-icon rounded-pill"
            onClick={() => showDeleteConfirm(record.codeId)}
          >
            <i className="bx bx-trash"></i>
          </button>
        </>
      ),
    },
  ];

  const showDeleteConfirm = (reductionId) => {
    confirm({
      title: "Êtes-vous sûr de vouloir supprimer ce détail de réduction?",
      icon: <ExclamationCircleFilled />,
      content: "Cette action est irréversible.",
      okText: "Oui",
      okType: "danger",
      cancelText: "Annuler",
      onOk() {
        deleteDetailsReduction(reductionId);
      },
    });
  };

  const deleteDetailsReduction = (reductionId) => {
    axios
      .delete(`${API_URL}/api/sous-garantie/${reductionId}`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        toast.success("Suppression effectuée avec succès");
      })
      .catch((err) => {
        console.log("Erreur lors de la suppression :", err);
        toast.error("Erreur lors de la suppression");
      });
  };

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <SideBar currentMenu={"TARIFICATION"} />
        <div className="layout-page">
          <NavBar />
          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              <Button
                type="primary"
                icon={<PlusOutlined />}
                style={{ marginBottom: 16 }}
                onClick={() => handleShow(false)}
              >
                Ajouter un détail de réduction
              </Button>
              <div className="card">
                <Table
                  columns={columns}
                  dataSource={detailsReductions}
                  rowKey="codeId"
                  pagination={{ pageSize: 10 }}
                />
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>

      <AddDetailsReduction
        show={showModal}
        handleClose={handleClose}
        editMode={editMode}
        selectedReduction={selectedReduction}
        onSuccess={handleAddOrUpdateSuccess}
      />
    </div>
  );
};
export default DetailsReductions;
