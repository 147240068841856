// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-carousel .slick-prev,
.custom-carousel .slick-next {
    width: 50px;
    height: 50px;
    z-index: 1;
}

.custom-carousel .slick-prev:before,
.custom-carousel .slick-next:before {
    font-size: 30px;
    color: #ff7f50;
    /* Couleur orange */
}

.custom-carousel .slick-dots li button:before {
    font-size: 12px;
    color: #ff7f50;
    /* Couleur orange */
}

.custom-carousel .slick-dots li.slick-active button:before {
    color: #ff4500;
    /* Couleur orange foncé */
}

.custom-carousel .slick-dots {
    bottom: -30px;
    /* Positionner les points plus bas */
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/carousel.css"],"names":[],"mappings":"AAAA;;IAEI,WAAW;IACX,YAAY;IACZ,UAAU;AACd;;AAEA;;IAEI,eAAe;IACf,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,oCAAoC;AACxC","sourcesContent":[".custom-carousel .slick-prev,\n.custom-carousel .slick-next {\n    width: 50px;\n    height: 50px;\n    z-index: 1;\n}\n\n.custom-carousel .slick-prev:before,\n.custom-carousel .slick-next:before {\n    font-size: 30px;\n    color: #ff7f50;\n    /* Couleur orange */\n}\n\n.custom-carousel .slick-dots li button:before {\n    font-size: 12px;\n    color: #ff7f50;\n    /* Couleur orange */\n}\n\n.custom-carousel .slick-dots li.slick-active button:before {\n    color: #ff4500;\n    /* Couleur orange foncé */\n}\n\n.custom-carousel .slick-dots {\n    bottom: -30px;\n    /* Positionner les points plus bas */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
