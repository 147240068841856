import SideBar from "../../components/SideBar";
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";
import React, {useEffect, useState} from "react";
import {getAllEvaluationsWithSinistre, rejectEvaluation, validateEvaluation} from "../../hooks/useHooks";
import InformationCard from "../../components/statistics/InformationCard";
import {DatePicker, Dropdown, Menu, Modal, Spin, Table, Tag} from "antd";
import {EllipsisOutlined} from "@ant-design/icons";
import {frenchDate} from "../../variables/functions";
import {toast} from "react-toastify";
import EvaluationDetailsModal from "./components/modals/EvaluationDetailsModal";

const {confirm} = Modal

const {RangePicker} = DatePicker;

const ValidationEvaluations = () => {

    const [evaluations, setEvaluations] = useState([]);
    const [evaluationsLoading, setEvaluationsLoading] = useState(false);
    const [filteredEvaluations, setFilteredEvaluations] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [refetch, setRefetch] = useState(false)

    useEffect(() => {
        const fetchAllEvaluations = async () => {
            setEvaluationsLoading(true);
            const response = await getAllEvaluationsWithSinistre();
            setEvaluations(response.data);
            setFilteredEvaluations(response.data);
            setEvaluationsLoading(false);
            console.log(refetch)
        };
        fetchAllEvaluations();
    }, []);
    console.log(refetch)

    const [selectedDetailsOpen, setSelectedDetailsOpen] = useState(false)
    const [selectedDetails, setSelectedDetails] = useState({})
    const handleDetailsModal = (record) => {
        setSelectedDetailsOpen((prevState) => !prevState)
        const selectedEvaluation = evaluations?.filter((ev) => ev?.evaluation.codeId === record.key)[0]
        console.log(selectedEvaluation)
        setSelectedDetails(selectedEvaluation)
    }
    const handleDetailsClose = () => {
        setSelectedDetailsOpen(true)
        setSelectedDetails({})
    }
    const onlyEvaluations = evaluations?.map((ev) => ev?.evaluation);

    const evaluationsValide = onlyEvaluations?.filter(evaluation => evaluation?.etatEvaluation === 'VALIDE');
    const evaluationEnAttente = onlyEvaluations?.filter(evaluation => evaluation?.etatEvaluation === 'EN_ATTENTE');
    const evaluationRejete = onlyEvaluations?.filter(evaluation => evaluation?.etatEvaluation === 'REJETE');

    const evaluationValideCardInfo = {
        container: 'col-sm-6 col-xl-4',
        label: 'Evaluations validées',
        value: evaluationsValide?.length || 0,
        description: 'Nombre total des évaluations validées',
        avatar: 'bx bx-check-circle',
        state: 'bg-label-success'
    };

    const evaluationEnAttenteCardInfo = {
        container: 'col-sm-6 col-xl-4',
        label: 'Evaluations en attente',
        value: evaluationEnAttente?.length || 0,
        description: 'Nombre total des évaluations en attente',
        avatar: 'bx bxs-hourglass',
        state: 'bg-label-warning'
    };

    const evaluationRejeteCardInfo = {
        container: 'col-sm-6 col-xl-4',
        label: 'Evaluations rejetées',
        value: evaluationRejete?.length || 0,
        description: 'Nombre total des évaluations rejetées',
        avatar: 'bx bx-message-alt-x',
        state: 'bg-label-danger'
    };
    console.log(evaluations)

    const source = evaluations?.map((ev) => {
        const {sinistreNumSinistre, sinistreNumPolice, sinistreSouscripteur, evaluation} = ev;
        const {codeId, ancienneProvision, nouvelleProvision, createdAt, etatEvaluation} = evaluation || {};

        if (!sinistreNumSinistre && !sinistreNumPolice && !sinistreSouscripteur && !ancienneProvision && !nouvelleProvision) {
            return null;
        }

        return {
            key: codeId,
            numSinistre: sinistreNumSinistre,
            numPolice: sinistreNumPolice,
            souscripteur: sinistreSouscripteur,
            ancienneProvision,
            nouvelleProvision,
            etatEvaluation,
            dateEvaluation: frenchDate(createdAt)
        };
    }).filter(item => item !== null);

    const columns = [
        {
            title: 'Numero de sinistre',
            dataIndex: 'numSinistre',
            key: 'numSinistre',
        },
        {
            title: 'Numero de police',
            dataIndex: 'numPolice',
            key: 'numPolice',
        },
        {
            title: 'Souscripteur',
            dataIndex: 'souscripteur',
            key: 'souscripteur',
        },
        {
            title: 'Ancienne provision',
            dataIndex: 'ancienneProvision',
            key: 'ancienneProvision',
        },
        {
            title: 'Nouvelle provision',
            dataIndex: 'nouvelleProvision',
            key: 'nouvelleProvision',
        },
        {
            title: 'Etat',
            dataIndex: 'etatEvaluation',
            key: 'etatEvaluation',
            render: (etat) => {
                let color;
                switch (etat) {
                    case 'VALIDE':
                        color = 'green';
                        break;
                    case 'EN_ATTENTE':
                        color = 'orange';
                        break;
                    case 'REJETE':
                        color = 'red';
                        break;
                    default:
                        color = 'gray';
                }
                return <Tag color={color}>{etat}</Tag>;
            }
        },
        {
            title: 'Date de l\'evaluation',
            dataIndex: 'dateEvaluation',
            key: 'dateEvaluation',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => {
                const menu = (
                    <Menu>
                        <Menu.Item key="valider" icon={<i className="bx bx-check"></i>}
                                   onClick={() => handleValiderEvaluation(record)}>
                            Valider
                        </Menu.Item>
                        <Menu.Item key="rejeter" icon={<i className="bx bx-x"></i>}
                                   onClick={() => handleRejeterEvaluation(record)}>
                            Rejeter
                        </Menu.Item>
                        <Menu.Item key="details" onClick={() => handleDetailsModal(record)}
                                   icon={<i className="bx bx-show"
                                   ></i>}>
                            Voir les details
                        </Menu.Item>
                    </Menu>
                );

                return (
                    <Dropdown overlay={menu} trigger={["click"]}>
                        <EllipsisOutlined style={{cursor: "pointer"}}/>
                    </Dropdown>
                );
            },
        },
    ];
    console.log(selectedDetails)
    const handleDateFilter = (dates) => {
        if (!dates) {
            setFilteredEvaluations(evaluations);
            return;
        }
        const [start, end] = dates.map(date => date.startOf('day').toDate());

        setFilteredEvaluations(
            evaluations.filter(ev => {
                const createdAt = ev?.evaluation?.createdAt;
                if (!createdAt) return false;
                const evaluationDate = new Date(createdAt);
                return evaluationDate >= start && evaluationDate <= end;
            })
        );
    };
    const confirmColumns = [
        {
            title: 'Numéro de sinistre',
            dataIndex: 'numSinistre',
            key: 'numSinistre',
        },
        {
            title: 'Numéro de police',
            dataIndex: 'numPolice',
            key: 'numPolice',
        },
        {
            title: 'Souscripteur',
            dataIndex: 'souscripteur',
            key: 'souscripteur',
        },
        {
            title: 'Nouvelle provision',
            dataIndex: 'nouvelleProvision',
            key: 'nouvelleProvision',
        },
    ]
    const [updateEvaluationLoading, setUpdateEvaluationLoading] = useState(false)
    const findEvaluationByCodeId = (codeId) => {
        return onlyEvaluations?.filter((ev) => ev?.codeId === codeId)[0]
    }

    console.log(selectedDetailsOpen)

    const handleValiderEvaluation = (record) => {
        confirm({
            title: 'Voulez-vous vraiment valider cette évaluation ?',
            icon: <i className="bx bx-check-circle" style={{color: 'green', fontSize: '24px'}}></i>,

            width: 800,
            content: (
                <div>
                    <Table
                        dataSource={[record]}
                        columns={confirmColumns}
                        pagination={false}
                        bordered
                    />
                </div>
            ),
            onOk() {
                try {
                    console.log('Évaluation validée:', record);
                    console.log(findEvaluationByCodeId(record.key))
                    const evaluation = findEvaluationByCodeId(record.key)
                    evaluation.etatEvaluation = 'VALIDE'
                    validateEvaluation(evaluation?.codeId)
                    setRefetch((prev) => !prev);
                    toast.success('Évaluation validée avec succès')
                } catch (e) {
                    console.log(e)
                    toast.error('Erreur lors de la validation de l\'évaluation')
                }

            },
            onCancel() {
                console.log('Validation annulée');
            },
        });
    };
    const handleRejeterEvaluation = (record) => {
        confirm({
            title: 'Voulez-vous vraiment rejeter cette évaluation ?',
            icon: <i className="bx bx-x-circle" style={{color: 'darkred', fontSize: '24px'}}></i>,
            width: 800,
            content: (
                <div>
                    <Table
                        dataSource={[record]}
                        columns={confirmColumns}
                        pagination={false}
                        bordered
                    />
                </div>
            ),
            onOk() {
                try {
                    console.log('Évaluation rejetée:', record);
                    console.log(findEvaluationByCodeId(record.key))
                    const evaluation = findEvaluationByCodeId(record.key)
                    evaluation.etatEvaluation = 'REJETE'
                    rejectEvaluation(evaluation.codeId)
                    toast.success('Évaluation rejetée avec succès')
                    setRefetch((prev) => !prev);
                } catch (e) {
                    console.log(e)
                    toast.error('Erreur lors du rejet de l\'évaluation')
                } finally {
                    setRefetch(false)
                }
            },
            onCancel() {
                console.log('Validation annulée');
            },
        });
    };

    return (
        <div className="layout-wrapper layout-content-navbar  ">
            <div className="layout-container">
                <SideBar currentMenu={"SINISTRES"}/>
                {/* <!-- Layout container --> */}
                <div className="layout-page">
                    <NavBar/>
                    {/* <!-- Content wrapper --> */}
                    <div className="content-wrapper">
                        {/* <!-- Content --> */}
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <div className={'container'}>
                                <div className={'container'}>
                                    <Spin spinning={evaluationsLoading}>
                                        <div className="row g-6 mb-6">
                                            <InformationCard cardInfo={evaluationValideCardInfo}/>
                                            <InformationCard cardInfo={evaluationEnAttenteCardInfo}/>
                                            <InformationCard cardInfo={evaluationRejeteCardInfo}/>
                                        </div>
                                    </Spin>
                                    <Spin spinning={evaluationsLoading}>
                                        <RangePicker onChange={handleDateFilter} style={{marginBottom: '16px'}}/>
                                        <Table
                                            dataSource={source}
                                            columns={columns}
                                            pagination={{
                                                showSizeChanger: true,
                                                onShowSizeChange: (_, size) => setPageSize(size),
                                                pageSize: pageSize
                                            }}
                                        />
                                    </Spin>
                                    {
                                        selectedDetails ? (
                                                <>
                                                    <EvaluationDetailsModal
                                                        isVisible={selectedDetailsOpen}
                                                        onClose={() => setSelectedDetailsOpen(false)}
                                                        evaluationData={selectedDetails}
                                                    />
                                                </>
                                            ) :
                                            null

                                    }


                                </div>
                            </div>

                        </div>
                        {/* <!-- / Content --> */}

                        {/* <!-- Footer --> */}
                        <Footer/>
                        {/* <!-- / Footer --> */}

                        <div className="content-backdrop fade"></div>
                    </div>
                    {/* <!-- Content wrapper --> */}
                </div>
                {/* <!-- / Layout page --> */}
            </div>

            {/* <!-- Overlay --> */}
            <div className="layout-overlay layout-menu-toggle"></div>

            {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
            <div className="drag-target"></div>
        </div>
    );
};

export default ValidationEvaluations;
