// axiosMultiPartDataInstance.js
import axios from "axios";
import { API_URL, TOKEN } from "../constants";

const axiosMultiPartDataInstance = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

axiosMultiPartDataInstance.interceptors.request.use(
  (config) => {
    if (TOKEN) {
      config.headers.Authorization = `Bearer ${TOKEN}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default axiosMultiPartDataInstance;
