// Banques.js
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, Modal as AntModal } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Footer from "../../../components/Footer";
import NavBar from "../../../components/NavBar";
import SideBar from "../../../components/SideBar";
import { API_URL, TOKEN } from "../../../variables/constants";
import AddBanque from "./AddBanque";

const Banques = () => {
    const [banques, setBanques] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [selectedBanque, setSelectedBanque] = useState(null);

    const handleShow = (edit = false, banque = null) => {
        setShowModal(true);
        setEditMode(edit);
        setSelectedBanque(banque);
    };

    const handleClose = () => {
        setShowModal(false);
        setEditMode(false);
        setSelectedBanque(null);
    };


    useEffect(() => {
        axios
            .get(`${API_URL}/api/parametre/type/BANQUE`, {
                headers: { Authorization: `Bearer ${TOKEN}`, "Content-Type": "application/json" },
            })
            .then((res) => setBanques(res.data))
            .catch((err) => console.error("Error fetching banques:", err));
    }, [showModal]);

    const handleDeleteBanque = (parametreId) => {
        if (!parametreId) {
            console.error("No banque ID provided for deletion.");
            return;
        }

        AntModal.confirm({
            title: "Êtes-vous sûr de vouloir supprimer cette banque ?",
            content: "Cette action est irréversible",
            okText: "Oui",
            okType: "danger",
            cancelText: "Annuler",
            onOk: () => {
                axios
                    .delete(`${API_URL}/api/parametre/${parametreId}`, {
                        headers: { Authorization: `Bearer ${TOKEN}` },
                    })
                    .then(() => {
                        setBanques((prev) => prev.filter((banque) => banque.parametreId !== parametreId));
                        toast.success("Banque supprimée avec succès.");
                    })
                    .catch((err) => {
                        console.error("Error deleting banque:", err);
                        toast.error("Erreur lors de la suppression de la banque");
                    });
            },
        });
    };

    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <SideBar currentMenu="AUTRES_PARAMETRES" />
                <div className="layout-page">
                    <NavBar />
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <Button
                                type="primary"
                                icon={<PlusOutlined />}
                                style={{ marginBottom: 16 }}
                                onClick={() => handleShow()}
                            >
                                Ajouter une nouvelle banque
                            </Button>
                            <div className="card">
                                <h5 className="card-header">Liste des banques</h5>
                                <div className="table-responsive text-nowrap">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Nom</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody className="table-border-bottom-0">
                                            {banques.map((banque) => (
                                                <tr key={banque.parametreId}>
                                                    <td>{banque.libelleParametre}</td>
                                                    <td>
                                                        <Dropdown
                                                            overlay={
                                                                <Menu>
                                                                    <Menu.Item icon={<EditOutlined />} onClick={() => handleShow(true, banque)}>
                                                                        Modifier
                                                                    </Menu.Item>
                                                                    <Menu.Item
                                                                        icon={<DeleteOutlined />}
                                                                        danger
                                                                        onClick={() => handleDeleteBanque(banque.parametreId)}
                                                                    >
                                                                        Supprimer
                                                                    </Menu.Item>
                                                                </Menu>
                                                            }
                                                            trigger={["click"]}
                                                        >
                                                            <Button type="text" icon={<PlusOutlined />} />
                                                        </Dropdown>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>

            <AddBanque
                show={showModal}
                handleClose={handleClose}
                editMode={editMode}
                selectedBanque={selectedBanque}
            />
        </div>
    );
};

export default Banques;
