import { Modal, Select } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { API_URL, TOKEN } from "../../../variables/constants";

const { Option } = Select;

const AddDetailsReduction = ({
  show,
  handleClose,
  editMode,
  selectedReduction,
}) => {
  const [nom, setNom] = useState("");
  const [primeNette, setPrimeNette] = useState("");
  const [tauxPCent, setTauxPCent] = useState("");
  const [tauxPMille, setTauxPMille] = useState("");
  const [trancheMin, setTrancheMin] = useState("");
  const [trancheMax, setTrancheMax] = useState("");
  const [garanties, setGaranties] = useState([]);
  const [selectedGaranties, setSelectedGaranties] = useState([]);
  const [commentaire, setCommentaire] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (show && selectedReduction) {
      console.log("la data", selectedReduction);

      setNom(selectedReduction.nom || "");
      setPrimeNette(selectedReduction.primeNette || "");
      setTauxPCent(selectedReduction.tauxPCent || "");
      setTauxPMille(selectedReduction.tauxPMille || "");
      setTrancheMin(
        selectedReduction.tranche ? selectedReduction.tranche[0] : "",
      );
      setTrancheMax(
        selectedReduction.tranche ? selectedReduction.tranche[1] : "",
      );
      setSelectedGaranties(
        selectedReduction.garanties
          ? selectedReduction.garanties.map((g) => g.id)
          : [],
      );
      setCommentaire(selectedReduction.commentaire || "");
    } else if (!editMode) {
      setNom("");
      setPrimeNette("");
      setTauxPCent("");
      setTauxPMille("");
      setTrancheMin("");
      setTrancheMax("");
      setSelectedGaranties([]);
      setCommentaire("");
    }
  }, [show, selectedReduction, editMode]);

  useEffect(() => {
    const fetchGaranties = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/garantie`, {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
          },
        });
        setGaranties(response.data);
      } catch (error) {
        console.error("Erreur lors du chargement des garanties :", error);
      }
    };
    fetchGaranties();
  }, []);

  const handleChange = (value) => {
    console.log(`selected ${value}`);
    setSelectedGaranties(value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const selectedGarantiesObjects = selectedGaranties.map((id) =>
      garanties.find((gar) => gar.id === id),
    );
    const reductionData = {
      ...selectedReduction,
      nom,
      primeNette,
      tauxPCent,
      tauxPMille,
      tranche: [trancheMin, trancheMax],
      garanties: selectedGarantiesObjects,
      commentaire,
    };

    console.log("Données de réduction à soumettre :", reductionData);

    try {
      if (editMode) {
        console.log("Envoi de la requête PUT pour la mise à jour...");
        await axios.put(`${API_URL}/api/sous-garantie/`, reductionData, {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
          },
        });
        toast.success("Réduction modifiée avec succès");
      } else {
        console.log("Envoi de la requête POST pour l'ajout...");
        await axios.post(`${API_URL}/api/sous-garantie`, reductionData, {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
          },
        });
        toast.success("Réduction ajoutée avec succès");
      }
      console.log("Requête réussie, fermeture du modal...");
      handleClose();
    } catch (error) {
      console.error("Erreur lors de l'opération :", error);
      toast.error("Erreur lors de l'opération.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={show} onCancel={handleClose} footer={null} centered>
      <h3 className="text-center mb-4">Ajouter Détails Réduction</h3>
      <form onSubmit={onSubmit}>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="nom">Nom</label>
              <input
                type="text"
                id="nom"
                className="form-control"
                placeholder="Nom de la réduction"
                value={nom}
                onChange={(e) => setNom(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="tauxPCent">Taux (%)</label>
              <input
                type="number"
                id="tauxPCent"
                className="form-control"
                placeholder="Taux en pourcentage"
                value={tauxPCent}
                onChange={(e) => setTauxPCent(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="trancheMin">Tranche Min</label>
              <input
                type="number"
                id="trancheMin"
                className="form-control"
                placeholder="Tranche Min"
                value={trancheMin}
                onChange={(e) => setTrancheMin(e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="primeNette">Prime Nette</label>
              <input
                type="number"
                id="primeNette"
                className="form-control"
                placeholder="Prime Nette"
                value={primeNette}
                onChange={(e) => setPrimeNette(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="tauxPMille">Taux (‰)</label>
              <input
                type="number"
                id="tauxPMille"
                className="form-control"
                placeholder="Taux en pour mille"
                value={tauxPMille}
                onChange={(e) => setTauxPMille(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="trancheMax">Tranche Max</label>
              <input
                type="number"
                id="trancheMax"
                className="form-control"
                placeholder="Tranche Max"
                value={trancheMax}
                onChange={(e) => setTrancheMax(e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="garantie">Garanties appliquées</label>
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                placeholder="Sélectionnez les garanties"
                onChange={handleChange}
                value={selectedGaranties}
                options={garanties.map((gar) => ({
                  label: gar.nom,
                  value: gar.codeId,
                }))}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="description">Commentaire</label>
              <textarea
                id="commentaire"
                className="form-control"
                placeholder="Description de la  sous réduction"
                value={commentaire}
                onChange={(e) => setCommentaire(e.target.value)}
                required
              />
            </div>
          </div>
        </div>
        <div className="text-center mt-4">
          <button type="submit" className="btn btn-primary" disabled={loading}>
            {loading ? "Enregistrement..." : "Enregistrer"}
          </button>
          <button
            type="button"
            className="btn btn-secondary ms-2"
            onClick={handleClose}
          >
            Annuler
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default AddDetailsReduction;
