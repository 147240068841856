import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { API_URL } from "../../variables/constants";
import { toast } from "react-toastify";
import axiosInstance from "../../variables/api/axiosInstance";
import axiosMultiPartDataInstance from "../../variables/api/axiosMultiPartDatainstance";

const AddMembres = ({ show, handleClose, editMode, selectedMembre }) => {
  const [raisonSociale, setRaisonSociale] = useState("");
  const [siegeSocial, setSiegeSocial] = useState("");
  const [code, setCode] = useState("");
  const [dateCreation, setDateCreation] = useState("");
  const [email, setEmail] = useState("");
  const [telephone1, setTelephone1] = useState("");
  const [telephone2, setTelephone2] = useState("");
  const [typeMembre, setTypeMembre] = useState("ASSURANCE");
  const [responsable, setResponsable] = useState("");
  const [otpConnexion, setOtpConnexion] = useState("NONE");
  const [domaine, setDomaine] = useState("");
  const [website, setWebsite] = useState("");
  const [defaultExercice, setDefaultExercice] = useState("");
  const [exercices, setExercices] = useState([]);
  const [fileImage, setFileImage] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (editMode && selectedMembre && selectedMembre.organismeId) {
      axiosInstance
        .get(`/api/organisme/${selectedMembre.organismeId}`)
        .then((response) => {
          const organisme = response.data;
          setRaisonSociale(organisme.raisonSociale);
          setSiegeSocial(organisme.siegeSocial);
          setCode(organisme.code);
          setDateCreation(organisme.dateCreation);
          setEmail(organisme.email);
          setTelephone1(organisme.contact ? organisme.contact[0] : "");
          setTelephone2(organisme.contact ? organisme.contact[1] : "");
          setTypeMembre(organisme.typeOrganisme);
          setResponsable(organisme.responsable);
          setOtpConnexion(organisme.otpConnexion);
          setDomaine(organisme.domaine);
          setWebsite(organisme.website);

          if (organisme.logo) {
            const logoUrl = `${API_URL}/api/organisme/logo/${
              organisme.logo.split("//")[2]
            }`;
            setImagePreviewUrl(logoUrl);
          }
        })
        .catch((error) => {
          console.error("Erreur lors de la récupération des données :", error);
          toast.error("Erreur lors du chargement des informations du membre.");
        });
    }
  }, [editMode, selectedMembre]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFileImage(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreviewUrl(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (
      !raisonSociale ||
      !siegeSocial ||
      !code ||
      !dateCreation ||
      !email ||
      !telephone1
    ) {
      toast.error("Veuillez remplir tous les champs obligatoires.");
      setLoading(false);
      return;
    }

    const organisme = {
      ...selectedMembre,
      raisonSociale,
      siegeSocial,
      code,
      dateCreation,
      contact: [telephone1, telephone2].filter(Boolean),
      email,
      responsable,
      typeOrganisme: typeMembre,
      otpConnexion,
      domaine,
      website,
      active: true,
    };

    try {
      let response;
      if (editMode && selectedMembre && selectedMembre.organismeId) {
        // Création d'un nouvel objet à partir de selectedMembre (au cas où certaines props viendraient de l'extérieur)
        const organismeToUpdate = {
          ...selectedMembre,
          raisonSociale,
          siegeSocial,
          code,
          dateCreation,
          email,
          responsable,
          typeOrganisme: typeMembre,
          otpConnexion,
          domaine,
          website,
          active: true,
          contact: [telephone1, telephone2],
        };

        // Mettre à jour l'organisme (mode édition)
        response = await axiosInstance.put(`/api/organisme`, organismeToUpdate);
        console.log("Réponse API PUT :", response.data);
        toast.success("Membre mis à jour avec succès !");
      } else {
        console.log("Mode ajout, création d'un nouveau membre.");
        response = await axiosInstance.post(`/api/organisme`, organisme);
        console.log("Réponse API POST :", response.data);
        toast.success("Nouveau membre ajouté avec succès !");
      }

      if (fileImage) {
        console.log("Téléversement de l'image :", fileImage);
        const formData = new FormData();
        const extension = fileImage.name.split(".").pop();
        const filename = `Logo_${raisonSociale
          .split(" ")
          .join("-")}.${extension}`;
        formData.append("fichier", fileImage, filename);

        const imageResponse = await axiosMultiPartDataInstance.post(
          `/api/organisme/logo`,
          formData,
        );
        console.log("Réponse API pour le logo :", imageResponse.data);
      }

      handleClose();
    } catch (error) {
      console.error("Erreur lors de la soumission :", error);
      toast.error("Erreur lors de l'enregistrement des données.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {editMode ? "Modifier un membre" : "Ajouter un membre"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-6 col-md-6 mb-3">
              <label className="form-label">Raison sociale</label>
              <input
                type="text"
                className="form-control"
                value={raisonSociale}
                onChange={(e) => setRaisonSociale(e.target.value)}
                required
              />
            </div>
            <div className="col-6 col-md-6 mb-3">
              <label className="form-label">Siège social</label>
              <input
                type="text"
                className="form-control"
                value={siegeSocial}
                onChange={(e) => setSiegeSocial(e.target.value)}
                required
              />
            </div>
            <div className="col-6 col-md-6 mb-3">
              <label className="form-label">Code du membre</label>
              <input
                type="text"
                className="form-control"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                required
              />
            </div>
            <div className="col-6 col-md-6 mb-3">
              <label className="form-label">Date de création</label>
              <input
                type="date"
                className="form-control"
                value={dateCreation}
                onChange={(e) => setDateCreation(e.target.value)}
                required
              />
            </div>
            <div className="col-6 col-md-6 mb-3">
              <label className="form-label">Adresse email</label>
              <input
                type="email"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="col-6 col-md-6 mb-3">
              <label className="form-label">Numéro de téléphone 1</label>
              <input
                type="tel"
                className="form-control"
                value={telephone1}
                onChange={(e) => setTelephone1(e.target.value)}
                required
              />
            </div>
            <div className="col-6 col-md-6 mb-3">
              <label className="form-label">Numéro de téléphone 2</label>
              <input
                type="tel"
                className="form-control"
                value={telephone2}
                onChange={(e) => setTelephone2(e.target.value)}
              />
            </div>
            <div className="col-6 col-md-6 mb-3">
              <label className="form-label">Type membre</label>
              <select
                className="form-select"
                value={typeMembre}
                onChange={(e) => setTypeMembre(e.target.value)}
                required
              >
                <option value="">-- Choisir un type --</option>
                <option value="ASSURANCE">ASSURANCE</option>
                <option value="PARTENAIRE">PARTENAIRE</option>
                <option value="CONCESSIONNAIRE">CONCESSIONNAIRE</option>
              </select>
            </div>
            <div className="col-6 col-md-6 mb-3">
              <label className="form-label">Responsable</label>
              <input
                type="text"
                className="form-control"
                value={responsable}
                onChange={(e) => setResponsable(e.target.value)}
              />
            </div>
            <div className="col-6 col-md-6 mb-3">
              <label className="form-label">OTP Connexion</label>
              <select
                className="form-select"
                value={otpConnexion}
                onChange={(e) => setOtpConnexion(e.target.value)}
              >
                <option value="NONE">Désactiver</option>
                <option value="SMS">SMS</option>
                <option value="EMAIL">EMAIL</option>
                <option value="WHATSAPP">WHATSAPP</option>
              </select>
            </div>
            <div className="col-6 col-md-6 mb-3">
              <label className="form-label">Nom de domaine</label>
              <input
                type="text"
                className="form-control"
                value={domaine}
                onChange={(e) => setDomaine(e.target.value)}
              />
            </div>
            <div className="col-6 col-md-6 mb-3">
              <label className="form-label">Site web</label>
              <input
                type="text"
                className="form-control"
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
              />
            </div>
            <div className="col-12 mb-3">
              {imagePreviewUrl && (
                <img
                  src={imagePreviewUrl}
                  alt="Prévisualisation"
                  className="img-fluid mb-3"
                />
              )}
              <label className="form-label">Logo</label>
              <input
                type="file"
                className="form-control"
                accept="image/png, image/jpeg"
                onChange={handleImageChange}
              />
            </div>
          </div>
          <div className="text-center">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}
            >
              {loading ? "En cours..." : editMode ? "Mettre à jour" : "Ajouter"}
            </button>
            <button
              type="button"
              className="btn btn-secondary ms-3"
              onClick={handleClose}
            >
              Annuler
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddMembres;
