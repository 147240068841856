import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";


// ANT.DESIGN
import {Input, message, Radio, Select, Typography, Upload} from "antd";
import {InboxOutlined, PlusOutlined} from "@ant-design/icons";
import {getSinistreById, recupererLesEntitesDeconstat, saveSinistreFile} from "../../../../hooks/useHooks";
import {
    errorStateChecker,
    formaterLesEntites,
    getIdentificateur,
    getvalue,
    setValue
} from "../../../../variables/functions";
import {useParams} from "react-router-dom";
import {useFormik} from "formik";
import {getAccidentValidationSchema} from "./form-validations/schemaValidations";
import {toast} from "react-toastify";

const {Dragger} = Upload
const {Title} = Typography

const {TextArea} = Input;

const initSinistre = {
    lieu: null, provenanceAssure: null, destinationAssure: null, vitesseAssure: null, temoignage: "non", temoin: {
        nom: null, prenom: null, adresse: null, fonction: null,
    }, circonstance: null, croquis: null, observationAgent: null, entiteConstat: {},
};


const InformationsSurAccident = forwardRef((props, ref) => {
    const [temoignage, setTemoignage] = useState(false)
    const [validationSchema, setValidationSchema] = useState()
    const [croquisFileUrl, setCroquisFileUrl] = useState('')
    const initialValues = {
        lieu: null,
        provenanceAssure: null,
        destinationAssure: null,
        vitesseAssure: null,
        temoignage: "non",
        temoin: {
            nom: null, prenom: null, temoin: null, adresse: null, fonction: null,
        },
        circonstance: null,
        croquis: sessionStorage.getItem('file'),
        observationAgent: null,
        entiteConstat: {},
    };

    const loadData = () => {
        console.log('hey ', temoignage)
        const vds = getAccidentValidationSchema(temoignage)
        setValidationSchema(vds)
    }

    useEffect(() => {
        loadData()
    }, [temoignage]);

    const formik = useFormik({
        initialValues: initialValues, validationSchema: validationSchema, onSubmit: (values) => {
            console.log("Form submitted:", values);
        },
    });
    console.log(formik.values.temoin.nom)
    console.log(formik.errors.temoin)
    console.log(temoignage)
    const {activeTab, setActiveTab} = props

    const getErrorMessagesOneByOne = (errorObject) => {
        const messages = [];

        const extractMessages = (obj) => {
            // Iterate over all keys in the object
            Object.values(obj).forEach((value) => {
                if (typeof value === "object" && value !== null) {
                    // If the value is an object, recursively extract its values
                    extractMessages(value);
                } else {
                    // Otherwise, add the value (error message) to the messages array
                    messages.push(value);
                }
            });
        };

        extractMessages(errorObject);
        return messages;
    };
    useImperativeHandle(ref, () => ({
        isValidated: async () => {
            try {
                console.log('chemical ', temoignage)
                const validationSchema = getAccidentValidationSchema(temoignage);

                // Extract fields to validate based on Formik values
                const fieldsToValidate = Object.keys(formik.values);

                // Construct a new object with the selected fields and their values
                const valuesToValidate = fieldsToValidate.reduce((acc, field) => {
                    acc[field] = formik.values[field];
                    return acc;
                }, {});

                // Validate the values against the schema
                await validationSchema.validate(valuesToValidate, {abortEarly: false});

                // If validation passes, proceed to the next tab
                setActiveTab(activeTab + 1);
            } catch (error) {
                // Check if validation errors exist
                if (error.inner && Array.isArray(error.inner)) {
                    // Iterate over each error and display it using toast
                    error.inner.forEach((err) => {
                        toast.error(err.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    });
                } else {
                    console.error("Unexpected validation error:", error);
                }
            }
        }, sendState: () => {
            sessionStorage.setItem('infoAcc', JSON.stringify(formik.values))

        }, state: {
            sinistre, fileList,
        },
    }));

    const handleFieldErrorToast = (fieldName) => {
        const fieldMeta = formik.getFieldMeta(fieldName);
        fieldMeta.touched = true
        if (fieldMeta.touched && fieldMeta.error) {
            toast.error(fieldMeta.error, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };


    const [sinistre, setSinistre] = useState(initSinistre);

    const [entites, setEntites] = useState([]);
    const [longitude, setLongitude] = useState(null);
    const [latitude, setLatitude] = useState(null);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");
    const [fileList, setFileList] = useState([]);
    const [entiteConstat, setEntiteConstat] = useState("");
    const [circonstance, setCirconstance] = useState("");

    useEffect(() => {
        recupererLesEntitesDeconstat()
            .then((response) => {
                // console.log(formaterLesEntites(response.data));
                setEntites(formaterLesEntites(response.data));
            })
            .catch((error) => {
                console.log(error);
                setEntites([]);
            });
    }, []);

    const getBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf("/") + 1));
    };

    const handleChangeFile = ({fileList: newFileList}) => setFileList(newFileList);

    const uploadButton = (<div>
        <PlusOutlined/>
        <div
            style={{
                marginTop: 8,
            }}
        >
            Sélectionnez un fichier
        </div>
    </div>);
    console.toLocaleString(formik.values.temoin.nom)

    const [informationsAccident, setInformationsAccident] = useState({
        lieu: null,
        heure: null,
        provenanceAssure: null,
        destinationAssure: null,
        vitesseAssure: null,
        temoin: "non",
        nomTemoin: null,
        prenomTemoin: null,
        adresseTemoin: null,
        qualiteTemoin: null,
        circonstance: null,
        croquis: null,
        observationAgent: null,
        entiteConstat: null,
    });

    const [errorState, setErrorState] = useState({
        lieu: null,
        heure: null,
        provenanceAssure: null,
        destinationAssure: null,
        vitesseAssure: null,
        nomTemoin: null,
        prenomTemoin: null,
        adresseTemoin: null,
        qualiteTemoin: null,
        circonstance: null,
        croquis: null,
        observationAgent: null,
        entiteConstat: null,
    });

    useEffect(() => {
        navigator.geolocation.getCurrentPosition((position) => {
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
            // console.log(position.coords.latitude, position.coords.longitude);
        });
        console.log(latitude, longitude);
    }, []);

    const handleChange = (e, name) => {
        if (e.target) {
            if (e.target.name.split(".").length > 1) {
                const a = e.target.name.split(".")[1];
                setSinistre({
                    ...sinistre.temoin, [a]: e.target.value,
                });
            } else {
                setSinistre({
                    ...sinistre, [e.target.name]: e.target.value,
                });
            }
        } else {
            setSinistre({
                ...sinistre, [name]: e,
            });
        }
    };
    console.log(formik.values.croquis)
    const param2 = useParams();
    useEffect(() => {
        if (param2.id) {
            getSinistreById(param2.id)
                .then((res) => {
                    setSinistre(res.data);
                    setEntiteConstat(res.data.entiteConstat);
                    setCirconstance(res.data.circonstance);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [param2.id]);
    console.log(formik.values)

    const draggerProps = {
        name: 'file', accept: 'image/*, .pdf', multiple: false, customRequest: async (options) => {
            const {file, onSuccess, onError, onProgress} = options;
            try {

                const response = await saveSinistreFile(file);
                console.log(response.data)
                await formik.setFieldValue('croquis', sessionStorage.getItem('file'))
                message.success(`${file.name} téléversé avec succès.`);
            } catch (error) {
                // Handle errors
                onError(error);
                message.error(`Échec de l'upload du fichier: ${file.name}`);
            }
        }, onChange(info) {
            const {status} = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                formik.setFieldValue('croquis', sessionStorage.getItem('file'))
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        }, onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };
    return (<div className={'grid-container grid container'} style={{
        justifyContent: 'center'
    }}>
        <Title style={{
            fontSize: '26px'
        }}> Informations sur l'accident </Title>
        <div className={'grid-item'}>
            <label
                htmlFor="lieu"
                style={{
                    margin: "30px 0 10px 0", color: errorState["lieu"] === true ? "red" : null,
                }}
            >
                Lieu de l&apos;accident:
            </label>
            <Input
                id="lieu"
                name="lieu"
                value={formik.values.lieu}
                placeholder="Lieu de l'accident"
                status={errorState["lieu"] === true ? "error" : null}
                onBlur={(e) => {
                    formik.handleBlur(e)
                    handleFieldErrorToast('lieu')

                }}
                onChange={(event) => {
                    formik.handleChange(event)
                    handleChange(event, "lieu");
                }}
            />
        </div>
        <div className={'grid-item'}>
            <label
                htmlFor="provenanceAssure"
                style={{
                    margin: "30px 0 10px 0", color: errorState["provenanceAssure"] === true ? "red" : null,
                }}
            >
                L&apos;engin de l&apos;assuré venant de:
            </label>
            <Input
                id="provenanceAssure"
                name="provenanceAssure"
                value={sinistre?.provenanceAssure}
                placeholder="Lieu de provenance de la voiture de l'assuré"
                status={errorState["provenanceAssure"] === true ? "error" : null}
                onBlur={(e) => {
                    formik.handleBlur(e)
                    handleFieldErrorToast('provenanceAssure')

                    errorStateChecker("provenanceAssure", informationsAccident["provenanceAssure"], setErrorState, "string");
                }}
                onChange={(event) => {
                    formik.handleChange(event)
                    setValue(getvalue(event), getIdentificateur(event), setInformationsAccident, setErrorState, "string");
                    formik.handleChange(event)
                    handleChange(event, "provenanceAssure");
                }}
            />
        </div>
        <div className={'grid-item'}>
            <label
                htmlFor="destinationAssure"
                style={{
                    margin: "30px 0 10px 0", color: errorState["destinationAssure"] === true ? "red" : null,
                }}
            >
                L&apos;engin de l&apos;assuré se dirigeait vers:
            </label>
            <Input
                id="destinationAssure"
                name="destinationAssure"
                value={formik.values.destinationAssure}
                placeholder="Destination de la voiture de l'assuré"
                status={errorState["destinationAssure"] === true ? "error" : null}
                onBlur={(e) => {
                    formik.handleBlur(e)
                    handleFieldErrorToast('destinationAssure')

                    errorStateChecker("destinationAssure", informationsAccident["destinationAssure"], setErrorState, "string");
                }}
                onChange={(event) => {
                    formik.handleChange(event)
                    setValue(getvalue(event), getIdentificateur(event), setInformationsAccident, setErrorState, "string");
                    handleChange(event, "destinationAssure");
                }}
            />
        </div>
        <div className={'grid-item'}>
            <label
                htmlFor="vitesseAssure"
                style={{
                    margin: "30px 0 10px 0", color: errorState["vitesseAssure"] === true ? "red" : null,
                }}
            >
                A quelle vitesse allait l&apos;engin de de l&apos;assuré?:
            </label>
            <Input
                id="vitesseAssure"
                name="vitesseAssure"
                value={formik.values.vitesseAssure}
                placeholder="Entrez la vitesse en km/h"
                type="number"
                status={errorState["vitesseAssure"] === true ? "error" : null}
                onBlur={(e) => {
                    formik.handleBlur(e)
                    handleFieldErrorToast('vitesseAssure')

                    errorStateChecker("vitesseAssure", informationsAccident["vitesseAssure"], setErrorState, "number");
                }}
                onChange={(event) => {
                    formik.handleChange(event)
                    setValue(getvalue(event), getIdentificateur(event), setInformationsAccident, setErrorState, "number");
                    handleChange(event, "vitesseAssure");
                }}
            />
        </div>
        <div className={'grid-item'}>
            <label htmlFor="temoignage" style={{margin: "30px 0 10px 0"}}>
                Souhaitez vous enregistrer un témoin du sinistre?:
            </label>
            <Radio.Group
                id="temoignage"
                name="temoignage"
                value={param2.id ? (sinistre.temoin ? "oui" : "non") : sinistre?.temoignage}
                defaultValue={"non"}
                onChange={(event) => {
                    formik.handleChange(event)

                    setValue(getvalue(event), getIdentificateur(event), setInformationsAccident);
                    console.log('rad ', event.target.value)
                    setTemoignage(event.target.value === 'oui')
                    console.log(formik.errors)
                    handleChange(event, "temoignage");
                }}
            >
                <Radio value={"non"}>Non</Radio>
                <Radio value={"oui"}>Oui</Radio>
            </Radio.Group>
        </div>

        {informationsAccident["temoignage"] === "oui" && (<>
            <div className={'grid-item'}>
                <label
                    htmlFor="temoin.nom"
                    style={{
                        margin: "30px 0 10px 0",
                    }}
                >
                    Nom du témoin:
                </label>
                <Input
                    id="temoin.nom"
                    name="temoin.nom"
                    value={formik.values.temoin.nom}
                    placeholder="Nom du témoin"
                    onBlur={(e) => {
                        formik.handleBlur(e)
                        handleFieldErrorToast('temoin.nom')

                        errorStateChecker("temoin.nom", informationsAccident["temoin.nom"], setErrorState, "string");
                    }}
                    onChange={(event) => {
                        formik.handleChange(event)
                        /* setValue(
                             getvalue(event),
                             getIdentificateur(event),
                             setInformationsAccident,
                             setErrorState,
                             "string"
                         );
                         handleChange(event, "temoin.nom");*/
                    }}
                />
            </div>
            <div className={'grid-item'}>
                <label
                    htmlFor="temoin.prenom"
                    style={{
                        margin: "30px 0 10px 0", color: errorState["temoin.prenom"] === true ? "red" : null,
                    }}
                >
                    Prénoms du témoin:
                </label>
                <Input
                    id="temoin.prenom"
                    name="temoin.prenom"
                    value={sinistre?.temoin?.prenom}
                    placeholder="Prénoms du témoin"
                    status={errorState["temoin.prenom"] === true ? "error" : null}
                    onBlur={(e) => {
                        formik.handleBlur(e)
                        handleFieldErrorToast('temoin.prenom')
                        errorStateChecker("temoin.prenom", informationsAccident["temoin.prenom"], setErrorState, "string");
                    }}
                    onChange={(event) => {
                        formik.handleChange(event)
                        setValue(getvalue(event), getIdentificateur(event), setInformationsAccident, setErrorState, "string");
                        handleChange(event, "temoin.prenom");
                    }}
                />
            </div>
            <div className="grid-item">
                <label
                    htmlFor="temoin.telephone"
                    style={{
                        margin: "30px 0 10px 0",
                    }}
                >
                    Numéro de téléphone du témoin
                </label>
                <div
                    className="input-group input-group-merge"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        borderRadius: "4px",
                        padding: "5px 10px",
                        border: 'solid 1px black'
                    }}
                >
                    <span
                        className="input-group-text"
                        style={{
                            fontWeight: 500,
                            backgroundColor: "#f5f5f5",
                            padding: "5px 10px",
                            borderRadius: "4px",
                            border: "1px solid #ddd",
                        }}>TG (+228)</span>
                    <Input
                        id="temoin.telephone"
                        placeholder="Numéro de téléphone du témoin"
                        style={{
                            flex: 1, // Takes up remaining space
                            border: "none", // Removes default border
                            outline: "none",
                            padding: "5px",
                            fontSize: "14px",
                        }}
                        onBlur={(e) => {
                            formik.handleBlur(e);
                            handleFieldErrorToast("temoin.telephone");
                        }}
                        value={formik.values.temoin?.telephone}
                        onChange={(event) => {
                            formik.handleChange(event);
                            handleChange(event, "conducteur.telephone");
                        }}
                    />
                </div>
            </div>

            <div className={'grid-item'}>
                <label
                    htmlFor="temoin.adresse"
                    style={{
                        margin: "30px 0 10px 0", color: errorState["temoin.adresse"] === true ? "red" : null,
                    }}
                >
                    Adresse du témoin:
                </label>
                <Input
                    id="temoin.adresse"
                    name="temoin.adresse"
                    value={formik.values.temoin.adresse}
                    placeholder="Adresse du témoin"
                    status={errorState["temoin.adresse"] === true ? "error" : null}
                    onBlur={(e) => {
                        formik.handleBlur(e)
                        handleFieldErrorToast('temoin.adresse')
                        errorStateChecker("temoin.adresse", informationsAccident["temoin.adresse"], setErrorState, "string");
                    }}
                    onChange={(event) => {
                        formik.handleChange(event)
                        setValue(getvalue(event), getIdentificateur(event), setInformationsAccident, setErrorState, "string");
                        handleChange(event, "temoin.adresse");
                    }}
                />
            </div>
            <div className={'grid-item'}>
                <label
                    htmlFor="temoin.fonction"
                    style={{
                        margin: "30px 0 10px 0", color: errorState["temoin.fonction"] === true ? "red" : null,
                    }}
                >
                    Qualité du témoin:
                </label>
                <Input
                    id="temoin.fonction"
                    name="temoin.fonction"
                    value={sinistre?.temoin?.fonction}
                    placeholder="Qualité du témoin"
                    status={errorState["temoin.fonction"] === true ? "error" : null}
                    onBlur={(e) => {
                        formik.handleBlur(e)
                        handleFieldErrorToast('temoin.fonction')
                        errorStateChecker("temoin.fonction", informationsAccident["temoin.fonction"], setErrorState, "string");
                    }}
                    onChange={(event) => {
                        setValue(getvalue(event), getIdentificateur(event), setInformationsAccident, setErrorState, "string");
                        handleChange(event, "temoin.fonction");
                    }}
                />
            </div>
        </>)}
        {/* FIN DU FORMULAIRE LORSQUE TEMOIN */}
        <div className={'grid-item'}>
            <label
                htmlFor="entiteConstat"
                style={{
                    margin: "30px 0 10px 0", color: errorState["entiteConstat"] === true ? "red" : null,
                }}
            >
                Sélectionnez l&apos;entité ayant effectué le constat:
            </label>
            <Select
                name="entiteConstat"
                id="entiteConstat"
                placeholder="Cherchez et sélectionnez"
                options={entites}
                value={param2.id ? entiteConstat?.entiteConstatId : sinistre?.entiteConstat?.entiteConstatId}
                showSearch
                status={errorState["entiteConstat"] === true ? "error" : null}
                onBlur={(e) => {
                    formik.handleBlur(e)
                    handleFieldErrorToast('entiteConstat')
                    errorStateChecker("entiteConstat", informationsAccident["entiteConstat"], setErrorState, "entiteConstat");
                }}
                onChange={(_, obj) => {

                    // Find entiteConstat by id

                    formik.setFieldValue('entiteConstat', obj)
                }}
                style={{width: "100%"}}
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label ?? "").includes(input)}
                filterSort={(optionA, optionB) => (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())}
            />
        </div>
        <div className={'grid-item'}>
            <label
                htmlFor="circonstance"
                style={{
                    margin: "30px 0 10px 0", color: errorState["circonstance"] === true ? "red" : null,
                }}
            >
                Circonstance de l&apos;accident:
            </label>
            <TextArea
                id="circonstance"
                name="circonstance"
                placeholder="Circonstance de l'accident"
                showCount
                maxLength={300}
                status={errorState["circonstance"] === true ? "error" : null}
                style={{height: 120, resize: "none"}}
                value={circonstance}
                onBlur={(e) => {
                    formik.handleBlur(e)
                    handleFieldErrorToast('circonstance')
                    errorStateChecker("circonstance", informationsAccident["circonstance"], setErrorState, "string");
                }}
                onChange={(event) => {
                    formik.handleChange(event)
                    setValue(getvalue(event), getIdentificateur(event), setInformationsAccident, setErrorState, "string");
                    setCirconstance(event.target.value);
                    handleChange(event, "circonstance");
                }}
            />
        </div>


        <div className={'grid-item'}>
            <label
                htmlFor="croquis"
                style={{
                    margin: "30px 0 10px 0", color: errorState["croquis"] === true ? "red" : null,
                }}
            >
                Croquis de l&apos;accident:
            </label>
            <Dragger {...draggerProps}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined/>
                </p>
                <p className="ant-upload-text">Selectionner ou glisser et deposer le fichier du croquis de l'accident
                    pour le téléverser</p>
            </Dragger>
        </div>
        <div className={'grid-item'}>
            <label
                htmlFor="observationAgent"
                style={{
                    margin: "30px 0 10px 0", color: errorState["observationAgent"] === true ? "red" : null,
                }}
            >
                Observations de l&apos;agent:
            </label>
            <TextArea
                id="observationAgent"
                name="observationAgent"
                placeholder="Observations de l'agent"
                showCount
                maxLength={300}
                value={sinistre?.observationAgent}
                status={errorState["observationAgent"] === true ? "error" : null}
                style={{height: 120, resize: "none"}}
                onBlur={(e) => {
                    formik.handleBlur(e)
                    handleFieldErrorToast('observationAgent')
                    errorStateChecker("observationAgent", informationsAccident["observationAgent"], setErrorState, "string");
                }}
                onChange={(event) => {
                    formik.handleChange(event)
                    setValue(getvalue(event), getIdentificateur(event), setInformationsAccident, setErrorState, "string");
                    handleChange(event, "observationAgent");
                }}
            />
        </div>
    </div>);
});

InformationsSurAccident.displayName = "InformationsSurAccident";

export default InformationsSurAccident;
