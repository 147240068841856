import { CarOutlined, EditOutlined, FilePdfOutlined, PlusOutlined, UserOutlined } from "@ant-design/icons";
import React, { useRef, useState } from "react";
import { CardBody } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import { frenchDate } from "../../../../variables/functions";
import AttestationDeclarationSinistre from "../../docs/AttestationDeclarationSinistre";

import {
    Alert,
    Button as AntdButton,
    Avatar,
    Button,
    Card,
    Descriptions,
    Divider,
    Form,
    Image,
    Input,
    List,
    Modal,
    Select,
    Space,
    Spin,
    Table,
    Tabs,
    Tag,
    Typography
} from "antd";
import axios from "axios";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FormInput, FormTextArea } from "../../../../components/forms/FormInputs";
import FormikDynamicStepper from "../../../../components/wizard/FormikDynamicStepper";
import { saveVictime, updateSinistre, updateVictime } from "../../../../hooks/useHooks";
import { API_URL, TOKEN } from "../../../../variables/constants";
import AjouterExpert from "../../components/AjouterExpert";
import AssignCheque from "../../components/AssignCheque";
import DecaissementsTable from "../../components/decaissements/DecaissementTable";
import {
    getAdversaireValidationSchema,
    getAdversaireValidationSchemaByStep,
    getNewVictimeValidationSchema
} from "../../components/declaration/form-validations/schemaValidations";
import ReglementModal from "../../components/modals/ReglementModal";
import { getPietonFormSteps, getVehiculeFormSteps } from "../../components/utils/formSteps";
import FicheDemandeReglementSinistre from "../../docs/FicheDemandeReglementSinistre";
import OrdreDeDepense from "../../docs/OrdreDeDepense";
import QuittanceIndemnite from "../../docs/QuittanceIndemnite";


const Details = ({
                     sinistre, modalQuittance,
                     victimes, quittance,
                     captures, refetch, setRefetch,
                     name,
                     ordreDepense,
                     addItem,
                     onNameChange,
                     setModalFicheDemandeReglement,
                     setFicheDemandeReglement,
                     setModalQuittance,
                     modalOrdreDepense,
                     setQuittance,
                     setSelectedGaranties,
                     setModalOrdreDepense,
                     setGarantieState,
                     setOrdreDepense,
                     provisionGaranties,
                     inputRef,
                     evaluations,
                     setModalReevaluation,
                     items,
                     setMotifRevisionState,
                     montant,
                     setEditMode,
                     removeProvisionFormFields,
                     setProvisionGarantie,
                     decaissements,
                     setExpert,
                     valueSelected,
                     editMode,
                     addProvisionFormFields,
                     setDecaissement,
                     setModalAddExpert,
                     initEvaluation,
                     setValueSelected,
                     decaissement,
                     loadingSinistre,
                     setEvaluation,
                     handleChange,
                     setModalCloture,
                     setModalSuspension,
                     experts,
                     captured,
                     handleSubmit,
                     garanties,
                     ficheDemandeReglement,
                     modalFicheDemandeReglement,
                     selectedGaranties,
                     evaluation,
                     modalReevaluation,
                     loadingSuspension,
                     modalCloture,
                     handleSuspensionDossier,
                     modalSuspension,
                     loadingCloture,
                     handleClotureDossier,
                 }) => {
    const navigate = useNavigate()
    const [tabId, setTabId] = useState('1')
    const [updateVictimeLoading, setUpdateVictimeLoading] = useState(false)

    const [isPieton, setIsPieton] = useState(null)
    const [wasAdversaireConducteur, setWasAdversaireConducteur] = useState(true)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentVictime, setCurrentVictime] = useState(null);
    const [modalAssignCheque, setModalAssignCheque] = useState(false)
    const [isVictimeModalVisible, setIsVictimeModalVisible] = useState(false)
    const handleNewVictimeOpen = () => setIsVictimeModalVisible(true)
    const handleNewVictimeClose = () => setIsVictimeModalVisible(false)


    const [isAdversaireModalVisible, setIsAdversaireModalVisible] = useState(false);
    const [currentAdversaire, setCurrentAdversaire] = useState(null);
    const [isNewAdversaireModalVisible, setIsNewAdversaireModalVisible] = useState(false)
    const handleNewAdversaireOpen = () => setIsNewAdversaireModalVisible(true)


    const handleNewAdversaireClose = () => {
        setIsNewAdversaireModalVisible(false)
        setIsPieton(null)
    }

    const handleAdversaireOk = async () => {
        try {
            setUpdateVictimeLoading(true)
            const response = await updateVictime(currentAdversaire)
            console.log('response ', response)
            setIsAdversaireModalVisible(false);
            toast.success('Adversaire mise à jour avec succès !')

        } catch (error) {
            toast.error('Une erreur est survenue')
        } finally {
            setUpdateVictimeLoading(false)
        }
    }
    const handleAdversaireCancel = () => setIsAdversaireModalVisible(false)
    console.log('quitita ', modalAssignCheque)
    const [isExpertVisible, setIsExpertVisible] = useState(false)
    const handleOpenNewExpert = () => setIsExpertVisible(true)
    const handleCloseNewExpert = () => setIsExpertVisible(false)


    const [currentStep, setCurrentStep] = useState(0)
    const [newAdversaireLoading, setNewAdversaireLoading] = useState(false)

    const editAdversaire = (adversaire) => {
        setCurrentAdversaire(adversaire);
        setIsAdversaireModalVisible(true)

    }

    const adversairesFilter = victimes?.filter(victime => victime.adversaire === true);

    const victimesFilter = victimes?.filter(
        victime => !victime.adversaire || victime.adversaire === null
    );


    const printReference = useRef();
    const printQReference = useRef();

    const exportToPdf = useReactToPrint({
        content: () => printReference.current,
    });
    useReactToPrint({
        content: () => printQReference.current,
    });
    const handleQPrint = useReactToPrint({
        content: () => printQReference.current,
    });
    const exportQToPdf = () => {
        handleQPrint();
        setTimeout(() => {
            axios
                .put(`${API_URL}/api/decaissement/print`, JSON.stringify(quittance), {
                    headers: {
                        Authorization: `Bearer ${TOKEN}`,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                        "Access-Control-Allow-Headers":
                            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                    },
                })
                .then((res) => {
                    console.log(res.data);
                    // setModalQuittance(false);
                })
                .catch((err) => {
                    console.log(err);
                });
        }, [500]);
    };

    const onTabsChange = (key) => {
        setTabId(key);
    };

    const editExpert = (item) => {
        setEditMode(true);
        setExpert(item);
        setModalAddExpert(true);
    };

    const showOrdreDepense = (item) => {
        setOrdreDepense(item);
        setModalOrdreDepense(true);
        console.log('modalOrdreDepense ', modalOrdreDepense)
    };


    const showQuittance = (item) => {
        setQuittance(item);
        setModalQuittance(true);
    };

    const showFicheDemandeReglement = (item) => {
        setFicheDemandeReglement(item);
        setModalFicheDemandeReglement(true);
    };


    const victimeInitialValue = {
        nom: "",
        prenom: "",
        telephone: "",
        profession: "",
        adresse: "",
        descriptionDegatsVictime: ""
    }

    const adversaireInitialValue = {
        items: [
            {
                pieton: "non",
                nom: "",
                prenom: "",
                telephone: "",
                profession: "",
                adresse: "",
                engin: {
                    marque: "",
                    puissance: "",
                    immatriculation: "",
                    usage: "PRIVE",
                },
                nombrePersonneTransportees: 0,
                conducteur: {
                    nom: "",
                    prenom: "",
                    telephone: "",
                    numeroPermis: "",
                    categoriePermis: "",
                    dateDelivrance: null,
                    lieuDelivrance: "",
                    dateExpiration: null,
                    qualite: "",
                },
                societeAssurance: "",
                typeSinistre: "",
                conduisait: "oui",
                provenance: "",
                destination: "",
                vitesse: 0,
            },
        ],
    }
    const sinistreEvaluations = evaluations?.filter((evaluation) => evaluation.recours !== true)
    console.log('evoh ', sinistreEvaluations)

    console.log(decaissements[0])
    const victimeFormik = useFormik({
        initialValues: victimeInitialValue,
        validationSchema: getNewVictimeValidationSchema()
    })
    console.log(victimeFormik.errors)

    const adversaireFormik = useFormik({
        initialValues: adversaireInitialValue,
        validationSchema: getAdversaireValidationSchema(isPieton, wasAdversaireConducteur)
    })
    console.log(wasAdversaireConducteur)
    console.log(currentAdversaire)

    const editVictime = (victime) => {
        setCurrentVictime(victime);
        setIsModalVisible(true);
    };

    const handleOk = async () => {
        try {
            setUpdateVictimeLoading(true)
            const response = await updateVictime(currentVictime)
            console.log('response ', response)
            setIsModalVisible(false);
            toast.success('Victime mise à jour avec succès !')

        } catch (error) {
            toast.error('Une erreur est survenue')
        } finally {
            setUpdateVictimeLoading(false)
        }

    };
    const printODReference = useRef();
    const printFDReference = useRef();

    const exportFDRToPdf = useReactToPrint({
        content: () => printFDReference.current,
    });
    const exportODToPdf = useReactToPrint({
        content: () => printODReference.current,
    });

    const handleSaveVictime = async () => {
        try {
            setUpdateVictimeLoading(true)
            const victime = {
                nom: victimeFormik.values.nom,
                prenom: victimeFormik.values.prenom,
                profession: victimeFormik.values.profession,
                adresse: victimeFormik.values.adresse,
                telephone: victimeFormik.values.telephone,
                descriptionDegatsVictime: victimeFormik.values.descriptionDegatsVictime,
                sinistre: sinistre,
                adversaire: false
            }
            const response = await saveVictime(victime)
            console.log('response ', response)

            setIsModalVisible(false);
            setRefetch((previous) => !previous)
            toast.success('Victime enregistrée avec succes !', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            })
            window.location.refresh()
        } catch
            (error) {
            toast.error('Une erreur est survenue', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            })
        } finally {
            setUpdateVictimeLoading(false)
        }

    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    console.log(victimeFormik.values)
    const handleVictimeChange = (field, value) => {
        setCurrentVictime((prev) => ({
            ...prev,
            personne: {
                ...prev.personne,
                [field]: value,
            },
        }));
    };
    const handleAdversaireChange = (field, value) => {
        setCurrentAdversaire((prev) => ({
            ...prev,
            personne: {
                ...prev.personne,
                [field]: value,
            },
        }));
    };

    const adversaireVehicleColumns = [
        {title: "Marque", dataIndex: ["engin", "marque"], key: "marque"},
        {title: "Puissance", dataIndex: ["engin", "puissance"], key: "puissance"},
        {title: "Immatriculation", dataIndex: ["engin", "immatriculation"], key: "immatriculation"},
        {title: "Usage", dataIndex: ["engin", "usage"], key: "usage"},
    ];

    const handlePietonChoice = (value) => {
        setIsPieton(value)
    }

    console.log(adversaireFormik.values.items[0])
    const handleNewAdversaireSubmit = async (AdversaireValidationSchema, formik, currentStep, isLastStep) => {
        if (!isLastStep) {
            try {
                const validationSchema = AdversaireValidationSchema[currentStep];
                const fieldsToValidate = Object.keys(formik.values);

                const valuesToValidate = fieldsToValidate.reduce((acc, field) => {
                    acc[field] = formik.values[field];
                    return acc;
                }, {});

                await validationSchema.validate(valuesToValidate, {abortEarly: false});
                setCurrentStep(currentStep + 1);
                return true;
            } catch (error) {
                if (error.inner && Array.isArray(error.inner)) {
                    error.inner.forEach((err) => {
                        toast.error(err.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    });
                    return false;
                } else {
                    console.error("Unexpected validation error:", error);
                    return false;
                }
            }
        } else {
            try {
                setNewAdversaireLoading(true);

                const victimeDTO = {
                    engin: formik.values.items[0].engin,
                    nom: formik.values.items[0].nom,
                    prenom: formik.values.items[0].prenom,
                    profession: formik.values.items[0].profession,
                    adresse: formik.values.items[0].adresse,
                    telephone: formik.values.items[0].telephone,
                    conducteur: formik.values.items[0].conducteur,
                    adversaire: true,
                    descriptionDegatsVictime: formik.values.items[0].descriptionDegatsVictime,
                    provenance: formik.values.items[0].provenance,
                    destination: formik.values.items[0].destination,
                    vitesse: formik.values.items[0].vitesse,
                    conduisait: formik.values.items[0].conduisait,
                    typeSinistre: formik.values.items[0].typeSinistre,
                    sinistre: sinistre,
                };

                console.log('adv ', victimeDTO);

                // API request
                await saveVictime(victimeDTO);

                toast.success("Adversaire added successfully!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setRefetch((previous) => !previous)

                setNewAdversaireLoading(false);
                return true;
                // window.location.refresh()
            } catch (error) {
                console.error("Error saving adversaire:", error);

                toast.error("L'enregistrement de l'adversaire a echoué. Veuillez réessayer.", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });

                setNewAdversaireLoading(false);
                return false;
            }
        }
    };

    const handleFieldErrorToast = (fieldName) => {
        const fieldMeta = victimeFormik.getFieldMeta(fieldName);
        fieldMeta.touched = true;
        if (fieldMeta.touched && fieldMeta.error) {
            toast.error(fieldMeta.error, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };

    const [reglementModalOpen, setReglementModalOpen] = useState(false)
    const handleReglementSubmit = (selectedBeneficiaires, sousEvaluationsSelected, sousEvaluations, montant) => {
        const sousEv = sousEvaluations?.filter((v) => sousEvaluationsSelected?.includes(v.codeId))

    }
    console.log(modalOrdreDepense)

    const reglementControl = {
        open: reglementModalOpen,
        setOpen: setReglementModalOpen,
        handleSubmit: handleReglementSubmit,
    }
    const gridStyle = {
        width: "33%", textAlign: "center", color: "#3C4858",
    };

    const [tauxResponsabiliteModal, setTauxResponsabiliteModal] = useState(false)
    const [tauxResponsabiliteLoading, setTauxResponsabiliteLoading] = useState(false)
    const [tauxResponsabilite, setTauxResponsabilite] = useState(0)
    const handleTauxResponsabiliteClose = () => {
        setTauxResponsabiliteModal(false)
    }
    console.log('dua ', sinistre)

    const handleResponsabiliteSave = async () => {
        try {
            setTauxResponsabiliteLoading(true);
            const updatedSinistre = {...sinistre, tauxResponsabilite: parseInt(tauxResponsabilite)};

            const response = await updateSinistre(updatedSinistre);
            toast.success("Responsabilité mise à jour avec succès !");
            console.log('lipa req ', updatedSinistre);
            console.log('lipa res ', response);
            handleTauxResponsabiliteClose()
            navigate('/details-sinistre', {state: {codeId: response.data.codeId}})
        } catch (error) {
            toast.error("Une erreur est survenue lors de la mise à jour de la responsabilité.");
            setTauxResponsabiliteLoading(false);

        } finally {
            setTauxResponsabiliteLoading(false);
        }
    };

    const evaluationCourante = evaluations?.filter((evaluation) => evaluation.courant === true)[0]
    console.log('evoh ', evaluationCourante)

    return (<>
        <div className={'card'}>
            <Spin spinning={loadingSinistre}>
                <Tabs
                    onChange={onTabsChange}
                    type="card"
                    items={new Array(8).fill(null).map((_, i) => {
                        const id = String(i + 1);
                        const lb = i === 0 ? `Détails` : i === 1 ? `Déclaration` : i === 2 ? `Victimes` : i === 3 ? `Adversaires` : i === 4 ? `Évaluations` : i === 5 ? `Reglements` : i === 6 ? `Experts` : `Documents et captures`;
                        const cd = i === 0 ? (<>
                            <div className="card-body">
                                <div className="container">
                                    <div className="grid-item">
                                        <>
                                            {sinistre?.sortDossier === "I" ? (<Tag color="green">EN
                                                INSTANCE</Tag>) : sinistre?.sortDossier === "T" ? (
                                                <Tag color="red">TERMINÉ</Tag>) : sinistre?.sortDossier === "S" ? (
                                                <Tag color="blue">SANS SUITE</Tag>) : (
                                                <Tag color="green">EN INSTANCE</Tag>)}
                                        </>
                                        <br/>
                                        <br/>
                                        <Descriptions
                                            title={`Sinistre N° - ${sinistre?.numSinistre ? sinistre?.numSinistre : "-"}`}
                                        >
                                            <Descriptions.Item label="Numéro de Police">
                                                {sinistre?.contrat?.souscripteur?.numeroPoliceM ? `${sinistre?.contrat?.numeroPoliceM}` : sinistre?.contrat?.numeroPoliceP ? `${sinistre?.contrat?.numeroPoliceP}` : "-"}
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Assuré">
                                                {
                                                    sinistre?.contrat?.souscripteur?.prenom || sinistre?.contrat?.souscripteur?.nom
                                                        ? `${sinistre?.contrat?.souscripteur?.prenom || ''} ${sinistre?.contrat?.souscripteur?.nom || ''}`.trim()
                                                        : '-'
                                                }

                                            </Descriptions.Item>
                                            <Descriptions.Item
                                                label="Immatriculation engin">
                                                {sinistre?.engin?.immatriculation}
                                            </Descriptions.Item>
                                            <Descriptions.Item
                                                label="Provision actuelle">
                                                {sinistre?.evaluation ? new Intl.NumberFormat("en-DE").format(sinistre?.evaluation) : "0"}{" "}
                                                FCFA
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Sinistre à payer">
                                                {sinistre?.sap ? new Intl.NumberFormat("en-DE").format(sinistre?.evaluation - montant) : "0"}{" "}
                                                FCFA
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Montant réglé">
                                                {sinistre?.sap ? new Intl.NumberFormat("en-DE").format(montant) : "0"}{" "}
                                                FCFA
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Card style={{marginTop: "16px"}}>
                                            <Card.Grid style={gridStyle}>
                                                <Button
                                                    type="primary"
                                                    danger
                                                    disabled={sinistre?.sortDossier === "S" || sinistre?.sortDossier === "T"}
                                                    onClick={() => setModalCloture(true)}
                                                >
                                                    Clôturer le dossier
                                                </Button>
                                            </Card.Grid>
                                            <Card.Grid style={gridStyle}>
                                                <Button
                                                    type="primary"
                                                    disabled={sinistre?.sortDossier === "T"}
                                                    onClick={() => setModalSuspension(true)}
                                                >
                                                    Suspendre le dossier
                                                </Button>
                                            </Card.Grid>
                                            <Card.Grid style={gridStyle}>
                                                <Button
                                                    type="primary"
                                                    disabled={sinistre?.sortDossier === "T"}
                                                    onClick={() => setTauxResponsabiliteModal(true)}
                                                    style={{
                                                        background: 'blueviolet'
                                                    }}>
                                                    {
                                                        sinistre?.tauxResponsabilite ? (
                                                            <>
                                                                Taux de responsabilité défini à
                                                                {sinistre?.tauxResponsabilite} %
                                                            </>
                                                        ) : (
                                                            <>
                                                                Definir le taux de responsabilité
                                                            </>
                                                        )
                                                    }
                                                </Button>
                                            </Card.Grid>
                                        </Card>
                                        <Modal title={'Taux de responsabilité'} open={tauxResponsabiliteModal}
                                               confirmLoading={tauxResponsabiliteLoading}
                                               onCancel={handleTauxResponsabiliteClose} onOk={handleResponsabiliteSave}>
                                            <Typography>Definir le taux de responsabilité</Typography>
                                            <Input
                                                type="number"
                                                min={0}
                                                max={100}
                                                value={tauxResponsabilite}
                                                onChange={(e) => setTauxResponsabilite(e.target.value)}
                                            />
                                        </Modal>
                                        <Modal
                                            title="Clôture du dossier sinistre"
                                            open={modalCloture}
                                            onOk={() => handleClotureDossier()}
                                            confirmLoading={loadingCloture}
                                            onCancel={() => setModalCloture(false)}
                                        >
                                            <p>Voulez-vous vraiment clôturer ce dossier sinistre?</p>
                                        </Modal>
                                        <Modal
                                            title="Suspension du dossier sinistre"
                                            open={modalSuspension}
                                            onOk={() => handleSuspensionDossier()}
                                            confirmLoading={loadingSuspension}
                                            onCancel={() => setModalSuspension(false)}
                                        >
                                            <p>
                                                Voulez-vous vraiment classer le dossier {"'"}Sans suite
                                                {"'"}?
                                            </p>
                                        </Modal>
                                    </div>
                                </div>
                            </div>
                        </>) : i === 1 ? (<>
                            <div className="card-body">
                                <div
                                    className="container">
                                    <div className="grid-item">
                                        <div id="mySinistre" ref={printReference}>
                                            {sinistre && (<AttestationDeclarationSinistre
                                                sinistre={sinistre}
                                            />)}
                                        </div>
                                    </div>
                                </div>
                                <br/>
                                <AntdButton
                                    onClick={() => {
                                        exportToPdf();
                                    }}
                                    type="primary"
                                    style={{
                                        background: "green",
                                    }}
                                    icon={<FilePdfOutlined/>}
                                >
                                    Générer PDF
                                    <br/>
                                    <br/>
                                    <br/>
                                </AntdButton>
                            </div>
                        </>) : i === 2 ? (
                            <div className="card-body">
                                <div className="container" justify="center">
                                    <div className="col-sm-4">
                                        <Button
                                            type="primary"
                                            icon={<PlusOutlined/>}
                                            style={{marginBottom: 16}}
                                            onClick={handleNewVictimeOpen}
                                        >
                                            Nouvelle victime
                                        </Button>
                                    </div>
                                    <div className="grid-item">
                                        {victimesFilter?.length >= 1 ? (
                                            <List
                                                grid={{gutter: 16, column: 2}}
                                                dataSource={victimesFilter}
                                                renderItem={(item, index) => (
                                                    <List.Item>
                                                        <Card
                                                            style={{
                                                                background: 'linear-gradient(to right, rgba(173, 216, 230, 0.5), rgba(238, 174, 255, 0.5))'
                                                            }}
                                                            title={
                                                                <Typography.Text strong>
                                                                    {`Victime N° ${index + 1}`}
                                                                </Typography.Text>
                                                            }
                                                            extra={[
                                                                <AntdButton
                                                                    type="primary"
                                                                    icon={<EditOutlined/>}
                                                                    onClick={() => editVictime(item)}
                                                                    key={item.evaluationId}
                                                                ></AntdButton>,
                                                            ]}

                                                        >
                                                            <Card.Meta
                                                                avatar={
                                                                    <Avatar
                                                                        src={`https://ui-avatars.com/api/?name=${item?.personne?.prenom
                                                                            ? item?.personne?.nom + item?.personne?.prenom
                                                                            : item?.personne?.nom}`}
                                                                    />
                                                                }
                                                                title={
                                                                    <Typography.Text>
                                                                        {item?.personne?.prenom
                                                                            ? `${item?.personne?.nom} ${item?.personne?.prenom}`
                                                                            : item?.personne?.nom}
                                                                    </Typography.Text>
                                                                }
                                                                description={
                                                                    <>
                                                                        <Typography.Paragraph>
                                                                            <strong>Profession: </strong>
                                                                            {item?.personne?.profession || "-"}
                                                                        </Typography.Paragraph>
                                                                        <Typography.Paragraph>
                                                                            <strong>Adresse: </strong>
                                                                            {item?.personne?.adresse || "-"}
                                                                        </Typography.Paragraph>
                                                                        <Typography.Paragraph>
                                                                            <strong>Description dommages: </strong>
                                                                            {item?.descriptionDegatsVictime || "-"}
                                                                        </Typography.Paragraph>
                                                                        <Typography.Paragraph>
                                                                            <strong>Enregistrée le: </strong>
                                                                            {frenchDate(item?.createdAt)}
                                                                        </Typography.Paragraph>
                                                                    </>}
                                                            />
                                                        </Card>
                                                    </List.Item>
                                                )}
                                            />
                                        ) : (
                                            "Aucune victime enregistrée sur ce dossier sinistre..."
                                        )}

                                        <Modal
                                            title="Modifier Victime"
                                            visible={isModalVisible}
                                            confirmLoading={updateVictimeLoading}
                                            loading={updateVictimeLoading}
                                            onOk={handleOk}
                                            onCancel={handleCancel}
                                            okText="Sauvegarder"
                                            cancelText="Annuler"
                                        >
                                            {currentVictime && (
                                                <Form layout="vertical">
                                                    <Form.Item label="Nom">
                                                        <Input
                                                            value={currentVictime.personne?.nom}
                                                            onChange={(e) => handleVictimeChange("nom", e.target.value)}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item label="Prénom">
                                                        <Input
                                                            value={currentVictime?.personne?.prenom}
                                                            onChange={(e) => handleVictimeChange("prenom", e.target.value)}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item label="Profession">
                                                        <Input
                                                            value={currentVictime?.personne?.profession}
                                                            onChange={(e) => handleVictimeChange("profession", e.target.value)}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item label="Adresse">
                                                        <Input
                                                            value={currentVictime?.personne?.adresse}
                                                            onChange={(e) => handleVictimeChange("adresse", e.target.value)}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item label="Description dommages">
                                                        <Input.TextArea
                                                            value={currentVictime.descriptionDegatsVictime}
                                                            onChange={(e) =>
                                                                setCurrentVictime((prev) => ({
                                                                    ...prev,
                                                                    descriptionDegatsVictime: e.target.value,
                                                                }))
                                                            }
                                                        />
                                                    </Form.Item>
                                                </Form>
                                            )}
                                        </Modal>
                                        <Modal
                                            title="Enregistrer une nouvelle Victime"
                                            open={isVictimeModalVisible}
                                            confirmLoading={updateVictimeLoading}
                                            loading={updateVictimeLoading}
                                            onOk={handleSaveVictime}
                                            onCancel={handleNewVictimeClose}
                                            okText="Sauvegarder"
                                            cancelText="Annuler"
                                        >
                                            <>
                                                <FormInput
                                                    label={`Nom de l'adversaire`}
                                                    name={"nom"}
                                                    value={victimeFormik.values.nom || ""}
                                                    onBlur={() => {
                                                        handleFieldErrorToast(("nom"))
                                                    }}
                                                    onChange={(event) => victimeFormik.handleChange(event)}
                                                />
                                                <FormInput
                                                    label={`Prénoms de l'adversaire`}
                                                    name={"prenom"}
                                                    value={victimeFormik.values.prenom || ""}
                                                    onBlur={() => handleFieldErrorToast(("prenom"))}
                                                    onChange={(event) => victimeFormik.handleChange(event)}
                                                />
                                                <FormInput
                                                    label={`Adresse de l'adversaire`}
                                                    name={"adresse"}
                                                    value={victimeFormik.values.adresse || ""}
                                                    onBlur={() => handleFieldErrorToast(("adresse"))}
                                                    onChange={(event) => victimeFormik.handleChange(event)}
                                                />
                                                <FormInput
                                                    label={`Profession de l'adversaire`}
                                                    name={"profession"}
                                                    value={victimeFormik.values.profession || ""}
                                                    onBlur={() => handleFieldErrorToast(("profession"))}
                                                    onChange={(event) => victimeFormik.handleChange(event)}
                                                />
                                                <FormInput
                                                    label={`Numero de téléphone de l'adversaire`}
                                                    name={"telephone"}
                                                    value={victimeFormik.values.telephone || ""}
                                                    onBlur={() => handleFieldErrorToast(("telephone"))}
                                                    onChange={(event) => victimeFormik.handleChange(event)}
                                                />
                                                <FormTextArea
                                                    label="Description des dégâts sur la victime"
                                                    name={'descriptionDegatsVictime'}
                                                    value={victimeFormik.values.descriptionDegatsVictime || ""}
                                                    onBlur={() => handleFieldErrorToast("descriptionDegatsVictime")}
                                                    onChange={victimeFormik.handleChange}
                                                />
                                            </>

                                        </Modal>

                                    </div>
                                </div>
                            </div>) : i === 4 ? (
                                <div className="card-body">
                                    <div className="col-sm-4">
                                        <Button
                                            type="primary"
                                            icon={<PlusOutlined/>}
                                            style={{marginBottom: 16}}
                                            onClick={() => navigate(`/evaluation-sinistre`, {
                                                state: {
                                                    iz: sinistre.codeId
                                                }
                                            })}>
                                            Nouvelle évaluation
                                        </Button>
                                    </div>
                                    <div
                                        className="container"
                                        style={{
                                            justifyContent: 'center'
                                        }}>
                                        <div className="grid-item">
                                            {evaluations.length ? (
                                                <List
                                                    itemLayout="horizontal"
                                                    dataSource={sinistreEvaluations}
                                                    renderItem={(item) => (<List.Item>
                                                        <List.Item.Meta
                                                            avatar={<Avatar
                                                                src={`https://ui-avatars.com/api/?name=${item?.motifRevision}`}
                                                            />}
                                                            title={<a href="/#">
                                                                {item?.motifRevision ? item?.motifRevision : ""}
                                                            </a>}
                                                            description={<>
                                                                <p>
                                                                    Ancienne
                                                                    provision:{" "}
                                                                    <b>
                                                                        {`${new Intl.NumberFormat("en-DE").format(Math.trunc(item?.ancienneProvision))}`}{" "}
                                                                        FCFA
                                                                    </b>
                                                                </p>
                                                                <p>
                                                                    Nouvelle
                                                                    provision:{" "}
                                                                    <b>
                                                                        {`${new Intl.NumberFormat("en-DE").format(Math.trunc(item?.nouvelleProvision))}`}{" "}
                                                                        FCFA
                                                                    </b>
                                                                </p>
                                                                <p>
                                                                    Reévaluée le:{" "}
                                                                    <b>
                                                                        {frenchDate(item?.createdAt)}
                                                                    </b>
                                                                </p>
                                                            </>}
                                                        />
                                                    </List.Item>)}
                                                />) : ("Aucune reévaluation effectuée sur ce dossier sinistre...")}
                                        </div>
                                    </div>
                                </div>) :
                            i === 3 ? (
                                <div className="card-body">
                                    <div className="container" justify="center">
                                        <div className="col-sm-4">
                                            <Button
                                                type="primary"
                                                icon={<PlusOutlined/>}
                                                style={{marginBottom: 16}}
                                                onClick={handleNewAdversaireOpen}
                                            >
                                                Nouvel Adversaire
                                            </Button>
                                        </div>
                                        <div className="grid-item">
                                            {adversairesFilter?.length ? (
                                                <List
                                                    grid={{gutter: 16, column: 1}}
                                                    dataSource={adversairesFilter}
                                                    renderItem={(item, index) => (
                                                        <List.Item>
                                                            <Card
                                                                style={{
                                                                    background: 'linear-gradient(to right, rgba(173, 216, 230, 0.5), rgba(238, 174, 255, 0.5))'
                                                                }}
                                                                title={
                                                                    <Typography.Text strong>
                                                                        {`Adversaire N° ${index + 1}`}
                                                                    </Typography.Text>
                                                                }
                                                                extra={
                                                                    <AntdButton
                                                                        type="primary"
                                                                        icon={<EditOutlined/>}
                                                                        onClick={() => editAdversaire(item)}
                                                                        key={item.evaluationId}
                                                                    />
                                                                }
                                                            >
                                                                <Card.Meta
                                                                    avatar={
                                                                        <Avatar
                                                                            src={`https://ui-avatars.com/api/?name=${
                                                                                item?.personne?.prenom
                                                                                    ? item?.personne?.nom[0] +
                                                                                    item?.personne?.prenom[0]
                                                                                    : item?.personne?.nom[0]
                                                                            }`}
                                                                        />
                                                                    }
                                                                    title={
                                                                        <Typography.Text>
                                                                            {item?.personne?.prenom
                                                                                ? `${item?.personne?.nom} ${item?.personne?.prenom}`
                                                                                : item?.personne?.nom}
                                                                        </Typography.Text>
                                                                    }
                                                                    description={
                                                                        <>
                                                                            <Typography.Paragraph>
                                                                                <strong>Profession: </strong>
                                                                                {item?.personne?.profession || "-"}
                                                                            </Typography.Paragraph>
                                                                            <Typography.Paragraph>
                                                                                <strong>Adresse: </strong>
                                                                                {item?.personne?.adresse || "-"}
                                                                            </Typography.Paragraph>
                                                                            <Typography.Paragraph>
                                                                                <strong>Description dommages: </strong>
                                                                                {item?.descriptionDegatsVictime || "-"}
                                                                            </Typography.Paragraph>
                                                                            <Typography.Paragraph>
                                                                                <strong>Enregistrée le: </strong>
                                                                                {frenchDate(item?.createdAt)}
                                                                            </Typography.Paragraph>
                                                                        </>
                                                                    }
                                                                />
                                                                {item?.engin?.marque || item?.engin?.puissance || item?.engin?.immatriculation ? (
                                                                    <Table
                                                                        columns={adversaireVehicleColumns}
                                                                        dataSource={[item]}
                                                                        pagination={false}
                                                                        locale={{emptyText: "-"}}
                                                                        style={{marginTop: 16}}
                                                                    />
                                                                ) : null}
                                                            </Card>
                                                        </List.Item>
                                                    )}
                                                />
                                            ) : (
                                                <Typography.Text type="secondary">
                                                    Aucune victime enregistrée sur ce dossier sinistre...
                                                </Typography.Text>
                                            )}
                                            <Modal
                                                title="Modifier les informations de l'adversaire"
                                                open={isAdversaireModalVisible}
                                                confirmLoading={updateVictimeLoading}
                                                loading={updateVictimeLoading}
                                                onOk={handleAdversaireOk}
                                                onCancel={handleAdversaireCancel}
                                                okText="Sauvegarder"
                                                cancelText="Annuler"
                                            >
                                                {currentAdversaire && (
                                                    <Form layout="vertical">
                                                        <Form.Item label="Nom">
                                                            <Input
                                                                value={currentAdversaire.personne.nom}
                                                                onChange={(e) => handleAdversaireChange("nom", e.target.value)}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item label="Prénom">
                                                            <Input
                                                                value={currentAdversaire.personne.prenom}
                                                                onChange={(e) => handleAdversaireChange("prenom", e.target.value)}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item label="Profession">
                                                            <Input
                                                                value={currentAdversaire.personne.profession}
                                                                onChange={(e) => handleAdversaireChange("profession", e.target.value)}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item label="Adresse">
                                                            <Input
                                                                value={currentAdversaire.personne.adresse}
                                                                onChange={(e) => handleAdversaireChange("adresse", e.target.value)}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item label="Description dommages">
                                                            <Input.TextArea
                                                                value={currentAdversaire.descriptionDegatsVictime}
                                                                onChange={(e) =>
                                                                    setCurrentAdversaire((prev) => ({
                                                                        ...prev,
                                                                        descriptionDegatsVictime: e.target.value,
                                                                    }))
                                                                }
                                                            />
                                                        </Form.Item>
                                                    </Form>
                                                )}
                                            </Modal>
                                            <Modal
                                                title="Enregistrer un nouvel adversaire"
                                                open={isNewAdversaireModalVisible}
                                                onCancel={handleNewAdversaireClose}
                                                confirmLoading={newAdversaireLoading}
                                                footer={null}
                                                width={800}
                                                zIndex={10}
                                            >
                                                <Spin spinning={newAdversaireLoading}>
                                                    {isPieton === null ? (
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                height: "100%",
                                                            }}
                                                        >
                                                            <Typography.Title level={4} style={{textAlign: "center"}}>
                                                                L'adversaire est-il
                                                            </Typography.Title>
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    justifyContent: "center",
                                                                    gap: "20px", // Adds spacing between buttons
                                                                    marginTop: "20px",
                                                                }}
                                                            >
                                                                <Button
                                                                    type="primary"
                                                                    icon={<UserOutlined/>}
                                                                    size="large"
                                                                    onClick={() => handlePietonChoice(true)}
                                                                >
                                                                    Piéton
                                                                </Button>
                                                                <Button
                                                                    type="primary"
                                                                    icon={<CarOutlined/>}
                                                                    size="large"
                                                                    onClick={() => handlePietonChoice(false)}
                                                                >
                                                                    À Véhicule
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <FormikDynamicStepper
                                                            steps={isPieton ? getPietonFormSteps(adversaireFormik) : getVehiculeFormSteps(adversaireFormik, wasAdversaireConducteur, setWasAdversaireConducteur)}
                                                            initialValues={adversaireInitialValue}
                                                            validationSchema={getAdversaireValidationSchemaByStep(isPieton, wasAdversaireConducteur)}
                                                            onSubmit={handleNewAdversaireSubmit}
                                                            formik={adversaireFormik}
                                                            currentStep={currentStep}
                                                            setCurrentStep={setCurrentStep}
                                                        />
                                                    )}
                                                </Spin>
                                            </Modal>

                                        </div>
                                    </div>
                                </div>
                            ) : i === 5 ? (
                                    <div className="card-body">
                                        <div className="container" justify="center">

                                            {
                                                !evaluationCourante && (
                                                    <div>
                                                        <Alert
                                                            message="Aucune evaluation n'a encore ete faite ..."
                                                            type="info" showIcon/>
                                                    </div>
                                                )
                                            }
                                            {
                                                evaluationCourante?.etatEvaluation === 'EN_ATTENTE' && (
                                                    <>
                                                        <Alert
                                                            message="L'evaluation courante de ce dossier est en attente de validation..."
                                                            type="info" showIcon/>
                                                    </>
                                                )
                                            } {
                                            evaluationCourante?.etatEvaluation === 'REJETE' && (
                                                <>
                                                    <Alert
                                                        message="L'evaluation courante de ce dossier a été rejetée..."
                                                        type="error" showIcon/>
                                                </>
                                            )
                                        }{
                                            evaluationCourante?.etatEvaluation === 'VALIDE' && (
                                                <>
                                                    <div className="col-sm-4">
                                                        <Button
                                                            type="primary"
                                                            icon={<PlusOutlined/>}
                                                            style={{marginBottom: 16}}
                                                            onClick={() => setReglementModalOpen(true)}>
                                                            Nouveau Reglement
                                                        </Button>
                                                    </div>
                                                </>
                                            )
                                        }


                                            <div className="grid-item">
                                                {decaissements?.length > 0 ? (
                                                    <>


                                                        <>
                                                            <DecaissementsTable decaissements={decaissements}
                                                                                setModalAssignCheque={setModalAssignCheque}
                                                                                setDecaissement={setDecaissement}
                                                                                showFicheDemandeReglement={showFicheDemandeReglement}
                                                                                showOrdreDepense={showOrdreDepense}
                                                                                showQuittance={showQuittance}/>
                                                        </>


                                                        <Modal
                                                            title={`QUITTANCE D'INDEMNITE - SINISTRE N° ${sinistre?.numSinistre}`}
                                                            centered
                                                            open={modalQuittance}
                                                            onOk={() => setModalQuittance(false)}
                                                            onCancel={() => {
                                                                setModalQuittance(false);
                                                            }}
                                                            width={850}>
                                                            <div id="myQSinistre" ref={printQReference}>
                                                                <QuittanceIndemnite
                                                                    decaissement={quittance}
                                                                    sinistre={sinistre}
                                                                />
                                                            </div>
                                                            <AntdButton
                                                                onClick={() => {
                                                                    exportQToPdf();
                                                                }}
                                                                type="primary"
                                                                style={{
                                                                    background: "blue",
                                                                }}
                                                                icon={<FilePdfOutlined/>}
                                                            >
                                                                {" "}
                                                                Générer PDF
                                                                <br/>
                                                                <br/>
                                                                <br/>
                                                            </AntdButton>
                                                        </Modal>
                                                        <Modal
                                                            title={`FICHE DE DEMANDE DE REGLEMENT - SINISTRE N° ${sinistre?.numSinistre}`}
                                                            centered
                                                            open={modalFicheDemandeReglement}
                                                            onOk={() => setModalFicheDemandeReglement(false)}
                                                            onCancel={() => {
                                                                setModalFicheDemandeReglement(false);
                                                            }}
                                                            width={850}>
                                                            <div id="myFDRSinistre" ref={printFDReference}>
                                                                <FicheDemandeReglementSinistre
                                                                    decaissement={ficheDemandeReglement}
                                                                    sinistre={sinistre}
                                                                />
                                                            </div>
                                                            <AntdButton
                                                                onClick={() => {
                                                                    exportFDRToPdf();
                                                                }}
                                                                type="primary"
                                                                style={{
                                                                    background: "gray",
                                                                }}
                                                                icon={<FilePdfOutlined/>}
                                                            >
                                                                {" "}
                                                                Générer PDF
                                                                <br/>
                                                                <br/>
                                                                <br/>
                                                            </AntdButton>
                                                        </Modal>
                                                        <Modal
                                                            title={`ORDRE DE DÉPENSE - SINISTRE N° ${sinistre?.numSinistre}`}
                                                            centered
                                                            open={modalOrdreDepense}
                                                            onOk={() => setModalOrdreDepense(false)}
                                                            onCancel={() => {
                                                                setModalOrdreDepense(false);
                                                            }}
                                                            width={850}
                                                        >
                                                            <div id="myODSinistre" ref={printODReference}>
                                                                <OrdreDeDepense
                                                                    decaissement={ordreDepense}
                                                                    sinistre={sinistre}
                                                                />
                                                            </div>
                                                            <AntdButton
                                                                onClick={() => {
                                                                    exportODToPdf();
                                                                }}
                                                                type="primary"
                                                                style={{
                                                                    background: "blue",
                                                                }}
                                                                icon={<FilePdfOutlined/>}
                                                            >
                                                                {" "}
                                                                Générer PDF
                                                                <br/>
                                                                <br/>
                                                                <br/>
                                                            </AntdButton>
                                                        </Modal>
                                                        <AssignCheque
                                                            open={modalAssignCheque}
                                                            decaissement={decaissement}
                                                            sinistre={sinistre}
                                                            onCancel={() => setModalAssignCheque(false)}
                                                        />
                                                    </>
                                                ) : ("Aucun reglement effectué sur ce dossier sinistre...")}
                                            </div>
                                            <ReglementModal sinistre={sinistre} modalControl={reglementControl}
                                                            sap={sinistre?.evaluation - montant} sapMin={1000}/>
                                        </div>
                                    </div>
                                ) :
                                i === 6 ? (
                                    <div className="card-body">
                                        <div className="container" justify="center">
                                            <div className="col-sm-4">
                                                <Button
                                                    type="primary"
                                                    icon={<PlusOutlined/>}
                                                    style={{marginBottom: 16}}
                                                    onClick={handleOpenNewExpert}
                                                >
                                                    Nouvel expert
                                                </Button>
                                            </div>
                                            <div className="grid-item">
                                                {experts.length ? (<List
                                                    itemLayout="horizontal"
                                                    dataSource={experts}
                                                    renderItem={(item, index) => (<List.Item
                                                        actions={[<AntdButton
                                                            type="primary"
                                                            icon={<EditOutlined/>}
                                                            onClick={() => editExpert(item)}
                                                            key={item.evaluationId}
                                                        ></AntdButton>,]}
                                                    >
                                                        <List.Item.Meta
                                                            avatar={<Avatar
                                                                src={`https://ui-avatars.com/api/?name=${item?.prenom ? item?.nom[0] + item?.prenom[0] : item?.nom[0]}`}
                                                            />}
                                                            title={<a href="/#">
                                                                {`Expert N° ${index + 1}`}
                                                            </a>}
                                                            description={<>
                                                                <p>
                                                                    Nom :{" "}
                                                                    {item?.prenom ? item?.nom + " " + item?.prenom : item?.nom}
                                                                </p>
                                                                <p>
                                                                    Profession :{" "}
                                                                    {item.profession ? item?.profession : "-"}
                                                                </p>
                                                                <p>
                                                                    Adresse :{" "}
                                                                    {item?.adresse ? item?.adresse : "-"}
                                                                </p>
                                                                <p>
                                                                    Enregistré le:{" "}
                                                                    {frenchDate(item?.createdAt)}
                                                                </p>
                                                            </>}
                                                        />
                                                    </List.Item>)}
                                                />) : ("Aucun expert enregistré sur ce dossier sinistre...")}
                                            </div>
                                            <AjouterExpert
                                                open={isExpertVisible}
                                                modalExpert={isExpertVisible}
                                                setOpen={setIsExpertVisible}
                                                sinistre={sinistre}
                                                edit={false}

                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <div className="card-body">
                                        <div
                                            className="container"
                                            justify="center"
                                        >
                                            <div className="grid-item">
                                                <div className="card-body">
                                                    <div>
                                                        {Array.isArray(captures) && captures?.length ? captures?.map((imageUrl, index) => (
                                                            <div className={'grid'}
                                                                 key={index}
                                                            >
                                                                <Image
                                                                    key={index}
                                                                    src={captured[index]?.url}
                                                                    alt={`Capture ${index}`}
                                                                    style={{margin: "8px"}}
                                                                />
                                                            </div>)) : "Aucun document enregistré sur ce dossier sinistre..."}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                        return {
                            label: lb, key:
                            id, children:
                            cd,
                        };
                    })}
                    activeKey={
                        tabId
                    }
                />
            </Spin>

        </div>
        <Modal
            title={
                editMode ? "MISE À JOUR RÉEVALUATION" : "REÉVALUATION"
            }
            centered
            open={modalReevaluation}
            onOk={(e) => handleSubmit(e, false)}
            onCancel={() => {
                setEvaluation(initEvaluation);
                setModalReevaluation(false);
            }}
        >
            <Card>
                <CardBody>
                    <div className={' grid container'}>
                        <div className={'grid-item'}>
                            <div>
                                <b>Ancienne provision :</b>{" "}
                                <Tag color="blue">
                                    <b>
                                        {sinistre?.evaluation
                                            ? new Intl.NumberFormat("en-DE").format(
                                                sinistre?.evaluation
                                            )
                                            : "0"}{" "}
                                        FCFA
                                    </b>
                                </Tag>
                            </div>
                            <br/>

                            <div className={' grid container'} justify="center">
                                <div className={'grid-item'} lg={12}>
                                    <div className={'form-control'}>
                                        <select
                                            className="select2 form-select"
                                            value={valueSelected}
                                            onChange={(value) => {
                                                let vs = valueSelected;
                                                if (value.length > vs.length) {
                                                    addProvisionFormFields();
                                                } else {
                                                    let outGarantie = value.filter(
                                                        (v) => !garanties.includes(v)
                                                    );
                                                    // console.log(outGarantie);

                                                    outGarantie &&
                                                    removeProvisionFormFields(
                                                        outGarantie[0]
                                                    );
                                                }
                                                var gs = [];
                                                value.forEach((v) => {
                                                    let g = garanties.find(
                                                        (gar) => gar.value === v
                                                    );
                                                    g && gs.push(g.garantie);
                                                });
                                                setValueSelected(value);
                                                setSelectedGaranties(gs);
                                                // console.log("Target...", value);
                                                value.length
                                                    ? setGarantieState("success")
                                                    : setGarantieState("error");
                                            }}
                                            /* options={garanties}
                                             placeholder="Sélectionnez des garanties"*/
                                        />
                                    </div>
                                </div>
                            </div>
                            {selectedGaranties.length ? (
                                <div className={' grid container'} sx={{
                                    justifyContent: 'center'
                                }}>
                                    {selectedGaranties.map((sg, index) => (
                                        <div className={'grid-item'}
                                             key={index}
                                        >
                                            <label
                                                htmlFor="capital"
                                                style={{
                                                    margin: "30px 0 10px 0",
                                                }}
                                            >
                                                Provision Garantie {sg.nom} :
                                            </label>
                                            <Input
                                                id="capital"
                                                name="capital"
                                                placeholder="Veuillez entrer la valeur de la provision"
                                                value={
                                                    provisionGaranties[index].provision
                                                }
                                                onChange={(event) => {
                                                    let newProvisionFormFields = [
                                                        ...provisionGaranties,
                                                    ];
                                                    newProvisionFormFields[
                                                        index
                                                        ].garantie = sg;
                                                    newProvisionFormFields[
                                                        index
                                                        ].provision = event.target.value;
                                                    setProvisionGarantie(
                                                        newProvisionFormFields
                                                    );
                                                }}
                                            />
                                        </div>
                                    ))}
                                </div>
                            ) : null}
                            <br/>
                            <div>
                                <b>Nouvelle provision :</b>{" "}
                                <Tag color="green">
                                    <b>
                                        {evaluation?.nouvelleProvision
                                            ? new Intl.NumberFormat("en-DE").format(
                                                evaluation?.nouvelleProvision
                                            )
                                            : "0"}{" "}
                                        FCFA
                                    </b>
                                </Tag>
                            </div>

                            <br/>
                            <div className={'form-control'}
                            >
                                <Select
                                    id="motifRevision"
                                    name="motifRevision"
                                    placeholder="Motif de la révision"
                                    dropdownRender={(menu) => (
                                        <>
                                            {menu}
                                            <Divider
                                                style={{
                                                    margin: "8px 0",
                                                }}
                                            />
                                            <Space
                                                style={{
                                                    padding: "0 8px 4px",
                                                }}
                                            >
                                                <Input
                                                    placeholder="Ajouter un motif"
                                                    ref={inputRef}
                                                    value={name}
                                                    onChange={onNameChange}
                                                />
                                                <Button
                                                    type="text"
                                                    icon={<PlusOutlined/>}
                                                    onClick={addItem}
                                                >
                                                    Ajouter un motif
                                                </Button>
                                            </Space>
                                        </>
                                    )}
                                    options={items.map((item) => ({
                                        label: item,
                                        value: item,
                                    }))}
                                    onChange={(value) => {
                                        handleChange(value, "motifRevision");
                                        console.log(value);
                                        if (value.trim().length > 3) {
                                            setMotifRevisionState("success");
                                        } else {
                                            setMotifRevisionState("error");
                                        }
                                    }}
                                    value={evaluation.motifRevision}
                                />
                            </div>
                            <br/>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </Modal>

    </>)
}

export default Details