import axios from "axios";
import Stepper from "bs-stepper";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../components/Footer";
import NavBar from "../../components/NavBar";
import SideBar from "../../components/SideBar";
import { API_URL, LOGGED_USER, TOKEN } from "../../variables/constants";

const AddUtilisateur = () => {
  const [profils, setProfils] = useState([]);
  const [agences, setAgences] = useState([]);
  const [selectedProfil, setSelectedProfil] = useState("");
  const [selectedAgence, setSelectedAgence] = useState("");
  const [activeTab, setActiveTab] = useState(1);
  const [loading, setLoading] = useState(false);
  const [mailExists, setMailExists] = useState(false);
  const [myAgence, setMyAgence] = useState("");

  const param = useParams();

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    nom: "",
    prenom: "",
    genre: "",
    fonction: "",
    adresseResidence: "",
    telephone: "",
    email: "",
  });

  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      checkIfEmailExists(value);
    }
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};
    const phonePattern = /^(22|90|91|92|93|96|98|99|70|71|79|99)\d{6}$/;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.nom) newErrors.nom = "Nom requis";
    if (formData.nom.length < 3)
      newErrors.nom = "Le nom doit avoir au moins 2 caractères";
    if (!formData.prenom) newErrors.prenom = "Prénom requis";
    if (formData.prenom.length < 3)
      newErrors.prenom = "Le prénom doit avoir au moins 2 caractères";
    if (!formData.genre) newErrors.genre = "Genre requis";
    if (!formData.telephone) newErrors.telephone = "Numéro de téléphone requis";
    if (!phonePattern.test(formData.telephone))
      newErrors.telephone = "Numéro de téléphone invalide";
    if (!formData.email) newErrors.email = "Adresse mail requis";
    if (!emailPattern.test(formData.email))
      newErrors.email = "L'adresse email n'est pas valide.";
    // Add more validation rules if needed
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    // Initialize the Stepper
    const stepperElement = document.querySelector(
      ".wizard-modern-vertical-icons-example"
    );
    if (stepperElement) {
      const stepper = new Stepper(stepperElement, { linear: false });

      const nextButtons = stepperElement.querySelectorAll(".btn-next");
      const prevButtons = stepperElement.querySelectorAll(".btn-prev");
      const submitButton = stepperElement.querySelector(".btn-submit");

      nextButtons.forEach((button) => {
        button.addEventListener("click", () => stepper.next());
      });

      prevButtons.forEach((button) => {
        button.addEventListener("click", () => stepper.previous());
      });

      submitButton?.addEventListener("click", () => {
        alert("Submitted..!!");
      });

      // Clean up event listeners on unmount
      return () => {
        nextButtons.forEach((button) => {
          button.removeEventListener("click", () => stepper.next());
        });

        prevButtons.forEach((button) => {
          button.removeEventListener("click", () => stepper.previous());
        });

        submitButton?.removeEventListener("click", () => {
          alert("Submitted..!!");
        });
      };
    }
  }, []);

  useEffect(() => {
    console.log("Agence: ", param?.codeAgence);
    if (param?.codeAgence) {
      axios
        .get(`${API_URL}/api/agence/code/${param.codeAgence}`, {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
          },
        }).then((res) => {
          console.log(res.data);
          getAgencies(res.data?.organisme?.organismeId);
          axios
            .get(`${API_URL}/api/profil/organisme/codeOrganisme/${res.data?.organisme?.code}`, {
              headers: {
                Authorization: `Bearer ${TOKEN}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                "Access-Control-Allow-Headers":
                  "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
              },
            })
            .then((resp) => {
              console.log("Profilssss", resp.data, "niceee", res.data);
              setProfils(resp.data);
            })
            .catch((err) => {
              console.log(err);
            });
          setSelectedAgence(res.data.codeId);
          setMyAgence(res.data);
        }).catch(err => {
          console.log(err);
        });


    } else {
      axios
        .get(`${API_URL}/api/profil/used`, {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
          },
        })
        .then((res) => {
          setProfils(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }

  }, [TOKEN]);

  const handleProfil = (codeId) => {
    const prof = profils.find((e) => e.codeId === codeId);
    setSelectedProfil(prof);
    getAgencies(prof.organisme.organismeId);
  };

  const getAgencies = (organismeId) => {
    axios
      .get(`${API_URL}/api/agence/organisme/${organismeId}`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
          "Access-Control-Allow-Headers":
            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        },
      })
      .then((res) => {
        console.log("Agences: ", res.data);
        setAgences(res.data);
        res.data && setSelectedAgence(res.data[0].codeId);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = (e) => {
    if (selectedProfil || myAgence.typeAgence === "COURTIER") {
      setLoading(true);
      e.preventDefault();
      const ag = agences.find((e) => e.codeId === selectedAgence);
      const user = {
        nom: formData.nom,
        prenom: formData.prenom,
        email: formData.email,
        contact: [formData.telephone],
        username: formData.username,
        password: formData.password,
        genre: formData.genre,
        adresse: formData.adresseResidence,
        fonction: formData.fonction,
        typePersonne: "PHYSIQUE",
        agence: ag,
        profil: selectedProfil,
      };
      if (myAgence.typeAgence === "COURTIER") {
        user.agence = myAgence;
        user.profil = null;
      }
      console.log("userrrrrr", user);
      axios
        .post(`${API_URL}/api/personne/register`, JSON.stringify(user), {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
          },
        })
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          param?.codeAgence ? myAgence?.typeAgence === "COURTIER" ? navigate(`/courtier/${ag.codeId}`, { replace: true }) : navigate(`/agence/${param?.codeAgence}`, { replace: true }) : navigate("/utilisateurs", { replace: true });
          toast("Utilisateur ajouté avec succès");
        })
        .catch((err) => {
          console.log(err);
          toast("Erreur lors de l'ajout de l'utilisateur");
          setLoading(false);
        });
    } else {
      toast("Veuillez sélectionner le profil de l'utilisateur");
    }
  };

  const checkIfEmailExists = (email) => {
    axios
      .get(`${API_URL}/api/utilisateur/check/${email}`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
          "Access-Control-Allow-Headers":
            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        },
      })
      .then((res) => {
        if (res.data)
          toast("Il existe déjà un utilisateur avec cette adresse mail!");
        setMailExists(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div class="layout-wrapper layout-content-navbar  ">
      <div class="layout-container">
        <SideBar currentMenu={"UTILISATEURS"} />

        {/* <!-- Layout container --> */}
        <div class="layout-page">
          <NavBar />

          {/* <!-- Content wrapper --> */}
          <div class="content-wrapper">
            {/* <!-- Content --> */}
            <div class="container-xxl flex-grow-1 container-p-y">
              <div class="row">
                <div className="col-12 mb-4">
                  <small className="text-light fw-medium">
                    Ajouter un utilisateur
                  </small>
                  <div className="bs-stepper vertical wizard-modern wizard-modern-vertical mt-2">
                    <div className="bs-stepper-header">
                      <div
                        className={activeTab === 1 ? "step active" : "step"}
                        data-target="#personal-info-vertical-modern"
                      >
                        <button
                          type="button"
                          className="step-trigger"
                          onClick={() => setActiveTab(1)}
                        >
                          <span className="bs-stepper-circle">
                            <i className="bx bx-user"></i>
                          </span>
                          <span className="bs-stepper-label">
                            <span className="bs-stepper-title">
                              Informations personnelles
                            </span>
                            <span className="bs-stepper-subtitle">
                              Ajouter les infos personnelles
                            </span>
                          </span>
                        </button>
                      </div>
                      <div className="line"></div>
                      <div
                        className={activeTab === 2 ? "step active" : "step"}
                        data-target="#social-links-vertical-modern"
                      >
                        <button
                          type="button"
                          className="step-trigger"
                          onClick={() => myAgence?.typeAgence !== "COURTIER" && setActiveTab(2)}
                        >
                          <span className="bs-stepper-circle">
                            <i className="bx bxl-instagram"></i>
                          </span>
                          <span className="bs-stepper-label">
                            <span className="bs-stepper-title">Profil</span>
                            <span className="bs-stepper-subtitle">
                              Configurer le profil
                            </span>
                          </span>
                        </button>
                      </div>
                    </div>
                    <div className="bs-stepper-content">
                      <form onSubmit={(e) => e.preventDefault()}>
                        {/* Personal Info */}
                        {activeTab === 1 && (
                          <div
                            id="personal-info-vertical-modern"
                            className={
                              activeTab === 1 ? "content active" : "content"
                            }
                          >
                            <div className="content-header mb-4">
                              <h6 className="mb-0">
                                Informations personnelles
                              </h6>
                              <small>
                                Veuillez renseigner les informations
                                personnelles
                              </small>
                            </div>
                            <div className="row g-6">
                              <div className="col-sm-6">
                                <label className="form-label" htmlFor="nom">
                                  Nom
                                </label>
                                <input
                                  type="text"
                                  id="nom"
                                  name="nom"
                                  className={`form-control ${errors.nom ? "is-invalid" : ""
                                    }`}
                                  placeholder="Entrez le nom"
                                  value={formData.nom}
                                  onChange={handleInputChange}
                                />
                                {errors.nom && (
                                  <div className="invalid-feedback">
                                    {errors.nom}
                                  </div>
                                )}
                              </div>
                              <div className="col-sm-6">
                                <label className="form-label" htmlFor="prenom">
                                  Prénom(s)
                                </label>
                                <input
                                  type="text"
                                  id="prenom"
                                  name="prenom"
                                  className={`form-control ${errors.prenom ? "is-invalid" : ""
                                    }`}
                                  placeholder="Entrez le prénom"
                                  value={formData.prenom}
                                  onChange={handleInputChange}
                                />
                                {errors.prenom && (
                                  <div className="invalid-feedback">
                                    {errors.prenom}
                                  </div>
                                )}
                              </div>
                              <div className="col-sm-6">
                                <label class="form-label" for="genre">
                                  Genre
                                </label>
                                <select
                                  id="genre"
                                  name="genre"
                                  class={`form-control w-100 ${errors.genre ? "is-invalid" : ""
                                    }`}
                                  data-style="btn-default"
                                  value={formData.genre}
                                  onChange={handleInputChange}
                                >
                                  <option value="">
                                    Selectionnez le genre
                                  </option>
                                  <option value="MASCULIN">Masculin</option>
                                  <option value="FEMININ">Féminin</option>
                                </select>
                                {errors.genre && (
                                  <div className="invalid-feedback">
                                    {errors.genre}
                                  </div>
                                )}
                              </div>
                              <div className="col-sm-6">
                                <label className="form-label" htmlFor="nom">
                                  Fonction
                                </label>
                                <input
                                  type="text"
                                  id="fonction"
                                  name="fonction"
                                  className={`form-control ${errors.fonction ? "is-invalid" : ""
                                    }`}
                                  placeholder="Entrez la fonction de l'utilisateur"
                                  value={formData.fonction}
                                  onChange={handleInputChange}
                                />
                                {errors.nom && (
                                  <div className="invalid-feedback">
                                    {errors.fonction}
                                  </div>
                                )}
                              </div>
                              <div className="col-sm-6">
                                <label className="form-label" htmlFor="email">
                                  Adresse mail
                                </label>
                                <div class="input-group input-group-merge">
                                  <input
                                    type="text"
                                    id="email"
                                    name="email"
                                    className={`form-control ${errors.email ? "is-invalid" : ""
                                      }`}
                                    placeholder="Veuillez renseigner l'adresse mail"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                  />
                                  {LOGGED_USER?.profil?.organisme?.domaine && (
                                    <span
                                      class="input-group-text"
                                      id="basic-addon33"
                                    >
                                      @{LOGGED_USER?.profil?.organisme?.domaine}
                                    </span>
                                  )}
                                  {errors.email && (
                                    <div className="invalid-feedback">
                                      {errors.email}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <label class="form-label" for="telephone">
                                  Numéro de téléphone
                                </label>
                                <div class="input-group">
                                  <span class="input-group-text">
                                    TG (+228)
                                  </span>
                                  <input
                                    type="text"
                                    id="telephone"
                                    name="telephone"
                                    placeholder="00 00 00 00"
                                    className={`form-control phone-number-mask ${errors.telephone ? "is-invalid" : ""
                                      }`}
                                    value={formData.telephone}
                                    onChange={handleInputChange}
                                  />
                                </div>
                                {errors.telephone && (
                                  <div className="invalid-feedback">
                                    {errors.telephone}
                                  </div>
                                )}
                              </div>
                              <div className="col-sm-6">
                                <label
                                  class="form-label"
                                  for="adresseResidence"
                                >
                                  Adresse de résidence
                                </label>
                                <textarea
                                  id="adresseResidence"
                                  name="adresseResidence"
                                  class="form-control bootstrap-maxlength-example"
                                  maxlength="255"
                                  placeholder="L'adresse de résidence de l'utilisateur"
                                  value={formData.adresseResidence}
                                  onChange={handleInputChange}
                                ></textarea>
                              </div>
                              <div className="col-12 d-flex justify-content-between">
                                <div></div>
                                {myAgence?.typeAgence === "COURTIER" ?
                                  <button
                                    className="btn btn-success"
                                    onClick={(e) => {
                                      checkIfEmailExists(formData.email);
                                      validateForm() & !mailExists && handleSubmit(e);
                                    }}
                                    disabled={loading}
                                  >
                                    {loading && (
                                      <span
                                        class="spinner-grow me-1"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                    )}
                                    <span className="align-middle d-sm-inline-block d-none me-sm-2">
                                      {loading
                                        ? `Enregistrement...`
                                        : `Enregistrer`}
                                    </span>
                                    <i className="bx bx-right-arrow-alt bx-sm me-sm-n2"></i>
                                  </button> : <button
                                    className="btn btn-primary"
                                    onClick={() => {
                                      checkIfEmailExists(formData.email);
                                      validateForm() & !mailExists &&
                                        setActiveTab(2);
                                    }}
                                  >
                                    <span className="align-middle d-sm-inline-block d-none me-sm-2">
                                      Suivant
                                    </span>
                                    <i className="bx bx-right-arrow-alt bx-sm me-sm-n2"></i>
                                  </button>}


                              </div>
                            </div>
                          </div>
                        )}

                        {/* Profil details */}
                        {activeTab === 2 && (
                          <div
                            id="social-links-vertical-modern"
                            className={
                              activeTab === 2 ? "content active" : "content"
                            }
                          >
                            <div className="content-header mb-4">
                              <h6 className="mb-0">Profil</h6>
                              <small>
                                Renseigner le profil de l'utilisateur
                              </small>
                            </div>
                            <div className="row g-6">
                              <div className="col-sm-6">
                                <label
                                  for="selectpickerBasic1"
                                  class="form-label"
                                >
                                  Profil
                                </label>
                                <select
                                  id="selectpickerBasic1"
                                  class="form-control w-100"
                                  data-style="btn-default"
                                  onChange={(e) => handleProfil(e.target.value)}
                                  disabled={myAgence.typeAgence === "COURTIER"}
                                >
                                  <option value="">
                                    Veuillez sélectionner le profil
                                  </option>
                                  {profils?.map((pro, id) => (
                                    <option key={id} value={pro.codeId}>
                                      {pro.nom} - {pro.organisme.raisonSociale}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="col-sm-6">
                                <label for="agencySelection" class="form-label">
                                  Agence
                                </label>
                                <select
                                  id="agencySelection"
                                  class="form-control w-100"
                                  data-style="btn-default"
                                  onChange={(e) =>
                                    setSelectedAgence(e.target.value)
                                  }
                                  value={selectedAgence}
                                  disabled={param?.codeAgence ? true : false}
                                >
                                  {agences.map((ag, id) => (
                                    <option key={id} value={ag.codeId}>
                                      {ag.nom} - {ag.ville}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="col-12 d-flex justify-content-between">
                                <button
                                  className="btn btn-primary"
                                  onClick={() => setActiveTab(1)}
                                >
                                  <i className="bx bx-left-arrow-alt bx-sm ms-sm-n2 me-sm-2"></i>
                                  <span className="align-middle d-sm-inline-block d-none">
                                    Précédent
                                  </span>
                                </button>
                                <button
                                  className="btn btn-success"
                                  onClick={(e) => handleSubmit(e)}
                                  disabled={loading}
                                >
                                  {loading && (
                                    <span
                                      class="spinner-grow me-1"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                  )}
                                  <span className="align-middle d-sm-inline-block d-none me-sm-2">
                                    {loading
                                      ? `Enregistrement...`
                                      : `Enregistrer`}
                                  </span>
                                  <i className="bx bx-right-arrow-alt bx-sm me-sm-n2"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- / Content --> */}

            {/* <!-- Footer --> */}
            <Footer />
            {/* <!-- / Footer --> */}

            <div class="content-backdrop fade"></div>
          </div>
          {/* <!-- Content wrapper --> */}
        </div>
        {/* <!-- / Layout page --> */}
      </div>

      {/* <!-- Overlay --> */}
      <div class="layout-overlay layout-menu-toggle"></div>

      {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
      <div class="drag-target"></div>
    </div>
  );
};

export default AddUtilisateur;
