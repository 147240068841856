import React from 'react';
import {Button} from "antd";

const DynamicStepper = ({steps, initialValues, validationSchema, onSubmit, formik, currentStep, setCurrentStep}) => {

    const isLastStep = currentStep === steps.length - 1;


    const nextStep = () => {
        if (currentStep < steps.length - 1) setCurrentStep(currentStep + 1);
    };

    const prevStep = () => {
        if (currentStep > 0) setCurrentStep(currentStep - 1);
    };

    const getVisibleSteps = (steps, currentStep) => {
        if (currentStep === 0) {
            return {visible: steps.slice(0, 3), showDotsBefore: false, showDotsAfter: steps.length > 3};
        } else if (currentStep === steps.length - 1) {
            return {visible: steps.slice(-3), showDotsBefore: steps.length > 3, showDotsAfter: false};
        } else {
            return {
                visible: steps.slice(currentStep - 1, currentStep + 2),
                showDotsBefore: currentStep > 1,
                showDotsAfter: currentStep < steps.length - 2,
            };
        }
    };

    const handleSave = async (AdversaireValidationSchema) => {
        const response = onSubmit(AdversaireValidationSchema, formik, currentStep, currentStep === steps.length - 1);

    }


    return (
        <form>
            <div className="bs-stepper">
                <div className="bs-stepper-header" style={{display: 'flex', alignItems: 'center', gap: '1rem'}}>
                    {getVisibleSteps(steps, currentStep).showDotsBefore && (
                        <div style={{width: '20px', textAlign: 'center'}}>...</div>
                    )}
                    {getVisibleSteps(steps, currentStep).visible.map((step, index) => {
                        const stepIndex = steps.indexOf(step);
                        return (
                            <React.Fragment key={stepIndex}>
                                <div
                                    className={`step ${currentStep === stepIndex ? 'active' : ''}`}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        textAlign: 'center',
                                    }}
                                    onClick={() => stepIndex <= currentStep && setCurrentStep(stepIndex)}
                                >
                                    <button
                                        type="button"
                                        className="step-trigger"
                                        style={{
                                            border: '2px solid',
                                            borderColor: currentStep >= stepIndex ? '#007bff' : '#ccc',
                                            backgroundColor: currentStep === stepIndex ? '#007bff' : '#fff',
                                            color: currentStep === stepIndex ? '#fff' : '#000',
                                            borderRadius: '5px',
                                            width: '40px',
                                            height: '40px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            fontSize: '14px',
                                            fontWeight: 'bold',
                                            cursor: 'pointer',
                                            transition: 'all 0.3s ease',
                                        }}
                                    >
                                        {stepIndex + 1}
                                    </button>
                                    <div
                                        style={{
                                            marginTop: '8px',
                                            fontSize: '14px',
                                            fontWeight: '500',
                                            color: currentStep >= stepIndex ? '#007bff' : '#000',
                                        }}
                                    >
                                        {step.title}
                                    </div>
                                    <div
                                        style={{
                                            fontSize: '12px',
                                            color: '#6c757d',
                                        }}
                                    >
                                        {step.subtitle}
                                    </div>
                                </div>
                                {stepIndex < steps.length - 1 && (
                                    <div
                                        className="line"
                                        style={{
                                            width: '100%',
                                            height: '2px',
                                            backgroundColor: currentStep >= stepIndex + 1 ? '#007bff' : '#ccc',
                                            margin: '0 10px',
                                            flex: '1',
                                        }}
                                    />
                                )}
                            </React.Fragment>
                        );
                    })}
                    {getVisibleSteps(steps, currentStep).showDotsAfter && (
                        <div style={{width: '20px', textAlign: 'center'}}>...</div>
                    )}
                </div>
                <div className="bs-stepper-content">
                    {steps[currentStep].content}
                    <div className="d-flex justify-content-between mt-4">
                        <Button
                            type="button"
                            className="btn btn-secondary"
                            onClick={prevStep}
                            disabled={currentStep === 0}
                        >
                            Précédent
                        </Button>
                        <Button
                            type="submit"
                            className={`btn ${isLastStep ? 'btn-success' : 'btn-primary'}`}

                            onClick={() => handleSave(validationSchema)}
                        >
                            {isLastStep ? 'Enregistrer' : 'Suivant'}
                        </Button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default DynamicStepper;
