import {Dropdown, Menu, Table, Tag} from "antd";
import {frenchDate} from "../../../../variables/functions";
import {EllipsisOutlined} from "@ant-design/icons";

const DecaissementsTable = ({
                                decaissements,
                                showQuittance,
                                showFicheDemandeReglement,
                                showOrdreDepense,
                                setModalAssignCheque,
                                setDecaissement,
                            }) => {
    const customizedData = decaissements.map((item) => ({
        ...item,
        beneficiaire: `${item.beneficiaire.nom} ${item.beneficiaire.prenom}`,
        dateReglement: frenchDate(item.dateReglement)
    }));

    const columns = [
        {
            title: "Bénéficiaire",
            dataIndex: "beneficiaire",
            key: "beneficiaire",
        },
        {
            title: "Montant TTC (FCFA)",
            dataIndex: "montantGlobal",
            key: "montantGlobal",
            render: (montantGlobal) => `${new Intl.NumberFormat("en-DE").format(Math.trunc(montantGlobal))} FCFA`,
        },
        {
            title: "Montant retenu (FCFA)",
            dataIndex: "montantPaye",
            key: "montantPaye",
            render: (montantPaye, record) => `${record.montantPaye ? new Intl.NumberFormat("en-DE").format(Math.trunc(record.montantGlobal - montantPaye)) : "0"} FCFA`,
        },
        {
            title: "Date de règlement",
            dataIndex: "dateReglement",
            key: "dateReglement",
        },
        {
            title: "État Décaissement",
            dataIndex: "etatDecaissement",
            key: "etatDecaissement",
            render: (etatDecaissement) => (
                <Tag
                    color={
                        etatDecaissement === "VALIDE"
                            ? "green"
                            : etatDecaissement === "EN_ATTENTE"
                                ? "orange"
                                : "red"
                    }
                    style={{padding: "7px", textAlign: "center"}}
                >
                    {etatDecaissement}
                </Tag>
            ),
        },
        {
            title: "Actions",
            key: "actions",
            render: (_, item) => {

                const menu =
                    (
                        <Menu>
                            {item.etatDecaissement === "VALIDE" && (
                                <>
                                    {item.numeroCheque ? (
                                        <Menu.Item key="quittance" onClick={() => showQuittance(item)}>
                                            Quittance
                                        </Menu.Item>
                                    ) : (
                                        <Menu.Item
                                            key="assignCheque"
                                            onClick={() => {
                                                setDecaissement(item);
                                                setModalAssignCheque(true);
                                            }}
                                        >
                                            Assigner Chèque
                                        </Menu.Item>
                                    )}
                                    <Menu.Item key="ordreDepense" onClick={() => showOrdreDepense(item)}>
                                        Ordre de Dépense
                                    </Menu.Item>
                                    <Menu.Item
                                        key="ficheDemandeReglement"
                                        onClick={() => showFicheDemandeReglement(item)}
                                    >
                                        Fiche Demande de Règlement
                                    </Menu.Item>
                                </>
                            )}
                        </Menu>
                    )
                return (
                    <Dropdown overlay={menu} trigger={['click']}>
                        <EllipsisOutlined style={{cursor: "pointer"}}/>
                    </Dropdown>
                )

            },
        },
    ];

    return (
        <Table
            dataSource={customizedData}
            columns={columns}
            rowKey="decaissementId"
            pagination={{pageSize: 10}}
        />
    );
};

export default DecaissementsTable;
