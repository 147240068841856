import {Card, ConfigProvider, Divider, Switch, Table, Typography} from "antd";
import {toast} from "react-toastify";
import {FormDatePicker, FormInput, FormSelect} from "../../../../components/forms/FormInputs";
import React from "react";
import dayjs from "dayjs";

const {Text} = Typography;


const getFieldPath = (field) => `items[${0}].${field}`;
const getEnginNestedFieldPath = (nestedField) => `items[${0}].engin.${nestedField}`;
const getConducteurNestedFieldPath = (nestedField) => `items[${0}].conducteur.${nestedField}`;


const listeUsage = [
    {value: "PRIVE", label: "PRIVÉ"},
    {value: "TAXI", label: "TAXI"},
];
const listeDesCategoriesDePermis = [
    {value: "A1", label: "A1"},
    {value: "B", label: "B"},
    {value: "C", label: "C"},
    {value: "D", label: "D"},
];

const listeDesQualitesDuConducteur = [
    {value: "propriétaire", label: "Propriétaire"},
    {value: "locataire", label: "Locataire"},
    {value: "emprunteur", label: "Emprunteur"},
    {value: "salarié", label: "Salarié"},
];
const listeDesTypesDeDommagesPossibles = [
    {value: "MATERIEL", label: "Matériel"},
    {value: "CORPOREL", label: "Corporel"},
    {value: "MIXTE", label: "Mixte"},
];

const getFrenchDate = () => {
    const now = dayjs();
    return now.format("DD-MM-YYYY");
};

const adversaireVehicleColumns = [
    {title: "Marque", dataIndex: ["engin", "marque"], key: "marque"},
    {title: "Puissance", dataIndex: ["engin", "puissance"], key: "puissance"},
    {title: "Immatriculation", dataIndex: ["engin", "immatriculation"], key: "immatriculation"},
    {title: "Usage", dataIndex: ["engin", "usage"], key: "usage"},
];


export const getPietonFormSteps = (formik) => {
    const handleFieldErrorToast = (fieldName) => {
        const fieldMeta = formik.getFieldMeta(fieldName);
        fieldMeta.touched = true;
        if (fieldMeta.touched && fieldMeta.error) {
            toast.error(fieldMeta.error, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };
    const currentItem = formik.values.items[0];

    return [
        {
            title: 'Informations personnelles',
            subtitle: '-',
            content: (
                <>
                    <FormInput
                        label={`Nom de l'adversaire`}
                        name={getFieldPath("nom")}
                        value={currentItem.nom || ""}
                        onBlur={() => {
                            handleFieldErrorToast(getFieldPath("nom"))
                        }}
                        onChange={(event) => formik.handleChange(event)}
                    />
                    <FormInput
                        label={`Prénoms de l'adversaire`}
                        name={getFieldPath("prenom")}
                        value={currentItem.prenom || ""}
                        onBlur={() => handleFieldErrorToast(getFieldPath("prenom"))}
                        onChange={(event) => formik.handleChange(event)}
                    />
                    <FormInput
                        label={`Adresse de l'adversaire`}
                        name={getFieldPath("adresse")}
                        value={currentItem.adresse || ""}
                        onBlur={() => handleFieldErrorToast(getFieldPath("adresse"))}
                        onChange={(event) => formik.handleChange(event)}
                    />
                    <FormInput
                        label={`Profession de l'adversaire`}
                        name={getFieldPath("profession")}
                        value={currentItem.profession || ""}
                        onBlur={() => handleFieldErrorToast(getFieldPath("profession"))}
                        onChange={(event) => formik.handleChange(event)}
                    />
                    <FormInput
                        label={`Numero de téléphone de l'adversaire`}
                        name={getFieldPath("telephone")}
                        value={currentItem.telephone || ""}
                        onBlur={() => handleFieldErrorToast(getFieldPath("telephone"))}
                        onChange={(event) => formik.handleChange(event)}
                    />
                    <FormSelect
                        label={`Type de dommages chez l'adversaire`}
                        name={getFieldPath("typeSinistre")}
                        value={currentItem.typeSinistre || ""}
                        onBlur={() => handleFieldErrorToast(getEnginNestedFieldPath("typeSinistre"))}
                        onChange={(event) => {
                            console.log(event.target.value); // Logs selected value
                            formik.setFieldValue(event.target.name, event.target.value);
                        }} options={listeDesTypesDeDommagesPossibles}
                    />
                </>
            ),
        },
        {
            title: 'Resumé des informations',
            subtitle: '-',
            content: (
                <>
                    <Card bordered title="Informations des Adversaires" style={{width: "100%"}}>
                        <>
                            <div>
                                <>
                                    <div>
                                        <Text strong>Nom:</Text>
                                        <Text>{formik.values.items[0].nom || "--"}</Text>
                                    </div>
                                    <div>
                                        <Text strong>Prénom(s):</Text>
                                        <Text>{formik.values.items[0].prenom || "--"}</Text>
                                    </div>

                                </>
                            </div>

                        </>
                    </Card>

                </>
            )

        }

    ]

}

export const getVehiculeFormSteps = (formik, wasAdversaireConducteur, setWasAdversaireConducteur) => {
    const handleFieldErrorToast = (fieldName) => {
        const fieldMeta = formik.getFieldMeta(fieldName);
        fieldMeta.touched = true;
        if (fieldMeta.touched && fieldMeta.error) {
            toast.error(fieldMeta.error, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };
    const currentItem = formik.values.items[0];

    return [
        {
            title: 'Informations personnelles',
            subtitle: '-',
            content: (
                <>
                    <FormInput
                        label={`Nom de l'adversaire`}
                        name={getFieldPath("nom")}
                        value={currentItem.nom || ""}
                        onBlur={() => {
                            handleFieldErrorToast(getFieldPath("nom"))
                        }}
                        onChange={(event) => formik.handleChange(event)}
                    />
                    <FormInput
                        label={`Prénoms de l'adversaire`}
                        name={getFieldPath("prenom")}
                        value={currentItem.prenom || ""}
                        onBlur={() => handleFieldErrorToast(getFieldPath("prenom"))}
                        onChange={(event) => formik.handleChange(event)}
                    />
                    <FormInput
                        label={`Adresse de l'adversaire`}
                        name={getFieldPath("adresse")}
                        value={currentItem.adresse || ""}
                        onBlur={() => handleFieldErrorToast(getFieldPath("adresse"))}
                        onChange={(event) => formik.handleChange(event)}
                    />
                    <FormInput
                        label={`Profession de l'adversaire`}
                        name={getFieldPath("profession")}
                        value={currentItem.profession || ""}
                        onBlur={() => handleFieldErrorToast(getFieldPath("profession"))}
                        onChange={(event) => formik.handleChange(event)}
                    />
                    <FormInput
                        label={`Numero de téléphone de l'adversaire`}
                        name={getFieldPath("telephone")}
                        value={currentItem.telephone || ""}
                        onBlur={() => handleFieldErrorToast(getFieldPath("telephone"))}
                        onChange={(event) => formik.handleChange(event)}
                    />
                    <FormSelect
                        label={`Type de dommages chez l'adversaire`}
                        name={getFieldPath("typeSinistre")}
                        value={currentItem.typeSinistre || ""}
                        onBlur={() => handleFieldErrorToast(getEnginNestedFieldPath("typeSinistre"))}
                        onChange={(event) => {
                            console.log(event.target.value); // Logs selected value
                            formik.setFieldValue(event.target.name, event.target.value);
                        }} options={listeDesTypesDeDommagesPossibles}
                    />
                </>
            ),
        },
        {
            title: `Engin de l'adversaire`,
            subtitle: `Fournir des informations sur l'engin`,
            content: (
                <>
                    <FormInput
                        label={`Marque du vehicule de l'adversaire`}
                        name={getEnginNestedFieldPath("marque")}
                        value={currentItem.engin.marque || ""}
                        onBlur={() => handleFieldErrorToast(getEnginNestedFieldPath("marque"))}
                        onChange={(event) => formik.handleChange(event)}
                    />
                    <FormInput
                        label={`Puissance du vehicule de l'adversaire`}
                        name={getEnginNestedFieldPath("puissance")}
                        value={currentItem.engin.puissance || ""}
                        onBlur={() => handleFieldErrorToast(getEnginNestedFieldPath("puissance"))}
                        onChange={(event) => formik.handleChange(event)}
                    />

                    <FormInput
                        label={`Numéro de la plaque du véhicule de l'adversaire`}
                        name={getEnginNestedFieldPath("immatriculation")}
                        value={currentItem.engin.immatriculation || ""}
                        onBlur={() => handleFieldErrorToast(getEnginNestedFieldPath("immatriculation"))}
                        onChange={(event) => formik.handleChange(event)}
                    />
                    <FormSelect
                        label={`Usage du véhicule de l'adversaire`}
                        name={getEnginNestedFieldPath("usage")}
                        value={currentItem.engin.usage}
                        onBlur={() => handleFieldErrorToast(getEnginNestedFieldPath("usage"))}
                        onChange={(event) => {
                            console.log(event.target.value); // Logs selected value
                            formik.setFieldValue(event.target.name, event.target.value);
                        }}
                        options={listeUsage}
                    />
                </>
            ),
        },
        {
            title: 'Informations sur le conducteur',
            subtitle: '-',
            content: (
                <>
                    <Switch checkedChildren="L'assuré conduisait" unCheckedChildren="L'assuré ne conduisait pas"
                            onChange={(value) => {
                                console.log(value)
                                setWasAdversaireConducteur(value)
                                formik.setFieldValue(getFieldPath("conduisait"), value === true ? "oui" : "non")
                            }}
                            defaultChecked/>
                    {
                        !wasAdversaireConducteur && (
                            <>
                                <FormInput
                                    label={`Nom du conducteur`}
                                    name={getConducteurNestedFieldPath("nom")}
                                    value={currentItem.conducteur.nom || ""}
                                    onBlur={() => handleFieldErrorToast(getConducteurNestedFieldPath("nom"))}
                                    onChange={(event) => formik.handleChange(event)}
                                />
                                <FormInput
                                    label={`Prénoms du conducteur`}
                                    name={getConducteurNestedFieldPath("prenom")}
                                    value={currentItem.conducteur.prenom || ""}
                                    onBlur={() => handleFieldErrorToast(getConducteurNestedFieldPath("prenom"))}
                                    onChange={(event) => formik.handleChange(event)}
                                />
                                <FormInput
                                    label={`Numero de téléphone  du conducteur`}
                                    name={getConducteurNestedFieldPath("telephone")}
                                    value={currentItem.conducteur.telephone || ""}
                                    onBlur={() => handleFieldErrorToast(getConducteurNestedFieldPath("telephone"))}
                                    onChange={(event) => formik.handleChange(event)}
                                />
                            </>
                        )
                    }
                    <>
                        <FormInput
                            label={`Numéro du permis du conducteur`}
                            name={getConducteurNestedFieldPath("numeroPermis")}
                            value={currentItem.conducteur.numeroPermis || ""}
                            onBlur={() => handleFieldErrorToast(getConducteurNestedFieldPath("numeroPermis"))}
                            onChange={(event) => formik.handleChange(event)}
                        />
                        <FormSelect
                            label={`Catégorie du permis du conducteur`}
                            name={getConducteurNestedFieldPath("categoriePermis")}
                            value={currentItem.conducteur.categoriePermis || ""}
                            onBlur={() => handleFieldErrorToast(getEnginNestedFieldPath("categoriePermis"))}
                            onChange={(event) => {
                                console.log(event.target.value); // Logs selected value
                                formik.setFieldValue(event.target.name, event.target.value);
                            }} options={listeDesCategoriesDePermis}
                        />
                        <ConfigProvider locale={'frFR'}>
                            <FormDatePicker
                                label="Date de délivrance du permis du conducteur"
                                name={getConducteurNestedFieldPath("dateDelivrance")}
                                value={currentItem.conducteur.dateDelivrance || null}
                                errorState={formik.errors}
                                onBlur={formik.handleBlur}
                                maxDate={dayjs(getFrenchDate(), 'DD-MM-YYYY')}
                                onChange={(date, dateString) => {
                                    if (date) {
                                        formik.setFieldValue(getConducteurNestedFieldPath("dateDelivrance"), date.toISOString());
                                    } else {
                                        formik.setFieldValue(getConducteurNestedFieldPath("dateDelivrance"), null);
                                    }
                                }}
                                picker="date"
                            />
                        </ConfigProvider>
                        <FormInput
                            label={`Lieu de délivrance du permis du conducteur`}
                            name={getConducteurNestedFieldPath("lieuDelivrance")}
                            value={currentItem.conducteur.lieuDelivrance || ""}
                            onBlur={() => handleFieldErrorToast(getConducteurNestedFieldPath("lieuDelivrance"))}
                            onChange={(event) => formik.handleChange(event)}
                        />
                        <FormDatePicker
                            label="Date d'expiration du permis du conducteur"
                            name={getConducteurNestedFieldPath("dateExpiration")}
                            value={currentItem.conducteur.dateExpiration || null}
                            errorState={formik.errors}
                            onBlur={formik.handleBlur}
                            onChange={(date, dateString) => {
                                if (date) {
                                    formik.setFieldValue(getConducteurNestedFieldPath("dateExpiration"), date.toISOString());
                                } else {
                                    formik.setFieldValue(getConducteurNestedFieldPath("dateExpiration"), null);
                                }
                            }}
                            picker="date"
                        />

                        <FormSelect
                            label={` Qualité du conducteur`}
                            name={getConducteurNestedFieldPath("qualite")}
                            value={currentItem.conducteur.qualite || ""}
                            onBlur={() => handleFieldErrorToast(getConducteurNestedFieldPath("qualite"))}
                            onChange={(event) => {
                                console.log(event.target.value); // Logs selected value
                                formik.setFieldValue(event.target.name, event.target.value);
                            }} options={listeDesQualitesDuConducteur}/>
                    </>
                </>
            ),
        },
        {
            title: 'Informations supplementaires',
            subtitle: '-',
            content: (
                <>
                    <FormInput
                        label={`Nombre de personnes transportées`}
                        name={getEnginNestedFieldPath("nombrePersonneTransportees")}
                        value={currentItem.engin.nombrePersonneTransportees || ""}
                        onBlur={() => handleFieldErrorToast(getEnginNestedFieldPath("nombrePersonneTransportees"))}
                        onChange={(event) => formik.handleChange(event)}
                    />
                    <FormInput
                        label={`Société d'assurance de l'adversaire`}
                        name={getFieldPath("societeAssurance")}
                        value={currentItem.societeAssurance || ""}
                        onBlur={() => handleFieldErrorToast(getFieldPath("societeAssurance"))}
                        onChange={(event) => formik.handleChange(event)}
                    />
                    <FormInput
                        label={`La voiture l'adversaire venant de`}
                        name={getFieldPath("provenance")}
                        value={currentItem.provenance || ""}
                        onBlur={() => handleFieldErrorToast(getFieldPath("provenance"))}
                        onChange={(event) => formik.handleChange(event)}
                    />
                    <FormInput
                        label={`La voiture l'adversaire se dirigeant vers`}
                        name={getFieldPath("destination")}
                        value={currentItem.destination || ""}
                        onBlur={() => handleFieldErrorToast(getFieldPath("destination"))}
                        onChange={(event) => formik.handleChange(event)}
                    />
                    <FormInput
                        label={` À quelle vitesse allait la voiture de l'adversaire?`}
                        name={getFieldPath("vitesse")}
                        value={currentItem.vitesse || ""}
                        onBlur={() => handleFieldErrorToast(getFieldPath("vitesse"))}
                        onChange={(event) => formik.handleChange(event)}
                    />
                </>
            ),
        },
        {
            title: 'Resumé des informations',
            subtitle: '-',
            content: (
                <>
                    <Card bordered title="Informations des Adversaires" style={{width: "100%"}}>
                        <>

                            <div>
                                {
                                    formik.values.items[0].conduisait === 'oui' ? (
                                        <>
                                            <div>
                                                <Text strong>Nom:</Text>
                                                <Text>{formik.values.items[0].nom || "--"}</Text>
                                            </div>
                                            <div>
                                                <Text strong>Prénom(s):</Text>
                                                <Text>{formik.values.items[0].prenom || "--"}</Text>
                                            </div>
                                            <Text> L'assuré conduisait le véhicule </Text>
                                        </>
                                    ) : (
                                        <>
                                            <div>
                                                <Text strong>Nom:</Text>
                                                <Text>{formik.values.items[0].nom || "--"}</Text>
                                            </div>
                                            <div>
                                                <Text strong>Prénom(s):</Text>
                                                <Text>{formik.values.items[0].prenom || "--"}</Text>
                                            </div>
                                            <Text> {formik.values.items[0]?.conducteur.nom} {formik.values.items[0]?.conducteur.prenom} conduisait
                                                a la place de l'assuré </Text>
                                        </>
                                    )
                                }
                            </div>
                            <div style={{marginTop: "10px"}}>
                                <Divider>Informations sur l'engin</Divider>
                                <Table
                                    columns={adversaireVehicleColumns}
                                    dataSource={[formik.values.items[0]]}
                                    pagination={false}
                                    locale={{emptyText: "-"}}
                                />
                            </div>
                        </>
                    </Card>

                </>
            )

        }

    ]
}