import React, {forwardRef} from "react";
import {DatePicker, Input, Radio, Select} from "antd";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";

const {Option} = Select;

export const FormInput = ({label, name, value, errorState, onChange, onBlur}) => {
    return (
        <div className="grid-item">
            <label
                htmlFor={name}
                style={{
                    margin: "30px 0 10px 0",
                    color: errorState?.[name] ? "red" : null,
                }}
            >
                {label}
            </label>
            <Input
                name={name}
                placeholder={label}
                value={value || ""}
                status={errorState?.[name] ? "error" : null}
                onBlur={() => onBlur(name)}
                onChange={onChange}

            />
        </div>
    );
};


export const FormSelect = ({
                               label,
                               name,
                               value,
                               errorState,
                               onChange,
                               onBlur,
                               options,
                           }) => {
    // Wrap the value in an event-like object for better form compatibility
    const handleChange = (selectedValue) => {
        onChange({target: {name, value: selectedValue}});
    };

    return (
        <div className="grid-item">
            <label
                htmlFor={name}
                style={{
                    margin: "30px 0 10px 0",
                    color: errorState?.[name] ? "red" : null,
                }}
            >
                {label}
            </label>
            <Select
                name={name}
                value={value || ""}
                status={errorState?.[name] ? "error" : null}
                onBlur={() => onBlur(name)}
                onChange={handleChange}
                placeholder={`Selectionner ${label}`}
                style={{width: "100%"}}
            >
                {options.map((option, index) => (
                    <Option key={index} value={option.value}>
                        {option.label}
                    </Option>
                ))}
            </Select>
        </div>
    );
};

export const FormRadio = forwardRef(
    ({label, name, value, errorState, onChange, onBlur, options}, ref) => {
        return (
            <div className="grid-item">
                <label
                    htmlFor={name}
                    style={{
                        margin: "30px 0 10px 0",
                    }}
                >
                    {label}
                </label>
                <Radio.Group
                    name={name}
                    value={value || ""}
                    onBlur={() => onBlur(name)}
                    onChange={(e) => onChange(e.target.value, name)}
                    style={{display: "flex", flexDirection: "row", gap: "10px"}}
                    ref={ref} // Attach the forwarded ref here
                >
                    {options.map((option, index) => (
                        <Radio key={index} value={option.value}>
                            {option.label}
                        </Radio>
                    ))}
                </Radio.Group>
            </div>
        );
    }
);


export const FormDatePicker = ({
                                   label,
                                   name,
                                   value,
                                   errorState,
                                   onChange,
                                   onBlur,
                                   picker = "date",
                                   format = "YYYY-MM-DD",
                                   minDate,
                                   maxDate,
                               }) => {
    return (
        <div className="grid-item">
            <label
                htmlFor={name}
                style={{
                    margin: "30px 0 10px 0",
                    color: errorState?.[name] ? "red" : null,
                }}
            >
                {label}
            </label>
            <DatePicker
                name={name}
                placeholder={label}
                value={value ? dayjs(value) : null}
                format={format}
                picker={picker}
                minDate={minDate}
                maxDate={maxDate}
                status={errorState?.[name] ? "error" : null}
                onBlur={() => onBlur(name)}
                onChange={onChange}
                style={{width: "100%"}}

            />
        </div>
    );
};


export const FormTextArea = ({
                                 label,
                                 name,
                                 value,
                                 errorState,
                                 onChange,
                                 onBlur,
                                 options,
                             }) => {
    return (
        <>
            <div className={'grid-item'}>
                <label
                    htmlFor="descriptionDegatsVictime"
                    style={{
                        margin: "30px 0 10px 0",
                    }}
                >
                    {label}
                </label>
                <TextArea
                    id={name}
                    name={name}
                    value={value}
                    placeholder={label}
                    showCount
                    maxLength={300}
                    style={{height: 120, resize: "none"}}
                    onBlur={onBlur(name)}
                    onChange={onChange}
                />
            </div>

        </>
    )
}

