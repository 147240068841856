import {Modal} from "antd";
import {useState} from "react";

const AffecterLotAttestation = ({
                                    children,
                                    categories,
                                    affectationModalOpen,
                                    handleAffectationModalClose,
                                    loading,
                                    onSubmit,
                                    compagnie
                                }) => {
    const [firstAttestation, setFirstAttestation] = useState(0);
    const [lastAttestation, setLastAttestation] = useState(0);
    const [nombre, setNombre] = useState(0);
    const [selectedCategorie, setSelectedCategorie] = useState("M");
    const [selectedTypeAttestation, setSelectedTypeAttestation] = useState("SIMPLE");

    return (
        <>
            <Modal open={affectationModalOpen} loading={loading}
                   onOk={() => onSubmit(firstAttestation, lastAttestation, selectedTypeAttestation, selectedCategorie, nombre)}
                   onCancel={() => handleAffectationModalClose(false)} zIndex={1900} centered>
                <div className="text-center mb-6">
                    <h4 className="address-title mb-2"> Affecter un nouveau lot d'attestation
                        pour {compagnie?.raisonSociale}</h4>

                </div>
                <form id="addNewAddressForm" className="row g-5" onSubmit={onSubmit}>
                    <div className="col-12 col-md-12">
                        <label class="form-label" for="firstAttestation">
                            Veuillez entrer le numéro de la première attestation
                        </label>
                        <input
                            className="form-control" id="firstAttestation" value={firstAttestation}
                            name="firstAttestation"
                            onChange={(e) => {
                                setFirstAttestation(parseInt(e.target.value));
                                setLastAttestation(parseInt(e.target.value));
                            }}
                            placeholder="Entrez le numéro de la première attestation" type="number"/>

                    </div>
                    <div className="col-12 col-md-12">
                        <label class="form-label" for="lastAttestation">
                            Le nombre d'attestation
                        </label>
                        <input className="form-control" id="lastAttestation" value={nombre} name="lastAttestation"
                               onChange={(e) => setNombre(parseInt(e.target.value))}
                               placeholder="Entrez le nombre d'attestation" type="number"/>
                    </div>
                    <div className="col-12 col-md-12">
                        <label class="form-label" for="lastAttestation">
                            Veuillez entrer le numéro de la dernière attestation
                        </label>
                        <input className="form-control" id="lastAttestation" value={lastAttestation}
                               name="lastAttestation" onChange={(e) => setLastAttestation(parseInt(e.target.value))}
                               placeholder="Entrez le numéro de la dernière attestation" type="number"/>
                    </div>
                    <div className="col-12 col-md-12">
                        <label class="form-label" for="agence">
                            Veuillez sélectionner le type d'attestation
                        </label>
                        <select className="form-control" id="typeAttestation" value={selectedTypeAttestation}
                                name="typeAttestation" onChange={(e) => setSelectedTypeAttestation(e.target.value)}
                                placeholder="Sélectionner un type d'attestation">
                            <option value="SIMPLE"> SIMPLE</option>
                            <option value="CEDEAO"> CEDEAO</option>
                        </select>
                    </div>
                    <div className="col-12 col-md-12">
                        <label class="form-label" for="agence">
                            Veuillez sélectionner la catégorie
                        </label>
                        <select className="form-control" id="agence" value={selectedCategorie} name="agence"
                                onChange={(e) => setSelectedCategorie(e.target.value)}
                                placeholder="Sélectionner une agence">
                            {categories?.map((cat, id) => (
                                <option key={id} value={cat.codeCategorie}> {cat.nom} </option>))}
                        </select>
                    </div>
                </form>
                <div>
                    {
                        children
                    }
                </div>
            </Modal>
        </>
    )
}

export default AffecterLotAttestation