import { Card, Image } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { CardBody } from "react-bootstrap";
import entete from "../../../assets/img/entete.jpg";
import entfoot from "../../../assets/img/footer.png";
import { frenchDate, frenchDateOnly, numberToWordsFrench } from "../../../variables/functions";


const styles = {
    infoText: {
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center",
    },

    epais: {
        border: "1px solid #063970",
        margin: "0px 0px 0px 0px",
    },

    textLeft: {
        textAlign: "left",
    },

    tabTextStyleLeft: {
        border: "1px solid #063970",
        textAlign: "left",
    },

    tabTextStyleRight: {
        border: "1px solid #063970",
        textAlign: "right",
    },

    textRight: {
        textAlign: "right",
    },

    textCenter: {
        textAlign: "center",
    },

    inputAdornmentIcon: {
        color: "#555",
    },
    inputAdornment: {
        position: "relative",
    },
};


const QuittanceIndemnite = (props) => {
    return (
        <div>
            <div className={'container'} style={{
                justifyContent: 'center'
            }}>
                <div className={'container'}>
                    <div className={'grid-item'}>
                        <Image src={entete} />
                        <Card testimonial>
                            <CardBody>
                                <div className={'grid-item'} >
                                    <hr className={styles.epais}></hr>
                                    <div className={'container'} justify="center">
                                        <div
                                            className={styles.textCenter}
                                            md={6}
                                        >
                                            <h5>
                                                <b>QUITTANCE D&apos;INDEMNITE</b>
                                            </h5>
                                        </div>
                                    </div>
                                    <hr className={styles.epais}></hr>
                                    <br />
                                    <br />
                                    <div className={'container'} justify="center">
                                        <table>
                                            <tr>
                                                <td className={styles.tabTextStyleLeft}>
                                                    <h7>
                                                        <b>POOL TPVM-VT GIE</b>
                                                        &nbsp; &nbsp;
                                                    </h7>
                                                </td>
                                                <td className={styles.tabTextStyleRight}>
                                                    <h7>
                                                        <b>N° SINISTRE</b>
                                                        &nbsp; &nbsp;
                                                    </h7>
                                                </td>
                                                <td className={styles.tabTextStyleLeft}>
                                                    <h7>
                                                        <b>N° Police</b>
                                                        &nbsp; &nbsp;
                                                    </h7>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={styles.tabTextStyleLeft}>
                                                    <h7>
                                                        <b>SIEGE</b>
                                                        &nbsp; &nbsp;
                                                    </h7>
                                                </td>
                                                <td className={styles.tabTextStyleRight}>
                                                    <h7>
                                                        <b>{props?.sinistre?.numSinistre}</b>
                                                        &nbsp; &nbsp;
                                                    </h7>
                                                </td>
                                                <td className={styles.tabTextStyleLeft}>
                                                    <h7>
                                                        <b>
                                                            {props?.sinistre?.contrat?.numeroPoliceP
                                                                ? props?.sinistre?.contrat?.numeroPoliceP
                                                                : props?.sinistre?.contrat?.numeroPoliceM}
                                                        </b>
                                                        &nbsp; &nbsp;
                                                    </h7>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={styles.tabTextStyleLeft}>
                                                    <h7>
                                                        <b>PAIEMENT EN FCFA</b>
                                                        &nbsp; &nbsp;
                                                    </h7>
                                                </td>
                                                <td className={styles.tabTextStyleRight}>
                                                    <h7>
                                                        <b>SURVENU LE</b>
                                                        &nbsp; &nbsp;
                                                    </h7>
                                                </td>
                                                <td className={styles.tabTextStyleLeft}>
                                                    <h7>
                                                        <b>ASSURE</b>
                                                        &nbsp; &nbsp;
                                                    </h7>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={styles.tabTextStyleLeft}>
                                                    <h7>
                                                        <b>
                                                            {new Intl.NumberFormat("en-DE").format(
                                                                Math.trunc(props?.decaissement?.montantGlobal)
                                                            )}
                                                        </b>
                                                        &nbsp; &nbsp;
                                                    </h7>
                                                </td>
                                                <td className={styles.tabTextStyleRight}>
                                                    <h7>
                                                        <b>{frenchDate(props?.sinistre?.dateSinistre)}</b>
                                                        &nbsp; &nbsp;
                                                    </h7>
                                                </td>
                                                <td className={styles.tabTextStyleLeft}>
                                                    <h7>
                                                        <b>
                                                            {props?.sinistre?.contrat?.souscripteur?.prenom
                                                                ? `${props?.sinistre?.contrat?.souscripteur?.nom} ${props?.sinistre?.contrat?.souscripteur?.prenom}`
                                                                : props?.sinistre?.contrat?.souscripteur?.nom}
                                                        </b>
                                                        &nbsp; &nbsp;
                                                    </h7>
                                                </td>
                                            </tr>
                                        </table>

                                        {/* <div className = {'grid-item'} md={3}></div> */}
                                    </div>
                                    <br />
                                    <br />
                                    <div className={'container'} justify="center">
                                        <div className={'grid-item'}
                                        >
                                            <h5>
                                                <b>
                                                    <u>SITUATION FINANCIERE DU DOSSIER</u>
                                                </b>
                                            </h5>
                                        </div>
                                    </div>
                                    <br />
                                    <br />
                                    <div className={'container'}>
                                        <div className={'grid-item'} >
                                            <p className={styles.textLeft}>
                                                <h7>
                                                    Je soussigné :{" "}
                                                    {props?.decaissement?.beneficiaire?.prenom
                                                        ? `${props?.decaissement?.beneficiaire?.nom} ${props?.decaissement?.beneficiaire?.prenom}`
                                                        : props?.decaissement?.beneficiaire?.nom}
                                                </h7>
                                            </p>
                                            <p className={styles.textLeft}>
                                                <h7>
                                                    Représenté par :{" "}
                                                    {props?.decaissement?.representant
                                                        ? props?.decaissement?.representant
                                                        : ""}
                                                </h7>
                                            </p>
                                            <p className={styles.textLeft}>
                                                <h7>
                                                    Demeurant à :{" "}
                                                    {props?.sinistre?.contrat?.souscripteur?.adresse}
                                                </h7>
                                            </p>
                                            <p className={styles.textLeft}>
                                                <h7>
                                                    <b>Reconnais avoir reçu du POOL TPVM-VT GIE,</b>
                                                </h7>
                                            </p>
                                            <p className={styles.textLeft}>
                                                <h7>
                                                    La somme de{" "}
                                                    {numberToWordsFrench(
                                                        props?.decaissement?.montantGlobal
                                                    )}{" "}
                                                    FRANCS CFA (
                                                    {new Intl.NumberFormat("en-DE").format(
                                                        Math.trunc(props?.decaissement?.montantGlobal)
                                                    )}
                                                    )
                                                </h7>
                                            </p>
                                            <p className={styles.textLeft}>
                                                <h7>
                                                    Réprésentant le montant de{" "}
                                                    {props?.decaissement?.libelleDecaissement}.
                                                </h7>
                                            </p>
                                            <p className={styles.textLeft}>
                                                <h7>
                                                    Par ce paiement, je renonce à exercer à
                                                    l&apos;encontre du POOL toute action amiable ou
                                                    judiciaire fondée sur ledit accident. Le présent
                                                    paiement est accepté pour solde complet et définitif
                                                    du préjudice matériel subi.
                                                </h7>
                                            </p>
                                            <p className={styles.textLeft}>
                                                <h7>Dont quittance sans aucune réserve.</h7>
                                            </p>
                                        </div>
                                    </div>
                                    <br />
                                    <div className={'container'}>
                                        <div className={'grid-item'} >
                                            <p className={styles.textLeft}>
                                                <h7>
                                                    <b>Chèque N° {props?.decaissement?.numeroCheque}</b>
                                                </h7>
                                            </p>
                                            <p className={styles.textLeft}>
                                                <h7>
                                                    <b>
                                                        Du{" "}
                                                        {props?.decaissement?.dateEmissionCheque
                                                            ? frenchDateOnly(
                                                                props?.decaissement?.dateEmissionCheque
                                                            )
                                                            : ""}
                                                    </b>
                                                </h7>
                                            </p>
                                            <p className={styles.textLeft}>
                                                <h7>
                                                    <b>
                                                        {props?.decaissement?.banque
                                                            ? props?.decaissement?.banque
                                                            : ""}
                                                    </b>
                                                </h7>
                                            </p>

                                            <p className={styles.textLeft}>
                                                <h7>
                                                    <b>Le {frenchDate(props?.decaissement?.updatedAt)}</b>
                                                </h7>
                                            </p>
                                        </div>
                                        <div className={'grid-item'} >
                                            A Lomé le,{" "}
                                            {props?.decaissement?.dateImpressionQuittance
                                                ? frenchDateOnly(
                                                    props?.decaissement?.dateImpressionQuittance
                                                )
                                                : frenchDateOnly(new Date().toISOString())}
                                        </div>
                                    </div>

                                    <br />
                                    <br />

                                    <br />
                                </div>
                            </CardBody>
                        </Card>
                        <Image src={entfoot} />
                    </div>
                </div>
            </div>
        </div>
    );
};

QuittanceIndemnite.propTypes = {
    decaissement: PropTypes.object,
    sinistre: PropTypes.object,
};

export default QuittanceIndemnite;
