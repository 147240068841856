import React from "react";

const InformationCard = ({cardInfo, children}) => {
    return (
        <>
            <div className={`${cardInfo.container}`}>
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex align-items-start justify-content-between">
                            <div className="content-left">
                                <span className="text-heading">{cardInfo.label} </span>
                                <div className="d-flex align-items-center my-1">
                                    <h4 className="mb-0 me-2">{cardInfo.value}</h4>
                                    {/* <p class="text-success mb-0">(+18%)</p> */}
                                </div>
                                <small className="mb-0">
                                    {cardInfo.description}
                                </small>
                            </div>
                            <div className="avatar">
                          <span className={`avatar-initial rounded ${cardInfo.state}`}>
                            <i className={`${cardInfo.avatar}`}></i>
                          </span>
                            </div>
                        </div>
                        <div style={{
                            width: '100%'
                        }}>
                            {children}
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default InformationCard;