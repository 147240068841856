import { PlusOutlined } from "@ant-design/icons";
import { Button, Collapse } from "antd";
import { useFormik } from "formik";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getSinistreById } from "../../../../hooks/useHooks";
import { getVictimeValidationSchema } from "./form-validations/schemaValidations";
import SingleItemForm from "./victime-form-elements/SingleItemForm";

const { Panel } = Collapse;

const InformationsSurVictimes = forwardRef((props, ref) => {
    const { activeTab, setActiveTab } = props;

    const initialValues = {
        items: [
            {
                pieton: "non",
                nom: "",
                prenom: "",
                telephone: "",
                profession: "",
                adresse: "",
                descriptionDegatsVictime: ""
            },
        ],
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: getVictimeValidationSchema(),
    });

    const [data, setData] = useState([
        {
            key: 0,
            label: "Victime 1",
            children: <SingleItemForm formik={formik} index={0} />,
        },
    ]);

    useImperativeHandle(ref, () => ({
        isValidated: async () => {
            try {
                const validationSchema = getVictimeValidationSchema();
                await validationSchema.validate(
                    { items: formik.values.items },
                    { abortEarly: false }
                );
                setActiveTab((prevTab) => prevTab + 1);
            } catch (validationError) {
                validationError.inner.forEach((error) => {
                    toast.error(error.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                });
            }
        },
        sendState: () => {
            sessionStorage.setItem("infoVict", JSON.stringify(formik.values));
        },
    }));

    const handleAddItem = () => {
        const newIndex = formik.values.items.length;
        formik.setFieldValue("items", [
            ...formik.values.items,
            {
                pieton: "non",
                nom: "",
                prenom: "",
                telephone: "",
                profession: "",
                adresse: "",
                descriptionDegatsVictime: ""
            },
        ]);

        setData((prevData) => [
            ...prevData,
            {
                key: newIndex,
                label: `Victime ${newIndex + 1}`,
                children: <SingleItemForm formik={formik} index={newIndex} />,
            },
        ]);
    };

    const param3 = useParams();

    useEffect(() => {
        if (param3.id) {
            getSinistreById(param3.id)
                .then((res) => {
                    const vics = res.data.victimes
                        .filter((victime) => !victime.adversaire)
                        .map((victime, index) => ({
                            nom: victime.personne.nom,
                            prenom: victime.personne.prenom,
                            adresse: victime.personne.adresse,
                            profession: victime.personne.profession,
                            descriptionDegatsVictime: victime.descriptionDegatsVictime,
                        }));

                    formik.setFieldValue("items", vics);
                    const updatedData = vics.map((_, index) => ({
                        key: index,
                        label: `Victime ${index + 1}`,
                        children: <SingleItemForm formik={formik} index={index} />,
                    }));
                    setData(updatedData);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [param3.id, formik]);

    console.log(data)
    return (
        <>
            <Collapse accordion>
                {formik.values.items.map((item, index) => (
                    <Panel header={`Victime ${index + 1}`} key={index}>
                        <SingleItemForm formik={formik} index={index} />
                    </Panel>
                ))}
            </Collapse>


            <div className="grid-container grid container" style={{ justifyContent: "center" }}>
                <br />
                <br />
                <Button onClick={handleAddItem} color="primary" type="button">
                    <PlusOutlined /> Ajouter une victime
                </Button>
            </div>
        </>

    );
});

InformationsSurVictimes.displayName = "InformationsSurVictimes";

export default InformationsSurVictimes;
