import PropTypes from "prop-types";
import React from "react";
import attestationMotoScan from "../../../../assets/img/scan_att.jpg";
import { frenchDateOnly } from "../../../../variables/functions";

const AttestationMoto = React.forwardRef((props, ref) => {
  return (
    <div className="container">
      <div
        ref={ref}
        className=""
        style={{ height: 400, width: 1080, backgroundImage: `url(${attestationMotoScan})`, backgroundSize: "contain", backgroundRepeat: "no-repeat", position: "absolute" }}
      >
        <div className="row">
          <div className="col" style={{ position: 'relative' }}>
            <h6
              className="text-left"
              style={{ position: 'absolute', marginLeft: "20px", marginTop: "65px" }}
            >
              <b>
                {props.contrat?.souscripteur?.nom}{" "}
                {props.contrat?.souscripteur?.prenom || ""}{" "}
              </b>
            </h6>
            <h6
              className="text-left"
              style={{ position: 'absolute', marginLeft: "20px", marginTop: "80px" }}
            >
              <b>{props.contrat?.souscripteur?.contact?.[0] || " "}</b>
            </h6>
            <h6
              className="text-left"
              style={{ position: 'absolute', marginLeft: "70px", marginTop: "95px" }}
            >
              <b>
                {props.contrat?.numeroPoliceM || props.contrat?.numeroPoliceP}
              </b>
            </h6>
            <h6
              className="text-left"
              style={{ position: 'absolute', marginLeft: "32px", marginTop: "110px" }}
            >
              <b>{frenchDateOnly(props.contrat?.dateEffet)}</b>
            </h6>
            <h6
              className="text-left"
              style={{ position: 'absolute', marginLeft: "122px", marginTop: "110px" }}
            >
              <b>{frenchDateOnly(props.contrat?.dateEcheance)}</b>
            </h6>
            <h6
              className="text-left"
              style={{ position: 'absolute', marginLeft: "112px", marginTop: "125px" }}
            >
              <b>{props.engin?.marque || ""}</b>
            </h6>
            <h6
              className="text-left"
              style={{ position: 'absolute', marginLeft: "112px", marginTop: "143px" }}
            >
              <b>{props.engin?.immatriculation || ""}</b>
            </h6>
            <h6
              className="text-left"
              style={{ position: 'absolute', marginLeft: "67px", marginTop: "160px" }}
            >
              <b>{props.engin?.numeroChassis || ""}</b>
            </h6>
            <h6
              className="text-left"
              style={{ position: 'absolute', marginLeft: "80px", marginTop: "173px" }}
            >
              <b>{props.engin?.genre || ""}</b>
            </h6>
          </div>
          <div className="col">
            <h6
              className=""
              style={{ position: 'absolute', marginLeft: "-45px", marginTop: "97px" }}
            >
              <b>
                {props.contrat?.numeroPoliceM || props.contrat?.numeroPoliceP}
              </b>
            </h6>
            <h6
              className="text-left"
              style={{ position: 'absolute', marginLeft: "-86px", marginTop: "115px" }}
            >
              <b>{frenchDateOnly(props.contrat?.dateEffet)}</b>
            </h6>
            <h6
              className="text-left"
              style={{ position: 'absolute', marginLeft: "0px", marginTop: "115px" }}
            >
              <b>{frenchDateOnly(props.contrat?.dateEcheance)}</b>
            </h6>
            <h6
              className="text-left"
              style={{ position: 'absolute', marginLeft: "-5px", marginTop: "132px" }}
            >
              <b>{props.engin?.marque || "_"}</b>
            </h6>
            <h6
              className="text-left"
              style={{ position: 'absolute', marginLeft: "-10px", marginTop: "150px" }}
            >
              <b>{props.engin?.immatriculation || "_"}</b>
            </h6>
            <h6
              className="text-left"
              style={{ position: 'absolute', marginLeft: "-45px", marginTop: "165px" }}
            >
              <b>{props.engin?.numeroChassis || "_"}</b>
            </h6>
            <h6
              className="text-left"
              style={{ position: 'absolute', marginLeft: "-50px", marginTop: "180px" }}
            >
              <b>{props.engin?.genre || "_"}</b>
            </h6>
            <h6
              className="text-left"
              style={{ position: 'absolute', marginLeft: "10px", marginTop: "198px" }}
            >
              <b>{props.engin?.usage || "_"}</b>
            </h6>
          </div>
          <div className="col">
            <h6
              className="text-left"
              style={{ position: 'absolute', marginLeft: "-87px", marginTop: "20px" }}
            >
              <b>
                {props.contrat?.souscripteur?.nom || "_"}{" "}
                {props.contrat?.souscripteur?.prenom || ""}{" "}
              </b>
            </h6>
            <h6 className="text-left" style={{ position: 'absolute', marginLeft: "-217px", marginTop: "46px" }}>
              <b>
                {props.contrat?.souscripteur?.adresse || "_"}{" "}
                {props.contrat?.souscripteur?.contact?.[0] || ""}
              </b>
            </h6>
            <h6
              className="text-left"
              style={{ position: 'absolute', marginLeft: "-150px", marginTop: "64px" }}
            >
              <b>{props.contrat?.souscripteur?.profession || "_"}</b>
            </h6>
            <h6
              className="text-left"
              style={{ position: 'absolute', marginLeft: "-153px", marginTop: "81px" }}
            >
              <b>
                {props.contrat?.numeroPoliceM || props.contrat?.numeroPoliceP}
              </b>
            </h6>
            <h6
              className="text-left"
              style={{ position: 'absolute', marginLeft: "-180px", marginTop: "107px" }}
            >
              <b>{frenchDateOnly(props.contrat?.dateEffet)}</b>
            </h6>
            <h6
              className="text-left"
              style={{ position: 'absolute', marginLeft: "-65px", marginTop: "107px" }}
            >
              <b>{frenchDateOnly(props.contrat?.dateEcheance)}</b>
            </h6>
            <h6
              className="text-left"
              style={{ position: 'absolute', marginLeft: "-120px", marginTop: "128px" }}
            >
              <b>{props.engin?.marque || ""}</b>
            </h6>
            <h6
              className="text-left"
              style={{ position: 'absolute', marginLeft: "-125px", marginTop: "145px" }}
            >
              <b>{props.engin?.immatriculation || ""}</b>
            </h6>
            <h6
              className="text-left"
              style={{ position: 'absolute', marginLeft: "-155px", marginTop: "162px" }}
            >
              <b>{props.engin?.numeroChassis || ""}</b>
            </h6>
            <h6
              className="text-left"
              style={{ position: 'absolute', marginLeft: "-135px", marginTop: "180px" }}
            >
              <b>{props.engin?.genre || "-"}</b>
            </h6>
            <h6
              className="text-left"
              style={{ position: 'absolute', marginLeft: "-55px", marginTop: "197px" }}
            >
              <b>{props.engin?.usage || "_"}</b>
            </h6>
            <h6
              className="text-left"
              style={{ position: 'absolute', marginLeft: "-185px", marginTop: "243px" }}
            >
              <b>App. Terrestre</b>
            </h6>
            <h6
              className="text-left"
              style={{ position: 'absolute', marginLeft: "-35px", marginTop: "243px" }}
            >
              <b>{props.engin?.marque || "_"}</b>
            </h6>
            <h6
              className="text-left"
              style={{ position: 'absolute', marginLeft: "-100px", marginTop: "257px" }}
            >
              <b>{props.engin?.immatriculation || "_"}</b>
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
});
AttestationMoto.propTypes = {
  contrat: PropTypes.object,
  engin: PropTypes.object,
  printing: PropTypes.bool,
};
AttestationMoto.displayName = "Attestation Moto";

export default AttestationMoto;
