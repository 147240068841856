import { Modal, Select } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { API_URL, TOKEN } from '../../../variables/constants';

const AddFractionnement = ({ show, handleClose, editMode, selectedFractionnement }) => {
  const [nom, setNom] = useState('');
  const [nombre, setNombre] = useState('');
  const [libelleFractionnement, setLibelleFractionnement] = useState('');
  const [description, setDescription] = useState('');
  const [moisMin, setMoisMin] = useState('');
  const [moisMax, setMoisMax] = useState('');
  const [categorie, setCategorie] = useState('');
  const [selectedGaranties, setSelectedGaranties] = useState([]);
  const [loading, setLoading] = useState(false);
  const [categoriesList, setCategoriesList] = useState([]);
  const [garantiesList, setGarantiesList] = useState([]);
  const [catGaranties, setCatGaranties] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [periode, setPeriode] = useState("MOIS");

  useEffect(() => {
    setIsDataLoading(true);
    axios
      .get(`${API_URL}/api/categorie`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      })
      .then((res) => setCategoriesList(res.data || []))
      .catch((err) => {
        console.error('Erreur lors du chargement des catégories :', err);
        toast.error("Erreur lors du chargement des catégories");
      });

    axios
      .get(`${API_URL}/api/garantie`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      })
      .then((res) => {
        setGarantiesList(res.data || []);
        setCatGaranties(res.data || []);
      })
      .catch((err) => {
        console.error('Erreur lors du chargement des garanties :', err);
        toast.error("Erreur lors du chargement des garanties");
      })
      .finally(() => setIsDataLoading(false));
  }, []);

  useEffect(() => {
    if (editMode && selectedFractionnement) {
      const {
        nom, nombre, libelleFractionnement, description, moisMin, moisMax, categorie, garanties, periode
      } = selectedFractionnement;

      setNom(nom);
      setNombre(nombre);
      setLibelleFractionnement(libelleFractionnement);
      setDescription(description);
      setMoisMin(moisMin);
      setMoisMax(moisMax);
      setPeriode(periode);
      setCategorie(categorie?.categorieId);
      setSelectedGaranties(garanties.map((gar) => gar.codeId.toString()));
      console.log("Mode édition - données actuelles :", selectedFractionnement);
    }
  }, [editMode, selectedFractionnement]);

  const handleGarantiesChange = (value) => {
    setSelectedGaranties(value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const fractionnementData = {
      nom,
      nombre: parseInt(nombre),
      libelleFractionnement,
      description,
      periode: periode,
      moisMin: parseInt(moisMin),
      moisMax: parseInt(moisMax),
      categorie: categoriesList.find((e) => e.codeCategorie === categorie),
      garanties: selectedGaranties
        .map((g) => garantiesList.find((gar) => gar.codeId.toString() === g))
        .filter(Boolean),
    };

    console.log("Données à envoyer au backend :", fractionnementData);

    try {
      if (editMode) {
        const updatedFractionnement = {
          ...selectedFractionnement,
          ...fractionnementData,
        };

        console.log("Mode édition - envoi de la requête PUT avec les données suivantes : ", updatedFractionnement);

        await axios.put(
          `${API_URL}/api/fractionnement`,
          updatedFractionnement,
          {
            headers: {
              Authorization: `Bearer ${TOKEN}`,
              'Content-Type': 'application/json',
            },
          }
        );

        toast.success("Fractionnement modifié avec succès");
        window.location.reload();
      } else {
        console.log("Mode ajout - envoi de la requête POST avec les données suivantes : ", fractionnementData);
        await axios.post(`${API_URL}/api/fractionnement`, fractionnementData, {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            'Content-Type': 'application/json',
          },
        });
        toast.success("Fractionnement ajouté avec succès");
        window.location.reload();
      }

      handleClose();
    } catch (err) {
      console.error("Erreur lors de l'opération :", err);

      if (err.response) {
        console.error('Réponse de l\'API :', err.response.data);
        console.error('Statut de l\'API :', err.response.status);
        toast.error(`Erreur ${err.response.status}: ${err.response.data.message || 'Opération échouée'}`);
      } else if (err.request) {
        console.error('Requête envoyée mais pas de réponse :', err.request);
      } else {
        console.error('Erreur lors de la configuration de la requête :', err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={show} onCancel={handleClose} footer={null} centered>
      <div className="text-center mb-6">
        <h4 className="address-title mb-2">
          {editMode ? "Modifier le fractionnement" : "Ajouter un fractionnement"}
        </h4>
        <p className="address-subtitle">
          {editMode ? "Modifier les informations du fractionnement" : "Ajouter un nouveau fractionnement"}
        </p>
      </div>
      <form onSubmit={onSubmit}>
        <div className="form-group">
          <label htmlFor="nom">Nom</label>
          <input
            type="text"
            id="nom"
            className="form-control"
            placeholder="Nom du fractionnement"
            value={nom}
            onChange={(e) => setNom(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="categorie">Catégorie</label>
          <select
            id="categorie"
            className="form-select"
            value={categorie}
            onChange={(e) => {
              setCatGaranties(garantiesList.filter(gl => gl.categorie.codeCategorie === e.target.value));
              setCategorie(e.target.value);
            }}
            required
          >
            <option value="">Sélectionner une catégorie</option>
            {categoriesList.map((cat) => (
              <option key={cat.categorieId} value={cat.codeCategorie}>
                {cat.nom}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="garanties">Garanties</label>
          <Select
            mode="multiple"
            style={{ width: "100%" }}
            placeholder="Sélectionner les garanties"
            value={selectedGaranties}
            onChange={handleGarantiesChange}
            options={catGaranties.map((garantie) => ({
              label: garantie.nom,
              value: garantie.codeId.toString(),
            }))}
          />
        </div>
        <div className="form-group">
          <label htmlFor="libelleFractionnement">Libellé</label>
          <input
            type="text"
            id="libelleFractionnement"
            className="form-control"
            placeholder="Libellé de fractionnement"
            value={libelleFractionnement}
            onChange={(e) => setLibelleFractionnement(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="nombre">Nombre</label>
          <input
            type="number"
            id="nombre"
            className="form-control"
            placeholder="Nombre"
            value={nombre}
            onChange={(e) => setNombre(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="periode">Période</label>
          <select
            id="periode"
            className="form-select"
            value={periode}
            onChange={(e) => {
              setPeriode(e.target.value);
            }}
          >
            <option value="MOIS">MOIS</option>
            <option value="JOUR">JOUR</option>
            <option value="AN">AN</option>

          </select>
        </div>
        <div className="form-group">
          <label htmlFor="moisMin">Mois Minimum</label>
          <input
            type="number"
            id="moisMin"
            className="form-control"
            placeholder="Mois Minimum"
            value={moisMin}
            onChange={(e) => setMoisMin(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="moisMax">Mois Maximum</label>
          <input
            type="number"
            id="moisMax"
            className="form-control"
            placeholder="Mois Maximum"
            value={moisMax}
            onChange={(e) => setMoisMax(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="description">Description</label>
          <textarea
            id="description"
            className="form-control"
            placeholder="Description du fractionnement"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </div>

        <div className="text-center mt-4">
          <button type="submit" className="btn btn-primary" disabled={loading}>
            {loading ? "Enregistrement..." : "Enregistrer"}
          </button>
          <button type="button" className="btn btn-secondary ms-2" onClick={handleClose}>
            Annuler
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default AddFractionnement;