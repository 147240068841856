import { CreditCardFilled, ExclamationCircleFilled, FilePdfFilled, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, ConfigProvider, Form, Input, Modal, Popconfirm, Select, Skeleton, Space, Spin, Table, Tag } from 'antd';
import frFR from "antd/locale/fr_FR";
import axios from 'axios';
import React, { createRef, useEffect, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useNavigate, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';
import Footer from '../../../components/Footer';
import NavBar from '../../../components/NavBar';
import SideBar from '../../../components/SideBar';
import { rightsService } from '../../../services/rightsService';
import { API_URL, LOGGED_USER, TOKEN } from '../../../variables/constants';
import { frenchDate, frenchDateSimple } from '../../../variables/functions';
import DTRFFormatProposition from '../../production/components/docs/DTRFFormatProposition';
import InvoiceCommissionPayment from '../../production/components/docs/InvoiceCommissionPayment';
import MultipleInvoicesCommissions from '../../production/components/docs/MultipleInvoicesCommissions';
const { confirm } = Modal;
const { Option } = Select;

const DetailsCourtier = () => {
    const [courtier, setCourtier] = useState("");
    const [imagePreviewUrl, setImagePreviewUrl] = useState();
    const [activeTab, setActiveTab] = useState(0);
    const [agreeActiveTab, setAgreeActiveTab] = useState(0);
    const [agreements, setAgreements] = useState([]);
    const [agreement, setAgreement] = useState();
    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [open, setOpen] = useState(false);
    const [tcModalOpen, setTCModalOpen] = useState(false);
    const [rtcModalOpen, setRTCModalOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [utilisateurs, setUtilisateurs] = useState([]);
    const [myAgreement, setMyAgreement] = useState("");
    const [myAgreementId, setMyAgreementId] = useState(0);
    const [contrats, setContrats] = useState([]);
    const [validatedContrats, setValidatedContrats] = useState([]);
    const [commissions, setCommissions] = useState([]);
    const [sumCommission, setSumCommission] = useState(0);
    const [loadingContrats, setLoadingContrats] = useState(true);
    const [tauxCommission, setTauxCommission] = useState(0);
    const [tauxRetenueCommission, setTauxRetenueCommission] = useState(0);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [hidden, setHidden] = useState(false);
    const [hiddenMultpleInvoices, setHiddenMultipleInvoices] = useState(false);
    const [hiddenContrat, setHiddenContrat] = useState(false);
    const [selectedContrat, setSelectedContrat] = useState();
    const [selectedContrats, setSelectedContrats] = useState([]);
    const [selecting, setSelecting] = useState(false);
    const [modalModePaiement, setModalModePaiement] = useState(false);
    const [modePaiementForm] = Form.useForm();
    const [modePaiement, setModePaiement] = useState("");
    const [numeroPaiement, setNumeroPaiement] = useState("P");
    const [banques, setBanques] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [tauxCommissions, setTauxCommissions] = useState([]);
    const [banque, setBanque] = useState("BANQUE");
    const [contractsToPay, setContractsToPay] = useState([]);
    const [editTauxCommissionOpenModal, setEditTauxCommissionOpenModal] = useState(false);
    const [editedTauxCommission, setEditedTauxCommission] = useState(0);

    const searchInput = useRef(null);
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});

    const navigate = useNavigate();

    const handleTabChange = (index) => {
        setActiveTab(index);
    };

    const param = useParams();
    const printInvoiceCommissionRef = createRef();
    const printPropositionRef = useRef();
    const printMultipleInvoicesRef = createRef();

    const handleCancel = () => {
        console.log('Clicked cancel button');
        setOpen(false);
    };

    const handleClose = () => setTCModalOpen(false);

    useEffect(() => {
        axios
            .get(`${API_URL}/api/agence/codeId/${param.courtierId}`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                console.log(res.data);
                if (LOGGED_USER?.profil?.organisme?.typeOrganisme !== "POOL") {
                    const v = res.data?.agrements?.find(ag => ag.compagnie.code === LOGGED_USER?.profil?.organisme?.code);
                    console.log("my vvvvvvvvv", v);
                    if (v) {
                        setMyAgreementId(v.agreementId);
                        setMyAgreement(v);
                        sessionStorage.setItem("myAgreement", JSON.stringify(v));
                        loadAgreementDocument(v, res.data);
                    }

                } else {
                    loadAgreementDocument(res.data.agrements[0], res.data);
                }
                setCourtier(res.data);
                axios
                    .get(`${API_URL}/api/contrat/courtier/codeAgence/${res.data.codeAgence}`, {
                        headers: {
                            Authorization: `Bearer ${TOKEN}`,
                            "Content-Type": "application/json",
                            "Access-Control-Allow-Origin": "*",
                            "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                            "Access-Control-Allow-Headers":
                                "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                        },
                    })
                    .then((resp) => {
                        console.log(resp.data);
                        let ctrs = [];
                        let vCtrs = [];
                        let coms = [];
                        let comKeys = [];
                        let valueCommission = 0;
                        resp.data.map((contrat, key) => {
                            if (contrat.reglements && contrat.reglements.length) {
                                contrat.reglements.forEach((reg) => {
                                    reg.montant < 0 && !reg.commissionPaye && comKeys.push(reg.codeId);
                                    coms.push({
                                        key: reg.codeId,
                                        codeId: reg.codeId,
                                        id: reg.codeId,
                                        numeroPolice: contrat.numeroPoliceP,
                                        souscripteur: contrat?.souscripteur?.prenom
                                            ? `${contrat?.souscripteur?.prenom} ${contrat?.souscripteur?.nom}`
                                            : `${contrat?.souscripteur?.nom}`,
                                        organisme: contrat.organisme.raisonSociale,
                                        dateEffet: contrat?.dateEffet
                                            ? `${frenchDate(contrat?.dateEffet)}`
                                            : "",
                                        dateEcheance: contrat?.dateEcheance
                                            ? `${frenchDateSimple(contrat?.dateEcheance)} `
                                            : "",
                                        regle: reg.dateReglement
                                            ? "REGLE"
                                            : "NON REGLE",
                                        data: reg,
                                        contrat: contrat
                                    });
                                    valueCommission += reg.commission ? reg.commission : 0;
                                })
                            }

                            contrat.statutContrat === "VALIDE" && vCtrs.push(contrat);

                            ctrs.push({
                                key: contrat.codeId,
                                codeId: contrat.codeId,
                                id: key,
                                numeroPolice: contrat.numeroPoliceP,
                                souscripteur: contrat?.souscripteur?.prenom
                                    ? `${contrat?.souscripteur?.prenom} ${contrat?.souscripteur?.nom}`
                                    : `${contrat?.souscripteur?.nom}`,
                                organisme: contrat.organisme.raisonSociale,
                                dateEffet: contrat?.dateEffet
                                    ? `${frenchDate(contrat?.dateEffet)}`
                                    : "",
                                dateEcheance: contrat?.dateEcheance
                                    ? `${frenchDateSimple(contrat?.dateEcheance)} `
                                    : "",
                                regle: contrat.reglements
                                    ? contrat.reglements.length
                                        ? "REGLE"
                                        : "NON REGLE"
                                    : "NON REGLE",
                                data: contrat,
                            });
                            // console.log("CTRS: ", ctrs);
                        });
                        console.log("Commmmss:", coms);
                        let _coms = coms.filter(e => e.data.montant < 0 && !e.data.commissionPaye);
                        console.log("____Commmmss:", _coms);
                        setSelectedContrats(_coms);
                        setSelectedRows(_coms);
                        setSelectedRowKeys(comKeys);
                        setContrats(ctrs);
                        setValidatedContrats(vCtrs);
                        setCommissions(coms);
                        setSumCommission(valueCommission);
                        setLoadingContrats(false);
                    })
                    .catch((err) => {
                        console.log(err);
                    });

                axios
                    .get(`${API_URL}/api/utilisateur/courtiers/${res.data.codeAgence}`, {
                        headers: {
                            Authorization: `Bearer ${TOKEN}`,
                            "Content-Type": "application/json",
                            "Access-Control-Allow-Origin": "*",
                            "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                            "Access-Control-Allow-Headers":
                                "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                        },
                    })
                    .then((resp) => {
                        setUtilisateurs(resp.data);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            })
            .catch((err) => {
                console.log(err);
            });

        axios
            .get(`${API_URL}/api/parametre/type/BANQUE`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                setBanques(res.data);
            })
            .catch((err) => {
                console.log(err);
            });

        axios
            .get(`${API_URL}/api/categorie`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                // console.log("Res.data: ", res.data.filter(e => !e.codeCategorie.startsWith("VT")));
                let tcs = [];
                res.data.filter(e => !e.codeCategorie.startsWith("VT")).forEach((cat) => {
                    tcs.push({
                        taux: 0,
                        categorie: cat.nom,
                        codeCategorie: cat.codeCategorie
                    });
                })
                setTauxCommissions(tcs);
                setCategories(res.data.filter(e => !e.codeCategorie.startsWith("VT")));
            })
            .catch((err) => {
                console.log(err);
            });

    }, [TOKEN]);

    const loadAgreementDocument = (element, court) => {
        // console.log("Element: ", element);
        // console.log("ValidAg: ", court?.validAgrements);
        const agId = court?.validAgrements?.findIndex((a) => a.agreementId === element.agreementId);
        // console.log("Agggg", agId);
        court?.validAgrements?.length ? agId !== -1 ? setValidated(true) : setValidated(false) : setValidated(false);
        setLoading(true);
        fetch(
            `${API_URL}/api/agreement/document/${element?.document?.split("//")[2]}`,
            {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods":
                        "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            }
        )
            .then(async (response) => {
                // convert byte to file
                const imageBytes = await response.arrayBuffer();
                // console.log("images byte: ", imageBytes);
                let ext = element?.document?.split(".")[1];
                if (ext === "pdf") {
                    var blob = new Blob([imageBytes], { type: "application/pdf" });
                    // console.log("Blob: ", blob);
                    var imageUrl = URL.createObjectURL(blob);
                    // console.log("Image URL: ", imageUrl);
                    var file = new File([blob], element?.document?.split("//")[2], {
                        type: "application/pdf",
                    });
                    // console.log("File: ", file);
                    setAgreement(imageUrl);
                    setLoading(false);
                } else {
                    let t = `image/${ext}`;
                    var blob = new Blob([imageBytes], { type: ext });
                    // console.log("Blob: ", blob);
                    var imageUrl = URL.createObjectURL(blob);
                    // console.log("Image URL: ", imageUrl);
                    var file = new File([blob], element?.document?.split("//")[2], {
                        type: ext,
                    });
                    // console.log("File: ", file);
                    setAgreement(imageUrl);
                    setLoading(false);
                }

            })
            .catch((error) => {
                console.log(error);
            });
    }

    const validateAgreement = (ag) => {
        setConfirmLoading(true);
        // console.log("Validating the agreement: ", ag);
        axios
            .get(`${API_URL}/api/agreement/agence/${ag.agreementId}`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                // console.log("resssss", res.data);
                setCourtier(res.data);
                setConfirmLoading(false);
                toast("Agréement validé avec succès!");
                window.location.reload();
            })
            .catch((err) => {
                console.log(err);
                setConfirmLoading(false);
            });
    }

    const revokeAgreement = (ag) => {
        setConfirmLoading(true);
        // console.log("Validating the agreement: ", ag);
        axios
            .get(`${API_URL}/api/agreement/agence/revoke/${ag.agreementId}`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                setCourtier(res.data);
                setConfirmLoading(false);
                toast("Agréement révoké avec succès!");
                window.location.reload();
            })
            .catch((err) => {
                console.log(err);
                setConfirmLoading(false);
            });
    }

    const validatedAgency = (agency) => {
        let v = 0;
        v = agency?.validAgrements.findIndex(ag => ag.compagnie.code === LOGGED_USER?.profil?.organisme?.code);
        // setMyAgreement(v);
        return v !== -1;
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };

    const handleChange = (pagination, filters, sorter) => {
        console.log("Various parameters", pagination, filters, sorter);
        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Rechercher ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Rechercher...
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reinitialiser
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filtrer
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        Fermer
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1677ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            title: "N° Police",
            dataIndex: "numeroPolice",
            key: "1",
            ...getColumnSearchProps("numeroPolice"),
        },
        {
            title: "Souscripteur",
            dataIndex: "souscripteur",
            key: "2",
            ...getColumnSearchProps("souscripteur"),
        },
        {
            title: "Date Effet",
            dataIndex: "dateEffet",
            key: "4",
        },
        {
            title: "Date Echeance",
            dataIndex: "dateEcheance",
            key: "5",
        },
        {
            title: "Statut reglement",
            dataIndex: "regle",
            key: "regle",
            render: (_, record) => (
                <>
                    {record.regle === "REGLE" ? (
                        <Tag color="success" key={_}>
                            REGLE
                        </Tag>
                    ) : (
                        <Tag color="blue" key={_}>
                            NON REGLE
                        </Tag>
                    )}
                </>
            ),
            filters: [
                {
                    text: "REGLE",
                    value: "REGLE",
                },
                {
                    text: "NON REGLE",
                    value: "NON REGLE",
                },
            ],
            filteredValue: filteredInfo.regle || null,
            onFilter: (value, record) => record.regle === value,
            sorter: (a, b) => a.regle.length - b.regle.length,
            sortOrder: sortedInfo.columnKey === "regle" ? sortedInfo.order : null,
            ellipsis: true,
        },
        {
            title: "Actions",
            dataIndex: "actions",
            key: "7",
            render: (_, record) => (
                <>
                    {rightsService("HLA037") && record.numeroPolice && record.regle !== "REGLE" && (
                        <button
                            type="button"
                            class="btn btn-success btn-icon rounded-pill"
                            onClick={() => {
                                setSelectedContrat(record);
                                setModalModePaiement(true);
                            }}
                        >
                            <i class="bx bxs-credit-card"></i>
                        </button>
                    )}
                    <button
                        type="button"
                        class="btn btn-primary btn-icon rounded-pill"
                        onClick={() => navigate(`/details-contrat/${record.codeId}`)}
                    >
                        <i class="bx bx-dots-vertical-rounded"></i>
                    </button>
                    {rightsService("HCO006") && <button type="button" class="btn btn-danger btn-icon rounded-pill" onClick={() => exportPropositionToPdf(record)}>
                        <i class="bx bxs-file-pdf"></i>
                    </button>}
                </>
            ),
        },
    ];

    const exportPropositionToPdf = (contrat) => {
        setSelectedContrat(contrat);
        setHiddenContrat(true);
    };

    const commissionColumns = [
        {
            title: "N° Police",
            dataIndex: "numeroPolice",
            key: "1",
        },
        {
            title: "Souscripteur",
            dataIndex: "souscripteur",
            key: "2",
        },
        {
            title: "Montant Prime",
            dataIndex: "primeNette",
            key: "4",
            render: (_, record) => (
                <>
                    {record.data.montant ? new Intl.NumberFormat("en-DE").format(
                        record.data.montant
                    ) : 0}
                </>
            )
        },
        {
            title: "Commission",
            dataIndex: "commission",
            key: "5",
            render: (_, record) => (
                <>
                    {record.data.commission ? new Intl.NumberFormat("en-DE").format(
                        record.data.commission
                    ) : 0}
                </>
            )
        },
        {
            title: "Statut reglement",
            dataIndex: "regle",
            key: "regle",
            render: (_, record) => (
                <>
                    {record.data.commissionPaye ? (
                        <Tag color="success" key={_}>
                            REGLE
                        </Tag>
                    ) : (
                        <Tag color="blue" key={_}>
                            NON REGLE
                        </Tag>
                    )}
                </>
            ),
            filters: [
                {
                    text: "REGLE",
                    value: "REGLE",
                },
                {
                    text: "NON REGLE",
                    value: "NON REGLE",
                },
            ],
            filteredValue: filteredInfo.regle || null,
            onFilter: (value, record) => record.regle === value,
            sorter: (a, b) => a.regle.length - b.regle.length,
            sortOrder: sortedInfo.columnKey === "regle" ? sortedInfo.order : null,
            ellipsis: true,
        },
        {
            title: "Actions",
            dataIndex: "actions",
            key: "7",
            render: (_, record) => (
                <>
                    {!record.data.commissionPaye && rightsService("HLA045") ? (
                        <>
                            <button
                                type="button"
                                class="btn btn-success btn-icon rounded-pill"
                                onClick={() => { showCommissionPaymentConfirm(record.data.codeId) }}
                            >
                                <i class="bx bxs-credit-card"></i>
                            </button>
                            <button
                                type="button"
                                class="btn btn-primary btn-icon rounded-pill"
                                onClick={() => {
                                    setSelectedContrat(record.contrat);
                                    setEditedTauxCommission(record.data.tauxCommission);
                                    setEditTauxCommissionOpenModal(true);
                                }}
                            >
                                <i class="bx bxs-edit"></i>
                            </button>
                        </>
                    ) : rightsService("HLA046") && <button
                        type="button"
                        class="btn btn-danger btn-icon rounded-pill"
                        onClick={() => { exportInvoiceCommissionPaymentToPdf(record) }}
                    >
                        <i class="bx bxs-file-pdf"></i>
                    </button>}
                </>
            ),
        },
    ];

    const handlePropositionPrint = useReactToPrint({
        content: () => printPropositionRef.current,
    });

    const handlePrint = useReactToPrint({
        content: () => printInvoiceCommissionRef.current,
    });


    const handleMultipleInvoicesPrint = useReactToPrint({
        content: () => printMultipleInvoicesRef.current,
    });

    useEffect(() => {
        if (hidden) {
            // If hidden is true, wait for the component to render and then print
            handlePrint();
        }
        if (hiddenContrat) {
            handlePropositionPrint();
        }
        if (hiddenMultpleInvoices) {
            handleMultipleInvoicesPrint();
        }
    }, [hidden, hiddenContrat]);

    const exportInvoiceCommissionPaymentToPdf = (contrat) => {
        console.log("The entire: ", contrat);
        setSelectedContrat(contrat);
        setHidden(true);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        console.log("TCS: ", JSON.stringify(tauxCommissions));

        // if (tauxCommission) {
        setLoading(true);
        // console.log("MyAgreement: " + JSON.parse(sessionStorage.getItem("myAgreement")));
        // console.log("MyAgreementId: ", myAgreementId);
        // myAgreement.tauxCommission = tauxCommission;
        // console.log("Taux commission" + tauxCommission);
        axios
            .get(`${API_URL}/api/agreement/update/${myAgreementId}/${selectedCategory.codeCategorie}/${tauxCommission}`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                setCourtier(res.data);
                setLoading(false);
                toast("Taux de commission défini avec succès!");
                window.location.reload();
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
        // } else {
        //     toast("Veuillez renseigner le taux s'il vous plait!");
        // }
    }

    const onSubmitEditTauxCommission = (e) => {
        e.preventDefault();

        // if (tauxCommission) {
        setLoading(true);
        axios
            .get(`${API_URL}/api/contrat/update-taux-commission/${selectedContrat.codeId}/${editedTauxCommission}`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                console.log(res.data);
                setLoading(false);
                toast("Taux de commission redéfini avec succès!");
                window.location.reload();
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    }

    const onSelectChange = (newSelectedRowKeys, rows) => {
        console.log("selectedRowKeys changed: ", newSelectedRowKeys);
        console.log("SelectedRows", rows);
        setSelecting(true);
        setSelectedRows(rows);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const showCommissionPaymentConfirm = (contratId) => {
        confirm({
            title: "Etes-vous sûr de vouloir payer cette commission?",
            icon: <ExclamationCircleFilled />,
            content: "Toutes vos actions sont historisées",
            okText: "Oui",
            okType: "danger",
            cancelText: "Annuler",
            onOk() {
                console.log("OK");
                commissionPayment(contratId);
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };

    const commissionPayment = (contratId) => {
        axios
            .get(`${API_URL}/api/reglement/commission-payment/${contratId}`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                // console.log(res.data);
                window.location.reload();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const alertReglement = () => {
        console.log(numeroPaiement);
        confirm({
            title: "Etes-vous sûr de vouloir régler ce contrat?",
            icon: <ExclamationCircleFilled />,
            content: "Toutes vos actions sont historisées!",
            okText: "Oui régler",
            okType: "danger",
            cancelText: "Annuler",
            onOk() {
                console.log("OK");
                reglementContrat();
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };

    const reglementContrat = () => {
        console.log("MP: ", modePaiement);

        if (modePaiement === "ESPECES") {
            // console.log("grumbling");
            axios
                .get(
                    `${API_URL}/api/reglement/especes/contrat/codeId/${selectedContrat.data.codeId}/${LOGGED_USER.codeId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${TOKEN}`,
                            "Content-Type": "application/json",
                            "Access-Control-Allow-Origin": "*",
                            "Access-Control-Allow-Methods":
                                "GET, PUT, POST, DELETE, OPTIONS",
                            "Access-Control-Allow-Headers":
                                "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                        },
                    }
                )
                .then((res) => {
                    window.location.reload();
                    toast("Règlement effectué avec succès");
                    // console.log(res.data);
                    res.data = null;
                })
                .catch((err) => {
                    console.log(err);
                    toast("Erreur lors du règlement");
                });
        } else if (modePaiement === "BANQUE" || modePaiement === "MOBILE_MONEY") {
            axios
                .get(
                    `${API_URL}/api/reglement/contrat/codeId/${selectedContrat.data.codeID}/${LOGGED_USER.codeId}/${modePaiement}/${numeroPaiement}/${banque}`,
                    {
                        headers: {
                            Authorization: `Bearer ${TOKEN}`,
                            "Content-Type": "application/json",
                            "Access-Control-Allow-Origin": "*",
                            "Access-Control-Allow-Methods":
                                "GET, PUT, POST, DELETE, OPTIONS",
                            "Access-Control-Allow-Headers":
                                "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                        },
                    }
                )
                .then((res) => {
                    window.location.reload();
                    toast("Règlement effectué avec succès!");
                    // console.log(res.data);
                    res.data = null;
                })
                .catch((err) => {
                    console.log(err);
                    toast("Erreur lors du règlement");
                });
        }

    };

    const encaisserContrats = (cts) => {
        setModalModePaiement(true);
        setContractsToPay(cts);
    };

    const exportToPdfs = (contrats) => {
        if (contrats.length === 1) {
            exportInvoiceCommissionPaymentToPdf(contrats[0]);
        } else {
            setSelectedContrats(contrats);
            // console.log("Contracts: ", contrats);
            setHiddenMultipleInvoices(true);
            handleMultipleInvoicesPrint();
        }
    };

    const MyFooter = () => {
        let ctrt = selectedRows.filter((ctr) => ctr.data.commissionPaye);
        let nCtrt = selectedRows.filter((ctr) => !ctr.data.commissionPaye);
        console.log("R: ", ctrt);
        // console.log("NR: ", nrCtrts);
        return (
            <div class="row">
                {!ctrt ? (
                    <div class="col-sm-4">
                        <Button
                            onClick={() => {
                                encaisserContrats(nCtrt);
                            }}
                            color="success"
                            icon={<CreditCardFilled />}
                            style={{
                                color: "red",
                            }}
                            title="Encaissement"
                        >
                            Encaisser
                        </Button>
                    </div>
                ) : (
                    <>
                        <div class="col-sm-4">
                            <Button
                                onClick={() => {
                                    exportToPdfs(ctrt);
                                }}
                                color="primary"
                                style={{ color: "green" }}
                                icon={<FilePdfFilled />}
                            >
                                Imprimer le reçu
                            </Button>
                        </div>
                    </>
                )}
            </div>
        );
    };


    return (
        <div class="layout-wrapper layout-content-navbar  ">
            <div class="layout-container">
                <SideBar currentMenu={"COURTIERS"} />

                {/* <!-- Layout container --> */}
                <div class="layout-page">
                    <NavBar />

                    {/* <!-- Content wrapper --> */}
                    <div class="content-wrapper">
                        {/* <!-- Content --> */}
                        <div class="container-xxl flex-grow-1 container-p-y">
                            <div class="d-flex flex-column flex-sm-row align-items-center justify-content-sm-between mb-6 text-center text-sm-start gap-2">
                                <div class="mb-2 mb-sm-0">
                                    <h4 class="mb-1">Code courtier #{courtier?.codeAgence}</h4>
                                    <p class="mb-0">Date de création : {courtier?.dateCreation}</p>
                                </div>
                                <button
                                    type="button"
                                    class="btn btn-label-danger delete-customer"
                                >
                                    Supprimer le courtier
                                </button>
                            </div>

                            <div class="row">
                                {/* <!-- Customer-detail Sidebar --> */}
                                <div class="col-xl-4 col-lg-5 col-md-5 order-1 order-md-0">
                                    <div class="card mb-6">
                                        <div class="card-body pt-12">
                                            <div class="customer-avatar-section">
                                                <div class="d-flex align-items-center flex-column">
                                                    {imagePreviewUrl ?
                                                        <img
                                                            class="img-fluid rounded mb-4"
                                                            src={imagePreviewUrl}
                                                            height="120"
                                                            width="120"
                                                            alt="User avatar"
                                                        /> : <div class="avatar avatar-lg me-2">
                                                            <span class="avatar-initial rounded-circle bg-label-secondary">
                                                                {courtier.nom?.charAt(0)}
                                                            </span>
                                                        </div>}
                                                    <div class="customer-info text-center mb-6">
                                                        <h5 class="mb-0">{courtier?.nom}</h5>
                                                        <span>Code #{courtier?.codeAgence}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-around flex-wrap mb-6 gap-0 gap-md-3 gap-lg-4">
                                                <div class="d-flex align-items-center gap-4 me-5">
                                                    <div class="avatar">
                                                        <div class="avatar-initial rounded bg-label-primary">
                                                            <i class="bx bx-user bx-lg"></i>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <h5 class="mb-0">{validatedContrats.length}</h5>
                                                        <span>Affaires</span>
                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center gap-4">
                                                    <div class="avatar">
                                                        <div class="avatar-initial rounded bg-label-primary">
                                                            <i class="bx bx-dollar bx-lg"></i>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <h5 class="mb-0">{new Intl.NumberFormat("en-DE").format(
                                                            sumCommission
                                                        )} FCFA</h5>
                                                        <span>Commissions</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="info-container">
                                                <h5 class="pb-4 border-bottom text-capitalize mt-6 mb-4">
                                                    Détails
                                                </h5>
                                                <ul class="list-unstyled mb-6">
                                                    <li class="mb-2">
                                                        <span class="h6 me-1">Nom du responsable:</span>
                                                        <span>{courtier?.responsable}</span>
                                                    </li>
                                                    <li class="mb-2">
                                                        <span class="h6 me-1">Email:</span>
                                                        <span>{courtier?.email}</span>
                                                    </li>
                                                    <li class="mb-2">
                                                        <span class="h6 me-1">Statut:</span>
                                                        {courtier?.active ? (
                                                            <span class="badge bg-label-success">Actif</span>
                                                        ) : (
                                                            <span class="badge bg-label-primary">
                                                                Inactif
                                                            </span>
                                                        )}
                                                    </li>
                                                    <li class="mb-2">
                                                        <span class="h6 me-1">Contacts:</span>
                                                        <span>
                                                            {courtier?.contact ? courtier?.contact[0] : ""}
                                                        </span>
                                                        <span>
                                                            {courtier?.contact && courtier?.contact[1]
                                                                ? `; ${courtier?.contact[1]}`
                                                                : ""}
                                                        </span>
                                                    </li>

                                                    <li class="mb-2">
                                                        <span class="h6 me-1">Siège:</span>
                                                        <span>{courtier?.siegeSocial}</span>
                                                    </li>
                                                    <li class="mb-2">
                                                        <span class="h6 me-1">Site web:</span>
                                                        <span>{courtier?.website}</span>
                                                    </li>
                                                    <li class="mb-2">
                                                        <span class="h6 me-1">Domaine:</span>
                                                        <span>{courtier?.domaine}</span>
                                                    </li>
                                                    <li class="mb-2">
                                                        <span class="h6 me-1">NIF:</span>
                                                        <span>{courtier?.nif ? courtier?.nif : "-"}</span>
                                                    </li>
                                                    <li class="mb-2">
                                                        <span class="h6 me-1">
                                                            Taux de commission:
                                                        </span>
                                                        <span>
                                                            {LOGGED_USER?.profil.organisme.typeOrganisme === "POOL" ?
                                                                courtier?.validAgrements?.map((va, id) => (
                                                                    <ul class="mb-2" key={id}>
                                                                        <u>{va.compagnie.raisonSociale}</u>
                                                                        {va?.tauxCommission ? va?.tauxCommission?.map(tc => (
                                                                            <li class="mb-2">
                                                                                {tc?.categorie?.nom} : <span class="h6 me-1">{tc?.taux} %</span>
                                                                            </li>
                                                                        )) : "Non défini"}
                                                                    </ul>
                                                                )) :
                                                                <ul class="mb-2">
                                                                    {myAgreement?.tauxCommission ? myAgreement?.tauxCommission?.map(tc => (
                                                                        <li class="mb-2">
                                                                            {tc.categorie.nom} : <span class="h6 me-1">{tc.taux} %</span>
                                                                        </li>
                                                                    )) : "Non défini"}
                                                                </ul>}
                                                            {/* {myAgreement?.tauxCommission ? `${myAgreement?.tauxCommission}%` : "Non défini"} */}
                                                        </span>
                                                    </li>
                                                </ul>
                                                <div class="d-flex justify-content-center">
                                                    <a
                                                        href="javascript:;"
                                                        class="btn btn-primary w-100"
                                                    >
                                                        Editer les informations
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!--/ Customer Sidebar --> */}

                                {/* <!-- Customer Content --> */}
                                <div class="col-xl-8 col-lg-7 col-md-7 order-0 order-md-1">
                                    {/* <!-- Customer Pills --> */}
                                    <div class="nav-align-top">
                                        <ul class="nav nav-pills flex-column flex-md-row mb-6">
                                            <li class="nav-item">
                                                <a
                                                    className={
                                                        activeTab === 0 ? "nav-link active" : "nav-link"
                                                    }
                                                    href="#contracts"
                                                    onClick={() => handleTabChange(0)}
                                                >
                                                    <i class="bx bx-detail bx-sm me-1_5"></i>Contrats
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a
                                                    className={
                                                        activeTab === 1 ? "nav-link active" : "nav-link"
                                                    }
                                                    href="#contracts"
                                                    onClick={() => handleTabChange(1)}
                                                >
                                                    <i class="bx bx-wallet-alt bx-sm me-1_5"></i> Commissions
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a
                                                    className={
                                                        activeTab === 2 ? "nav-link active" : "nav-link"
                                                    }
                                                    href="#apercu"
                                                    onClick={() => handleTabChange(2)}
                                                >
                                                    <i class="bx bx-check-circle bx-sm me-1_5"></i>Agréements
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a
                                                    className={
                                                        activeTab === 3 ? "nav-link active" : "nav-link"
                                                    }
                                                    href="#user"
                                                    onClick={() => handleTabChange(3)}
                                                >
                                                    <i class="bx bx-group bx-sm me-1_5"></i>Utilisateurs
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a
                                                    className={
                                                        activeTab === 4 ? "nav-link active" : "nav-link"
                                                    }
                                                    href="#notif"
                                                    onClick={() => handleTabChange(4)}
                                                >
                                                    <i class="bx bx-bell bx-sm me-1_5"></i>Notifications
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    {/* Toutes les affaires apportées par le courtier (compris les simples devis) */}
                                    {activeTab === 0 && (
                                        <>
                                            <div class="card mb-4">
                                                <div class="card-datatable table-responsive">
                                                    <ConfigProvider locale={frFR}>
                                                        <Spin spinning={loadingContrats}>
                                                            <Table
                                                                columns={columns}
                                                                dataSource={contrats}
                                                                onChange={handleChange}
                                                            />
                                                        </Spin>
                                                    </ConfigProvider>
                                                </div>
                                                <Modal
                                                    title="Mode de paiement"
                                                    centered
                                                    open={modalModePaiement}
                                                    onOk={() => {
                                                        alertReglement();
                                                    }}
                                                    onCancel={() => {
                                                        setModalModePaiement(false);
                                                    }}
                                                >
                                                    <>
                                                        <div
                                                            className="container"
                                                            justify="center"
                                                        >
                                                            <div className="grid-item" lg={12}>
                                                                <Form
                                                                    form={modePaiementForm}
                                                                    layout="vertical"
                                                                    name="userForm"
                                                                >
                                                                    <Form.Item
                                                                        name="modePaiement"
                                                                        label="Mode de paiement"
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Select
                                                                            onChange={(value) => {
                                                                                console.log(value);
                                                                                setModePaiement(value);
                                                                            }}
                                                                            placeholder="Selectionnez votre mode de paiement"
                                                                        >
                                                                            <Option value="ESPECES">
                                                                                {" "}
                                                                                ESPECES{" "}
                                                                            </Option>
                                                                            <Option value="BANQUE">
                                                                                BANQUE (Chèque)
                                                                            </Option>
                                                                            <Option value="MOBILE_MONEY">
                                                                                TMONEY / FLOOZ
                                                                            </Option>
                                                                        </Select>
                                                                    </Form.Item>
                                                                    {modePaiement === "BANQUE" ||
                                                                        modePaiement ===
                                                                        "MOBILE_MONEY" ? (
                                                                        <Form.Item
                                                                            name="numeroPaiement"
                                                                            label={
                                                                                modePaiement === "BANQUE"
                                                                                    ? "Numero de chèque"
                                                                                    : modePaiement ===
                                                                                        "MOBILE_MONEY"
                                                                                        ? "Numero FLOOZ / TMONEY"
                                                                                        : ""
                                                                            }
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message:
                                                                                        "Veuillez renseigner le champ!",
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Input
                                                                                placeholder={
                                                                                    modePaiement ===
                                                                                        "BANQUE"
                                                                                        ? "Le numero de chèque"
                                                                                        : modePaiement ===
                                                                                            "MOBILE_MONEY"
                                                                                            ? "Le numero de téléphone MOOV (FLOOZ) / TOGOCOM (TMONEY)"
                                                                                            : ""
                                                                                }
                                                                                onChange={(value) => {
                                                                                    console.log(
                                                                                        value.target.value
                                                                                    );
                                                                                    setNumeroPaiement(
                                                                                        value.target.value
                                                                                    );
                                                                                }}
                                                                            />
                                                                        </Form.Item>
                                                                    ) : null}
                                                                    {modePaiement === "BANQUE" ? (
                                                                        <Form.Item
                                                                            name="banque"
                                                                            label="Banque"
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Select
                                                                                onChange={(value) => {
                                                                                    console.log(value);
                                                                                    setBanque(value);
                                                                                }}
                                                                                placeholder="Selectionnez la banque"
                                                                            >
                                                                                {banques.map((b) => (
                                                                                    <Option
                                                                                        key={b.parametreId}
                                                                                        value={
                                                                                            b.libelleParametre
                                                                                        }
                                                                                    >
                                                                                        {b.libelleParametre}
                                                                                    </Option>
                                                                                ))}
                                                                            </Select>
                                                                        </Form.Item>
                                                                    ) : null}
                                                                </Form>
                                                            </div>
                                                        </div>
                                                    </>
                                                </Modal>
                                            </div>
                                            <div class="card" id="myContrat" ref={printPropositionRef}>
                                                {
                                                    hiddenContrat &&
                                                    <DTRFFormatProposition contrat={selectedContrat.data} />
                                                }
                                            </div>
                                        </>
                                    )}
                                    {/* Les commissions (Les contrats réglés) */}
                                    {activeTab === 1 && (
                                        <>
                                            <div class="card mb-4">
                                                <div class="card-datatable table-responsive">
                                                    <ConfigProvider locale={frFR}>
                                                        <Spin spinning={loadingContrats}>
                                                            {selecting ?
                                                                <Table
                                                                    rowSelection={rowSelection}
                                                                    columns={commissionColumns}
                                                                    dataSource={commissions}
                                                                    onChange={handleChange}
                                                                    footer={() => <MyFooter />}
                                                                />
                                                                :
                                                                <Table
                                                                    rowSelection={rowSelection}
                                                                    columns={commissionColumns}
                                                                    dataSource={commissions}
                                                                    onChange={handleChange}
                                                                />}
                                                        </Spin>
                                                    </ConfigProvider>
                                                </div>
                                            </div>
                                            {hidden &&
                                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "135vh", margin: 0, padding: 0 }}>
                                                    <InvoiceCommissionPayment ref={printInvoiceCommissionRef} contrat={selectedContrat} />
                                                </div>
                                            }
                                            {hiddenMultpleInvoices && <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "205vh", margin: 0, padding: 0 }}>
                                                <MultipleInvoicesCommissions ref={printMultipleInvoicesRef} contrats={selectedContrats} />
                                            </div>}
                                        </>
                                    )}
                                    {/*  AGreements */}
                                    {activeTab === 2 && (
                                        <div class="row">
                                            <div class="nav-align-top nav-tabs-shadow mb-6">
                                                <ul class="nav nav-tabs" role="tablist">
                                                    {LOGGED_USER?.profil?.organisme?.typeOrganisme === "POOL" ? courtier?.agrements?.map((agr, id) => (
                                                        <li class="nav-item">
                                                            <button type="button" onClick={() => loadAgreementDocument(agr, courtier)} class={`nav-link ${agreeActiveTab === id && "active"}`} role="tab" data-bs-toggle="tab" data-bs-target={`#navs-top-${agr.compagnie?.code}`} aria-controls="navs-top-home" aria-selected="true">Agréement - {agr.compagnie?.code} </button>
                                                        </li>
                                                    )) : validatedAgency() ? <li class="nav-item">
                                                        <button type="button" onClick={() => loadAgreementDocument(myAgreement, courtier)} class={`nav-link active`} role="tab" data-bs-toggle="tab" data-bs-target={`#navs-top-25`} aria-controls="navs-top-home" aria-selected="true">Agréement - {myAgreement?.compagnie?.code} </button>
                                                    </li> : null}
                                                </ul>
                                                <div class="tab-content">
                                                    {LOGGED_USER?.profil?.organisme?.typeOrganisme === "POOL" ? courtier?.agrements?.map((agr, id) => (
                                                        <div class={`tab-pane fade  ${agreeActiveTab === id && "show active"}`} id={`navs-top-${agr.compagnie?.code}`} role="tabpanel">
                                                            <p>
                                                                {agr.compagnie.raisonSociale} {" "}
                                                                {courtier?.validAgrements?.length ? validated ? <i className="bx bx-check-circle bx-sm me-sm-n2"></i> : "" : ""}
                                                            </p>
                                                            <div style={{ height: "100%", width: "100%" }}>
                                                                {loading || !agreement ? <Skeleton /> : agr.document.split(".")[1] === "pdf" ?
                                                                    <embed
                                                                        src={agreement}
                                                                        type="application/pdf"
                                                                        style={{
                                                                            width: "100%",
                                                                            height: "1000px",
                                                                        }}
                                                                    /> :
                                                                    <img
                                                                        class="img-fluid rounded mb-4"
                                                                        src={agreement}
                                                                        height="1000"
                                                                        width="1000"
                                                                        alt="Agreement"
                                                                    />}
                                                            </div>
                                                            <Popconfirm
                                                                title={validated ? "Révoquer" : "Valider"}
                                                                description={validated ? "Voulez-vous révoquer l'agréement?" : "Voulez-vous valider l'agréement?"}
                                                                open={open}
                                                                onConfirm={() => validated ? revokeAgreement(agr) : validateAgreement(agr)}
                                                                okButtonProps={{
                                                                    loading: confirmLoading,
                                                                }}
                                                                onCancel={handleCancel}
                                                            >
                                                                {validated ?
                                                                    <Button type="primary" style={{ background: "red", }} loading={confirmLoading} onClick={() => { setOpen(true) }}>
                                                                        Révoquer
                                                                    </Button> :
                                                                    <Button type="primary" loading={confirmLoading} onClick={() => { setOpen(true) }}>
                                                                        Valider
                                                                    </Button>}
                                                            </Popconfirm>

                                                        </div>
                                                    )) : validatedAgency() ? <div class={`tab-pane fade show active`} id={`navs-top-25`} role="tabpanel">
                                                        <p>
                                                            {myAgreement?.compagnie?.raisonSociale} {" "}
                                                            {courtier?.validAgrements?.length ? validated ? <i className="bx bx-check-circle bx-sm me-sm-n2"></i> : "" : ""}
                                                        </p>
                                                        <div style={{ height: "100%", width: "100%" }}>
                                                            {loading || !agreement ? <Skeleton /> : myAgreement?.document?.split(".")[1] === "pdf" ?
                                                                <embed
                                                                    src={agreement}
                                                                    type="application/pdf"
                                                                    style={{
                                                                        width: "100%",
                                                                        height: "1000px",
                                                                    }}
                                                                /> :
                                                                <img
                                                                    class="img-fluid rounded mb-4"
                                                                    src={agreement}
                                                                    height="1000"
                                                                    width="1000"
                                                                    alt="Agreement"
                                                                />}
                                                        </div>
                                                        <br />
                                                        <Popconfirm
                                                            title={validated ? "Révoquer" : "Valider"}
                                                            description={validated ? "Voulez-vous révoquer l'agréement?" : "Voulez-vous valider l'agréement?"}
                                                            open={open}
                                                            onConfirm={() => validated ? revokeAgreement(myAgreement) : validateAgreement(myAgreement)}
                                                            okButtonProps={{
                                                                loading: confirmLoading,
                                                            }}
                                                            onCancel={handleCancel}
                                                        >
                                                            {validated ?
                                                                <Button type="primary" style={{ background: "red", }} loading={confirmLoading} onClick={() => { setOpen(true) }}>
                                                                    Révoquer
                                                                </Button> :
                                                                <Button type="primary" loading={confirmLoading} onClick={() => { setOpen(true) }}>
                                                                    Valider
                                                                </Button>}
                                                        </Popconfirm>
                                                        <br />
                                                        <br />
                                                        {validated && categories.map((cat, id) => (
                                                            <>
                                                                <Button type="primary" style={{ background: "violet", }}
                                                                    onClick={() => {
                                                                        setSelectedCategory(cat);
                                                                        setTCModalOpen(true);
                                                                    }}
                                                                    key={id}
                                                                >
                                                                    Définir le taux de commission - {cat.nom}
                                                                </Button>
                                                                <br />
                                                                <br />
                                                            </>
                                                        ))}
                                                        {/* <Button type="primary" style={{ background: "orange", }}
                                                            onClick={() => {

                                                                setTCModalOpen(true);
                                                            }}
                                                        >
                                                            Définir le taux de retenue sur commission
                                                        </Button> */}
                                                    </div> : null}
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {activeTab === 3 && (
                                        <div>
                                            {/* User Tab content */}
                                            {/* Add the actual content for this tab */}
                                            {rightsService("HPE038") ? <Button
                                                type="primary"
                                                icon={<PlusOutlined />}
                                                style={{ marginBottom: 16 }}
                                                onClick={() => { navigate(`/ajouter-utilisateur-agence/${courtier.codeAgence}`) }}
                                            >
                                                Ajouter un nouvel utilisateur
                                            </Button> : null}
                                            <div class="card mb-6">
                                                <div class="table-responsive mb-4">
                                                    <table class="table datatables-customer-order border-top">
                                                        <thead>
                                                            <tr>
                                                                <th>Nom & Prénom(s)</th>
                                                                <th>Email</th>
                                                                <th>Profil</th>
                                                                <th>Agence</th>
                                                                <th>Statut</th>
                                                                <th class="text-md-center">Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {utilisateurs?.length ? (
                                                                utilisateurs?.map((user, id) => (
                                                                    <tr key={id}>
                                                                        <td>
                                                                            {user.personne.nom} {user.personne.prenom}
                                                                        </td>
                                                                        <td>{user.email}</td>
                                                                        <td>{user.profil.nom}</td>
                                                                        <td>{user.agence.nom}</td>
                                                                        <td>
                                                                            {user.active ? (
                                                                                <span class="badge bg-label-success">
                                                                                    Actif
                                                                                </span>
                                                                            ) : (
                                                                                <span class="badge bg-label-danger">
                                                                                    Inactif
                                                                                </span>
                                                                            )}
                                                                        </td>
                                                                        <td class="text-md-center">
                                                                            <div class="dropdown">
                                                                                <button
                                                                                    type="button"
                                                                                    class="btn p-0 dropdown-toggle hide-arrow"
                                                                                    data-bs-toggle="dropdown"
                                                                                >
                                                                                    <i class="bx bx-dots-vertical-rounded"></i>
                                                                                </button>
                                                                                <div class="dropdown-menu">
                                                                                    <a
                                                                                        class="dropdown-item"
                                                                                        href={`/details-utilisateur/${user.codeId}`}
                                                                                    >
                                                                                        <i class="bx bx-detail me-1"></i>{" "}
                                                                                        Détails
                                                                                    </a>
                                                                                    {rightsService("HPE041") ? <a
                                                                                        class="dropdown-item"
                                                                                        href="javascript:void(0);"
                                                                                    >
                                                                                        <i class="bx bx-edit-alt me-1"></i>{" "}
                                                                                        Modifier
                                                                                    </a> : null}
                                                                                    {rightsService("HPE042") ? <a
                                                                                        class="dropdown-item"
                                                                                        href="javascript:void(0);"
                                                                                        onClick={() => { }}
                                                                                    >
                                                                                        <i class="bx bx-trash me-1"></i>{" "}
                                                                                        Supprimer
                                                                                    </a> : null}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan={5}>
                                                                        Aucun utilisateur enregistré pour le moment
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {activeTab === 4 && (
                                        <div>
                                            {/* Notification Tab content */}
                                            {/* Add the actual content for this tab */}
                                        </div>
                                    )}

                                </div>
                                {/* <!--/ Customer Content --> */}
                            </div>

                            {/* <!--/ Add New Credit Card Modal --> */}

                            {/* <!-- /Modal --> */}
                        </div >
                        {/* <!-- / Content --> */}

                        {/* <!-- Footer --> */}
                        <Footer />
                        {/* <!-- / Footer --> */}
                        <div class="content-backdrop fade"></div>
                    </div >
                    {/* <!-- Content wrapper --> */}
                </div >
                {/* <!-- / Layout page --> */}
            </div >
            <Modal open={tcModalOpen} loading={loading} onOk={onSubmit} onCancel={handleClose} zIndex={1900} centered>

                <div className="text-center mb-6">
                    <h4 className="address-title mb-2">Taux de commission </h4>
                    <p className="address-subtitle">
                        Définir le taux de commission pour <b>{courtier?.nom}</b>
                    </p>
                </div>
                <form id="addNewAddressForm" className="row g-12" onSubmit={onSubmit}>
                    <div className="col-12 col-md-12">
                        <div class="row">
                            <div class="col-md-12 select2-primary">
                                <label class="form-label" for="tauxCommission">
                                    Veuillez entre la valeur du taux pour la catégorie <b>{selectedCategory.nom}</b>
                                </label>
                                <input className="form-control" id="tauxCommission" name="tauxCommission"
                                    onChange={(e) => {
                                        setTauxCommission(parseFloat(e.target.value));
                                    }}
                                    placeholder="Entrez le taux de commission en %" type="number"
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </Modal>

            <Modal open={editTauxCommissionOpenModal} loading={loading} onOk={onSubmitEditTauxCommission} onCancel={() => setEditTauxCommissionOpenModal(false)} zIndex={1900} centered>

                <div className="text-center mb-6">
                    <h4 className="address-title mb-2">Taux de commission </h4>
                    <p className="address-subtitle">
                        Recaclculer le taux de commission pour le contrat <b>{selectedContrat?.numeroPoliceP}</b>
                    </p>
                </div>
                <form id="addNewAddressForm" className="row g-12" onSubmit={onSubmitEditTauxCommission}>
                    <div className="col-12 col-md-12">
                        <div class="row">
                            <div class="col-md-12 select2-primary">
                                <label class="form-label" for="tauxCommission">
                                    Veuillez entre la valeur du taux de commission à considérer
                                </label>
                                <input className="form-control" id="tauxCommission" name="tauxCommission" value={editedTauxCommission}
                                    onChange={(e) => {
                                        setEditedTauxCommission(parseFloat(e.target.value));
                                    }}
                                    placeholder="Entrez le taux de commission en %" type="number"
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </Modal>

            <Modal open={rtcModalOpen} loading={loading} onOk={onSubmit} onCancel={() => setRTCModalOpen(false)} zIndex={1900} centered>

                <div className="text-center mb-6">
                    <h4 className="address-title mb-2">Retenue sur la commission </h4>
                    <p className="address-subtitle">
                        Définir le taux de retenu sur la commission pour <b>{courtier?.nom}</b>
                    </p>
                </div>
                <form id="addNewAddressForm" className="row g-12" onSubmit={onSubmit}>
                    <div className="col-12 col-md-12">
                        <div class="row">
                            <div class="col-md-12 select2-primary">
                                <label class="form-label" for="tauxCommission">
                                    Veuillez entre la valeur du taux de la retenue sur la commission
                                </label>
                                <input className="form-control" id="tauxCommission" name="tauxCommission"
                                    onChange={(e) => {
                                        setTauxRetenueCommission(parseFloat(e.target.value));
                                    }}
                                    placeholder="Entrez le taux de retenue sur la commission en %" type="number"
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </Modal>
            {/* <!-- Overlay --> */}
            <div div class="layout-overlay layout-menu-toggle" ></div >

            {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
            <div div class="drag-target" ></div >
        </div >
    )
}

export default DetailsCourtier