import axiosInstance from "../variables/api/axiosInstance";

export const searchProduction = async (searchValue) => {
    return await axiosInstance.get(`/api/contrat`, {
        params: {
            page: 0,
            size: 10,
            search: searchValue,
        },
    });
}



// export const searchProduction = async (searchValue) => {
//     const contrats = await recupererLesContrats();
//     const fuse = new Fuse(contrats, {
//         keys: ["numeroPoliceP", "numeroPoliceM", "dateEffet", "dateEcheance"],
//         threshold: 0.3,
//     });
//     console.log("contrats", contrats);
//     const fuseResponse = fuse.search(searchValue).map((result) => result.item);
//     console.log("response: ", fuseResponse);
//     return fuseResponse;
// }