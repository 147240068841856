import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { API_URL, TOKEN } from "../../../variables/constants";
import { Modal } from "antd";

const AddPrejudice = ({
  show,
  handleClose,
  editMode,
  selectedPrejudice,
  onSaveSuccess,
}) => {
  const [loading, setLoading] = useState(false);
  const [libellePrejudice, setLibellePrejudice] = useState("");
  const [montant, setMontant] = useState("");

  useEffect(() => {
    if (editMode && selectedPrejudice) {
      // Pré-remplissage des champs en mode édition
      setLibellePrejudice(selectedPrejudice.libellePrejudice || "");
      setMontant(selectedPrejudice.montant || "");
    } else {
      // Réinitialisation des champs en mode ajout
      setLibellePrejudice("");
      setMontant("");
    }
  }, [editMode, selectedPrejudice, show]); // Réinitialiser à chaque ouverture du modal

  const validateForm = () => {
    if (!libellePrejudice.trim()) {
      toast.error("Le libellé du préjudice est obligatoire.");
      return false;
    }
    if (isNaN(montant) || montant < 0) {
      toast.error("Le montant doit être un nombre valide et positif.");
      return false;
    }
    return true;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);

    const prejudiceData = {
      codeId: selectedPrejudice?.codeId || undefined, // Ajoute l'ID en mode édition
      libellePrejudice,
      montant: parseFloat(montant),
    };

    try {
      if (editMode) {
        await axios.put(`${API_URL}/api/prejudice`, prejudiceData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${TOKEN}`,
          },
        });
        toast.success("Préjudice modifié avec succès.");
      } else {
        await axios.post(`${API_URL}/api/prejudice`, prejudiceData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${TOKEN}`,
          },
        });
        toast.success("Préjudice ajouté avec succès.");
      }
      onSaveSuccess(); // Rafraîchit la liste des préjudices
      handleClose(); // Ferme le modal automatiquement
    } catch (error) {
      console.error(
        "Erreur lors de l'enregistrement :",
        error.response || error.message
      );
      toast.error("Erreur lors de l'enregistrement du préjudice.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Body>
        <button
          type="button"
          className="btn-close"
          onClick={handleClose}
          aria-label="Close"
        ></button>
        <div className="text-center mb-4">
          <h4>{editMode ? "Modifier le" : "Ajouter un"} préjudice</h4>
          <p className="text-muted">
            {editMode
              ? "Apportez vos modifications et enregistrez."
              : "Remplissez les informations pour ajouter un nouveau préjudice."}
          </p>
        </div>
        <form onSubmit={onSubmit}>
          <div className="mb-3">
            <label className="form-label">Libellé Préjudice</label>
            <input
              type="text"
              className="form-control"
              placeholder="Entrez le libellé du préjudice"
              value={libellePrejudice}
              onChange={(e) => setLibellePrejudice(e.target.value)}
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Montant</label>
            <input
              type="number"
              className="form-control"
              placeholder="Entrez le montant"
              value={montant}
              onChange={(e) => setMontant(e.target.value)}
              required
            />
          </div>
          <div className="text-center">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}
            >
              {loading ? "Enregistrement..." : "Enregistrer"}
            </button>
            <button
              type="button"
              className="btn btn-secondary ms-3"
              onClick={handleClose}
              disabled={loading}
            >
              Annuler
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddPrejudice;
