import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { API_URL, TOKEN } from "../../../variables/constants";

const AddCauseSinistre = ({
  show,
  handleClose,
  editMode,
  selectedCauseSinistre,
}) => {
  const [loading, setLoading] = useState(false);
  const [libelle, setLibelle] = useState("");

  // Pré-remplissage du formulaire lors de l'édition
  useEffect(() => {
    setLibelle(selectedCauseSinistre?.libelleParametre || "");
  }, [selectedCauseSinistre]);

  const resetForm = () => setLibelle("");

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const causeSinistreData = {
      parametreId: selectedCauseSinistre?.parametreId,
      libelleParametre: libelle,
      typeParametre: "CAUSE_SINISTRE",
    };

    const request = editMode
      ? axios.put(
          `${API_URL}/api/parametre`,
          JSON.stringify(causeSinistreData),
          {
            headers: {
              Authorization: `Bearer ${TOKEN}`,
              "Content-Type": "application/json",
            },
          },
        )
      : axios.post(
          `${API_URL}/api/parametre`,
          JSON.stringify(causeSinistreData),
          {
            headers: {
              Authorization: `Bearer ${TOKEN}`,
              "Content-Type": "application/json",
            },
          },
        );

    request
      .then(() => {
        toast.success(
          editMode
            ? "Cause du sinistre modifiée avec succès"
            : "Cause du sinistre ajoutée avec succès",
        );
        resetForm();
        handleClose();
      })
      .catch((err) => {
        const errorMessage =
          err.response?.data?.message || "Erreur lors de l'opération.";
        console.error("Error:", err);
        toast.error(errorMessage);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Body>
        <button
          type="button"
          className="btn-close"
          onClick={handleClose}
          aria-label="Close"
        ></button>
        <div className="text-center mb-6">
          <h4 className="address-title mb-2">
            {editMode ? "Modifier la" : "Ajouter une"} cause du sinistre
          </h4>
          <p className="address-subtitle">
            {editMode ? "Modifier la" : "Ajouter une nouvelle"} cause du
            sinistre
          </p>
        </div>
        <form
          id="addNewCauseSinistreForm"
          className="row g-12"
          onSubmit={onSubmit}
        >
          <div className="col-12 col-md-12">
            <label className="form-label" htmlFor="modalCauseSinistreLibelle">
              Libellé
            </label>
            <input
              type="text"
              id="modalCauseSinistreLibelle"
              onChange={(e) => setLibelle(e.target.value)}
              className="form-control"
              placeholder="Libellé de la cause du sinistre"
              name="libelle"
              value={libelle}
            />
          </div>
          <div className="col-12 text-center">
            <button
              type="submit"
              className="btn btn-primary me-3"
              disabled={loading}
            >
              {loading && (
                <span
                  className="spinner-grow me-1"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              {loading ? `Enregistrement...` : `Enregistrer`}
            </button>
            <button
              type="button"
              className="btn btn-label-secondary"
              onClick={resetForm}
            >
              Réinitialiser
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddCauseSinistre;
