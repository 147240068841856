import React from "react";
import {Card, Modal, Tag, Timeline, Typography} from "antd";
import {CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined} from "@ant-design/icons";
import {formatFrenchDate} from "../../../../variables/functions";

const OrderTimeline = ({order, open, cancel}) => {
    const {Text} = Typography;

    const statuses = [
        {
            key: "dateEmission",
            label: "Émission",
            comment: order.commentaire,
            user: order.emetteur,
            icon: <ClockCircleOutlined/>,
        },
        {
            key: "dateValidation",
            label: "Validation",
            comment: order.commentaireValidation,
            user: order.validateur,
            icon: <CheckCircleOutlined style={{color: "green"}}/>,
        },
        {
            key: "dateAutorisation",
            label: "Autorisation",
            comment: order.commentaireAutorisation,
            user: order.autorite,
            icon: <CheckCircleOutlined style={{color: "green"}}/>,
        },
        {
            key: "dateConfirmation",
            label: "Confirmation",
            comment: order.commentaireConfirmation,
            user: order.confirmateur,
            icon: <CheckCircleOutlined style={{color: "green"}}/>,
        },
        {
            key: "dateTraitement",
            label: "Traitement",
            comment: order.commentaireTraitement,
            user: order.traiteur,
            icon: <CheckCircleOutlined style={{color: "blue"}}/>,
        },
        {
            key: "dateApprobation",
            label: "Approbation",
            comment: order.commentaireApprobation,
            user: order.approbateur,
            icon: <CheckCircleOutlined style={{color: "blue"}}/>,
        },
        {
            key: "dateReglement",
            label: "Règlement",
            comment: order.commentaireReglement,
            user: order.regleur,
            icon: <CheckCircleOutlined style={{color: "blue"}}/>,
        },
        {
            key: "dateLivraison",
            label: "Livraison",
            comment: order.commentaireLivraison,
            user: order.livreur,
            icon: <CheckCircleOutlined style={{color: "green"}}/>,
        },
        {
            key: "dateReception",
            label: "Réception",
            comment: order.commentaireReception,
            user: order.receptionniste,
            icon: <CheckCircleOutlined style={{color: "green"}}/>,
        },
    ];

    const renderUserDetails = (user) => {
        if (user) {
            return (
                <div style={{padding: '0px'}}>
                    <Text strong>{`${user.personne.nom} ${user.personne.prenom}`}</Text> <br/>
                    <Tag>{user.profil.nom}</Tag>
                </div>
            );
        }
        return null;
    };

    const renderTimelineItems = () =>
        statuses.map(({key, label, comment, icon, user}) => {
            const date = order[key];
            if (date) {
                return (
                    <Timeline.Item
                        key={key}
                        dot={icon}
                        label={
                            <Tag color={'blue'}>
                                <Text style={{
                                    color: '#000'
                                }} type="secondary">{formatFrenchDate(date)}</Text>
                            </Tag>
                        }
                    >
                        <Text strong style={{
                            color: 'blue'
                        }}>{label}</Text>
                        <Card bordered actions={[
                            renderUserDetails(user)
                        ]} style={{
                            padding: 0,
                            alignItems: 'center',
                            background: 'beige'
                        }}>
                            <div>
                                {comment && (
                                    <>
                                        <Text italic style={{
                                            textAlign: 'left'
                                        }}>{comment}</Text>
                                    </>
                                )}
                            </div>


                        </Card>
                    </Timeline.Item>
                );
            }
            return null;
        });


    const renderRejection = () => {
        if (order.statutCommande === "REJETE") {
            return (
                <Timeline.Item dot={<CloseCircleOutlined style={{color: "red"}}/>}>
                    <Text strong style={{color: "red"}}>Rejeté</Text>
                    <br/>
                    <Text type="secondary">Motif: {order.motifRejet || "Non spécifié"}</Text>
                </Timeline.Item>
            );
        }
        return null;
    };
    console.log(order)
    return (
        <Modal open={open} onCancel={cancel} footer={null} width={750}
               title={
                   <div
                       style={{
                           marginTop: "20px",
                       }}
                   >
                       Commande de{" "}
                       <span style={{color: "blue"}}>{order?.nombre}</span> attestations{" "}
                       <span style={{color: "blue"}}>{order?.typeAttestation}</span>{" "}
                       <span style={{color: "blue"}}>{order?.categorie?.nom}</span> de{" "}
                       <span style={{color: "blue"}}>{order?.emetteur?.agence?.nom}</span>
                   </div>

               } style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <br/>

            <div style={{
                marginRight: '90px'
            }}>
                <Timeline mode="left">
                    {renderTimelineItems()}
                    {renderRejection()}
                </Timeline>
            </div>

        </Modal>
    );
};

export default OrderTimeline;
