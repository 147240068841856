import React, {forwardRef, useImperativeHandle, useState} from "react";
import PropTypes from "prop-types";
import {Button, Collapse} from "antd";
import {PlusCircleOutlined} from "@ant-design/icons";
import {useFormik} from "formik";
import {getAdversaireValidationSchema} from "./form-validations/schemaValidations";
import SingleItemForm from "./adversaire-form-elements/SingleItemForm";
import {toast} from "react-toastify";

const {Panel} = Collapse;

const InformationsSurAdversaire = forwardRef((props, ref) => {
    const {activeTab, setActiveTab} = props;
    const [isPieton, setIsPieton] = useState(false)
    const [wasAdversaireConducteur, setWasAdversaireConducteur] = useState(true)
    const initialValues = {
        items: [
            {
                pieton: "non",
                nom: "",
                prenom: "",
                telephone: "",
                profession: "",
                adresse: "",
                engin: {
                    marque: "",
                    puissance: "",
                    immatriculation: "",
                    usage: "PRIVE",
                },
                nombrePersonneTransportees: "",
                conducteur: {
                    nom: "",
                    prenom: "",
                    telephone: "",
                    numeroPermis: "",
                    categoriePermis: "",
                    dateDelivrance: null,
                    lieuDelivrance: "",
                    dateExpiration: null,
                    qualite: "",
                },
                societeAssurance: "",
                typeSinistre: "",
                conduisait: "oui",
                provenance: "",
                destination: "",
                vitesse: 0,
            },
        ],
    };

    const formik = useFormik({
        initialValues,
        validationSchema: getAdversaireValidationSchema(isPieton, wasAdversaireConducteur),
    });

    const [data, setData] = useState(
        initialValues.items.map((item, index) => ({
            key: index,
            label: `Adversaire ${index + 1}`,
            children: (
                <SingleItemForm
                    formik={formik}
                    index={index}
                    wasAdversaireConducteur={wasAdversaireConducteur}
                    setWasAdversaireConducteur={setWasAdversaireConducteur}
                    setIsPieton={setIsPieton}
                    isPieton={isPieton}
                />
            ),
        }))
    );

    useImperativeHandle(ref, () => ({
        isValidated: async () => {
            try {
                // Retrieve the validation schema based on isPieton and wasAdversaireConducteur
                const validationSchema = getAdversaireValidationSchema(isPieton, wasAdversaireConducteur);

                // Extract fields to validate based on formik values
                const fieldsToValidate = Object.keys(formik.values);

                // Construct a new object with the selected fields and their values
                const valuesToValidate = fieldsToValidate.reduce((acc, field) => {
                    acc[field] = formik.values[field];
                    return acc;
                }, {});

                // Validate the values against the schema
                await validationSchema.validate(valuesToValidate, {abortEarly: false});

                // If validation passes, proceed to the next tab
                setActiveTab(activeTab + 1);
            } catch (error) {
                // Check if validation errors exist
                if (error.inner && Array.isArray(error.inner)) {
                    error.inner.forEach(err => {
                        toast.error(err.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    });
                } else {
                    console.error("Unexpected validation error:", error);
                }
            }
        },
        sendState: () => {
            sessionStorage.setItem('infoAdvers', JSON.stringify(formik.values))
        },
        state: {},
    }));
    const handleAddItem = () => {
        const newIndex = formik.values.items.length;

        formik.setFieldValue("items", [
            ...formik.values.items,
            {
                pieton: "non",
                nom: "",
                prenom: "",
                telephone: "",
                profession: "",
                adresse: "",
                engin: {
                    marque: "",
                    puissance: "",
                    immatriculation: "",
                    usage: "PRIVE",
                },
                nombrePersonneTransportees: "",
                conducteur: {
                    nom: "",
                    prenom: "",
                    telephone: "",
                    numeroPermis: "",
                    categoriePermis: "",
                    dateDelivrance: null,
                    lieuDelivrance: "",
                    dateExpiration: null,
                    qualite: "",
                },
                societeAssurance: "",
                typeSinistre: "",
                conduisait: "oui",
                provenance: "",
                destination: "",
                vitesse: 0,
            },
        ]);

        setData((prevData) => [
            ...prevData,
            {
                key: newIndex,
                label: `Adversaire ${newIndex + 1}`,
                children: (
                    <SingleItemForm
                        formik={formik}
                        index={newIndex}
                        wasAdversaireConducteur={wasAdversaireConducteur}
                        setWasAdversaireConducteur={setWasAdversaireConducteur}
                        setIsPieton={setIsPieton}
                        isPieton={isPieton}
                    />
                ),
            },
        ]);
    };

    return (
        <>
            {formik.values.items.length === 1 ? (
                <SingleItemForm formik={formik} index={0}
                                setWasAdversaireConducteur={setWasAdversaireConducteur} setIsPieton={setIsPieton}
                                isPieton={isPieton} wasAdversaireConducteur={wasAdversaireConducteur}/>
            ) : (
                <Collapse accordion>
                    {formik.values.items.map((item, index) => (
                        <Panel header={`Adversaire ${index + 1}`} key={index}>
                            <SingleItemForm formik={formik} index={index} setIsPieton={setIsPieton}
                                            setWasAdversaireConducteur={setWasAdversaireConducteur}
                                            wasAdversaireConducteur={wasAdversaireConducteur} isPieton={isPieton}/>
                        </Panel>
                    ))}
                </Collapse>
            )}

            <div className="grid-container grid container" style={{justifyContent: "center"}}>
                <Button onClick={handleAddItem} color="primary" type="button">
                    <PlusCircleOutlined/> Ajouter un Adversaire
                </Button>
            </div>
        </>
    );
});

InformationsSurAdversaire.displayName = "InformationsSurAdversaire";

InformationsSurAdversaire.propTypes = {
    activeTab: PropTypes.number.isRequired,
    setActiveTab: PropTypes.func.isRequired,
};

export default InformationsSurAdversaire;
