import React, {useEffect, useRef, useState} from "react";
import NavBar from "../../../components/NavBar";
import {Footer} from "antd/es/layout/layout";
import {useLocation, useNavigate} from "react-router-dom";
import SideBar from "../../../components/SideBar";
import {Button, Card, Descriptions, Divider, Input, Select, Space, Spin, Tag, Typography} from "antd";
import axios from "axios";
import {API_URL, PARAMETERS, TOKEN} from "../../../variables/constants";
import {CardBody, CardHeader} from "react-bootstrap";
import {InfoCircleOutlined, PlusOutlined} from "@ant-design/icons";
import EvaluationModal from "../components/evaluation/EvaluationModal";
import {toast} from "react-toastify";
import {getParametre, saveReevaluation} from "../../../hooks/useHooks";

const {Text} = Typography

const {Option} = Select;


const EvaluationSinistre = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const data = location.state;
    const codeId = data.iz
    const recours = data?.recours
    console.log('rec ', recours)
    const [sinistre, setSinistre] = useState({})
    const [montant, setMontant] = useState(0.0)
    const [loadingSinistre, setLoadingSinistre] = useState(false)

    const [provisionGaranties, setProvisionGaranties] = useState([])
    const [valueSelected, setValueSelected] = useState([])
    const [garanties, setGaranties] = useState([])
    const [evaluation, setEvaluation] = useState([])
    const [name, setName] = useState('')
    const [items, setItems] = useState([])
    const [evaluationLoading, setEvaluationLoading] = useState(false)
    const [selectedVictime, setSelectedVictime] = useState(null)
    const [isEvaluating, setIsEvaluating] = useState(false);
    const [evaluatedVictims, setEvaluatedVictims] = useState([])
    const [motifs, setMotifs] = useState([])
    const [motifsLoading, setMotifsLoading] = useState(false)


    /*PREJUDICE DATA TEST*/
    const [selectedPrejudice, setSelectedPrejudice] = useState(null);
    const [cost, setCost] = useState("");

    const prejudices = [{
        label: "Préjudice matériel (dommages aux biens)",
        value: "Préjudice matériel"
    }, {
        label: "Préjudice corporel (dommages physiques)",
        value: "Préjudice corporel"
    }, {
        label: "Préjudice moral (souffrance psychologique)",
        value: "Préjudice moral"
    }, {label: "Préjudice économique (pertes financières)", value: "Préjudice économique"},];

    /*PREJUDICE DATA TEST END*/


    const inputRef = useRef(null);
    const addProvisionFormFields = () => {
        setProvisionGaranties([...provisionGaranties,]);
    };
    const removeProvisionFormFields = (index) => {
        let newProvisionFormFields = [...provisionGaranties];
        let id = valueSelected.findIndex((value) => value === index);
        if (id !== -1) {
            newProvisionFormFields.splice(id, 1);
        }

        setProvisionGaranties(newProvisionFormFields);
    };
    const onNameChange = (event) => {
        setName(event.target.value);
    };
    console.log('ta ', items)

    const [options, setOptions] = useState([]);

    useEffect(() => {
        if (motifs) {
            setOptions(motifs.map((motif) => ({
                label: motif.valeur, // Display value
                value: motif.valeur, // Selected value
            })));
        }
    }, [motifs]);

    const addItem = (e) => {
        e.preventDefault();

        if (!name?.trim()) {
            return; // Prevent adding empty or whitespace-only items
        }

        setOptions((prevOptions) => [...prevOptions, {label: name, value: name}]); // Update options immutably
        setItems((prevItems) => [...prevItems, name]); // Update items immutably

        setName(""); // Clear the input field

        inputRef.current?.focus(); // Focus the input directly
    };


    const handleChange = (e, nom) => {
        if (nom) {
            setEvaluation({...evaluation, [nom]: e});
        } else {
            const {name, value} = e.target;
            setEvaluation({...evaluation, [name]: value});
        }
    };

    const [motifRevision, setMotifRevision] = useState()
    const handleFindMotive = (e) => {
        setMotifRevision(e)
    }
    console.log(motifRevision)

    useEffect(() => {
        const fetchMotifs = async () => {
            setMotifsLoading(true)
            const response = await getParametre(PARAMETERS.motifRevision)
            setMotifs(response.data)
            setMotifsLoading(false)
        }
        fetchMotifs()
    }, []);

    useEffect(() => {
        if (codeId) {
            setLoadingSinistre(true)
            axios
                .get(`${API_URL}/api/sinistre/codeId/${codeId}`, {
                    headers: {
                        Authorization: `Bearer ${TOKEN}`,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                        "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                    },
                })
                .then((res) => {
                    console.log(res.data);
                    setSinistre(res.data);
                    let m = 0.0;
                    res.data.decaissements.forEach((d) => {
                        if (!d.encaissement) {
                            m += parseFloat(d.montantGlobal);
                        }
                    });
                    setMontant(m);
                    setLoadingSinistre(false)

                    if (res.data?.engin) {
                        axios
                            .get(`${API_URL}/api/garantie/categorie/compulsory/code/${res.data?.engin?.categorie?.codeCategorie}`, {
                                headers: {
                                    Authorization: `Bearer ${TOKEN}`,
                                    "Content-Type": "application/json",
                                    "Access-Control-Allow-Origin": "*",
                                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                                    "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                                },
                            })
                            .then((response) => {
                                let gs = [];
                                gs = response.data;
                                let g = [];
                                res.data.engin.sousGaranties && res.data.engin.sousGaranties.forEach((sg) => {
                                    gs.push(sg.garantie);
                                    g.push({
                                        value: sg.garantie.codeId, label: sg.nom, garantie: sg.garantie,
                                    });
                                });

                                response.data && response.data.forEach((ga) => {
                                    g.push({
                                        value: ga.codeId, label: ga.nom, garantie: ga,
                                    });
                                });

                                setGaranties(g);
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    }

                })
                .catch((err) => {
                    setLoadingSinistre(false)
                    console.log(err);
                });
        }
    }, [codeId]);
    const getStateOfSinistre = () => {
        const states = {
            I: {color: "green", label: "EN INSTANCE"},
            T: {color: "red", label: "TERMINÉ"},
            S: {color: "blue", label: "SANS SUITE"},
        };

        const {color, label} = states[sinistre?.sortDossier] || states["I"];

        return <Tag color={color}>{label}</Tag>;
    };


    const handleSelectVictimeChange = (value) => {
        // Find the selected victime object by its unique value
        const selected = sinistre.victimes.find((v) => `${v?.personne?.nom} ${v?.personne?.prenom}` === value);


        // Add the selected victim to the evaluatedVictims array if not already present
        setEvaluatedVictims((prev) => {
            if (!prev.some((victime) => victime === selected)) {
                return [...prev, selected];
            }
            return prev;
        });

        // Update the selectedVictime state
        setSelectedVictime(selected);
    };
    const handleToggleEvaluation = () => {
        setIsEvaluating(true);
    };

    // Function to retrieve all sessionStorage items with a specific key format
    const getAllEvaluationObjects = () => {
        const allItems = [];

        // Loop through all keys in sessionStorage
        for (let i = 0; i < sessionStorage.length; i++) {
            const key = sessionStorage.key(i);

            // Use a regular expression to check if the key matches the expected format
            const regex = /^([a-zA-Z0-9_-]+)-([a-zA-Z0-9_-]+)-([a-zA-Z0-9_-]+)$/;
            const match = key.match(regex);

            if (match) {
                // If the key matches the pattern, parse and store the value
                const storedValue = JSON.parse(sessionStorage.getItem(key));
                allItems.push(storedValue);
            }
        }

        return allItems;
    };
    console.log(sinistre)
// Usage example
    const sousEvaluations = getAllEvaluationObjects();
    console.log('sousEvaluations ', sousEvaluations);


    const groupByVictimeCodeId = (data) => {
        const grouped = {};

        // Loop through each item in the data
        data.forEach((item) => {
            const victimeCodeId = item.victime.codeId; // Group by victime.codeId

            // Initialize the group if it doesn't exist
            if (!grouped[victimeCodeId]) {
                grouped[victimeCodeId] = {
                    victime: item.victime, // Store the victime details
                    garanties: [] // Array to store garanties related to this victime
                };
            }

            // Push the garantieAffecte and prejudices into the correct group
            grouped[victimeCodeId].garanties.push({
                garantieAffecte: item.garantieAffecte, prejudices: item.prejudices
            });
        });

        // Return an array of all grouped items
        return Object.values(grouped);
    };
    const calculateTotalProvision = (prejudices) => {
        return prejudices?.reduce((total, current) => total + current.montantPrejudice, 0);
    };
    const victimesArray = groupByVictimeCodeId(sousEvaluations)
    console.log('vicarray', victimesArray)
    const calculateTotalMontantPrejudice = (victimesArray) => {
        return victimesArray?.reduce((total, victime) => {
            return total + victime.garanties?.reduce((garantieTotal, garantie) => {
                const prejudicesTotal = garantie.prejudices?.reduce((prejudiceSum, prejudice) => {
                    return prejudiceSum + prejudice.montantPrejudice;
                }, 0);
                return garantieTotal + (prejudicesTotal || 0);
            }, 0);
        }, 0);
    };
    console.log(sousEvaluations)
    const handleSubmit = async () => {
        setEvaluationLoading(true)
        const evaluation = {
            sinistre: sinistre,
            motifRevision: motifRevision,
            sousEvaluations: sousEvaluations,
            nouvelleProvision: calculateTotalMontantPrejudice(victimesArray),
            ancienneProvision: sinistre?.evaluation,
            recours: recours ? recours : null
        }
        try {
            const response = await saveReevaluation(evaluation)
            console.log(evaluation)
            toast.success('Reevaluation effectuée avec succes')
            setEvaluationLoading(false)

            const keysToRemove = [];

            for (let i = 0; i < sessionStorage.length; i++) {
                const key = sessionStorage.key(i);
                // Use a regular expression to check if the key matches the expected format
                const regex = /^([a-zA-Z0-9_-]+)-([a-zA-Z0-9_-]+)-([a-zA-Z0-9_-]+)$/;
                if (regex.test(key)) {
                    keysToRemove.push(key);
                }
            }
            keysToRemove.forEach((key) => {
                sessionStorage.removeItem(key);
            });
            navigate(`/details-sinistre`, {
                state: {codeId: codeId}
            })
        } catch (e) {
            toast.error('Echec de l\'operation')
            setEvaluationLoading(false)

        }
    }


    console.log('opps ', options)
    return (<div className="layout-wrapper layout-content-navbar  ">
        <div className="layout-container">
            <SideBar currentMenu={"SINISTRES"}/>

            {/* <!-- Layout container --> */}
            <div className="layout-page">
                <NavBar/>

                {/* <!-- Content wrapper --> */}
                <div className="content-wrapper">
                    {/* <!-- Content --> */}
                    <Spin spinning={loadingSinistre}>
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <div className={'card'}>
                                <div className="card-body">
                                    <div className="container">
                                        <div className="grid-item">
                                            <>
                                                {sinistre?.sortDossier === "I" ? (<Tag color="green">EN
                                                    INSTANCE</Tag>) : sinistre?.sortDossier === "T" ? (<Tag
                                                    color="red">TERMINÉ</Tag>) : sinistre?.sortDossier === "S" ? (
                                                    <Tag color="blue">SANS SUITE</Tag>) : (
                                                    <Tag color="green">EN INSTANCE</Tag>)}
                                            </>
                                            <br/>
                                            <br/>
                                            <Descriptions
                                                title={`Sinistre N° - ${sinistre?.numSinistre ? sinistre?.numSinistre : "-"} `}
                                            >
                                                <Descriptions.Item label="Numéro de Police">
                                                    {sinistre?.contrat?.souscripteur?.numeroPoliceM ? `${sinistre?.contrat?.numeroPoliceM}` : sinistre?.contrat?.numeroPoliceP ? `${sinistre?.contrat?.numeroPoliceP}` : "-"}
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Assuré">
                                                    {sinistre?.contrat?.souscripteur?.prenom || sinistre?.contrat?.souscripteur?.nom ? `${sinistre?.contrat?.souscripteur?.prenom || ''} ${sinistre?.contrat?.souscripteur?.nom || ''}`.trim() : '-'}

                                                </Descriptions.Item>
                                                <Descriptions.Item
                                                    label="Immatriculation engin">
                                                    {sinistre?.engin?.immatriculation}
                                                </Descriptions.Item>
                                                <Descriptions.Item
                                                    label="Provision actuelle">
                                                    {sinistre?.evaluation ? new Intl.NumberFormat("en-DE").format(sinistre?.evaluation) : "0"}{" "}
                                                    FCFA
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Sinistre à payer">
                                                    {sinistre?.sap ? new Intl.NumberFormat("en-DE").format(sinistre?.evaluation - montant) : "0"}{" "}
                                                    FCFA
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Montant réglé">
                                                    {sinistre?.sap ? new Intl.NumberFormat("en-DE").format(montant) : "0"}{" "}
                                                    FCFA
                                                </Descriptions.Item>
                                            </Descriptions>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>

                            </div>
                            <Card style={{
                                marginTop: '10px'
                            }}>
                                <CardBody>
                                    <div className={' grid container'}>
                                        <div className={'grid-item'}>
                                            <div>
                                                <b>Ancienne provision :</b>{" "}
                                                <Tag color="blue">
                                                    <b>
                                                        {sinistre?.evaluation ? new Intl.NumberFormat("en-DE").format(sinistre?.evaluation) : "0"}{" "}
                                                        FCFA
                                                    </b>
                                                </Tag>
                                            </div>
                                            <br/>
                                            <div>
                                                <b>Nouvelle provision :</b>{"  "}
                                                <Tag color="green">
                                                    <b>
                                                        {new Intl.NumberFormat("en-DE").format(calculateTotalMontantPrejudice(victimesArray))}{" "}
                                                        FCFA
                                                    </b>
                                                </Tag>
                                                <br/>
                                                <div>
                                                    <Space direction="vertical" style={{width: "100%"}}>
                                                        <div style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            alignItems: "center",
                                                            gap: "16px"
                                                        }}>
                                                            <Select
                                                                placeholder="Sélectionner la victime"
                                                                style={{flex: 1, height: "50px"}}
                                                                onChange={handleSelectVictimeChange}
                                                            >
                                                                {sinistre?.victimes?.map((victime, index) => (
                                                                    <Option
                                                                        key={index} // Use a unique identifier if possible, e.g., victime.id
                                                                        value={`${victime?.personne?.nom} ${victime?.personne?.prenom}`}
                                                                    >
                                                                        {victime?.personne?.nom} {victime?.personne?.prenom}
                                                                    </Option>
                                                                ))}
                                                            </Select>

                                                            <Button
                                                                type="primary"
                                                                onClick={handleToggleEvaluation}
                                                                disabled={!selectedVictime} // Disable button if no victim is selected
                                                                style={{height: "50px", minWidth: "150px"}}
                                                            >
                                                                Evaluer
                                                            </Button>
                                                        </div>

                                                        {isEvaluating && selectedVictime && (
                                                            <div style={{marginTop: "16px", textAlign: "center"}}>
                                                                <Text type="success">
                                                                    Évaluation en cours
                                                                    pour {selectedVictime.personne.nom} {selectedVictime.personne.prenom}.
                                                                </Text>
                                                            </div>
                                                        )}
                                                    </Space>
                                                </div>

                                                <br/>
                                            </div>

                                            <div
                                                style={{
                                                    display: 'flex', flexWrap: 'wrap', gap: '20px',
                                                }}
                                            >
                                                {victimesArray?.map((item, index) => (<Card
                                                    key={index}
                                                    style={{
                                                        width: '450px',
                                                        height: 'auto',
                                                        padding: '15px',
                                                        borderRadius: '10px',
                                                        boxShadow: '0 4px 8px rgba(0,0,0,0.1)', //   background: 'linear-gradient(to right, rgba(0, 0, 255, 0.5), rgba(238, 130, 238, 0.5))'
                                                        background: 'linear-gradient(to right, rgba(173, 216, 230, 0.5), rgba(238, 174, 255, 0.5))'

                                                    }}
                                                >
                                                    <CardHeader
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            marginBottom: '10px'
                                                        }}
                                                    >
                                                        <Text
                                                            type="secondary"
                                                            style={{
                                                                fontWeight: 'bold',
                                                                textAlign: 'center',
                                                                fontSize: '16px',
                                                            }}
                                                        >
                                                            {item.victime?.personne.nom} {item.victime?.personne.prenom}
                                                        </Text>
                                                    </CardHeader>

                                                    {/* Map through the guarantees and show them */}
                                                    <div style={{marginBottom: '15px'}}>
                                                        {item.garanties?.map((garantie, index) => {
                                                            const totalMontant = calculateTotalProvision(garantie.prejudices);
                                                            return (<div key={index} style={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                marginBottom: '10px'
                                                            }}>
                                                                <Text style={{
                                                                    fontSize: '14px', fontWeight: 'bold'
                                                                }}>
                                                                    {garantie.garantieAffecte.nom}
                                                                </Text>
                                                                <Tag
                                                                    color="geekblue">{totalMontant} FCFA</Tag>
                                                            </div>);
                                                        })}
                                                    </div>

                                                    {/* Buttons at the bottom */}
                                                    <div style={{
                                                        display: 'flex', justifyContent: 'space-between'
                                                    }}>
                                                        <Button
                                                            type="default"
                                                            icon={<InfoCircleOutlined/>}
                                                            style={{width: '48%'}}
                                                        >
                                                            Détails
                                                        </Button>
                                                        <Button
                                                            type="primary"
                                                            icon={<PlusOutlined/>}
                                                            style={{width: '48%'}}
                                                        >
                                                            Nouvelle provision
                                                        </Button>
                                                    </div>
                                                </Card>))}
                                            </div>
                                            <br/>
                                            <div className={'form-control'} style={{
                                                border: 'none',
                                                width: '100%'
                                            }}>
                                                <Spin spinning={motifsLoading}>
                                                    <Select
                                                        id="motifRevision"
                                                        name="motifRevision"
                                                        placeholder="Motif de la révision"
                                                        style={{
                                                            width: "100%",
                                                            height: "50px",
                                                        }}
                                                        dropdownRender={(menu) => (
                                                            <>
                                                                {menu}
                                                                <Divider style={{margin: "8px 0"}}/>
                                                                <Space style={{padding: "0 8px 4px"}}>
                                                                    <Input
                                                                        placeholder="Ajouter un motif"
                                                                        ref={inputRef}
                                                                        value={name}
                                                                        onChange={onNameChange}
                                                                    />
                                                                    <Button
                                                                        type="text"
                                                                        icon={<PlusOutlined/>}
                                                                        onClick={addItem}
                                                                    >
                                                                        Ajouter un motif
                                                                    </Button>
                                                                </Space>
                                                            </>
                                                        )}
                                                        options={options}
                                                        onChange={(value) => {
                                                            handleChange(value, "motifRevision");
                                                            handleFindMotive(value);
                                                        }}
                                                        value={evaluation.motifRevision}
                                                    />
                                                </Spin>

                                            </div>
                                            <br/>
                                            <Button type={'primary'} disabled={evaluationLoading}
                                                    onClick={handleSubmit}> Enregistrer l&apos;evaluation </Button>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                        <EvaluationModal victime={selectedVictime} evalCancel={() => setIsEvaluating(false)}
                                         evalOk={() => setIsEvaluating(false)} evalOpen={isEvaluating}
                                         garanties={garanties}
                                         setIsEvaluating={setIsEvaluating}/>
                    </Spin>
                    {/* <!-- / Content --> */}

                    {/* <!-- Footer --> */}
                    <Footer/>
                    {/* <!-- / Footer --> */}

                    <div className="content-backdrop fade">
                    </div>
                </div>
                {/* <!-- Content wrapper --> */}
            </div>
            {/* <!-- / Layout page --> */}
        </div>

        {/* <!-- Overlay --> */}
        <div className="layout-overlay layout-menu-toggle"></div>

        {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
        <div className="drag-target"></div>
    </div>);
};

export default EvaluationSinistre;


