import {CreditCardOutlined} from "@ant-design/icons";
import {
    Button, ConfigProvider, DatePicker, Descriptions, message, Select, Space, Switch, Table, Upload,
} from "antd";
import frFR from "antd/locale/fr_FR";
import axios from "axios";
import Stepper from "bs-stepper";
import dayjs from "dayjs";
import React, {useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import Footer from "../../../components/Footer";
import NavBar from "../../../components/NavBar";
import SideBar from "../../../components/SideBar";
import {rightsService} from "../../../services/rightsService";
import axiosInstance from "../../../variables/api/axiosInstance";
import axiosMultiPartDataInstance from "../../../variables/api/axiosMultiPartDatainstance";
import {API_URL, LOGGED_USER, TOKEN} from "../../../variables/constants";

const {Option} = Select;

const AddContrat = () => {
    const [typePersonne, setTypePersonne] = useState("PHYSIQUE");
    const [categorie, setCategorie] = useState("MOTO");
    const [selectedCategorie, setSelectedCategorie] = useState("");
    const [categories, setCategories] = useState([]);
    const [typePieces, setTypePieces] = useState([]);
    const [garanties, setGaranties] = useState([[]]);
    const [concessionnaires, setConcessionnaires] = useState([]);
    const [courtiers, setCourtiers] = useState([]);
    const [organisme, setOrganisme] = useState("");
    const [carosseries, setCarosseries] = useState([]);
    const [marques, setMarques] = useState([]);
    const [allMarques, setAllMarques] = useState([]);
    const [carteGrise, setCarteGrise] = useState("");
    const [activeTab, setActiveTab] = useState(1);
    const [totalGarantiesP, setTotalGarantiesP] = useState(0);
    const [detailsGarantiesP, setDetailsGarantiesP] = useState([]);
    const [totalGarantiesO, setTotalGarantiesO] = useState(0);
    const [tauxReduction, setTauxReduction] = useState(0);
    const [cent, setCent] = useState(false);
    const [fractionnementSelect, setFractionnementSelect] = useState("");
    const [tauxFractionnementSelect, setTauxFractionnementSelect] = useState("");
    const [fraisAccessoires, setFraisAccessoires] = useState(1000);
    const [tauxCommission, setTauxCommission] = useState(0);
    const [exonereDeTaxe, setExonereDeTaxe] = useState(false);
    const [differentSouscripteur, setDifferentSouscripteur] = useState(false);
    const [taxe, setTaxe] = useState(0);
    const [professions, setProfessions] = useState([]);
    const [accessoires, setAccessoires] = useState([]);
    const [dateEffet, setDateEffet] = useState(dayjs());
    const [dateEcheance, setDateEcheance] = useState();
    const [loading, setLoading] = useState(false);
    const [existChassis, setExistChassis] = useState([false]);
    const [existImmatriculation, setExistImmatriculation] = useState([false]);
    const [showAddPersonModal, setShowAddPersonModal] = useState(false);
    const [showAddInsuredModal, setShowAddInsuredModal] = useState(false);
    const [formData, setFormData] = useState([{
        categorie: "MOTO",
        marque: "",
        numeroSerie: "",
        matricule: "",
        typeSerie: "TG",
        immatriculation: "",
        puissance: 1,
        volume: "",
        nombrePlace: 0,
        energie: "ESSENCE",
        numeroChassis: "",
        valeurDeclaree: "",
        valeurVenale: "",
        valeurANeuf: "",
        dateMiseEnCirculation: "",
        puissanceType: [],
        sousGaranties: [],
        garanties: [],
        usage: "TAXI",
        carosserie: null,
        moteur: "",
        type: "",
        genre: "VELOMOTEUR",
        concessionnaire: null,
        rc: 0,
        carteGrise: "",
        personne: {
            personneId: 0,
            nom: "",
            prenom: "",
            contact: [],
            profession: "",
            genre: "MASCULIN",
            adresse: "",
            email: "",
            pieces: [],
            numIdentite: "",
            lieuDelivrance: "",
            dateDelivrance: "",
            typePiece: "CNI",
        },
        codeId: "",
    },]);
    const [personneData, setPersonneData] = useState({
        nom: "",
        prenom: "",
        genre: "MASCULIN",
        profession: "",
        email: "",
        contact: [],
        typePersonne: "",
        adresse: "",
        pieces: [],
        numIdentite: "",
        lieuDelivrance: "",
        dateDelivrance: "",
        dateNaissance: "",
        typePiece: "CNI",
        nif: "",
        numeroRegistreCommerce: "",
    });

    const [insuredData, setInsuredData] = useState({
        nom: "",
        prenom: "",
        genre: "MASCULIN",
        profession: "",
        email: "",
        contact: [],
        typePersonne: "",
        adresse: "",
        pieces: [],
        numIdentite: "",
        lieuDelivrance: "",
        dateDelivrance: "",
        dateNaissance: "",
        typePiece: "CNI",
        nif: "",
        numeroRegistreCommerce: "",
    });

    const [ncGuaranties, setNcGuaranties] = useState([]);
    const [subGaranties, setSubGaranties] = useState([]);

    const [reductions, setReductions] = useState([]);
    const [fractionnements, setFractionnements] = useState([]);

    const [agences, setAgences] = useState([]);
    const [selectedAgence, setSelectedAgence] = useState("");
    const [intermediaires, setIntermediaires] = useState([]);
    const [selectedIntermediaire, setSelectedIntermediaire] = useState("");
    const [agencyProduction, setAgencyProduction] = useState("");
    const [selectedCompany, setSelectedCompany] = useState("");
    const [oldInsured, setOldInsured] = useState(false);
    const [oldClient, setOldClient] = useState("");
    const [cSelected, setCSelected] = useState(false);
    const [clients, setClients] = useState([]);

    const [errors, setErrors] = useState([{}]);

    const [sfErrors, setSfErrors] = useState([]);
    const [ifErrors, setIfErrors] = useState([]);
    const [insuredFErrors, setInsuredFErrors] = useState([]);

    const [personneId, setPersonneId] = useState(0); // id de l'assuré suivant le numéro de l'engin dans la flotte

    const columns = [{
        title: "Garantie", dataIndex: "nom", key: "nom",
    }, {
        title: "Capital",
        dataIndex: "capital",
        key: "capital",
        render: (capital) => (<>{capital ? new Intl.NumberFormat("en-DE").format(capital) : "-"} </>),
    }, {
        title: "Prime nette",
        dataIndex: "primeNette",
        key: "primeNette",
        render: (primeNette) => (<>{new Intl.NumberFormat("en-DE").format(primeNette)} </>),
    },];

    const addFlotteFormFields = () => {
        let currentFormField = [...formData];
        currentFormField.push({
            categorie: currentFormField[0].categorie,
            marque: "",
            numeroSerie: "",
            matricule: "",
            typeSerie: "TG",
            immatriculation: "",
            puissance: "",
            volume: "",
            nombrePlace: 0,
            energie: "ESSENCE",
            numeroChassis: "",
            valeurDeclaree: "",
            valeurVenale: "",
            valeurANeuf: "",
            dateMiseEnCirculation: "",
            puissanceType: [],
            sousGaranties: [],
            garanties: [],
            usage: categorie === "MOTO" ? "TAXI" : "PRIVE",
            carosserie: null,
            moteur: "",
            type: "",
            genre: categorie === "MOTO" ? "VELOMOTEUR" : null,
            concessionnaire: null,
            rc: 0,
            carteGrise: "",
            personne: {
                personneId: 0,
                nom: "",
                prenom: "",
                contact: [],
                profession: "",
                genre: "MASCULIN",
                adresse: "",
                email: "",
                pieces: [],
                piece: {
                    numIdentite: "", lieuDelivrance: "", dateDelivrance: "", typePiece: "",
                },
            },
            codeId: "",
        });
        setFormData(currentFormField);
    };

    const handleClose = () => setShowAddPersonModal(false);
    const handleInsuredClose = () => setShowAddInsuredModal(false);

    const handleShowAddPersonModal = (id) => {
        setPersonneId(id);
        setShowAddPersonModal(true);
    };

    const handleDateMiseEnCirculation = (value, index) => {
        let newFormValues = [...formData];
        newFormValues[index]["dateMiseEnCirculation"] = value.toISOString();
        setFormData(newFormValues);
    };

    const removeFormFields = (index) => {
        let newFormData = [...formData];
        let newGaranties = [...garanties];
        newFormData.splice(index, 1);
        newGaranties.splice(index, 1);
        setFormData(newFormData);
        setGaranties(newGaranties);
    };

    const initValeurs = () => {
        let newFormData = [...formData];
        newFormData.forEach((data) => {
            data.valeurDeclaree = selectedCategorie.valeurVenaleMinimale;
            data.valeurVenale = selectedCategorie.valeurVenaleMinimale;
            data.valeurANeuf = selectedCategorie.valeurVenaleMinimale;
        });
        console.log("OK", selectedCategorie);
        setFormData(newFormData);
    }

    const handleChange = (e, name, index) => {
        let newFormValues = [...formData];
        if (selectedCategorie?.codeCategorie?.startsWith("TPV")) {
            newFormValues[index].carosserie = null;
            newFormValues[index].genre = null;
        }
        if (name === "carteGrise") {
            newFormValues[index].carteGrise = e;
        } else if (name === "marque") {
            initValeurs();
            console.log('Marque; ', e)
            newFormValues[index].marque = e;
        } else if (name === "numeroChassis") {
            verifyChassisNumber(e.target.value.toUpperCase(), index);
            newFormValues[index].numeroChassis = e.target.value.toUpperCase();
        } else {
            const {id, value} = e.target;
            if (id === "numeroSerie" || id === "nom") {
                newFormValues[index].numeroSerie = value.toUpperCase();
            } else {
                newFormValues[index][id] = value;
            }
            if (e.target.name === "valeurVenale") {
                newFormValues[index].valeurANeuf = value;
                newFormValues[index].valeurDeclaree = value;
            }

            newFormValues[index].immatriculation = selectedCategorie?.codeCategorie?.startsWith("VT") || newFormValues[index].typeSerie !== "TG" ? newFormValues[index].matricule : newFormValues[index].typeSerie + " " + newFormValues[index].matricule + " " + newFormValues[index].numeroSerie;

            if (id === "numeroSerie" || id === "matricule" || id === "typeSerie") {
                verifyImmatriculation(newFormValues[index].immatriculation, index);
            }
        }

        setFormData(newFormValues);
    };

    const formatCNI = (value) => {
        // Supprimer tous les tirets existants
        value = value.replace(/-/g, '');

        // Ajouter les tirets aux positions appropriées
        if (value.length > 4 && value.length <= 7) {
            value = value.slice(0, 4) + '-' + value.slice(4);
        } else if (value.length > 7) {
            value = value.slice(0, 4) + '-' + value.slice(4, 7) + '-' + value.slice(7, 11);
        }

        return value;
    }

    const handleAssureChange = (e, index) => {
        let newFormValues = [...formData];

        const {name, value} = e.target;

        if (name === "nom") {
            newFormValues[index].personne[name] = value.toUpperCase();
        } else if (name === "numIdentite") {
            if (newFormValues[index].personne.typePiece === "CNI") {
                newFormValues[index].personne[name] = formatCNI(value);
            }

        } else {
            newFormValues[index].personne[name] = value;
        }
        console.log("FormValues: ", newFormValues);

        setFormData(newFormValues);
    };

    const handleInsuredChange = (e) => {
        const {name, value} = e.target;
        setInsuredData({...insuredData, [name]: value});
    };

    const handlePersonneChange = (e) => {
        const {id, value, name} = e.target;
        if (name === "telephone") {
            let contacts = [value];
            setPersonneData({...personneData, ["contact"]: contacts});
        } else if (name === "nom") {
            setPersonneData({...personneData, [name]: value.toUpperCase()});
        } else if (name === "numIdentite") {
            if (personneData.typePiece === "CNI") {
                setPersonneData({...personneData, [name]: formatCNI(value)});
            }
        } else {
            setPersonneData({...personneData, [id]: value});
        }

    };

    const handleGarantiesChange = (value, index) => {
        let newGaranties = [...garanties];
        newGaranties[index] = value;
        setGaranties(newGaranties);
    };

    const handleSelectReduction = (e) => {
        setReductions(e);
    };

    const getTauxCommissionByCourtierAndCodeCategorie = (codeAgence, codeCompagnie) => {
        axios
            .get(`${API_URL}/api/agence/taux-commission/${codeAgence}/${categorie}/${codeCompagnie}`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                console.log("taux-commission-fetched: ", res.data);
                res.data && setTauxCommission(res.data);
            })
            .catch((err) => {
                console.log(err);
                toast("Taux de commission non encore défini pour cette catégorie pour ce courtier");
            });
    }

    const getTauxCommissionByIntermediaireAndCodeCategorie = (codeIntermediaire) => {
        axios
            .get(`${API_URL}/api/intermediaire/taux-commission/${codeIntermediaire}/${categorie}`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                console.log("taux-commission-fetched: ", res.data);
                res.data && setTauxCommission(res.data);
            })
            .catch((err) => {
                console.log(err);
                toast("Taux de commission non encore défini pour cette catégorie pour ce courtier");
            });
    }

    const handleAgenceChange = (value) => {
        let ag = agences.find(el => el.codeAgence === value);
        if (ag && ag.typeAgence !== "COURTIER") {
            getIntermediairesByAgency(value);
        } else {
            setIntermediaires([]);
            ag = courtiers.find(el => el.codeAgence === value);
            getTauxCommissionByCourtierAndCodeCategorie(value, LOGGED_USER.profil.organisme.code);
        }
        setAgencyProduction(ag);
        console.log("Agency production: ", ag, value);
        setSelectedAgence(value);
    };

    const handleIntermediaireChange = (e) => {
        getTauxCommissionByIntermediaireAndCodeCategorie(e.target.value);
        setSelectedIntermediaire(e.target.value);
    }

    const handleCourtierChange = (e) => {
        setSelectedCompany(e.target.value);
    }

    const nonCompulsoryGuaranties = (categorie) => {
        const cat = categories.find((e) => e.nom === categorie);

        axios
            .get(`${API_URL}/api/garantie/categorie/non-compulsory/${cat.categorieId}/${organisme?.code}`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                // console.log(res.data);
                setNcGuaranties(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const copyDatas = () => {
        let newFormData = [...formData];

        let l = formData.length;
        newFormData[l - 1].carosserie = newFormData[0].carosserie;

        newFormData[l - 1].categorie = newFormData[0].categorie;

        newFormData[l - 1].marque = newFormData[0].marque;

        newFormData[l - 1].puissance = newFormData[0].puissance;

        newFormData[l - 1].volume = newFormData[0].volume;

        newFormData[l - 1].puissanceType = newFormData[0].puissanceType;

        newFormData[l - 1].nombrePlace = newFormData[0].nombrePlace;

        newFormData[l - 1].energie = newFormData[0].energie;

        newFormData[l - 1].valeurDeclaree = newFormData[0].valeurDeclaree;

        newFormData[l - 1].valeurANeuf = newFormData[0].valeurANeuf;

        newFormData[l - 1].valeurVenale = newFormData[0].valeurVenale;

        newFormData[l - 1].dateMiseEnCirculation = newFormData[0].dateMiseEnCirculation;

        newFormData[l - 1].sousGaranties = newFormData[0].sousGaranties;

        newFormData[l - 1].garanties = newFormData[0].garanties;

        newFormData[l - 1].usage = newFormData[0].usage;

        newFormData[l - 1].moteur = newFormData[0].moteur;

        newFormData[l - 1].type = newFormData[0].type;

        newFormData[l - 1].genre = newFormData[0].genre;

        newFormData[l - 1].concessionnaire = newFormData[0].concessionnaire;
        newFormData[l - 1].intermediaire = newFormData[0].intermediaire;

        newFormData[l - 1].rc = newFormData[0].rc;

        newFormData[l - 1].personne = newFormData[0].personne;

        setFormData(newFormData);
    };

    useEffect(() => {
        axios
            .get(`${API_URL}/api/organisme/concessionnaire`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                let dt = [];
                res.data.forEach((org) => {
                    dt.push({
                        label: org.raisonSociale, value: JSON.stringify(org),
                    });
                });
                setConcessionnaires(dt);
            })
            .catch((err) => {
                console.log(err);
            });

        axios
            .get(`${API_URL}/api/agence/organisme/${LOGGED_USER?.profil?.organisme?.organismeId}`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                // console.log("Agences: ", res.data);
                setAgences(res.data);
                res.data && LOGGED_USER?.agence?.codeAgence && setSelectedAgence(LOGGED_USER?.agence?.codeAgence);
                LOGGED_USER?.agence?.codeAgence && getIntermediairesByAgency(LOGGED_USER?.agence?.codeAgence);
            })
            .catch((err) => {
                console.log(err);
            });

        axios
            .get(`${API_URL}/api/parametre/type/ACCESSOIRES`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                setAccessoires(res.data);
                let f = res.data.find(el => el.categorie.nom === "MOTO");
                // console.log("fff: ", f);
                setFraisAccessoires(parseInt(f.libelleParametre));
            })
            .catch((err) => {
                console.log(err);
            });

        axios
            .get(`${API_URL}/api/agence/courtiers`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                // console.log("Agreed courtiers: ", res.data);
                setCourtiers(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
        // setTimeout(() => {
        //   setConcessionnaires(dt);
        // }, 1000);
    }, [TOKEN]);

    const getIntermediairesByAgency = (codeAgence) => {
        axios
            .get(`${API_URL}/api/agence/intermediaires/code/${codeAgence}`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                console.log("intermédiaires: ", res.data);
                setIntermediaires(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const navigate = useNavigate();

    useEffect(() => {
        // Initialize the Stepper
        const stepperElement = document.querySelector(".wizard-modern-vertical-icons-example");
        if (stepperElement) {
            const stepper = new Stepper(stepperElement, {linear: false});

            const nextButtons = stepperElement.querySelectorAll(".btn-next");
            const prevButtons = stepperElement.querySelectorAll(".btn-prev");
            const submitButton = stepperElement.querySelector(".btn-submit");

            nextButtons.forEach((button) => {
                button.addEventListener("click", () => stepper.next());
            });

            prevButtons.forEach((button) => {
                button.addEventListener("click", () => stepper.previous());
            });

            submitButton?.addEventListener("click", () => {
                alert("Submitted..!!");
            });

            // Clean up event listeners on unmount
            return () => {
                nextButtons.forEach((button) => {
                    button.removeEventListener("click", () => stepper.next());
                });

                prevButtons.forEach((button) => {
                    button.removeEventListener("click", () => stepper.previous());
                });

                submitButton?.removeEventListener("click", () => {
                    alert("Submitted..!!");
                });
            };
        }
    }, []);

    useEffect(() => {
        axiosInstance.get(`/api/parametre/type/PROFESSION`)
            .then((res) => {
                setProfessions(res.data);
            })
            .catch((err) => {
                console.log(err);
            });

        axiosInstance.get(`/api/categorie`)
            .then((res) => {
                // console.log("eff: ", res.data);
                setCategories(res.data);
                setSelectedCategorie(res.data[0]);
            })
            .catch((err) => {
                console.log(err);
            });

        axiosInstance.get(`/api/type-piece`)
            .then((res) => {
                setTypePieces(res.data);
            })
            .catch((err) => {
                console.log(err);
            });

        axiosInstance.get(`/api/parametre/type/CAROSSERIE`)
            .then((res) => {
                setCarosseries(res.data);
            })
            .catch((err) => {
                console.log(err);
            });

        axiosInstance.get(`/api/parametre/type/MARQUE`)
            .then((res) => {
                let mqs = [];
                mqs = res.data.filter(el => el.categorie.nom.trim() === "MOTO");
                setMarques(mqs);
                setAllMarques(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
        axiosInstance.get(`/api/personne/client`)
            .then((res) => {
                // console.log(res);
                var dt = [];
                res.data.forEach((clt) => {
                    let nom = clt.prenom ? `${clt.nom} ${clt.prenom}` : `${clt.nom}`;
                    dt.push({
                        label: nom, value: JSON.stringify(clt), key: clt.codeId,
                    });
                });
                setClients(dt);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [TOKEN]);

    const someEffects = (categorieValue, codeId) => {
        var dt = [[]];
        dt[codeId] = [];
        let newFormValues = [...formData];

        const cat = categories.find((e) => e.nom === categorieValue);

        axiosInstance.get(`/api/garantie/categorie/compulsory/${cat.categorieId}`)
            .then((res) => {
                // console.log("Guaranties", res.data);
                res.data?.forEach((guaranty, index) => {
                    if (guaranty.nom.startsWith("Surprime")) {
                        if(cat.codeCategorie.startsWith("TPV-Taxi")) {
                          axiosInstance.get(`/api/sous-garantie/surprimes-obligatoires-tpv/${newFormValues[0].nombrePlace}/${cat.codeCategorie}`)
                              .then((response) => {
                                  console.log("'Ressss' s: " + response.data);
                                  dt[codeId].push({
                                      key: index + 1,
                                      nom: `${guaranty.nom} - ${guaranty.categorie.nom}`,
                                      capital: "-",
                                      primeNette: response.data.tauxPCent * (parseFloat(newFormValues[0].nombrePlace) - 1.0) * parseInt(newFormValues[0].rc) * 0.01,
                                  });
                              })
                              .catch((error) => {
                                  console.log(error);
                              });
                        } else {
                          if(newFormValues[0].nombrePlace >= 10) {
                            axiosInstance.get(`/api/sous-garantie/surprimes-obligatoires-tpv/${newFormValues[0].nombrePlace}/${cat.codeCategorie}`)
                                .then((response) => {
                                    console.log("'Ressss' s: " + response.data);
                                    dt[codeId].push({
                                        key: index + 1,
                                        nom: `${guaranty.nom} - ${guaranty.categorie.nom}`,
                                        capital: "-",
                                        primeNette: parseInt(newFormValues[0].nombrePlace) < 21 ? response.data.primeNette : response.data.primeNette * (parseFloat(newFormValues[0].nombrePlace) - 1.0),
                                    });
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                          }
                        }

                    } else {

                        axiosInstance.get(`/api/sous-garantie/capital/codeId/${guaranty.codeId}`)
                            .then((response) => {
                                // console.log("'Responsessss' response: " + response.data);
                                dt[codeId].push({
                                    key: index + 1,
                                    nom: `${guaranty.nom} - ${guaranty.categorie.nom}`,
                                    capital: guaranty.nom.toLowerCase().startsWith("rc") ? 4000000000 : guaranty.nom.toLowerCase().startsWith("vol") || guaranty.nom.toLowerCase().startsWith("inc") ? newFormValues[codeId].valeurVenale : response.data[0],
                                    primeNette: guaranty.nom.toLowerCase().startsWith("rc") ? newFormValues[codeId].rc : guaranty.nom.toLowerCase().startsWith("vol") || guaranty.nom.toLowerCase().startsWith("inc") ? parseFloat(newFormValues[codeId].valeurVenale) * response.data[2] / 100 : response.data[1],
                                });
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    }
                });

                setTimeout(() => {
                    // console.log("SubGuaranties", dt);
                    var dts = [];
                    dt.forEach((sg) => {
                        sg.forEach((g) => {
                            dts.push(g);
                        });
                    });
                    if (formData.length === 1) {
                        setSubGaranties(dts);
                    }
                    // console.log("DTS: ", dts);
                }, 1000);
            })
            .catch((err) => {
                console.log(err);
            });

        axiosInstance.get(`/api/garantie/categorie/non-compulsory/${cat.categorieId}/${organisme.code}`)
            .then((res) => {
                setNcGuaranties(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
        //
    };

    const handlePuissance = (value, codeId) => {
        var puissanceRC = value;

        const cat = categories.find((e) => e.nom === formData[codeId].categorie);

        axiosInstance.get(`/api/sous-garantie/rc/${puissanceRC}/${cat.categorieId}`)
            .then((resp) => {
                let newFormValues = [...formData];
                newFormValues[codeId]["rc"] = resp.data.primeNette;
                setFormData(newFormValues);
                someEffects(formData[codeId].categorie, codeId);
            })
            .catch((errp) => {
                console.log(errp);
            });
    };

    const getReductions = (categorie) => {
        const cat = categories.find((e) => e.nom === categorie);

        axiosInstance.get(`/api/reduction/categorie/${cat.categorieId}`)
            .then((res) => {
                setReductions(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getFractionnements = (categorie) => {
        const cat = categories.find((e) => e.nom === categorie);

        axiosInstance.get(`/api/fractionnement/categorie/${cat.categorieId}`)
            .then((res) => {
                setFractionnements(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleSousGarantie = (index, event) => {
        let newFormValues = [...formData];

        // console.log("EVENT: ", event.target.value);
        var gtys = [];
        event.forEach((e) => {
            let g = ncGuaranties.find((el) => el.codeId === e);
            gtys.push(g);
        });
        // gtys.push(JSON.parse(event.target.value));

        // console.log("GTY: ", gtys);
        axiosInstance.post(`/api/sous-garantie/garanties`, JSON.stringify(gtys))
            .then((ress) => {
                newFormValues[index]["sousGaranties"] = ress.data;
                setFormData(newFormValues);
            })
            .catch((errr) => {
                console.log(errr);
            });
    };

    const prop = (index) => {
        return {
            beforeUpload: (file) => {
                if (file.type !== "application/pdf") {
                    message.error("Vous ne pouvez uploader que des fichiers PDF!");
                    return false;
                } else {
                    var cg = [];
                    cg.push(file);
                    setCarteGrise(cg);
                    uploadGrayCard(file, index);
                    return false;
                }
            }, showUploadList: true, maxCount: 1, data: carteGrise, onChange(info) {
                if (info.file.status === "done") {
                    message.success(`${info.file.name} carte grise uploadée avec succès`);
                } else if (info.file.status === "error") {
                    message.error(`${info.file.name} carte grise non uploadée!`);
                }
            },
        };
    };

    const uploadGrayCard = async (e, index) => {
        const formData = new FormData();

        let extension = e.name.substring(e.name.lastIndexOf(".") + 1, e.name.length);

        let d = new Date();
        let filename = `carteGrise_${d?.toISOString()?.split(" ").join("-").split(":").join("-").split(".")[0]}.${extension}`;
        formData.append(`fichier`, e, filename);

        axiosMultiPartDataInstance.post(`/api/engin/carte-grise`, formData)
            .then((response) => {
                handleChange(index, "carteGrise", response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleSubmit = (e) => {
        setLoading(true);
        e.preventDefault();
        personneData.typePersonne = typePersonne;
        personneData.exonereDeTaxe = exonereDeTaxe;
        if (exonereDeTaxe) {
            updateTaxes();
        }
        if (insuredData?.nom) {
            insuredData.typePersonne = "PHYSIQUE";
        }
        let engins = [];
        const cat = categories.find((e) => e.nom === formData[0].categorie);
        formData.forEach((fd, id) => {
            fd.categorie = cat;
            fd.concessionnaire = JSON.parse(fd.concessionnaire);
            // fd.personne = personneData;
            // fd.marque = fd.marque;
            engins.push(fd);
        });
        let interm = null;
        if (selectedIntermediaire) interm = intermediaires.find(el => el.codeIntermediaire === selectedIntermediaire);

        let contratData = {
            engins: engins,
            souscripteur: personneData,
            assure: insuredData?.nom ? insuredData : null,
            fractionnement: null,
            taxes: taxe,
            accessoires: fraisAccessoires,
            primeNette: totalGarantiesO * formData.length + totalGarantiesP,
            dateEffet: dateEffet?.toISOString(),
            dateEcheance: cat?.codeCategorie?.startsWith("VT") ? dateEffet.add(15, 'day').endOf('day').toISOString() : null,
            intermediaire: interm,
            courtier: agencyProduction ? agencyProduction : null,
            tauxCommission: tauxCommission
        };

        if (fractionnementSelect) {
            let fract = fractionnements.find(e => e.fractionnementId === parseInt(fractionnementSelect));
            contratData.fractionnement = fract;
            contratData.primeTotale = Math.trunc(tauxReduction ? cent ? ((totalGarantiesO * formData.length + totalGarantiesP + fraisAccessoires) * (1 - tauxReduction / 100) * 1.06 * tauxFractionnementSelect?.taux) / 100 : ((totalGarantiesO * formData.length + totalGarantiesP + fraisAccessoires) * (1 - tauxReduction / 1000) * 1.06 * tauxFractionnementSelect?.taux) / 100 : ((totalGarantiesO * formData.length + totalGarantiesP + fraisAccessoires) * 1.06 * tauxFractionnementSelect?.taux) / 100)
        }

        if (LOGGED_USER?.agence?.typeAgence === "COURTIER") {
            const scAg = LOGGED_USER.agence.validAgrements.find((ag) => ag.compagnie.organismeId === parseInt(selectedCompany));
            contratData.organisme = scAg.compagnie;
            contratData.courtier = LOGGED_USER.agence;
        }

        if (oldInsured) {
            contratData.oldSouscripteur = oldClient;
            axiosInstance.post(`/api/contrat/new/old-client`, JSON.stringify(contratData))
                .then((res) => {
                    setLoading(false);
                    navigate(`/details-contrat/${res.data.codeId}`, {replace: true});
                    toast("Contrat ajoutée avec succès");
                })
                .catch((err) => {
                    console.log(err);
                    toast("Erreur lors de l'ajout du contrat");
                    setLoading(false);
                });
        } else {
            axiosInstance.post(`/api/contrat/new`, JSON.stringify(contratData))
                .then((res) => {
                    setLoading(false);
                    navigate(`/details-contrat/${res.data.codeId}`, {replace: true});
                    toast("Contrat ajoutée avec succès");
                })
                .catch((err) => {
                    console.log(err);
                    toast("Erreur lors de l'ajout du contrat");
                    setLoading(false);
                });
        }

    };

    const updatePrimes = () => {
        var totalSGO = 0;
        var dt = [];
        var pn = 0.0;
        formData?.forEach((engin, id) => {
            engin.sousGaranties.forEach((eng) => {
                eng && dt.push({
                    risk: `${eng.garantie.nom}`,
                    taux: eng.tauxPCent ? eng.tauxPCent : eng.tauxPMille ? eng.tauxPMille : 0.0,
                    tauxP: eng.tauxPCent ? "cent" : eng.tauxPMille ? "mille" : "",
                    valeurDeclaree: engin.valeurDeclaree,
                    primeNette: eng.primeNette ? eng.primeNette : eng.tauxPCent ? (eng.tauxPCent * engin.valeurDeclaree) / 100 : eng.tauxPMille ? (eng.tauxPMille * engin.valeurDeclaree) / 1000 : 0.0,
                });
                eng && (eng.nom.startsWith("Inc") || eng.nom.startsWith("Vol")) && (pn += eng.tauxPCent ? (eng.tauxPCent * engin.valeurDeclaree) / 100 : eng.tauxPMille ? (eng.tauxPMille * engin.valeurDeclaree) / 1000 : 0.0);

                eng && !(eng.nom.startsWith("Inc") || eng.nom.startsWith("Vol")) && (pn += eng.primeNette ? eng.primeNette : eng.tauxPCent ? (eng.tauxPCent * engin.valeurDeclaree) / 100 : eng.tauxPMille ? (eng.tauxPMille * engin.valeurDeclaree) / 1000 : 0.0);
            });
        });
        setTotalGarantiesP(pn);
        setDetailsGarantiesP(dt);
        subGaranties.forEach((gar) => {
            totalSGO += parseFloat(gar.primeNette);
        });
        setTotalGarantiesO(totalSGO);
        setTimeout(() => {
            updateTaxes();
        }, 500);
    };

    const updateTaxes = () => {
        setTaxe(tauxReduction ? cent ? (totalGarantiesO * formData.length + totalGarantiesP + fraisAccessoires) * (1 - tauxReduction / 100) * 0.06 : (totalGarantiesO * formData.length + totalGarantiesP + fraisAccessoires) * (1 - tauxReduction / 1000) * 0.06 : (totalGarantiesO * formData.length + totalGarantiesP + fraisAccessoires) * 0.06);
    };

    const calculateFractionnement = (fractionnementId) => {
      if(fractionnementId) {
        axiosInstance.get(`/api/taux-fractionnement/fractionnement/${fractionnementId}/rang/1`)
            .then((res) => {
                setTauxFractionnementSelect(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
      } else {
        setTauxFractionnementSelect("");
      }

    }

    const MyFooter = () => {
        var details = "";
        detailsGarantiesP?.forEach((dgp) => {
            details += `${dgp.risk} - ${dgp.primeNette}; `;
        });

        setTaxe(tauxReduction ? cent ? (totalGarantiesO * formData.length + totalGarantiesP + fraisAccessoires) * (1 - tauxReduction / 100) * 0.06 : (totalGarantiesO * formData.length + totalGarantiesP + fraisAccessoires) * (1 - tauxReduction / 1000) * 0.06 : (totalGarantiesO * formData.length + totalGarantiesP + fraisAccessoires) * 0.06);

        return (<>
                <div>
                    <b>Total garanties obligatoires</b>:{" "}
                    {new Intl.NumberFormat("en-DE").format(totalGarantiesO)} F CFA x{" "}
                    {formData.length} , soit{" "}
                    {new Intl.NumberFormat("en-DE").format(totalGarantiesO * formData.length)}{" "}
                    F CFA
                </div>
                <div>
                    <b>Total autres garanties</b>:{" "}
                    {new Intl.NumberFormat("en-DE").format(totalGarantiesP)} F CFA (
                    {details})
                </div>
                {tauxReduction ? (<div>
                        <b>Réduction</b> : {tauxReduction}
                        {cent ? "%" : "‰"}
                    </div>) : null}

                <div>
                    <b>Frais Accessoires</b> :{" "}
                    {new Intl.NumberFormat("en-DE").format(fraisAccessoires)} F CFA
                </div>
                <div>
                    <b>Taxe(6%) </b> : {new Intl.NumberFormat("en-DE").format(taxe)}
                </div>
                {tauxFractionnementSelect && fractionnementSelect.nombre !== 1 ? (<div>
                        <b>Fractionnement</b> : {tauxFractionnementSelect?.taux} %
                    </div>) : null}
                <div>
                    <b>Total TTC</b> :{" "}
                    {new Intl.NumberFormat("en-DE").format(Math.trunc(tauxReduction ? cent ? (totalGarantiesO * formData.length + totalGarantiesP + fraisAccessoires) * (1 - tauxReduction / 100) * 1 : (totalGarantiesO * formData.length + totalGarantiesP + fraisAccessoires) * (1 - tauxReduction / 1000) * 1 : (totalGarantiesO * formData?.length + totalGarantiesP + fraisAccessoires) * 1) + taxe)}{" "}
                    F CFA
                </div>
                {tauxFractionnementSelect ? (<div>
                        <b>Montant à payer après fractionnement</b> :{" "}
                        {new Intl.NumberFormat("en-DE").format(Math.trunc(tauxReduction ? cent ? ((totalGarantiesO * formData.length + totalGarantiesP + fraisAccessoires) * (1 - tauxReduction / 100) * 1.06 * tauxFractionnementSelect?.taux) / 100 : ((totalGarantiesO * formData.length + totalGarantiesP + fraisAccessoires) * (1 - tauxReduction / 1000) * 1.06 * tauxFractionnementSelect?.taux) / 100 : ((totalGarantiesO * formData.length + totalGarantiesP + fraisAccessoires) * 1.06 * tauxFractionnementSelect?.taux) / 100))}{" "}
                        F CFA
                    </div>) : null}
            </>);
    };

    const getDateEcheance = (date, dateString) => {
        setDateEffet(date);
        setDateEcheance(date?.add(12, "M"));
        // sessionStorage.setItem("effet", date.toISOString());
    };

    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < dayjs().add(-1, "day");
    };

    const disabledCirculationDate = (current) => {
        // Can not select years after this year
        return current && current.year() > dayjs().year();
    };

    const getFraisAccessoires = (category) => {
        let fa = accessoires.find(el => el.categorie.nom === category);
        fa?.libelleParametre && setFraisAccessoires(parseInt(fa?.libelleParametre));
    }

    const validateForm = () => {
        const newErrors = [];

        const valeurVenaleMinimale = selectedCategorie?.valeurVenaleMinimale;

        formData.forEach((fd, index) => {
            newErrors.push({});
            if (fd.typeSerie === "TG" && !selectedCategorie?.codeCategorie?.startsWith("VT")) {
                if (!fd.numeroSerie) newErrors[index].numeroSerie = "Le numéro de série est requis";
                if (fd.numeroSerie?.length !== 2) newErrors[index].numeroSerie = "Le numéro de série doit avoir 2 caractères";
                if (!fd.matricule) newErrors[index].matricule = "Le numéro d'immatriculation est requis";
                if (fd.matricule?.length !== 4) newErrors[index].matricule = "Le numéro d'immatriculation doit avoir 4 caractères";
            } else {
                if (!fd.matricule) newErrors[index].matricule = "Le numéro d'immatriculation est requis";
                if (fd.matricule?.length < 6) newErrors[index].matricule = "Le numéro d'immatriculation doit avoir 6 caractères au minimum";
            }

            if (existImmatriculation[index]) newErrors[index].matricule = "Un engin est déjà enregistré avec cette immatriculation";
            if (!fd.marque) newErrors[index].marque = "La marque est requise";
            if (!fd.nombrePlace && !selectedCategorie?.codeCategorie?.startsWith("VT")) newErrors[index].nombrePlace = "Le nombre de places est requis";

            if (!fd.carosserie && !selectedCategorie?.codeCategorie?.startsWith("VT") && !selectedCategorie?.codeCategorie?.startsWith("TPV")) newErrors[index].carosserie = "La carosserie est requise";
            if (!fd.moteur && !selectedCategorie?.codeCategorie?.startsWith("VT")) newErrors[index].moteur = "Le moteur est requis";
            if (!fd.genre && !selectedCategorie?.codeCategorie?.startsWith("VT") && !selectedCategorie?.codeCategorie?.startsWith("TPV")) newErrors[index].genre = "Le genre du moteur est requis";
            if (!fd.type && !selectedCategorie?.codeCategorie?.startsWith("VT")) newErrors[index].type = "Le type de moteur est requis";
            if (!fd.dateMiseEnCirculation && !selectedCategorie?.codeCategorie?.startsWith("VT")) newErrors[index].dateMiseEnCirculation = "La date de mise en circulation est requise";
            if (!fd.numeroChassis) newErrors[index].numeroChassis = "Le numéro de châssis est requis";
            if (fd.numeroChassis?.length > 17) newErrors[index].numeroChassis = "Le numéro de châssis doit comporter 17 caractères au maximum";
            if (existChassis[index]) newErrors[index].numeroChassis = "Un moteur est déjà enregistré avec ce numéro de châssis!";
            if (parseInt(fd.valeurANeuf) < valeurVenaleMinimale) {
                newErrors[index].valeurANeuf = `La valeur à neuf doit être supérieure à ${valeurVenaleMinimale}`;
            }
            if (parseInt(fd.valeurDeclaree) < valeurVenaleMinimale) {
                newErrors[index].valeurDeclaree = `La valeur déclarée doit être supérieure à ${valeurVenaleMinimale}`;
            }
            if (parseInt(fd.valeurVenale) < valeurVenaleMinimale) {
                newErrors[index].valeurVenale = `La valeur vénale doit être supérieure à ${valeurVenaleMinimale}`;
            }
        });

        // Add more validation rules if needed
        setErrors(newErrors);

        let valid = true;
        newErrors.forEach((ne) => {
            if (Object.keys(ne).length !== 0) {
                valid = false;
            }
        });

        return valid;
    };

    const validateSouscripteurForm = () => {
        const newSfErrors = {};
        const phonePattern = /^(22|90|91|92|93|96|98|99|70|71|79)\d{6}$/;
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const cniPattern = /^\d{0,4}-?\d{0,3}-?\d{0,4}$/;
        const passportPattern = /^[A-Za-z]{0,2}\d*$/;

        if (!personneData.nom) newSfErrors.nom = "Le nom du souscripteur est requis!";
        if (personneData.nom.trim().length < 2) newSfErrors.nom = "Le nom du souscripteur est trop court!";
        if (typePersonne === "PHYSIQUE" && !personneData.prenom) newSfErrors.prenom = "Le prénom du souscripteur est requis!";
        if (typePersonne === "PHYSIQUE" && personneData.prenom.trim().length < 2) newSfErrors.prenom = "Le prénom du souscripteur est trop court!";
        if (typePersonne === "PHYSIQUE" && !personneData.genre) newSfErrors.genre = "Le genre du souscripteur est requis!";
        if (typePersonne === "MORALE" && !personneData.nif) newSfErrors.nif = "Le NIF est requis!";
        if (typePersonne === "MORALE" && personneData.nif.trim().length < 5) newSfErrors.nif = "Le NIF est invalide!";
        if (typePersonne === "PHYSIQUE" && !personneData.dateNaissance) newSfErrors.dateNaissance = "Le date de naissance du souscripteur est requise!";
        if (typePersonne === "PHYSIQUE" && !personneData.typePiece) newSfErrors.typePiece = "Le type de pièce du souscripteur est requis!";
        if (typePersonne === "PHYSIQUE" && !personneData.numIdentite) newSfErrors.numIdentite = "Le numéro de pièce d'identité du souscripteur est requis!";
        if (typePersonne === "PHYSIQUE" && !personneData.dateDelivrance) newSfErrors.dateDelivrance = "La date de délivrance de pièce du souscripteur est requise!";
        if (!personneData.contact) newSfErrors.contact = "Le numéro de téléphone du souscripteur est requis!";
        if (!phonePattern.test(personneData.contact)) newSfErrors.contact = "Numéro de téléphone invalide";
        if (personneData.email && !emailPattern.test(personneData.email)) newSfErrors.email = "L'adresse email n'est pas valide.";
        if (personneData.typePiece === "CNI" && !cniPattern.test(personneData.numIdentite)) newSfErrors.numIdentite = "Le format du numéro de la CNI est invalide";
        if (personneData.typePiece === "PASSEPORT" && !passportPattern.test(personneData.numIdentite)) newSfErrors.numIdentite = "Le format du numéro du passeport est invalide";

        setSfErrors(newSfErrors);
        return Object.keys(newSfErrors).length === 0;
    };

    const validateInsuredForm = (insuredPersonne) => {
        const newSfErrors = {};
        const phonePattern = /^(22|90|91|92|93|96|98|99|70|71|79)\d{6}$/;
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const cniPattern = /^\d{0,4}-?\d{0,3}-?\d{0,4}$/;
        const passportPattern = /^[A-Za-z]{0,2}\d*$/;

        if (!insuredPersonne.nom) newSfErrors.nom = "Le nom du souscripteur est requis!";
        if (insuredPersonne.nom.trim().length < 2) newSfErrors.nom = "Le nom du souscripteur est trop court!";
        if (!insuredPersonne.prenom) newSfErrors.prenom = "Le prénom du souscripteur est requis!";
        if (insuredPersonne.prenom.trim().length < 2) newSfErrors.prenom = "Le prénom du souscripteur est trop court!";
        if (!insuredPersonne.genre) newSfErrors.genre = "Le genre du souscripteur est requis!";
        // if (!insuredPersonne.dateNaissance)
        //   newSfErrors.dateNaissance =
        //     "Le date de naissance du souscripteur est requise!";
        if (!insuredPersonne.typePiece) newSfErrors.typePiece = "Le type de pièce du souscripteur est requis!";
        if (!insuredPersonne.numIdentite) newSfErrors.numIdentite = "Le numéro de pièce d'identité du souscripteur est requis!";
        if (!insuredPersonne.dateDelivrance) newSfErrors.dateDelivrance = "La date de délivrance de pièce du souscripteur est requise!";
        if (!insuredPersonne.contact) newSfErrors.contact = "Le numéro de téléphone du souscripteur est requis!";
        if (!phonePattern.test(insuredPersonne.telephone)) newSfErrors.telephone = "Numéro de téléphone invalide";
        if (insuredPersonne.email && !emailPattern.test(insuredPersonne.email)) newSfErrors.email = "L'adresse email n'est pas valide.";
        if (insuredPersonne.typePiece === "CNI" && !cniPattern.test(insuredPersonne.numIdentite)) newSfErrors.numIdentite = "Le format du numéro de la CNI est invalide";
        if (insuredPersonne.typePiece === "PASSEPORT" && !passportPattern.test(insuredPersonne.numIdentite)) newSfErrors.numIdentite = "Le format du numéro du passeport est invalide";

        setIfErrors(newSfErrors);
        return Object.keys(newSfErrors).length === 0;
    };

    const validateDifferentInsuredForm = (insuredPersonne) => {
        const newSfErrors = {};
        const phonePattern = /^(22|90|91|92|93|96|98|99|70|71|79)\d{6}$/;
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const cniPattern = /^\d{0,4}-?\d{0,3}-?\d{0,4}$/;
        const passportPattern = /^[A-Za-z]{0,2}\d*$/;

        if (!insuredPersonne.nom) newSfErrors.nom = "Le nom du souscripteur est requis!";
        if (insuredPersonne.nom.trim().length < 2) newSfErrors.nom = "Le nom du souscripteur est trop court!";
        if (!insuredPersonne.prenom) newSfErrors.prenom = "Le prénom du souscripteur est requis!";
        if (insuredPersonne.prenom.trim().length < 2) newSfErrors.prenom = "Le prénom du souscripteur est trop court!";
        if (!insuredPersonne.genre) newSfErrors.genre = "Le genre du souscripteur est requis!";
        // if (!insuredPersonne.dateNaissance)
        //   newSfErrors.dateNaissance =
        //     "Le date de naissance du souscripteur est requise!";
        if (!insuredPersonne.typePiece) newSfErrors.typePiece = "Le type de pièce du souscripteur est requis!";
        if (!insuredPersonne.numIdentite) newSfErrors.numIdentite = "Le numéro de pièce d'identité du souscripteur est requis!";
        if (!insuredPersonne.dateDelivrance) newSfErrors.dateDelivrance = "La date de délivrance de pièce du souscripteur est requise!";
        if (!insuredPersonne.contact) newSfErrors.contact = "Le numéro de téléphone du souscripteur est requis!";
        if (!phonePattern.test(insuredPersonne.telephone)) newSfErrors.telephone = "Numéro de téléphone invalide";
        if (insuredPersonne.email && !emailPattern.test(insuredPersonne.email)) newSfErrors.email = "L'adresse email n'est pas valide.";
        if (insuredPersonne.typePiece === "CNI" && !cniPattern.test(insuredPersonne.numIdentite)) newSfErrors.numIdentite = "Le format du numéro de la CNI est invalide";
        if (insuredPersonne.typePiece === "PASSEPORT" && !passportPattern.test(insuredPersonne.numIdentite)) newSfErrors.numIdentite = "Le format du numéro du passeport est invalide";

        setInsuredFErrors(newSfErrors);
        return Object.keys(newSfErrors).length === 0;
    };

    const verifyChassisNumber = (numChassis, index) => {
        axios
            .get(`${API_URL}/api/engin/chassis/${numChassis}`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "multipart/form-data",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                let ec = [...existChassis];
                ec[index] = res.data;
                if (res.data) {
                    toast("Un moteur est déjà enregistré avec ce numéro de châssis");
                }
                setExistChassis(ec);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const verifyImmatriculation = (immatriculation, index) => {
        axios
            .get(`${API_URL}/api/engin/immatriculation/${immatriculation}`, {
                headers: {
                    Authorization: `Bearer ${TOKEN}`,
                    "Content-Type": "multipart/form-data",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
                    "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
            })
            .then((res) => {
                let ei = [...existImmatriculation];
                ei[index] = res.data;
                if (res.data) {
                    toast("Immatriculation déjà existante!");
                }
                setExistImmatriculation(ei);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getSimpleAgencies = () => {
        let agencies = [];
        agencies = agences.filter((a) => a.typeAgence !== "COURTIER");
        return agencies;
    }

    const onSubmit = (e) => {
        e.preventDefault();
    };

    return (<div class="layout-wrapper layout-content-navbar  ">
            <div class="layout-container">
                <SideBar currentMenu={"PRODUCTION"}/>

                {/* <!-- Layout container --> */}
                <div class="layout-page">
                    <NavBar/>

                    {/* <!-- Content wrapper --> */}
                    <div class="content-wrapper">
                        {/* <!-- Content --> */}
                        <div class="container-xxl flex-grow-1 container-p-y">
                            <div class="row">
                                <div className="col-12 mb-4">
                                    <small className="text-light fw-medium">
                                        Ajouter un contrat
                                    </small>
                                    <div
                                        className="bs-stepper vertical wizard-modern wizard-modern-vertical wizard-modern-vertical-icons-example mt-2">
                                        <div className="bs-stepper-header">
                                            <div
                                                className={activeTab === 1 ? "step active" : "step"}
                                                data-target="#personal-info-vertical-modern"
                                            >
                                                <button
                                                    type="button"
                                                    className="step-trigger"
                                                    onClick={() => setActiveTab(1)}
                                                >
                          <span className="bs-stepper-circle">
                            <i className="bx bx-car"></i>
                          </span>
                                                    <span className="bs-stepper-label">
                            <span className="bs-stepper-title">
                              Informations sur l'engin
                            </span>
                            <span className="bs-stepper-subtitle">
                              Ajouter les infos sur l'engin
                            </span>
                          </span>
                                                </button>
                                            </div>
                                            <div className="line"></div>
                                            <div
                                                className={activeTab === 2 ? "step active" : "step"}
                                                data-target="#account-details-vertical-modern"
                                                onClick={(e)=> {e.preventDefault()}}
                                            >
                                                <button
                                                    type="button"
                                                    className="step-trigger"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        validateForm() && setActiveTab(2);
                                                    }}
                                                >
                          <span className="bs-stepper-circle">
                            <i className="bx bx-detail"></i>
                          </span>
                                                    <span className="bs-stepper-label">
                            <span className="bs-stepper-title">
                              Détails des garanties
                            </span>
                            <span className="bs-stepper-subtitle">
                              Configurer les détails des garanties
                            </span>
                          </span>
                                                </button>
                                            </div>
                                            <div className="line"></div>
                                            <div
                                                className={activeTab === 3 ? "step active" : "step"}
                                                data-target="#social-links-vertical-modern"
                                                onClick={(e)=> {e.preventDefault()}}
                                            >
                                                <button
                                                    type="button"
                                                    className="step-trigger"
                                                    onClick={(e) => {
                                                    e.preventDefault()
                                                        validateForm() && setActiveTab(3)
                                                    }}
                                                >
                          <span className="bs-stepper-circle">
                            <i className="bx bx-user"></i>
                          </span>
                                                    <span className="bs-stepper-label">
                            <span className="bs-stepper-title">
                              Infos sur le souscripteur
                            </span>
                            <span className="bs-stepper-subtitle">
                              Renseigner les infos sur le souscripteur
                            </span>
                          </span>
                                                </button>
                                            </div>
                                            <div className="line"></div>
                                            <Space direction="vertical">
                                                <Switch
                                                    checkedChildren="Assuré existant"
                                                    unCheckedChildren="Nouvel assuré"
                                                    value={oldInsured}
                                                    onChange={(e) => {
                                                        setOldInsured(e);
                                                    }}
                                                />
                                            </Space>
                                        </div>
                                        <div className="bs-stepper-content">
                                            <form onSubmit={(e) => e.preventDefault()}>
                                                {/* Personal Info */}
                                                <div
                                                    id="personal-info-vertical-modern"
                                                    className={activeTab === 1 ? "content active" : "content"}
                                                >
                                                    <div className="content-header mb-4">
                                                        <h6 className="mb-0">Informations sur l'engin</h6>
                                                        <small>
                                                            Veuillez renseigner les informations sur l'engin
                                                        </small>
                                                    </div>
                                                    <div className="row g-6">
                                                        <div className="col-sm-12">
                                                            <label class="form-label" for="categorieId">
                                                                Catégorie *
                                                            </label>
                                                            <select
                                                                id="categorie"
                                                                name="categorie"
                                                                class="form-control w-100"
                                                                data-style="btn-default"
                                                                value={formData[0].categorie}
                                                                onChange={(e) => {
                                                                    handleChange(e, "categorie", 0);
                                                                    setCategorie(e.target.value);
                                                                    const cat = categories.find((el) => el.nom === e.target.value);
                                                                    setSelectedCategorie(cat);
                                                                    let mqs = [];
                                                                    mqs = allMarques.filter(el => el.categorie.nom.trim().startsWith(e.target.value.trim().slice(0, 4)));
                                                                    setMarques(mqs);
                                                                    handlePuissance(formData[0].puissance, 0);
                                                                    getReductions(e.target.value);
                                                                    getFractionnements(e.target.value);
                                                                    nonCompulsoryGuaranties(e.target.value);
                                                                    getFraisAccessoires(e.target.value);
                                                                }}
                                                            >
                                                                {categories?.map((cat, id) => {
                                                                    if (cat.codeCategorie.startsWith("VT") && rightsService("HCO021")) {
                                                                        return (<option key={id} value={cat.nom}>
                                                                                {cat.nom}
                                                                            </option>)
                                                                    } else if (cat.codeCategorie.startsWith("M") && rightsService("HCO019")) {
                                                                        return (<option key={id} value={cat.nom}>
                                                                                {cat.nom}
                                                                            </option>)
                                                                    } else if (cat.codeCategorie.startsWith("TPV") && rightsService("HCO020")) {
                                                                        return (<option key={id} value={cat.nom}>
                                                                                {cat.nom}
                                                                            </option>)
                                                                    }
                                                                })}
                                                            </select>
                                                        </div>
                                                        {formData.length === 1 ? (<>
                                                                {selectedCategorie?.codeCategorie?.startsWith("VT") ?
                                                                    <div className="col-sm-6">
                                                                        <label
                                                                            className="form-label"
                                                                            htmlFor="last-name-modern-vertical"
                                                                        >
                                                                            Immatriculation *
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            aria-label="Immatriculation"
                                                                            placeholder="Immatriculation"
                                                                            class={`form-control ${errors[0]?.matricule ? "is-invalid" : ""}`}
                                                                            id="matricule"
                                                                            name="matricule"
                                                                            value={formData[0].matricule}
                                                                            onChange={(e) => handleChange(e, "matricule", 0)}
                                                                        />
                                                                        {errors[0]?.matricule && (
                                                                            <div className="invalid-feedback">
                                                                                {errors[0]?.matricule}
                                                                            </div>)}
                                                                    </div> : formData[0].typeSerie !== "TG" ?
                                                                        <div className="col-sm-6">
                                                                            <label
                                                                                className="form-label"
                                                                                htmlFor="last-name-modern-vertical"
                                                                            >
                                                                                Immatriculation *
                                                                            </label>
                                                                            <input
                                                                                type="text"
                                                                                aria-label="Immatriculation"
                                                                                placeholder="Immatriculation"
                                                                                class={`form-control ${errors[0]?.matricule ? "is-invalid" : ""}`}
                                                                                id="matricule"
                                                                                name="matricule"
                                                                                value={formData[0].matricule}
                                                                                onChange={(e) => handleChange(e, "matricule", 0)}
                                                                            />
                                                                            {errors[0]?.matricule && (
                                                                                <div className="invalid-feedback">
                                                                                    {errors[0]?.matricule}
                                                                                </div>)}
                                                                        </div> : <div className="col-sm-6">
                                                                            <label
                                                                                className="form-label"
                                                                                htmlFor="last-name-modern-vertical"
                                                                            >
                                                                                Immatriculation *
                                                                            </label>
                                                                            <div class="input-group">
                                                                                <select
                                                                                    id="typeSerie"
                                                                                    name="typeSerie"
                                                                                    class={`form-control ${errors[0]?.typeSerie ? "is-invalid" : ""}`}
                                                                                    data-style="btn-default"
                                                                                    value={formData[0].typeSerie}
                                                                                    onChange={(e) => handleChange(e, "typeSerie", 0)}
                                                                                >
                                                                                    <option value="TG">TG</option>
                                                                                    <option value="Autre">Autre</option>
                                                                                </select>
                                                                                {errors[0]?.typeSerie && (
                                                                                    <div className="invalid-feedback">
                                                                                        {errors[0]?.typeSerie}
                                                                                    </div>)}
                                                                                <input
                                                                                    type="text"
                                                                                    aria-label="Immatriculation"
                                                                                    placeholder="Immatriculation"
                                                                                    class={`form-control ${errors[0]?.matricule ? "is-invalid" : ""}`}
                                                                                    id="matricule"
                                                                                    name="matricule"
                                                                                    value={formData[0].matricule}
                                                                                    onChange={(e) => handleChange(e, "matricule", 0)}
                                                                                />
                                                                                {errors[0]?.matricule && (
                                                                                    <div className="invalid-feedback">
                                                                                        {errors[0]?.matricule}
                                                                                    </div>)}
                                                                                <input
                                                                                    type="text"
                                                                                    aria-label="N° série"
                                                                                    placeholder="N° série"
                                                                                    class={`form-control ${errors[0]?.numeroSerie ? "is-invalid" : ""}`}
                                                                                    name="numeroSerie"
                                                                                    id="numeroSerie"
                                                                                    value={formData[0].numeroSerie}
                                                                                    onChange={(e) => handleChange(e, "numeroSerie", 0)}
                                                                                />
                                                                                {errors[0]?.numeroSerie && (
                                                                                    <div className="invalid-feedback">
                                                                                        {errors[0]?.numeroSerie}
                                                                                    </div>)}
                                                                            </div>
                                                                        </div>}
                                                                <div className="col-sm-6">
                                                                    <label
                                                                        className="form-label"
                                                                        htmlFor="marque"
                                                                    >
                                                                        Marque *
                                                                    </label>
                                                                    {rightsService("HPA060") ? <Select
                                                                        mode="tags"
                                                                        style={{
                                                                            width: '100%',
                                                                        }}
                                                                        maxCount={1}
                                                                        placeholder="Sélectionner la marque"
                                                                        onChange={(e) => handleChange(e[0], "marque", 0)}
                                                                        options={marques?.map((mar, id) => ({
                                                                            label: mar.libelleParametre,
                                                                            value: mar.libelleParametre,
                                                                            key: id
                                                                        }))}
                                                                    /> : <select
                                                                        id="marque"
                                                                        name="marque"
                                                                        value={formData[0].marque}
                                                                        onChange={(e) => {
                                                                            handleChange(e.target.value, "marque", 0);
                                                                            selectedCategorie?.codeCategorie && selectedCategorie?.codeCategorie?.startsWith("VT") && handlePuissance(1, 0);
                                                                        }}
                                                                        class={`form-control w-100 ${errors[0]?.marque ? "is-invalid" : ""}`}
                                                                        data-style="btn-default"
                                                                    >
                                                                        <option value="">
                                                                            Sélectionner la marque
                                                                        </option>
                                                                        {marques?.map((mar, id) => (<option
                                                                                key={id}
                                                                                value={mar.libelleParametre}
                                                                            >
                                                                                {mar.libelleParametre}
                                                                            </option>))}
                                                                    </select>}
                                                                    {errors[0]?.marque && (
                                                                        <div className="invalid-feedback">
                                                                            {errors[0]?.marque}
                                                                        </div>)}
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <label class="form-label" for="energieId">
                                                                        Energie *
                                                                    </label>
                                                                    <select
                                                                        class="form-control w-100"
                                                                        data-style="btn-default"
                                                                        id="energie"
                                                                        name="energie"
                                                                        value={formData[0].energie}
                                                                        onChange={(e) => handleChange(e, "energie", 0)}
                                                                    >
                                                                        <option value="ESSENCE">ESSENCE</option>
                                                                        <option value="DIESEL">DIESEL</option>
                                                                        <option value="ELECTRIQUE">
                                                                            ELECTRIQUE
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                                {categorie !== "MOTO" ? (<div className="col-sm-6">
                                                                        <label
                                                                            className="form-label"
                                                                            htmlFor="puissanceId"
                                                                        >
                                                                            Puissance (en
                                                                            CV) {selectedCategorie?.codeCategorie && selectedCategorie?.codeCategorie?.startsWith("VT") ? "(Facultatif)" : " *"}
                                                                        </label>
                                                                        <input
                                                                            type="number"
                                                                            id="puissance"
                                                                            name="puissance"
                                                                            value={formData[0].puissance}
                                                                            onChange={(e) => {
                                                                                handleChange(e, "puissance", 0);
                                                                                handlePuissance(e.target.value, 0);
                                                                            }}
                                                                            className="form-control"
                                                                            placeholder="Puissance"
                                                                        />
                                                                    </div>) : (<div className="col-sm-6">
                                                                        <label
                                                                            className="form-label"
                                                                            htmlFor="volumeId"
                                                                        >
                                                                            Volume (en cm^3) *
                                                                        </label>
                                                                        <input
                                                                            type="number"
                                                                            id="volume"
                                                                            name="volume"
                                                                            value={formData[0].volume}
                                                                            onChange={(e) => {
                                                                                handleChange(e, "volume", 0);
                                                                                handlePuissance(e.target.value, 0);
                                                                            }}
                                                                            className="form-control"
                                                                            placeholder="Volume"
                                                                        />
                                                                    </div>)}

                                                                <div className="col-sm-6">
                                                                    <label
                                                                        className="form-label"
                                                                        htmlFor="nbrePlaceId"
                                                                    >
                                                                        Nombre de
                                                                        places {selectedCategorie?.codeCategorie && selectedCategorie?.codeCategorie?.startsWith("VT") ? "(Facultatif)" : " *"}
                                                                    </label>
                                                                    <input
                                                                        type="number"
                                                                        id="nombrePlace"
                                                                        name="nombrePlace"
                                                                        value={formData[0].nombrePlace}
                                                                        onChange={(e) => {
                                                                            handleChange(e, "nombrePlace", 0);
                                                                            someEffects(categorie, 0);
                                                                        }}
                                                                        className={`form-control ${errors[0]?.nombrePlace ? "is-invalid" : ""}`}
                                                                        placeholder="Nbre de places"
                                                                    />
                                                                    {errors[0]?.nombrePlace && (
                                                                        <div className="invalid-feedback">
                                                                            {errors[0]?.nombrePlace}
                                                                        </div>)}
                                                                </div>
                                                                {selectedCategorie?.codeCategorie && selectedCategorie?.codeCategorie?.startsWith("VT") ? null :
                                                                    <div className="col-sm-6">
                                                                        <label className="form-label" htmlFor="usage">
                                                                            Usage *
                                                                        </label>
                                                                        <select
                                                                            id="usage"
                                                                            name="usage"
                                                                            value={formData[0].usage}
                                                                            onChange={(e) => handleChange(e, "usage", 0)}
                                                                            class="form-control w-100"
                                                                            data-style="btn-default"
                                                                        >
                                                                            <option value="TAXI">Taxi</option>
                                                                            {selectedCategorie?.codeCategorie?.startsWith("TPV") ? null :
                                                                                <option value="PRIVE">Privé</option>}
                                                                        </select>
                                                                    </div>}
                                                                {selectedCategorie?.codeCategorie?.startsWith("TPV") ? null :
                                                                    <div className="col-sm-6">
                                                                        <label
                                                                            className="form-label"
                                                                            htmlFor="carosserie"
                                                                        >
                                                                            Carosserie {selectedCategorie?.codeCategorie && selectedCategorie?.codeCategorie?.startsWith("VT") ? "(Facultatif)" : ""}
                                                                        </label>
                                                                        <select
                                                                            id="carosserie"
                                                                            name="carosserie"
                                                                            value={formData[0].carosserie}
                                                                            onChange={(e) => handleChange(e, "carosserie", 0)}
                                                                            class={`form-control w-100 ${errors[0]?.carosserie ? "is-invalid" : ""}`}
                                                                            data-style="btn-default"
                                                                        >
                                                                            <option value="">
                                                                                Sélectionner une carosserie *
                                                                            </option>
                                                                            {carosseries?.map((car, id) => (<option
                                                                                    key={id}
                                                                                    value={car.libelleParametre}
                                                                                >
                                                                                    {car.libelleParametre}
                                                                                </option>))}
                                                                        </select>
                                                                        {errors[0]?.carosserie && (
                                                                            <div className="invalid-feedback">
                                                                                {errors[0]?.carosserie}
                                                                            </div>)}
                                                                    </div>}
                                                                <div className="col-sm-6">
                                                                    <label
                                                                        className="form-label"
                                                                        htmlFor="moteur"
                                                                    >
                                                                        N°
                                                                        Moteur {selectedCategorie?.codeCategorie && selectedCategorie?.codeCategorie?.startsWith("VT") ? "(Facultatif)" : " *"}
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        id="moteur"
                                                                        name="moteur"
                                                                        value={formData[0].moteur}
                                                                        onChange={(e) => handleChange(e, "moteur", 0)}
                                                                        className={`form-control ${errors[0]?.moteur ? "is-invalid" : ""}`}
                                                                        placeholder="Moteur"
                                                                    />
                                                                    {errors[0]?.moteur && (
                                                                        <div className="invalid-feedback">
                                                                            {errors[0]?.moteur}
                                                                        </div>)}
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <label
                                                                        className="form-label"
                                                                        htmlFor="typeMoteurId"
                                                                    >
                                                                        Type {selectedCategorie?.codeCategorie && selectedCategorie?.codeCategorie?.startsWith("VT") ? "(Facultatif)" : " *"}
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        id="type"
                                                                        name="type"
                                                                        value={formData[0].type}
                                                                        onChange={(e) => handleChange(e, "type", 0)}
                                                                        className={`form-control ${errors[0]?.type ? "is-invalid" : ""}`}
                                                                        placeholder="Type"
                                                                    />
                                                                    {errors[0]?.type && (
                                                                        <div className="invalid-feedback">
                                                                            {errors[0]?.type}
                                                                        </div>)}
                                                                </div>
                                                                {categorie === "MOTO" ? (<div className="col-sm-6">
                                                                        <label
                                                                            className="form-label"
                                                                            htmlFor="genreMoteurId"
                                                                        >
                                                                            Genre du moteur *
                                                                        </label>
                                                                        <select
                                                                            id="genre"
                                                                            name="genre"
                                                                            value={formData[0].genre}
                                                                            onChange={(e) => handleChange(e, "genre", 0)}
                                                                            class={`form-control w-100 ${errors[0]?.genre ? "is-invalid" : ""}`}
                                                                            data-style="btn-default"
                                                                        >
                                                                            <option value="VELOMOTEUR">
                                                                                Vélomoteur
                                                                            </option>
                                                                            <option value="TRICYCLE">Tricycle</option>
                                                                            <option value="MOTOCYCLETTE">
                                                                                Motocyclette
                                                                            </option>
                                                                            <option value="CYCLOMOTEUR">
                                                                                Cyclomoteur
                                                                            </option>
                                                                        </select>
                                                                    </div>) : null}
                                                                <div className="col-sm-6">
                                                                    <label
                                                                        className="form-label"
                                                                        htmlFor="concessionnaire"
                                                                    >
                                                                        Concessionnaire {selectedCategorie?.codeCategorie && selectedCategorie?.codeCategorie?.startsWith("VT") ? "(Facultatif)" : ""}
                                                                    </label>
                                                                    <select
                                                                        class="form-control w-100"
                                                                        data-style="btn-default"
                                                                        id="concessionnaire"
                                                                        name="concessionnaire"
                                                                        value={formData[0].concessionnaire}
                                                                        onChange={(e) => handleChange(e, "concessionnaire", 0)}
                                                                    >
                                                                        <option value="">
                                                                            Veuillez sélectionner le concessionnaire
                                                                        </option>
                                                                        {concessionnaires.map((con, id) => (
                                                                            <option key={id} value={con.value}>
                                                                                {con.label}
                                                                            </option>))}
                                                                    </select>
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <label
                                                                        className="form-label"
                                                                        htmlFor="numeroChassis"
                                                                    >
                                                                        Numéro de châssis *
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        id="numeroChassis"
                                                                        name="numeroChassis"
                                                                        value={formData[0].numeroChassis}
                                                                        onChange={(e) => {
                                                                            handleChange(e, "numeroChassis", 0);
                                                                            someEffects(categorie, 0);
                                                                        }}
                                                                        className={`form-control ${errors[0]?.numeroChassis ? "is-invalid" : ""}`}
                                                                        placeholder="Numéro de châssis"
                                                                    />
                                                                    {errors[0]?.numeroChassis && (
                                                                        <div className="invalid-feedback">
                                                                            {errors[0]?.numeroChassis}
                                                                        </div>)}
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <label
                                                                        className="form-label"
                                                                        htmlFor="valeurVenale"
                                                                    >
                                                                        Valeur
                                                                        vénale {selectedCategorie?.codeCategorie && selectedCategorie?.codeCategorie?.startsWith("VT") ? "(Facultatif)" : " *"}
                                                                    </label>
                                                                    <input
                                                                        type="number"
                                                                        id="valeurVenale"
                                                                        name="valeurVenale"
                                                                        value={formData[0].valeurVenale}
                                                                        onChange={(e) => {
                                                                            handleChange(e, "valeurVenale", 0);
                                                                            someEffects(categorie, 0);
                                                                        }}
                                                                        className={`form-control ${errors[0]?.valeurVenale ? "is-invalid" : ""}`}
                                                                        placeholder="Valeur vénale"
                                                                    />
                                                                    {errors[0]?.valeurVenale && (
                                                                        <div className="invalid-feedback">
                                                                            {errors[0]?.valeurVenale}
                                                                        </div>)}
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <label
                                                                        className="form-label"
                                                                        htmlFor="valeurANeuf"
                                                                    >
                                                                        Valeur à
                                                                        neuf {selectedCategorie?.codeCategorie && selectedCategorie?.codeCategorie?.startsWith("VT") ? "(Facultatif)" : ""}
                                                                    </label>
                                                                    <input
                                                                        type="number"
                                                                        id="valeurANeuf"
                                                                        name="valeurANeuf"
                                                                        value={formData[0].valeurANeuf}
                                                                        onChange={(e) => handleChange(e, "valeurANeuf", 0)}
                                                                        className={`form-control ${errors[0]?.valeurANeuf ? "is-invalid" : ""}`}
                                                                        placeholder="Valeur à neuf"
                                                                    />
                                                                    {errors[0]?.valeurANeuf && (
                                                                        <div className="invalid-feedback">
                                                                            {errors[0]?.valeurANeuf}
                                                                        </div>)}
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <label
                                                                        className="form-label"
                                                                        htmlFor="valeurDeclaree"
                                                                    >
                                                                        Valeur
                                                                        déclarée {selectedCategorie?.codeCategorie && selectedCategorie?.codeCategorie?.startsWith("VT") ? "(Facultatif)" : ""}
                                                                    </label>
                                                                    <input
                                                                        type="number"
                                                                        id="valeurDeclaree"
                                                                        name="valeurDeclaree"
                                                                        value={formData[0].valeurDeclaree}
                                                                        onChange={(e) => handleChange(e, "valeurDeclaree", 0)}
                                                                        className={`form-control ${errors[0]?.valeurDeclaree ? "is-invalid" : ""}`}
                                                                        placeholder="Valeur déclarée"
                                                                    />
                                                                    {errors[0]?.valeurDeclaree && (
                                                                        <div className="invalid-feedback">
                                                                            {errors[0]?.valeurDeclaree}
                                                                        </div>)}
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <label
                                                                        className="form-label"
                                                                        htmlFor="miseEnCirculationId"
                                                                    >
                                                                        Date de mise en
                                                                        circulation {selectedCategorie?.codeCategorie && selectedCategorie?.codeCategorie?.startsWith("VT") ? "(Facultatif)" : " *"}
                                                                    </label>
                                                                    <div class="col-md-12">
                                                                        <DatePicker
                                                                            onChange={(e) => handleDateMiseEnCirculation(e, 0)}
                                                                            picker="year"
                                                                            placeholder="Veuillez sélectionner l'année"
                                                                            disabledDate={disabledCirculationDate}
                                                                            showNow={true}
                                                                            style={{
                                                                                width: "100%",
                                                                                borderColor: `${errors[0]?.dateMiseEnCirculation ? "red" : "none"}`,
                                                                            }}
                                                                        />
                                                                        {errors[0]?.dateMiseEnCirculation && (
                                                                            <div className="invalid-feedback">
                                                                                {errors[0]?.dateMiseEnCirculation}
                                                                            </div>)}
                                                                    </div>
                                                                </div>

                                                                {selectedCategorie?.codeCategorie && selectedCategorie?.codeCategorie?.startsWith("VT") ? null :
                                                                    <div className="col-sm-6">
                                                                        <label
                                                                            className="form-label"
                                                                            htmlFor="username-modern-vertical"
                                                                        >
                                                                            Garanties supplémentaires
                                                                        </label>
                                                                        <Select
                                                                            mode="multiple"
                                                                            style={{
                                                                                width: "100%",
                                                                            }}
                                                                            placeholder="Sélectionnez les garanties"
                                                                            onChange={(e) => {
                                                                                handleGarantiesChange(e, 0);
                                                                                handleSousGarantie(0, e);
                                                                            }}
                                                                            options={ncGuaranties?.map((ng, id) => ({
                                                                                label: ng.nom,
                                                                                value: ng.codeId,
                                                                                key: id,
                                                                            }))}
                                                                        />
                                                                    </div>}
                                                                <div className="row mt-4">
                                                                    <div class="d-grid gap-2 col-lg-6 mx-auto">
                                                                        <Upload {...prop(0)}>
                                                                            <br/>
                                                                            <Button
                                                                                style={{
                                                                                    background: "gray", color: "white",
                                                                                }}
                                                                                icon={<CreditCardOutlined/>}
                                                                            >
                                                                                Joindre la carte grise
                                                                            </Button>
                                                                        </Upload>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-4">
                                                                    <button
                                                                        className="btn btn-info"
                                                                        onClick={() => {
                                                                            handleShowAddPersonModal(0);
                                                                        }}
                                                                    >
                                                                        <i className="bx bx-user-plus bx-sm ms-sm-n2 me-sm-2"></i>
                                                                        <span
                                                                            className="align-middle d-sm-inline-block d-none">
                                      Préciser le conducteur habituel
                                    </span>
                                                                    </button>
                                                                </div>
                                                            </>) : (<div class="accordion mt-4" id="accordionExample">
                                                                {formData.map((fd, id) => (
                                                                    <div class="card accordion-item" key={id}>
                                                                        <h2
                                                                            class="accordion-header"
                                                                            id={`heading${id + 1}`}
                                                                        >
                                                                            <button
                                                                                type="button"
                                                                                class="accordion-button"
                                                                                data-bs-toggle="collapse"
                                                                                data-bs-target={`#accordion${id + 1}`}
                                                                                aria-expanded="true"
                                                                                aria-controls={`accordion${id + 1}`}
                                                                            >
                                                                                Engin N° {id + 1}
                                                                            </button>
                                                                        </h2>

                                                                        <div
                                                                            id={`accordion${id + 1}`}
                                                                            class="accordion-collapse collapse show"
                                                                            data-bs-parent="#accordionExample"
                                                                        >
                                                                            <div class="accordion-body">
                                                                                <div className="row">
                                                                                    {formData[id].typeSerie !== "TG" ?
                                                                                        <div className="col-sm-6">
                                                                                            <label
                                                                                                className="form-label"
                                                                                                htmlFor="last-name-modern-vertical"
                                                                                            >
                                                                                                Immatriculation *
                                                                                            </label>
                                                                                            <input
                                                                                                type="text"
                                                                                                aria-label="Immatriculation"
                                                                                                placeholder="Immatriculation"
                                                                                                class={`form-control ${errors[id]?.matricule ? "is-invalid" : ""}`}
                                                                                                id="matricule"
                                                                                                name="matricule"
                                                                                                value={formData[id].matricule}
                                                                                                onChange={(e) => handleChange(e, "matricule", id)}
                                                                                            />
                                                                                            {errors[id]?.matricule && (
                                                                                                <div
                                                                                                    className="invalid-feedback">
                                                                                                    {errors[id]?.matricule}
                                                                                                </div>)}
                                                                                        </div> :

                                                                                        <div className="col-sm-6">
                                                                                            <label
                                                                                                className="form-label"
                                                                                                htmlFor="last-name-modern-vertical"
                                                                                            >
                                                                                                Immatriculation *
                                                                                            </label>
                                                                                            <div class="input-group">
                                                                                                <select
                                                                                                    id="typeSerie"
                                                                                                    name="typeSerie"
                                                                                                    class={`form-control ${errors[id]?.typeSerie ? "is-invalid" : ""}`}
                                                                                                    data-style="btn-default"
                                                                                                    value={formData[id].typeSerie}
                                                                                                    onChange={(e) => handleChange(e, "typeSerie", id)}
                                                                                                >
                                                                                                    <option
                                                                                                        value="TG">TG
                                                                                                    </option>
                                                                                                    <option
                                                                                                        value="Autre">Autre
                                                                                                    </option>
                                                                                                </select>
                                                                                                {errors[id]?.typeSerie && (
                                                                                                    <div
                                                                                                        className="invalid-feedback">
                                                                                                        {errors[id]?.typeSerie}
                                                                                                    </div>)}
                                                                                                <input
                                                                                                    type="text"
                                                                                                    aria-label="Immatriculation"
                                                                                                    placeholder="Immatriculation"
                                                                                                    class={`form-control ${errors[id]?.matricule ? "is-invalid" : ""}`}
                                                                                                    id="matricule"
                                                                                                    name="matricule"
                                                                                                    value={formData[id].matricule}
                                                                                                    onChange={(e) => handleChange(e, "matricule", id)}
                                                                                                />
                                                                                                {errors[id]?.matricule && (
                                                                                                    <div
                                                                                                        className="invalid-feedback">
                                                                                                        {errors[0]?.matricule}
                                                                                                    </div>)}
                                                                                                <input
                                                                                                    type="text"
                                                                                                    aria-label="N° série"
                                                                                                    placeholder="N° série"
                                                                                                    class={`form-control ${errors[id]?.numeroSerie ? "is-invalid" : ""}`}
                                                                                                    name="numeroSerie"
                                                                                                    id="numeroSerie"
                                                                                                    value={formData[id].numeroSerie}
                                                                                                    onChange={(e) => handleChange(e, "numeroSerie", id)}
                                                                                                />
                                                                                                {errors[id]?.numeroSerie && (
                                                                                                    <div
                                                                                                        className="invalid-feedback">
                                                                                                        {errors[id]?.numeroSerie}
                                                                                                    </div>)}
                                                                                            </div>
                                                                                        </div>}
                                                                                    <div className="col-sm-6">
                                                                                        <label
                                                                                            className="form-label"
                                                                                            htmlFor="marque"
                                                                                        >
                                                                                            Marque *
                                                                                        </label>
                                                                                        <select
                                                                                            id="marque"
                                                                                            name="marque"
                                                                                            value={formData[id].marque}
                                                                                            onChange={(e) => {
                                                                                                handleChange(e.target.value, "marque", id);
                                                                                                selectedCategorie?.codeCategorie && selectedCategorie?.codeCategorie?.startsWith("VT") && handlePuissance(1, 0);
                                                                                            }}
                                                                                            class={`form-control w-100 ${errors[id]?.marque ? "is-invalid" : ""}`}
                                                                                            data-style="btn-default"
                                                                                        >
                                                                                            <option value="">
                                                                                                Sélectionner la marque
                                                                                            </option>
                                                                                            {marques?.map((mar, id) => (
                                                                                                <option
                                                                                                    key={id}
                                                                                                    value={mar.libelleParametre}
                                                                                                >
                                                                                                    {mar.libelleParametre}
                                                                                                </option>))}
                                                                                        </select>
                                                                                        {errors[id]?.marque && (<div
                                                                                                className="invalid-feedback">
                                                                                                {errors[id]?.marque}
                                                                                            </div>)}
                                                                                    </div>
                                                                                    <div className="col-sm-6">
                                                                                        <label
                                                                                            class="form-label"
                                                                                            for="energieId"
                                                                                        >
                                                                                            Energie *
                                                                                        </label>
                                                                                        <select
                                                                                            class="form-control w-100"
                                                                                            data-style="btn-default"
                                                                                            id="energie"
                                                                                            name="energie"
                                                                                            value={formData[id].energie}
                                                                                            onChange={(e) => handleChange(e, "energie", id)}
                                                                                        >
                                                                                            <option value="ESSENCE">
                                                                                                ESSENCE
                                                                                            </option>
                                                                                            <option value="DIESEL">
                                                                                                DIESEL
                                                                                            </option>
                                                                                            <option value="ELECTRIQUE">
                                                                                                ELECTRIQUE
                                                                                            </option>
                                                                                        </select>
                                                                                    </div>
                                                                                    {categorie !== "MOTO" ? (
                                                                                        <div className="col-sm-6">
                                                                                            <label
                                                                                                className="form-label"
                                                                                                htmlFor="puissanceId"
                                                                                            >
                                                                                                Puissance (en
                                                                                                CV) {selectedCategorie?.codeCategorie && selectedCategorie?.codeCategorie?.startsWith("VT") ? "(Facultatif)" : " *"}
                                                                                            </label>
                                                                                            <input
                                                                                                type="number"
                                                                                                id="puissance"
                                                                                                name="puissance"
                                                                                                value={formData[id].puissance}
                                                                                                onChange={(e) => {
                                                                                                    handleChange(e, "puissance", id);
                                                                                                    handlePuissance(e.target.value, id);
                                                                                                }}
                                                                                                className="form-control"
                                                                                                placeholder="Puissance"
                                                                                            />
                                                                                        </div>) : (
                                                                                        <div className="col-sm-6">
                                                                                            <label
                                                                                                className="form-label"
                                                                                                htmlFor="volumeId"
                                                                                            >
                                                                                                Volume (en cm^3) *
                                                                                            </label>
                                                                                            <input
                                                                                                type="number"
                                                                                                id="volume"
                                                                                                name="volume"
                                                                                                value={formData[id].volume}
                                                                                                onChange={(e) => {
                                                                                                    handleChange(e, "volume", id);
                                                                                                    handlePuissance(e.target.value, id);
                                                                                                }}
                                                                                                className="form-control"
                                                                                                placeholder="Volume"
                                                                                            />
                                                                                        </div>)}

                                                                                    <div className="col-sm-6">
                                                                                        <label
                                                                                            className="form-label"
                                                                                            htmlFor="nbrePlaceId"
                                                                                        >
                                                                                            Nombre de
                                                                                            places {selectedCategorie?.codeCategorie && selectedCategorie?.codeCategorie?.startsWith("VT") ? "(Facultatif)" : " *"}
                                                                                        </label>
                                                                                        <input
                                                                                            type="number"
                                                                                            id="nombrePlace"
                                                                                            name="nombrePlace"
                                                                                            value={formData[id].nombrePlace}
                                                                                            onChange={(e) => handleChange(e, "nombrePlace", id)}
                                                                                            className="form-control"
                                                                                            placeholder="Nbre de places"
                                                                                        />
                                                                                    </div>
                                                                                    {selectedCategorie?.codeCategorie && selectedCategorie?.codeCategorie?.startsWith("VT") ? null :
                                                                                        <div className="col-sm-6">
                                                                                            <label
                                                                                                className="form-label"
                                                                                                htmlFor="usage"
                                                                                            >
                                                                                                Usage *
                                                                                            </label>
                                                                                            <select
                                                                                                id="usage"
                                                                                                name="usage"
                                                                                                value={formData[id].usage}
                                                                                                onChange={(e) => handleChange(e, "usage", id)}
                                                                                                class="form-control w-100"
                                                                                                data-style="btn-default"
                                                                                            >
                                                                                                <option
                                                                                                    value="TAXI">Taxi
                                                                                                </option>
                                                                                                <option value="PRIVE">
                                                                                                    Privé
                                                                                                </option>
                                                                                            </select>
                                                                                        </div>}
                                                                                    <div className="col-sm-6">
                                                                                        <label
                                                                                            className="form-label"
                                                                                            htmlFor="carosserie"
                                                                                        >
                                                                                            Carosserie {selectedCategorie?.codeCategorie && selectedCategorie?.codeCategorie?.startsWith("VT") ? "(Facultatif)" : " *"}
                                                                                        </label>
                                                                                        <select
                                                                                            id="carosserie"
                                                                                            name="carosserie"
                                                                                            value={formData[id].carosserie}
                                                                                            onChange={(e) => handleChange(e, "carosserie", id)}
                                                                                            class={`form-control w-100 ${errors[id]?.carosserie ? "is-invalid" : ""}`}
                                                                                            data-style="btn-default"
                                                                                        >
                                                                                            <option value="">
                                                                                                Sélectionner une
                                                                                                carosserie
                                                                                            </option>
                                                                                            {carosseries?.map((car, id) => (
                                                                                                <option
                                                                                                    key={id}
                                                                                                    value={car.libelleParametre}
                                                                                                >
                                                                                                    {car.libelleParametre}
                                                                                                </option>))}
                                                                                        </select>
                                                                                        {errors[id]?.carosserie && (<div
                                                                                                className="invalid-feedback">
                                                                                                {errors[id]?.carosserie}
                                                                                            </div>)}
                                                                                    </div>
                                                                                    <div className="col-sm-6">
                                                                                        <label
                                                                                            className="form-label"
                                                                                            htmlFor="moteur"
                                                                                        >
                                                                                            Moteur {selectedCategorie?.codeCategorie && selectedCategorie?.codeCategorie?.startsWith("VT") ? "(Facultatif)" : " *"}
                                                                                        </label>
                                                                                        <input
                                                                                            type="text"
                                                                                            id="moteur"
                                                                                            name="moteur"
                                                                                            value={formData[id].moteur}
                                                                                            onChange={(e) => handleChange(e, "moteur", id)}
                                                                                            className={`form-control ${errors[id]?.moteur ? "is-invalid" : ""}`}
                                                                                            placeholder="Moteur"
                                                                                        />
                                                                                        {errors[id]?.moteur && (<div
                                                                                                className="invalid-feedback">
                                                                                                {errors[id]?.moteur}
                                                                                            </div>)}
                                                                                    </div>
                                                                                    <div className="col-sm-6">
                                                                                        <label
                                                                                            className="form-label"
                                                                                            htmlFor="typeMoteurId"
                                                                                        >
                                                                                            Type {selectedCategorie?.codeCategorie && selectedCategorie?.codeCategorie?.startsWith("VT") ? "(Facultatif)" : " *"}
                                                                                        </label>
                                                                                        <input
                                                                                            type="text"
                                                                                            id="type"
                                                                                            name="type"
                                                                                            value={formData[id].type}
                                                                                            onChange={(e) => handleChange(e, "type", id)}
                                                                                            className={`form-control ${errors[id]?.type ? "is-invalid" : ""}`}
                                                                                            placeholder="Type"
                                                                                        />
                                                                                        {errors[id]?.type && (<div
                                                                                                className="invalid-feedback">
                                                                                                {errors[id]?.type}
                                                                                            </div>)}
                                                                                    </div>
                                                                                    {categorie === "MOTO" ? (
                                                                                        <div className="col-sm-6">
                                                                                            <label
                                                                                                className="form-label"
                                                                                                htmlFor="genreMoteurId"
                                                                                            >
                                                                                                Genre du moteur *
                                                                                            </label>
                                                                                            <select
                                                                                                id="genre"
                                                                                                name="genre"
                                                                                                value={formData[id].genre}
                                                                                                onChange={(e) => handleChange(e, "genre", id)}
                                                                                                class={`form-control w-100 ${errors[id]?.genre ? "is-invalid" : ""}`}
                                                                                                data-style="btn-default"
                                                                                            >
                                                                                                <option
                                                                                                    value="VELOMOTEUR">
                                                                                                    Vélomoteur
                                                                                                </option>
                                                                                                <option
                                                                                                    value="TRICYCLE">
                                                                                                    Tricycle
                                                                                                </option>
                                                                                                <option
                                                                                                    value="MOTOCYCLETTE">
                                                                                                    Motocyclette
                                                                                                </option>
                                                                                                <option
                                                                                                    value="CYCLOMOTEUR">
                                                                                                    Cyclomoteur
                                                                                                </option>
                                                                                            </select>
                                                                                        </div>) : null}
                                                                                    <div className="col-sm-6">
                                                                                        <label
                                                                                            className="form-label"
                                                                                            htmlFor="concessionnaire"
                                                                                        >
                                                                                            Concessionnaire /
                                                                                            Intermédiaire
                                                                                        </label>
                                                                                        <select
                                                                                            class="form-control w-100"
                                                                                            data-style="btn-default"
                                                                                            id="concessionnaire"
                                                                                            name="concessionnaire"
                                                                                            value={formData[id].concessionnaire}
                                                                                            onChange={(e) => handleChange(e, "concessionnaire", id)}
                                                                                        >
                                                                                            <option value="">
                                                                                                Veuillez sélectionner le
                                                                                                concessionnaire
                                                                                            </option>
                                                                                            {concessionnaires.map((con, id) => (
                                                                                                <option
                                                                                                    key={id}
                                                                                                    value={con.value}
                                                                                                >
                                                                                                    {con.label}
                                                                                                </option>))}
                                                                                        </select>
                                                                                    </div>
                                                                                    <div className="col-sm-6">
                                                                                        <label
                                                                                            className="form-label"
                                                                                            htmlFor="numeroChassis"
                                                                                        >
                                                                                            Numéro de châssis *
                                                                                        </label>
                                                                                        <input
                                                                                            type="text"
                                                                                            id="numeroChassis"
                                                                                            name="numeroChassis"
                                                                                            value={formData[id].numeroChassis}
                                                                                            onChange={(e) => handleChange(e, "numeroChassis", id)}
                                                                                            className={`form-control ${errors[id]?.numeroChassis ? "is-invalid" : ""}`}
                                                                                            placeholder="Numéro de châssis"
                                                                                        />
                                                                                        {errors[id]?.numeroChassis && (
                                                                                            <div
                                                                                                className="invalid-feedback">
                                                                                                {errors[id]?.numeroChassis}
                                                                                            </div>)}
                                                                                    </div>
                                                                                    <div className="col-sm-6">
                                                                                        <label
                                                                                            className="form-label"
                                                                                            htmlFor="valeurVenale"
                                                                                        >
                                                                                            Valeur
                                                                                            vénale {selectedCategorie?.codeCategorie && selectedCategorie?.codeCategorie?.startsWith("VT") ? "(Facultatif)" : " *"}
                                                                                        </label>
                                                                                        <input
                                                                                            type="number"
                                                                                            id="valeurVenale"
                                                                                            name="valeurVenale"
                                                                                            value={formData[id].valeurVenale}
                                                                                            onChange={(e) => handleChange(e, "valeurVenale", id)}
                                                                                            className="form-control"
                                                                                            placeholder="Valeur vénale"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-sm-6">
                                                                                        <label
                                                                                            className="form-label"
                                                                                            htmlFor="valeurANeuf"
                                                                                        >
                                                                                            Valeur à
                                                                                            neuf {selectedCategorie?.codeCategorie && selectedCategorie?.codeCategorie?.startsWith("VT") ? "(Facultatif)" : ""}
                                                                                        </label>
                                                                                        <input
                                                                                            type="number"
                                                                                            id="valeurANeuf"
                                                                                            name="valeurANeuf"
                                                                                            value={formData[id].valeurANeuf}
                                                                                            onChange={(e) => handleChange(e, "valeurANeuf", id)}
                                                                                            className="form-control"
                                                                                            placeholder="Valeur à neuf"
                                                                                        />
                                                                                    </div>

                                                                                    <div className="col-sm-6">
                                                                                        <label
                                                                                            className="form-label"
                                                                                            htmlFor="valeurDeclaree"
                                                                                        >
                                                                                            Valeur
                                                                                            déclarée {selectedCategorie?.codeCategorie && selectedCategorie?.codeCategorie?.startsWith("VT") ? "(Facultatif)" : ""}
                                                                                        </label>
                                                                                        <input
                                                                                            type="number"
                                                                                            id="valeurDeclaree"
                                                                                            name="valeurDeclaree"
                                                                                            value={formData[id].valeurDeclaree}
                                                                                            onChange={(e) => handleChange(e, "valeurDeclaree", id)}
                                                                                            className="form-control"
                                                                                            placeholder="Valeur déclarée"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-sm-6">
                                                                                        <label
                                                                                            className="form-label"
                                                                                            htmlFor="miseEnCirculationId"
                                                                                        >
                                                                                            Date de mise en
                                                                                            circulation {selectedCategorie?.codeCategorie && selectedCategorie?.codeCategorie?.startsWith("VT") ? "(Facultatif)" : " *"}
                                                                                        </label>
                                                                                        <div class="col-md-12">
                                                                                            <DatePicker
                                                                                                onChange={(e) => handleDateMiseEnCirculation(e, id)}
                                                                                                picker="year"
                                                                                                placeholder="Veuillez sélectionner l'année"
                                                                                                disabledDate={disabledCirculationDate}
                                                                                                showNow={true}
                                                                                                style={{
                                                                                                    width: "100%",
                                                                                                    borderColor: `${errors[id]?.dateMiseEnCirculation ? "red" : "none"}`,
                                                                                                }}
                                                                                            />
                                                                                            {errors[id]?.dateMiseEnCirculation && (
                                                                                                <div
                                                                                                    className="invalid-feedback">
                                                                                                    {errors[id]?.dateMiseEnCirculation}
                                                                                                </div>)}
                                                                                        </div>
                                                                                    </div>

                                                                                    {selectedCategorie?.codeCategorie && selectedCategorie?.codeCategorie?.startsWith("VT") ? null :
                                                                                        <div className="col-sm-6">
                                                                                            <label
                                                                                                className="form-label"
                                                                                                htmlFor="username-modern-vertical"
                                                                                            >
                                                                                                Garanties
                                                                                                supplémentaires
                                                                                            </label>
                                                                                            <Select
                                                                                                mode="multiple"
                                                                                                style={{
                                                                                                    width: "100%",
                                                                                                }}
                                                                                                placeholder="Sélectionnez les garanties"
                                                                                                onChange={(e) => {
                                                                                                    handleGarantiesChange(e, id);
                                                                                                    handleSousGarantie(id, e);
                                                                                                }}
                                                                                                options={ncGuaranties?.map((ng, id) => ({
                                                                                                    label: ng.nom,
                                                                                                    value: ng.codeId,
                                                                                                    key: id,
                                                                                                }))}
                                                                                            />
                                                                                        </div>}
                                                                                    <div className="row mt-4">
                                                                                        <div
                                                                                            class="d-grid gap-2 col-lg-6 mx-auto">
                                                                                            <Upload {...prop(id)}>
                                                                                                <br/>
                                                                                                <Button
                                                                                                    style={{
                                                                                                        background: "gray",
                                                                                                        color: "white",
                                                                                                    }}
                                                                                                    icon={
                                                                                                        <CreditCardOutlined/>}
                                                                                                >
                                                                                                    Joindre la carte
                                                                                                    grise
                                                                                                </Button>
                                                                                            </Upload>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-sm-3">
                                                                                        <button
                                                                                            className="btn btn-info"
                                                                                            onClick={() => {
                                                                                                handleShowAddPersonModal(id);
                                                                                            }}
                                                                                        >
                                                                                            <i className="bx bx-user-plus bx-sm ms-sm-n2 me-sm-2"></i>
                                                                                            <span
                                                                                                className="align-middle d-sm-inline-block d-none">
                                                Préciser le conducteur habituel
                                              </span>
                                                                                        </button>
                                                                                    </div>
                                                                                    <div className="col-sm-6"></div>
                                                                                    <div className="col-sm-3">
                                                                                        {id ? (<button
                                                                                                className="btn btn-danger"
                                                                                                onClick={() => removeFormFields(id)}
                                                                                            >
                                                                                                <i className="bx bxs-trash bx-sm ms-sm-n2 me-sm-2"></i>
                                                                                                <span
                                                                                                    className="align-middle d-sm-inline-block d-none">
                                                  Supprimer
                                                </span>
                                                                                            </button>) : null}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>))}
                                                            </div>)}
                                                        {/* <div className="col-sm-6">
                              <label
                                className="form-label"
                                htmlFor="carteGriseId"
                              >
                                Carte grise
                              </label>
                              <input
                                class="form-control"
                                type="file"
                                id="carteGriseId"
                              />
                            </div> */}
                                                        {categorie === "MOTO" ? (<div class="row mt-4">
                                                                <div class="d-grid gap-2 col-lg-6 mx-auto">
                                                                    <button
                                                                        type="button"
                                                                        class="btn btn-primary"
                                                                        onClick={() => addFlotteFormFields()}
                                                                    >
                                                                        <span
                                                                            class="tf-icons bx bx-folder-plus bx-18px me-2"></span>
                                                                        Ajouter un engin
                                                                    </button>
                                                                </div>
                                                            </div>) : null}
                                                        <div className="col-12 d-flex justify-content-between">
                                                            <button
                                                                className="btn btn-label-secondary btn-prev"
                                                                disabled
                                                            >
                                                                <i className="bx bx-left-arrow-alt bx-sm ms-sm-n2 me-sm-2"></i>
                                                                <span className="align-middle d-sm-inline-block d-none">
                                  Précédent
                                </span>
                                                            </button>
                                                            <button
                                                                className="btn btn-primary"
                                                                onClick={() => {
                                                                    updateTaxes();
                                                                    updateTaxes();
                                                                    updatePrimes();
                                                                    updateTaxes();
                                                                    validateForm() && setActiveTab(2);
                                                                }}
                                                            >
                                                                <span className="align-middle d-sm-inline-block d-none me-sm-2">
                                                                  Suivant
                                                                </span>
                                                                <i className="bx bx-right-arrow-alt bx-sm me-sm-n2"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Account Details */}
                                                <div
                                                    id="account-details-vertical-modern"
                                                    className={activeTab === 2 ? "content active" : "content"}
                                                >
                                                    <div className="content-header mb-4">
                                                        <h6 className="mb-0">Détails de garanties</h6>
                                                        <small>
                                                            Veuillez entrer les détails de garanties
                                                        </small>
                                                    </div>
                                                    <div className="row g-6">
                                                        <div className="col-sm-6">
                                                            <label
                                                                className="form-label"
                                                                htmlFor="fractionnement"
                                                            >
                                                              Durée
                                                            </label>
                                                            <select
                                                                id="categorieId"
                                                                class="form-control w-100"
                                                                data-style="btn-default"
                                                                onChange={(e) => {
                                                                    setFractionnementSelect(e.target.value);
                                                                    calculateFractionnement(e.target.value);
                                                                }}
                                                            >
                                                                <option value="">
                                                                  1 an
                                                                </option>
                                                                {fractionnements.map((fra, id) => fra.nombre !== 1 && (
                                                                    <option key={id} value={fra.fractionnementId}>
                                                                        {fra.moisMin} {fra.periode ? fra.periode : "Mois"}
                                                                    </option>))}
                                                            </select>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <label
                                                                className="form-label"
                                                                htmlFor="email-modern-vertical"
                                                            >
                                                                Réductions
                                                            </label>
                                                            <Select
                                                                mode="multiple"
                                                                style={{
                                                                    width: "100%",
                                                                }}
                                                                placeholder="Sélectionnez les réductions"
                                                                onChange={handleSelectReduction}
                                                                options={reductions?.map((red, id) => ({
                                                                    label: red.nom, value: red.reductionId, key: id,
                                                                }))}
                                                            />
                                                        </div>

                                                        {LOGGED_USER?.agence?.typeAgence === "COURTIER" ?
                                                            <div className="col-sm-6">
                                                                <label
                                                                    className="form-label"
                                                                    htmlFor="email-modern-vertical"
                                                                >
                                                                    La compagnie pour laquelle vous produisez ...
                                                                </label>
                                                                <select
                                                                    id="categorie"
                                                                    class="form-control w-100"
                                                                    data-style="btn-default"
                                                                    value={selectedCompany}
                                                                    onChange={handleCourtierChange}
                                                                >
                                                                    <option value="">Veuillez sélectionner la compagnie
                                                                        pour laquelle vous produisez
                                                                    </option>
                                                                    {LOGGED_USER?.agence?.validAgrements?.map((agr, id) => (
                                                                        <option key={id}
                                                                                value={agr.compagnie.organismeId}>
                                                                            {agr?.compagnie?.raisonSociale}
                                                                        </option>))}
                                                                </select>
                                                            </div> : <div className="col-sm-6">
                                                                <label
                                                                    className="form-label"
                                                                    htmlFor="email-modern-vertical"
                                                                >
                                                                    Vous produisez pour le compte de l'agence ...
                                                                </label>
                                                                <Select
                                                                    style={{
                                                                        width: "100%",
                                                                    }}
                                                                    onChange={handleAgenceChange}
                                                                    options={[{
                                                                        label: <span>Agence</span>,
                                                                        title: 'agence',
                                                                        options: getSimpleAgencies()?.map((a, id) => rightsService("HLA068") ? ({
                                                                            label: a.nom, value: a.codeAgence, key: id,
                                                                        }) : (LOGGED_USER?.agence?.codeAgence === a.codeAgence && {
                                                                            label: a.nom, value: a.codeAgence, key: id,
                                                                        })).filter(Boolean)
                                                                    }, {
                                                                        label: <span>Courtier</span>,
                                                                        title: 'courtier',
                                                                        options: courtiers?.map((c, id) => ({
                                                                            label: c.nom, value: c.codeAgence, key: id,
                                                                        }))
                                                                    },]}
                                                                    defaultValue={LOGGED_USER?.agence?.nom}
                                                                />
                                                            </div>}
                                                        {intermediaires?.length ? <div className="col-sm-6">
                                                            <label
                                                                className="form-label"
                                                                htmlFor="email-modern-vertical"
                                                            >
                                                                L'intermédiaire ...
                                                            </label>
                                                            <select
                                                                id="categorie"
                                                                class="form-control w-100"
                                                                data-style="btn-default"
                                                                value={selectedIntermediaire}
                                                                onChange={handleIntermediaireChange}
                                                            >
                                                                <option value="">Veuillez sélectionner l'intermediaire
                                                                    de cette affaire
                                                                </option>
                                                                {intermediaires?.map((inter, id) => (
                                                                    <option key={id} value={inter.codeIntermediaire}>
                                                                        {inter?.personne?.nom} {inter?.personne?.prenom}
                                                                    </option>))}
                                                            </select>
                                                        </div> : null}
                                                        {intermediaires?.length || LOGGED_USER?.agence?.typeAgence === "COURTIER" || agencyProduction?.typeAgence === "COURTIER" ?
                                                            <div className="col-sm-6">
                                                                <label
                                                                    className="form-label"
                                                                    htmlFor="tauxCommission"
                                                                >
                                                                    Taux de commission{" "}
                                                                </label>
                                                                <input
                                                                    type="number"
                                                                    id="tauxCommission"
                                                                    name="tauxCommission"
                                                                    value={tauxCommission}
                                                                    onChange={(e) => {
                                                                        setTauxCommission(parseInt(e.target.value));
                                                                    }}
                                                                    className="form-control"
                                                                    placeholder=""
                                                                />
                                                            </div> : null}
                                                        <div className="col-sm-6">
                                                            <label
                                                                className="form-label"
                                                                htmlFor="accessoires"
                                                            >
                                                                Accessoires{" "}
                                                            </label>
                                                            <input
                                                                type="number"
                                                                id="accessoires"
                                                                name="accessoires"
                                                                value={fraisAccessoires}
                                                                onChange={(e) => {
                                                                    setFraisAccessoires(parseInt(e.target.value));
                                                                    updateTaxes();
                                                                }}
                                                                className="form-control"
                                                                placeholder="Entrez les frais accessoires"
                                                                disabled
                                                            />
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <label className="form-label" htmlFor="dateEffet">
                                                                Date effet *
                                                            </label>
                                                            <div class="col-md-12">
                                                                <ConfigProvider locale={frFR}>
                                                                    <DatePicker
                                                                        onChange={getDateEcheance}
                                                                        placeholder="Entrer la date effet"
                                                                        showTime={true}
                                                                        showNow={true}
                                                                        showToday={true}
                                                                        disabledDate={disabledDate}
                                                                        defaultValue={dayjs()}
                                                                    />
                                                                </ConfigProvider>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <div className="container">
                                                                <Table
                                                                    tableHeaderColor="primary"
                                                                    coloredColls={[3]}
                                                                    colorsColls={["primary"]}
                                                                    columns={columns}
                                                                    dataSource={subGaranties}
                                                                    pagination={false}
                                                                    footer={() => <MyFooter/>}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 d-flex justify-content-between">
                                                            <button
                                                                className="btn btn-primary"
                                                                onClick={() => {
                                                                    setActiveTab(1);
                                                                }}
                                                            >
                                                                <i className="bx bx-left-arrow-alt bx-sm ms-sm-n2 me-sm-2"></i>
                                                                <span className="align-middle d-sm-inline-block d-none">
                                  Précédent
                                </span>
                                                            </button>
                                                            <button
                                                                className="btn btn-primary"
                                                                onClick={() => {
                                                                    setActiveTab(3);
                                                                }}
                                                            >
                                <span className="align-middle d-sm-inline-block d-none me-sm-2">
                                  Suivant
                                </span>
                                                                <i className="bx bx-right-arrow-alt bx-sm me-sm-n2"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Social Links */}
                                                <div
                                                    id="social-links-vertical-modern"
                                                    className={activeTab === 3 ? "content active" : "content"}
                                                >
                                                    <div className="content-header mb-4">
                                                        <h6 className="mb-0">Infos sur le souscripteur</h6>
                                                        <small>
                                                            {oldInsured ? "Veuillez sélectionner le souscripteur" : "Renseigner les informations sur le souscripteur"}
                                                        </small>
                                                    </div>
                                                    {oldInsured ? <div className="row g-6">
                                                        <div className="col-sm-12">
                                                            <Select
                                                                showSearch
                                                                placeholder="Selectionnez le client pour lequel vous voulez établir un contrat. vous pouvez faire une recherche dynamique......"
                                                                optionFilterProp="children"
                                                                onChange={(e) => {
                                                                    setOldClient(JSON.parse(e));
                                                                    setCSelected(true);
                                                                }}
                                                                onSearch={(e) => {
                                                                    console.log("Recherche ...", e);
                                                                }}
                                                                filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                                                                options={clients}
                                                                style={{width: "100%"}}
                                                            />
                                                        </div>
                                                        <div className="col-sm-12">
                                                            {cSelected && (<div className="col-sm-12">
                                                                    <Descriptions
                                                                        title={`Assuré N° ${oldClient.numeroAssureM ? oldClient.numeroAssureM : oldClient.numeroAssureP ? oldClient.numeroAssureP : "---"}`}
                                                                        size={"default"}
                                                                    >
                                                                        <Descriptions.Item label="Nom et prénom(s)">
                                                                            {oldClient.prenom ? `${oldClient.nom} ${oldClient.prenom}` : `${oldClient.nom}`}
                                                                        </Descriptions.Item>
                                                                        {oldClient.typePersonne && oldClient.typePersonne === "PHYSIQUE" && (
                                                                            <Descriptions.Item label="Genre">
                                                                                {oldClient.genre}
                                                                            </Descriptions.Item>)}
                                                                        {oldClient.adresse && (<Descriptions.Item
                                                                                label={oldClient.typePersonne === "PHYSIQUE" ? "Adresse de résidence" : "Siège social"}
                                                                            >
                                                                                {oldClient.adresse}
                                                                            </Descriptions.Item>)}
                                                                        {oldClient.email && (
                                                                            <Descriptions.Item label="Adresse mail">
                                                                                {oldClient.email}
                                                                            </Descriptions.Item>)}
                                                                        {oldClient.contact?.length && (
                                                                            <Descriptions.Item label="Contact(s)">
                                                                                {oldClient.contact[0]} {oldClient.contact[1] && oldClient.contact[1]}
                                                                            </Descriptions.Item>)}
                                                                        {oldClient.profession && (
                                                                            <Descriptions.Item label="Profession">
                                                                                {oldClient.profession}
                                                                            </Descriptions.Item>)}
                                                                    </Descriptions>
                                                                </div>)}
                                                        </div>
                                                        <div className="col-12 d-flex justify-content-between">
                                                            <button
                                                                className="btn btn-primary"
                                                                onClick={() => {
                                                                    setActiveTab(2);
                                                                }}
                                                            >
                                                                <i className="bx bx-left-arrow-alt bx-sm ms-sm-n2 me-sm-2"></i>
                                                                <span
                                                                    className="align-middle d-sm-inline-block d-none">
                                    Précédent
                                  </span>
                                                            </button>
                                                            <button
                                                                className="btn btn-success"
                                                                onClick={(e) => {
                                                                    handleSubmit(e);
                                                                }}
                                                                disabled={loading}
                                                            >
                                                                {loading && (<span
                                                                        class="spinner-grow me-1"
                                                                        role="status"
                                                                        aria-hidden="true"
                                                                    ></span>)}
                                                                <span
                                                                    className="align-middle d-sm-inline-block d-none me-sm-2">
                                    {loading ? `Enregistrement...` : `Enregistrer`}
                                  </span>
                                                                <i className="bx bx-right-arrow-alt bx-sm me-sm-n2"></i>
                                                            </button>
                                                        </div>
                                                    </div> : <div className="row g-6">
                                                        <div className="col-sm-12">
                                                            <div class="col-xl-12">
                                                                <div class="card">
                                                                    <h5 class="card-header">Type de personne *</h5>
                                                                    <div class="card-body">
                                                                        <div class="row">
                                                                            <div class="col-md mb-md-0 mb-5">
                                                                                <div
                                                                                    class="form-check custom-option custom-option-icon">
                                                                                    <label
                                                                                        class="form-check-label custom-option-content"
                                                                                        for="customRadioIcon2"
                                                                                    >
                                              <span class="custom-option-body">
                                                <i class="bx bx-user"></i>
                                                <span class="custom-option-title">
                                                  {" "}
                                                    Physique{" "}
                                                </span>
                                                  {/* <small>
                                                {" "}
                                                Cake sugar plum fruitcake I love
                                                sweet roll jelly-o.{" "}
                                              </small> */}
                                              </span>
                                                                                        <input
                                                                                            name="customOptionRadioIcon"
                                                                                            class="form-check-input"
                                                                                            type="radio"
                                                                                            value=""
                                                                                            id="customRadioIcon2"
                                                                                            onChange={() => setTypePersonne("PHYSIQUE")}
                                                                                            checked={typePersonne === "PHYSIQUE"}
                                                                                        />
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md">
                                                                                <div
                                                                                    class="form-check custom-option custom-option-icon">
                                                                                    <label
                                                                                        class="form-check-label custom-option-content"
                                                                                        for="customRadioIcon3"
                                                                                    >
                                              <span class="custom-option-body">
                                                <i class="bx bx-briefcase"></i>
                                                <span class="custom-option-title">
                                                  {" "}
                                                    Morale{" "}
                                                </span>
                                                  {/* <small>
                                                Cake sugar plum fruitcake I love
                                                sweet roll jelly-o.
                                              </small> */}
                                              </span>
                                                                                        <input
                                                                                            name="customOptionRadioIcon"
                                                                                            class="form-check-input"
                                                                                            type="radio"
                                                                                            value=""
                                                                                            id="customRadioIcon3"
                                                                                            onChange={() => setTypePersonne("MORALE")}
                                                                                        />
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <label className="form-label" htmlFor="nom">
                                                                {typePersonne === "PHYSIQUE" ? "Nom" : "Raison sociale"} *
                                                            </label>
                                                            <input
                                                                type="text"
                                                                id="nom"
                                                                className={`form-control ${sfErrors?.nom ? "is-invalid" : ""}`}
                                                                placeholder="Entrez le nom"
                                                                name="nom"
                                                                value={personneData.nom}
                                                                onChange={handlePersonneChange}
                                                            />
                                                            {sfErrors?.nom && (<div className="invalid-feedback">
                                                                    {sfErrors?.nom}
                                                                </div>)}
                                                        </div>
                                                        {typePersonne === "PHYSIQUE" ? (<div className="col-sm-6">
                                                                <label className="form-label" htmlFor="prenom">
                                                                    Prénom(s) *
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    id="prenom"
                                                                    className={`form-control ${sfErrors?.prenom ? "is-invalid" : ""}`}
                                                                    placeholder="Entrez le prénom"
                                                                    name="prenom"
                                                                    value={personneData.prenom}
                                                                    onChange={handlePersonneChange}
                                                                />
                                                                {sfErrors?.prenom && (<div className="invalid-feedback">
                                                                        {sfErrors?.prenom}
                                                                    </div>)}
                                                            </div>) : null}
                                                        <div className="col-sm-6">
                                                            <label className="form-label" htmlFor="email">
                                                                Adresse mail
                                                            </label>
                                                            <input
                                                                type="text"
                                                                id="email"
                                                                className={`form-control ${sfErrors?.email ? "is-invalid" : ""}`}
                                                                placeholder="Entrez l'adresse mail"
                                                                name="email"
                                                                value={personneData.email}
                                                                onChange={handlePersonneChange}
                                                            />{sfErrors?.email && (<div className="invalid-feedback">
                                                                {sfErrors?.email}
                                                            </div>)}
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <label className="form-label" htmlFor="adresse">
                                                                {typePersonne === "PHYSIQUE" ? "Adresse de résidence" : "Siège social"} *
                                                            </label>
                                                            <input
                                                                type="text"
                                                                id="adresse"
                                                                className={`form-control ${sfErrors?.adresse ? "is-invalid" : ""}`}
                                                                placeholder={typePersonne === "PHYSIQUE" ? "Entrez l'adresse de résidence" : "Entrez le siège social"}
                                                                name="adresse"
                                                                value={personneData.adresse}
                                                                onChange={handlePersonneChange}
                                                            />
                                                            {sfErrors?.adresse && (<div className="invalid-feedback">
                                                                    {sfErrors?.adresse}
                                                                </div>)}
                                                        </div>
                                                        {typePersonne === "PHYSIQUE" ? (<div className="col-sm-6">
                                                                <label class="form-label" for="genre">
                                                                    Sexe *
                                                                </label>
                                                                <select
                                                                    id="genre"
                                                                    class="form-control w-100"
                                                                    data-style="btn-default"
                                                                    name="genre"
                                                                    value={personneData.genre}
                                                                    onChange={handlePersonneChange}
                                                                >
                                                                    <option value="MASCULIN">Masculin</option>
                                                                    <option value="FEMININ">Féminin</option>
                                                                </select>
                                                            </div>) : null}
                                                        {typePersonne === "PHYSIQUE" ? (<>
                                                                <div className="col-sm-6">
                                                                    <label for="profession" class="form-label">
                                                                        Profession
                                                                    </label>
                                                                    <Select
                                                                        id="profession"
                                                                        className="w-100"
                                                                        showSearch
                                                                        placeholder="Selectionnez la profession"
                                                                        optionFilterProp="children"
                                                                        value={personneData.profession}
                                                                        onChange={(value) => handlePersonneChange({
                                                                            target: {
                                                                                name: 'profession',
                                                                                id: 'profession',
                                                                                value
                                                                            }
                                                                        })}
                                                                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                                        style={{width: '100%'}}
                                                                    >
                                                                        {professions.map((prof, id) => (<Option key={id}
                                                                                                                value={prof.libelleParametre}>
                                                                                {prof.libelleParametre}
                                                                            </Option>))}
                                                                    </Select>
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <label for="typePiece" class="form-label">
                                                                        Type de pièce *
                                                                    </label>
                                                                    <select
                                                                        id="typePiece"
                                                                        class={`form-control w-100 ${sfErrors?.typePiece ? "is-invalid" : ""}`}
                                                                        data-style="btn-default"
                                                                        name="typePiece"
                                                                        value={personneData.typePiece}
                                                                        onChange={handlePersonneChange}
                                                                    >
                                                                        <option value={"CNI"}>CNI</option>
                                                                        <option value={"PASSEPORT"}>Passeport
                                                                        </option>
                                                                        <option value="PERMIS">PERMIS DE CONDUIRE
                                                                        </option>
                                                                        <option value="ELECTEUR">CARTE D'ELECTEUR
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <label
                                                                        className="form-label"
                                                                        htmlFor="numeroPiece"
                                                                    >
                                                                        Numéro de pièce *
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        id="numIdentite"
                                                                        className={`form-control ${sfErrors?.numIdentite ? "is-invalid" : ""}`}
                                                                        placeholder="Entrez le numéro de la pièce"
                                                                        name="numIdentite"
                                                                        value={personneData.numIdentite}
                                                                        onChange={handlePersonneChange}
                                                                    />
                                                                    {sfErrors?.numIdentite && (
                                                                        <div className="invalid-feedback">
                                                                            {sfErrors?.numIdentite}
                                                                        </div>)}
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <label
                                                                        className="form-label"
                                                                        htmlFor="lieuDelivrance"
                                                                    >
                                                                        Lieu de délivrance *
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        id="lieuDelivrance"
                                                                        className={`form-control ${sfErrors?.lieuDelivrance ? "is-invalid" : ""}`}
                                                                        placeholder="Entrez le lieu de délivrance de la pièce"
                                                                        name="lieuDelivrance"
                                                                        value={personneData.lieuDelivrance}
                                                                        onChange={handlePersonneChange}
                                                                    />
                                                                    {sfErrors?.lieuDelivrance && (
                                                                        <div className="invalid-feedback">
                                                                            {sfErrors?.lieuDelivrance}
                                                                        </div>)}
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <label
                                                                        className="form-label"
                                                                        htmlFor="dateDelivrance"
                                                                    >
                                                                        Date de délivrance *
                                                                    </label>
                                                                    <div class="col-md-12">
                                                                        <input
                                                                            class={`form-control ${sfErrors?.dateDelivrance ? "is-invalid" : ""}`}
                                                                            type="date"
                                                                            id="dateDelivrance"
                                                                            name="dateDelivrance"
                                                                            value={personneData.dateDelivrance}
                                                                            onChange={handlePersonneChange}
                                                                        />
                                                                        {sfErrors?.dateDelivrance && (
                                                                            <div className="invalid-feedback">
                                                                                {sfErrors?.dateDelivrance}
                                                                            </div>)}
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <label
                                                                        className="form-label"
                                                                        htmlFor="dateNaissance"
                                                                    >
                                                                        Date de naissance *
                                                                    </label>
                                                                    <div class="col-md-12">
                                                                        <input
                                                                            class={`form-control ${sfErrors?.dateNaissance ? "is-invalid" : ""}`}
                                                                            type="date"
                                                                            id="dateNaissance"
                                                                            name="dateNaissance"
                                                                            value={personneData.dateNaissance}
                                                                            onChange={handlePersonneChange}
                                                                        />
                                                                        {sfErrors?.dateNaissance && (
                                                                            <div className="invalid-feedback">
                                                                                {sfErrors?.dateNaissance}
                                                                            </div>)}
                                                                    </div>
                                                                </div>
                                                            </>) : null}
                                                        {typePersonne === "PHYSIQUE" ? null : (<>
                                                                <div className="col-sm-6">
                                                                    <label
                                                                        className="form-label"
                                                                        htmlFor="numeroRegistreCommerce"
                                                                    >
                                                                        Numéro de régistre de commerce *
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        id="numeroRegistreCommerce"
                                                                        className={`form-control ${sfErrors?.numeroRegistreCommerce ? "is-invalid" : ""}`}
                                                                        placeholder="Entrez le numéro de registre de commerce"
                                                                        name="numeroRegistreCommerce"
                                                                        value={personneData.numeroRegistreCommerce}
                                                                        onChange={handlePersonneChange}
                                                                    />
                                                                    {sfErrors?.numeroRegistreCommerce && (
                                                                        <div className="invalid-feedback">
                                                                            {sfErrors?.numeroRegistreCommerce}
                                                                        </div>)}
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <label className="form-label" htmlFor="nif">
                                                                        Numéro d'identification fiscale *
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        id="nif"
                                                                        className={`form-control ${sfErrors?.nif ? "is-invalid" : ""}`}
                                                                        placeholder="Entrez le NIF"
                                                                        name="nif"
                                                                        value={personneData.nif}
                                                                        onChange={handlePersonneChange}
                                                                    />
                                                                    {sfErrors?.nif && (
                                                                        <div className="invalid-feedback">
                                                                            {sfErrors?.nif}
                                                                        </div>)}
                                                                </div>
                                                            </>)}
                                                        <div className="col-sm-6">
                                                            <label class="form-label" for="telephone">
                                                                Numéro de téléphone *
                                                            </label>
                                                            <div class="input-group">
                                                                <span class="input-group-text">TG (+228)</span>
                                                                <input
                                                                    type="text"
                                                                    id="telephone"
                                                                    class={`form-control phone-number-mask ${sfErrors?.telephone ? "is-invalid" : ""}`}
                                                                    placeholder="00 00 00 00"
                                                                    name="telephone"
                                                                    value={personneData.contact[0]}
                                                                    onChange={handlePersonneChange}
                                                                />
                                                                {sfErrors?.telephone && (
                                                                    <div className="invalid-feedback">
                                                                        {sfErrors?.telephone}
                                                                    </div>)}
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <div class="text-light small fw-medium mb-4">
                                                                Souscripteur
                                                            </div>
                                                            <div>
                                                                <Switch
                                                                    checkedChildren="OUI"
                                                                    unCheckedChildren="NON"
                                                                    value={exonereDeTaxe}
                                                                    onChange={(e) => {
                                                                        setExonereDeTaxe(e);
                                                                        if (e) {
                                                                            setTaxe(0);
                                                                        } else {
                                                                            updateTaxes();
                                                                        }
                                                                    }}
                                                                />
                                                                <span class="switch-label">
                                    {" "}
                                                                    Exonéré de taxe?
                                  </span>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <div class="text-light small fw-medium mb-4">
                                                                Assuré différent du souscripteur ?
                                                            </div>
                                                            <div>
                                                                <Switch
                                                                    checkedChildren="OUI"
                                                                    unCheckedChildren="NON"
                                                                    value={differentSouscripteur}
                                                                    onChange={(e) => {
                                                                        e ? setInsuredData(personneData) : setInsuredData({});
                                                                        setDifferentSouscripteur(e);
                                                                    }}
                                                                />
                                                                <span class="switch-label">
                                    {" "}
                                                                    Préciser un assuré différent du souscripteur?
                                  </span>
                                                            </div>
                                                        </div>
                                                        {differentSouscripteur ? <div className="col-sm-4">
                                                            <button
                                                                className="btn btn-info"
                                                                onClick={() => {
                                                                    setShowAddInsuredModal(true);
                                                                }}
                                                            >
                                                                <i className="bx bx-user-plus bx-sm ms-sm-n2 me-sm-2"></i>
                                                                <span
                                                                    className="align-middle d-sm-inline-block d-none">
                                    Préciser l'assuré
                                  </span>
                                                            </button>
                                                        </div> : null}
                                                        <div className="col-12 d-flex justify-content-between">
                                                            <button
                                                                className="btn btn-primary"
                                                                onClick={() => {
                                                                    setActiveTab(2);
                                                                }}
                                                            >
                                                                <i className="bx bx-left-arrow-alt bx-sm ms-sm-n2 me-sm-2"></i>
                                                                <span
                                                                    className="align-middle d-sm-inline-block d-none">
                                    Précédent
                                  </span>
                                                            </button>
                                                            <button
                                                                className="btn btn-success"
                                                                onClick={(e) => {
                                                                    console.log("Current errors: ", sfErrors);
                                                                    validateSouscripteurForm() && handleSubmit(e);
                                                                }}
                                                                disabled={loading}
                                                            >
                                                                {loading && (<span
                                                                        class="spinner-grow me-1"
                                                                        role="status"
                                                                        aria-hidden="true"
                                                                    ></span>)}
                                                                <span
                                                                    className="align-middle d-sm-inline-block d-none me-sm-2">
                                    {loading ? `Enregistrement...` : `Enregistrer`}
                                  </span>
                                                                <i className="bx bx-right-arrow-alt bx-sm me-sm-n2"></i>
                                                            </button>
                                                        </div>
                                                    </div>}
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <!-- / Content --> */}

                        {/* <!-- Footer --> */}
                        <Footer/>
                        {/* <!-- / Footer --> */}

                        <div class="content-backdrop fade"></div>
                    </div>
                    {/* <!-- Content wrapper --> */}
                </div>
                {/* <!-- / Layout page --> */}
            </div>
            <Modal
                show={showAddPersonModal}
                handleCancel={handleClose}
                size="lg"
                centered
            >
                <Modal.Body>
                    <div className="text-center mb-6">
                        <h4 className="address-title mb-2">Ajouter une personne</h4>
                        <p className="address-subtitle">
                            Préciser le conducteur habituel, si différent du souscripteur
                        </p>
                    </div>
                    <form id="addNewAddressForm" className="row g-12" onSubmit={onSubmit}>
                        <div className="col-12 col-md-12">
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <label class="form-label" for="nom">
                                        Nom *
                                    </label>
                                    <input
                                        type="text"
                                        id="nom"
                                        name="nom"
                                        className={`form-control ${ifErrors?.nom ? "is-invalid" : ""}`}
                                        placeholder="Entrez le nom de l'assuré"
                                        onChange={(e) => handleAssureChange(e, personneId)}
                                        value={formData[personneId].personne.nom}
                                    />
                                    {ifErrors?.nom && (<div className="invalid-feedback">
                                            {ifErrors?.nom}
                                        </div>)}
                                </div>
                                <div class="col-12 col-md-6">
                                    <label class="form-label" for="prenom">
                                        Prénom(s) *
                                    </label>
                                    <input
                                        type="text"
                                        id="prenom"
                                        name="prenom"
                                        className={`form-control ${ifErrors?.prenom ? "is-invalid" : ""}`}
                                        placeholder="Entrez le prénom de l'assuré"
                                        onChange={(e) => handleAssureChange(e, personneId)}
                                        value={formData[personneId].personne.prenom}
                                    />
                                    {ifErrors?.prenom && (<div className="invalid-feedback">
                                            {ifErrors?.prenom}
                                        </div>)}
                                </div>
                                <div class="col-12 col-md-6">
                                    <label class="form-label" for="genre">
                                        Sexe *
                                    </label>
                                    <select
                                        id="genre"
                                        name="genre"
                                        className={`form-control ${ifErrors?.genre ? "is-invalid" : ""}`}
                                        onChange={(e) => handleAssureChange(e, personneId)}
                                        value={formData[personneId].personne.genre}
                                    >
                                        <option value="MASCULIN">MASCULIN</option>
                                        <option value="FEMININ">FEMININ</option>
                                    </select>
                                    {ifErrors?.genre && (<div className="invalid-feedback">
                                            {ifErrors?.genre}
                                        </div>)}
                                </div>
                                <div class="col-12 col-md-6">
                                    <label class="form-label" for="profession">
                                        Profession
                                    </label>
                                    <select
                                        id="profession"
                                        name="profession"
                                        className={`form-control ${ifErrors?.profession ? "is-invalid" : ""}`}
                                        onChange={(e) => handleAssureChange(e, personneId)}
                                        value={formData[personneId].personne.profession}
                                    >
                                        <option value="">Selectionnez la profession</option>
                                        {professions.map((prof, id) => (<option key={id} value={prof.libelleParametre}>
                                                {prof.libelleParametre}
                                            </option>))}
                                    </select>
                                    {ifErrors?.profession && (<div className="invalid-feedback">
                                            {ifErrors?.profession}
                                        </div>)}
                                </div>
                                <div class="col-12 col-md-6">
                                    <label class="form-label" for="email">
                                        Email
                                    </label>
                                    <input
                                        type="text"
                                        id="email"
                                        name="email"
                                        className={`form-control ${ifErrors?.email ? "is-invalid" : ""}`}
                                        placeholder="Entrez l'adresse mail"
                                        onChange={(e) => handleAssureChange(e, personneId)}
                                        value={formData[personneId].personne.email}
                                    />
                                    {ifErrors?.email && (<div className="invalid-feedback">
                                            {ifErrors?.email}
                                        </div>)}
                                </div>
                                <div class="col-12 col-md-6">
                                    <label class="form-label" for="adresse">
                                        Adresse de résidence *
                                    </label>
                                    <input
                                        type="text"
                                        id="adresse"
                                        name="adresse"
                                        className={`form-control ${ifErrors?.adresse ? "is-invalid" : ""}`}
                                        placeholder="Entrez l'adresse de résidence"
                                        onChange={(e) => handleAssureChange(e, personneId)}
                                        value={formData[personneId].personne.adresse}
                                    />
                                    {ifErrors?.adresse && (<div className="invalid-feedback">
                                            {ifErrors?.adresse}
                                        </div>)}
                                </div>
                                <div class="col-12 col-md-6">
                                    <label class="form-label" for="phone">
                                        Numéro de téléphone *
                                    </label>
                                    <div class="input-group">
                                        <span class="input-group-text">TG (+228)</span>
                                        <input
                                            type="tel"
                                            id="telephone"
                                            name="telephone"
                                            class={`form-control phone-number-mask ${ifErrors?.telephone ? "is-invalid" : ""}`}
                                            placeholder="00000000"
                                            onChange={(e) => handleAssureChange(e, personneId)}
                                            value={formData[personneId].personne.telephone}
                                        />
                                        {ifErrors?.telephone && (<div className="invalid-feedback">
                                                {ifErrors?.telephone}
                                            </div>)}
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <label class="form-label" for="typePiece">
                                        Type de pièce *
                                    </label>
                                    <select
                                        id="typePiece"
                                        name="typePiece"
                                        className={`form-control ${ifErrors?.adresse ? "is-invalid" : ""}`}
                                        data-allow-clear="true"
                                        onChange={(e) => handleAssureChange(e, personneId)}
                                        value={formData[personneId].personne.typePiece}
                                    >
                                        <option value="CNI">CNI</option>
                                        <option value="PASSEPORT">PASSEPORT</option>
                                        <option value="PERMIS">PERMIS DE CONDUIRE</option>
                                        <option value="ELECTEUR">CARTE D'ELECTEUR</option>
                                    </select>
                                    {ifErrors?.typePiece && (<div className="invalid-feedback">
                                            {ifErrors?.typePiece}
                                        </div>)}
                                </div>
                                <div class="col-12 col-md-6">
                                    <label class="form-label" for="numIdentite">
                                        Numéro de la pièce d'identité *
                                    </label>
                                    <input
                                        type="text"
                                        id="numIdentite"
                                        name="numIdentite"
                                        className={`form-control ${ifErrors?.numIdentite ? "is-invalid" : ""}`}
                                        placeholder="Entrez le numéro de la pièce"
                                        onChange={(e) => handleAssureChange(e, personneId)}
                                        value={formData[personneId].personne.numIdentite}
                                    />
                                    {ifErrors?.numIdentite && (<div className="invalid-feedback">
                                            {ifErrors?.numIdentite}
                                        </div>)}
                                </div>
                                <div className="col-12 col-md-6">
                                    <label className="form-label" htmlFor="dateDelivrance">
                                        Date de délivrance *
                                    </label>
                                    <div class="col-md-12">
                                        <input
                                            className={`form-control ${ifErrors?.dateDelivrance ? "is-invalid" : ""}`}
                                            type="date"
                                            id="dateDelivrance"
                                            name="dateDelivrance"
                                            onChange={(e) => handleAssureChange(e, personneId)}
                                            value={formData[personneId].personne.dateDelivrance}
                                        />
                                        {ifErrors?.dateDelivrance && (<div className="invalid-feedback">
                                                {ifErrors?.dateDelivrance}
                                            </div>)}
                                    </div>
                                </div>
                                <div class="col-12 text-center">
                                    <br/>
                                    <button
                                        onClick={() => validateInsuredForm(formData[personneId].personne) && handleClose()}
                                        class="btn btn-primary me-3"
                                    >
                                        Enregistrer
                                    </button>
                                    <button
                                        type="reset"
                                        class="btn btn-label-secondary"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={() => handleClose()}
                                    >
                                        Annuler
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal
                show={showAddInsuredModal}
                handleCancel={handleInsuredClose}
                size="lg"
                centered
            >
                <Modal.Body>
                    <div className="text-center mb-6">
                        <h4 className="address-title mb-2">Ajouter une personne</h4>
                        <p className="address-subtitle">
                            Préciser l'assuré, si différent du souscripteur
                        </p>
                    </div>
                    <form id="addNewAddressForm" className="row g-12" onSubmit={onSubmit}>
                        <div className="col-12 col-md-12">
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <label class="form-label" for="nom">
                                        Nom *
                                    </label>
                                    <input
                                        type="text"
                                        id="nom"
                                        name="nom"
                                        className={`form-control ${insuredFErrors?.nom ? "is-invalid" : ""}`}
                                        placeholder="Entrez le nom de l'assuré"
                                        onChange={(e) => handleInsuredChange(e)}
                                        value={insuredData.nom}
                                    />
                                    {insuredFErrors?.nom && (<div className="invalid-feedback">
                                            {insuredFErrors?.nom}
                                        </div>)}
                                </div>
                                <div class="col-12 col-md-6">
                                    <label class="form-label" for="prenom">
                                        Prénom(s) *
                                    </label>
                                    <input
                                        type="text"
                                        id="prenom"
                                        name="prenom"
                                        className={`form-control ${insuredFErrors?.prenom ? "is-invalid" : ""}`}
                                        placeholder="Entrez le prénom de l'assuré"
                                        onChange={(e) => handleInsuredChange(e)}
                                        value={insuredData.prenom}
                                    />
                                    {insuredFErrors?.prenom && (<div className="invalid-feedback">
                                            {insuredFErrors?.prenom}
                                        </div>)}
                                </div>
                                <div class="col-12 col-md-6">
                                    <label class="form-label" for="genre">
                                        Sexe *
                                    </label>
                                    <select
                                        id="genre"
                                        name="genre"
                                        className={`form-control ${insuredFErrors?.genre ? "is-invalid" : ""}`}
                                        onChange={(e) => handleInsuredChange(e)}
                                        value={insuredData.genre}
                                    >
                                        <option value="MASCULIN">MASCULIN</option>
                                        <option value="FEMININ">FEMININ</option>
                                    </select>
                                    {insuredFErrors?.genre && (<div className="invalid-feedback">
                                            {insuredFErrors?.genre}
                                        </div>)}
                                </div>
                                <div class="col-12 col-md-6">
                                    <label class="form-label" for="profession">
                                        Profession
                                    </label>
                                    <select
                                        id="profession"
                                        name="profession"
                                        className={`form-control ${insuredFErrors?.profession ? "is-invalid" : ""}`}
                                        onChange={(e) => handleInsuredChange(e)}
                                        value={insuredData.profession}
                                    >
                                        <option value="">Selectionnez la profession</option>
                                        {professions.map((prof, id) => (<option key={id} value={prof.libelleParametre}>
                                                {prof.libelleParametre}
                                            </option>))}
                                    </select>
                                    {insuredFErrors?.profession && (<div className="invalid-feedback">
                                            {insuredFErrors?.profession}
                                        </div>)}
                                </div>
                                <div class="col-12 col-md-6">
                                    <label class="form-label" for="email">
                                        Email
                                    </label>
                                    <input
                                        type="text"
                                        id="email"
                                        name="email"
                                        className={`form-control ${insuredFErrors?.email ? "is-invalid" : ""}`}
                                        placeholder="Entrez l'adresse mail"
                                        onChange={(e) => handleInsuredChange(e)}
                                        value={insuredData.email}
                                    />
                                    {insuredFErrors?.email && (<div className="invalid-feedback">
                                            {insuredFErrors?.email}
                                        </div>)}
                                </div>
                                <div class="col-12 col-md-6">
                                    <label class="form-label" for="adresse">
                                        Adresse de résidence *
                                    </label>
                                    <input
                                        type="text"
                                        id="adresse"
                                        name="adresse"
                                        className={`form-control ${insuredFErrors?.adresse ? "is-invalid" : ""}`}
                                        placeholder="Entrez l'adresse de résidence"
                                        onChange={(e) => handleInsuredChange(e)}
                                        value={insuredData.adresse}
                                    />
                                    {insuredFErrors?.adresse && (<div className="invalid-feedback">
                                            {insuredFErrors?.adresse}
                                        </div>)}
                                </div>
                                <div class="col-12 col-md-6">
                                    <label class="form-label" for="phone">
                                        Numéro de téléphone *
                                    </label>
                                    <div class="input-group">
                                        <span class="input-group-text">TG (+228)</span>
                                        <input
                                            type="tel"
                                            id="telephone"
                                            name="telephone"
                                            class={`form-control phone-number-mask ${insuredFErrors?.telephone ? "is-invalid" : ""}`}
                                            placeholder="00000000"
                                            onChange={(e) => handleInsuredChange(e)}
                                            value={insuredData.telephone}
                                        />
                                        {insuredFErrors?.telephone && (<div className="invalid-feedback">
                                                {insuredFErrors?.telephone}
                                            </div>)}
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <label class="form-label" for="typePiece">
                                        Type de pièce *
                                    </label>
                                    <select
                                        id="typePiece"
                                        name="typePiece"
                                        className={`form-control ${insuredFErrors?.adresse ? "is-invalid" : ""}`}
                                        data-allow-clear="true"
                                        onChange={(e) => handleInsuredChange(e)}
                                        value={insuredData.typePiece}
                                    >
                                        <option value="CNI">CNI</option>
                                        <option value="PASSEPORT">PASSEPORT</option>
                                        <option value="PERMIS">PERMIS DE CONDUIRE</option>
                                        <option value="ELECTEUR">CARTE D'ELECTEUR</option>
                                    </select>
                                    {insuredFErrors?.typePiece && (<div className="invalid-feedback">
                                            {insuredFErrors?.typePiece}
                                        </div>)}
                                </div>
                                <div class="col-12 col-md-6">
                                    <label class="form-label" for="numIdentite">
                                        Numéro de la pièce d'identité *
                                    </label>
                                    <input
                                        type="text"
                                        id="numIdentite"
                                        name="numIdentite"
                                        className={`form-control ${insuredFErrors?.numIdentite ? "is-invalid" : ""}`}
                                        placeholder="Entrez le numéro de la pièce"
                                        onChange={(e) => handleInsuredChange(e)}
                                        value={insuredData.numIdentite}
                                    />
                                    {insuredFErrors?.numIdentite && (<div className="invalid-feedback">
                                            {insuredFErrors?.numIdentite}
                                        </div>)}
                                </div>
                                <div className="col-12 col-md-6">
                                    <label className="form-label" htmlFor="dateDelivrance">
                                        Date de délivrance *
                                    </label>
                                    <div class="col-md-12">
                                        <input
                                            className={`form-control ${insuredFErrors?.dateDelivrance ? "is-invalid" : ""}`}
                                            type="date"
                                            id="dateDelivrance"
                                            name="dateDelivrance"
                                            onChange={(e) => handleInsuredChange(e)}
                                            value={insuredData.dateDelivrance}
                                        />
                                        {insuredFErrors?.dateDelivrance && (<div className="invalid-feedback">
                                                {insuredFErrors?.dateDelivrance}
                                            </div>)}
                                    </div>
                                </div>
                                <div class="col-12 text-center">
                                    <br/>
                                    <button
                                        onClick={() => validateDifferentInsuredForm(insuredData) && handleInsuredClose()}
                                        class="btn btn-primary me-3"
                                    >
                                        Enregistrer
                                    </button>
                                    <button
                                        type="reset"
                                        class="btn btn-label-secondary"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={() => handleInsuredClose()}
                                    >
                                        Annuler
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
            {/* <!-- Overlay --> */}
            <div class="layout-overlay layout-menu-toggle"></div>
            {formData.length > 1 ? <div class="buy-now">
                <button onClick={() => copyDatas()} class="btn btn-warning btn-buy-now">
                    Copier les données
                </button>
            </div> : null}
            {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
            <div class="drag-target"></div>
        </div>);
};

export default AddContrat;
