import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, ConfigProvider, Input, Space, Spin, Table } from "antd";
import frFR from "antd/locale/fr_FR";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import NavBar from "../../components/NavBar";
import SideBar from "../../components/SideBar";
import axiosInstance from "../../variables/api/axiosInstance";
import { API_URL, TOKEN } from "../../variables/constants";

const Assures = () => {
  const [clients, setClients] = useState([]);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [loadingClients, setLoadingClients] = useState(true);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const handleChange = (pagination, filters, sorter) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Rechercher ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Rechercher...
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reinitialiser
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filtrer
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Fermer
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const navigate = useNavigate();

  const columns = [
    {
      title: "Nom & Prénom(s)",
      dataIndex: "nom",
      key: "1",
      ...getColumnSearchProps("nom"),
      render: (_, record) => (
        <>
          {record.nom} {record.prenom}{" "}
        </>
      ),
    },
    {
      title: "Numéro Assuré",
      dataIndex: "numeroAssure",
      key: "2",
      ...getColumnSearchProps("numeroAssure"),
    },
    {
      title: "Contact",
      dataIndex: "contact",
      key: "3",
      ...getColumnSearchProps("contact"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "4",
    },
    {
      title: "Adresse",
      dataIndex: "adresse",
      key: "5",
    },
  ];

  const fetchClients = async (page, limit, search) => {
    setLoadingClients(true);
    try {
      await axiosInstance
        .get(`/api/personne/clients`, {
          params: {
            page: page - 1,
            size: limit,
            search: search,
          },
        })
        .then((res) => {
          let ctrs = [];
          console.log("contrats: ", res.data);
          res.data.content.map((client, key) => {
            ctrs.push({
              id: key + 1,
              nom: client.nom,
              prenom: client.prenom,
              contact: client.contact[0]
                ? client.contact[1]
                  ? `${client.contact[0]} - ${client.contact[1]}`
                  : `${client.contact[0]}`
                : "",
              email: client.email,
              numeroAssure: client.numeroAssureP,
              adresse: client.adresse,
              data: client,
              codeId: client.codeId,
            });
          });

          ctrs.sort().reverse();
          setClients(ctrs);
          setLoadingClients(false);

          setTotal(res.data.totalElements);
        });
    } catch (error) {
      setLoadingClients(false);
      console.error("Error fetching contracts:", error);
    } finally {
      setLoadingClients(false);
    }
  };

  useEffect(() => {
    fetchClients(currentPage, pageSize, "");
  }, [currentPage, pageSize]);

  return (
    <div class="layout-wrapper layout-content-navbar  ">
      <div class="layout-container">
        <SideBar currentMenu={"ASSURES"} />

        {/* <!-- Layout container --> */}
        <div class="layout-page">
          <NavBar />

          {/* <!-- Content wrapper --> */}
          <div class="content-wrapper">
            {/* <!-- Content --> */}
            <div class="container-xxl flex-grow-1 container-p-y">
              {/* <!-- Users List Table --> */}
              {/* <div class="row">
                <div class="col-sm-4">
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    style={{ marginBottom: 16 }}
                    onClick={() => navigate("/ajouter-contrat")}
                  >
                    Nouveau client
                  </Button>
                </div>
              </div> */}
              <div class="card">
                <ConfigProvider locale={frFR}>
                  <Spin spinning={loadingClients}>
                    <Table
                      columns={columns}
                      dataSource={clients}
                      onChange={handleChange}
                      pagination={{
                        current: currentPage,
                        pageSize: pageSize,
                        total: total,
                        showSizeChanger: true,
                      }}
                    />
                  </Spin>
                </ConfigProvider>
              </div>
            </div>
            {/* <!-- / Content --> */}

            {/* <!-- Footer --> */}
            <Footer />
            {/* <!-- / Footer --> */}

            <div class="content-backdrop fade"></div>
          </div>
          {/* <!-- Content wrapper --> */}
        </div>
        {/* <!-- / Layout page --> */}
      </div>

      {/* <!-- Overlay --> */}
      <div class="layout-overlay layout-menu-toggle"></div>

      {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
      <div class="drag-target"></div>
    </div>
  );
};

export default Assures;
