import { ExclamationCircleFilled, PlusOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Footer from "../../../components/Footer";
import NavBar from "../../../components/NavBar";
import SideBar from "../../../components/SideBar";
import { API_URL, TOKEN } from "../../../variables/constants";
import AddAvenant from "./AddAvenant";

const Avenants = () => {
  const [avenants, setAvenants] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedAvenant, setSelectedAvenant] = useState(null);

  useEffect(() => {
    const fetchAvenants = async () => {
      try {
        const res = await axios.get(`${API_URL}/api/avenant`, {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
          },
        });
        setAvenants(res.data);
      } catch (err) {
        console.error(err);
        toast.error("Erreur lors du chargement des avenants");
      }
    };

    fetchAvenants();
  }, [showModal]);

  const showDeleteConfirm = (avenantId) => {
    Modal.confirm({
      title: "Êtes-vous sûr de vouloir supprimer cet avenant ?",
      icon: <ExclamationCircleFilled />,
      content: "Cette action est irréversible.",
      okText: "Oui",
      okType: "danger",
      cancelText: "Annuler",
      onOk() {
        deleteAvenant(avenantId);
      },
      onCancel() {
        console.log("Annulation de la suppression");
      },
    });
  };

  const deleteAvenant = async (avenantId) => {
    try {
      await axios.delete(`${API_URL}/api/avenant/${avenantId}`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
        },
      });
      setAvenants(avenants.filter((av) => av.avenantId !== avenantId));
      toast.success("Avenant supprimé avec succès");
    } catch (err) {
      console.error(err);
      toast.error("Erreur lors de la suppression");
    }
  };

  const handleShow = (edit, avenant) => {
    setEditMode(edit);
    setSelectedAvenant(avenant);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setSelectedAvenant(null);
  };

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <SideBar />
        <div className="layout-page">
          <NavBar />
          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              <Button
                type="primary"
                icon={<PlusOutlined />}
                style={{ marginBottom: 16 }}
                onClick={() => handleShow(false, null)}
              >
                Ajouter un nouvel avenant
              </Button>
              <div className="card">
                <h5 className="card-header">Les différents avenants</h5>
                <div className="table-responsive text-nowrap">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Nom</th>
                        <th>Code</th>
                        <th>Description</th>
                        <th>Pénalité</th>
                        <th>Accessoires</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody className="table-border-bottom-0">
                      {avenants.map((av, id) => (
                        <tr key={id}>
                          <td>{av.nom}</td>
                          <td>{av.code}</td>
                          <td>{av.description}</td>
                          <td>{av.penalite ? av.penalite : 1}</td>
                          <td>
                            {av.accessoires
                              ? new Intl.NumberFormat("en-DE").format(
                                  av.accessoires,
                                )
                              : 0.0}
                          </td>
                          <td>
                            <Button
                              type="default"
                              onClick={() => handleShow(true, av)}
                              style={{ marginRight: 8 }}
                            >
                              Modifier
                            </Button>
                            <Button
                              type="danger"
                              onClick={() => showDeleteConfirm(av.avenantId)}
                            >
                              Supprimer
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
      <AddAvenant
        show={showModal}
        handleClose={handleClose}
        editMode={editMode}
        selectedAvenant={selectedAvenant}
      />
    </div>
  );
};

export default Avenants;
