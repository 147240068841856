import * as Yup from "yup";

export const getAssureValidationSchema = (wasContractantConducteur) =>
    Yup.object().shape({
        contrat: Yup.object().required("Le Contrat est obligatoire."),
        engin: wasContractantConducteur
            ? Yup.mixed().notRequired()
            : Yup.object().required("L'Engin est obligatoire."),
        dateSinistre: Yup.date()
            .required("Le champ 'Date de sinistre' est obligatoire.")
            .typeError("Veuillez entrer une date valide pour 'Date de sinistre'."),
        conduisait: Yup.string()
            .oneOf(["oui", "non"], "Veuillez sélectionner une option valide pour 'Conduisait'.")
            .required("Le champ 'Conduisait' est obligatoire."),
        conducteur: wasContractantConducteur
            ? Yup.mixed().notRequired()
            : Yup.object().shape({
                nom: Yup.string().required("Le nom du conducteur est obligatoire."),
                prenom: Yup.string().required("Le prénom du conducteur est obligatoire."),
                telephone: Yup.string().required("Le numero de téléphone est obligatoire"),
                numeroPermis: Yup.string().nullable(),
                dateNaissance: Yup.date().nullable(),
                categoriePermis: Yup.string().nullable(),
                lieuDelivrance: Yup.string().nullable(),
                dateDelivrance: Yup.date().nullable(),
            }),
        typeSinistre: Yup.string().required("Le champ 'Type de sinistre' est obligatoire."),
    });


export const getAdversaireValidationSchema = (isPieton, wasAdversaireConducteur) => {

    return Yup.object().shape({
        items: Yup.array().of(
            Yup.object().shape({
                // Common fields for all cases
                nom: Yup.string().required("Le nom de l'adversaire est obligatoire."),
                prenom: Yup.string().required("Le prénom de l'adversaire est obligatoire."),
                profession: Yup.string().nullable(),
                adresse: Yup.string().required("L'adresse de l'adversaire est obligatoire."),
                telephone: Yup.string().required("Le numero de telephone de l'adversaire est obligatoire."),
                typeSinistre: Yup.string().required("Le type de sinistre est obligatoire."),

                // Engin-related fields, conditionally required if not a pedestrian
                engin: isPieton
                    ? Yup.mixed().notRequired()
                    : Yup.object().shape({
                        marque: Yup.string().required("La marque est obligatoire."),
                        puissance: Yup.number().nullable(),
                        immatriculation: Yup.string().required("L'immatriculation est obligatoire."),
                        usage: Yup.string().nullable(),
                    }),

                // Conducteur fields, dynamically configured based on `wasAdversaireConducteur`
                conducteur: isPieton
                    ? Yup.mixed().notRequired()
                    : wasAdversaireConducteur
                        ? Yup.object().shape({
                            numeroPermis: Yup.string().nullable(),
                            categoriePermis: Yup.string().nullable(),
                            dateDelivrance: Yup.date().nullable(),
                            lieuDelivrance: Yup.string().nullable(),
                            dateExpiration: Yup.date().nullable(),
                            qualite: Yup.string().nullable(),
                        })
                        : Yup.object().shape({
                            nom: Yup.string().required("Le nom du conducteur est obligatoire."),
                            prenom: Yup.string().required("Le prénom du conducteur est obligatoire."),
                            telephone: Yup.string().required("Le numero de téléphone du conducteur est obligatoire."),
                            numeroPermis: Yup.string().nullable(),
                            categoriePermis: Yup.string().nullable(),
                            dateDelivrance: Yup.date().nullable(),
                            lieuDelivrance: Yup.string().nullable(),
                            dateExpiration: Yup.date().nullable(),
                            qualite: Yup.string().nullable(),
                        }),

                societeAssurance: isPieton
                    ? Yup.mixed().notRequired()
                    : Yup.string().nullable(),
                provenance: isPieton
                    ? Yup.mixed().notRequired()
                    : Yup.string().required("La provenance est obligatoire."),
                destination: isPieton
                    ? Yup.mixed().notRequired()
                    : Yup.string().required("La destination est obligatoire."),
                vitesse: isPieton
                    ? Yup.mixed().notRequired()
                    : Yup.number()
                        .nullable()
                        .min(0, "La vitesse doit être supérieure ou égale à 0."),
                nombrePersonneTransportees: isPieton
                    ? Yup.mixed().notRequired()
                    : Yup.number()
                        .nullable()
                        .min(0, "Le nombre de personnes transportées doit être supérieure ou égale à 0."),
            })
        ),
    });
}
// Function to return the validation schema based on `temoignage`
export const getAccidentValidationSchema = (hasTestimony) =>
    Yup.object().shape({
        lieu: Yup.string().required("Le lieu est obligatoire."),
        provenanceAssure: Yup.string().required("La provenance est obligatoire."),
        destinationAssure: Yup.string().required("La destination est obligatoire."),
        vitesseAssure: Yup.number()
            .required("La vitesse est obligatoire.")
            .min(0, "La vitesse doit être supérieure ou égale à 0."),
        temoignage: Yup.string()
            .oneOf(["oui", "non"], "Veuillez sélectionner une option valide pour 'Témoignage'.")
            .required("Le champ 'Témoignage' est obligatoire."),
        temoin: hasTestimony
            ? Yup.object().shape({
                nom: Yup.string().required("Le nom du témoin est obligatoire."),
                prenom: Yup.string().required("Le prénom du témoin est obligatoire."),
                adresse: Yup.string().required("L'adresse du témoin est obligatoire."),
                fonction: Yup.string().nullable(),
                telephone: Yup.string().required('Le numero de telephone du temoin est réquis'),
            })
            : Yup.mixed().notRequired(),
        circonstance: Yup.string().required("La circonstance est obligatoire."),
        croquis: Yup.string().nullable(),
        observationAgent: Yup.string().nullable(),
        entiteConstat: Yup.object().required("L'entité du constat est obligatoire."),
    });


export const getVictimeValidationSchema = () => {
    return Yup.object().shape({
        items: Yup.array().of(
            Yup.object().shape({
                nom: Yup.string().required("Le nom est obligatoire."),
                prenom: Yup.string().required("Le prénom est obligatoire."),
                telephone: Yup.string().required("Le numero de téléphone est obligatoire."),
                profession: Yup.string().nullable(),
                adresse: Yup.string().required("L'adresse est obligatoire."),
                descriptionDegatsVictime: Yup.string().nullable(),

            })
        ),
    });
}

export const getNewVictimeValidationSchema = () => {
    return Yup.object().shape({
        nom: Yup.string().required("Le nom est obligatoire."),
        prenom: Yup.string().required("Le prénom est obligatoire."),
        telephone: Yup.string().required("Le numero de téléphone est obligatoire."),
        profession: Yup.string().nullable(),
        adresse: Yup.string().required("L'adresse est obligatoire."),
        descriptionDegatsVictime: Yup.string().nullable()
    });
}


const getFieldPath = (fieldName) => `items[0].${fieldName}`;
const getEnginNestedFieldPath = (fieldName) => `items[0].engin.${fieldName}`;
const getConducteurNestedFieldPath = (fieldName) => `items[0].conducteur.${fieldName}`;


export const getAdversaireValidationSchemaByStep = (isPieton, wasAdversaireConducteur) => [
    // Step 1: Personal Information
    Yup.object().shape({
        items: Yup.array().of(
            Yup.object().shape({
                nom: Yup.string().required("Le nom de l'adversaire est obligatoire."),
                prenom: Yup.string().required("Le prénom de l'adversaire est obligatoire."),
                profession: Yup.string().nullable(),
                adresse: Yup.string().required("L'adresse de l'adversaire est obligatoire."),
                telephone: Yup.string().required("Le numéro de téléphone de l'adversaire est obligatoire."),
                typeSinistre: Yup.string().required("Le type de sinistre est obligatoire."),
            })
        ),
    }),

    // Step 2: Vehicle Information
    Yup.object().shape({
        items: Yup.array().of(
            Yup.object().shape({
                engin: isPieton
                    ? Yup.mixed().notRequired()
                    : Yup.object().shape({
                        marque: Yup.string().required("La marque est obligatoire."),
                        puissance: Yup.number()
                            .nullable()
                            .typeError("La puissance doit être un nombre.")
                            .positive("La puissance doit être positive."),
                        immatriculation: Yup.string().required("L'immatriculation est obligatoire."),
                        usage: Yup.string().nullable(),
                    }),
            })
        ),
    }),

    // Step 3: Driver Information
    Yup.object().shape({
        items: Yup.array().of(
            Yup.object().shape({
                conducteur: isPieton
                    ? Yup.mixed().notRequired()
                    : wasAdversaireConducteur
                        ? Yup.object().shape({
                            numeroPermis: Yup.string().nullable(),
                            categoriePermis: Yup.string().nullable(),
                            dateDelivrance: Yup.date().nullable(),
                            lieuDelivrance: Yup.string().nullable(),
                            dateExpiration: Yup.date().nullable(),
                            qualite: Yup.string().nullable(),
                        })
                        : Yup.object().shape({
                            nom: Yup.string().required("Le nom du conducteur est obligatoire."),
                            prenom: Yup.string().required("Le prénom du conducteur est obligatoire."),
                            telephone: Yup.string().required("Le numéro de téléphone du conducteur est obligatoire."),
                            numeroPermis: Yup.string().nullable(),
                            categoriePermis: Yup.string().nullable(),
                            dateDelivrance: Yup.date().nullable(),
                            lieuDelivrance: Yup.string().nullable(),
                            dateExpiration: Yup.date().nullable(),
                            qualite: Yup.string().nullable(),
                        }),
            })
        ),
    }),

    // Step 4: Additional Information
    Yup.object().shape({
        items: Yup.array().of(
            Yup.object().shape({
                societeAssurance: isPieton ? Yup.mixed().notRequired() : Yup.string().nullable(),
                provenance: isPieton
                    ? Yup.mixed().notRequired()
                    : Yup.string().required("La provenance est obligatoire."),
                destination: isPieton
                    ? Yup.mixed().notRequired()
                    : Yup.string().required("La destination est obligatoire."),
                vitesse: isPieton
                    ? Yup.mixed().notRequired()
                    : Yup.number()
                        .nullable()
                        .typeError("La vitesse doit être un nombre.")
                        .min(0, "La vitesse doit être supérieure ou égale à 0."),
            })
        ),
    }),
];





