import { Image } from 'antd';
import PropTypes from "prop-types";
import React from 'react';
import entete from '../../../../assets/img/entete.jpg';
import footer from '../../../../assets/img/footer.png';
import { LOGGED_USER } from '../../../../variables/constants';
import { frenchDate } from '../../../../variables/functions';

const EmissionsNettesCedeesParExerciceEtCategorie = React.forwardRef((props, ref) => {
    const totalJanvier = props?.emissions?.reduce((sum, emission) => sum + emission.janvier, 0);
    const totalFevrier = props?.emissions?.reduce((sum, emission) => sum + emission.fevrier, 0);
    const totalMars = props?.emissions?.reduce((sum, emission) => sum + emission.mars, 0);
    const totalAvril = props?.emissions?.reduce((sum, emission) => sum + emission.avril, 0);
    const totalMai = props?.emissions?.reduce((sum, emission) => sum + emission.mai, 0);
    const totalJuin = props?.emissions?.reduce((sum, emission) => sum + emission.juin, 0);
    const totalJuillet = props?.emissions?.reduce((sum, emission) => sum + emission.juillet, 0);
    const totalAout = props?.emissions?.reduce((sum, emission) => sum + emission.aout, 0);
    const totalSeptembre = props?.emissions?.reduce((sum, emission) => sum + emission.septembre, 0);
    const totalOctobre = props?.emissions?.reduce((sum, emission) => sum + emission.octobre, 0);
    const totalNovembre = props?.emissions?.reduce((sum, emission) => sum + emission.novembre, 0);
    const totalDecembre = props?.emissions?.reduce((sum, emission) => sum + emission.decembre, 0);
    const total = props?.emissions?.reduce((sum, emission) => sum + emission.total, 0);
    return (
        <div ref={ref}>
            <Image
                src={entete}
                style={{
                    width: "100%",
                    height: "auto"
                }}
            />
            <div
                className=""
                style={{
                    padding: "10mm",
                    boxSizing: "border-box"
                }}
            >
                <div className='text-center'>
                    EMISSIONS NETTES CEDEES
                </div>
                <div className='text-center'>
                    EXERCICE {props.exercice}
                </div>
                <div className='text-center'>
                    CATEGORIE : {props.categorie}
                </div>
                <div className='text-center'>
                    Edité le {frenchDate(new Date().toISOString())} par {LOGGED_USER?.personne?.prenom} {LOGGED_USER?.personne?.nom}
                </div>
                <table
                    className="table table-bordered"
                    style={{ width: "100%" }}
                >
                    <thead>
                        <tr>
                            <th>Compagnie</th>
                            <th>Janvier</th>
                            <th>Février</th>
                            <th>Mars</th>
                            <th>Avril</th>
                            <th>Mai</th>
                            <th>Juin</th>
                            <th>Juillet</th>
                            <th>Août</th>
                            <th>Septembre</th>
                            <th>Octobre</th>
                            <th>Novembre</th>
                            <th>Décembre</th>
                            <th>TOTAL</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.emissions.map((emission, index) => (
                            <tr key={index}>
                                <td>{emission.compagnie?.raisonSociale?.toUpperCase()}</td>
                                <td>{new Intl.NumberFormat("en-DE").format(emission.janvier)}</td>
                                <td>{new Intl.NumberFormat("en-DE").format(emission.fevrier)}</td>
                                <td>{new Intl.NumberFormat("en-DE").format(emission.mars)}</td>
                                <td>{new Intl.NumberFormat("en-DE").format(emission.avril)}</td>
                                <td>{new Intl.NumberFormat("en-DE").format(emission.mai)}</td>
                                <td>{new Intl.NumberFormat("en-DE").format(emission.juin)}</td>
                                <td>{new Intl.NumberFormat("en-DE").format(emission.juillet)}</td>
                                <td>{new Intl.NumberFormat("en-DE").format(emission.aout)}</td>
                                <td>{new Intl.NumberFormat("en-DE").format(emission.septembre)}</td>
                                <td>{new Intl.NumberFormat("en-DE").format(emission.octobre)}</td>
                                <td>{new Intl.NumberFormat("en-DE").format(emission.novembre)}</td>
                                <td>{new Intl.NumberFormat("en-DE").format(emission.decembre)}</td>
                                <td>{new Intl.NumberFormat("en-DE").format(emission.total)}</td>
                            </tr>
                        ))}
                        <tr>
                            <td>TOTAL</td>
                            <td>{new Intl.NumberFormat("en-DE").format(totalJanvier)}</td>
                            <td>{new Intl.NumberFormat("en-DE").format(totalFevrier)}</td>
                            <td>{new Intl.NumberFormat("en-DE").format(totalMars)}</td>
                            <td>{new Intl.NumberFormat("en-DE").format(totalAvril)}</td>
                            <td>{new Intl.NumberFormat("en-DE").format(totalMai)}</td>
                            <td>{new Intl.NumberFormat("en-DE").format(totalJuin)}</td>
                            <td>{new Intl.NumberFormat("en-DE").format(totalJuillet)}</td>
                            <td>{new Intl.NumberFormat("en-DE").format(totalAout)}</td>
                            <td>{new Intl.NumberFormat("en-DE").format(totalSeptembre)}</td>
                            <td>{new Intl.NumberFormat("en-DE").format(totalOctobre)}</td>
                            <td>{new Intl.NumberFormat("en-DE").format(totalNovembre)}</td>
                            <td>{new Intl.NumberFormat("en-DE").format(totalDecembre)}</td>
                            <td>{new Intl.NumberFormat("en-DE").format(total)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <Image
                src={footer}
                style={{
                    width: "100%",
                    height: "auto"
                }}
            />
        </div>
    )
});
EmissionsNettesCedeesParExerciceEtCategorie.propTypes = {
    emissions: PropTypes.array,
    exercice: PropTypes.string,
    categorie: PropTypes.string
};
export default EmissionsNettesCedeesParExerciceEtCategorie