import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { API_URL, TOKEN } from "../../../variables/constants";

const AddEtablissement = ({ show, handleClose, editMode, selectedEtablissement }) => {
  const [formData, setFormData] = useState({
    nom: "",
    adresse: "",
    telephone1: "",
    telephone2: "",
    typeEtablissement: "",
  });
  const [loading, setLoading] = useState(false);
  const [typesEtablissement, setTypesEtablissement] = useState([
    "PHARMACIE",
    "CLINIQUE",
    "CHR",
    "CHU",
    "CMS",
    "USP",
    "ATELIER_REPARATION",
  ]);

  useEffect(() => {
    if (editMode && selectedEtablissement) {
        console.log("heyuuu",selectedEtablissement);
        
      setFormData({
        nom: selectedEtablissement.nom,
        adresse: selectedEtablissement.adresse,
        telephone1: selectedEtablissement.contact[0] || "",
        telephone2: selectedEtablissement.contact[1] || "",
        typeEtablissement: selectedEtablissement.typeEtablissement,
      });
    }
  }, [editMode, selectedEtablissement]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const payload = {
        
      nom: formData.nom,
      adresse: formData.adresse,
      contact: [formData.telephone1, formData.telephone2].filter((num) => num),
      typeEtablissement: formData.typeEtablissement,
    };

    console.log("Payload envoyé:", payload);

    const request = editMode
      ? axios.put(`${API_URL}/api/etablissement`, payload, {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
          },
        })
      : axios.post(`${API_URL}/api/etablissement`, payload, {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
          },
        });

    request
      .then(() => {

        toast.success(`Etablissement ${editMode ? "modifié" : "ajouté"} avec succès !`);
        handleClose();
      })
      .catch((err) => {
        console.error("Erreur lors de l'enregistrement:", err);
        toast.error("Erreur lors de l'enregistrement de l'établissement");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Body>
        <button
          type="button"
          className="btn-close btn-primary"
          onClick={handleClose}
          aria-label="Close"
        ></button>
        <div className="text-center mb-4">
          <h4>{editMode ? "Modifier l'établissement" : "Ajouter un établissement"}</h4>
        </div>
        <form className="row g-3" onSubmit={onSubmit}>
          <div className="col-12">
            <label className="form-label" htmlFor="nomField">
              Nom
            </label>
            <input
              type="text"
              id="nomField"
              name="nom"
              value={formData.nom}
              onChange={handleInputChange}
              className="form-control"
              required
            />
          </div>
          <div className="col-12">
            <label className="form-label" htmlFor="adresseField">
              Adresse
            </label>
            <input
              type="text"
              id="adresseField"
              name="adresse"
              value={formData.adresse}
              onChange={handleInputChange}
              className="form-control"
              required
            />
          </div>
          <div className="col-md-6">
            <label className="form-label" htmlFor="telephone1Field">
              Téléphone 1
            </label>
            <input
              type="text"
              id="telephone1Field"
              name="telephone1"
              value={formData.telephone1}
              onChange={handleInputChange}
              className="form-control"
            />
          </div>
          <div className="col-md-6">
            <label className="form-label" htmlFor="telephone2Field">
              Téléphone 2
            </label>
            <input
              type="text"
              id="telephone2Field"
              name="telephone2"
              value={formData.telephone2}
              onChange={handleInputChange}
              className="form-control"
            />
          </div>
          <div className="col-12">
            <label className="form-label" htmlFor="typeEtablissementField">
              Type d'établissement
            </label>
            <select
              id="typeEtablissementField"
              name="typeEtablissement"
              value={formData.typeEtablissement}
              onChange={handleInputChange}
              className="form-control"
              required
            >
              <option value="">Sélectionnez le type d'établissement</option>
              {typesEtablissement.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>
          <div className="col-12 text-center mt-4">
            <button type="submit" className="btn btn-primary me-3" disabled={loading}>
              {loading && <span className="spinner-grow spinner-grow-sm me-1"></span>}
              {loading ? "Enregistrement..." : "Enregistrer"}
            </button>
            <button type="button" className="btn btn-secondary" onClick={handleClose}>
              Annuler
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddEtablissement;
