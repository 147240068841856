import { ExclamationCircleFilled, PlusOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../components/Footer";
import NavBar from "../../components/NavBar";
import SideBar from "../../components/SideBar";
import { rightsService } from "../../services/rightsService";
import { API_URL, LOGGED_USER, TOKEN } from "../../variables/constants";
import { isTomonth } from "../../variables/functions";
const { confirm } = Modal;

const DetailsMembre = () => {
  const [membre, setMembre] = useState("");
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [raisonSociale, setRaisonSociale] = useState("");
  const [responsable, setResponsable] = useState("");
  const [siegeSocial, setSiegeSocial] = useState("");
  const [code, setCode] = useState("");
  const [telephone1, setTelephone1] = useState("");
  const [telephone2, setTelephone2] = useState("");
  const [email, setEmail] = useState("");
  const [typeMembre, setTypeMembre] = useState("ASSURANCE");
  const [otpConnexion, setOtpConnexion] = useState("");
  const [domaine, setDomaine] = useState("");
  const [website, setWebsite] = useState("");
  const [defaultExercice, setDefaultExercice] = useState(0);
  const [dateCreation, setDateCreation] = useState("");
  const [changeLogo, setChangeLogo] = useState(false);
  const [fileImage, setFileImage] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [utilisateurs, setUtilisateurs] = useState([]);
  const [agences, setAgences] = useState([]);
  const [active, setActive] = useState(true);
  const [villes, setVilles] = useState([]);
  const [exercices, setExercices] = useState([]);
  const [codeAgence, setCodeAgence] = useState("");
  const [nomAgence, setNomAgence] = useState("");
  const [villeAgence, setVilleAgence] = useState("");
  const [responsableAgence, setResponsableAgence] = useState("");
  const [telephone1Agence, setTelephone1Agence] = useState("");
  const [telephone2Agence, setTelephone2Agence] = useState("");
  const [activeAgency, setActiveAgency] = useState(false);
  const [typeAgence, setTypeAgence] = useState("AGENCE");
  const [montantPrimes, setMontantPrimes] = useState(0);
  const [montantPrimesMensuelles, setMontantPrimesMensuelles] = useState(0);
  const [contrats, setContrats] = useState([]);

  const param = useParams();
  const navigate = useNavigate();

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  useEffect(() => {
    let codeOrg = "";
    codeOrg = param.organismeCode
      ? param.organismeCode
      : LOGGED_USER.profil.organisme.code;

    axios
      .get(`${API_URL}/api/organisme/code/${codeOrg}`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setMembre(res.data);
        setRaisonSociale(res.data.raisonSociale);
        setSiegeSocial(res.data.siegeSocial);
        setCode(res.data.code);
        setEmail(res.data.email);
        setResponsable(res.data.responsable);

        // Gestion sécurisée des contacts
        if (res.data.contact && Array.isArray(res.data.contact)) {
          setTelephone1(res.data.contact[0] ? res.data.contact[0] : "");
          setTelephone2(res.data.contact[1] ? res.data.contact[1] : "");
        } else {
          setTelephone1("");
          setTelephone2("");
        }

        setTypeMembre(res.data.typeOrganisme);
        setDateCreation(res.data.dateCreation);
        setActive(res.data.active);
        setDomaine(res.data.domaine);
        setWebsite(res.data.website);
        setOtpConnexion(res.data.otpConnexion);
        setDefaultExercice(res.data?.defaultExercice?.exerciceId || 0);

        if (res.data.logo) {
          fetch(
            `${API_URL}/api/organisme/logo/${res.data.logo.split("//")[2]}`,
            {
              headers: {
                Authorization: `Bearer ${TOKEN}`,
                "Content-Type": "application/json",
              },
            },
          )
            .then(async (response) => {
              const imageBytes = await response.arrayBuffer();
              var blob = new Blob([imageBytes], { type: "image/png" });
              var imageUrl = URL.createObjectURL(blob);
              setImagePreviewUrl(imageUrl);
              // Si besoin, on peut reconstruire un File object, mais pas nécessaire pour l'affichage
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          setImagePreviewUrl(null);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${API_URL}/api/exercice`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setExercices(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${API_URL}/api/contrat/organisme/code/${codeOrg}`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setContrats(res.data);
        let pns = 0;
        let monthPns = 0;
        res.data.forEach((cont) => {
          pns += cont.reglements.reduce(
            (pn, reg) => (reg.modePaiement ? pn + reg.montant : pn),
            0,
          );
          monthPns += cont.reglements.reduce(
            (pn, reg) =>
              reg.modePaiement && isTomonth(reg.dateReglement)
                ? pn + reg.montant
                : pn,
            0,
          );
        });
        setMontantPrimes(pns);
        setMontantPrimesMensuelles(monthPns);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [param.organismeCode]);

  // Function to handle image change
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setFileImage(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result); // Set the image preview URL
      };
      reader.readAsDataURL(file); // Convert the file to a Data URL
    }
    setChangeLogo(true);
  };

  const resetImage = () => {
    setImagePreviewUrl(null);
    setChangeLogo(false);
    setFileImage("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    var contacts = [];
    telephone2
      ? contacts.push(telephone1, telephone2)
      : contacts.push(telephone1);
    let myExercice = exercices.find(
      (ex) => ex.exerciceId === parseInt(defaultExercice),
    );

    try {
      if (changeLogo && fileImage) {
        let extension = fileImage?.name?.substring(
          fileImage?.name?.lastIndexOf(".") + 1,
          fileImage?.name?.length,
        );
        let filename = `Logo_${raisonSociale.split(" ").join("-")}.${extension}`;
        formData.append(`fichier`, fileImage, filename);

        axios
          .post(`${API_URL}/api/organisme/logo`, formData, {
            headers: {
              Authorization: `Bearer ${TOKEN}`,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            const organisme = membre;
            organisme.typeOrganisme = typeMembre;
            organisme.raisonSociale = raisonSociale;
            organisme.code = code;
            organisme.contact = contacts;
            organisme.email = email;
            organisme.dateCreation = dateCreation;
            organisme.responsable = responsable;
            organisme.logo = response.data;
            organisme.siegeSocial = siegeSocial;
            organisme.active = active;
            organisme.defaultExercice = myExercice;
            organisme.otpConnexion = otpConnexion;
            organisme.domaine = domaine;
            organisme.website = website;

            axios
              .put(`${API_URL}/api/organisme`, JSON.stringify(organisme), {
                headers: {
                  Authorization: `Bearer ${TOKEN}`,
                  "Content-Type": "application/json",
                },
              })
              .then((res) => {
                toast("Membre mis à jour avec succès!");
                window.location.reload();
              })
              .catch((err) => {
                console.log(err);
                toast("Erreur lors de la mise à jour du membre");
              });
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const organisme = membre;
        organisme.typeOrganisme = typeMembre;
        organisme.raisonSociale = raisonSociale;
        organisme.code = code;
        organisme.contact = contacts;
        organisme.email = email;
        organisme.dateCreation = dateCreation;
        organisme.responsable = responsable;
        organisme.siegeSocial = siegeSocial;
        organisme.active = active;
        organisme.defaultExercice = myExercice;
        organisme.otpConnexion = otpConnexion;
        organisme.domaine = domaine;
        organisme.website = website;

        axios
          .put(`${API_URL}/api/organisme`, JSON.stringify(organisme), {
            headers: {
              Authorization: `Bearer ${TOKEN}`,
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            toast("Membre mis à jour avec succès!");
            navigate(`/membre/${res.data.organismeId}`, { replace: true });
            window.location.reload();
          })
          .catch((err) => {
            console.log(err);
            toast("Erreur lors de la mise à jour des informations du membre");
          });
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    let codeOrg = "";
    codeOrg = param.organismeCode
      ? param.organismeCode
      : LOGGED_USER.profil.organisme.code;

    axios
      .get(`${API_URL}/api/agence/organisme/code/${codeOrg}`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        setAgences(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(
        `${API_URL}/api/organisme/utilisateurs/code/${param.organismeCode}`,
        {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
          },
        },
      )
      .then((res) => {
        setUtilisateurs(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${API_URL}/api/parametre/type/VILLE`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setVilles(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [param.organismeCode]);

  const handleAgencySubmit = (e) => {
    e.preventDefault();

    var contactsAgence = [];
    telephone2Agence
      ? contactsAgence.push(telephone1Agence, telephone2Agence)
      : contactsAgence.push(telephone1Agence);

    const agence = {
      codeAgence: codeAgence,
      contact: contactsAgence,
      responsable: responsableAgence,
      ville: villeAgence,
      restrictionProduction: activeAgency,
      nom: nomAgence,
      organisme: membre,
      typeAgence: typeAgence,
    };

    axios
      .post(`${API_URL}/api/agence`, JSON.stringify(agence), {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        toast("Agence ajoutée avec succès!");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        toast("Erreur lors de l'ajout de l'agence");
      });
  };

  const showActivateConfirm = (agence) => {
    confirm({
      title: `Etes-vous sûr de vouloir ${agence?.restrictionProduction ? "activer" : "désactiver"} cette agence?`,
      icon: <ExclamationCircleFilled />,
      content: "Cela impliquera que toutes les données soient corrompues",
      okText: "Oui",
      okType: "danger",
      cancelText: "Annuler",
      onOk() {
        activateAgency(agence);
      },
    });
  };

  const activateAgency = (agence) => {
    axios
      .get(`${API_URL}/api/agence/activate/${agence.codeId}`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        window.location.reload();
        toast(
          agence?.restrictionProduction
            ? "Agence activée avec succès"
            : "Agence désactivée avec succès",
        );
      })
      .catch((err) => {
        console.log(err);
        toast("Erreur lors de la mise à jour");
      });
  };

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <SideBar currentMenu={"MEMBRES"} />

        <div className="layout-page">
          <NavBar />

          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              <div className="d-flex flex-column flex-sm-row align-items-center justify-content-sm-between mb-6 text-center text-sm-start gap-2">
                <div className="mb-2 mb-sm-0">
                  <h4 className="mb-1">Code membre #{membre?.code}</h4>
                  <p className="mb-0">
                    Date de création : {membre?.dateCreation}
                  </p>
                </div>
                <button
                  type="button"
                  className="btn btn-label-danger delete-customer"
                >
                  Supprimer le membre
                </button>
              </div>

              <div className="row">
                <div className="col-xl-4 col-lg-5 col-md-5 order-1 order-md-0">
                  <div className="card mb-6">
                    <div className="card-body pt-12">
                      <div className="customer-avatar-section">
                        <div className="d-flex align-items-center flex-column">
                          {imagePreviewUrl ? (
                            <img
                              className="img-fluid rounded mb-4"
                              src={imagePreviewUrl}
                              height="120"
                              width="120"
                              alt="User avatar"
                            />
                          ) : (
                            <img
                              className="img-fluid rounded mb-4"
                              src=""
                              height="120"
                              width="120"
                              alt="User avatar"
                            />
                          )}
                          <div className="customer-info text-center mb-6">
                            <h5 className="mb-0">{membre?.raisonSociale}</h5>
                            <span>Code #{membre?.code}</span>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-around flex-wrap mb-6 gap-0 gap-md-3 gap-lg-4">
                        <div className="d-flex align-items-center gap-4 me-5">
                          <div className="avatar">
                            <div className="avatar-initial rounded bg-label-primary">
                              <i className="bx bx-user bx-lg"></i>
                            </div>
                          </div>
                          <div>
                            <h5 className="mb-0">{contrats?.length}</h5>
                            <span>Contrats</span>
                          </div>
                        </div>
                        <div className="d-flex align-items-center gap-4">
                          <div className="avatar">
                            <div className="avatar-initial rounded bg-label-primary">
                              <i className="bx bx-dollar bx-lg"></i>
                            </div>
                          </div>
                          <div>
                            <h5 className="mb-0">
                              {new Intl.NumberFormat("en-DE").format(
                                montantPrimesMensuelles,
                              )}{" "}
                              FCFA
                            </h5>
                            <span>Production du mois</span>
                          </div>
                        </div>
                      </div>

                      <div className="info-container">
                        <h5 className="pb-4 border-bottom text-capitalize mt-6 mb-4">
                          Détails
                        </h5>
                        <ul className="list-unstyled mb-6">
                          <li className="mb-2">
                            <span className="h6 me-1">Nom du responsable:</span>
                            <span>{membre?.responsable}</span>
                          </li>
                          <li className="mb-2">
                            <span className="h6 me-1">Email:</span>
                            <span>{membre?.email}</span>
                          </li>
                          <li className="mb-2">
                            <span className="h6 me-1">Statut:</span>
                            {membre?.active ? (
                              <span className="badge bg-label-success">
                                Actif
                              </span>
                            ) : (
                              <span className="badge bg-label-primary">
                                Inactif
                              </span>
                            )}
                          </li>
                          <li className="mb-2">
                            <span className="h6 me-1">Contacts:</span>
                            <span>
                              {membre?.contact ? membre?.contact[0] : ""}
                            </span>
                            <span>
                              {membre?.contact && membre?.contact[1]
                                ? `; ${membre?.contact[1]}`
                                : ""}
                            </span>
                          </li>

                          <li className="mb-2">
                            <span className="h6 me-1">Siège:</span>
                            <span>{membre?.siegeSocial}</span>
                          </li>
                          <li className="mb-2">
                            <span className="h6 me-1">Site web:</span>
                            <span>{membre?.website}</span>
                          </li>
                          <li className="mb-2">
                            <span className="h6 me-1">Domaine:</span>
                            <span>{membre?.domaine}</span>
                          </li>
                          <li className="mb-2">
                            <span className="h6 me-1">
                              Double Authentification:
                            </span>
                            <span>
                              {membre?.otpConnexion === "NONE"
                                ? "Non"
                                : membre?.otpConnexion}
                            </span>
                          </li>
                          <li className="mb-2">
                            <span className="h6 me-1">
                              Exercice par défaut:
                            </span>
                            <span>
                              {membre?.defaultExercice?.libelleExercice}
                            </span>
                          </li>
                        </ul>
                        {rightsService("HME050") && (
                          <div className="d-flex justify-content-center">
                            <a
                              href="#"
                              onClick={(e) => e.preventDefault()}
                              className="btn btn-primary w-100"
                              data-bs-target="#editUser"
                              data-bs-toggle="modal"
                            >
                              Editer les informations
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-8 col-lg-7 col-md-7 order-0 order-md-1">
                  <div className="nav-align-top">
                    <ul className="nav nav-pills flex-column flex-md-row mb-6">
                      <li className="nav-item">
                        <a
                          className={
                            activeTab === 0 ? "nav-link active" : "nav-link"
                          }
                          href="#apercu"
                          onClick={() => handleTabChange(0)}
                        >
                          <i className="bx bx-detail bx-sm me-1_5"></i>Aperçu
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={
                            activeTab === 1 ? "nav-link active" : "nav-link"
                          }
                          href="#user"
                          onClick={() => handleTabChange(1)}
                        >
                          <i className="bx bx-group bx-sm me-1_5"></i>
                          Utilisateurs
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={
                            activeTab === 2 ? "nav-link active" : "nav-link"
                          }
                          href="#agency"
                          onClick={() => handleTabChange(2)}
                        >
                          <i className="bx bx-home-smile bx-sm me-1_5"></i>
                          Agences
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={
                            activeTab === 3 ? "nav-link active" : "nav-link"
                          }
                          href="#notif"
                          onClick={() => handleTabChange(3)}
                        >
                          <i className="bx bx-bell bx-sm me-1_5"></i>
                          Notifications
                        </a>
                      </li>
                    </ul>
                  </div>

                  {activeTab === 0 && (
                    <div className="row text-nowrap">
                      <div className="col-md-6 mb-6">
                        <div className="card h-100">
                          <div className="card-body">
                            <div className="card-icon mb-2">
                              <div className="avatar">
                                <div className="avatar-initial rounded bg-label-primary">
                                  <i className="bx bx-file bx-lg"></i>
                                </div>
                              </div>
                            </div>
                            <div className="card-info">
                              <h5 className="card-title mb-2">Production</h5>
                              <div className="d-flex align-items-baseline gap-1">
                                <h5 className="text-primary mb-0">
                                  {new Intl.NumberFormat("en-DE").format(
                                    montantPrimesMensuelles,
                                  )}{" "}
                                  FCFA
                                </h5>
                                <p className="mb-0"> Primes encaissées</p>
                              </div>
                              <p className="mb-0 text-truncate">
                                Etat des primes encaissées pour le mois en cours
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 mb-6">
                        <div className="card">
                          <div className="card-body">
                            <div className="card-icon mb-2">
                              <div className="avatar">
                                <div className="avatar-initial rounded bg-label-primary">
                                  <i className="bx bx-first-aid bx-lg"></i>
                                </div>
                              </div>
                            </div>
                            <div className="card-info">
                              <h5 className="card-title mb-2">
                                Sinistres payés
                              </h5>
                              <p className="mb-0">0 FCFA</p>
                            </div>
                            <p className="mb-0 text-truncate">
                              Etat des sinistres payés pour le mois en cours
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 mb-6">
                        <div className="card">
                          <div className="card-body">
                            <div className="card-icon mb-2">
                              <div className="avatar">
                                <div className="avatar-initial rounded bg-label-warning">
                                  <i className="bx bx-file bx-lg"></i>
                                </div>
                              </div>
                            </div>
                            <div className="card-info">
                              <h5 className="card-title mb-2">Production</h5>
                              <div className="d-flex align-items-baseline gap-1">
                                <h5 className="text-primary mb-0">
                                  {new Intl.NumberFormat("en-DE").format(
                                    montantPrimes,
                                  )}
                                  FCFA
                                </h5>
                                <p className="mb-0"> Primes encaissées</p>
                              </div>
                              <p className="mb-0 text-truncate">
                                Etat des primes encaissées depuis la création
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 mb-6">
                        <div className="card">
                          <div className="card-body">
                            <div className="card-icon mb-2">
                              <div className="avatar">
                                <div className="avatar-initial rounded bg-label-warning">
                                  <i className="bx bx-first-aid bx-lg"></i>
                                </div>
                              </div>
                            </div>
                            <div className="card-info">
                              <h5 className="card-title mb-2">
                                Sinistres payés
                              </h5>
                              <p className="mb-0">0 FCFA</p>
                            </div>
                            <p className="mb-0 text-truncate">
                              Etat des sinistres payés pour le mois passé
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {activeTab === 1 && (
                    <div>
                      <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        style={{ marginBottom: 16 }}
                        onClick={() => {}}
                      >
                        Ajouter un nouvel utilisateur
                      </Button>
                      <div className="card mb-6">
                        <div className="table-responsive mb-4">
                          <table className="table datatables-customer-order border-top">
                            <thead>
                              <tr>
                                <th>Nom d'utilisateur</th>
                                <th>Nom & Prénom(s)</th>
                                <th>Email</th>
                                <th>Profil</th>
                                <th>Agence</th>
                                <th>Statut</th>
                                <th className="text-md-center">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {utilisateurs?.length ? (
                                utilisateurs?.map((user, id) => (
                                  <tr key={id}>
                                    <td>{user?.username}</td>
                                    <td>
                                      {user?.personne?.nom}{" "}
                                      {user?.personne?.prenom}
                                    </td>
                                    <td>{user?.email}</td>
                                    <td>{user?.profil.nom}</td>
                                    <td>{user?.agence.nom}</td>
                                    <td>
                                      {user.active ? (
                                        <span className="badge bg-label-success">
                                          Actif
                                        </span>
                                      ) : (
                                        <span className="badge bg-label-danger">
                                          Inactif
                                        </span>
                                      )}
                                    </td>
                                    <td className="text-md-center">
                                      <div className="dropdown">
                                        <button
                                          type="button"
                                          className="btn p-0 dropdown-toggle hide-arrow"
                                          data-bs-toggle="dropdown"
                                        >
                                          <i className="bx bx-dots-vertical-rounded"></i>
                                        </button>
                                        <div className="dropdown-menu">
                                          <a
                                            className="dropdown-item"
                                            href={`/details-utilisateur/${user.codeId}`}
                                          >
                                            <i className="bx bx-detail me-1"></i>{" "}
                                            Détails
                                          </a>
                                          <a
                                            className="dropdown-item"
                                            href="#"
                                            onClick={(e) => e.preventDefault()}
                                          >
                                            <i className="bx bx-edit-alt me-1"></i>{" "}
                                            Modifier
                                          </a>
                                          <a
                                            className="dropdown-item"
                                            href="#"
                                            onClick={(e) => e.preventDefault()}
                                          >
                                            <i className="bx bx-trash me-1"></i>{" "}
                                            Supprimer
                                          </a>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={6}>
                                    Aucun utilisateur enregistré pour le moment
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
                  {activeTab === 2 && (
                    <div>
                      <a
                        href="#"
                        onClick={(e) => e.preventDefault()}
                        className="btn btn-primary w-35"
                        data-bs-target="#upgradePlanModal"
                        data-bs-toggle="modal"
                      >
                        <i className="bx bx-folder-plus bx-lg"></i> Ajouter une
                        nouvelle agence
                      </a>
                      <div className="card mb-6">
                        <div className="table-responsive mb-4">
                          <table className="table datatables-customer-order border-top">
                            <thead>
                              <tr>
                                <th>Code agence</th>
                                <th>Nom</th>
                                <th>Ville</th>
                                <th>Responsable</th>
                                <th>Contacts</th>
                                <th>Statut</th>
                                <th className="text-md-center">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {agences?.length ? (
                                agences?.map((ag, id) => (
                                  <tr key={id}>
                                    <td>{ag.codeAgence}</td>
                                    <td>{ag.nom}</td>
                                    <td>{ag.ville}</td>
                                    <td>{ag.responsable}</td>
                                    <td>
                                      {ag.contact && ag.contact[0]
                                        ? ag.contact[0]
                                        : ""}
                                    </td>
                                    <td>
                                      {ag.restrictionProduction ? (
                                        <span className="badge bg-label-danger">
                                          Inactif
                                        </span>
                                      ) : (
                                        <span className="badge bg-label-success">
                                          Actif
                                        </span>
                                      )}
                                    </td>
                                    <td className="text-md-center">
                                      <div className="dropdown">
                                        <button
                                          type="button"
                                          className="btn p-0 dropdown-toggle hide-arrow"
                                          data-bs-toggle="dropdown"
                                        >
                                          <i className="bx bx-dots-vertical-rounded"></i>
                                        </button>
                                        <div className="dropdown-menu">
                                          <a
                                            className="dropdown-item"
                                            href={`/agence/${ag.codeAgence}`}
                                          >
                                            <i className="bx bx-detail me-1"></i>{" "}
                                            Détails
                                          </a>
                                          <a
                                            className="dropdown-item"
                                            href="#"
                                            onClick={() =>
                                              showActivateConfirm(ag)
                                            }
                                          >
                                            <i className="bx bx-expand-horizontal me-1"></i>{" "}
                                            {ag?.restrictionProduction
                                              ? "Activer"
                                              : "Désactiver"}
                                          </a>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            <i className="bx bx-edit-alt me-1"></i>{" "}
                                            Modifier
                                          </a>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                            onClick={() => {}}
                                          >
                                            <i className="bx bx-trash me-1"></i>{" "}
                                            Supprimer
                                          </a>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={6}>
                                    Aucune agence enregistrée pour le moment
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
                  {activeTab === 3 && (
                    <div>
                      {/* Notification Tab content */}
                      {/* À implémenter si nécessaire */}
                    </div>
                  )}
                </div>
              </div>

              {/* Modal Edit User */}
              <div
                className="modal fade"
                id="editUser"
                tabIndex="-1"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-lg modal-simple modal-edit-user">
                  <div className="modal-content">
                    <div className="modal-body">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                      <div className="text-center mb-6">
                        <h4 className="mb-2">
                          Editer les informations du membre
                        </h4>
                        <p>Mettez à jour les informations du membre</p>
                      </div>
                      <form className="row g-6" onSubmit={handleSubmit}>
                        <div className="col-12">
                          <label
                            className="form-label"
                            htmlFor="modalEditUserFirstName"
                          >
                            Raison sociale
                          </label>
                          <input
                            type="text"
                            id="modalEditUserFirstName"
                            name="modalEditUserFirstName"
                            className="form-control"
                            placeholder="Veuillez renseigner la raison sociale"
                            value={raisonSociale}
                            onChange={(e) => setRaisonSociale(e.target.value)}
                          />
                        </div>
                        <div className="col-12">
                          <label
                            className="form-label"
                            htmlFor="modalEditUserLastName"
                          >
                            Siège social
                          </label>
                          <input
                            type="text"
                            id="modalEditUserLastName"
                            name="modalEditUserLastName"
                            className="form-control"
                            placeholder="Entrez le siège social"
                            value={siegeSocial}
                            onChange={(e) => setSiegeSocial(e.target.value)}
                          />
                        </div>
                        <div className="col-12 col-md-6">
                          <label
                            className="form-label"
                            htmlFor="modalEditUserName"
                          >
                            Code du membre
                          </label>
                          <input
                            type="text"
                            id="modalEditUserName"
                            name="modalEditUserName"
                            className="form-control"
                            placeholder="Entrez le code du membre"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                          />
                        </div>
                        <div className="col-12 col-md-6">
                          <label
                            className="form-label"
                            htmlFor="modalEditUserName"
                          >
                            Date de création
                          </label>
                          <input
                            type="date"
                            id="modalEditUserName"
                            name="modalEditUserName"
                            className="form-control"
                            value={dateCreation}
                            onChange={(e) => setDateCreation(e.target.value)}
                          />
                        </div>
                        <div className="col-12">
                          <label
                            className="form-label"
                            htmlFor="modalEditUserEmail"
                          >
                            Adresse mail
                          </label>
                          <input
                            type="text"
                            id="modalEditUserEmail"
                            name="modalEditUserEmail"
                            className="form-control"
                            placeholder="Entrez l'adresse mail"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>

                        <div className="col-12 col-md-6">
                          <label
                            className="form-label"
                            htmlFor="modalEditUserPhone1"
                          >
                            Numéro de téléphone 1
                          </label>
                          <div className="input-group">
                            <span className="input-group-text">TG (+228)</span>
                            <input
                              type="tel"
                              id="modalEditUserPhone1"
                              name="modalEditUserPhone"
                              className="form-control phone-number-mask"
                              placeholder="00 00 00 00"
                              value={telephone1}
                              onChange={(e) => setTelephone1(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <label
                            className="form-label"
                            htmlFor="modalEditUserPhone2"
                          >
                            Numéro de téléphone 2
                          </label>
                          <div className="input-group">
                            <span className="input-group-text">TG (+228)</span>
                            <input
                              type="tel"
                              id="modalEditUserPhone2"
                              name="modalEditUserPhone"
                              className="form-control phone-number-mask"
                              placeholder="00 00 00 00"
                              value={telephone2}
                              onChange={(e) => setTelephone2(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <label
                            className="form-label"
                            htmlFor="modalEditUserLanguage"
                          >
                            Type membre
                          </label>
                          <select
                            id="modalEditUserLanguage"
                            name="modalEditUserLanguage"
                            className="select2 form-select"
                            value={typeMembre}
                            onChange={(e) => setTypeMembre(e.target.value)}
                          >
                            <option value="ASSURANCE">ASSURANCE</option>
                            <option value="PARTENAIRE">PARTENAIRE</option>
                          </select>
                        </div>
                        <div className="col-12 col-md-6">
                          <label
                            className="form-label"
                            htmlFor="modalEditUserCountry"
                          >
                            Responsable
                          </label>
                          <input
                            type="text"
                            id="modalEditUserPhone2"
                            name="modalEditUserPhone"
                            className="form-control phone-number-mask"
                            placeholder="Le nom du responsable du membre"
                            value={responsable}
                            onChange={(e) => setResponsable(e.target.value)}
                          />
                        </div>
                        <div className="col-12 col-md-6">
                          <label className="form-label" htmlFor="otpConnexion">
                            OTP Connexion ?
                          </label>
                          <select
                            id="otpConnexion"
                            name="otpConnexion"
                            className="form-control"
                            value={otpConnexion}
                            onChange={(e) => setOtpConnexion(e.target.value)}
                          >
                            <option value="NONE">Désactiver</option>
                            <option value="SMS">SMS</option>
                            <option value="EMAIL">EMAIL</option>
                            <option value="WHATSAPP">WHATSAPP</option>
                          </select>
                        </div>
                        <div className="col-12 col-md-6">
                          <label className="form-label" htmlFor="domaine">
                            Nom de domaine
                          </label>
                          <input
                            type="text"
                            id="domaine"
                            name="domaine"
                            className="form-control"
                            placeholder="Le nom de domaine de l'organisme"
                            value={domaine}
                            onChange={(e) => setDomaine(e.target.value)}
                          />
                        </div>
                        <div className="col-12 col-md-6">
                          <label className="form-label" htmlFor="website">
                            Site web de l'organisme
                          </label>
                          <input
                            type="text"
                            id="website"
                            name="website"
                            className="form-control"
                            placeholder="Le site web de la compagnie / du partenaire"
                            value={website}
                            onChange={(e) => setWebsite(e.target.value)}
                          />
                        </div>
                        <div className="col-12 col-md-6">
                          <label
                            className="form-label"
                            htmlFor="defaultExerice"
                          >
                            Exercice par défaut
                          </label>
                          <select
                            id="defaultExercice"
                            name="defaultExercice"
                            className="form-control"
                            value={defaultExercice}
                            onChange={(e) => {
                              setDefaultExercice(e.target.value);
                            }}
                          >
                            {exercices.map((exo, id) => (
                              <option key={id} value={exo.exerciceId}>
                                {exo.libelleExercice}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-12">
                          <div className="card-body">
                            <div className="d-flex align-items-start align-items-sm-center gap-6 pb-4 border-bottom">
                              {imagePreviewUrl && (
                                <img
                                  src={imagePreviewUrl}
                                  alt="logo-avatar"
                                  className="d-block w-px-300 h-px-100 rounded"
                                  id="uploadedAvatar"
                                />
                              )}
                              <div className="button-wrapper">
                                <label
                                  htmlFor="upload"
                                  className="btn btn-primary me-3 mb-4"
                                  tabIndex="0"
                                >
                                  <span className="d-none d-sm-block">
                                    Téléverser une nouvelle image
                                  </span>
                                  <i className="bx bx-upload d-block d-sm-none"></i>
                                  <input
                                    type="file"
                                    id="upload"
                                    className="account-file-input"
                                    hidden
                                    accept="image/png, image/jpeg"
                                    onChange={handleImageChange}
                                  />
                                </label>
                                <button
                                  type="button"
                                  className="btn btn-label-secondary account-image-reset mb-4"
                                  onClick={resetImage}
                                >
                                  <i className="bx bx-reset d-block d-sm-none"></i>
                                  <span className="d-none d-sm-block">
                                    Réinitialiser
                                  </span>
                                </button>

                                <div>
                                  JPG, GIF ou PNG autorisés. Taille maximale de
                                  2 Mo
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-check form-switch my-2 ms-2">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="editBillingAddress"
                              checked={active}
                              onChange={() => setActive(!active)}
                            />
                            <label
                              htmlFor="editBillingAddress"
                              className="switch-label"
                            >
                              Actif?
                            </label>
                          </div>
                        </div>
                        <div className="col-12 text-center">
                          <button
                            type="submit"
                            className="btn btn-primary me-3"
                          >
                            Enregistrer
                          </button>
                          <button
                            type="reset"
                            className="btn btn-label-secondary"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            Annuler
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              {/* Modal Ajout Agence */}
              <div
                className="modal fade"
                id="upgradePlanModal"
                tabIndex="-1"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-lg modal-dialog-centered modal-simple modal-upgrade-plan">
                  <div className="modal-content">
                    <div className="modal-body">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                      <div className="text-center mb-6">
                        <h4 className="mb-2">
                          Ajouter une agence de <u>{raisonSociale}</u>
                        </h4>
                        <p>
                          Renseigner les informations pour créer une nouvelle
                          agence.
                        </p>
                      </div>
                      <form
                        id="upgradePlanForm"
                        className="row g-4"
                        onSubmit={(e) => handleAgencySubmit(e)}
                      >
                        <div className="col-12">
                          <label className="form-label" htmlFor="codeAgence">
                            Code de l'agence
                          </label>
                          <input
                            type="text"
                            id="codeAgence"
                            name="codeAgence"
                            className="form-control"
                            placeholder="Veuillez renseigner le code de l'agence"
                            value={codeAgence}
                            onChange={(e) => setCodeAgence(e.target.value)}
                          />
                        </div>
                        <div className="col-12">
                          <label className="form-label" htmlFor="nomAgence">
                            Nom de l'agence{" "}
                          </label>
                          <input
                            type="text"
                            id="nomAgence"
                            name="nomAgence"
                            className="form-control"
                            placeholder="Veuillez renseigner le nom de l'agence"
                            value={nomAgence}
                            onChange={(e) => setNomAgence(e.target.value)}
                          />
                        </div>
                        <div className="col-12 col-md-6">
                          <label className="form-label" htmlFor="villeAgence">
                            Ville{" "}
                          </label>
                          <select
                            id="villeAgence"
                            name="villeAgence"
                            className="form-control form-select"
                            value={villeAgence}
                            onChange={(e) => setVilleAgence(e.target.value)}
                          >
                            {villes?.map((vil, id) => (
                              <option key={id} value={vil.libelleParametre}>
                                {vil.libelleParametre}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="col-12 col-md-6">
                          <label className="form-label" htmlFor="typeAgence">
                            Type agence
                          </label>
                          <select
                            id="typeAgence"
                            name="typeAgence"
                            className="form-control form-select"
                            value={typeAgence}
                            onChange={(e) => setTypeAgence(e.target.value)}
                          >
                            <option value="AGENCE">AGENCE</option>
                            <option value="BUREAU_DIRECT">BUREAU DIRECT</option>
                            <option value="AGENCE_GENERALE">
                              AGENCE GENERALE
                            </option>
                            <option value="COURTIER">COURTIER</option>
                            <option value="ETABLISSEMENT_FINANCIER">
                              ETABLISSEMENT FINANCIER
                            </option>
                            <option value="AUTRE">AUTRE</option>
                          </select>
                        </div>
                        <div className="col-12">
                          <label
                            className="form-label"
                            htmlFor="responsableAgence"
                          >
                            Nom du responsable{" "}
                          </label>
                          <input
                            type="text"
                            id="responsableAgence"
                            name="responsableAgence"
                            className="form-control"
                            placeholder="Veuillez renseigner le nom du responsable"
                            value={responsableAgence}
                            onChange={(e) =>
                              setResponsableAgence(e.target.value)
                            }
                          />
                        </div>
                        <div className="col-12 col-md-6">
                          <label
                            className="form-label"
                            htmlFor="telephone1Agence"
                          >
                            Numéro de téléphone 1
                          </label>
                          <div className="input-group">
                            <span className="input-group-text">TG (+228)</span>
                            <input
                              type="tel"
                              id="telephone1Agence"
                              name="telephone1Agence"
                              className="form-control phone-number-mask"
                              placeholder="00 00 00 00"
                              value={telephone1Agence}
                              onChange={(e) =>
                                setTelephone1Agence(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <label
                            className="form-label"
                            htmlFor="telephone2Agence"
                          >
                            Numéro de téléphone 2
                          </label>
                          <div className="input-group">
                            <span className="input-group-text">TG (+228)</span>
                            <input
                              type="tel"
                              id="telephone2Agence"
                              name="telephone2Agence"
                              className="form-control phone-number-mask"
                              placeholder="00 00 00 00"
                              value={telephone2Agence}
                              onChange={(e) =>
                                setTelephone2Agence(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-check form-switch my-2 ms-2">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="activeAgency"
                              checked={activeAgency}
                              onChange={() => setActiveAgency(!activeAgency)}
                            />
                            <label
                              htmlFor="activeAgency"
                              className="switch-label"
                            >
                              Actif?
                            </label>
                          </div>
                        </div>
                        <div className="col-12 text-center">
                          <button
                            type="submit"
                            className="btn btn-primary me-3"
                          >
                            Enregistrer
                          </button>
                          <button
                            type="reset"
                            className="btn btn-label-secondary"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            Annuler
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
            <div className="content-backdrop fade"></div>
          </div>
        </div>
      </div>
      <div className="layout-overlay layout-menu-toggle"></div>
      <div className="drag-target"></div>
    </div>
  );
};

export default DetailsMembre;
