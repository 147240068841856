import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_URL, TOKEN } from "../../../../variables/constants";
import PropTypes from "prop-types";

const ExpandedRowContent = ({ record }) => {
  const [debits, setDebits] = useState([]);
  const [credits, setCredits] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);

  useEffect(() => {
    if (!dataFetched) {
      axios
        .get(
          `${API_URL}/api/details-ecriture/ecriture/1/${record.numeroPolice}`,
          {
            headers: {
              Authorization: `Bearer ${TOKEN}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
              "Access-Control-Allow-Headers":
                "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
            },
          }
        )
        .then((res) => {
          console.log("res.data");
          // console.log(
          //   "Credits: ",
          //   res.data.filter((dt) => dt.credit !== null)
          // );
          setDebits(res.data.filter((dt) => dt.debit !== null));
          setCredits(res.data.filter((dt) => dt.credit !== null));
          setDataFetched(true);
          // console.log(debits, credits);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [record, dataFetched]);
  return (
    <>
      <p
        style={{
          margin: 0,
        }}
      >
        {`Ecriture comptable : Emission de contrat - N° Police: ${record.numeroPolice}`}
      </p>
      <table border="1">
        <tr>
          <th colSpan={2}>COMPTES</th>
          <th></th>
          <th colSpan={2}>MONTANT</th>
        </tr>
        <tr>
          <td>DEBIT</td>
          <td>CREDIT</td>
          <td>LIBELLE</td>
          <td>DEBIT</td>
          <td>CREDIT</td>
        </tr>
        {debits?.map((dt, key) => (
          <tr key={key}>
            <td>{dt.numeroCompte}</td>
            <td></td>
            <td>{dt.libelle}</td>
            <td>{new Intl.NumberFormat("en-DE").format(dt.debit)}</td>
            <td></td>
          </tr>
        ))}
        {credits?.map((dt, key) => (
          <tr key={key}>
            <td></td>
            <td>{dt.numeroCompte}</td>
            <td>{dt.libelle}</td>
            <td></td>
            <td>{new Intl.NumberFormat("en-DE").format(dt.credit)}</td>
          </tr>
        ))}
        <tr>
          <td colSpan={5}>
            {" "}
            <center>{credits[0]?.ecriture?.libelle}</center>
          </td>
        </tr>
      </table>
    </>
  );
};
ExpandedRowContent.propTypes = {
  record: PropTypes.object.isRequired,
};
export default ExpandedRowContent;
