import { Image } from "antd";
import PropTypes from "prop-types";
import React from "react";
import entete from "../../../../assets/img/entete.jpg";
import footer from "../../../../assets/img/footer.png";
import { frenchMonth } from "../../../../variables/functions";

const CompteDeCessionParCategorieParCompagnie = React.forwardRef((props, ref) => {
    const montantSouscriptions = props?.souscriptions ? props?.souscriptions?.reduce((sum, reglement) => reglement.contrat.engins[0].categorie.codeCategorie === props?.categorie?.codeCategorie ? sum + reglement.data.montant : sum + 0, 0) : 0;
    const montantSinistresPayes = props?.sinistres ? props?.sinistres?.reduce((sum, sinistre) => sinistre.contrat.engins[0].categorie.codeCategorie === props?.categorie?.codeCategorie ? sum + sinistre.montant : sum + 0, 0) : 0;
    const indemniteDeGestion = montantSouscriptions * 0.15;
    const difference = indemniteDeGestion + montantSinistresPayes - montantSouscriptions;
    return (
        <div className="col-12"
            style={{
                width: "210mm",
                height: "297mm",
                margin: 0,
                padding: 0,
                pageBreakInside: "avoid"
            }}
            ref={ref}
        >
            <div className="card">
                <Image
                    src={entete}
                    style={{
                        width: "100%",
                        height: "auto"
                    }}
                />
                <div
                    className=""
                    style={{
                        padding: "10mm",
                        boxSizing: "border-box"
                    }}
                >
                    <div className="">
                        <div className="row">
                            <div className="col-12">
                                <table
                                    className="table table-bordered"
                                    style={{ width: "30%" }}
                                >
                                    <tbody>
                                        <tr>
                                            <td className="text-left">
                                                <strong>COMPTE DE CESSION</strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-left">
                                                <strong>Catégorie: {props?.categorie?.codeCategorie === "M" ? "MOTO" : "TPV"}</strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-left">
                                                <em>Période: {frenchMonth(props?.periode.split("-")[1])} {props?.periode.split("-")[0]}</em>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <br />
                        <div style={{ marginLeft: "360px" }}>
                            <p>{props?.compagnie?.raisonSociale}</p>
                            <p>{props?.compagnie?.siegeSocial}</p>
                            <p>Tel: {props?.compagnie?.contact ? props?.compagnie?.contact[0] : ""}</p>
                            <p>LOME</p>
                        </div>

                        <br />

                        <div className="row">
                            <div className="col-12">
                                <table
                                    className="table table-bordered"
                                    style={{ width: "100%" }}
                                >
                                    <thead>
                                        <tr>
                                            <th>Postes</th>
                                            <th>Débits</th>
                                            <th>Crédits</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="text-start">
                                                MONTANT TOTAL DES SOUSCRIPTIONS
                                            </td>
                                            <td className=""></td>
                                            <td>
                                                {new Intl.NumberFormat("en-DE").format(
                                                    Math.trunc(montantSouscriptions)
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-start">
                                                INDEMNITE DE GESTION 15%
                                            </td>
                                            <td className=""> {new Intl.NumberFormat("en-DE").format(
                                                Math.trunc(indemniteDeGestion)
                                            )}</td>
                                            <td>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-start">
                                                SINISTRES PAYES
                                            </td>
                                            <td className="">{new Intl.NumberFormat("en-DE").format(
                                                Math.trunc(montantSinistresPayes)
                                            )}</td>
                                            <td></td>
                                        </tr>

                                        <tr>
                                            <td className="text-start">
                                                <strong>SOLDE EN FAVEUR {difference >= 0 ? "DE LA COMPAGNIE" : "DU POOL"}</strong>
                                            </td>
                                            <td className="">{difference < 0 ? new Intl.NumberFormat("en-DE").format(
                                                Math.trunc(-difference)
                                            ) : ""}</td>
                                            <td>{difference >= 0 ? new Intl.NumberFormat("en-DE").format(
                                                Math.trunc(difference)
                                            ) : ""}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th>BALANCE</th>
                                            <th>{new Intl.NumberFormat("en-DE").format(
                                                Math.trunc(difference >= 0 ? montantSinistresPayes + indemniteDeGestion : montantSouscriptions)
                                            )}</th>
                                            <th>{new Intl.NumberFormat("en-DE").format(
                                                Math.trunc(difference >= 0 ? montantSinistresPayes + indemniteDeGestion : montantSouscriptions)
                                            )}</th>
                                        </tr>

                                    </tfoot>
                                </table>
                                <br />
                                <div style={{ marginLeft: "500px" }}>
                                    <div class="row">
                                        <div class="col-12">
                                            <strong><u>Directeur du Pool</u></strong>
                                        </div>

                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <div class="col-12">
                                            <strong>Koffi G. WARBUTIN</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Image
                    src={footer}
                    style={{
                        width: "100%",
                        height: "auto",
                        position: "absolute",
                        bottom: 0
                    }}
                />
            </div>
        </div>
    );
});

CompteDeCessionParCategorieParCompagnie.propTypes = {
    souscriptions: PropTypes.array,
    sinistres: PropTypes.array,
    categorie: PropTypes.object,
    compagnie: PropTypes.object,
    periode: PropTypes.string
};
export default CompteDeCessionParCategorieParCompagnie;
