import React, { useRef, useState } from "react";
import SideBar from "../../components/SideBar";
import NavBar from "../../components/NavBar";
import {Footer} from "antd/es/layout/layout";
import {Button, message, Space, Spin} from "antd";
import InformationsSurAssure from "./components/declaration/InformationsSurAssure";
import { ajouterSinistre, ajouterUnTemoin } from "../../hooks/useHooks";
import InformationsSurVictimes from "./components/declaration/InformationsSurVictimes";
import InformationsSurAccident from "./components/declaration/InformationsSurAccident";
import InformationsSurAdversaire from "./components/declaration/InformationsSurAdversaire";
import ResumeInformations from "./components/declaration/ResumeInformations";

const DeclarationSinistre = () => {
    const [activeTab, setActiveTab] = useState(1);
    const [saveSinistreLoading, setSaveSinistreLoading] = useState(false)
    const initSinistre = {
        contrat: "",
        engin: "",
        dateSinistre: "",
        lieu: "",
        typeSinistre: "",
        victimes: [],
        provenanceAssure: "",
        destinationAssure: "",
        vitesseAssure: "",
        circonstance: "",
        observationAgent: "",
        temoin: "",
        entiteConstat: ""
    };

  const initPersonne = {
    nom: "",
    prenom: "",
    dateNaissance: "",
    profession: "",
    adresse: "",
    fonction: "",
  };

  const handleSubmit = (e) => {
    console.log("handleSubmit: ", e);

    const sin = initSinistre;
    sin.contrat = e?.assure?.infosAssure?.contrat;
    sin.engin = e?.assure?.infosAssure?.engin;
    sin.lieu = e?.accident?.sinistre?.lieu;
    sin.dateSinistre = e?.assure?.infosAssure?.dateSinistre;
    sin.provenanceAssure = e?.accident?.sinistre?.provenanceAssure;
    sin.destinationAssure = e?.accident?.sinistre?.destinationAssure;
    sin.vitesseAssure = e?.accident?.sinistre?.vitesseAssure;
    sin.observationAgent = e?.accident?.sinistre?.observationAgent;
    sin.circonstance = e?.accident?.sinistre?.circonstance;
    sin.typeSinistre = e?.assure?.infosAssure?.typeSinistre;
    sin.victimes = e?.victimes?.victimes;
    sin.adversaires = e?.adversaire?.adversaires;
    sin.entiteConstat = e?.accident?.sinistre?.entiteConstat;

    if (e?.accident?.sinistre?.temoin?.nom) {
      let temoin = initPersonne;
      temoin.nom = e?.accident?.sinistre?.temoin?.nom;
      temoin.prenom = e?.accident?.sinistre?.temoin?.prenom;
      temoin.fonction = e?.accident?.sinistre?.temoin?.fonction;
      console.log("Temoin: ", temoin);
      ajouterUnTemoin(JSON.stringify(temoin))
        .then((res) => {
          sin.temoin = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      sin.temoin = null;
    }

    // setTimeout(() => {
    console.log("sinistre: ", sin);
    ajouterSinistre(JSON.stringify(sin))
      .then((res) => {
        console.log(res.data);
        message.success("Sinistre déclaré!");
        window.location.href = "/admin/sinistres";
      })
      .catch((err) => {
        console.log(err);
      });
    // }, [700]);
  };

  const assureRef = useRef(null);
  const adversaireRef = useRef(null);
  const accidentRef = useRef(null);
  const victimeRef = useRef(null);
  const resumeRef = useRef(null);

  const tabRefs = {
    1: assureRef,
    2: adversaireRef,
    3: accidentRef,
    4: victimeRef,
    5: resumeRef,
  };

  const returnActiveTabRef = () => tabRefs[activeTab];

  const handleSaveAssureInfos = () => {
    if (assureRef.current) {
      assureRef.current.isValidated();
    }
  };

  const handleSaveVictimeInfos = () => {
    if (assureRef.current) {
      assureRef.current.isValidated();
    }
  };

    const handleSaveAdversaireInfos = () => {
        if (adversaireRef.current) {
            adversaireRef.current.isValidated();
        }
    }
    const handleSaveInfos = () => {
        setSaveSinistreLoading(true)
        const activeTabRef = tabRefs[activeTab];
        if (activeTabRef?.current) {
            activeTabRef.current.isValidated();
            activeTabRef.current.sendState()
            setSaveSinistreLoading(false)

        }
        setSaveSinistreLoading(false)
    };

  return (
    <div className="layout-wrapper layout-content-navbar  ">
      <div className="layout-container">
        <SideBar currentMenu={"SINISTRES"} />

        {/* <!-- Layout container --> */}
        <div className="layout-page">
          <NavBar />

          {/* <!-- Content wrapper --> */}
          <div className="content-wrapper">
            {/* <!-- Content --> */}
            <div className="container-xxl flex-grow-1 container">
              <div className={"grid container grid-container"}>
                <div className={"grid-item"}>
                  <div className="bs-stepper vertical wizard-modern wizard-modern-vertical wizard-modern-vertical-icons-example mt-2">
                    <div
                      className="bs-stepper-header"
                      style={{ marginRight: 25 }}
                    >
                      <div
                        className={activeTab === 1 ? "step active" : "step"}
                        data-target="#personal-info-vertical-modern"
                      >
                        <button
                          type="button"
                          className="step-trigger"
                          onClick={() => setActiveTab(1)}
                        >
                          <span className="bs-stepper-circle">
                            <i className="bx bx-user"></i>
                          </span>
                          <span className="bs-stepper-label">
                            <span className="bs-stepper-title">
                              Informations sur l'assuré
                            </span>
                            <span className="bs-stepper-subtitle">
                              Ajouter les infos de l'assuré
                            </span>
                          </span>
                        </button>
                      </div>
                      <div className="line"></div>
                      <div
                        className={activeTab === 2 ? "step active" : "step"}
                        data-target="#account-details-vertical-modern"
                      >
                        <button
                          type="button"
                          className="step-trigger"
                          onClick={() => setActiveTab(2)}
                        >
                          <span className="bs-stepper-circle">
                            <i className="bx bx-user-pin"></i>
                          </span>
                          <span className="bs-stepper-label">
                            <span className="bs-stepper-title">
                              Informations sur l'adversaire
                            </span>
                            <span className="bs-stepper-subtitle">
                              Saisir les informations de l'adversaire
                            </span>
                          </span>
                        </button>
                      </div>
                      <div className="line"></div>
                      <div
                        className={activeTab === 3 ? "step active" : "step"}
                        data-target="#social-links-vertical-modern"
                      >
                        <button
                          type="button"
                          className="step-trigger"
                          onClick={() => setActiveTab(3)}
                        >
                          <span className="bs-stepper-circle">
                            <i className="bx bxs-car-crash"></i>
                          </span>
                          <span className="bs-stepper-label">
                            <span className="bs-stepper-title">
                              Informations sur l'accident
                            </span>
                            <span className="bs-stepper-subtitle">
                              Renseigner les infos sur l'accident
                            </span>
                          </span>
                        </button>
                      </div>
                      <div className="line"></div>
                      <div
                        className={activeTab === 4 ? "step active" : "step"}
                        data-target="#social-links-vertical-modern"
                      >
                        <button
                          type="button"
                          className="step-trigger"
                          onClick={() => setActiveTab(4)}
                        >
                          <span className="bs-stepper-circle">
                            <i className="bx bx-user-voice"></i>
                          </span>
                          <span className="bs-stepper-label">
                            <span className="bs-stepper-title">
                              Informations sur la(les) victime(s)
                            </span>
                            <span className="bs-stepper-subtitle">
                              Renseigner les infos sur les victimes
                            </span>
                          </span>
                        </button>
                      </div>
                      <div className="line"></div>
                      <div
                        className={activeTab === 5 ? "step active" : "step"}
                        data-target="#social-links-vertical-modern"
                      >
                        <button
                          type="button"
                          className="step-trigger"
                          onClick={() => setActiveTab(5)}
                        >
                          <span className="bs-stepper-circle">
                            <i className="bx bx-detail"></i>
                          </span>
                          <span className="bs-stepper-label">
                            <span className="bs-stepper-title">
                              Resume de la declaration
                            </span>
                            <span className="bs-stepper-subtitle">
                              Verifier les informations
                            </span>
                          </span>
                        </button>
                      </div>
                      <div className="line"></div>
                      <Space direction="vertical"></Space>
                    </div>
                    <div className="bs-stepper-content">
                      <div
                        id="personal-info-vertical-modern"
                        className={
                          activeTab === 1 ? "content active" : "content"
                        }
                      >
                        <InformationsSurAssure
                          activeTab={activeTab}
                          setActiveTab={setActiveTab}
                          ref={assureRef}
                        />
                      </div>
                      <div
                        id="personal-info-vertical-modern"
                        className={
                          activeTab === 2 ? "content active" : "content"
                        }
                      >
                        <InformationsSurAdversaire
                          activeTab={activeTab}
                          setActiveTab={setActiveTab}
                          ref={adversaireRef}
                        />
                      </div>

                                        <div
                                            id="personal-info-vertical-modern"
                                            className={activeTab === 3 ? "content active" : "content"}
                                        >
                                            <InformationsSurAccident activeTab={activeTab} setActiveTab={setActiveTab}
                                                                     ref={accidentRef}/>
                                        </div>
                                        <div
                                            id="personal-info-vertical-modern"
                                            className={activeTab === 4 ? "content active" : "content"}
                                        >
                                            <InformationsSurVictimes activeTab={activeTab} setActiveTab={setActiveTab}
                                                                     ref={victimeRef}/>
                                        </div>
                                        <div
                                            id="personal-info-vertical-modern"
                                            className={activeTab === 5 ? "content active" : "content"}
                                        >
                                            <ResumeInformations ref={resumeRef}/>
                                        </div>
                                        <div className="col-12 d-flex justify-content-between mt-5">
                                            <button className="btn btn-label-secondary btn-prev"
                                                    disabled={activeTab === 1}
                                                    onClick={() => setActiveTab(activeTab - 1)}>
                                                <i className="bx bx-left-arrow-alt bx-sm ms-sm-n2 me-sm-2"></i>
                                                <span className="align-middle d-sm-inline-block d-none">Précédent</span>
                                            </button>
                                            {activeTab !== 5 ? (
                                                <Spin spinning={saveSinistreLoading}>
                                                    <button className="btn btn-primary" onClick={handleSaveInfos}>
                                                    <span
                                                        className="align-middle d-sm-inline-block d-none me-sm-2">Suivant</span>
                                                        <i className="bx bx-right-arrow-alt bx-sm me-sm-n2"></i>
                                                    </button>
                                                </Spin>
                                            ) : (
                                                <Spin spinning={saveSinistreLoading}>
                                                    <Button disabled={saveSinistreLoading} className="btn btn-primary"
                                                            onClick={handleSaveInfos}>
                                                    <span
                                                        className="align-middle d-sm-inline-block d-none me-sm-2">Valider</span>
                                                        <i className="bx bx-save bx-sm me-sm-n2"></i>
                                                    </Button>
                                                </Spin>
                                            )
                                            }

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- / Content --> */}

            {/* <!-- Footer --> */}
            <Footer />
            {/* <!-- / Footer --> */}

            <div className="content-backdrop fade"></div>
          </div>
          {/* <!-- Content wrapper --> */}
        </div>
        {/* <!-- / Layout page --> */}
      </div>

      {/* <!-- Overlay --> */}
      <div className="layout-overlay layout-menu-toggle"></div>

      {/* <!-- Drag Target Area To SlideIn Menu On Small Screens --> */}
      <div className="drag-target"></div>
    </div>
  );
};

export default DeclarationSinistre;
