import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, Modal } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Footer from "../../../components/Footer";
import NavBar from "../../../components/NavBar";
import SideBar from "../../../components/SideBar";
import { API_URL, TOKEN } from "../../../variables/constants";
import AddSommeMin from "./AddSommeMin"; // <-- le composant que vous avez déjà

const SommeMinList = () => {
  const [sommeMins, setSommeMins] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedSommeMin, setSelectedSommeMin] = useState(null);

  /**
   * Ouvrir la modale
   * @param {boolean} edit - mode édition ou non
   * @param {object|null} sommeMin - données de l'élément sélectionné
   */
  const handleShow = (edit = false, sommeMin = null) => {
    setShowModal(true);
    setEditMode(edit);
    setSelectedSommeMin(sommeMin);
  };

  /**
   * Fermer la modale
   */
  const handleClose = () => {
    setShowModal(false);
    setEditMode(false);
    setSelectedSommeMin(null);
  };

  /**
   * Récupérer la liste des sommes minimales
   * On l'appelle une première fois au montage
   * et aussi à chaque fermeture de la modale (pour actualiser la liste).
   */
  useEffect(() => {
    axios
      .get(`${API_URL}/api/parametre/type/SOMME_MIN`, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log("alls sm: " + JSON.stringify(res.data));
        setSommeMins(res.data);
      })
      .catch((err) => {
        console.error("Error fetching somme min list:", err);
      });
  }, [showModal]);
  // Quand showModal redevient false (c.-à-d. après un ajout ou modif), on recharge la liste

  /**
   * Suppression d'une somme minimale
   */
  const handleDeleteSommeMin = (sommeMinId) => {
    Modal.confirm({
      title: "Êtes-vous sûr de vouloir supprimer cette somme minimale de sinistre à payer ?",
      content: "Cette action est irréversible",
      okText: "Oui",
      okType: "danger",
      cancelText: "Annuler",
      onOk: () => {
        axios
          .delete(`${API_URL}/api/parametre/${sommeMinId}`, {
            headers: { Authorization: `Bearer ${TOKEN}` },
          })
          .then(() => {
            // On retire l'élément supprimé de la liste locale
            setSommeMins((prev) =>
              prev.filter((item) => item.parametreId !== sommeMinId)
            );
            toast.success("Somme minimale  de sinistre à  payer supprimée avec succès.");
          })
          .catch((err) => {
            console.error("Error deleting somme min:", err);
            toast.error("Erreur lors de la suppression de la somme minimale.");
          });
      },
    });
  };

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <SideBar currentMenu="AUTRES_PARAMETRES" />
        <div className="layout-page">
          <NavBar />
          <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
              {/* Bouton pour ajouter une nouvelle somme min */}
              <Button
                type="primary"
                icon={<PlusOutlined />}
                style={{ marginBottom: 16 }}
                onClick={() => handleShow(false, null)}
              >
                Ajouter une nouvelle somme minimale
              </Button>

              <div className="card">
                <h5 className="card-header">Paramétrage des sommes minimales de sinistre à payer</h5>
                <div className="table-responsive text-nowrap">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Libellé</th>
                        <th>Montant</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody className="table-border-bottom-0">
                      {sommeMins.map((item, index) => (
                        <tr key={index}>
                          <td>{item.libelleParametre}</td>
                          <td>{item.valeur}</td>
                          <td>
                            <Dropdown
                              overlay={
                                <Menu>
                                  <Menu.Item
                                    icon={<EditOutlined />}
                                    onClick={() => handleShow(true, item)}
                                  >
                                    Modifier
                                  </Menu.Item>
                                  <Menu.Item
                                    icon={<DeleteOutlined />}
                                    danger
                                    onClick={() => handleDeleteSommeMin(item.parametreId)}
                                  >
                                    Supprimer
                                  </Menu.Item>
                                </Menu>
                              }
                              trigger={["click"]}
                            >
                              <Button type="text" icon={<PlusOutlined />} />
                            </Dropdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>

      {/* Modale pour créer / modifier la somme min */}
      <AddSommeMin
        show={showModal}
        handleClose={handleClose}
        editMode={editMode}
        selectedSommeMin={selectedSommeMin}
      />
    </div>
  );
};

export default SommeMinList;
